import styled from 'styled-components';
import { deviceTypes } from '@amzn/storm-ui-utils-v3';
import { TextButton } from '../../Button';
import Icon from '../../Icon';
import { TextButtonProps } from '../../Button/TextButton';
import { withTaktFallbackId } from '../../TaktIdContext';

/**
 * Take the name of a size and get its value from the theme
 */
const getPadding = (theme: any, size = 'xlarge', deviceType = deviceTypes.DESKTOP) => {
  if (deviceType === deviceTypes.MOBILE) {
    if (theme
      && theme.mobile
      && theme.mobile.spacingValues
      && typeof theme.mobile.spacingValues[size] !== 'undefined'
    ) {
      return theme.mobile.spacingValues[size];
    }
  }

  if (theme
    && theme.spacingValues
    && typeof theme.spacingValues[size] !== 'undefined'
  ) {
    return theme.spacingValues[size];
  }

  return theme.spacingValues.base;
};

interface PopperContentProps {
  $padding?: 'none' | 'micro' | 'mini' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

export const PopperContent = styled('div')<PopperContentProps>`
  padding: ${({ $padding, theme }) => getPadding(theme, $padding)}px;

  overflow: visible;
`;
PopperContent.displayName = 'PopperContent';

export const PopperContentWithTopCornerIcon = styled(PopperContent)`
  padding: ${({ theme }) => theme.tooltip.paddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.paddingLeftRightWithTopCornerIcon};
`;
PopperContentWithTopCornerIcon.displayName = 'PopperContentWithTopCornerIcon';

export const TooltipScrollContainer = styled.div`
  overflow-x: auto;
`;
TooltipScrollContainer.displayName = 'TooltipScrollContainer';

export const TooltipMessage = styled.div`
  ${({ theme }) => theme.typography.base};
`;
TooltipMessage.displayName = 'TooltipMessage';

type ThemeType = 'light' | 'dark' | 'blue';

interface StyledCloseButtonBaseProps extends TextButtonProps {
  $styleType: ThemeType;
}
const CloseButtonBase = styled(TextButton)<StyledCloseButtonBaseProps>`
  opacity: ${({ $styleType }) => (($styleType === 'blue' || $styleType === 'dark') ? 1 : 0.5)};
  padding: ${({ theme }) => theme.tooltip.closeIcon.padding};
  text-align: center;
  color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  outline: none;
  margin-inline-start: ${({ theme }) => theme.tooltip.closeIcon.marginLeft};

  :hover{
    opacity: 1;
    background: ${({ $styleType, theme }) => (theme.tooltip.closeIconBg[$styleType])};
    color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: background .2s ease-in-out;
  }

  :focus {
    opacity: 1;
  }
`;

export const CloseButton = withTaktFallbackId<StyledCloseButtonBaseProps>(CloseButtonBase);

export const CloseIcon = styled(Icon)`
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;

  .svg-inline--fa{
    vertical-align: ${({ theme }) => theme.tooltip.closeIcon.svg.verticalAlign};
    width: ${({ theme }) => theme.tooltip.closeIcon.svg.width};
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ContentContainerWithTopCornerIcon = styled(FlexContainer)`
  padding: ${({ theme }) => theme.tooltip.contentPaddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.contentPaddingLeftRightWithTopCornerIcon};
`;
ContentContainerWithTopCornerIcon.displayName = 'ContentContainerWithTopCornerIcon';

export const ChildrenWrapper = styled.div`
  width: 100%;
`;
