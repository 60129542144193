import React from 'react';
import styled, { css } from 'styled-components';
import { isMobileStyleMixin } from '@amzn/storm-ui-v3';
import CalendarWeek from './CalendarWeek';
import getWeeks from '../utils/getWeeks';
import useDatePicker from '../hooks/useDatePicker';
import getWeekdays from '../utils/getWeekdays';
import { FormatWeekday } from './formatters';
import { ISODate } from '../types';

const Table = styled('table')`
  /** Adds space between the weekdays and the table cells */
  tbody::before {
    content: "";
    display: block;
    height: 2px;

    ${isMobileStyleMixin(css`
      height: 7px;
    `)}
  }
  /** Adds space between the table cells and time zone */
  tbody::after {
    content: "";
    display: block;
    height: 30px;

    ${isMobileStyleMixin(css`
      height: 15px;
    `)}
  }
  > thead > tr > th {
    font-weight: unset;
 }
`;

const WeekdayHeader = styled('thead')`
  color: ${({ theme }) => theme.datepicker.weekColor};
  font-size: ${({ theme }) => theme.datepicker.weekSize};
  text-align: center;

  ${isMobileStyleMixin(css`
    font-size: ${({ theme }) => theme.datepicker.mobile.weekSize};
  `)}
`;

const HeaderRow = styled('tr')`
  padding: unset;
`;

const HeaderCell = styled('th')`
  padding: unset;
`;

export interface CalendarMonthProps {
  displayMonth: ISODate;
}

const CalendarMonth = ({ displayMonth }: CalendarMonthProps): JSX.Element => {
  const { locale } = useDatePicker();

  const weekdays = getWeekdays(locale);
  const weeks = getWeeks(displayMonth, locale);

  return (
    <Table cellSpacing="0" cellPadding="0" role="grid">
      <WeekdayHeader role="rowgroup">
        <HeaderRow role="row">
          {weekdays.map((weekday, i) => (
            <HeaderCell
              key={`${weekday}`}
              scope="col"
              role="columnheader"
            >
              <FormatWeekday weekday={weekday} />
            </HeaderCell>
          ))}
        </HeaderRow>
      </WeekdayHeader>
      <tbody>
        {Object.keys(weeks).map(weekNumber => (
          <CalendarWeek
            key={weeks[weekNumber].toString()}
            dates={weeks[weekNumber]}
            displayMonth={displayMonth}
            locale={locale}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default CalendarMonth;
