/* eslint-disable react/forbid-prop-types */
/* eslint id-length: ["error", { "exceptions": ["as"] }] */
import React, {
  createRef,
  PureComponent,
  ReactNode,
  RefObject,
} from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { getElementFromRef, MergeElementProps, PreventScroll } from '@amzn/storm-ui-utils';
import Portal from '../Portal';
import { Overlay } from './Overlay';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import { OnCloseContext, TypeContext } from './Context';
import {
  MaxSizeProp,
  SheetSide,
  SheetPaddingOptionsPlusUndefined,
} from './types';
import { SurfaceType } from '../Surface/types';

/* SSR workaround for nodejs, things like Element don't exists in nodejs. */
const NodeSafeElement = (typeof Element !== 'undefined' ? Element : Object) as typeof Element;

export interface SheetOverrideProps {
  /**
   * Sets the Sheet background color.
   * @defaultValue `"light"`
   */
  type?: SurfaceType;
}

export interface SheetProps extends TaktProps, Omit<MergeElementProps<'div', SheetOverrideProps>, 'ref'> {
  /**
   * The React nodes/nodes that are rendered as content of the Sheet.
   */
  children: ReactNode;
  /**
   * This should correspond to the ROOT level dom node id that the modal will render into.
   * When this prop is not explicitly passed a value, the modal will mount the bottom sheet into
   * the div with id matching the portalElementId property in the popover object of the theme.
   * @defaultValue `undefined`
   */
  sheetElementId?: string;
  /**
   * This configures the close button for the bottom sheet. Setting the type property will display
   * the corresponding type of close button above the bottom sheet. A type of icon will render the
   * "X" icon. A type of message will render the string value of the label property. The label is
   * read by a screen reader when the close button is in focus. Both type and label are required.
   */
  closeButton: {
    type: 'icon' | 'message',
    label: string
  };
  /**
   * Props that are applied to the CloseButton component.
   * @defaultValue `undefined`
   */
  closeButtonProps?: Record<string, unknown>;
  /**
   * Fired when any close action occurs, such as: clicking on the background, hitting the close
   * button, or hitting escape.
   * @defaultValue `undefined`
   */
  onClose?: () => void;
  /**
   * Control open / close of bottom sheet. It will animate automatically on change.
   * @defaultValue `false`
   */
  isOpen?: boolean;
  /**
   * Pass a ref to an element here to use as the toggle element. Focus will move back to this
   * element when the bottom sheet closes.
   * @defaultValue `undefined`
   */
  toggleEl?: RefObject<HTMLElement | undefined> | HTMLElement;
  /**
   * Configure the side of the screen the sheet will appear.
   * @defaultValue `"bottom"`
   */
  side?: SheetSide;
  /**
   * To show or hide the close button.
   * @defaultValue `true`
   */
  withCloseButton?: boolean;
  /**
   * Sets the padding around the sheet's content.
   * @defaultValue `"base"`
   */
  padding?: SheetPaddingOptionsPlusUndefined;
  /**
   * When set to `viewport`, the Sheet will fill up to 100% of the viewport width for `start` and
   * `end` sides or up to 100% of the viewport height for `bottom` side. This is useful for
   * applications that control the Sheet size based on the width or height of the content.
   *
   * When set to `default` the Sheet will fill up to 80% of the viewport width for `start` and
   * `end` sides or up to 65% of the viewport height for `bottom` side.
   * @defaultValue `default`
   */
  maxSize?: MaxSizeProp;
  /**
  * Boolean value to leave child component mounted when sheet is exited/closed.
  * @defaultValue `false`
  */
  preventUnmountOnClose?: boolean;
  /**
   * @defaultValue `200`
   */
  transitionDuration?: number;
}

const defaultTransitionDuration = 200; // milliseconds

class Sheet extends PureComponent<SheetProps> {
  public static propTypes = {
    /**
     * The React nodes/nodes that are rendered as content of the Sheet.
     */
    children: PropTypes.node.isRequired,
    /**
     * This configures the close button for the bottom sheet. Setting the type property will display
     * the corresponding type of close button above the bottom sheet. A type of icon will render the
     * "X" icon. A type of message will render the string value of the label property. The label is
     * read by a screen reader when the close button is in focus. Both type and label are required.
     */
    closeButton: PropTypes.shape({
      type: PropTypes.oneOf(['icon', 'message']).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * Props that are applied to the CloseButton component.
     */
    closeButtonProps: PropTypes.objectOf(PropTypes.any),
    /**
     * To show or hide the close button.
     */
    withCloseButton: PropTypes.bool,
    /**
     * A passthrough prop that allows the Sheet Shroud to be customized by wrapping the
     * Sheet in a styled-component.
     */
    className: PropTypes.string,
    /**
     * This should correspond to the ROOT level dom node id that the modal will render into.
     * When this prop is not explicitly passed a value, the modal will mount the bottom sheet into
     * the div with id matching the portalElementId property in the popover object of the theme.
     */
    sheetElementId: PropTypes.string,
    /**
     * Control open / close of bottom sheet. It will animate automatically on change.
     */
    isOpen: PropTypes.bool,
    /**
     * Fired when any close action occurs, such as: clicking on the background, hitting the close
     * button, or hitting escape.
     */
    onClose: PropTypes.func,
    /**
     * Pass a ref to an element here to use as the toggle element. Focus will move back to this
     * element when the bottom sheet closes.
     */
    toggleEl: PropTypes.oneOfType([
      PropTypes.instanceOf(NodeSafeElement),
      PropTypes.shape({ current: PropTypes.instanceOf(NodeSafeElement) }),
    ]),
    /**
     * Sets the Sheet background color.
     */
    type: PropTypes.oneOf(['light', 'dark', 'blue']),
    /**
     * Configure the side of the screen the sheet will appear.
     */
    side: PropTypes.oneOf(['bottom', 'start', 'end']),
    /**
     * Sets the padding around the sheet's content.
     */
    padding: PropTypes.oneOf([
      'none',
      'micro',
      'mini',
      'small',
      'base',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
    ]),
    /**
     * When set to `viewport`, the Sheet will fill up to 100% of the viewport width for `start` and
     * `end` sides or up to 100% of the viewport height for `bottom` side. This is useful for
     * applications that control the Sheet size based on the width or height of the content.
     *
     * When set to `default` the Sheet will fill up to 80% of the viewport width for `start` and
     * `end` sides or up to 65% of the viewport height for `bottom` side.
     */
    maxSize: PropTypes.oneOf(['viewport', 'default']),
    /**
    * Boolean value to leave child component mounted when sheet is exited/closed.
    */
    preventUnmountOnClose: PropTypes.bool,
    header: PropTypes.node,
    transitionDuration: PropTypes.number,
  }

  public static defaultProps = {
    sheetElementId: undefined,
    className: '',
    closeButtonProps: undefined,
    isOpen: false,
    onClose: undefined,
    padding: 'base',
    side: 'bottom',
    toggleEl: undefined,
    type: 'light',
    withCloseButton: true,
    maxSize: 'default',
    preventUnmountOnClose: false,
    header: undefined,
    transitionDuration: defaultTransitionDuration,
  }

  modalContainerRef: RefObject<HTMLDivElement>;

  constructor(props: SheetProps) {
    super(props);
    this.modalContainerRef = createRef<HTMLDivElement>();
  }

  componentWillUnmount(): void {
    this.teardown();
  }

  teardown = (): void => {
    const { toggleEl } = this.props;
    // Bring focus back to the toggle element
    if (toggleEl !== undefined) {
      const element = getElementFromRef(toggleEl);
      if (element?.focus) {
        element.focus();
      }
    }
  }

  render(): JSX.Element {
    const {
      sheetElementId,
      children,
      className,
      closeButton,
      closeButtonProps,
      isOpen,
      onClose,
      padding,
      side,
      type,
      withCloseButton,
      maxSize,
      taktId,
      taktValue,
      preventUnmountOnClose,
      transitionDuration = defaultTransitionDuration,
      ...rest
    } = this.props;

    return (
      <Transition
        timeout={transitionDuration}
        appear
        in={isOpen}
        onExit={this.teardown}
        nodeRef={this.modalContainerRef}
      >
        {state => {
          if (state === 'exited' && !preventUnmountOnClose) return null;
          return (
            <Portal containerId={sheetElementId}>
              <PreventScroll isScrollEnabled={state === 'exited' && preventUnmountOnClose}>
                <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('sheet')}>
                  <TypeContext.Provider value={type}>
                    <OnCloseContext.Provider value={onClose}>
                      <Overlay
                        {...rest}
                        className={className}
                        closeButton={closeButton}
                        closeButtonProps={closeButtonProps}
                        modalContainerRef={this.modalContainerRef}
                        onClose={onClose}
                        padding={padding}
                        state={state}
                        side={side}
                        type={type}
                        withCloseButton={withCloseButton}
                        maxSize={maxSize}
                      >
                        {children}
                      </Overlay>
                    </OnCloseContext.Provider>
                  </TypeContext.Provider>
                </TaktIdProvider>
              </PreventScroll>
            </Portal>
          );
        }}
      </Transition>
    );
  }
}

export default Sheet;
