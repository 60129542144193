import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import InlineMessage from '../Text/InlineMessage';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import { StatusType } from './types';

export const inputFocusStyles = css`
  ${focusOutline}
  /* Overriding offset from standard outline for inputs at UX direction */
  outline-offset: 1px;
`;

export interface inputStylesProps {
  $statusType?: StatusType;
}

export const inputStyles = css<inputStylesProps>`
  ${({ theme }) => theme.typography.base};
  background: unset;
  border: unset;
  border-color: unset;
  box-shadow: unset;
  border-radius: ${({ theme }) => theme.form.input.radius};
  color: ${({ theme }) => theme.form.input.color};
  position: relative;
  width: 100%;
  align-self: center;

  /* Unsetting the transition because of AUI */
  transition: unset;

  /* Override the top and bottom margin in Safari */
  margin-block-start: 0;
  margin-block-end: 0;
  min-width: 0;
  appearance: none;
  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    font-size: ${({ theme }) => theme.form.input.mobile.fontSize};
  `)}

  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,background-color 100ms ease,border-color 100ms ease, box-shadow 100ms ease;
  }

  ::placeholder {
    color: ${({ theme }) => theme.form.input.placeholderColor};
    opacity: 1;
  }

  :disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    color: ${({ theme }) => theme.form.input.disabled.color};
    box-shadow: none;
    border-color: ${({ theme }) => theme.form.input.disabled.borderColor};
    background-color: ${({ theme }) => theme.form.input.disabled.bg};
  }

  :focus, :focus-within {
    outline: none;
  }

  &&[type="color"]{
    align-self: auto;
  }
`;

export interface StyleInputMixinProps extends inputStylesProps {
  prefix?: ReactNode | (() => ReactNode);
  suffix?: ReactNode | (() => ReactNode);
  $ignoreTextDirection?: boolean;
}

export const styleInputMixin = css<StyleInputMixinProps>`
  ${inputStyles}
  box-sizing: border-box;
  min-height: calc(${({ theme }) => theme.form.input.height} - 6px);
  height: auto;
  padding: 0 calc(${({ theme }) => theme.spacing.small} - 2px);

  /* clears the 'X' from Internet Explorer */
  ::-ms-clear {  display: none; width : 0; height: 0; }
  ::-ms-reveal {  display: none; width : 0; height: 0; }

  /* clears the 'X' from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration { display: none; }
  ::-webkit-calendar-picker-indicator { background: none; }

  :focus {
    background-color: ${({ theme }) => theme.palette.gray[0]};
  }

  ${({
    prefix,
    suffix,
    theme,
    $ignoreTextDirection,
  }) => ((prefix && !suffix) && css`
    /*! @noflip */ border-radius: 0 ${theme.form.input.radius} ${theme.form.input.radius} 0;

    ${!$ignoreTextDirection && css`
      /*! @noflip */
      [dir="rtl"] && {
        /*! @noflip */ border-radius: ${theme.form.input.radius} 0 0 ${theme.form.input.radius};
      }
    `}

    border-inline-start: none;
    padding-inline-start: 0px;
  `)}

  ${({
    prefix,
    suffix,
    theme,
    $ignoreTextDirection,
  }) => ((!prefix && suffix) && css`
    /*! @noflip */ border-radius: ${theme.form.input.radius} 0 0 ${theme.form.input.radius};

    ${!$ignoreTextDirection && css`
      /*! @noflip */
      [dir="rtl"] && {
        /*! @noflip */ border-radius: 0 ${theme.form.input.radius} ${theme.form.input.radius} 0;
      }
    `}

    border-inline-end: none;
    padding-inline-end: 0px;
  `)}

  ${({ prefix, suffix }) => ((prefix && suffix) && css`
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    border-radius: 0;
  `)}

  ${isMobile(css`
    min-height: calc(${({ theme }) => theme.form.input.mobile.height} - 6px);
  `)}
`;

export const InputValidationMessage = styled(InlineMessage)`
  margin-top: 0;
  margin-bottom: 0;
`;
InputValidationMessage.displayName = 'InputValidationMessage';

export const InputGroup = styled(({
  className,
  children,
  inputGroupRef,
  dir,
}): JSX.Element => (
  <div dir={dir} className={className} ref={inputGroupRef}>{children}</div>
))`
  background: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.bg : theme.form.input.bg)};
  border: ${({ theme }) => theme.form.input.border};
  border-color: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.borderColor : theme.form.input.borderColor)};
  box-shadow: ${({ theme }) => theme.form.input.boxShadow};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.form.input.radius};
  color: ${({ theme, disabled }) => (disabled ? theme.form.input.disabled.color : theme.form.input.color)};
  min-width: ${({ fullWidth, theme }) => (fullWidth ? 'auto' : theme.form.input.minWidth)};
  padding: 2px;
  display: flex;

  ${({ $statusType, theme }) => ($statusType === 'error' && css`
    border-color: ${theme.form.input.error.borderColor};
    box-shadow: ${theme.form.input.error.boxShadow};
  `)}

  ${({ $statusType, theme }) => ($statusType === 'warning' && css`
    border-color: ${theme.form.input.warning.borderColor};
  `)}

  ${({ $statusType, theme }) => ($statusType === 'success' && css`
    border-color: ${theme.form.input.success.borderColor};
  `)}

  :focus-within {
    ${inputFocusStyles}
  }

  > input {
    /* needed for AUI overriding */
    ${styleInputMixin}
  }
`;
InputGroup.displayName = 'InputGroup';
