/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Anuluj"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Zapisz"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Niestandardowe"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Wybierz pojedynczy dzień"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Wybierz miesiąc"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Wybierz tydzień"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Ostatnie 30 dni"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Ostatnie 7 dni"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Ostatni miesiąc"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Ostatni tydzień"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Od początku do końca"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Ten tydzień"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Dzisiaj"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Od początku roku"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Wczoraj"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
