import React, {
  PureComponent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { deepMerge } from '@amzn/storm-ui-utils';
import ConfigObserver, { IStormConfig } from './ConfigObserver';
import { Theme as AnyTheme } from './themes/types';
import defaultTheme, { Theme as ThemeDefaultType } from './themes/default';
import evolutionTheme from './themes/evolution';

export type ThemeType = ThemeDefaultType & AnyTheme;

export interface ThemeProviderProps {
  /**
   * @defaultValue `defaultTheme`
   */
  theme?: ThemeType | ((theme: ThemeType) => ThemeType);
  children: ReactNode;
}

export default class ThemeProvider extends PureComponent<ThemeProviderProps> {
  static propTypes = {
    children: PT.node.isRequired,
    theme: PT.oneOfType([
      PT.objectOf(PT.any),
      PT.func,
    ]),
  }

  static defaultProps = {
    theme: defaultTheme,
  }

  isEvolutionTheme: boolean

  theme?: ThemeType | ((theme: ThemeType) => ThemeType)

  constructor(props :ThemeProviderProps) {
    super(props);

    this.isEvolutionTheme = false;
    this.theme = props.theme;
  }

  render(): JSX.Element {
    const { theme, children } = this.props;

    return (
      <ConfigObserver>
        {(config: IStormConfig) => {
          if (typeof theme === 'function') {
            this.theme = theme;
          }

          if (this.isEvolutionTheme !== config.evolutionTheme) {
            this.isEvolutionTheme = config.evolutionTheme;

            if (config.evolutionTheme && typeof theme === 'object') {
              this.theme = deepMerge({}, theme, evolutionTheme) as ThemeType;
            } else {
              this.theme = theme;
            }
          }

          return (
            <StyledThemeProvider theme={this.theme}>
              {children}
            </StyledThemeProvider>
          );
        }}
      </ConfigObserver>
    );
  }
}
