import React, { RefObject } from 'react';
import { useDropdownIsMobile } from './DropdownIsMobileContext';
import DesktopContainer, { DesktopContainerProps } from './DropdownDesktopContainer';
import MobileContainer, { MobileContainerProps } from './DropdownMobileContainer';

export interface DropdownContainerProps extends MobileContainerProps, DesktopContainerProps {
  isOpen: boolean;
  trigger: DesktopContainerProps['trigger'];
  onClose: () => void;
  name: string;
  anchorEl: MobileContainerProps['anchorEl'];
  onChange: MobileContainerProps['onChange'];
  popperRef?: RefObject<HTMLDivElement>;
  openDirection?: 'bottom' | 'top';
  forceDirection?: boolean;
}

function DropdownContainer({
  isOpen,
  onClose,
  secondaryViewCloseButtonLabel,
  children,
  shadowRoot,
  trigger,
  anchorEl,
  preRender,
  postRender,
  selectedValue,
  onChange,
  noWrap,
  name,
  focusSelected,
  forceOpen,
  popperRef,
  openDirection,
  forceDirection,
}: DropdownContainerProps) {
  const isMobile = useDropdownIsMobile();

  if (isMobile) {
    return (
      <MobileContainer
        isOpen={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        secondaryViewCloseButtonLabel={secondaryViewCloseButtonLabel}
        preRender={preRender}
        postRender={postRender}
        trigger={trigger}
        name={name}
        selectedValue={selectedValue}
        onChange={onChange}
      >
        {children}
      </MobileContainer>
    );
  }

  return (
    <DesktopContainer
      isOpen={isOpen}
      onClose={onClose}
      shadowRoot={shadowRoot}
      trigger={trigger}
      preRender={preRender}
      selectedValue={selectedValue}
      onChange={onChange}
      noWrap={noWrap}
      postRender={postRender}
      focusSelected={focusSelected}
      anchorEl={anchorEl}
      forceOpen={forceOpen}
      popperRef={popperRef}
      openDirection={openDirection}
      forceDirection={forceDirection}
    >
      {children}
    </DesktopContainer>
  );
}

export default DropdownContainer;
