import React, {
  createContext, RefObject, FC,
} from 'react';
import PT, { Validator } from 'prop-types';

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

export type PortalRef = RefObject<HTMLElement>;
const PortalContext = createContext<PortalRef | undefined>(undefined);

export interface PortalContextProviderProps {
  /**
   * A react ref object to a DOM element that will contain the popover/popper.
   * @defaultValue `undefined`
   */
  portalElementRef?: PortalRef;
}

/**
 * The PortalContextProvider allows applications to specify a element the descendants popover/popper
 *  will be rended into.
 */
export const PortalContextProvider: FC<React.PropsWithChildren<PortalContextProviderProps>> = ({
  portalElementRef,
  children,
}) => (
  <PortalContext.Provider value={portalElementRef}>
    {children}
  </PortalContext.Provider>
);

PortalContextProvider.displayName = 'PortalContextProvider';

PortalContextProvider.propTypes = {
  /**
   * A react ref object to a DOM element that will contain the popover/popper.
   * @type {object}
   */
  portalElementRef: PT.shape({
    current: (PT.instanceOf(NodeSafeHTMLElement)),
  }) as Validator<PortalRef>,
};

PortalContextProvider.defaultProps = {
  portalElementRef: undefined,
};

export default PortalContext;
