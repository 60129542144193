import React, {
  useCallback, useState, useRef, MouseEvent, KeyboardEvent,
} from 'react';
import { useTheme } from 'styled-components';
import {
  getFocusableElements,
  trapTabFocus,
  keyboardKeynames as keys,
} from '@amzn/storm-ui-utils';
import useInlinePopper from '../Popper/useInlinePopper';
import { PopperTrigger } from '../Popper/Popper.styles';
import { Theme } from '../theme';
import { getPlacement } from './utils';
import type { TooltipContainerProps } from './types';

function InteractiveContainer({
  align,
  children,
  forceOpen,
  inert,
  onClick,
  onCloseButtonClick,
  position,
  spacing,
  trigger,
  withCloseButton,
}: TooltipContainerProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(forceOpen);
  const [showFocus, setShowFocus] = useState(false);
  const timeout = useRef<number | undefined>(undefined);

  const placement = getPlacement(align, position);

  const {
    attributes,
    styles,
    triggerElement,
    popperElement,
    setArrowElement,
    setPopperElement,
    setTriggerElement,
    update,
  } = useInlinePopper({
    offsetDistance: spacing,
    placement,
    enableFlip: true,
    withArrow: true,
    rootBoundary: 'viewport',
  });

  /**
   * Handles mouse clicks on the <Tooltip /> close button.
   */
  const handleCloseClicked = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);

    // call the callback function passed via props.
    onCloseButtonClick?.(event);
  }, [onCloseButtonClick]);

  /**
   * Handles the mouse hovering over the <Tooltip /> trigger.
   */
  const handleMouseEnterTrigger = useCallback(() => {
    if (inert) {
      return;
    }
    clearTimeout(timeout.current);
    setShowFocus(false);
    timeout.current = window.setTimeout(() => {
      setIsOpen(true);
    }, (theme as Theme).tooltip.mouseEnterTimeout);
  }, [inert, theme]);

  /**
   * Handles the mouse leaving the <Tooltip /> trigger.
   */
  const handleMouseLeaveTrigger = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      setIsOpen(false);
    }, (theme as Theme).tooltip.mouseLeaveTimeout);
  }, [theme]);

  /**
   * Handles keyboard input on the <Tooltip /> trigger.
   *
   * Should open the <Tooltip /> if Enter or Space are clicked and focus should be visible.
   */
  const handleKeyDownTrigger = useCallback((event: KeyboardEvent<HTMLSpanElement>) => {
    if (inert) {
      return;
    }

    const { key } = event;
    if (key === keys.ENTER || key === keys.SPACE) {
      clearTimeout(timeout.current);
      event.preventDefault();
      setShowFocus(true);
      setIsOpen(true);
    }
  }, [inert]);

  /**
   * Handles the mouse hovering over the <Tooltip /> surface. It should
   * stop the <Tooltip /> from closing.
   *
   * Because it is mouse input, focus should not be visible.
   */
  const handleMouseEnterTooltip = useCallback(() => {
    // clear timeout created when the mouse left the <Tooltip /> trigger
    clearTimeout(timeout.current);
  }, []);

  /**
   * Handles the mouse leaving the <Tooltip /> surface. It should
   * close the <Tooltip /> unless it is being forced open.
   *
   * Because it is mouse input, focus should not be visible.
   */
  const handleMouseLeaveTooltip = useCallback(() => {
    if (!forceOpen) {
      setIsOpen(false);
    }
  }, [forceOpen]);

  /**
   * Handles keyboard KeyDown input when the <Tooltip /> surface is in focus.
   */
  const handleKeyDownTooltip = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (popperElement) {
      // Trap the focus of the browser inside the popper element. Keep the user in this element
      // and allow them to tab through content until the user closes the <Tooltip />.
      trapTabFocus(event, popperElement, true);
    }

    if (key === keys.SPACE) {
      event.preventDefault();
    }
  }, [popperElement]);

  /**
   * Handles keyboard input when the <Tooltip /> surface is in focus.
   */
  const handleKeyUpTooltip = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    // Close the <Tooltip /> if the Escape key is pressed
    if (key === keys.ESCAPE) {
      event.stopPropagation();
      setIsOpen(false);
    }
  }, []);

  /**
   * Catches the click event from the <Tooltip /> trigger and only
   * call the 'onClick' callback if it was passed.
   */
  const preventTriggerClickThrough = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  /**
   * Function called once the <Transition /> component is in the entered state.
   *
   * If we are showing focus because we have had some keyboard input, we should set
   * focus to the Popper element.
   */
  const handleTooltipEntered = useCallback(() => {
    if (popperElement) {
      if (showFocus) {
        popperElement.focus();
      }
    }
  }, [showFocus, popperElement]);

  const handleTooltipEntering = useCallback((node: unknown, isAppearing: boolean) => {
    if (!isAppearing && update !== null) {
      update();
    }
  }, [update]);

  /**
   * Function called once the <Transition /> component is in the exited state.
   *
   * If we are showing focus because we have had some keyboard input, we should
   * return focus back to the <Trigger /> element.
   */
  const handleTooltipExited = useCallback(() => {
    if (showFocus) {
      const focusableEls = getFocusableElements(triggerElement);
      if (focusableEls.length > 0) { // e.g. tooltip trigger element has a span wrapper
        focusableEls[0].focus();
      }
    }
  }, [triggerElement, showFocus]);

  return (
    <>
      <PopperTrigger
        onClick={preventTriggerClickThrough}
        onKeyDown={handleKeyDownTrigger}
        ref={setTriggerElement}
      >
        {React.cloneElement(trigger, {
          onMouseEnter: handleMouseEnterTrigger,
          onMouseLeave: handleMouseLeaveTrigger,
        })}
      </PopperTrigger>
      {children({
        attributes,
        focusableBody: true,
        handleCloseClicked,
        handleKeyDown: handleKeyDownTooltip,
        handleKeyUp: handleKeyUpTooltip,
        handleMouseEnter: handleMouseEnterTooltip,
        handleMouseLeave: handleMouseLeaveTooltip,
        handlePopoverEntered: handleTooltipEntered,
        handlePopoverEntering: handleTooltipEntering,
        handlePopoverExited: handleTooltipExited,
        isOpen,
        setArrowElement,
        setPopperElement,
        styles,
        withCloseButton,
      })}
    </>
  );
}

export default InteractiveContainer;
