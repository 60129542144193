import styled from 'styled-components';

import Text from '../../Text';
import DropdownDivider from '../../Dropdown/DropdownDivider';

export const StyledSelectOptionGroupHeader = styled(Text).attrs({
  type: 'span',
  textColor: 'tertiary',
})`
    userSelect: none;
    padding: 1px 11px 0px 11px;
    font-weight: bold;
    font-size: ${({ theme }) => theme.typography.size.base};
    color: ${({ theme }) => theme.dropdown.group.headingColor};
    text-transform: none;
`;

StyledSelectOptionGroupHeader.displayName = 'StyledSelectOptionGroupHeader';

export const SelectOptionGroupContainer = styled.div`
  :first-child ${DropdownDivider} {
    display: none;
  };
  text-align: start;
`;
