import React, {
  PureComponent,
  ReactNode,
  ReactNodeArray,
  createRef,
  MouseEvent,
  MouseEventHandler,
  RefObject,
} from 'react';
import PT from 'prop-types';
import { ellipsisH } from '@amzn/storm-ui-icons-v3';
import { composeRefs, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import {
  SplitButtonWrapper,
  ButtonGroupFrame,
  StyledMainButton,
  StyledDropdownButton,
  HiddenLabelText,
} from './SplitButton.styles';
import Icon from '../../Icon';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';
import { LoadingIcon } from '../Button';
import SplitButtonPopper from './SplitButtonPopper';

export type Offset = {
  x?: number | string,
  y?: number | string,
}

interface SplitButtonState {
  isPopoverVisible: boolean;
}

export interface SplitButtonProps extends TaktProps, MergeElementProps<'button'> {
  /**
   * @defaultValue `false`
   */
  small?: boolean;
  /**
   * @defaultValue `false`
   */
  fullWidth?: boolean;
  /**
   * @defaultValue `false`
   */
  primary?: boolean;
  /**
   * @defaultValue `false`
   */
  disabled?: boolean;
  children: ReactNode | ReactNodeArray;
  mainButtonLabel: ReactNode;
  mainButtonOnClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * Use to offset the overlay from the calculated position.
   * @defaultValue `{ y: 2, x: 1 }`
   */
  offset: Offset | null;
  /**
   * Hidden label for dropdown. Can be used to pass in localized string.
   * @defaultValue `"Toggle Dropdown"`
   */
  dropdownLabel?: string;
  /**
   * Main button and dropdown button will have an id based off of this.
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * To indicate the button is loading.
   * @defaultValue `false`
   */
  loading?: boolean;
  /**
   * Substitute for Button's body when loading is true.
   * @defaultValue `undefined`
   */
  loadingLabel?: string;
}

class SplitButton extends PureComponent<SplitButtonProps, SplitButtonState> {
  static propTypes = {
    small: PT.bool,
    fullWidth: PT.bool,
    primary: PT.bool,
    disabled: PT.bool,
    mainButtonLabel: PT.node.isRequired,
    mainButtonOnClick: PT.func.isRequired,
    /**
     * Use to offset the overlay from the calculated position.
     */
    offset: PT.shape({ x: PT.number, y: PT.number }),
    children: PT.node.isRequired,
    /**
     * Hidden label for dropdown. Can be used to pass in localized string.
     */
    dropdownLabel: PT.string,
    /**
     * Main button and dropdown button will have an id based off of this.
     */
    id: PT.string,
    loading: PT.bool,
    loadingLabel: PT.string,
  }

  static defaultProps = {
    small: false,
    fullWidth: false,
    primary: false,
    disabled: false,
    offset: { y: 2, x: 1 },
    dropdownLabel: 'Toggle Dropdown',
    id: undefined,
    loading: false,
    loadingLabel: undefined,
  }

  private splitDropdownButtonRef = createRef<HTMLButtonElement>();

  private anchorRef = createRef<HTMLDivElement>();

  constructor(props: SplitButtonProps) {
    super(props);

    this.state = {
      isPopoverVisible: false,
    };
  }

  onCloseHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    if (event
      && event.target instanceof Node
      && this.splitDropdownButtonRef
      && !this.splitDropdownButtonRef.current?.contains(event.target)) {
      this.togglePopoverView();
    }
  }

  togglePopoverView = (): void => {
    this.setState(({ isPopoverVisible: prevIsPopoverVisible }) => ({
      isPopoverVisible: !prevIsPopoverVisible,
    }));
  }

  render(): JSX.Element {
    const {
      mainButtonLabel,
      disabled,
      small,
      offset,
      children,
      mainButtonOnClick,
      dropdownLabel,
      fullWidth,
      primary,
      id,
      taktId,
      taktValue,
      loading,
      loadingLabel,
      ...rest
    } = this.props;

    const { isPopoverVisible } = this.state;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('split-button')}>
        {({ getDataTaktAttributes }) => (
          <SplitButtonWrapper>
            <SplitButtonPopper
              isOpen={isPopoverVisible}
              onClose={this.togglePopoverView}
              onPopoverClose={this.onCloseHandler}
              $minWidth={
                this.anchorRef && (this.anchorRef.current?.getBoundingClientRect().width)
              }
              focusedAnchorEl={this.splitDropdownButtonRef}
              offsetDistance={Number(offset?.y ?? 0) - 2}
              offsetSkidding={(Number(offset?.x ?? 0))}
              trigger={triggerProps => (
                <ButtonGroupFrame
                  ref={composeRefs(this.anchorRef, triggerProps?.setTriggerElement) as RefObject<HTMLDivElement>}
                >
                  <StyledMainButton
                    {...getDataTaktAttributes()}
                    {...rest}
                    id={id}
                    type="button"
                    disabled={disabled}
                    $small={small}
                    $primary={primary}
                    $fullWidth={fullWidth}
                    onClick={loading ? undefined : mainButtonOnClick}
                    $loading={loading}
                    {...(loading) && { 'aria-live': 'assertive' }}
                    {...(loading) && { 'aria-disabled': true }}
                  >
                    {loading && <LoadingIcon />}
                    <>{(loading && loadingLabel) ? loadingLabel : mainButtonLabel}</>
                  </StyledMainButton>
                  <StyledDropdownButton
                    {...getDataTaktAttributes({ taktIdSuffix: 'dropdown' })}
                    {...rest}
                    id={typeof id === 'string' ? `${id}-dropdown` : undefined}
                    disabled={disabled || loading}
                    $small={small}
                    $fullWidth={fullWidth}
                    $primary={primary}
                    onClick={this.togglePopoverView}
                    aria-haspopup="true"
                    aria-expanded={isPopoverVisible}
                    ref={this.splitDropdownButtonRef}
                  >
                    <HiddenLabelText>
                      {dropdownLabel}
                    </HiddenLabelText>
                    <Icon type={ellipsisH} />
                  </StyledDropdownButton>
                </ButtonGroupFrame>

              )}
            >
              {children}
            </SplitButtonPopper>
          </SplitButtonWrapper>
        )}
      </TaktIdConsumer>
    );
  }
}

export default SplitButton;
