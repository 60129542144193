import styled from 'styled-components';
import { TransitionStatus } from '../types/react-transition-group/Transition';

export interface ViewProps {
  $fadeDuration: number;
  $fadeState: TransitionStatus;
}

const View = styled('div')<ViewProps>`
  background: ${({ theme }) => theme.palette.alabaster};
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  @media (prefers-reduced-motion: no-preference) {
    transition: ${({ $fadeDuration }) => `opacity ${$fadeDuration}ms ease-in-out`};
  }
  opacity: ${({ $fadeState }) => {
    if ($fadeState === 'entered') {
      return 1;
    }

    return 0;
  }};

  z-index: ${({ theme }) => theme.modal.zIndex};
`;
View.displayName = 'View';

export default View;
