import { PureComponent } from 'react';
import PT from 'prop-types';

import parse24HourTime from './time-utils/parse24HourTime';
import compose24HourTime from './time-utils/compose24HourTime';

interface TimeInputStateState {
  hour?: string | null,
  minute?: string | null,
  meridiem?: string | null,
}

interface TimeInputStateChildProps extends TimeInputStateState {
  onChangeHour: (hour?: string | null) => void,
  onChangeMinute: (minute?: string | null) => void,
  onChangeMeridiem: (meridiem?: string | null) => void,
}

export interface TimeInputStateProps {
  children: (params: TimeInputStateChildProps) => JSX.Element;
  onChange: (arg0: string) => void;
  value?: string;
}

export default class TimeInputState extends PureComponent<TimeInputStateProps, TimeInputStateState> {
  static propTypes = {
    children: PT.func.isRequired,
    onChange: PT.func.isRequired,
    value: PT.string.isRequired,
  }

  constructor(props: TimeInputStateProps) {
    super(props);

    const {
      value,
    } = props;

    const {
      hour,
      minute,
      meridiem,
    } = parse24HourTime(value);

    this.state = {
      hour,
      minute,
      meridiem,
    };
  }

  onChangeHour = (hour?: string | null): void => {
    const {
      onChange,
    } = this.props;

    const {
      minute,
      meridiem,
    } = this.state;

    onChange(compose24HourTime({
      hour,
      minute,
      meridiem,
    }));
    this.setState({ hour });
  }

  onChangeMinute = (minute?: string | null): void => {
    const {
      onChange,
    } = this.props;

    const {
      hour,
      meridiem,
    } = this.state;

    onChange(compose24HourTime({
      hour,
      minute,
      meridiem,
    }));

    this.setState({ minute });
  }

  onChangeMeridiem = (meridiem?: string | null): void => {
    const {
      onChange,
    } = this.props;

    const {
      hour,
      minute,
    } = this.state;

    onChange(compose24HourTime({
      hour,
      minute,
      meridiem,
    }));

    this.setState({ meridiem });
  }

  render(): JSX.Element {
    const {
      value,
      children,
    } = this.props;

    const {
      hour: stateHour,
      minute: stateMinute,
      meridiem: stateMeridiem,
    } = this.state;

    const {
      hour: propsHour,
      minute: propsMinute,
      meridiem: propsMeridiem,
    } = parse24HourTime(value);

    return children({
      hour: propsHour || stateHour,
      minute: propsMinute || stateMinute,
      meridiem: propsMeridiem || stateMeridiem,
      onChangeHour: this.onChangeHour,
      onChangeMinute: this.onChangeMinute,
      onChangeMeridiem: this.onChangeMeridiem,
    });
  }
}
