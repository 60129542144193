import React, {
  ChangeEvent,
  DragEvent,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useRef,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { noop, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import FileUploadContext from '../FileUploadContext';
import UploadDropZone from './FileUploadDropZone';

export interface FileUploadDropZoneProps extends Omit<MergeElementProps<'input'>, 'accept'|'multiple'|'name'|'ref'|'tabIndex'|'type'|'onChange'> {
  /**
   * Specifies allowed file types
   * @defaultValue `undefined`
   * */
  accept?: string[];
  /**
   * Rendered list of upload descriptions
   * @defaultValue `undefined`
   * */
  description?: ReactNode;
  /**
   * Specifies if the control is disabled
   * @defaultValue `false`
   * */
  disabled?: boolean;
  /**
   * Instructional text displayed within drop zone
   * @defaultValue `undefined`
   * */
  heading?: ReactNode;
  /** ID for drop zone */
  dropzoneId: string;
  /**
   * Text displayed in Alert component when an error occurs
   * @defaultValue `""`
   * */
  errorText?: string;
  /**
   * Specifies if the control takes full width of parent element
   * @defaultValue `false`
   * */
  fullWidth?: boolean;
  /** ID for file input */
  inputId: string;
  /**
   * Specifies that the control will accept one or more values
   * @defaultValue `false`
   * */
  multiple?: boolean;
  /**
   * Name of the form control
   * @defaultValue `""`
   * */
  name?: string;
  /**
   * Callback function called when file(s) are selected from file system
   * @defaultValue `undefined`
   * */
  onChange?: (event : SyntheticEvent, files: File[]) => void;
  /**
   * Callback function called when Alert close button is selected
   * @defaultValue `() => undefined`
   * */
  onCloseAlert?: () => void;
}

const Input = styled('input')`
  display: none;
`;

const FileUploadDropZone: FunctionComponent<FileUploadDropZoneProps> = ({
  accept,
  children,
  description,
  disabled,
  dropzoneId,
  heading,
  errorText,
  fullWidth,
  inputId,
  multiple,
  name,
  onChange,
  onCloseAlert,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement> & DragEvent<HTMLDivElement>) => {
    const fileList = (event.type === 'drop')
      ? event.dataTransfer.files
      : event.target.files as FileList;
    const fileNames = [...fileList].map(file => file);
    onChange?.(event, fileNames);
  }

  return (
    <FileUploadContext.Provider value={{
      description,
      disabled,
      errorText,
      fullWidth,
      inputId,
      inputRef,
      onCloseAlert,
    }}
    >
      <Input
        {...rest}
        {...(accept && { accept: accept.toString() })}
        aria-hidden="true"
        id={inputId}
        name={name}
        tabIndex={-1}
        type="file"
        onChange={handleOnChange}
        ref={inputRef}
        multiple={multiple}
      />
      <UploadDropZone
        heading={heading}
        dropzoneId={dropzoneId}
        onDrop={handleOnChange}
      />
    </FileUploadContext.Provider>
  );
};

FileUploadDropZone.propTypes = {
  /**
   * Specifies allowed file types
   */
  accept: PT.arrayOf(PT.string.isRequired),
  /**
   * Rendered list of uploader descriptions
   */
  description: PT.node as PT.Validator<ReactNode>,
  /**
   * Specifies if the control is disabled
   */
  disabled: PT.bool,
  /**
   * ID for drop zone
   */
  dropzoneId: PT.string.isRequired,
  /**
   * Instructional text displayed within drop zone
   */
  heading: PT.node as PT.Validator<ReactNode>,
  /**
   * Text displayed in Alert component when an error occurs
   */
  errorText: PT.string,
  /**
   * Specifies if the control takes full width of parent element
   */
  fullWidth: PT.bool,
  /**
   * ID for file input
   */
  inputId: PT.string.isRequired,
  /**
   * Specifies that the control will accept one or more values
   */
  multiple: PT.bool,
  /**
   * Name of the form control
   */
  name: PT.string,
  /**
   * Callback function called when file(s) are selected from file system
   */
  onChange: PT.func,
  /**
   * Callback function called when Alert close button is selected
   */
  onCloseAlert: PT.func,
};

FileUploadDropZone.defaultProps = {
  accept: undefined,
  description: undefined,
  disabled: false,
  heading: undefined,
  errorText: '',
  fullWidth: false,
  multiple: false,
  name: '',
  onChange: undefined,
  onCloseAlert: noop,
};

export default FileUploadDropZone;
