import { isMobileStyleMixin } from '@amzn/storm-ui';
import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useDatePicker from '../../hooks/useDatePicker';
import useDateSingle from '../../hooks/useDateSingle';
import useNavigation from '../../hooks/useNavigation';
import CalendarMonth from '../CalendarMonth';
import CalendarMonthHeader from '../CalendarMonthHeader';

interface DatePickerOuterWrapperProps {
  $loading: boolean;
}
const DatePickerOuterWrapper = styled('div')<DatePickerOuterWrapperProps>`
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  background: ${({ theme }) => theme.datepicker.bg};
  width: 255px;
  ${isMobileStyleMixin(css`
    width: 300px;
  `)}
`;

const DatePickerInnerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: fit-content;
  padding: 0 13px;
  margin: auto;
  padding: 0 13px;
  ${isMobileStyleMixin(css`
    padding: 0;
  `)}
`;

const Zone = styled('p')`
  ${({ theme }) => theme.typography.base};
  color: ${({ theme }) => theme.datepicker.timeZoneColor};
  margin: 0;
  padding: 0 22px 12px 22px;
  font-size: 11px;

  ${isMobileStyleMixin(css`
    padding: 0 11px 12px 11px;
  `)}
`;
const Root: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    zone,
    zonePrefix,
    id,
    loading,
    renderZoneOverride,
    onVisibleMonthsChange,
  } = useDatePicker();
  const { NoDateOption } = useDateSingle();
  const { displayMonths } = useNavigation();
  const displayMonth = displayMonths[0];

  useEffect(() => {
    onVisibleMonthsChange?.(displayMonths);
  }, [displayMonths, onVisibleMonthsChange]);

  return (
    <DatePickerOuterWrapper id={id} $loading={loading}>
      {NoDateOption && <NoDateOption />}
      <DatePickerInnerWrapper>
        <CalendarMonthHeader displayMonth={displayMonth} />
        <CalendarMonth displayMonth={displayMonth} />
      </DatePickerInnerWrapper>
      {renderZoneOverride
        ? renderZoneOverride(zone)
        : <Zone>{zonePrefix}{zone}</Zone>}
    </DatePickerOuterWrapper>
  );
};

export default Root;
