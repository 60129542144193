import {
  Locale,
} from 'date-fns';
import { ISODate } from '../types';
import {
  addDays,
  endOfMonth,
  getMonth,
  startOfMonth,
  getWeek,
  differenceInCalendarDays,
} from './dateUtils';

import getOutsideEndDays from './getOutsideEndDays';
import getOutsideStartDays from './getOutsideStartDays';

export type MonthWeeks = { [weeknumber: string]: ISODate[] };

/** Returns an object of all date arrays corresponding to the weeks of the current month */
const getWeeks = (
  month: ISODate,
  locale: Locale,
): MonthWeeks => {
  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);

  const diff = differenceInCalendarDays(monthEnd, monthStart);

  const weeks: MonthWeeks = {};
  let lastWeekStr = '';
  for (let i = 0; i <= diff; i++) {
    const date = addDays(monthStart, i);
    let week = getWeek(date, locale);
    if (week === 1 && Number(getMonth(date)) - 1 === 11) {
      week = 53;
    }
    const weekStr: string = week.toString();

    if (!weeks[weekStr]) {
      const startDays = getOutsideStartDays(date, locale);
      weeks[weekStr] = startDays;
    }
    weeks[weekStr].push(date);
    lastWeekStr = weekStr;
  }

  const lastWeek = weeks[lastWeekStr];
  const lastDay = lastWeek[lastWeek.length - 1];
  const endDays = getOutsideEndDays(lastDay, locale);
  weeks[lastWeekStr] = lastWeek.concat(endDays);

  return weeks;
};

export default getWeeks;
