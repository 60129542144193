import React, { PureComponent, Ref, MouseEvent } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { spinner } from '@amzn/storm-ui-icons';
import { Theme } from '../theme';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import Icon from '../Icon';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

const globalStyles = css`
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: ${({ theme }) => theme.button.cursor};
  text-align: center;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  user-select: auto;
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms ease;
  }
`;

export const colorMixin = (themeValueSelector: ({ theme }: { theme: Theme; }) => string) => css`
&&, &&:visited, &&:active, &&:link, &&:hover{
  color: ${themeValueSelector};
}`;

/*
 * Replace `colorMixin()` with this version if we want to only increase the Link
 * color rule specificity when `theme.link.overrideAuiLinkStyle === true`

const colorMixin = themeValueSelector => ({ theme }) =>{
  if (theme?.globals?.link?.overrideAuiLinkStyle === true) {
    return css`
    &&, &&:visited, &&:active, &&:link, &&:hover{
      color: ${themeValueSelector};
    }`;
  }
  return css`color: ${themeValueSelector};`;
};
*/

const normalStyles = css`
  padding-top: ${({ theme }) => theme.button.normal.paddingV};
  padding-bottom: ${({ theme }) => theme.button.normal.paddingV};
  padding-inline-start: ${({ theme }) => theme.button.normal.paddingH};
  padding-inline-end: ${({ theme }) => theme.button.normal.paddingH};
  font-size: ${({ theme }) => theme.button.normal.fontSize};
  ${isMobile(css`
    padding-top: ${({ theme }) => theme.button.normal.mobile.paddingV};
    /* add 1px to bottom padding so that we hit 44px height */
    padding-bottom: calc(${({ theme }) => theme.button.normal.mobile.paddingV} + 1px);
    padding-inline-start: ${({ theme }) => theme.button.normal.mobile.paddingH};
    padding-inline-end: ${({ theme }) => theme.button.normal.mobile.paddingH};
    font-size: ${({ theme }) => theme.button.normal.mobile.fontSize};
  `)}
`;

const smallStyles = css`
  padding-top: ${({ theme }) => theme.button.small.paddingV};
  padding-bottom: ${({ theme }) => theme.button.small.paddingV};
  padding-inline-start: ${({ theme }) => theme.button.small.paddingH};
  padding-inline-end: ${({ theme }) => theme.button.small.paddingH};
  font-size: ${({ theme }) => theme.button.small.fontSize};
  ${isMobile(css`
    padding-top: ${({ theme }) => theme.button.small.mobile.paddingV};
    padding-bottom: ${({ theme }) => theme.button.small.mobile.paddingV};
    padding-inline-start: ${({ theme }) => theme.button.small.mobile.paddingH};
    padding-inline-end: ${({ theme }) => theme.button.small.mobile.paddingH};
    font-size: ${({ theme }) => theme.button.small.mobile.fontSize};
  `)}
`;

export const transparentStyles = css`
  background: ${({ theme }) => theme.button.transparent.bg};
  border-color: ${({ theme }) => theme.button.transparent.borderColor};
  box-shadow: ${({ theme }) => theme.button.transparent.boxShadow};
  ${colorMixin(({ theme }) => theme.button.transparent.color)}

  :hover {
    background: ${({ theme }) => theme.button.transparent.bgHover};
    box-shadow: ${({ theme }) => theme.button.transparent.boxShadow};
  }

  :active {
    background: ${({ theme }) => theme.button.transparent.bgActive};
    border-color: ${({ theme }) => theme.button.transparent.borderColorActive};
  }

  :disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    border-color: ${({ theme }) => theme.button.transparent.borderColorDisabled};
    ${colorMixin(({ theme }) => theme.button.transparent.colorDisabled)}
    background: ${({ theme }) => theme.button.transparent.bg};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
  }
`;

const disabledLinkButtonMixin = css`
  outline: none;
  pointer-events: none;
  opacity: 1;
  ${colorMixin(({ theme }) => theme.button.default.colorDisabled)}
  cursor: not-allowed;
  border-color: ${({ theme }) => theme.button.default.borderColorDisabled};
  background: ${({ theme }) => theme.button.default.bgDisabled};
`;

const disabledPrimaryLinkButtonMixin = css`
  outline: none;
  pointer-events: none;
  opacity: 1;
  ${colorMixin(({ theme }) => theme.button.primary.colorDisabled)}
  cursor: not-allowed;
  border-color: ${({ theme }) => theme.button.primary.borderColorDisabled};
  background: ${({ theme }) => theme.button.primary.bgDisabled};
`;

const disabledTransparentLinkButtonMixin = css`
  cursor: not-allowed;
  outline: none;
  pointer-events: none;
  opacity: 1;
  border-color: ${({ theme }) => theme.button.transparent.borderColorDisabled};
  ${colorMixin(({ theme }) => theme.button.transparent.colorDisabled)}
  background: ${({ theme }) => theme.button.transparent.bg};
`;

const iconStyles = css`
  margin-inline-end: ${({ theme }) => theme.spacing.mini};
`;

const loadingStyles = css`
  &&[aria-disabled="true"] {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    border-color: ${({ theme }) => theme.button.default.borderColorDisabled};
    ${colorMixin(({ theme }) => theme.button.default.colorDisabled)}
    background: ${({ theme }) => theme.button.default.bgDisabled};
    pointer-events: none;
    /* ↑ override pointer-events rule for buttons */
    box-shadow: ${({ theme }) => theme.button.default.boxShadowDisabled};
  }
`;

const transparentloadingStyles = css`
  &&[aria-disabled="true"] {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    border-color: ${({ theme }) => theme.button.transparent.borderColorDisabled};
    ${colorMixin(({ theme }) => theme.button.transparent.colorDisabled)}
    background: ${({ theme }) => theme.button.transparent.bg};
    pointer-events: none;
    /* ↑ override pointer-events rule for buttons */
    box-shadow: ${({ theme }) => theme.button.default.boxShadowDisabled};
  }
`;

const defaultStyles = css`
  background: ${({ theme }) => theme.button.default.bg};
  border-color: ${({ theme }) => theme.button.default.borderColor};
  ${colorMixin(({ theme }) => theme.button.default.color)}
  box-shadow: ${({ theme }) => theme.button.default.boxShadow};
  :hover {
    background: ${({ theme }) => theme.button.default.bgHover};
    box-shadow: ${({ theme }) => theme.button.default.boxShadowHover};
  }

  :active {
    border-color: ${({ theme }) => theme.button.default.borderColorActive};
    background: ${({ theme }) => theme.button.default.bgActive};
    box-shadow: ${({ theme }) => theme.button.default.boxShadowActive};
  }

  :disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    border-color: ${({ theme }) => theme.button.default.borderColorDisabled};
    ${colorMixin(({ theme }) => theme.button.default.colorDisabled)}
    background: ${({ theme }) => theme.button.default.bgDisabled};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
    box-shadow: ${({ theme }) => theme.button.default.boxShadowDisabled};
  }
`;

const primaryStyles = css`
  background: ${({ theme }) => theme.button.primary.bg};
  border-color: ${({ theme }) => theme.button.primary.borderColor};
  ${colorMixin(({ theme }) => theme.button.primary.color)}
  box-shadow: ${({ theme }) => theme.button.primary.boxShadow};

  :hover {
    background: ${({ theme }) => theme.button.primary.bgHover};
    box-shadow: ${({ theme }) => theme.button.primary.boxShadowHover};
  }

  :active {
    border-color: ${({ theme }) => theme.button.primary.borderColorActive};
    background: ${({ theme }) => theme.button.primary.bgActive};
    box-shadow: ${({ theme }) => theme.button.primary.boxShadowActive};
  }

  :disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    ${colorMixin(({ theme }) => theme.button.primary.colorDisabled)}
    background: ${({ theme }) => theme.button.primary.bgDisabled};
    border-color: ${({ theme }) => theme.button.primary.borderColorDisabled};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
    box-shadow: ${({ theme }) => theme.button.primary.boxShadowDisabled};
  }
`;

const StyledIcon = styled(Icon)`
  ${iconStyles};
`;

export const LoadingIcon = () => (<StyledIcon type={spinner} />);

interface StyledButtonProps {
  $primary?: boolean;
  $small?: boolean;
  $transparent?: boolean;
  $loading?: boolean;
}
const StyledButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
}) <StyledButtonProps>`
  ${({ theme }) => theme.typography.base};
  ${globalStyles};
  ${({ $primary, $transparent }) => {
    if ($transparent) {
      return transparentStyles;
    }

    if ($primary) {
      return primaryStyles;
    }

    return defaultStyles;
  }};
  ${({ $small }) => ($small ? smallStyles : normalStyles)};
  ${({ $loading, $transparent }) => {
    if ($loading) {
      if ($transparent) {
        return transparentloadingStyles;
      }
      return loadingStyles;
    }
    return undefined;
  }};
  &&:focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
  margin: 0;
`;

interface StyledLinkProps {
  $disabled?: boolean;
  $primary?: boolean;
  $small?: boolean;
  $transparent?: boolean;
}
const StyledLink = styled('a').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
}) <StyledLinkProps>`
  &,
  :visited,
  :active,
  :link {
    text-decoration: none;
    ${globalStyles};
    ${({ $disabled, $primary, $transparent }) => {
    if ($disabled) {
      if ($transparent) {
        return disabledTransparentLinkButtonMixin;
      }

      if ($primary) {
        return disabledPrimaryLinkButtonMixin;
      }

      return disabledLinkButtonMixin;
    }

    return '';
  }};
  }
  ${({ theme }) => theme.typography.base};
  ${({ $primary, $transparent }) => {
    if ($transparent) {
      return transparentStyles;
    }

    if ($primary) {
      return primaryStyles;
    }

    return defaultStyles;
  }};
  ${({ $disabled, $primary, $transparent }) => {
    if ($disabled) {
      if ($transparent) {
        return disabledTransparentLinkButtonMixin;
      }

      if ($primary) {
        return disabledPrimaryLinkButtonMixin;
      }

      return disabledLinkButtonMixin;
    }

    return '';
  }};
  ${({ $small }) => ($small ? smallStyles : normalStyles)};
  display: inline-block;
  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
`;

/**
 * A `Buttonish` element represents a superset of the most common types of
 * elements our customers are using for the Button component.
 */
type ButtonishElement = HTMLElement & HTMLButtonElement & HTMLAnchorElement;
type ButtonishElementProps = MergeElementProps<'button'> & MergeElementProps<'a'>;

interface BaseButtonProps {
  /**
   * @defaultValue `false`
   */
  small?: boolean;
  /**
   * @defaultValue `false`
   */
  primary?: boolean;
  /**
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * @defaultValue `false`
   */
  transparent?: boolean;
  /**
   * @defaultValue `undefined`
   */
  buttonRef?: Ref<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * @defaultValue `undefined`
   */
  innerRef?: Ref<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * @defaultValue `undefined`
   */
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  /**
   * To indicate the button is loading.
   * @defaultValue `false`
   */
  loading?: boolean;
  /**
   * Substitute for Button's body when loading is true.
   * @defaultValue `undefined`
   */
  loadingLabel?: string;
  /**
   * `any` is necessary here to properly imitate the native onClick function.
   * @defaultValue `undefined`
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: MouseEvent<ButtonishElement>, ...args: any[]) => void;
}

export interface ButtonProps extends TaktProps, BaseButtonProps, Omit<ButtonishElementProps, 'onClick'|'ref'> {}

export default class Button extends PureComponent<ButtonProps> {
  static propTypes = {
    type: PT.oneOf(['submit', 'reset', 'button']),
    small: PT.bool,
    primary: PT.bool,
    disabled: PT.bool,
    href: PT.string,
    buttonRef: PT.oneOfType([
      PT.func,
      PT.shape({ current: PT.instanceOf(typeof Element !== 'undefined' ? Element : Object) }),
    ]),
    innerRef: PT.oneOfType([
      PT.func,
      PT.shape({ current: PT.instanceOf(typeof Element !== 'undefined' ? Element : Object) }),
    ]),
    transparent: PT.bool,
    loading: PT.bool,
    loadingLabel: PT.string,
    onClick: PT.func,
  }

  static defaultProps = {
    type: 'button',
    small: false,
    primary: false,
    disabled: false,
    href: undefined,
    buttonRef: undefined,
    innerRef: undefined,
    transparent: false,
    loading: false,
    loadingLabel: undefined,
    onClick: undefined,
  }

  render(): JSX.Element {
    const {
      href,
      buttonRef,
      innerRef,
      disabled,
      primary,
      small,
      type,
      transparent,
      loading,
      loadingLabel,
      children,
      taktId,
      taktValue,
      onClick,
      ...rest
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('button')}>
        {({ getDataTaktAttributes }) => {
          if (href) {
            return (
              <StyledLink
                {...getDataTaktAttributes()}
                {...rest}
                ref={buttonRef || innerRef}
                {...((disabled) ? { 'aria-disabled': true } : { href })}
                tabIndex={disabled ? -1 : undefined}
                $disabled={disabled}
                $primary={primary}
                $small={small}
                $transparent={transparent}
                onClick={onClick}
              >{children}
              </StyledLink>
            );
          }
          return (
            <StyledButton
              {...getDataTaktAttributes()}
              {...rest}
              disabled={disabled}
              type={type}
              ref={buttonRef || innerRef}
              $primary={primary}
              $small={small}
              $transparent={transparent}
              $loading={loading}
              {...(loading) && { 'aria-live': 'assertive' }}
              {...(loading) && { 'aria-disabled': true }}
              onClick={loading ? undefined : onClick}
            >
              {loading && <LoadingIcon />}
              <>{(loading && loadingLabel) ? loadingLabel : children}</>
            </StyledButton>
          );
        }}
      </TaktIdConsumer>
    );
  }
}
