import React, { PureComponent, PropsWithChildren } from 'react';
import { TaktIdConsumer, TaktProps, createStormTaktId } from '@amzn/storm-ui';
import { MultiSelectItemButton, MultiSelectItemLabel } from './MultiSelect.styles';
import MultiSelectItemCheckbox from './MultiSelectItemCheckbox';
import MultiSelectContext from './MultiSelectContext';
import { MultiSelectIsMobileConsumer } from './MultiSelectIsMobileContext';
import { OptionValue } from './types';

export interface MultiSelectItemProps extends TaktProps {
  /**
   * The value returned when the item is selected.
   */
  value: OptionValue;
  /**
   * Used to generate the actionable components' ids
   */
  name: string;
  /**
   * Makes the item un-selectable.
   * @defaultValue `false`
   */
  disabled?: boolean;
}

/**
 * Selectable MultiSelect Dropdown Item
 */
class MultiSelectItem extends PureComponent<PropsWithChildren<MultiSelectItemProps>> {
  static displayName = 'MultiSelectItem';

  static defaultProps = {
    disabled: false,
  };

  render(): JSX.Element {
    const {
      disabled, value, name, children, taktId, taktValue, ...restProps
    } = this.props;
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('multi-select-item')}>
        {({ getDataTaktAttributes }) => (
          <MultiSelectContext.Consumer>
            {({ selectedValuesSet, onToggle }) => {
              const selected = selectedValuesSet?.has(value);
              return (
                <MultiSelectIsMobileConsumer>
                  {isMobile => (
                    <MultiSelectItemButton
                      {...getDataTaktAttributes({ taktIdSuffix: 'button' })}
                      {...restProps}
                      type="button"
                      onClick={event => {
                        if (typeof onToggle === 'function') {
                          onToggle(value, event);
                        }
                      }}
                      disabled={disabled}
                      role="checkbox"
                      aria-checked={selected}
                      tabIndex={disabled ? -1 : 0}
                      value={value}
                      id={`${name}-checkbox-${value}`}
                      $isMobile={isMobile}
                    >
                      <div>
                        <MultiSelectItemCheckbox
                          checked={selected}
                          disabled={disabled}
                          {...getDataTaktAttributes({ taktIdSuffix: 'checkbox' })}
                        />
                        <MultiSelectItemLabel $disabled={disabled}>
                          {children}
                        </MultiSelectItemLabel>
                      </div>
                    </MultiSelectItemButton>
                  )}
                </MultiSelectIsMobileConsumer>
              );
            }}
          </MultiSelectContext.Consumer>
        )}
      </TaktIdConsumer>
    );
  }
}

export default MultiSelectItem;
