import React, { FunctionComponent as FC, ReactNode, useCallback } from 'react';
import PT from 'prop-types';
import Dropdown from '../../Dropdown/Dropdown';
import DropdownItem from '../../Dropdown/DropdownItem/DropdownItem';

function parseToNum(strToParse: string) {
  const parsed = parseInt(strToParse, 10);
  if (isNaN(parsed)) return 0;
  return parsed;
}

export interface PaginationDropdownProps {
  /**
   * The id assigned to the interactive element.
   * DropdownItem will be assigned {id}-{pagesize}.
   */
  id: string;
  /**
  * The current page size.
  */
  currentSize: number;
  /**
  * The page sizes that the user can select from.
  */
  pageSizes: number[];
  /**
  * onChange handler for changing the page size.
  */
  onSetPageSize: (newPageSize: number) => void;
  /**
  * The text that displays on the dropdown.
  * @defaultValue `(pageSize) => pageSize`
  */
  renderPaginationDropdownLabel?: (pageSize: number) => ReactNode;
  /**
   * Specifies if the control is disabled, which prevents the user
   * from modifying the page size. A disabled control can't receive focus.
   * @defaultValue `false`
   */
  disabled?: boolean;
}

const PaginationDropdown: FC<React.PropsWithChildren<PaginationDropdownProps>> = (
  props: PaginationDropdownProps,
): JSX.Element => {
  const {
    id,
    currentSize,
    disabled,
    pageSizes,
    onSetPageSize,
    renderPaginationDropdownLabel,
  } = props;

  const handleOverrideLabel = useCallback(
    (label: string) => renderPaginationDropdownLabel?.(parseToNum(label)),
    [renderPaginationDropdownLabel],
  );

  const handleSetPageSize = useCallback(
    (pageSize: string) => onSetPageSize(parseToNum(pageSize)),
    [onSetPageSize],
  );

  return (
    <Dropdown
      id={id}
      disabled={disabled}
      selectedValue={currentSize.toString()}
      onChange={handleSetPageSize}
      onOverrideLabel={handleOverrideLabel}
    >
      {pageSizes.map((pageSize: number) => (
        <DropdownItem id={`${id}-${pageSize}`} key={pageSize.toString()} value={pageSize.toString()}>
          {pageSize}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

PaginationDropdown.propTypes = {
  /**
   * The id assigned to the interactive element.
   * DropdownItem will be assigned {id}-{pagesize}.
   */
  id: PT.string.isRequired,
  /**
  * The current page size.
  */
  currentSize: PT.number.isRequired,
  /**
  * The page sizes that the user can select from.
  */
  pageSizes: PT.arrayOf(PT.number.isRequired).isRequired,
  /**
  * onChange handler for changing the page size.
  */
  onSetPageSize: PT.func.isRequired,
  /**
  * The text that displays on the dropdown.
  */
  renderPaginationDropdownLabel: PT.func,
  /**
   * Specifies if the control is disabled, which prevents the user
   * from modifying the page size. A disabled control can't receive focus.
   */
  disabled: PT.bool,
};

PaginationDropdown.defaultProps = {
  renderPaginationDropdownLabel: (pageSize: number): string => `${pageSize}`,
  disabled: false,
};

export default PaginationDropdown;
