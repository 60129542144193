import styled, { css } from 'styled-components';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import PortalComponent from '../Portal/PortalComponent';

type PopoverType = 'blue' | 'dark' | 'light';

export interface OuterWrapperProps {
  transition: string;
  transitionState: TransitionStatus;
  withBackdrop: boolean;
  /*
   * `type` is implemented for the OuterWrapper border, but does not seem to effect the
   * popover surface. This seems to be a vestigial prop from a earlier implementation.
   * considering removing this in the future.
   */
  $type?: PopoverType;
  $hideOverflow: boolean;
}

export const OuterWrapper = styled('div')<OuterWrapperProps>`
  box-sizing: border-box;
  outline: none;
  ${({ $hideOverflow }) => ($hideOverflow && 'overflow: hidden;')}
  position: fixed;


  @media (prefers-reduced-motion:no-preference) {
    transition: ${({ transition }) => (transition)};
  }

  opacity: 0;
  opacity: ${({ transitionState }) => ({
    entering: 0,
    entered: 1,
    exiting: 0,
    exited: 0,
    unmounted: 0,
  }[transitionState])};

  z-index: ${({ theme }) => theme.popover.zIndex};

  ${({ withBackdrop, theme, $type }) => withBackdrop && `
    border: none;
    border-radius: ${theme.popover.radius};
    background-color: ${theme.popover.bg};
    box-shadow: ${theme.popover.boxShadow};
  `}
  ${PortalComponent} > && {
    width: max-content;
  }
`;
OuterWrapper.displayName = 'OuterWrapper';

export interface ContentProps {
  $disableContentScroll?: boolean;
  $hideOverflow?: boolean;
}

function contentOverflowRules({ $disableContentScroll, $hideOverflow }:ContentProps) {
  if ((!$disableContentScroll) && $hideOverflow) {
    return css`
      overflow-y: auto;
      overflow-x: hidden;
    `;
  }
  if ($disableContentScroll && $hideOverflow) {
    return css`
      overflow: hidden;
    `;
  }
  if ((!$disableContentScroll) && (!$hideOverflow)) {
    return css`
    overflow-y: auto;
    `;
  }
  return css``;
}

export const Content = styled('div')<ContentProps>`
  box-sizing: border-box;
  word-wrap: break-word;
  ${contentOverflowRules}
  ul, ol {
    color: inherit;
  }
`;
Content.displayName = 'Content';
