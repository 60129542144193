import styled from 'styled-components';

const DropdownDivider = styled('div')`
  content: "";
  border-bottom: 1px solid ${({ theme }) => theme.dropdown.submenuBorderColor};
  margin: ${({ theme }) => theme.spacing.mini} 0;
`;

DropdownDivider.displayName = 'DropdownDivider';

export default DropdownDivider;
