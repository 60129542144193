import React, { FunctionComponent as FC, ReactNode } from 'react';
import PT from 'prop-types';
import PaginationDropdown from './PaginationDropdown/PaginationDropdown';
import PaginationPageStatus from './PaginationPageStatus/PaginationPageStatus';
import PaginationFirstButton from './PaginationButton/PaginationFirstButton';
import PaginationLastButton from './PaginationButton/PaginationLastButton';
import PaginationNextButton from './PaginationButton/PaginationNextButton';
import PaginationPrevButton from './PaginationButton/PaginationPrevButton';
import { PaginationNavGroup } from './Pagination.styles';
import BasePagination from './BasePagination'
import type { TaktProps } from '../types/TaktProps';

export interface PaginationProps extends TaktProps {
  /**
    * Each interactive element will have an id based off of this.
    */
  id: string;
  /**
    * The text that displays "x out of 12 pages" in the example.
    */
  currentPageLabel: string;
  /**
    * Labels are required for screen reader accessibility.
    */
  firstPageLabel: string;
  /**
    * Labels are required for screen reader accessibility.
    */
  lastPageLabel: string;
  /**
    * Labels are required for screen reader accessibility.
    */
  nextPageLabel: string;
  /**
    * Labels are required for screen reader accessibility.
    */
  prevPageLabel: string;
  /**
    * The page sizes that the user can select from.
    */
  pageSizes: number[];
  /**
    * The page the user is currently on (0-indexed).
    */
  currentPage: number;
  /**
    * The current page size.
    */
  currentSize: number;
  /**
    * The total number of pages for nav disabling purposes.
    */
  totalPages: number;
  /**
    * onChange handler for changing the page size.
    */
  onSetPageSize: (newPageSize: number) => void;
  /**
    * Setter for page navigation changes.
    */
  setCurrentPage: (newPage: number) => void;

  /**
    * The text that displays on the dropdown.
    * @defaultValue `(pageSize) => pageSize`
    */
  renderPaginationDropdownLabel?: (pageSize: number) => ReactNode;
}

const Pagination: FC<React.PropsWithChildren<PaginationProps>> = (props: PaginationProps): JSX.Element => {
  const {
    id,
    currentPageLabel,
    firstPageLabel,
    lastPageLabel,
    nextPageLabel,
    prevPageLabel,
    pageSizes,
    currentPage,
    currentSize,
    totalPages,
    onSetPageSize,
    setCurrentPage,
    renderPaginationDropdownLabel,
    taktId,
    taktValue,
  } = props;

  return (
    <BasePagination taktId={taktId} taktValue={taktValue} id={id}>
      <PaginationDropdown
        id={`${id}-dropdown`}
        currentSize={currentSize}
        pageSizes={pageSizes}
        onSetPageSize={onSetPageSize}
        renderPaginationDropdownLabel={renderPaginationDropdownLabel}
      />
      <PaginationPageStatus
        id={`${id}-page-status`}
        currentPageLabel={currentPageLabel}
        aria-live="polite"
      />
      <PaginationNavGroup
        aria-controls={`${id}-page-status`}
        aria-labelledby={`${id}-page-status`}
      >
        <PaginationFirstButton
          id={`${id}-first`}
          ariaLabel={firstPageLabel}
          onClick={() => setCurrentPage(0)}
          disabled={currentPage === 0 || totalPages === 0}
          taktFallbackId="first-button"

        />
        <PaginationPrevButton
          id={`${id}-prev`}
          ariaLabel={prevPageLabel}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0 || totalPages === 0}
          taktFallbackId="prev-button"

        />
        <PaginationNextButton
          id={`${id}-next`}
          ariaLabel={nextPageLabel}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          taktFallbackId="next-button"

        />
        <PaginationLastButton
          id={`${id}-last`}
          ariaLabel={lastPageLabel}
          onClick={() => setCurrentPage(totalPages - 1)}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          taktFallbackId="last-button"

        />
      </PaginationNavGroup>
    </BasePagination>
  );
};

Pagination.propTypes = {
  /**
    * Each interactive element will have an id based off of this.
    */
  id: PT.string.isRequired,
  /**
    * The text that displays "x out of 12 pages" in the example.
    */
  currentPageLabel: PT.string.isRequired,
  /**
    * Labels are required for screen reader accessibility.
    */
  firstPageLabel: PT.string.isRequired,
  /**
    * Labels are required for screen reader accessibility.
    */
  lastPageLabel: PT.string.isRequired,
  /**
    * Labels are required for screen reader accessibility.
    */
  nextPageLabel: PT.string.isRequired,
  /**
    * Labels are required for screen reader accessibility.
    */
  prevPageLabel: PT.string.isRequired,
  /**
    * The page sizes that the user can select from.
    */
  pageSizes: PT.arrayOf(PT.number.isRequired).isRequired,
  /**
    * The page the user is currently on (0-indexed).
    */
  currentPage: PT.number.isRequired,
  /**
    * The current page size.
    */
  currentSize: PT.number.isRequired,
  /**
    * The total number of pages for nav disabling purposes.
    */
  totalPages: PT.number.isRequired,
  /**
    * onChange handler for changing the page size.
    */
  onSetPageSize: PT.func.isRequired,
  /**
    * Setter for page navigation changes.
    */
  setCurrentPage: PT.func.isRequired,
  /**
    * The text that displays on the dropdown.
    */
  renderPaginationDropdownLabel: PT.func,
};

Pagination.defaultProps = {
  renderPaginationDropdownLabel: (pageSize: number): string => `${pageSize}`,
};

export default Pagination;
