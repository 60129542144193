import React, { ReactNode } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useTileGroupContext } from '../TileGroupContext';
import {
  Main,
  Checkbox,
  IconWrapper,
  Body,
  SubText,
  TileControlTextContainer,
  Title,
} from './Tile.styles';
import { useTileContext, useTileControlContext } from './TileContext';
import TileToggleArea from './TileToggleArea';
import TileBox from './TileBox';
import type { TileTitleFontSize } from '../types';

const StyledTileBox = styled(TileBox)`
  flex: 1;
`;

export interface TileControlProps {
  /**
   * Set this to true to hide TileControl's start border.
   * @defaultValue `false`
   */
  hideStartBorder?: boolean;
  /**
   * Render prop for icon of the Tile.
   * @defaultValue `undefined`
   */
  renderIcon?: ({
    disabled, selected,
  }: { disabled?: boolean; selected?: boolean; }) => ReactNode;
  /**
   * Extra content of the Tile.
   * @defaultValue `undefined`
   */
  subText?: string;
  /**
   * Content of the Tile.
   * @defaultValue `undefined`
   */
  text?: string;
  /**
   * Title of the Tile.
   * @defaultValue `undefined`
   */
  title?: string;
  /**
   * Font size of the title. ("base" or "large")
   * @defaultValue `"base"`
   */
  titleFontSize?: TileTitleFontSize;
}

const TileControl = ({
  hideStartBorder,
  renderIcon,
  subText,
  text,
  title,
  titleFontSize,
}: TileControlProps) => {
  const {
    multiple,
    name,
  } = useTileGroupContext();
  const {
    disabled,
    selected,
  } = useTileContext();
  const {
    focusProps,
    label,
    onChange,
    value,
    dataTaktAttributes,
    innerRef,
    restProps,
  } = useTileControlContext();

  return (
    <StyledTileBox
      hideStartBorder={hideStartBorder}
      paddingBlockStart="none"
      paddingBlockEnd="none"
      paddingInlineStart="none"
      paddingInlineEnd="none"
    >
      <TileToggleArea
        {...dataTaktAttributes}
        {...restProps}
        checked={selected}
        disabled={disabled}
        focusProps={focusProps}
        label={label}
        multiple={multiple}
        name={name}
        onChange={onChange}
        ref={innerRef}
        value={value}
      >
        <Main>
          {multiple && <Checkbox checkboxSize="medium" $checked={selected} $disabled={disabled} />}
          {renderIcon && <IconWrapper>{renderIcon({ disabled, selected })}</IconWrapper>}
          <TileControlTextContainer>
            {title && <Title $titleFontSize={titleFontSize}>{title}</Title>}
            {text && <Body>{text}</Body>}
            {subText && <SubText>{subText}</SubText>}
          </TileControlTextContainer>
        </Main>
      </TileToggleArea>
    </StyledTileBox>
  );
}

TileControl.defaultProps = {
  hideStartBorder: false,
  renderIcon: undefined,
  subText: undefined,
  text: undefined,
  title: undefined,
  titleFontSize: 'base',
}

TileControl.propTypes = {
  /**
   * Set this to true to hide TileControl's start border.
   */
  hideStartBorder: PT.bool,
  /**
   * Render prop for icon of the Tile.
   */
  renderIcon: PT.func,
  /**
   * Extra content of the Tile.
   */
  subText: PT.string,
  /**
   * Content of the Tile.
   */
  text: PT.string,
  /**
   * Title of the Tile.
   */
  title: PT.string,
  /**
   * Font size of the title.  ("base" or "large")
   */
  titleFontSize: PT.oneOf(['base', 'large']),
}

export default TileControl;
