const verifyAttachmentType = (file: File, acceptedFiles?: string[]) => {
  if (file && acceptedFiles) {
    if (acceptedFiles.length === 0) {
      return true;
    }
    const fileName = file.name || '';
    const mimeType = (file.type || '').toLowerCase();
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    return acceptedFiles.some(type => {
      const validType = type.trim().toLowerCase()
      if (validType.charAt(0) === '.') {
        return fileName.toLowerCase().endsWith(validType)
      } if (validType.endsWith('/*')) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return mimeType === validType;
    })
  }
  return true;
}

export default verifyAttachmentType;
