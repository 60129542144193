import React, { ReactNode } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import {
  createStormTaktId,
  TaktIdProvider,
  TaktProps,
  UIDPrefix,
} from '@amzn/storm-ui';

const FormDiv = styled.div`
  max-width: 1023px;
  padding-inline-start: ${({ theme }) => theme.spacing.large};
  padding-inline-end: ${({ theme }) => theme.spacing.xlarge};
`;

export interface FormProps extends TaktProps {
  /**
   * Use if you need to add a classname to the form.
   * @defaultValue `undefined`
   */
  className?: string,
  /**
   * The content of the form, which would consist of <FormRow /> elements.
   */
  children: ReactNode | ReactNode[],
  /**
   * Unique identifier for the form
   */
  id: string,
}

const Form = ({
  className,
  children,
  id,
  taktId,
  taktValue,
}: FormProps) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('column-form')}>
    <UIDPrefix value={id}>
      <FormDiv className={className || ''}>{children}</FormDiv>
    </UIDPrefix>
  </TaktIdProvider>
);

Form.propTypes = {
  /**
   * Use if you need to add a classname to the form.
   */
  className: PT.string,
  /**
   * The content of the form, which would consist of <FormRow /> elements.
   */
  children: PT.node.isRequired,
  /**
   * Unique identifier for the form
   */
  id: PT.string.isRequired,
};

Form.defaultProps = {
  className: undefined,
};

export default Form;
