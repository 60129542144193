/* eslint-disable id-length */
import { border } from '../base/border';
import { palette } from '../base/color';
import { spacing, mobileSpacing } from '../base/spacing';
import { fontSizes, mobileFontSizes } from '../base/typography';

export const radioStyles = {
  // legacy styles for 2.x
  height: '18px',
  width: '18px',
  dotHeight: '8px',
  dotWidth: '8px',

  // styles
  bg: palette.white,
  bgHover: palette.blue[200],
  bgChecked: palette.blue[700],
  bgCheckedHover: palette.blue[800],
  bgActive: palette.blue[900],
  borderColor: palette.gray[500],
  borderColorChecked: palette.blue[700],
  activeColor: palette.white,
  inputHeight: '16px',
  inputWidth: '16px',
  inputDotHeight: '6px',
  inputDotWidth: '6px',
  marginInlineEnd: '6px',

  buttonPadding: spacing.none,
  buttonFontSize: fontSizes.base,
};

export const mobileRadioStyles = {
  mobile: {
    backgroundColor: palette.white,

    buttonBorderColor: palette.mercury,
    buttonBorderRadius: border.roundedThin.radius,
    buttonBorderWidth: border.roundedThin.width,
    buttonPadding: mobileSpacing.small,
    buttonFontSize: mobileFontSizes.base,

    groupBackgroundColor: palette.white,
    groupBorderColor: palette.mercury,
    groupBorderRadius: border.roundedThin.radius,
    groupBorderWidth: border.roundedThin.width,
  },
};
