export const palette = {
  white: '#FFFFFF', // white
  alabaster: '#FBFBFB', // basically white
  concrete: '#F3F3F3', // even lighter grey
  haze: '#F7F7F7', // invisible grey
  alto: '#DDDDDD', // light grey
  mercury: '#E7E7E7', // lighter grey
  zumthor: '#EBF5FF', // background blue
  water: '#CEE5F6', // hover blue
  mango: '#E77600', // focus orange
  danube: '#6D9CCF', // border blue
  silver: '#BFBFBF', // medium grey
  inkwell: '#232F3F', // inkwell blue
  shuttle: '#556478', // inkwell blue 75
  azure: '#326295', // selected blue
  black: '#111111', // black
  emperor: '#555555', // dark grey
  boulder: '#767676', // light dark grey
  dustyGray: '#979797',
  auiblue: '#0066C0', // aui blue
  rust: '#C45500', // rust brown
  auigreen: '#008A00', // aui green
  auired: '#C40000', // aui red
  auiprice: '#B12704', // aui price red
  successGreen: '#387E22', // slightly darker than AUI, for use on a light green background
  warningRust: '#BC5100', // slightly darker than AUI, for use on a light orange background
  yellow: '#F9CF6F',
  night: '#002B4F', // data viz 0
  aqua: '#2E8CB8', // data viz 1
  tangerine: '#DD7703', // data viz 2
  raspberry: '#A3317B', // data viz 3
  moss: '#678227', // data viz 4
  indigo: '#4F4FCD', // data viz 5
  apple: '#33C336', // high keyword traffic
  tea: '#BFEBB8', // med keyword traffic
  ghost: '#F5F9FA', // info callout bg
  honeydew: '#EFF5ED', // success callout bg
  linen: '#FFF5E9', // warning callout bg
  blush: '#FBEDED', // error callout bg
  bubbles: '#E6F6FF', // onboarding callout bg
  smoke: '#BBC6C9', // onboarding callout border
  tbd: 'transparent', // TBD COLORS
  // Evolution rebranding colors
  squidInk: '#232F3F',
  gray: {
    900: '#354152',
    800: '#495566', // Replaces emperor
    700: '#5C687C', // Replaces boulder
    600: '#6F7B8F',
    500: '#8290A4', // Replaces dustyGray
    400: '#A0AABA',
    300: '#B8BFCB', // Replaces silver
    200: '#CED3DC', // Replaces alto
    150: '#D9DEE4', // Secondary buttons only
    100: '#E6E9ED', // Replaces mercury
    50: '#F2F4F6', // Replaces concrete
    25: '#F8F9FA', // Replaces haze
    10: '#FBFBFC', // Replaces alabaster
    0: '#FFFFFF',
  },
  blue: {
    900: '#00508A',
    800: '#0061A9',
    700: '#0073C7', // Primary, 4.5:1 contrast
    600: '#2386CE',
    500: '#4598D6',
    400: '#67ABDD',
    300: '#89BEE5',
    200: '#ACD1EC',
    100: '#CFE4F4',
    50: '#EDF5FB',
  },
  red: {
    900: '#711823',
    800: '#9A1B1A',
    700: '#B9201F',
    600: '#D82624', // 4.5:1 contrast
    500: '#F72C2A',
    400: '#F85250',
    300: '#F97877',
    200: '#FB9F9E',
    100: '#FCC5C4',
    50: '#FEECEC',
  },
  green: {
    900: '#0B5627',
    800: '#0E7234',
    700: '#11883E', // 4.5:1 contrast
    600: '#15A34A',
    500: '#38B567',
    400: '#5EC384',
    300: '#82D1A0',
    200: '#A7DEBB',
    100: '#CCECD8',
    50: '#F0F9F3',
  },
  clementine: {
    900: '#663600',
    800: '#924D00',
    700: '#B45F00', // 4.5:1 contrast
    600: '#DF7600',
    500: '#FF8C0A',
    400: '#FFA642',
    300: '#FFB96B',
    200: '#FFCE96',
    100: '#FFE2C2',
    50: '#FFF6ED',
  },
  orange: {
    900: '#8C3100',
    800: '#B23E00', // 4.5:1 contrast
    700: '#D94C00',
    600: '#FF5A00', // Primary
    500: '#FF7226',
    400: '#FF8B4D',
    300: '#FFA473',
    200: '#FFBD99',
    100: '#FFD5BF',
    50: '#FFEEE6',
  },
  gold: {
    900: '#775500',
    800: '#966B00', // 4.5:1 contrast
    700: '#D89B00',
    600: '#FFB800', // Primary
    500: '#FEC228',
    400: '#FDCE57',
    300: '#FEDB83',
    200: '#FFE6A6',
    100: '#FCF1D5',
    50: '#FFFBF1',
  },
  teal: {
    900: '#005758',
    800: '#007A7B', // 4.5:1 contrast
    700: '#009596', // Primary
    600: '#2EA8A8',
    500: '#56B6B6',
    400: '#7BC5C6',
    300: '#A4D9D9',
    200: '#C3E6E6',
    100: '#E1F2F2',
    50: '#F1F8F8',
  },
  cyan: {
    900: '#004C50',
    800: '#007F87', // 4.5:1 contrast
    700: '#009EA8',
    600: '#01BFCB', // Primary
    500: '#2FCAD3',
    400: '#5CD5DC',
    300: '#83DFE4',
    200: '#ABE8EC',
    100: '#D8F5F7',
    50: '#F2FBFC',
  },
  royalBlue: {
    900: '#202B7A',
    800: '#263492',
    700: '#3446C5', // Primary, 4.5:1 contrast
    600: '#4C5CCC',
    500: '#6572D3',
    400: '#828DDB',
    300: '#A1AAE4',
    200: '#BEC4EC',
    100: '#E0E3F6',
    50: '#F4F5FC',
  },
  purple: {
    900: '#453076',
    800: '#583E96',
    700: '#815BDB', // 4.5:1 contrast
    600: '#976AFF', // Primary
    500: '#A27BFD',
    400: '#B18FFF',
    300: '#C3ABFD',
    200: '#D7C7FC',
    100: '#EEE8FE',
    50: '#FAF7FF',
  },
  iris: {
    900: '#22255F',
    800: '#303485',
    700: '#444ABD',
    600: '#5158E0', // 4.5:1 contrast
    500: '#5D65FF', // Primary
    400: '#868CFF',
    300: '#A7ABFF',
    200: '#C6C9FF',
    100: '#E7E8FF',
    50: '#F5F6FE',
  },
  magenta: {
    900: '#471041',
    800: '#65175D',
    700: '#891F7E',
    600: '#A32596',
    500: '#BB2CAC', // Primary, 4.5:1 contrast
    400: '#CB60C0',
    300: '#D98BD1',
    200: '#E3B2DE',
    100: '#F4DFF2',
    50: '#FAF4FA',
  },
  pink: {
    900: '#793555',
    800: '#9B446E', // 4.5:1 contrast
    700: '#DD619C',
    600: '#FF71B5', // Primary
    500: '#FE81BD',
    400: '#FE94C6',
    300: '#FFB0D6',
    200: '#FFCDE5',
    100: '#FFE9F3',
    50: '#FFF9FC',
  },
  // Data-visualization color ramps
  data: {
    aqua: {
      900: '#1E5D7B',
      800: '#236F91',
      700: '#287DA4', // 4.5:1 contrast
      600: '#2E8CB8', // Primary
      500: '#57A3C6',
      400: '#81BAD4',
      300: '#ABD1E2',
      200: '#D5E8F0',
      100: '#E4EEF3',
      50: '#F5F9FB',
    },
    orange: {
      900: '#8E4C01',
      800: '#A65802', // 4.5:1 contrast
      700: '#BE6602', // 3:1 (large text only)
      600: '#DD7703', // Primary
      500: '#E39235',
      400: '#EAAD67',
      300: '#F1C89A',
      200: '#F8E3CC',
      100: '#FAEFE3',
      50: '#FDF9F5',
    },
    magenta: {
      900: '#651D4C',
      800: '#8B2969',
      700: '#A3317B', // Primary, 4.5:1 contrast
      600: '#AD4789',
      500: '#B55A95',
      400: '#C783AF',
      300: '#DAACCA',
      200: '#ECD5E4',
      100: '#F4E9F1',
      50: '#FBF7F9',
    },
    green: {
      900: '#415318',
      800: '#4C601C',
      700: '#587021', // 4.5:1 contrast
      600: '#678227', // Primary
      500: '#859B52',
      400: '#A3B47D',
      300: '#C2CDA8',
      200: '#E0E6D3',
      100: '#EEF1E8',
      50: '#F8F9F6',
    },
    indigo: {
      900: '#313182',
      800: '#3C3C9C',
      700: '#4F4FCD', // Primary, 4.5:1 contrast
      600: '#5F5FD1',
      500: '#7272D7',
      400: '#9595E1',
      300: '#B8B8EB',
      200: '#DBDBF5',
      100: '#EEEEF9',
      50: '#F9F9FD',
    },
  },
};

export const color = {
  primary: palette.yellow,
  secondary: palette.tbd,
  success: palette.auigreen,
  warning: palette.mango,
  error: palette.tbd,
  stable: palette.auigreen,
  beta: palette.warningRust,
  alpha: palette.auired,
  backlog: palette.black,
};
