import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

import focusPseudoElementsAfter from '../FocusIndicator/styleMixins/focusPseudoElementsAfter';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import Icon from '../Icon';
import TouchLinkGroup from './TouchLinkGroup/TouchLinkGroup';

const TouchLinkContainerStyle = css`
  color: ${({ theme }) => theme.touchLink.color};
  cursor: pointer;
  display: block;
  font-size: ${({ theme }) => theme.touchLink.fontSize};
  position: relative;
  text-decoration: none;
  width: 100%;

  :active, :link, :visited {
    color: ${({ theme }) => theme.touchLink.color};
    text-decoration: none;
  }

  :hover {
    color: ${({ theme }) => theme.touchLink.color};
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.touchLink.mobile.fontSize};
  `)}
`;

export const TouchLinkAnchor = styled('a').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})`
  ${TouchLinkContainerStyle}

  ${({ focusVisible }) => (focusVisible && focusPseudoElementsAfter)}
`;

export const TouchLinkContent = styled('div')`
  background-color: ${({ theme }) => theme.touchLink.backgroundColor};
  border: 1px solid ${({ theme }) => theme.touchLink.borderColor};
  border-radius: ${({ theme }) => theme.touchLink.borderRadius};
  padding-top: ${({ theme }) => theme.spacing.base};
  padding-bottom: ${({ theme }) => theme.spacing.base};
  padding-inline-start: ${({ theme }) => theme.spacing.medium};
  padding-inline-end: 38px;
  position: relative;

  ${TouchLinkGroup} & {
    border: none;
  }
`;

export const TouchLinkButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
    type: 'button',
  });
})`
  all: unset;
  border-radius: ${({ theme }) => theme.touchLink.borderRadius};
  ${TouchLinkContainerStyle}

  ${({ focusVisible }) => (focusVisible && focusPseudoElementsAfter)}
`;

export const TouchLinkIcon = styled(Icon)`
  color: ${({ theme }) => theme.touchLink.iconColor};
  margin-top: -9px;
  position: absolute;
  top: 50%;

  /* @noflip */ left: auto;
  inset-inline-start: auto;
  /* @noflip */ right: 18px;
  inset-inline-end: 18px;

  [dir="rtl"] && {
    /* @noflip */ right: auto;
    inset-inline-start: auto;
    /* @noflip */ left: 18px;
    inset-inline-end: 18px;
  }

  ${isMobile(css`
    margin-top: -11px;
  `)}
`;
