/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "ยกเลิก"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "บันทึก"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "กำหนดเอง"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "เลือกวันเดียว"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "เลือกเดือน"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "เลือกสัปดาห์"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "30 วันที่ผ่านมา"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "7 วันที่ผ่านมา"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "เดือนที่ผ่านมา"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "สัปดาห์ล่าสุด"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "อายุการใช้งาน"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "สัปดาห์นี้"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "วันนี้"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "ตั้งแต่ต้นปีจนถึงปัจจุบัน"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "เมื่อวานนี้"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
