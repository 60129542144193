import { Locale } from 'date-fns';
import { ISODate, ISOPresetRange, PresetRange, UnresolvedPresetRange } from '../types';
import { isoDateToDate } from './dateUtils';

const resolvePreset = (
  preset: PresetRange | UnresolvedPresetRange | ISOPresetRange,
  today: ISODate,
  zone: string,
  locale?: Locale,
) => {
  let start;
  let end;

  if (typeof preset.start === 'string') {
    start = isoDateToDate(preset.start as ISODate)
  } else if (typeof preset.start === 'function') {
    start = preset.start?.(today, zone, locale);
  } else {
    start = preset.start;
  }
  if (typeof preset.end === 'string') {
    end = isoDateToDate(preset.end as ISODate)
  } else if (typeof preset.end === 'function') {
    end = preset.end?.(today, zone, locale);
  } else {
    end = preset.end;
  }
  return {
    label: preset.label,
    period: preset.period,
    start,
    end,
  };
};

export default resolvePreset;
