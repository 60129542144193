import { spacing, mobileSpacing } from '../base/spacing';

const NODE_WIDTH = 14; // width of the checkbox
const NODE_HALF_WIDTH = NODE_WIDTH / 2;

export const tree = {
  nodeHalfWidth: `${NODE_HALF_WIDTH}px`,
  arrowButtonWidth: '34px',
  arrowButtonSpacerWidth: spacing.base,
  arrowButtonDisplayAllTreeNodeSpacerWidth: '4px',
  arrowButtonDisplayAllSpacerWidth: '11px',
};

export const mobileTree = {
  mobile: {
    nodeHalfWidth: `${NODE_HALF_WIDTH}px`,
    arrowButtonWidth: '44px',
    arrowButtonSpacerWidth: mobileSpacing.medium,
    arrowButtonDisplayAllTreeNodeSpacerWidth: '14px',
    arrowButtonDisplayAllSpacerWidth: '28px',
  },
};
