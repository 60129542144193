import React, { PureComponent } from 'react';
import PT from 'prop-types';
import { noop } from '@amzn/storm-ui-utils';
import { SelectOptionGroup } from '../../SelectList';
import { DropdownItemGroupDisplayName } from '../displayNames';
import { SelectOptionGroupProps } from '../../SelectList/SelectOptionGroup/SelectOptionGroup';
import { DropdownIsMobileConsumer } from '../DropdownIsMobileContext';
import RadioButtonGroup from '../../RadioGroup/RadioButtonGroup';

export interface DropdownItemGroupProps extends SelectOptionGroupProps {}

export default class DropdownItemGroup extends PureComponent<DropdownItemGroupProps> {
  static displayName = DropdownItemGroupDisplayName;

  static propTypes = {
    /**
     * Specifies label for the group.
     */
    label: PT.node.isRequired,
    /**
     * Called when the group is clicked.
     */
    onClick: PT.func,
  }

  static defaultProps = {
    onClick: noop,
  }

  render(): JSX.Element {
    const {
      label,
      children,
    } = this.props;

    return (
      <DropdownIsMobileConsumer>
        {isMobile => {
          if (isMobile) {
            return (
              <RadioButtonGroup
                label={label}
              >
                {children}
              </RadioButtonGroup>
            );
          }

          return (
            <SelectOptionGroup {...this.props} />
          );
        }}
      </DropdownIsMobileConsumer>
    );
  }
}
