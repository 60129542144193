import { palette } from '../base/color';
import { border } from '../base/border';

const sectionExpanderStyles = {
  border: 'none',
  borderRadius: border.roundedArea.radius,
  transition: 'color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms',
  borderTop: `1px solid ${palette.gray[100]}`,
  boxShadow: '0 0 2px 0 rgba(35,47,63,0.30), 0 1px 4px 0 rgba(35,47,63,0.15);',
  bgActive: palette.gray[100],
  bgHover: palette.gray[50],
  bgOpen: palette.white,
  bgClosed: palette.white,
  bgContent: palette.gray[25],
  iconColor: palette.gray[700],
  iconColorHover: palette.squidInk,
  iconColorActive: palette.squidInk,
};

export default sectionExpanderStyles;
