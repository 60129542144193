const WHITE = '#FFF';

const BorderColors = {
  gold: '#DE7921',
  inkwell: '#232F3F',
}

const FillColors = {
  gold: '#FFA41C',
  inkwell: '#232F3F',
}

export type StarColorsType = 'gold' | 'inkwell';
/**
 * Returns a star SVG with a border and two halves.
 * Both halves are colored depending on if a full, half or empty star is required.
 * @param {boolean} startFilled Determines if the first half of the star SVG should be filled.
 * @param {boolean} endFilled Determines if the second half of the star SVG should be fllled.
 * @param {StarColorsType} starColor Determines the color the star should be fllled and the color of the star border.
 * @return {string} Returns a string literal of a star SVG.
 */
const getStarSVG = (startFilled: boolean, endFilled: boolean, className: string, starColor: StarColorsType = 'gold'): string => `
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 512" class="${className}">
    <path fill="${BorderColors[starColor]}" d="M349.97 179.51 272.78 8l-77.17 171.51L8 196.83l147.01 124.28L107.76 504l165.1-93.94L438.03 504l-44.01-182.89 143.56-124.33z" />
    <path fill="${endFilled ? FillColors[starColor] : WHITE}" d="m217.65 210.52 54.92-122.07 54.95 122.08 129.52 11.93-100.1 86.69 30.86 128.21-115.15-65.49-113.53 64.6 32.9-127.34-102.63-86.76" />
    <path fill="${startFilled ? FillColors[starColor] : WHITE}" d="m217.86 210.66 54.92-122.07.08 283.42-113.53 64.6 32.9-127.34L89.6 222.51z" />
  </svg>
`;

export default getStarSVG;
