import { times } from '@amzn/storm-ui-icons';
import React, {
  RefObject,
  FC,
  ComponentProps,
} from 'react';
import Icon from '../Icon';
import { useTaktId, createStormTaktId } from '../TaktIdContext';
import {
  CloseButton,
  SheetClose,
} from './Sheet.styles';

export interface CloseButtonSectionProps {
  onClose?: ComponentProps<typeof CloseButton>['onClick'];
  closeRef: RefObject<HTMLButtonElement>;
  closeButtonProps?: Record<string, unknown>;
  closeButton: {
    type: 'icon' | 'message';
    label: string;
  };
  side?: 'bottom' | 'start' | 'end';
}

export const CloseButtonSection: FC<React.PropsWithChildren<CloseButtonSectionProps>> = ({
  onClose,
  closeRef,
  closeButtonProps,
  closeButton,
  side = 'bottom',
}) => {
  const { getDataTaktAttributes } = useTaktId({ taktId: closeButtonProps?.taktId as (string | undefined), fallbackId: createStormTaktId('close-button') });

  return (
    <SheetClose>
      <CloseButton
        tabIndex={0}
        onClick={onClose}
        aria-label={closeButton.label}
        ref={closeRef}
        type="button"
        side={side}
        {...getDataTaktAttributes({ taktValue: closeButtonProps?.taktValue as (Record<string, unknown> | undefined) })}
        {...closeButtonProps}
      >
        {closeButton.type === 'icon'
          ? (<Icon type={times} size="lg" />)
          : closeButton.label}
      </CloseButton>
    </SheetClose>
  );
}

CloseButtonSection.defaultProps = {
  onClose: undefined,
  closeButtonProps: undefined,
  side: 'bottom',
};
