import { useState, useEffect } from 'react';

export interface IStormConfig {
  evolutionTheme: boolean;
}

export interface IConfigObserverProps {
  children: (config: IStormConfig) => JSX.Element,
}

const defaultStormConfig: IStormConfig = { evolutionTheme: false };

type StormConfigChangeListener = (config: IStormConfig) => void;

/**
 * Class that stores the configuration values for Storm. It is used by ConfigObserver
 * and is initialized using either default values or values retrieved from the window.
 *
 * When ConfigObservers are rendered in the DOM, their config state setter functions
 * are added as functions to be called when config properties change.
 */
class StormConfig {
  /**
   * There is a case where multiple copies of Storm can be loaded on the page.  Each
   * copy of Storm has its own StormConfig class which caused 'instanceof' checks to
   * fail. Instead, we check for this meaningless class member. Do not touch or the
   * spooky monsters will get you.
   */
  #zombieVampireWerewolfGhost: string;

  // The Storm config object
  #config: IStormConfig;

  // An array of config state setter functions
  #listeners: StormConfigChangeListener[];

  constructor(configArray: IStormConfig[]) {
    this.#zombieVampireWerewolfGhost = 'zombieVampireWerewolfGhost';
    this.#listeners = [];
    this.#config = configArray.reduce((acc, current) => ({
      ...acc,
      ...current,
    }), { ...defaultStormConfig });
  }

  // Getter for spooky monsters
  get spookyMonstersInside() {
    return this.#zombieVampireWerewolfGhost;
  }

  // Getter for the config object.
  get value() {
    return this.#config;
  }

  // Adds a config state setter to listeners so that it is called when config
  // settings are pushed to the stormConfig.
  addChangeListener(listener: StormConfigChangeListener) {
    if (this.#listeners.indexOf(listener) === -1) {
      this.#listeners.push(listener);
    }
  }

  // Removes a config state setter from listeners so that it isn't called when config
  // settings are pushed to the stormConfig.
  removeChangeListener(listener: StormConfigChangeListener) {
    const listenerIdx = this.#listeners.indexOf(listener);
    if (listenerIdx !== -1) {
      this.#listeners.splice(listenerIdx, 1);
    }
  }

  // This function is only used by builders who wish to test Storm config settings using
  // the browser development tools. (e.g. window.stormConfig.push({ evolutionTheme: true });)
  // When push is called, new config values are spread onto the old config and every ConfigObserver
  // in the document will update to use the new values.
  push(config: IStormConfig) {
    this.#config = { ...this.#config, ...config };
    this.#listeners.forEach(listener => {
      listener(this.#config);
    });
  }
}

declare global {
  interface Window {
    stormConfig: StormConfig | IStormConfig[];
  }
}

const isStormConfig = (config:unknown): config is StormConfig => (
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (config !== null) && (typeof config === 'object' && 'spookyMonstersInside' in config!));

/**
 * Higher order component that manages the state of the Storm config. It looks for
 * configuration values set on the window and converts those settings to state which
 * affect how Storm components render in the DOM.
 *
 * Console and DSP navigation will add storm configuration values on the window. This
 * component detects those values and translates them to state.
 */
const ConfigObserver = ({ children }: IConfigObserverProps): JSX.Element => {
  // Initialize a new storm config if we haven't already and if we are rendering in an
  // environment that has a global window. It is possible that a StormConfig has already
  // been initialized (i.e. nested ThemeProviders).
  if (typeof window !== 'undefined' && !isStormConfig(window.stormConfig)) {
    window.stormConfig = new StormConfig(
      Array.isArray(window.stormConfig) ? window.stormConfig : [defaultStormConfig],
    );
  }

  // Hold the state of the storm Config on the window.
  const [configState, setConfigState] = useState<IStormConfig>(
    (typeof window !== 'undefined' && isStormConfig(window.stormConfig))
      ? window.stormConfig.value
      : defaultStormConfig,
  );

  useEffect(() => {
    // To support builders being able to update Storm config values in the console of the
    // browser developer tools, we add the config state setter. This tells the StormConfig
    // to update this config when a config setting is changed.
    if (typeof window !== 'undefined' && isStormConfig(window.stormConfig)) {
      window.stormConfig.addChangeListener(setConfigState);
    }

    // Cleanup remove the config state setter from those which will be updated by StormConfig.
    return () => {
      if (typeof window !== 'undefined' && isStormConfig(window.stormConfig)) {
        window.stormConfig.removeChangeListener(setConfigState);
      }
    };
  }, []);

  return children(configState);
};

export default ConfigObserver;
