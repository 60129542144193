import { palette, color } from '../base/color';
import { spacing, spacingValues } from '../base/spacing';
import { focus as focusVisible } from '../base/focus';
import globals from '../base/globals';

const theme = {
  isEvolutionRebrand: true,
  color,
  focusVisible,
  globals,
  palette,
  spacing,
  spacingValues,
};

export type Theme = typeof theme

export default theme;
