/**
 * Regex to test for a properly formated meridiem. It must be AM or PM
 */
const isMeridiem = /^[ap]m$/i;

/**
 * Convert a object with members {hour:#,minute:#,meridiem:{'AM'|'PM'}} into a 24 hour time string
 * @param  {string} options.hour     hour value
 * @param  {string} options.minute   minute value
 * @param  {string} options.meridiem meridiem value
 * @param  {object} options.         time object
 * @return {string}                  String representation of the time in `HH:mm` format
 */
function compose24HourTime({ hour = '', minute = '', meridiem }: {hour?: string | null, minute?: string | null, meridiem?: string | null}): string {
  let timeString = '';

  /* We use NaN here when a incoming value is not acceptable. It is convenient because a NaN used
   * any math operation returns a NaN. So we can chain math functions and a bad value will not
   * create a runtime error. We just need to check if the final value is a NaN or not.
   */
  const hourNumber = Number(hour && hour.length > 1 ? hour : NaN) % 12;
  const minuteNumber = Number(minute && minute.length > 1 ? minute : NaN) % 60;

  /* If hour or minute have become NaN, then they where badly formatted numbers. */
  if (!Number.isNaN(hourNumber) && !Number.isNaN(minuteNumber) && meridiem && isMeridiem.test(meridiem)) {
    const isPM = (meridiem.toUpperCase() === 'PM');
    timeString = [
      String(hourNumber + (isPM ? 12 : 0)).padStart(2, '0'), // hour
      ':', // divider character
      String(minuteNumber).padStart(2, '0'), // minute
    ].join('');
  }

  return timeString;
}

export default compose24HourTime;
