import {
  GetDataTaktAttributesFunctionProps,
  GetDataTaktAttributes,
  DataTaktAttributes,
} from './TaktIdContext.types';
/**
 * helper method to prepend any value with a standard storm specific string identifier
 */
export const createStormTaktId = (id?: string) => (id ? `storm-ui-${id}` : undefined);

export const getDataTaktAttributesFunction = (
  {
    taktId,
    taktIdContext,
    taktValue: taktValueFromProvider,
    taktIdPrefix,
  }: GetDataTaktAttributesFunctionProps,
): GetDataTaktAttributes => (params = {}) => {
  const {
    taktValue,
    contextOnlyTaktValues,
    taktIdSuffix,
  } = params;
  const {
    taktFeatureChain,
    taktValues,
    taktPrefix,
  } = taktIdContext;
  const dataTaktAttributes = {} as DataTaktAttributes;
  if (taktId) {
    const fullTaktId = [
      taktIdPrefix ?? taktPrefix,
      taktId,
      taktIdSuffix,
    ].filter(part => !!part).join('-');

    dataTaktAttributes['data-takt-id'] = fullTaktId;
  }
  if (taktFeatureChain) {
    dataTaktAttributes['data-takt-feature'] = taktFeatureChain;
  }

  const dataTaktValue = {
    ...(taktFeatureChain ? contextOnlyTaktValues : {}),
    ...(taktFeatureChain ? taktValues : {}),
    ...taktValueFromProvider,
    ...taktValue,
  };

  if (Object.keys(dataTaktValue).length !== 0) {
    dataTaktAttributes['data-takt-value'] = JSON.stringify(dataTaktValue);
  }

  return dataTaktAttributes;
}
export const setDataTaktAttributesOnElement = (element?: HTMLElement, dataTaktAttributes: DataTaktAttributes = {}) => {
  if (dataTaktAttributes['data-takt-id']) {
    element?.setAttribute('data-takt-id', dataTaktAttributes['data-takt-id'])
  }
  if (dataTaktAttributes['data-takt-feature']) {
    element?.setAttribute('data-takt-feature', dataTaktAttributes['data-takt-feature'])
  }
  if (dataTaktAttributes['data-takt-value']) {
    element?.setAttribute('data-takt-value', dataTaktAttributes['data-takt-value'])
  }
}
