import { RefObject } from 'react';
/**
 * Each modal-like element with a inline portal must set a 'z-index' value to prevent its
 * sibling elements from "cutting through" and being visible over child portals.
 * `adoptAncestorZIndex()` walk up the DOM tree looking for a ancestor with a computed `z-index`
 * that is a number. When one is found it, adopts the ancestor `z-index` and increments it
 * by a fixed value. This alow each modal-like element with a inline portal to have
 * a defined range of `z-index`s it may use internally.
 *
 * @param ref A React Ref to the element that will receive the z-index
 * @param zIndexIncrement Number value to increase the Ancestor's z-index by
 */
export default function adoptAncestorZIndex(
  ref: RefObject<HTMLElement>,
  zIndexIncrement: number,
): void {
  if (typeof window !== 'undefined' && ref.current) {
    let ancestorZIndex = 0;
    let { parentElement } = ref.current;

    /** starting at its parent, loop over all the an ancestor till parentElement is falsie.
     * This insures that the loop end when the top of the tree is found.
    */
    while (parentElement) {
      const parentElementZIndexString = window.getComputedStyle(parentElement).getPropertyValue('z-index');

      /** Determine when a `z-index` is numeric by parsing it and comparing the result
       * back to the original string. */
      const parentElementZIndexNumber = parseInt(parentElementZIndexString, 10);
      if (parentElementZIndexString === parentElementZIndexNumber.toString()) {
        /** When a numeric z-index is found exit the loop */
        ancestorZIndex = parentElementZIndexNumber;
        parentElement = null;
        break;
      }
      parentElement = parentElement.parentElement;
    }
    if (ref.current) {
      /** Adopt the found `z-index` incremented by the `zIndexIncrement` value */
      ref.current.style.setProperty('z-index', `${ancestorZIndex + zIndexIncrement}`);
    }
  }
}
