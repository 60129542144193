/* eslint-disable id-length */
import React, {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
} from 'react';
import DateInputContext from './DateInputContext';
import { AllPickerProps } from '../../types';
// import getLocaleDateFormat from '../../utils/getLocaleDateFormat';

export interface DateInputProviderProps {
  baseProps: AllPickerProps;
  children: ReactNode;
}

const DateInputProvider: FC<React.PropsWithChildren<DateInputProviderProps>> = ({
  children,
  baseProps,
}) => {
  const {
    label,
    prefixAriaLabel,
    inputRef = undefined,
    value = '',
    disabled = false,
    placeholder,
    onClick,
    onBlur,
    onKeyDown,
    onKeyDownPrefix,
    onKeyUpPrefix,
    ...rest
  } = baseProps;

  // TODO: come back to DatePickerInput type-ability
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // const tVal = (e.target as HTMLInputElement)?.value;

    // const date = tVal && isValidDate(tVal)
    //   ? formatDateToTimeZone(new Date(Date.parse(tVal)), zone)
    //   : undefined;

    // (baseProps as DateInputProps).onChange?.(date, e);
  }, []);

  // const localePlaceholder = placeholder || getLocaleDateFormat(locale.code).toUpperCase();

  return (
    <DateInputContext.Provider
      value={{
        label,
        prefixAriaLabel,
        inputRef,
        value,
        disabled,
        onClick,
        handleInputChange,
        onBlur,
        onKeyDown,
        placeholder,
        onKeyDownPrefix,
        onKeyUpPrefix,
        ...rest,
      }}
    >
      {children}
    </DateInputContext.Provider>
  );
};

export default DateInputProvider;
