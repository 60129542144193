import { palette } from '../base/color';

const pageLayout = {
  section: {
    background: {
      default: palette.gray[0],
      offset: palette.gray[25],
    },
    contentMaxWidth: {
      xsmall: '320px',
      small: '540px',
      base: '960px',
      large: '1320px',
      fluid: '100%',
    },
  },
};

export default pageLayout;
