import { useContext } from 'react';
import FocusContext from '../contexts/Focus/FocusContext';
import { FocusContextValue } from '../types';

const useFocus = (): FocusContextValue => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error(
      'Focus context is not defined.',
    );
  }
  return context;
};

export default useFocus;
