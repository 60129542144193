import PT from 'prop-types';
import React, {
  ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { Theme as DefaultThemeType } from '../themes/default';
import getMatchMediaFunction from './getMatchMediaFunction';
import defaultMobileTheme from '../themes/mobile';
import getMobileTheme, { Theme as MobileThemeType } from './getMobileTheme';
import MobileMode from './MobileMode';
import MobileModeChangeSource from './MobileModeChangeSource';
import MobileModeHint from './MobileModeHint';
import MobileThemeContext from './MobileThemeContext';
import { MaybeOnMobileModeChange } from './types';
import useDefaultStateManager from './useDefaultStateManager';
import isMobileMediaQuery from './isMobileMediaQuery';

export interface MobileThemeProviderProps {
    children: ReactNode;
    /**
     * @defaultValue `"auto"`
     */
    mobileMode?: MobileMode;
    /**
     * @defaultValue `"mobile"`
     */
    mobileModeHint?: MobileModeHint;
    /**
     * @defaultValue `undefined`
     */
    onMobileModeChange?: MaybeOnMobileModeChange;
}

type ReducerState = {
  browserIsMobile?: boolean;
  userIsMobile?: boolean;
}

interface MatchMediaChangeEvent {
  matches: boolean;
}

function MobileThemeProvider(props: MobileThemeProviderProps) {
  const {
    mobileMode = MobileMode.Auto,
    mobileModeHint = MobileModeHint.Mobile,
    onMobileModeChange: externalOnMobileModeChange,
    children,
  } = props;

  const defaultTheme: DefaultThemeType = useContext<DefaultThemeType>(ThemeContext);

  /* use the mediaQuery from the theme context if it is available */
  const mediaQuery = isMobileMediaQuery(defaultTheme)
    ? (defaultTheme as MobileThemeType).mobile.mediaQuery
    : defaultMobileTheme.mobile.mediaQuery;

  /* Create a matchMedia object. Recreate it if the mediaQuery changes */
  const isMobileMatchMedia = useMemo(
    () => getMatchMediaFunction(mobileModeHint)(mediaQuery),
    [mediaQuery],
  );

  const [browserIsMobile, setBrowserIsMobile] = useState<boolean>(isMobileMatchMedia.matches);

  const {
    isMobile: autoIsMobile,
    onMobileModeChange: defaultOnMobileModeChange,
  } = useDefaultStateManager(isMobileMatchMedia.matches);

  /* Use the external state manager when it is available */
  const onMobileModeChange = externalOnMobileModeChange ?? defaultOnMobileModeChange;

  useEffect(() => {
    /* setup a listener for browser change its mobile/not-mobile status */
    const isMobileMatchMediaListener = (event: MatchMediaChangeEvent) => {
      /* only fire a onMobileModeChange if mobileMode has changed */
      setBrowserIsMobile(event.matches);
      onMobileModeChange({
        source: MobileModeChangeSource.Browser,
        mobileMode: event.matches ? MobileMode.Enable : MobileMode.Disable,
      });
    };

    isMobileMatchMedia.addListener(isMobileMatchMediaListener);

    /* Fire a change event on mount if the hint does not match what the browser detected */
    if (isMobileMatchMedia.matches !== (mobileModeHint === MobileModeHint.Mobile)) {
      setBrowserIsMobile(isMobileMatchMedia.matches);
      isMobileMatchMediaListener({ matches: isMobileMatchMedia.matches });
    }
    return () => {
      /* cleanup match media event listener */
      isMobileMatchMedia.removeListener(isMobileMatchMediaListener);
    };
  }, [isMobileMatchMedia, onMobileModeChange, mobileModeHint]);

  /* Use internal logic to pick mobile/not-mobile in "Auto" mode */
  let isMobile = autoIsMobile;

  /* use the mobileMode if it is set to "Enable" or "Disable" */
  if (mobileMode === MobileMode.Enable) {
    isMobile = true;
  } else if (mobileMode === MobileMode.Disable) {
    isMobile = false;
  }

  /* memoize complex context values to prevent unneeded re-renders */
  const mobileThemeContextValue = useMemo(
    () => ({ isMobile, onMobileModeChange, browserIsMobile }),
    [isMobile, onMobileModeChange, browserIsMobile],
  );

  /* Create the mobile theme and memoize it */
  const mobileTheme = useMemo<MobileThemeType>(
    () => {
      if (isMobile === browserIsMobile && mobileMode === MobileMode.Auto) {
        return (getMobileTheme(MobileMode.Auto)(defaultTheme));
      }
      return (getMobileTheme(isMobile ? MobileMode.Enable : MobileMode.Disable)(defaultTheme));
    },
    /* regenerate the theme if incoming theme or mode changes */
    [mobileMode, defaultTheme, isMobile],
  );

  return (
    <ThemeContext.Provider value={mobileTheme}>
      <MobileThemeContext.Provider value={mobileThemeContextValue}>
        {children}
      </MobileThemeContext.Provider>
    </ThemeContext.Provider>
  );
}

MobileThemeProvider.propTypes = {
  mobileMode: PT.oneOf([
    MobileMode.Auto,
    MobileMode.Enable,
    MobileMode.Disable,
  ]),
  mobileModeHint: PT.oneOf([
    MobileModeHint.Mobile,
    MobileModeHint.NotMobile,
  ]),
  onMobileModeChange: PT.func,
  children: PT.node.isRequired,
};

MobileThemeProvider.defaultProps = {
  mobileMode: MobileMode.Auto,
  mobileModeHint: MobileModeHint.Mobile,
  onMobileModeChange: undefined,
};

export default MobileThemeProvider;
