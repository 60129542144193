/* eslint-disable id-length */
import React, { FC, MouseEvent } from 'react';
import {
  Button,
  createStormTaktId,
  isMobileStyleMixin,
  TaktProps,
  TextButton,
  useTaktId,
} from '@amzn/storm-ui';
import styled, { css } from 'styled-components';

const ConfirmationControl = styled('div')`
  align-self: flex-end;
  background: ${({ theme }) => theme.datepicker.bg};
  margin: 0;
  margin-inline-end: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.base};

  ${isMobileStyleMixin(css`
    display: flex;
    flex-direction: column;
    align-self: normal;
    margin: 0;
    margin-top: ${({ theme }) => theme.mobile.spacing.mini};
  `)}
`;

const ControlTextButton = styled(TextButton)`
  min-width: 80px;

  ${isMobileStyleMixin(css`
    padding: ${({ theme }) => theme.mobile.spacing.mini};
  `)}
`;

const ControlButton = styled(Button)`
  min-width: 80px;

  ${isMobileStyleMixin(css`
    padding: ${({ theme }) => theme.mobile.spacing.mini};
  `)}
`;

export interface DateRangeConfirmationControlProps extends TaktProps {
  cancelText: string;
  saveText: string;
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  isStartValid: boolean;
  isMobile: boolean | undefined;
}

const DateRangeConfirmationControl: FC<React.PropsWithChildren<DateRangeConfirmationControlProps>> = ({
  cancelText,
  saveText,
  onSubmit,
  onCancel,
  isStartValid,
  isMobile,
  taktId,
  taktValue,
}) => {
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-picker-confirmation-control') });

  const CancelButton = () => (
    <ControlTextButton
      {...getDataTaktAttributes({ taktValue, taktIdSuffix: 'cancel' })}
      onClick={onCancel}
    >
      {cancelText}
    </ControlTextButton>
  );
  const SaveButton = () => (
    <ControlButton
      {...getDataTaktAttributes({ taktValue, taktIdSuffix: 'save' })}
      primary
      onClick={onSubmit}
      disabled={!isStartValid}
    >
      {saveText}
    </ControlButton>
  );
  return (
    <ConfirmationControl>
      {
        isMobile ? (
          <>
            <SaveButton />
            <CancelButton />
          </>
        ) : (
          <>
            <CancelButton />
            <SaveButton />
          </>
        )
      }
    </ConfirmationControl>
  );
};

export default DateRangeConfirmationControl;
