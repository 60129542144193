import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

interface ContainerProps {
  $numColumns?: number
}

export const Container = styled('div')<ContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({ $numColumns }) => (typeof $numColumns === 'number' ? $numColumns : 'auto-fit')}, minmax(220px, 1fr));
  gap: ${({ theme }) => theme.spacing.base};

  ${isMobile(css`
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
  `)}
`

export const Fieldset = styled('fieldset')`
  border: none;
  margin: 0;
  padding: 0;
`

export const MessageWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing.base} 0;

  ${isMobile(css`
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.button.toggleArea.bg};
  `)}
`;
