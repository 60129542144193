import React, {
  FunctionComponent,
  UIEvent,
  useContext,
} from 'react';
import PT from 'prop-types';
import { upload } from '@amzn/storm-ui-icons';
import styled from 'styled-components';
import Alert from '../../Alert/Alert';
import Button, { ButtonProps } from '../../Button/Button'
import Icon from '../../Icon/Icon'
import Text from '../../Text';
import FileUploadContext from '../FileUploadContext';
import type { IconDefinition } from '../../Icon/types';

export interface FileUploadButtonProps extends ButtonProps {
  /** Text displayed as Button component label */
  label: string;
  /** Should the label be hidden in the button */
  hideLabel?: boolean;
  /** The icon to display within the button */
  icon?: IconDefinition | string;
}
interface ContainerProps {
  $fullWidth?: boolean;
}

const ButtonIcon = styled(Icon)<{ $hideLabel: boolean }>`
  margin-inline-end: ${({ theme, $hideLabel }) => ($hideLabel ? 'none' : theme.spacing.mini)};
`;

const Container = styled('div')<ContainerProps>`
  display: ${({ $fullWidth }) => ($fullWidth ? 'block' : 'inline-block')};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  && > button {
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  }
`;

const CriteriaListContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.typography.color.secondary};
`;

const UploaderAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing.mini};
`;

const FileUploadButton: FunctionComponent<FileUploadButtonProps> = ({
  label,
  hideLabel = false,
  icon,
  ...rest
}) => {
  const {
    description,
    disabled,
    errorText,
    fullWidth,
    inputRef,
    onCloseAlert,
  } = useContext(FileUploadContext);

  const handleClick = (event: UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputRef?.current) {
      /** Reset the value of the input so
       * duplicate files are not ignored */
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  return (
    <Container $fullWidth={fullWidth}>
      <Button
        {...rest}
        disabled={disabled}
        onClick={handleClick}
        aria-label={hideLabel ? label : undefined}
      >
        <ButtonIcon type={icon ?? upload} $hideLabel={hideLabel} />
        {!hideLabel && label}
      </Button>
      {description
        && (
          <CriteriaListContainer>
            {description}
          </CriteriaListContainer>
        )}
      {errorText && (
        <UploaderAlert type="error" role="alert" withCloseButton onClick={onCloseAlert}>
          <Text>{errorText}</Text>
        </UploaderAlert>
      )}
    </Container>
  );
};

FileUploadButton.propTypes = {
  /**
   * Text displayed as Button component label
   */
  label: PT.string.isRequired,
};

export default FileUploadButton;
