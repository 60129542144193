import { spacing, mobileSpacing } from '../base/spacing';
import { fontSizeValues, mobileFontSizeValues } from '../base/typography';
import { palette } from '../base/color';

const helptipStyles = {
  triggerIcon: {
    size: `${fontSizeValues.mini}px`,
    margin: `0 ${spacing.micro}`,
    padding: `${spacing.none}`,
    color: palette.gray[500],
    hoverColor: palette.gray[700],
  },
};

const mobileHelptipStyles = {
  triggerIcon: {
    mobile: {
      size: `${mobileFontSizeValues.base}px`,
      margin: `0 ${mobileSpacing.micro}`,
      padding: '7px',
    },
  },
};

export {
  helptipStyles,
  mobileHelptipStyles,
};
