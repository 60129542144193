import React, {
  KeyboardEvent,
  ReactNode,
  useRef,
  useEffect,
  RefObject,
} from 'react';
import styled, { css } from 'styled-components';
import { scrollIntoBounds, getFocusableElements, keyboardKeynames as keys } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { useTablistContext } from './TablistContext';

const NavWrapper = styled.nav`
  display: flex;

  ${isMobile(css`
    overflow-x: scroll;
    ::-webkit-scrollbar { // Chrome & Safari hide scroll bar
      display: none;
    }
    scrollbar-width: none; // FF hide scroll bar
    padding-inline-end: ${({ theme }) => theme.navigation.tabs.mobile.width};
  `)}
`;

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  ${isMobile(css`
    flex-wrap: nowrap;
    overflow: visible;
  `)}
`;

const getOnKeyDown = (tabGroupRef: RefObject<HTMLUListElement>) => (event: KeyboardEvent<HTMLUListElement>): void => {
  const { key } = event;

  if (tabGroupRef.current) {
    const focusableEls = getFocusableElements(tabGroupRef.current);
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    // When "Left Arrow" or "Right Arrow" is pressed, move focus to the left or right tab
    if (key === keys.ARROW_RIGHT
      || key === keys.ARROW_LEFT
      || key === keys.HOME
      || key === keys.END
    ) {
      const currentIndex = focusableEls.indexOf(document.activeElement as HTMLElement);
      let newFocusIndex = 0;

      // When "Right Arrow" is pressed, focus on the tab to the right
      if (key === keys.ARROW_RIGHT) {
        newFocusIndex = currentIndex + 1;
        if (document.activeElement === lastFocusableEl) {
          newFocusIndex = 0;
        }
      }

      // When "Left Arrow" is pressed, focus on the tab to the left
      if (key === keys.ARROW_LEFT) {
        newFocusIndex = currentIndex - 1;
        if (document.activeElement === firstFocusableEl) {
          newFocusIndex = focusableEls.length - 1;
        }
      }

      // When "End" is pressed, focus on the last tab in the tab group
      if (key === keys.END) {
        newFocusIndex = focusableEls.length - 1;
      }

      // When "Home" is pressed, focus on the first tab in the tab group
      if (key === keys.HOME) {
        newFocusIndex = 0;
      }

      focusableEls[newFocusIndex].focus();
      event.preventDefault();
    }
  }
}

interface ListProps {
  children: ReactNode;
}

const Nav = ({ children }: ListProps) => {
  const { isTablist } = useTablistContext();
  const navRef = useRef<HTMLElement>(null);
  const tabGroupRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (navRef.current) {
      scrollIntoBounds(
        navRef.current,
        navRef.current?.querySelector('[aria-selected=true]'),
      );
    }
  }, []);

  return (
    <NavWrapper ref={navRef}>
      <StyledUl
        ref={tabGroupRef}
        {...(isTablist ? {
          role: 'tablist',
          onKeyDown: getOnKeyDown(tabGroupRef),
        } : {})}
      >
        {children}
      </StyledUl>
    </NavWrapper>
  );
};

export default Nav;
