/* eslint-disable id-length */
import { palette } from '../base/color';
import { typography } from '../base/typography';
import { spacing, mobileSpacing } from '../base/spacing';

export const dividerStyles = {
  bg: palette.white,
  color: palette.gray[100],
  margin: `${spacing.base} 0`,
  textPadding: `0 ${spacing.mini}`,
  textColor: typography.color.tertiary,
};

export const mobileDividerStyles = {
  mobile: {
    margin: `${mobileSpacing.base} 0`,
    textPadding: `0 ${mobileSpacing.mini}`,
  },
};
