import React, {
  ReactNode, MouseEvent, KeyboardEvent, FunctionComponent as FC,
} from 'react';
import styled from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import type { TaktProps } from '../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import Card from '../Card/Card';
import Content from './Content';
import DefaultTitle from './DefaultTitle';
import DefaultFooter from './DefaultFooter';
import DefaultBody from './DefaultBody';
import DefaultImage from './DefaultImage';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';

const StyledTitle = styled('span')`
  word-break: break-word;
`;

const StyledCard = styled(Card)<{ $isSelected: boolean | undefined }>`
  ${({ $isSelected, theme }) => $isSelected && {
    'background-color': theme.card.selected.bg,
    'border-color': theme.card.selected.borderColor,
  }}
`;

const FooterContainer = styled.button
  .attrs(props => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return {
      ...mergeProps(props, focusProps),
      focusVisible: isFocusVisible,
      type: 'button',
    };
  })`
  ${({ theme }) => theme.typography.base}
  color: inherit;
  background-color: unset;
  border: unset;
  width: 100%;
  text-align: start;
  padding: ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  border-radius: ${({ theme }) => `0 0 ${theme.card.radius} ${theme.card.radius}`};
  :focus {
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
  :active {
    color: unset;
  }
`;
FooterContainer.displayName = 'FooterContainer';

export interface CardListItemProps extends TaktProps {
  /**
   * Unique id associated with the list item.
   */
  id: string;
  /**
   * @defaultValue `""`
   */
  className?: string,
  /**
   * Callback for when the checkbox is selected. If not passed, the checkbox is not displayed.
   * @defaultValue `undefined`
   */
  onSelect?: (event: MouseEvent | KeyboardEvent) => void;
  /**
   * Specifies if the card should appear as selected.
   * @defaultValue `false`
   */
  isSelected?: boolean;
  /**
   * Callback for when the card is clicked. If not passed, the card is not displayed as clickable.
   * @defaultValue `undefined`
   */
  onClick?: (event: MouseEvent | KeyboardEvent) => void;
  /**
   * Title displayed for the card list item.
   * @defaultValue `undefined`
   */
  title?: ReactNode;
  /**
   * Content rendered in the footer section. If not passed, no footer is displayed.
   * @defaultValue `undefined`
   */
  footer?: ReactNode;
  /**
   * Callback triggered when the footer is clicked.
   * @defaultValue `undefined`
   */
  onFooterClick?: (event: MouseEvent | KeyboardEvent) => void;
  /**
   * Labels are required for screen reader accessibility.
   */
  ariaLabel: string;
  /**
   * The content of the `<CardListItem />`.
   */
  children: ReactNode;
}

export interface ICardListItem extends FC<CardListItemProps> {
  DefaultFooter: typeof DefaultFooter;
  DefaultBody: typeof DefaultBody;
  DefaultTitle: typeof DefaultTitle;
  DefaultImage: typeof DefaultImage;
}

const CardListItem: ICardListItem = ({
  id,
  className,
  isSelected,
  onSelect,
  onClick,
  title,
  footer,
  onFooterClick,
  ariaLabel,
  children,
  taktId,
  taktValue,
  ...restProps
}: CardListItemProps): JSX.Element => (
  <StyledCard
    className={className}
    id={id}
    padding="none"
    $isSelected={isSelected}
    taktId={taktId}
  >
    <Content
      {...restProps}
      id={id}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={onClick}
      ariaLabel={ariaLabel}
    >
      <StyledTitle>{ title }</StyledTitle>
      { children }
    </Content>
    { footer && (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('card-footer')}>
        {({ getDataTaktAttributes }) => (
          <FooterContainer
            {...getDataTaktAttributes()}
            onClick={(event: MouseEvent) => onFooterClick && onFooterClick(event)}
          >
            { footer }
          </FooterContainer>
        )}
      </TaktIdConsumer>
    )}
  </StyledCard>
);

CardListItem.defaultProps = {
  className: '',
  onSelect: undefined,
  isSelected: false,
  onClick: undefined,
  title: undefined,
  footer: undefined,
  onFooterClick: undefined,
};

CardListItem.DefaultTitle = DefaultTitle;
CardListItem.DefaultFooter = DefaultFooter;
CardListItem.DefaultBody = DefaultBody;
CardListItem.DefaultImage = DefaultImage;

export default CardListItem;
