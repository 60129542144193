import React, { FC } from 'react';
import { angleInlineStart } from '@amzn/storm-ui-icons-v3';
import { createStormTaktId } from '../../TaktIdContext';
import Icon from '../../Icon/Icon';
import PaginationButton, { PaginationNavProps } from './PaginationButton';

const PaginationPrevButton: FC<React.PropsWithChildren<PaginationNavProps>> = (
  props: PaginationNavProps,
): JSX.Element => (
  <PaginationButton taktFallbackId={createStormTaktId('pagination-prev-button')} {...props}>
    <Icon type={angleInlineStart} blockSize />
  </PaginationButton>
);
PaginationPrevButton.displayName = 'PaginationPrevButton';

export default PaginationPrevButton;
