import React, {
  ChangeEvent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../../TaktIdContext';
import { renderInputValidationMessage } from '../../InputFormGroup/InputFormGroup';
import type { StatusType } from '../../InputFormGroup/types';
import { TileGroupContextProvider } from '../TileGroupContext';
import type { TileDirection, TileInputValue } from '../types';
import {
  Fieldset,
  Container,
  MessageWrapper,
} from './TileGroup.styles';

export interface TileGroupProps<T> extends TaktProps {
  /**
   * Content of the TileGroup (Tiles).
   */
  children: ReactNode;
  /**
   * The name of the TileGroup. This MUST be unique, and it allows the group to manage all children properly.
   */
  name: string;
  /**
   * Set this to have a fixed number of columns.
   * @defaultValue `undefined`
   */
  numColumns?: number;
  /**
   * How Tile's header, body and footer are placed. Can be 'column' or 'row'.
   * @defaultValue `undefined`
   */
  direction?: TileDirection;
  /**
   * Set this to true to indicate the Tiles are disabled.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * Adds a message below the input, which will update based on error/success/warning.
   * @defaultValue `undefined`
   */
  message?: string;
  /**
   * Specifies the type of message to be displayed. Can be 'error', 'warning', 'success'. (Default is 'error')
   * @defaultValue `"error"`
   */
  statusType?: StatusType;
  /**
   * The page unique identifier of the input.
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * Set this to true to indicate that the user can select multiple Tiles.
   * @defaultValue `false`
   */
  multiple?: boolean;
  /**
   * Called when the user changes the selected values.
   * @defaultValue `undefined`
   */
  onChange?: (
    selected: T[],
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  /**
   * Values selected by the user. Can be number or string.
   * @defaultValue `[]`
   */
  selectedValues?: T[];
}

function TileGroup<T extends TileInputValue>({
  children,
  numColumns,
  direction,
  disabled,
  message,
  statusType,
  id,
  multiple,
  name,
  onChange,
  selectedValues,
  taktId,
  taktValue,
  ...restProps
}: TileGroupProps<T>) {
  const messageId = `${name}-${id}-tile-group-message`;
  const a11yProps = message ? {
    'aria-describedby': messageId,
    ...(statusType === 'error' ? { 'aria-invalid': true } : {}),
  } : {};

  return (
    <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tile-group')}>
      <TileGroupContextProvider
        direction={direction ?? 'column'}
        disabled={disabled ?? false}
        multiple={multiple ?? false}
        name={name}
        onSelect={(
          value: T,
          event: ChangeEvent<HTMLInputElement>,
        ) => {
          if (onChange) {
            let values = [value];
            if (multiple) {
              const prevSelectedValues = [...(selectedValues ?? [])];
              const index = prevSelectedValues.indexOf(value);
              if (index !== -1) {
                values = [...prevSelectedValues.slice(0, index), ...prevSelectedValues.slice(index + 1)];
              } else {
                values = [...prevSelectedValues, value];
              }
            }
            onChange(values, event);
          }
        }}
        selectedValues={selectedValues ?? []}
      >
        <Fieldset {...a11yProps} {...restProps} disabled={disabled} id={id}>
          <Container $numColumns={numColumns}>
            {children}
          </Container>
          {message && (
            <MessageWrapper>
              {renderInputValidationMessage(undefined, messageId, message, undefined, statusType)}
            </MessageWrapper>
          )}
        </Fieldset>
      </TileGroupContextProvider>
    </TaktIdProvider>
  )
}
TileGroup.defaultProps = {
  numColumns: undefined,
  direction: undefined,
  disabled: false,
  message: undefined,
  statusType: 'error',
  id: undefined,
  multiple: false,
  onChange: undefined,
  selectedValues: [],
}

TileGroup.propTypes = {
  /**
   * Content of the TileGroup (Tiles).
   */
  children: PT.node.isRequired,
  /**
   * The name of the TileGroup. This MUST be unique, and it allows the group to manage all children properly.
   */
  name: PT.string.isRequired,
  /**
   * Set this to have a fixed number of columns.
   */
  numColumns: PT.number,
  /**
   * How Tile's header, body and footer are placed.
   */
  direction: PT.oneOf(['column', 'row']),
  /**
   * Set this to true to indicate the Tiles are disabled.
   */
  disabled: PT.bool,
  /**
   * Adds a message below the input, which will update based on error/success/warning.
   */
  message: PT.string,
  /**
   * Specifies the type of message to be displayed. Can be 'error', 'warning', 'success'. (Default is 'error')
   */
  statusType: PT.oneOf<StatusType>(['error', 'warning', 'success']),
  /**
   * The page unique identifier of the input.
   */
  id: PT.string,
  /**
   * Set this to true to indicate that the user can select multiple Tiles.
   */
  multiple: PT.bool,
  /**
   * Called when the user changes the selected values.
   */
  onChange: PT.func,
  /**
   * Values selected by the user.
   */
  selectedValues: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
}

export default TileGroup;
