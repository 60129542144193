import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import useIsMobile from '../../theme/MobileThemeProvider/useIsMobile';
import CancelButton from './CancelButton';
import LeaveButton from './LeaveButton';
import { TaktProps } from '../../types/TaktProps';

interface FooterContainerProps {
  isMobile: boolean;
}

const FooterContainer = styled('div')<FooterContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: flex-end;
  gap: 12px;
`

export interface DefaultAbandonmentFooterProps extends TaktProps {
  // Function to handle 'Continue Working' action
  onCancel: () => void;
  // Function to handle 'Leave Page' action
  onLeave: () => void;
}

const DefaultAbandonmentFooter: React.FC<DefaultAbandonmentFooterProps> = ({
  onCancel,
  onLeave,
  ...rest
}) => {
  const isMobile = useIsMobile() || false;
  return (
    <FooterContainer isMobile={isMobile}>
      <CancelButton onCancel={onCancel} {...rest} />
      <LeaveButton onLeave={onLeave} {...rest} />
    </FooterContainer>
  )
}

DefaultAbandonmentFooter.propTypes = {
  onCancel: PT.func.isRequired,
  onLeave: PT.func.isRequired,
}

export default DefaultAbandonmentFooter;
