import { palette } from './color';
import { typography, list, link } from './typography';
import { shadow } from './shadow';

// DO NOT USE
const globals = {
  bodyBackground: palette.concrete,
  bodyFontColor: typography.body.bodyFontColor,
  bodyFontFamily: typography.body.bodyFontFamily,
  bodyFontSize: typography.body.bodyFontSize,
  textDisabledColor: palette.boulder,
  textSecondaryColor: palette.emperor,
  textTertiaryColor: palette.boulder,
  radius: '3px',
  maxWidth: '1200px',
  boxShadowFocus: shadow.focus,
  boxShadowFocusInput: shadow.focusInput,

  link,
  list,
  // Applies to most disabled states
  disabled: `
    cursor: not-allowed;
    opacity: 0.5;
    outline: none;
  `,
};

export default globals;
