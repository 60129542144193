import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { ISODate } from '../types';
import { dateToISODate, START, END } from './dateUtils';

/**
 * Return the given number padded with the specified number of zeroes
 * @param num the given number
 * @param pad the number of zeroes to pad with
 * @returns the given number padded with zeroes
 */
const padNum = (num: number, pad = 2) => num.toString().padStart(pad, '0');

/**
 * Convert the given date to an ISODate
 * @param date the given date
 * @returns the given date as an ISODate
 */
const normalizeDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return date.slice(0, 10);
  }
  return dateToISODate(date);
}

/**
 * Return the given date with timezone applied
 * @param date the given date
 * @param zone the timezone
 * @param timeOfDay the time of the day to set the date to
 * @returns the new date in the given timezone
 */
const createDate = (
  date: Date | string,
  zone: string,
  timeOfDay: string = START,
) => {
  const baseDate = normalizeDate(date);
  const temp = getTimezoneOffset(zone, typeof date === 'string' ? new Date(`${date}T${timeOfDay}Z`) : date) / (1000 * 60);
  const hours = padNum(Math.floor(Math.abs(temp) / 60));
  const mins = padNum(Math.abs(temp) % 60);
  const operand = Math.sign(temp) === -1 ? '-' : '+';
  const offset = `${operand}${hours}:${mins}`;
  return `${baseDate}T${timeOfDay}${offset}`;
}

/**
 * Get the date right now in the given timezone
 * @param today today's date
 * @param zone the given timezone
 * @returns right now in the given timezone
 */
const nowAtTz = (today: ISODate, zone: string) => {
  const zonedTime = utcToZonedTime(new Date(), zone);
  const isoTime = `${padNum(zonedTime.getHours())}:${padNum(zonedTime.getMinutes())}:${padNum(zonedTime.getSeconds())}.${padNum(zonedTime.getMilliseconds(), 3)}`;
  return new Date(createDate(today, zone, isoTime));
}

/**
 * Get the start of the given date in the given timezone
 * @param date the given date
 * @param zone the given timezone
 * @returns the start of the given date in the given timezone
 */
const startOfDayAtTz = (
  date: Date | string,
  zone: string,
) => new Date(createDate(date, zone));

/**
 * Get the end of the given date in the given timezone
 * @param date the given date
 * @param zone the given timezone
 * @returns the end of the given date in the given timezone
 */
const endOfDayAtTz = (
  date: Date | string,
  zone: string,
) => new Date(createDate(date, zone, END));

export {
  nowAtTz,
  startOfDayAtTz,
  endOfDayAtTz,
};
