import React, { PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  check,
  exclamation,
  exclamationTriangle,
  infoCircle,
  opportunities,
  spinner,
} from '@amzn/storm-ui-icons';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import Icon from '../../Icon';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { IconDefinition } from '../../Icon/types';

export type MessageType = 'error' | 'warning' | 'success' | 'loading' | 'info' | 'opportunity';
interface MessageIconProps {
  $messagetype?: MessageType;
}
const MessageIcon = styled(Icon)<MessageIconProps>`
  ${({ theme, $messagetype }) => ($messagetype === 'info' && css`
    color: ${theme.form.input.info.iconColor};
  `)}
  ${({ theme, $messagetype }) => ($messagetype === 'warning' && css`
    color: ${theme.form.input.warning.iconColor};
  `)}
  ${({ theme, $messagetype }) => ($messagetype === 'opportunity' && css`
    color: ${theme.palette.data.indigo[700]};
  `)}
`;
MessageIcon.displayName = 'MessageIcon';

interface MessageProps {
  $messagetype?: MessageType;
}
const Message = styled('span')<MessageProps>`
  align-self: center;
  ${({ theme }) => theme.typography.base};
  font-size: ${({ theme }) => theme.typography.size.small};
  /* set direct descendant elements inside the message to the same font size by default,
     so authors don't have to specify font size for links etc. */
  > {
    p, a, span, b, strong, i, em, ul, ol, abbr, cite {
      font-size: ${({ theme }) => theme.typography.size.small};
    }
  }
  line-height: 1.25;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.micro};
  color:  ${({ theme }) => theme.form.input.message.color};
  svg {
    margin-inline-end: ${({ theme }) => theme.spacing.mini};
  }

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    font-size: ${({ theme }) => theme.typography.mobile.size.small};
    > {
      p, a, span, b, strong, i, em, ul, ol, abbr, cite {
        font-size: ${({ theme }) => theme.typography.mobile.size.small};
      }
    }
    margin-top: ${({ theme }) => theme.mobile.spacing.micro};
    svg {
      margin-inline-end: ${({ theme }) => theme.mobile.spacing.mini};
    }
  `)}

  ${({ $messagetype, theme }) => ($messagetype === 'error' && `
    color:  ${theme.form.input.error.messageColor};
  `)}

  ${({ $messagetype, theme }) => ($messagetype === 'warning' && `
    color:  ${theme.form.input.warning.messageColor};
  `)}

  ${({ $messagetype, theme }) => ($messagetype === 'success' && `
    color:  ${theme.form.input.success.messageColor};
  `)}
`;
Message.displayName = 'Message';

const MessageIconMap: { [key in MessageType] : IconDefinition } = {
  error: exclamation,
  info: infoCircle,
  warning: exclamationTriangle,
  success: check,
  loading: spinner,
  opportunity: opportunities,
};

export interface InlineMessageProps extends MergeElementProps<'span'> {
  /**
   * Specifies the message to be displayed.
   */
  message: ReactNode;
  /**
   * Specifies the type of message to be displayed.
   * @defaultValue `undefined`
   */
  messageType?: MessageType;
}
export default class InlineMessage extends PureComponent<InlineMessageProps> {
  static propTypes = {
    /**
     * Specifies the type of message to be displayed.
     */
    messageType: PT.oneOf(['error', 'warning', 'success', 'loading', 'info', 'opportunity']),
    /**
     * Specifies the message to be displayed.
     */
    message: PT.oneOfType([PT.node, PT.string]).isRequired,
  }

  static defaultProps = {
    messageType: undefined,
  }

  render(): ReactNode {
    const {
      messageType,
      message,
      ...rest
    } = this.props;

    return (
      <Message
        $messagetype={messageType}
        {...rest}
      >
        {messageType && MessageIconMap[messageType] && (
          <MessageIcon
            type={MessageIconMap[messageType]}
            $messagetype={messageType}
          />
        )}
        {message}
      </Message>
    );
  }
}
