/* eslint-disable import/prefer-default-export */
import { palette } from './color';

export const focus = {
  borderWidth: '1px ',
  borderStyle: 'solid',
  borderColor: palette.auiblue,
  boxShadowBlurRadius: '1px',
  boxShadowSpreadRadius: '2px',
  boxShadowColor: 'rgba(0,102,192,0.50)',
  outlineOffset: '2px',
  outlineColor: palette.blue[700],
  outlineStyle: 'solid',
  outlineWidth: '2px',
};
