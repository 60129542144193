import React, { FunctionComponent as FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const Container = styled.div`
  display: flex;
  margin-top: 2px;
`;

const ImageContainer = styled.div`
  min-width: 70px;
  max-width: 70px;
  margin-inline-end: ${({ theme }) => theme.spacing.small};
`;

const Grid = styled.div<{ $minColumnWidth: number }>`
  width: 100%;
  ${({ $minColumnWidth }) => ({
    'column-width': `${$minColumnWidth}px`,
  })}
`;

const Row = styled.div`
  margin-bottom: 2px;
  page-break-inside: avoid;
  break-inside: avoid;
`;

const DataItem = styled.dl`
  margin-block-start: ${({ theme }) => theme.spacing.none};
  margin-block-end: ${({ theme }) => theme.spacing.none};
  display: flex;
  word-break: break-word;
  font-size: ${({ theme }) => theme.typography.size.small};
  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.small};
  `)}
`;

const DataLabel = styled.dt`
  font-weight: normal;
  display: inline;
  float: none;
  ::after {
    content: ":";
  }
`;

const DataValue = styled.dd`
  display: inline;
  float: none;
  margin-inline-start: ${({ theme }) => theme.spacing.micro};
`;

interface DataRow {
  /**
   * Key of the key/value data pair.
   */
  label: string;
  /**
   * Value of the key/value data pair.
   */
  value: string | ReactNode;
  /**
   * Optional content to be displayed after the key/value data pair is rendered.
   */
  renderAfter?: ReactNode;
}

export interface DefaultBodyProps {
  /**
   * List of data pairs to displayed.
   */
  dataRows: DataRow[];
  /**
   * Optional image to be displayed.
   * @defaultValue `undefined`
   */
  image?: ReactNode;
  /**
   * Configurable minimum column width for the data pairs.
   * @defaultValue `250`
   */
  minColumnWidth?: number;
}

const DefaultBody: FC<React.PropsWithChildren<DefaultBodyProps>> = ({
  dataRows,
  image,
  minColumnWidth = 250,
}: DefaultBodyProps): JSX.Element => (
  <Container>
    { image && (
      <ImageContainer>
        { image }
      </ImageContainer>
    )}
    <Grid $minColumnWidth={minColumnWidth}>
      { dataRows.map(({ label, value, renderAfter }, index) => (
        <Row key={index}>
          <DataItem>
            <DataLabel>{ label }</DataLabel>
            <DataValue>{ value }</DataValue>
          </DataItem>
          { renderAfter && renderAfter }
        </Row>
      ))}
    </Grid>
  </Container>
);

DefaultBody.defaultProps = {
  image: undefined,
  minColumnWidth: 250,
};

export default DefaultBody;
