import { PureComponent } from 'react';
import PT from 'prop-types';

interface TimeInputFocusControllerChildrenProps {
    focus: string | null;
    focusNext: () => void;
    focusPrevious: () => void;
    onFocusHour: () => void;
    onFocusMinute: () => void;
    onFocusMeridiem: () => void;
    onBlur: () => void;
}

interface TimeInputFocusControllerProps {
  children: (props: TimeInputFocusControllerChildrenProps) => JSX.Element;
}

interface TimeInputFocusControllerState {
  focus: string | null;
}

class TimeInputFocusController extends PureComponent<TimeInputFocusControllerProps, TimeInputFocusControllerState> {
  static propTypes = {
    children: PT.func.isRequired,
  }

  blurTimeout?: NodeJS.Timeout;

  constructor(props: TimeInputFocusControllerProps) {
    super(props);

    this.state = {
      focus: null,
    };
  }

  componentWillUnmount(): void {
    this.clearBlurTimeout();
  }

  clearBlurTimeout = (): void => {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
  }

  focusNext = (): void => {
    const { focus } = this.state;
    if (focus === 'hour') {
      this.setState({ focus: 'minute' });
    } else if (focus === 'minute') {
      this.setState({ focus: 'meridiem' });
    }
  }

  focusPrevious = (): void => {
    const { focus } = this.state;
    if (focus === 'meridiem') {
      this.setState({ focus: 'minute' });
    } else if (focus === 'minute') {
      this.setState({ focus: 'hour' });
    }
  }

  onFocusHour = (): void => {
    this.clearBlurTimeout();
    this.setState({ focus: 'hour' });
  }

  onFocusMinute = (): void => {
    this.clearBlurTimeout();
    this.setState({ focus: 'minute' });
  }

  onFocusMeridiem = (): void => {
    this.clearBlurTimeout();
    this.setState({ focus: 'meridiem' });
  }

  onBlur = (): void => {
    this.clearBlurTimeout();

    /*
     * we want to transactional-ize the state update because we get a blur before each focus,
     * but we don't want to send a state with a empty focus.
     */
    this.blurTimeout = setTimeout(() => {
      this.setState({ focus: null });
    }, 0);
  }

  render(): JSX.Element {
    const {
      children,
    } = this.props;

    const {
      focus,
    } = this.state;

    return children({
      focus,
      focusNext: this.focusNext,
      focusPrevious: this.focusPrevious,
      onFocusHour: this.onFocusHour,
      onFocusMinute: this.onFocusMinute,
      onFocusMeridiem: this.onFocusMeridiem,
      onBlur: this.onBlur,
    });
  }
}

export default TimeInputFocusController;
