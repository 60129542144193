/* eslint-disable  jsx-a11y/label-has-associated-control */
import React, {
  PureComponent, isValidElement, ReactNode, Ref,
} from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { isFunction, isString } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

import { TooltipIcon, TooltipButton } from '../HelpTip/HelpTip';
import Tag from '../Tag/Tag';

import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

/**
 * We want to render react nodes *OR* run a render prop function is that is what we are given.
 */
function returnElementOrRunRenderable(
  renderable: ReactNode | ((params: any[]) => ReactNode) | undefined, ...rest: any[]
) {
  if (isValidElement(renderable) || isString(renderable)) {
    return renderable;
  }
  if (isFunction(renderable)) {
    return renderable(...rest);
  }
  return null;
}

export interface LabelLineComponentProps extends TaktProps {
  labelId?: string;
  labelFor?: string;
  className?: string;
  renderLabelStart?: ReactNode | (() => ReactNode);
  renderLabelEnd?: ReactNode | (() => ReactNode);
  onLabelClick?: () => void;
  children: ReactNode;
  hidden?: boolean;
  innerRef?: Ref<HTMLLabelElement>;
}

const LabelLine = styled(class LabelLineComponent extends PureComponent<LabelLineComponentProps> {
  static propTypes = {
    labelId: PT.string,
    labelFor: PT.string,
    className: PT.string,
    renderLabelStart: PT.oneOfType([PT.func, PT.node]),
    renderLabelEnd: PT.oneOfType([PT.func, PT.node]),
    onLabelClick: PT.func,
    children: PT.node.isRequired,
    hidden: PT.bool,
    innerRef: PT.shape({ current: PT.instanceOf(NodeSafeHTMLElement) }),
  }

  static defaultProps = {
    labelId: undefined,
    labelFor: undefined,
    renderLabelStart: undefined,
    renderLabelEnd: undefined,
    onLabelClick: undefined,
    hidden: false,
    className: '',
    innerRef: undefined,
  }

  renderLabelElement = ({ useClassNameProp, ...extraProps }: { useClassNameProp: boolean }): JSX.Element => {
    const {
      labelId,
      labelFor,
      onLabelClick,
      children,
      className,
      renderLabelStart,
      renderLabelEnd,
      hidden,
      innerRef,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const labelProps = {
      ...rest,
      id: labelId,
      className: (useClassNameProp ? className : undefined),
      /*
       * Only attach the click handler if the label has no id string that associates with a input.
       */
      ...(isString(labelFor)
        ? { htmlFor: labelFor }
        : { onClick: onLabelClick }),
    };

    return (
      <label ref={innerRef} {...extraProps} {...labelProps}>
        {children}
      </label>
    );
  }

  render(): JSX.Element {
    const {
      className,
      renderLabelStart,
      renderLabelEnd,
      taktId,
      taktValue,
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('label-line')}>
        {({ getDataTaktAttributes }) => (
          <>
            { /*
            * in the case of having a label-start or label-end we wrap the <label/> in a <span/> to keep
            * the elements in-line.
            */}
            {((!!renderLabelStart) || (!!renderLabelEnd)) ? (
              <span className={className} {...getDataTaktAttributes()}>
                {((!!renderLabelStart) && (<>{returnElementOrRunRenderable(renderLabelStart)}{' '}</>))}
                {this.renderLabelElement({ useClassNameProp: false })}
                {((!!renderLabelEnd) && (<>{' '}{returnElementOrRunRenderable(renderLabelEnd)}</>))}
              </span>
            ) : (
              <>{this.renderLabelElement({ useClassNameProp: true, ...getDataTaktAttributes() })}</>
            )}
          </>
        )}
      </TaktIdConsumer>
    );
  }
})`
${({ hidden, theme }) => (hidden && theme.typography.screenReaderOnly)}

& > label, label&  {
  display: inline;
  padding-left: 0;
  padding-bottom: 0;
  ${({ theme }) => theme.typography.base}
  font-weight: ${({ theme }) => theme.form.label.weight};
  color: ${({ theme }) => theme.form.label.color};

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base}
  `)}
}

${TooltipButton} {
  vertical-align: baseline;
}

${TooltipIcon} {
  padding: 0;
}

${Tag}{
  margin: 0;
}
`;

LabelLine.displayName = 'LabelLine';

export default LabelLine;
