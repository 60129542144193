import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { longArrowAltUp } from '@amzn/storm-ui-icons-v3';
import { useIntl } from '@amzn/storm-ui-intl-v3';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import Button from '../Button';
import { useTaktId } from '../TaktIdContext';

const IconButton = styled(Button)<{ $active: boolean }>`
  background: ${({ theme, $active }) => ($active ? theme.palette.blue[700] : theme.palette.gray[500])};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  padding: unset;

  :hover {
    background: ${({ theme }) => theme.palette.gray[500]};
  }
`;

const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.palette.gray[500] : 'white')};

  /*! @noflip */
  [dir="rtl"] && > svg {
    /*! @noflip */ transform: scaleX(-1);
  }
`;

export interface SendButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean;
  isActive?: boolean;
}

const SendButton = ({ onClick, disabled, isActive = false }: SendButtonProps) => {
  const { formatMessage } = useIntl();
  const { getDataTaktAttributes } = useTaktId({ fallbackId: 'send-button' });
  return (
    <Tooltip
      nonInteractive
      inert={disabled}
      trigger={(
        <span aria-label={formatMessage({ id: 'action-send' })}>
          <IconButton
            {...getDataTaktAttributes()}
            transparent
            onClick={onClick}
            disabled={disabled}
            $active={isActive}
          >
            <StyledIcon disabled={disabled} type={longArrowAltUp} transform="rotate-90" size="sm" />
          </IconButton>
        </span>
          )}
      message={formatMessage({ id: 'action-send' })}
    />
  );
};

export default SendButton;
