import { ISODate } from '../types';
import { endOfWeek, differenceInCalendarDays, addDays } from './dateUtils';

/** Returns an array of dates ourside of the current month but within the same calendar view */
const getOutsideEndDays = (month: ISODate, locale?: Locale): Array<ISODate> => {
  const days: ISODate[] = [];
  const lastDayOfWeek = endOfWeek(month, locale);
  const endDiff = differenceInCalendarDays(lastDayOfWeek, month);

  for (let i = 1; i <= endDiff; i++) {
    const dayDate = addDays(month, i);
    days.push(dayDate);
  }
  return days;
};

export default getOutsideEndDays;
