import React, { memo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { angleInlineEnd } from '@amzn/storm-ui-icons-v3';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { createStormTaktId, useTaktId } from '../TaktIdContext';
import { TreeNode } from './types';
import Icon from '../Icon';
import Button from '../Button';
import { TaktProps } from '../types/TaktProps';

export interface StyledArrowButtonProps {
  $opened: boolean;
}
export const StyledArrowButton = styled(Button)<StyledArrowButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  width: ${({ theme }) => theme.tree.arrowButtonWidth};
  padding: 0;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;

  :hover {
    background: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: transform 150ms ease-in-out;
  }

  ${({ $opened }) => $opened && css`
    /*! @noflip */ transform: rotate(90deg);
    /*! @noflip */
    [dir="rtl"] && {
      /*! @noflip */ transform: rotate(-90deg);
    }
  `}

  ${isMobile(css`
    font-size: 1.2em;
    width: ${({ theme }) => theme.tree.mobile.arrowButtonWidth};
  `)}
`;

const hasSiblingWithArrowButtonMixin = css`
  width: ${({ theme }) => theme.tree.arrowButtonWidth};
  ${isMobile(css`
    width: ${({ theme }) => theme.tree.mobile.arrowButtonWidth};
  `)}
`;

export interface SpacerProps {
  hasSiblingWithArrowButton: boolean;
}

export const ArrowButtonSpacer = styled('div')<SpacerProps>`
  flex: 0 0 auto;
  width: ${({ theme }) => theme.tree.arrowButtonSpacerWidth};
  ${isMobile(css`
    width: ${({ theme }) => theme.tree.mobile.arrowButtonSpacerWidth};
  `)}

  ${({ hasSiblingWithArrowButton }) => (hasSiblingWithArrowButton && hasSiblingWithArrowButtonMixin)}
`;

const isTreeRoleMixin = css`
  width: ${({ theme }) => theme.tree.arrowButtonDisplayAllTreeNodeSpacerWidth};
  ${isMobile(css`
    width: ${({ theme }) => theme.tree.mobile.arrowButtonDisplayAllTreeNodeSpacerWidth};
  `)}
`;

export interface DisplayAllSpacerProps {
  isTreeRole?: boolean;
}

export const DisplayAllSpacer = styled('div')<DisplayAllSpacerProps>`
  flex: 0 0 auto;
  width: ${({ theme }) => theme.tree.arrowButtonDisplayAllSpacerWidth};
  ${isMobile(css`
    width: ${({ theme }) => theme.tree.mobile.arrowButtonDisplayAllSpacerWidth};
  `)}

  ${({ isTreeRole }) => (isTreeRole && isTreeRoleMixin)}
`;

export interface NoArrowSpacerProps {
  displayAll?: boolean;
  hasSiblingWithArrowButton: boolean;
  isTreeRole: boolean;
}

export const NoArrowSpacer = ({
  displayAll,
  hasSiblingWithArrowButton,
  isTreeRole,
}: NoArrowSpacerProps) => {
  if (displayAll) {
    return (
      <DisplayAllSpacer isTreeRole={isTreeRole} />
    );
  }

  return (
    <ArrowButtonSpacer hasSiblingWithArrowButton={hasSiblingWithArrowButton} />
  );
}

export interface ArrowButtonProps<T> extends TaktProps {
  item: T,
  onArrowClick: (item: T) => void,
  opened: boolean,
  ariaLabel: string;
}

function ArrowButton<T extends TreeNode>({
  item,
  onArrowClick,
  opened,
  ariaLabel,
  taktId,
  taktValue,
}: ArrowButtonProps<T>): JSX.Element {
  const handleArrowClick = useCallback(() => onArrowClick(item), [item, onArrowClick]);
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('tree') });

  const { children, renderLabel, ...rest } = item;
  return (
    <StyledArrowButton
      data-test-id={`option-expander-${item.label}`}
      transparent
      type="button"
      onClick={handleArrowClick}
      $opened={opened}
      aria-expanded={opened}
      aria-label={ariaLabel}
      {...getDataTaktAttributes({
        taktIdSuffix: `${item.id.toString()}-arrow`,
        taktValue,
        contextOnlyTaktValues: { item: rest },
      })}
    >
      <Icon type={angleInlineEnd} />
    </StyledArrowButton>
  );
}

export default memo(ArrowButton) as typeof ArrowButton;
