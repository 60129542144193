/**
 * A mapping of i18n to date-fns locale codes
 */
const i18nLocaleMap: Record<string, string> = {
  'ar-AE': 'ar',
  'bn-IN': 'bn',
  'cs-CZ': 'cs',
  'da-DK': 'da',
  'de-DE': 'de',
  'en-AE': 'en-US',
  'en-AU': 'en-AU',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  'en-IN': 'en-IN',
  'en-NG': 'en-US',
  'en-SG': 'en-US',
  'en-US': 'en-US',
  'en-ZA': 'en-US',
  'es-CL': 'es',
  'es-CO': 'es',
  'es-ES': 'es',
  'es-MX': 'es',
  'es-US': 'es',
  'fr-BE': 'fr',
  'fr-CA': 'fr-CA',
  'fr-FR': 'fr',
  'he-IL': 'he',
  'hi-IN': 'hi',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'kn-IN': 'kn',
  'ko-KR': 'ko',
  'ml-IN': 'en-US',
  'mr-IN': 'en-US',
  'ms-MY': 'ms',
  'nl-BE': 'nl-BE',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt',
  'sv-SE': 'sv',
  'ta-IN': 'ta',
  'te-IN': 'te',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'vi-VN': 'vi',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

export default i18nLocaleMap;
