import { formatInTimeZone } from 'date-fns-tz';

/**
 * Returns if the given date is a weekend in the given timezone
 * @param day the given date
 * @param zone the given timezone
 * @returns if the day is a weekend in the timezone
 */
const isWeekendInTimeZone = (
  day: Date,
  zone?: string,
): boolean => formatInTimeZone(day, zone ?? Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone, 'EEEEE') === 'S';

export default isWeekendInTimeZone;
