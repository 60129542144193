import React, {
  PureComponent,
  ContextType,
  RefObject,
  ReactNode,
} from 'react';
import PT, { Validator } from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import focusOutline from '../../FocusIndicator/styleMixins/focusOutline';
import defaultValueSerializer from '../../SelectList/utils/defaultValueSerializer';
import { RadioButtonContainer } from '../RadioButtonGroup';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { RadioButtonProps } from './RadioButtonProps';
import RadioGroupContext, { defaultContextValue } from '../RadioGroupContext';

const NodeSafeHTMLInputElement = (typeof HTMLInputElement !== 'undefined' ? HTMLInputElement : Object);

export interface StyledInputProps {
  checked?: boolean;
  disabled?: boolean;
}

export interface StyledInputAttrsProps extends StyledInputProps {
  $focusVisible?: boolean;
}

const StyledInput = styled('input')
  .attrs<StyledInputProps>(props => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps(props, focusProps),
      $focusVisible: isFocusVisible,
    });
  })<StyledInputAttrsProps>`
  &&& {
    -webkit-appearance: none;
    position: relative;
    flex: 0 0 auto;
    background-color: ${({ checked, theme }) => (checked ? theme.form.radio.bgChecked : theme.form.radio.bg)};
    width: ${({ theme }) => theme.form.radio.inputWidth};
    height: ${({ theme }) => theme.form.radio.inputHeight};
    margin: 0;
    margin-top: 1px;
    margin-inline-end: ${({ theme }) => theme.form.radio.marginInlineEnd};
    transition: none;
    border-radius: 100%;
    border: 1px solid ${({ checked, theme }) => (checked ? theme.form.radio.borderColorChecked : theme.form.radio.borderColor)};
    @media (prefers-reduced-motion: no-preference) {
      transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms ease;
    }

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;

      /*! @noflip */
      transform: translateY(-50%) translateX(-50%);
      width: ${({ theme }) => theme.form.radio.inputDotWidth};
      height: ${({ theme }) => theme.form.radio.inputDotHeight};
      background-color: ${({ checked, theme }) => (checked ? theme.form.radio.activeColor : theme.form.radio.bg)};
      border-radius: 100%;
    }

    :hover {
      cursor:  ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      border-color: ${({ theme }) => theme.focusVisible.borderColor};
      box-shadow: none;
      background-color: ${({ checked, theme }) => (checked ? theme.form.radio.bgActive : theme.form.radio.bgHover)};
    }

    :active{
      transform: scale(1.2);
      background-color: ${({ checked, theme }) => (checked ? theme.form.radio.bgActive : theme.form.radio.bgHover)};
      box-shadow: none;
    }

    :focus {
      outline: none;
      ${({ $focusVisible }) => ($focusVisible && focusOutline)};
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
      outline: none;

      :active {
        transform: none;
      }

      :hover {
        background-color: ${({ theme }) => theme.form.radio.bg};
        border-color: ${({ theme }) => theme.form.radio.borderColor};
        box-shadow: none;

        :checked {
          background-color: ${({ theme }) => theme.form.radio.bgChecked};
          border: 1px solid ${({ theme }) => theme.form.radio.borderColorChecked};
        }
      }
    }

    ${isMobile(css`
      /* bumps the input down a pixel to look more centered when the label is only one line */
      top: 1px;
    `)}

    /* overwrite AUI styles */
    vertical-align: auto;
    bottom: auto;
  }
`;

const WrapperLabel = styled('label')<{
  inline?: boolean;
  disabled?: boolean;
}>`
  cursor:  ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled, theme }) => (disabled ? theme.form.label.colorDisabled : theme.form.label.color)};
  font-size: ${({ theme }) => theme.form.radio.buttonFontSize};
  display: flex;
  flex-direction: row;

  padding: ${({ theme }) => theme.form.radio.buttonPadding};
  align-items: ${({ inline }) => (inline ? 'center' : 'flex-start')};
  margin-bottom: ${({ inline, theme }) => (inline ? 0 : theme.spacing.micro)};

  margin-inline-end: ${({ inline, theme }) => (inline ? theme.spacing.base : 0)};
  margin-inline-start: 0;

  ${isMobile(css`
    margin-inline-end: 0;
    margin-inline-start: 0;
  `)}

  ${isMobile(css`
    align-items: flex-start;
    border: ${({ theme }) => theme.form.radio.mobile.buttonBorderWidth} solid ${({ theme }) => theme.form.radio.mobile.buttonBorderColor};
    border-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
    font-size: ${({ theme }) => theme.form.radio.mobile.buttonFontSize};
    margin-bottom: 0;
    padding: ${({ theme }) => theme.form.radio.mobile.buttonPadding};

    /* Targets RadioButtons that are children of <RadioButtonContainer /> */
    ${RadioButtonContainer} && {
      background-color: ${({ theme }) => theme.form.radio.mobile.backgroundColor};
      border: none;
      border-radius: 0;
      border-bottom: ${({ theme }) => theme.form.radio.mobile.buttonBorderWidth} solid ${({ theme }) => theme.form.radio.mobile.buttonBorderColor};
    }

    ${RadioButtonContainer} &&:first-of-type {
      border-radius: ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius} ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius} 0 0;
    }

    ${RadioButtonContainer} &&:last-of-type {
      border-radius: 0 0 ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius} ${({ theme }) => theme.form.radio.mobile.buttonBorderRadius};
      border-bottom: none;
    }
  `)}

  /* overwrite AUI styles */
  box-sizing: border-box;
`;
WrapperLabel.displayName = 'Wrapper';

const StyledLabelText = styled('div')`
  ${({ theme }) => theme.typography.base};
  flex: 1 1 auto;

  /* Ensure the correct font-size for mobile */
  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
`;
StyledLabelText.displayName = 'StyledLabel';

class RadioButton extends PureComponent<RadioButtonProps> {
  static propTypes = {
    /**
     * managed by RadioGroup
     */
    id: PT.string.isRequired,
    /**
     * Positions the Label inline with the control.
     */
    inline: PT.bool,
    /**
     * Signifies whether the <RadioButton> has been selected.
     */
    isChecked: PT.bool,
    /**
     * Callback that is called when the <RadioButton> is selected.
     */
    onChange: PT.func,
    /**
     * Unique name for the <RadioButton>.
     */
    name: PT.string,
    /**
     * Use this to access the ref for the underlying <input>.
     */
    inputRef: PT.shape({ current: PT.instanceOf(NodeSafeHTMLInputElement) }) as Validator<RefObject<HTMLInputElement>>,
    /**
     * The label that describes the <RadioButton>.
     */
    label: PT.node as PT.Validator<ReactNode>,
    /**
     * The unique value of the <RadioButton> choice.
     */
    value: PT.string.isRequired,
    /**
     * Disables the <RadioButton>.
     */
    disabled: PT.bool,
  }

  static defaultProps = {
    inputRef: undefined,
    label: undefined,
    inline: false,
    isChecked: undefined,
    onChange: undefined,
    name: undefined,
    disabled: false,
  }

  static contextType = RadioGroupContext;

  context: ContextType<typeof RadioGroupContext> = defaultContextValue;

  handleChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, value } = this.props;
    const { handleRadioChange } = this.context;

    handleRadioChange?.(event, value);
    onChange?.(event);
  });

  render(): JSX.Element {
    const {
      id,
      disabled,
      inline,
      isChecked,
      inputRef,
      label,
      onChange,
      value,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const {
      name,
      isGroupDisabled,
      isGroupInline,
      selectedValue,
    } = this.context;
    const serializedValue = defaultValueSerializer(value);
    const radioId = this.context ? `${name}-${serializedValue}` : id;
    const checked = selectedValue ? selectedValue === value : isChecked
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('radio-button')}>
        {({ getDataTaktAttributes }) => (
          <WrapperLabel
            disabled={isGroupDisabled || disabled}
            inline={isGroupInline}
            htmlFor={radioId}
            {...getDataTaktAttributes({ taktIdSuffix: 'label' })}
          >
            <StyledInput
              {...getDataTaktAttributes({ contextOnlyTaktValues: { value, checked } })}
              {...rest}
              type="radio"
              id={radioId}
              name={name}
              checked={checked}
              onChange={this.handleChange}
              value={serializedValue}
              disabled={isGroupDisabled || disabled}
              ref={inputRef}
            />
            <StyledLabelText>
              {label}
            </StyledLabelText>
          </WrapperLabel>
        )}
      </TaktIdConsumer>
    );
  }
}

export default RadioButton;
