import styled, { css, keyframes } from 'styled-components';
import { ClassNamePassthrough, MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';

const pulseAnimation = keyframes`
  0% {
    /*! @noflip */ transform: rotate(0deg);
  }
  100% {
    /*! @noflip */ transform: rotate(360deg);
  }
`;

const pulseStyle = css`
  animation-duration: 1s;
  animation-timing-function: steps(8);
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ${pulseAnimation};
`;

export interface IconClassWrapperProps {
  $pulse: boolean;
  $size?: string;
  $fontSize?: string;
}

export const IconClassWrapper = styled(ClassNamePassthrough)<IconClassWrapperProps>`
  height: 1em;
  :not(:root) {
    overflow: visible;
  }
  ${({ $pulse }) => ($pulse ? pulseStyle : null)}
  ${({ $fontSize }) => ($fontSize ? `font-size: ${$fontSize}` : null)};
  vertical-align: ${({ $size }) => ($size === 'lg' ? '-0.225' : '-0.125')}em;
`;

export interface IconWrapperProps extends MergeStyledComponentElementProps<'i'> {
  $blockSize?: boolean;
  $horizontalFlipOnRTL?: boolean;
}

export const IconWrapperStyled = styled('i')<IconWrapperProps>`
  ${({ $blockSize }) => ($blockSize ? css`
  display: inline-block;
  > svg {
    display: block;
  }` : '')}
  ${// Only use the icon RTL flipper when using a logical direction icon type;
  ({ $horizontalFlipOnRTL }) => ($horizontalFlipOnRTL
    ? css`
    /*! @noflip */
    [dir="rtl"] && > svg {
      /*! @noflip */ transform: scaleX(-1);
    }` : '')}
`;
