import React, { FC, forwardRef } from 'react';
import styled from 'styled-components';
import { angleDown } from '@amzn/storm-ui-icons-v3';
import Icon from '../../Icon';
import Button, { ButtonProps } from '../../Button/Button';
import { createStormTaktId, withTaktFallbackId } from '../../TaktIdContext';

export interface MenuButtonTriggerProps extends Omit<ButtonProps, 'ref'> {}

interface MenuButtonTriggerIconProps {
  $disabled?: boolean;
  $primary?: boolean;
}
export const MenuButtonTriggerIcon = styled(Icon)<MenuButtonTriggerIconProps>`
  /* margins that respect writing direction */
  margin-inline-start: 10px;
  margin-inline-end: 0;

  color:  ${({ $disabled, theme }) => (!$disabled ? theme.dropdown.icon.color : theme.dropdown.icon.colorDisabled)};
  ${({ $primary, $disabled, theme }) => $primary && `
    color: ${(!$disabled ? theme.button.primary.color : theme.button.primary.colorDisabled)};
  `}
  pointer-events: none;
`;
MenuButtonTriggerIcon.displayName = 'MenuButtonTriggerIcon';

const StyledButton = styled(Button)``;
const MenuButton = withTaktFallbackId<ButtonProps>(StyledButton);

const MenuButtonTrigger: FC<React.PropsWithChildren<MenuButtonTriggerProps>> = forwardRef(({
  children,
  disabled,
  primary,
  ...rest
}, ref) => (
  <MenuButton
    {...rest}
    disabled={disabled}
    buttonRef={ref}
    primary={primary}
    taktFallbackId={createStormTaktId('menu-button')}
  >
    {children}
    <MenuButtonTriggerIcon
      type={angleDown}
      $disabled={disabled}
      $primary={primary}
    />
  </MenuButton>
));

export default MenuButtonTrigger;
