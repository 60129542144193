/* eslint-disable import/prefer-default-export */
import { palette } from '../base/color';

export const splitButton = {
  innerBorderRadius: '100px',
  default: {
    dropdown: {
      color: palette.gray[700],
      hoverColor: palette.squidInk,
      disabledColor: palette.gray[500],
    },
  },
  primary: {
    dropdown: {
      color: palette.white,
      hoverColor: palette.white,
      disabledColor: palette.gray[500],
    },
  },
};
