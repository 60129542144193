const calculateOffsetDistance = (
  withArrow: boolean | undefined,
  arrowHeight: number,
  tooltipOffset: number,
  offsetDistance = 0,
): number => {
  if (withArrow) {
    return arrowHeight + (tooltipOffset - arrowHeight) + offsetDistance;
  }

  return offsetDistance;
};

export default calculateOffsetDistance;
