import { ISODate, MultiDateSelector } from '../../types';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isoDateToDate,
} from '../../utils/dateUtils';
import {
  endOfDayAtTz,
  startOfDayAtTz,
} from '../../utils/timezoneUtils';

const multiDateSelectors: Array<MultiDateSelector> = [
  {
    label: 'Custom_Label',
    range: 'CUSTOM',
    getFrom: (date: ISODate): Date => isoDateToDate(date),
    getTo: (date: ISODate): Date => isoDateToDate(date),
    isDefaultTranslation: true,
  },
  {
    label: 'Day_Label',
    range: 'DAY',
    getFrom: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => startOfDayAtTz(date, zone),
    getTo: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => endOfDayAtTz(date, zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Week_Label',
    range: 'WEEK',
    getFrom: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => startOfDayAtTz(startOfWeek(date, locale), zone),
    getTo: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => endOfDayAtTz(endOfWeek(date, locale), zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Month_Label',
    range: 'MONTH',
    getFrom: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => startOfDayAtTz(startOfMonth(date), zone),
    getTo: (
      date: ISODate, zone: string, locale?: Locale,
    ): Date => endOfDayAtTz(endOfMonth(date), zone),
    isDefaultTranslation: true,
  },
];

export default multiDateSelectors;
