import React, {
  PureComponent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import { TablistContextProvider } from './TablistContext';
import Nav from './Nav';
import type { TaktProps } from '../types/TaktProps';

const Wrapper = styled.div`
  position: relative;

  ${isMobile(css`
    // visual indicator on scrollable with additional tabs
    ::after {
      position: absolute;
      height: 100%;
      width: ${({ theme }) => theme.navigation.tabs.mobile.width};

      /* @noflip */ left: auto;
      inset-inline-start: auto;
      /* @noflip */ right: 0;
      inset-inline-end: 0;

      top: 0;
      content: " ";
      background: ${({ theme }) => theme.navigation.tabs.mobile.scrollableIndicatorBackground};
    }

    /*! @noflip */
    [dir="rtl"] &&::after {
      background: ${({ theme }) => theme.navigation.tabs.mobile.scrollableIndicatorBackgroundRTL};

      /* @noflip */ right: auto;
      inset-inline-start: auto;
      /* @noflip */ left: 0;
      inset-inline-end: 0;
    }
  `)}
`;

export interface TabbedNavigationProps extends TaktProps {
  /**
     * A set of TabbedNavigationItem components
     */
  children?: ReactNode;
  /**
   * set to true if TabbedNavigation is for tabs that control content on the same page.
   * @defaultValue `false`
   */
  tablist?: boolean;
}

class TabbedNavigation extends PureComponent<TabbedNavigationProps> {
  static propTypes = {
    /**
     * A set of TabbedNavigationItem components
     */
    children: PT.node.isRequired,
    /**
     * set to true if TabbedNavigation is for tabs that control content on the same page.
     */
    tablist: PT.bool,
  };

  static defaultProps = {
    tablist: false,
  }

  render(): JSX.Element {
    const {
      children,
      tablist,
      taktId,
      taktValue,
    } = this.props;

    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tabbed-navigation')}>
        <TablistContextProvider isTablist={tablist ?? false}>
          <Wrapper>
            <Nav>
              {children}
            </Nav>
          </Wrapper>
        </TablistContextProvider>
      </TaktIdProvider>
    );
  }
}

export default TabbedNavigation;
