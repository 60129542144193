function uidSafeCharsPropType(props: Record<string, any>, propName: string, componentName: string) {
  // eslint-disable-next-line react/destructuring-assignment
  if (!/^[A-Za-z0-9%-:]{3,}$/.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to  \`${componentName}\`. value must be a string consisting of characters a-z\`, \`A-Z\`, \`0-9\` , \`%\` , \`-\` and be at lest 3 characters long.`,
    );
  }
  return null;
}
export default uidSafeCharsPropType;
