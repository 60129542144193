import React, {
  RefObject,
  FC,
  MouseEvent,
  KeyboardEvent,
  ComponentProps,
  useRef,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import {
  getFocusableElements,
  keyboardKeynames as keys,
  MergeElementProps,
  trapTabFocus,
} from '@amzn/storm-ui-utils';
import InlinePortalProvider from '../Portal/InlinePortalProvider';
import {
  CloseButton,
  Shroud,
  SheetContentContainer,
  SheetContentSurface,
  SheetContent,
} from './Sheet.styles';
import { CloseButtonSection } from './CloseButtonSection';
import { SurfaceLight, SurfaceDark, SurfaceDarkInfo } from '../Surface/index';
import {
  MaxSizeProp,
  SheetSide,
  SheetPaddingOptionsPlusUndefined,
} from './types';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { Surface, SurfaceType } from '../Surface/types';

export function getSurfaceByType(type: SurfaceType): Surface {
  switch (type) {
    case 'dark':
      return SurfaceDark;
    case 'blue':
      return SurfaceDarkInfo;
    default:
      return SurfaceLight;
  }
}

export function createSetBottomToVisualViewport(divRef: RefObject<HTMLDivElement>): () => void {
  return function setBottomToVisualViewport(): void {
    if (divRef.current instanceof HTMLElement && window.visualViewport) {
      const { height: viewportHeight, offsetTop: viewportOffsetTop } = window.visualViewport;
      const { innerHeight } = window;

      const divStyle = divRef.current.style;
      // if the viewport is zoomed in (pinch on mobile or trackpad)
      if (innerHeight > viewportHeight) {
        /**
         * set the bottom offset to the window height minus the
         * combined height of the current viewport and its top offset
        */
        divStyle.setProperty('bottom', `${innerHeight - viewportHeight - viewportOffsetTop}px`);
        return;
      }
      divStyle.removeProperty('bottom');
    }
  };
}

export const Overlay: FC<React.PropsWithChildren<OverlayProps>> = ({
  children,
  className,
  closeButton,
  closeButtonProps,
  modalContainerRef,
  onClose,
  padding = 'base',
  side = 'bottom',
  state,
  type = 'light',
  withCloseButton,
  maxSize = 'default',
  header,
  ...rest
}) => {
  const closeRef = useRef<HTMLButtonElement>(null);
  const sheetRef = useRef<HTMLDivElement>(null);

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLElement>): void => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      if (onClose) {
        onClose();
      }
    }
  }, [onClose]);

  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    if (modalContainerRef.current !== null) {
      trapTabFocus(event, modalContainerRef.current);
    }
  }, [modalContainerRef]);

  const handleClick = useCallback((event: MouseEvent): void => {
    const { target, currentTarget } = event;

    if (target === currentTarget) {
      event.preventDefault();
      if (onClose) {
        onClose();
      }
    }
  }, [onClose]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sheetRef.current) {
      const focusableEls = getFocusableElements(sheetRef.current);
      if (focusableEls.length > 0) {
        focusableEls[0].focus();
      }
    }

    if (
      side === 'bottom'
      && typeof window !== 'undefined'
      && 'visualViewport' in window
      && window.visualViewport
    ) {
      const { visualViewport } = window;

      const setBottomToVisualViewport = createSetBottomToVisualViewport(sheetRef);
      visualViewport.addEventListener('resize', setBottomToVisualViewport);
      visualViewport.addEventListener('scroll', setBottomToVisualViewport);
      setBottomToVisualViewport();

      return () => {
        visualViewport.removeEventListener('resize', setBottomToVisualViewport);
        visualViewport.removeEventListener('scroll', setBottomToVisualViewport);
      };
    }
  }, [sheetRef, side]);

  return (
    <Shroud
      className={className}
      onMouseDown={handleClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      role="presentation"
      ref={modalContainerRef}
      $fadeState={state}
    >
      <InlinePortalProvider>
        <SheetContentContainer $slideState={state} side={side} ref={sheetRef}>
          {withCloseButton
            && (
            <CloseButtonSection
              onClose={onClose}
              closeRef={closeRef}
              closeButtonProps={closeButtonProps}
              closeButton={closeButton}
              side={side}
            />
            )}
          <SheetContentSurface
            as={getSurfaceByType(type)}
            $padding={padding}
            side={side}
            $maxSize={maxSize}
          >
            {header}
            <SheetContent
              {...rest}
              $padding={padding}
            >
              {children}
            </SheetContent>
          </SheetContentSurface>
        </SheetContentContainer>
      </InlinePortalProvider>
    </Shroud>
  );
};

export interface OverlayProps extends Omit<MergeElementProps<'div'>, 'ref' | 'type'> {
  className?: string;
  handleClick?: ComponentProps<typeof Shroud>['onMouseDown'];
  handleKeyUp?: ComponentProps<typeof Shroud>['onKeyUp'];
  handleKeyDown?: ComponentProps<typeof Shroud>['onKeyDown'];
  modalContainerRef: RefObject<HTMLDivElement>;
  onClose?: ComponentProps<typeof CloseButton>['onClick'];
  closeButtonProps?: Record<string, unknown>;
  closeButton: {
    type: 'icon' | 'message';
    label: string;
  };
  state: TransitionStatus;
  padding?: SheetPaddingOptionsPlusUndefined;
  side?: SheetSide;
  type?: SurfaceType;
  withCloseButton?: boolean;
  maxSize?: MaxSizeProp;
  header?: ReactNode;
}

Overlay.defaultProps = {
  className: undefined,
  handleClick: undefined,
  handleKeyUp: undefined,
  handleKeyDown: undefined,
  onClose: undefined,
  closeButtonProps: undefined,
  padding: 'base',
  side: 'bottom',
  type: 'light',
  withCloseButton: false,
  maxSize: 'default',
  header: undefined,
};
