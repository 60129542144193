/* eslint-disable id-length */
import { useEffect } from 'react';
import { ISODate } from '../types';
import { isSameDay } from '../utils/dateUtils';
import useFocus from './useFocus';

type useDayFocus = {
  focus: (date: ISODate) => void;
  blur: () => void;
  focusOnKeyDown: React.KeyboardEventHandler;
  isFocused: boolean;
  focusedDay: ISODate | undefined;
};

const useDayFocus = (date: ISODate, buttonRef: React.RefObject<HTMLButtonElement>): useDayFocus => {
  const [focusedDay, {
    focusDayAfter,
    focusDayBefore,
    focusWeekAfterDay,
    focusWeekBeforeDay,
    blur,
    focus,
  }] = useFocus();

  useEffect(() => {
    if (!focusedDay) return;
    if (isSameDay(focusedDay, date)) {
      buttonRef.current?.focus();
    }
  }, [buttonRef, date, focusedDay]);

  const focusOnKeyDown: React.KeyboardEventHandler = e => {
    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault();
        e.stopPropagation();
        focusDayBefore();
        break;
      case 'ArrowRight':
        e.preventDefault();
        e.stopPropagation();
        focusDayAfter();
        break;
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        focusWeekAfterDay();
        break;
      case 'ArrowUp':
        e.preventDefault();
        e.stopPropagation();
        focusWeekBeforeDay();
        break;
    }
  };

  const isFocused = Boolean(focusedDay && !isSameDay(focusedDay, date));

  return {
    focus,
    blur,
    focusOnKeyDown,
    isFocused,
    focusedDay,
  };
};

export default useDayFocus;
