import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  SetStateAction,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  RadioGroup,
  RadioGroupProps,
  scrollIntoBounds,
  isMobileStyleMixin as isMobile,
} from '@amzn/storm-ui-v3';
import { noop } from '@amzn/storm-ui-utils-v3';

import WithFilterComponent from './withFilter';

const ContentHeader = styled.div`
  background: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.spacing.mini};
  text-align: start;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};

  ${isMobile(css`
    padding: 0;
  `)}
`;
const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & fieldset {
    background-color: ${({ theme }) => theme.palette.white};
  }
`;

const ScrollWrapper = styled.div`
  padding: 10px;
  flex: 1;
  overflow: scroll;
`;

export interface FilterRadioGroupBodyProps extends Omit<RadioGroupProps, 'label' | 'name'> {
  /**
   * Callback that is called when the filter dropdown is opened.
   * @defaultValue `() => undefined`
   */
  onOpen?: () => void;
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   * @defaultValue `() => undefined`
   */
  preRender?: () => ReactNode;
  /**
    * Required for RadioGroup `name` property.
    */
  radioGroupName: string;
  /**
   * Required for RadioGroup `label` property.
   * @defaultValue `""`
   */
  radioGroupLabel?: string;
  /**
   * Represents the value of the selected item in the Filter dropdown.
   * @defaultValue `""`
   */
  selectedValue?: string;
  /**
   * Ignore this. It is passed by withFilter but is irrelevant for RadioGroup.
   * @defaultValue `undefined`
   */
  transparentButton?: boolean;
  /**
   * @defaultValue `undefined`
   */
  children?: ReactNode;
}

const FilterRadioGroupBody: FC<React.PropsWithChildren<FilterRadioGroupBodyProps>> = props => {
  const {
    preRender,
    selectedValue,
    radioGroupName = '',
    radioGroupLabel = '',
    onOpen,
    transparentButton,
    ...rest
  } = props;

  const [radioGroupRef, setRadioGroupRef] = useState<HTMLElement | null>(null);
  const radioGroupRefWrapper = useCallback((element: HTMLDivElement) => {
    setRadioGroupRef(element);
  }, []);

  useEffect(() => {
    if (radioGroupRef) {
      scrollIntoBounds(radioGroupRef, radioGroupRef?.querySelector('input[type="radio"]:checked'));
    }
  }, [radioGroupRef]);

  return (
    <ContentWrapper>
      <ContentHeader>{preRender && preRender()}</ContentHeader>
      <ScrollWrapper ref={radioGroupRefWrapper}>
        <RadioGroup
          label={radioGroupLabel}
          name={radioGroupName}
          selectedValue={selectedValue}
          hideLabel
          {...rest}
        />
      </ScrollWrapper>
    </ContentWrapper>
  );
};

FilterRadioGroupBody.propTypes = {
  /**
   * Callback that is called when the filter dropdown is opened.
   */
  onOpen: PT.func,
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   */
  preRender: PT.func,
  /**
   * Required for RadioGroup `label` property.
   */
  radioGroupLabel: PT.string,
  /**
  * Required for RadioGroup `name` property.
  */
  radioGroupName: PT.string.isRequired,
  /**
   * Represents the value of the selected item in the Filter dropdown.
   */
  selectedValue: PT.string,
  /**
   * Ignore this. It is passed by withFilter but is irrelevant for RadioGroup.
   */
  transparentButton: PT.bool,
};

FilterRadioGroupBody.defaultProps = {
  onOpen: noop,
  preRender: noop,
  selectedValue: '',
  radioGroupLabel: '',
  transparentButton: undefined,
  children: undefined,
};

export default WithFilterComponent<FilterRadioGroupBodyProps>(FilterRadioGroupBody);
