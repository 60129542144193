/* eslint-disable id-length */
import React, {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import FocusContext from './FocusContext';
import useNavigation from '../../hooks/useNavigation';
import useDayPicker from '../../hooks/useDatePicker';
import { ISODate } from '../../types';
import {
  addMonths,
  isSameMonth,
  addDays,
  addWeeks,
} from '../../utils/dateUtils';

export type FocusProviderProps = {
  children: React.ReactNode;
};

const FocusProvider: FC<React.PropsWithChildren<FocusProviderProps>> = ({
  children,
}) => {
  const [focusedDay, setDay] = useState<ISODate | undefined>();
  const { goToMonth, displayMonths } = useNavigation();
  const { numberOfMonths = 1 } = useDayPicker();

  const blur = useCallback(() => setDay(undefined), []);
  const focus = useCallback((date: ISODate) => setDay(date), []);

  const switchMonth = useCallback((date: ISODate, offset: number) => {
    if (displayMonths.some(m => isSameMonth(date, m))) return;
    if (offset < 0) {
      goToMonth(addMonths(date, 1 + offset));
    } else {
      goToMonth(date);
    }
  }, [displayMonths, goToMonth]);

  const focusDayBefore = useCallback(() => {
    if (!focusedDay) return;
    const before = addDays(focusedDay, -1);
    setDay(before);
    switchMonth(before, numberOfMonths * -1);
  }, [focusedDay, numberOfMonths, switchMonth]);

  const focusDayAfter = useCallback(() => {
    if (!focusedDay) return;
    const after = addDays(focusedDay, 1);
    setDay(after);
    switchMonth(after, numberOfMonths);
  }, [focusedDay, numberOfMonths, switchMonth]);

  const focusWeekBeforeDay = useCallback(() => {
    if (!focusedDay) return;
    const up = addWeeks(focusedDay, -1);
    setDay(up);
    switchMonth(up, numberOfMonths * -1);
  }, [focusedDay, numberOfMonths, switchMonth]);

  const focusWeekAfterDay = useCallback(() => {
    if (!focusedDay) return;
    const down = addWeeks(focusedDay, 1);
    setDay(down);
    switchMonth(down, numberOfMonths);
  }, [focusedDay, numberOfMonths, switchMonth]);

  const setters = useMemo(() => ({
    blur,
    focus,
    focusDayAfter,
    focusDayBefore,
    focusWeekAfterDay,
    focusWeekBeforeDay,
  }), [
    blur,
    focus,
    focusDayAfter,
    focusDayBefore,
    focusWeekAfterDay,
    focusWeekBeforeDay]);
  return (
    <FocusContext.Provider value={[focusedDay, setters]}>
      {children}
    </FocusContext.Provider>
  );
};

export default FocusProvider;
