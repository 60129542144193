import React, {
  useRef,
  FC,
  useEffect,
  useContext,
  HTMLProps,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { PortalContextProvider } from './PortalContext';
import adoptAncestorZIndex from './adoptAncestorZIndex';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InlinePortalProviderProps extends HTMLProps<HTMLDivElement> {}
/**
 * The InlinePortalProvider is used to create a portal to render popover content
 * within a modal-like elements. This allows tab focus work and prevents
 * custom modal z-index from hiding popovers.
 */
const InlinePortalProvider: FC<React.PropsWithChildren<InlinePortalProviderProps>> = ({ children, ...rest }) => {
  const portalRef = useRef<HTMLDivElement>(null);
  const zIndexIncrement = useContext(ThemeContext)?.portal?.inlinePortalZIndexIncrement ?? 10000;

  useEffect(() => {
    /** Assign a `z-index` value based on the closest ancestor with a non-auto `z-index` and
     * increment it to prevent parent modal-like elements from cutting through. */
    adoptAncestorZIndex(portalRef, zIndexIncrement);
  }, [zIndexIncrement]);

  return (
    <PortalContextProvider portalElementRef={portalRef}>
      {children}
      <div {...rest} ref={portalRef} />
    </PortalContextProvider>
  );
};

InlinePortalProvider.propTypes = {
  /**
   * The children to render into the `Portal`.
   */
  children: PropTypes.node as PropTypes.Validator<ReactNode>,
};

InlinePortalProvider.defaultProps = {
  children: null,
};

export default InlinePortalProvider;
