/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/require-default-props */
import React, {
  ReactNode, FC, MutableRefObject, useRef, useEffect, ReactElement, RefObject,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { composeRefs, OnRootContainerMousedown } from '@amzn/storm-ui-utils';
import Portal from '../Portal';
import { PopperArrow, PopperSurface, TRANSITION_CLASSNAME_PREFIX } from './Popper.styles';
import { getContainer } from './utils';
import { Alignment, Position } from './types';
import createTriggerPopoverChangeEvent from './createPopoverChangeEvent';
import { SurfaceType } from '../Surface/types';

const PopperMetricEvent = ({
  children,
  popperRef,
  popoverType,
  isOpen,
}:{
  children: ReactElement,
  popperRef: MutableRefObject<undefined>,
  popoverType: string,
  isOpen?: boolean,
}): ReactElement => {
  useEffect(() => {
    // Create the function that triggers a change event when the Dropdown opens
    const triggerPopoverOpenEvent = createTriggerPopoverChangeEvent('open', popoverType);

    // Create the function that triggers a change event when the Dropdown closes
    const triggerPopoverCloseEvent = createTriggerPopoverChangeEvent('close', popoverType);
    if (isOpen && popperRef?.current) {
      triggerPopoverOpenEvent(popperRef?.current as HTMLElement);
    }

    if (!isOpen && popperRef?.current) {
      triggerPopoverCloseEvent(popperRef?.current as HTMLElement);
    }
  }, [isOpen, popoverType, popperRef]);

  return children;
}

const PopperContainer: FC<React.PropsWithChildren<PopperContainerProps>> = ({
  attributes,
  children,
  closeButtonLabel,
  closeIconPosition,
  disablePortal,
  focusableBody,
  footer,
  forceOpen,
  handleCloseClicked,
  handleKeyDown,
  handleKeyUp,
  handlePopoverEntered,
  handlePopoverEntering,
  handlePopoverExited,
  handleMouseEnter,
  handleMouseLeave,
  isOpen,
  message,
  onDocumentMousedown,
  setArrowElement,
  setPopperElement,
  shadowRoot,
  styles,
  type = 'light',
  withCloseButton,
  withArrow = true,
  popperRef,
  portalRef,
  position = 'top',
  transitionDuration = 0,
  popoverType = 'Popper',
  ...rest
}) => {
  const popperSurfaceRef = useRef();
  return (
    <CSSTransition
      timeout={transitionDuration}
      appear
      in={forceOpen || isOpen}
      onEntered={handlePopoverEntered}
      onEntering={handlePopoverEntering}
      onExited={handlePopoverExited}
      mountOnEnter
      unmountOnExit
      nodeRef={popperSurfaceRef}
      classNames={TRANSITION_CLASSNAME_PREFIX}
    >
      {transitionState => {
        if (transitionState === 'exited') return null;
        return (
          <OnRootContainerMousedown
            handler={onDocumentMousedown}
            rootContainer={shadowRoot ?? document?.documentElement}
          >
            <Portal
              disabled={disablePortal}
              externalPortalRef={portalRef}
              transitionDuration={transitionDuration}
            >
              <PopperMetricEvent
                popperRef={popperSurfaceRef}
                popoverType={popoverType}
                isOpen={isOpen}
              >
                <PopperSurface
                  $transitionDuration={transitionDuration}
                  position={position}
                  as={getContainer(type)}
                  aria-expanded={isOpen}
                  aria-hidden={!isOpen}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={composeRefs(setPopperElement, popperSurfaceRef, popperRef)}
                  role="dialog"
                  style={styles.popper}
                  styleType={type}
                  transitionState={transitionState}
                  withCloseButton={withCloseButton}
                  {...(focusableBody ? { tabIndex: '0' } : {})}
                  {...attributes.popper}
                  {...rest}
                >
                  {withArrow && (
                    <PopperArrow
                      ref={element => element && setArrowElement(element as HTMLElement)}
                      style={styles.arrow}
                      styleType={type}
                      $position={position}
                      {...attributes.arrow}
                    />
                  )}
                  {children}
                </PopperSurface>
              </PopperMetricEvent>
            </Portal>
          </OnRootContainerMousedown>
        );
      }}
    </CSSTransition>
  );
};

export interface PopperContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  align?: Alignment;
  attributes: { [key: string]: { [key: string]: string } | undefined };
  closeButtonLabel?: string;
  closeIconPosition?: 'default' | 'topCorner';
  disablePortal?: boolean;
  forceOpen?: boolean;
  handleCloseClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handlePopoverEntered?: () => void;
  handlePopoverEntering?: (node: any, isAppearing: boolean) => void;
  handlePopoverExited?: () => void;
  isOpen?: boolean;
  focusableBody?: boolean;
  footer?: ReactNode;
  message?: string | ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onCloseButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDocumentMousedown?: (event: Event) => void;
  position?: Position;
  setArrowElement: (element: HTMLElement) => void;
  setPopperElement: (element: HTMLElement) => void;
  shadowRoot?: EventTarget;
  spacing?: number;
  styles: { [key: string]: React.CSSProperties };
  transitionDuration?: number;
  type?: SurfaceType;
  withCloseButton?: boolean;
  withArrow: boolean;
  portalRef?: RefObject<HTMLElement>;
  popperRef?: RefObject<HTMLElement>;
  popoverType?: string;
}

export default PopperContainer;
