import React, {
  PureComponent,
} from 'react';
import PT from 'prop-types';
import Fuse from 'fuse.js';
import {
  SearchInput, TaktIdConsumer, TaktProps, createStormTaktId,
} from '@amzn/storm-ui';
import { PanelWrapper } from './MultiSelect.styles'

type ItemType = {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

export interface MultiSelectSearchPanelProps<T> extends TaktProps {
  /**
   * Used to generate the ids of actionable components.
   * @defaultValue `"searchPanel"`
   */
  name: string;
  /**
   * A short hint that describes the expected value of an input field.
   * @defaultValue `"Search"`
   */
  placeholder: string;
  /**
   * Function called when the search input value is changed.
   * results: The filtered items after search logic applied.
   * filterValue: The search key.
   * @defaultValue `() => null`
   */
  onFilterChange: (results: ItemType[], filterValue: string, event: React.ChangeEvent) => void,
  /**
   * Function called when the search input value is cleared
   * @defaultValue `() => null`
   */
  onFilterClear: (event: null | React.MouseEvent) => void,
  /**
   * ItemType: `{ label: string; value: any; [key: string]: any; }`.
   * Normally, the label/value should be the MultiSelectItem's children/value.
   * By default, fuse.js will try to search the label first, then value.
   * Search Key can be controlled by `props.options.keys`
   */
  items: T[];
  /**
   * Check fuse.js doc for detail: https://fusejs.io/api/options.html
   * @defaultValue `{
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'label',
    'value',
  ] }`
   */
  options: Fuse.FuseOptions<T>;
}

interface MultiSelectSearchPanelState {
  filterValue: string;
}

/**
 * SearchPanel at the top of the dropdown popover.
 * Integrated with Fuse.js (http://fusejs.io/) by default
 *
 */
export default class MultiSelectSearchPanel<T extends ItemType>
  extends PureComponent<MultiSelectSearchPanelProps<T>, MultiSelectSearchPanelState> {
  static propTypes = {
    name: PT.string,
    placeholder: PT.string,
    onFilterChange: PT.func,
    onFilterClear: PT.func,
    items: PT.arrayOf(PT.object).isRequired,
    /**
     * Check fuse.js options for detail
     * https://fusejs.io/api/options.html
     */
    options: PT.shape({
      shouldSort: PT.bool,
      threshold: PT.number,
      location: PT.number,
      distance: PT.number,
      maxPatternLength: PT.number,
      minMatchCharLength: PT.number,
      keys: PT.arrayOf(PT.string),
    }),
  }

  static defaultProps = {
    name: 'searchPanel',
    placeholder: 'Search',
    onFilterChange: () => null,
    onFilterClear: () => null,
    options: {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'label',
        'value',
      ],
    },
  }

  private filterRef: HTMLInputElement | undefined;

  constructor(props: MultiSelectSearchPanelProps<T>) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }

  componentDidMount(): void {
    this.handleOpen();
  }

  handleOpen = (): void => {
    this.setState({ filterValue: '' });
    this.props.onFilterClear(null);
    window.requestAnimationFrame(() => {
      if (this.filterRef) this.filterRef.focus();
    });
  }

  handleFilterChange = (event: React.ChangeEvent): void => {
    const filterValue = (event.target as HTMLInputElement).value;
    const { items, options } = this.props;
    const mergedOptions = {
      ...MultiSelectSearchPanel.defaultProps.options,
      ...options,
    };

    const fuse = new Fuse(items, mergedOptions);
    const results = filterValue ? fuse.search(filterValue) : items;

    this.setState({ filterValue });
    this.props.onFilterChange(results as ItemType[], filterValue, event);
  }

  handleFilterClear = (event: React.MouseEvent): void => {
    this.setState({ filterValue: '' });
    this.props.onFilterClear(event);
    if (this.filterRef) this.filterRef.focus();
  }

  handleFilterKeyDown = (event: React.KeyboardEvent): void => {
    const { key } = event;
    if (key === ' ' || key === 'Enter') {
      event.stopPropagation();
    }
  }

  render(): JSX.Element {
    const {
      name,
      placeholder,
      taktId,
      taktValue,
      items,
      options,
      ...rest
    } = this.props;

    const {
      filterValue,
    } = this.state;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('multi-select-search-input')}>
        {({ getDataTaktAttributes }) => (
          <PanelWrapper>
            <SearchInput
              {...getDataTaktAttributes()}
              {...rest}
              id={`${name}-searchIput`}
              placeholder={placeholder}
              inputRef={(element: HTMLInputElement) => { this.filterRef = element; }}
              value={filterValue}
              onClear={this.handleFilterClear}
              onChange={this.handleFilterChange}
              onKeyDown={this.handleFilterKeyDown}
              fullWidth
              autoComplete="off"
            />
          </PanelWrapper>
        )}
      </TaktIdConsumer>
    );
  }
}
