/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "취소"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "저장"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "사용자 지정"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "일 선택"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "월 선택"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "주 선택"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "지난 30일"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "지난 7일"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "지난달"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "지난주"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "전체"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "이번 주"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "오늘"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "연간 누계"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "어제"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
