import { StepLabelPlacement, StepStatus } from './types';

export const STATUS: { [key: string]: StepStatus } = {
  COMPLETED: 'completed',
  ACTIVE: 'active',
  FUTURE: 'future',
  DISABLED: 'disabled',
};

export const LABEL_PLACEMENT: { [key: string]: StepLabelPlacement } = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
