import { css, ThemedStyledProps } from 'styled-components';
import { Theme as DefaultTheme } from '../../theme/themes/default';

export type SurfaceThemedProps<P> = ThemedStyledProps<P, DefaultTheme>;

export interface SurfaceCssProps {
  styleType: 'blue' | 'dark' | 'light';
}

const surface = css`
  background-clip: padding-box;
  border-radius: ${({ theme }) => theme.tooltip.radius};
  box-shadow: ${({ theme }) => theme.popover.boxShadow};
  outline: none;
  z-index: ${({ theme }) => theme.popover.zIndex};
  border: none;
`;

export default surface;
