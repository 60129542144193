/* eslint-disable no-nested-ternary */
import { useStateEphemeral, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import React, {
  Dispatch, ReactNode, SetStateAction, useCallback, useMemo,
} from 'react';
import PT from 'prop-types';
import { TaktProps } from '../types/TaktProps';
import Text from '../Text';
import {
  NodeWrapper,
  RenderNodeWrapper,
  StyledTree,
  StyledTreeList,
} from './Tree.styles';
import { NodeMap, TreeNode, TreeRole } from './types';
import ArrowButton, { NoArrowSpacer } from './ArrowButton';
import DepthSpacer from './DepthSpacer';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';

const renderTreeNode = <T extends TreeNode>(item: T) => {
  if (item.renderLabel) return item.renderLabel(item.label);
  return <Text>{item.label}</Text>;
};

const shouldArrowShow = <T extends TreeNode>(item: T, displayed?: NodeMap) => {
  let areChildrenDisplayed = false;
  if (item.children && item.children.length > 0) {
    areChildrenDisplayed = true;
    if (displayed) {
      areChildrenDisplayed = false;
      for (let i = 0; i < item.children.length; i++) {
        if (displayed[item.children[i].id] !== undefined) {
          areChildrenDisplayed = true;
          break;
        }
      }
    }
  }
  return areChildrenDisplayed;
};

const getDisplayedChildrenNodes = <T extends TreeNode>(
  currentDisplayed: NodeMap = {},
  node: T,
  displayAll?: boolean,
) => {
  const childrenToDisplay: T[] = [];
  if (node.children && node.children.length) {
    const hasVisible = node.children.some(child => currentDisplayed?.[child.id] === true);
    node.children.forEach(child => {
      if (displayAll || hasVisible) {
        childrenToDisplay.push(child);
      }
    });
  }
  return childrenToDisplay;
};

const isLastNode = (
  index: number,
  isRoot: boolean,
  lastTree: boolean,
  dataLength: number,
  displayedChildren: number,
) => {
  if (!displayedChildren && index === dataLength - 1 && (isRoot || lastTree)) {
    return true;
  }
  return false;
};

export interface TreeProps<T> extends TaktProps, MergeElementProps<'ul'> {
  /**
   * The tree data.
   */
  data: T[];
  /**
   * The accessible role of the tree. The top-most tree must have the `tree` role
   * whilst the inner trees must have the `group` role.
   * @defaultValue `"tree"`
   */
  role?: TreeRole;
  /**
   * A mapping of nodes to force the tree to display. Only use if complete control
   * of the displayed node state is desired.
   * @defaultValue `undefined`
   */
  displayed?: NodeMap;
  /**
   * If all nodes should be displayed with no option to hide them.
   * @defaultValue `false`
   */
  displayAll?: boolean;
  /**
   * A function used to render a component for each node in the tree.
   * @defaultValue `undefined`
   */
  renderNode?: (node: T) => ReactNode;
  /**
   * A SetState dispatcher used to update the displayed state if it is being
   * controlled externally.
   * @defaultValue `undefined`
   */
  onDisplayChange?: Dispatch<SetStateAction<NodeMap | undefined>>;
  /**
   * The aria-label for the tree arrows.
   */
  arrowLabel: string | ((label: string) => string);
  /**
   * The initial depth of the tree. Should almost always be 0.
   * @defaultValue `0`
   */
  depth?: number;
  /**
   * The maximum node height of the tree.
   * @defaultValue `Infinity`
   */
  maxDepth?: number;
  /**
   * If the tree is considered the last tree in the hierarchy of given data.
   * @defaultValue `undefined`
   */
  isLastTree?: boolean;
}

function Tree<T extends TreeNode>(props: TreeProps<T>): JSX.Element {
  const {
    taktId,
    taktValue,
  } = props;

  const {
    data,
    role = 'tree',
    displayed,
    renderNode,
    onDisplayChange,
    arrowLabel,
    depth = 0,
    maxDepth = Infinity,
    isLastTree,
    displayAll,
    ...rest
  } = props;
  // gives display control to the local Tree component if the `displayed` prop is used
  const [displayState, setDisplayState] = useStateEphemeral<NodeMap | undefined>({}, {
    externalState: displayed,
    externalDispatch: onDisplayChange,
    useExternalState: !!displayed,
  });
  // the display state must be combined with the root nodes of the tree if no external display state is used
  const displayedNodes = useMemo(
    () => (displayed ?? data.reduce((acc, topNode) => ({ ...acc, [topNode.id]: true }), displayState)),
    [data, displayState, displayed],
  );

  const isRoot = depth === 0;

  const handleArrowClick = useCallback((item: T) => {
    const displayedChildrenNodes: NodeMap = {};
    if (item.children) {
      for (let i = 0; i < item.children?.length; i++) {
        const hasVisible = item.children.some(node => displayedNodes?.[node.id] === true);
        // if displayed is controlled externally
        if (displayed) {
          if (displayed[item.children[i].id] !== undefined) {
            displayedChildrenNodes[item.children[i].id] = !hasVisible;
          }
        } else {
          displayedChildrenNodes[item.children[i].id] = !hasVisible;
        }
      }
    }
    setDisplayState({
      ...displayedNodes,
      ...displayedChildrenNodes,
    });
  }, [displayed, displayedNodes, setDisplayState]);

  const showArrows = data.map((item: T) => (shouldArrowShow(item, displayed) && depth < maxDepth && !displayAll));
  const hasChildWithArrowButton = showArrows.some(Boolean);

  return (
    <TaktIdProvider taktId={isRoot ? taktId : undefined} taktValue={isRoot ? taktValue : undefined} fallbackId={isRoot ? createStormTaktId('tree') : undefined}>
      <StyledTree role={role} {...rest}>
        {data.map((item: T, index: number) => {
          const displayedChildren = getDisplayedChildrenNodes<T>(displayedNodes, item, displayAll);
          const lastNode = isLastNode(index, role === 'tree', isLastTree ?? false, data.length, displayedChildren.length);
          return displayedNodes && displayedNodes[item.id] && (
          <StyledTreeList
            data-test-id={role === 'tree' ? `option-root-${item.label}` : undefined}
            role="treeitem"
            key={item.id}
            $root={role === 'tree'}
            $displayed={displayedChildren.length > 0}
          >
            <NodeWrapper
              $lastNode={lastNode}
              data-test-id={`option-wrapper-${item.label}`}
            >
              <DepthSpacer depth={depth} displayAll={displayAll} />
              {showArrows[index] ? (
                <ArrowButton
                  item={item}
                  opened={displayedChildren.length > 0}
                  onArrowClick={handleArrowClick}
                  ariaLabel={typeof arrowLabel === 'string' ? arrowLabel : arrowLabel(item.label)}
                  taktId={taktId}
                />
              ) : (
                <NoArrowSpacer
                  displayAll={displayAll}
                  hasSiblingWithArrowButton={hasChildWithArrowButton}
                  isTreeRole={role === 'tree'}
                />
              )}
              <RenderNodeWrapper>
                {renderNode ? renderNode(item) : renderTreeNode(item)}
              </RenderNodeWrapper>
            </NodeWrapper>
            {item.children && displayedChildren.length > 0 && (
              <Tree
                {...rest}
                data={displayedChildren}
                role="group"
                displayed={displayed}
                renderNode={renderNode}
                onDisplayChange={onDisplayChange}
                arrowLabel={arrowLabel}
                depth={depth + 1}
                maxDepth={maxDepth}
                isLastTree={index === data.length - 1}
                displayAll={displayAll}
              />
            )}
          </StyledTreeList>
          );
        })}
      </StyledTree>
    </TaktIdProvider>
  );
}

Tree.propTypes = {
  data: PT.arrayOf(PT.shape({
    id: PT.oneOfType([PT.string, PT.number]).isRequired,
    label: PT.string.isRequired,
  })).isRequired,
  role: PT.oneOf(['tree', 'group']),
  displayed: PT.objectOf(PT.any), // eslint-disable-line react/forbid-prop-types
  renderNode: PT.func,
  onDisplayChange: PT.func,
  arrowLabel: PT.oneOfType([PT.string, PT.func]).isRequired,
  depth: PT.number,
};

Tree.defaultProps = {
  role: 'tree',
  displayed: undefined,
  renderNode: undefined,
  onDisplayChange: undefined,
  depth: 0,
  maxDepth: Infinity,
  isLastTree: undefined,
  displayAll: false,
};

export default Tree;
