function parse24HourTime(time24Hour?: string): {
  hour?: string | null,
  minute?: string | null,
  meridiem?: string | null,
} {
  let hour: string | null = null;
  let minute: string | null = null;
  let meridiem: string | null = null;

  const [, hourString, minuteString] = String(time24Hour)
    .match(/^([0-9]{1,2}):([0-9]{1,2})$/) || [];

  // Clamp hour between 0 and 23
  const hourNumber = Math.max(Math.min(Number(hourString), 23), 0);

  if (!Number.isNaN(hourNumber)) {
    if (hourNumber === 0) {
      hour = '12';
      meridiem = 'AM';
    } else if (hourNumber === 12) {
      hour = '12';
      meridiem = 'PM';
    } else if (hourNumber > 12) {
      hour = `0${(hourNumber - 12)}`.slice(-2);
      meridiem = 'PM';
    } else {
      hour = `0${(hourNumber)}`.slice(-2);
      meridiem = 'AM';
    }
  }

  // Clamp minute between 0 and 59
  const minuteNumber = Math.max(Math.min(Number(minuteString), 59), 0);
  if (!Number.isNaN(minuteNumber)) {
    minute = `0${(minuteNumber)}`.slice(-2);
  }

  return {
    hour,
    minute,
    meridiem,
  };
}

export default parse24HourTime;
