import React from 'react';
import { useIntl } from '@amzn/storm-ui-intl-v3';
import TextButton, { TextButtonProps } from '../../Button/TextButton'
import { createStormTaktId, useTaktId } from '../../TaktIdContext';

export interface AbandonmentDialogCancelButtonProps extends Omit<TextButtonProps, 'onClick'> {
  /**
   * Function to handle 'Continue Working' action
   */
  onCancel: () => void;
}

const CancelButton: React.FC<Omit<AbandonmentDialogCancelButtonProps, 'ref'>> = ({
  onCancel, taktId, children, ...rest
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const { dataTaktId } = useTaktId({ taktId, fallbackId: createStormTaktId('cancel-button') })

  return (
    <TextButton {...rest} onClick={onCancel} taktId={dataTaktId}>
      {children ?? formatMessage({ id: 'cancel' })}
    </TextButton>
  )
}

export default CancelButton;
