import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { ClassNamePassthrough, MergeStyledComponentElementProps } from '@amzn/storm-ui-utils-v3';
import getStarSVG, { StarColorsType } from './getStarSVG';

const StarRatingContainer = styled(ClassNamePassthrough)`
  /*! @noflip */
  [dir="rtl"] && {
    /*! @noflip */ transform: scaleX(-1);
  }
  height: 1em;
  vertical-align: -0.125em;
  pointer-events: none;
`;

const StarsContainer = styled.div`
  display: flex;
`;

/**
 * Builds a string of star SVGs to be inserted into the HTML
 */
const renderStars = (rating: number, className: string, starColor: StarColorsType) => {
  const roundedRating = Math.round(rating * 2) / 2;
  let stars = '';
  for (let i = 1; i <= 5; i++) {
    if (roundedRating === 0) {
      stars += getStarSVG(false, false, className, starColor);
    } else if (i === (roundedRating + 0.5)) {
      stars += getStarSVG(true, false, className, starColor);
    } else if (i <= roundedRating) {
      stars += getStarSVG(true, true, className, starColor);
    } else {
      stars += getStarSVG(false, false, className, starColor);
    }
  }
  return { __html: stars };
};

const getValidRating = (rating: number) => {
  if (rating < 0) return 0;
  if (rating > 5) return 5;
  return rating;
};

export interface StarRatingProps extends MergeStyledComponentElementProps<'div'> {
  /**
     * We automatically round the number to the nearest 0.5.
     * Any number over 5 will render as 5 stars.
     * @defaultValue `0`
     */
  rating?: number;
  /**
     * Use this prop to add a suffix to the aria-label.
     * @defaultValue `"out of 5 stars"`
    */
  ratingSuffix?: string;
  /**
     * Sets the color of the stars to either 'gold' or 'inkwell'
     * default is 'gold'
     * @defaultValue `"gold"`
     */
  starColor?: StarColorsType;
}

export default class StarRating extends PureComponent<StarRatingProps> {
  static propTypes = {
    /**
     * We automatically round the number to the nearest 0.5.
     * Any number over 5 will render as 5 stars.
     */
    rating: PT.number,
    /**
     * Use this prop to add a suffix to the aria-label.
    */
    ratingSuffix: PT.string,
    /**
     * Use this prop to provide localized aria-label in internationalized UIs.
     */
    'aria-label': PT.string,
    /**
     * Sets the color of the stars to either 'gold' or 'inkwell'
     * default is 'gold'
     */
    starColor: PT.oneOf(['gold', 'inkwell']),
  }

  static defaultProps = {
    rating: 0,
    ratingSuffix: 'out of 5 stars',
    'aria-label': undefined,
    starColor: 'gold',
  }

  render(): JSX.Element {
    const {
      rating = 0,
      ratingSuffix,
      'aria-label': ariaLabel,
      starColor = 'gold',
      ...rest
    } = this.props;
    const validRating = getValidRating(rating);

    return (
      <StarRatingContainer>
        {(className?: string) => (
          <StarsContainer
            {...rest}
            role="img"
            aria-label={ariaLabel || `${validRating} ${ratingSuffix}`}
            /** Dangerously setting innerHTML was found to be up to
             * 10x more performant than rendering as React elements
             * due to the amount of SVGs required in the StarRating component. */
            dangerouslySetInnerHTML={renderStars(validRating, className ?? '', starColor)}
          />
        )}
      </StarRatingContainer>
    );
  }
}
