import createSurface from './createSurface';
import { Surface } from './types';

export const SurfaceDark: Surface = Object.assign(
  createSurface('dark'),
  { displayName: 'SurfaceDark' },
);
export const SurfaceDarkInfo: Surface = Object.assign(
  createSurface('blue'),
  { displayName: 'SurfaceDarkInfo' },
);
export const SurfaceLight: Surface = Object.assign(
  createSurface('light'),
  { displayName: 'SurfaceLight' },
);
