/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Hủy"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Lưu"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Tùy chỉnh"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Chọn một ngày"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Chọn một tháng"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Chọn một tuần"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "30 ngày qua"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "7 ngày qua"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Tháng trước"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Tuần trước"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Trọn đời"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Tuần này"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Hôm nay"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Từ đầu năm đến nay"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Hôm qua"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
