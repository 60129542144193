import React, {
  KeyboardEvent,
  MouseEvent,
  Component,
  createRef,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { TaktProps, TaktIdConsumer, createStormTaktId } from '@amzn/storm-ui';
import { angleDown, spinner } from '@amzn/storm-ui-icons';
import { composeRefs } from '@amzn/storm-ui-utils';
import MultiSelectItem from './MultiSelectItem';
import { getFocusableElements, handlePopoverKeyDownEvents, handleDropdownButtonKeyDownEvents } from './utils';
import { Position, OptionValue, MultiSelectToggleEventHandler } from './types';
import isElementOf from './isElementOf';
import MultiSelectWrapper from './MultiSelectWrapper';
import MultiSelectDivider from './MultiSelectDivider';
import MultiSelectContext from './MultiSelectContext';
import { MultiSelectIsMobileProvider, MultiSelectIsMobileConsumer } from './MultiSelectIsMobileContext';
import {
  Wrapper,
  LabelText,
  DropdownLabel,
  DropdownIcon,
  LoadingIcon,
  MultiSelectTriggrButton,
  MultiSelectTriggrButtonProps,
  MultiSelectContainer,
  MultiSelectFixedContainer,
  MultiSelectItemsContainer,
} from './MultiSelect.styles';

const isMultiSelectItem = isElementOf(MultiSelectItem);

type OnOverrideLabelType = (
  selectedValues: OptionValue[], selectedItems: MultiSelectItem[]) => ReactNode;

export interface MultiSelectProps extends Omit<MultiSelectTriggrButtonProps, '$error' | 'onChange' | 'ref'>, TaktProps {
  /**
   * Ids for actionable components
   */
  name: string;
  /**
   * Selectable Option Value, number | string
   */
  selectedValues: OptionValue[];
  /**
   * `<MultiSelectItem />` usually, but can also be `<DropdownDivider />` or `<DropdownItemGroup />`
   * or any other react elements. Only MultiSelectItem will be used for selection purposes.
   * *MultiSelectItem must be direct child of MultiSelect*
   */
  children: ReactNode;
  /**
   * Displays a red border and sets aria-invalid="true" when true. If a dropdown is in an invalid
   * state, pair and describe it with an `<InlineMessage error />` to explain the problem.
   * @defaultValue `false`
   */
  error?: boolean;
  onChange: (selectedValues: OptionValue[], event: MouseEvent | KeyboardEvent) => void;
  /**
   * The text label for the dropdown.
   * @defaultValue `""`
   */
  label: string;
  /**
   * Moves the label inline.
   * @defaultValue `false`
   */
  inline: boolean;
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel: boolean;
  /**
   * Used to render content *before* the label
   * @defaultValue `undefined`
   */
  renderLabelStart: ReactNode | (() => ReactNode);
  /**
   * Used to render content *after* the label
   * @defaultValue `undefined`
   */
  renderLabelEnd: ReactNode | (() => ReactNode);
  /**
   * Accepts elements to be rendered inside the close button in the SecondaryView's header.
   * @defaultValue `"done"`
   */
  secondaryViewCloseButtonLabel: ReactNode;
  /**
   * Overrides the placeholder text for the dropdown.
   * @defaultValue `undefined`
   */
  selectedLabelOverride?: string,
  /**
   * Hook in to this to display the "selected" label differently than how
   * it appears in the list item.
   * @defaultValue `defaultOnOverrideLabel`
   */
  onOverrideLabel: OnOverrideLabelType,
  /**
   * Should be `<MultiSelectSimpleBulkActionPanel />` or `<MultiSelectBulkActionPanel />`
   * @defaultValue `null`
   */
  withBulkActionPanel: null | JSX.Element,
  /**
   * Should be `<MultiSelectSearchPanel />`
   * @defaultValue `null`
   */
  withSearchPanel: null | JSX.Element;
  /**
   * Specifies that the dropdown should be a smaller size than default.
   * @defaultValue `false`
   */
  small: boolean;
  /**
   * Used to render dropdown button with no background.
   * @defaultValue `false`
   */
  transparentButton: boolean,
  /**
   * Force dropdown to fill container (instead of matching content). Can be used to
   * size custom by giving container a width.
   * @defaultValue `false`
   */
  fullWidth: boolean;
  /**
   * Popover position relative to dropdown buttion
   * 'bottom' | 'overlay'
   * @defaultValue `"overlay"`
   */
  popoverPosition: Position;
  /**
   * Specifies if the control is disabled, which prevents the user from modifying the value and
   * prevents the value from being included in a form submission. A disabled control can't receive
   * focus.
   * @defaultValue `false`
   */
  disabled: boolean,
  /**
   * Set the popover to be loading state (spinner icon)
   * @defaultValue `false`
   */
  loading: boolean,
  /**
   * Can be used to render elements prior to the children of `<MultiSelect />`.
   * Useful to have a sticky section which does not scroll with long dropdown lists.
   * @defaultValue `null`
   */
  preRender: null | (() => ReactNode),
  /**
   * Can be used to render elements after the children of `<MultiSelect />`.
   * Useful to have a sticky section which does not scroll with long dropdown lists.
   * @defaultValue `null`
   */
  postRender: null | (() => ReactNode),
  /**
   * Function called when the dropdown is opened.
   * @defaultValue `() => null`
   */
  onOpen: (event: MouseEvent | KeyboardEvent) => void,
  /**
   * Function called when the dropdown is closed.
   * @defaultValue `() => null`
   */
  onClose: (event: MouseEvent | KeyboardEvent) => void,
  /**
   * Used to render dropdown in SecondaryView on mobile device.
   * @defaultValue `false`
   */
  mobileFullScreen?: boolean;
}

type MultiSelectDefaultProps = Omit<MultiSelectProps,
  'name' | 'selectedValues' | 'children' | 'onChange'>;

interface MultiSelectState {
  isPopoverVisible: boolean;
}

const defaultOnOverrideLabel: OnOverrideLabelType = (selectedValues, selectedItems) => {
  if (selectedValues.length === 0) {
    return 'Select Options';
  }
  if (selectedValues.length === 1) {
    return selectedItems.length > 0 ? selectedItems[0].props.children : '';
  }
  const label = selectedItems.length > 0 ? selectedItems.map(item => item.props.children).reduce((prev, curr) => [prev, ', ', curr]) : '';
  const prefix = (
    <span key={`prefix-${selectedValues.length}`}>({selectedValues.length} selected)</span>
  );
  return [prefix, ' ', label];
};

const getSelectedItems = (selectedValuesSet: Set<OptionValue>, multiSelectChildren: ReactNode): MultiSelectItem[] => {
  const selectedItems: MultiSelectItem[] = [];

  const checkChildren = (children: ReactNode) => {
    React.Children.forEach(children, child => {
      if (child) {
        if (isMultiSelectItem(child)
          && selectedValuesSet.has((child as unknown as MultiSelectItem).props.value)) {
          selectedItems.push(child as unknown as MultiSelectItem);
        } else if ((child as any)?.props?.children) {
          checkChildren((child as any).props.children);
        }
      }
    });
  }

  checkChildren(multiSelectChildren);

  return selectedItems;
}

/**
 * Basic MultiSelect Component
 *
 * Fully controlled components, selectedValues & onChange must be provided.
 *
 */
class MultiSelect extends Component<MultiSelectProps, MultiSelectState> {
  static propTypes = {
    name: PropTypes.string.isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])).isRequired,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,

    /*
      Description Label
    */
    label: PropTypes.string,
    inline: PropTypes.bool,
    hideLabel: PropTypes.bool,
    renderLabelStart: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    renderLabelEnd: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    secondaryViewCloseButtonLabel: PropTypes.node,

    /*
      Dropdown Button Label
    */
    selectedLabelOverride: PropTypes.string,
    onOverrideLabel: PropTypes.func,

    /*
      Add-on Plugins
    */
    withBulkActionPanel: PropTypes.element,
    withSearchPanel: PropTypes.element,

    /*
      Styling
    */
    small: PropTypes.bool,
    transparentButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    popoverPosition: PropTypes.string,

    /*
      Status
    */
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,

    /*
      Advanced Control
    */
    preRender: PropTypes.func,
    postRender: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,

    mobileFullScreen: PropTypes.bool,
  };

  static defaultProps: MultiSelectDefaultProps = {
    label: '',
    inline: false,
    hideLabel: false,
    renderLabelStart: undefined,
    renderLabelEnd: undefined,
    secondaryViewCloseButtonLabel: 'Done',

    selectedLabelOverride: undefined,
    onOverrideLabel: defaultOnOverrideLabel,

    withBulkActionPanel: null,
    withSearchPanel: null,

    small: false,
    transparentButton: false,
    fullWidth: false,
    popoverPosition: 'overlay',

    loading: false,
    disabled: false,
    error: false,

    preRender: null,
    postRender: null,
    onOpen: () => null,
    onClose: () => null,
    mobileFullScreen: false,
  };

  // Declar refs
  private bulkChoicePanel: any;

  private searchPanel: any;

  private toggleRef = createRef<HTMLButtonElement>();

  private popoverRef = createRef<HTMLDivElement>();

  constructor(props: MultiSelectProps) {
    super(props);
    this.state = {
      isPopoverVisible: false,
    };
  }

  componentDidUpdate(prevProps: MultiSelectProps): void {
    const { fullWidth, selectedValues } = this.props;
    // Force adjust the width of the popover
    if (!fullWidth
      && selectedValues.length !== prevProps.selectedValues.length) {
      this.forceUpdate();
    }
  }

  handleChange: MultiSelectToggleEventHandler = (value, event) => {
    if (this.bulkChoicePanel && typeof this.bulkChoicePanel.selectedItemsChange === 'function') {
      this.bulkChoicePanel.selectedItemsChange();
    }

    let values = [];
    const index = this.props.selectedValues.indexOf(value);
    if (index !== -1) {
      values = this.props.selectedValues.filter(val => val !== value);
    } else {
      values = [...this.props.selectedValues, value];
    }

    this.props.onChange(values, event);
  }

  togglePopoverView = (event: MouseEvent | KeyboardEvent): void => {
    // searchKey must be cleared before closing the popover
    if (this.searchPanel && typeof this.searchPanel.handleFilterClear === 'function') {
      this.searchPanel.handleFilterClear();
    }

    if (!!event && typeof event.persist === 'function') {
      event.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    }

    this.setState(
      prevState => ({ isPopoverVisible: !prevState.isPopoverVisible }),
      () => {
        if (this.state.isPopoverVisible) {
          this.props.onOpen(event);
        } else {
          this.props.onClose(event);
        }
      },
    );
  }

  handleFocus = (element: HTMLButtonElement | null): void => {
    if (element) {
      element.focus();
    }
  }

  handleEscape = (event: KeyboardEvent): void => {
    this.togglePopoverView(event);
    this.handleFocus(this.toggleRef.current);
  }

  handleKeyboardSelect = (event: KeyboardEvent): void => {
    this.handleChange((event.target as HTMLInputElement).value, event);
  }

  handleKeyDown = (event: KeyboardEvent): void => handlePopoverKeyDownEvents(
    event,
    this.popoverRef?.current,
    this.handleEscape,
    this.handleKeyboardSelect,
  );

  onPopoverElementDidMount = (popoverEl?: HTMLElement | null): void => {
    const focusableEls = getFocusableElements(popoverEl) as HTMLElement[];
    if (focusableEls.length > 0) {
      focusableEls[0].focus();
    }
  }

  handleDropdownButtonKeyDown = (event: KeyboardEvent): void => handleDropdownButtonKeyDownEvents(
    event,
    this.togglePopoverView,
  )

  renderPopover(): JSX.Element {
    const {
      children,
      name,
      selectedValues,
      withBulkActionPanel,
      withSearchPanel,
      preRender,
      postRender,
    } = this.props;

    return (
      <MultiSelectIsMobileConsumer>
        {isMobile => (
          <MultiSelectContainer ref={this.popoverRef} $isMobile={isMobile}>
            { (withSearchPanel || withBulkActionPanel || preRender) && (
            <>
              <MultiSelectFixedContainer $isMobile={isMobile}>
                {withSearchPanel && React.cloneElement(withSearchPanel, {
                  name: `${name}-searchPanel`,
                  ref: (component: ReactNode) => { this.searchPanel = component; },
                })}
                {withBulkActionPanel && React.cloneElement(withBulkActionPanel, {
                  name: `${name}-bulkPanel`,
                  selectedValues,
                  ref: (component: ReactNode) => { this.bulkChoicePanel = component; },
                })}
                {preRender && preRender()}
              </MultiSelectFixedContainer>
              <MultiSelectDivider />
            </>
            )}
            <MultiSelectItemsContainer $isMobile={isMobile}>
              {children}
            </MultiSelectItemsContainer>
            { postRender && (
            <>
              <MultiSelectDivider />
              <MultiSelectFixedContainer $isMobile={isMobile}>
                {postRender()}
              </MultiSelectFixedContainer>
            </>
            )}
          </MultiSelectContainer>
        )}
      </MultiSelectIsMobileConsumer>
    );
  }

  render(): JSX.Element {
    const {
      children,
      error,
      name,
      selectedValues,
      onChange,
      label,
      renderLabelStart,
      renderLabelEnd,
      inline,
      fullWidth,
      hideLabel,
      selectedLabelOverride,
      onOverrideLabel,
      withBulkActionPanel,
      withSearchPanel,
      small,
      transparentButton,
      loading,
      disabled,
      popoverPosition,
      preRender,
      postRender,
      onOpen,
      onClose,
      secondaryViewCloseButtonLabel,
      mobileFullScreen,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const { isPopoverVisible } = this.state;

    const selectedValuesSet = new Set([...selectedValues]);

    const buttonText = selectedLabelOverride
    || onOverrideLabel(selectedValues, getSelectedItems(selectedValuesSet, children));

    const buttonId = `${name}-button`;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('multi-select')}>
        {({ getDataTaktAttributes }) => (
          <MultiSelectIsMobileProvider mobileFullScreen={mobileFullScreen}>
            <MultiSelectWrapper
              selectedValues={selectedValues}
              anchorEl={this.toggleRef}
              isOpen={isPopoverVisible}
              onClose={this.togglePopoverView}
              position={popoverPosition}
              $fullWidth={fullWidth}
              onKeyDown={this.handleKeyDown}
              popperElementDidMount={this.onPopoverElementDidMount}
              secondaryViewCloseButtonLabel={secondaryViewCloseButtonLabel}
              trigger={triggerProps => (
                <Wrapper $fullWidth={fullWidth} $inline={inline}>
                  {
                    !hideLabel && !!label
                      && (
                        <LabelText
                          $inline={inline}
                          htmlFor={buttonId}
                        >
                          {renderLabelStart && (typeof renderLabelStart === 'function'
                            ? renderLabelStart() : renderLabelStart)}
                          {label}
                          {renderLabelEnd && (typeof renderLabelEnd === 'function'
                            ? renderLabelEnd() : renderLabelEnd)}
                        </LabelText>
                      )
                  }
                  <MultiSelectTriggrButton
                    {...getDataTaktAttributes({ taktIdSuffix: 'trigger-button' })}
                    {...rest}
                    id={buttonId}
                    disabled={disabled}
                    small={small}
                    transparent={transparentButton}
                    onClick={this.togglePopoverView}
                    buttonRef={composeRefs(this.toggleRef, triggerProps?.setTriggerElement)}
                    aria-haspopup="listbox"
                    onKeyDown={this.handleDropdownButtonKeyDown}
                    aria-invalid={error}
                    $error={error}
                  >
                    <DropdownLabel>
                      {buttonText}
                    </DropdownLabel>
                    <DropdownIcon type={angleDown} />
                  </MultiSelectTriggrButton>
                </Wrapper>
              )}
            >
              {
                loading
                  ? <LoadingIcon type={spinner} />
                  : (
                    <MultiSelectContext.Provider
                      value={{
                        selectedValuesSet,
                        onToggle: this.handleChange,
                      }}
                    >
                      {this.renderPopover()}
                    </MultiSelectContext.Provider>
                  )
              }
            </MultiSelectWrapper>
          </MultiSelectIsMobileProvider>
        )}
      </TaktIdConsumer>
    );
  }
}

export default MultiSelect;
