/**
 * Mix-in for styled-components that checks whether the theme in the context
 * is the evolution theme.
 */
export default function isEvolution(style: any) {
  return ({ theme }: any) => {
    if (theme?.isEvolutionRebrand) {
      return [style];
    }

    return null;
  };
}
