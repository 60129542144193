import {
  useContext, useMemo, useState, useEffect,
} from 'react';
import { ThemeContext } from 'styled-components';
import { isFunction, isString } from '@amzn/storm-ui-utils-v3';

type matchMediaChangeEvent = {
  matches: boolean;
}

export default function useBrowserIsMobile() {
  /* outside the browser context always return undefined */
  if (!isFunction(window.matchMedia)) {
    return undefined;
  }

  const mediaQuery = useContext(ThemeContext)?.mobile?.mediaQuery;

  /* Return undefined if we do not have media query to detect mobile/not-mobile status */
  if (!isString(mediaQuery)) {
    return undefined;
  }

  /* Create a matchMedia object. Recreate it if the mediaQuery changes */
  const isMobileMatchMedia = useMemo(() => window.matchMedia(mediaQuery), [mediaQuery]);

  const [isBrowserMobile, setBrowserMobile] = useState<boolean>(isMobileMatchMedia.matches);

  useEffect(() => {
    /* setup a listener for browser change its mobile/not-mobile status */
    const isMobileMatchMediaListener = (event: matchMediaChangeEvent) => {
      setBrowserMobile(event.matches);
    };

    isMobileMatchMedia.addListener(isMobileMatchMediaListener);
    return () => {
      /* cleanup match media event listener */
      isMobileMatchMedia.removeListener?.(isMobileMatchMediaListener);
    };
  }, [mediaQuery]);

  return isBrowserMobile;
}
