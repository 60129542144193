import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledTh = styled.th`
  flex: 1 0;
  background: ${({ theme }) => theme.table.header.bg};
  color: ${({ theme }) => theme.table.header.color};
  font-size: ${({ theme }) => theme.table.header.fontSize};
  font-weight: ${({ theme }) => theme.table.header.fontWeight};
  padding-top: ${({ theme }) => theme.table.header.paddingTop};
  padding-right: ${({ theme }) => theme.table.header.paddingRight};
  padding-bottom: ${({ theme }) => theme.table.header.paddingBottom};
  padding-left: ${({ theme }) => theme.table.header.paddingLeft};
  vertical-align: top;
  text-align: start;

  :first-child {
    padding-left: ${({ theme }) => theme.table.header.paddingLeft};
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.table.mobile.header.fontSize};
  `)}
`;
export interface KeyCellProps extends MergeElementProps<'th'> {}

const KeyCell: FC<React.PropsWithChildren<KeyCellProps>> = ({ children, ...rest }) => (
  <StyledTh role="rowheader" scope="row" {...rest}>
    {children}
  </StyledTh>
);

export default KeyCell;
