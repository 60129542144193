import styled, { css } from 'styled-components';
import { deviceTypes, getPadding } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { CardPadding } from './types';

export interface CardStyledProps {
  isCollapsed?: boolean;
}

export const CardStyled = styled.div<CardStyledProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  border:  ${({ theme }) => theme.card.border};
  background:  ${({ theme }) => theme.card.bg};
  box-shadow:  ${({ theme }) => theme.card.boxShadow};
  border-radius:  ${({ theme }) => theme.card.radius};
  > *:first-child {
    border-top-left-radius:  ${({ theme }) => theme.card.radius};
    border-top-right-radius:  ${({ theme }) => theme.card.radius};
  }

  > *:last-child {
    border-bottom-left-radius:  ${({ theme }) => theme.card.radius};
    border-bottom-right-radius:  ${({ theme }) => theme.card.radius};
  }

  width: 100%;
  ul, ol {
    color: inherit;
  }
`;
CardStyled.displayName = 'CardStyled';

interface CardBodyStyledProps {
  visible?: boolean;
  padding?: CardPadding;
}

export const CardBodyStyled = styled.div<CardBodyStyledProps>`
  ${({ visible }) => (visible === false ? 'display:  none;' : '')}
  padding: ${({ theme, padding }) => getPadding(theme, padding)}px;
  flex: 1 1 auto;

  ${isMobile(css<CardBodyStyledProps>`
    padding: ${({ theme, padding }) => getPadding(theme, padding, deviceTypes.MOBILE)}px;
  `)}
`;
CardBodyStyled.displayName = 'CardBodyStyled';
