import { useContext } from 'react';
import DateInputContext from '../contexts/DateInput/DateInputContext';
import { DateInputContextValue } from '../types';

const useDateInput = (): DateInputContextValue => {
  const context = useContext(DateInputContext);
  if (!context) {
    throw new Error(
      'DateInput context is not defined.',
    );
  }
  return context;
};

export default useDateInput;
