import { palette } from '../base/color';

const sortableListStyles = {
  borderColor: palette.gray[100],
  bgColor: palette.gray[50],
  boxShadow: '2px 2px 2px 0 rgba(96,95,95,0.30)',
  handleHoverColor: palette.squidInk,
  handActiveColor: palette.squidInk,
  handleColor: palette.gray[700],
};

export default sortableListStyles;
