import React, { PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import type { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils-v3';
import focusPseudoElementsAfter from '../FocusIndicator/styleMixins/focusPseudoElementsAfter';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

const TabbedCardItemContainer = styled('div').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})`
  outline: none;
  position: relative;
  ${({ focusVisible }) => (focusVisible && focusPseudoElementsAfter)}
`;
TabbedCardItemContainer.displayName = 'TabbedCardItemContainer';

export interface TabbedCardItemProps extends TaktProps, MergeStyledComponentElementProps<'div'> {
  /* eslint-disable react/no-unused-prop-types */
    /**
     * The contents of the card item.
     */
  value: string;
  /**
     * The contents of the tab relating to this card. Any content may be passed
     * for custom formatting. We will pass isActive to any custom class or
     * function component, for use in special actions within those tabs based on state.
     * A string will give default styles.
     */
  tabChildren: ReactNode;
}

export default class TabbedCardItem extends PureComponent<TabbedCardItemProps> {
  static propTypes = {
    // The following are used by the parent (TabbedCard)
    /* eslint-disable react/no-unused-prop-types */
    /**
     * The contents of the card item.
     */
    value: PT.string.isRequired, // must be unique within parent!
    /**
     * The contents of the tab relating to this card. Any content may be passed
     * for custom formatting. We will pass isActive to any custom class or
     * function component, for use in special actions within those tabs based on state.
     * A string will give default styles.
     */
    tabChildren: PT.oneOfType([PT.element, PT.string]).isRequired,
    /* eslint-enable */
    /**
     * Must be a unique value for this tabbed card group. Used by parent to
     * determine which card is selected.
     */
    children: PT.node.isRequired,
  }

  render(): JSX.Element {
    const {
      children, value, taktId, taktValue, ...rest
    } = this.props;
    // Returning tab content. Actual tab rendered in TabbedCard.
    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tabbed-card-item')}>
        <TabbedCardItemContainer
          {...rest}
          role="tabpanel"
          aria-labelledby={value}
          tabIndex={0}
        >
          {children}
        </TabbedCardItemContainer>
      </TaktIdProvider>
    );
  }
}
