import { ISODate, UnresolvedPresetRange } from '../../types';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from '../../utils/dateUtils';
import {
  endOfDayAtTz,
  nowAtTz,
  startOfDayAtTz,
} from '../../utils/timezoneUtils';

const DATE_AMAZON_FOUNDED: ISODate = '1994-07-05';

const presets: Array<UnresolvedPresetRange> = [
  {
    label: 'Today',
    period: 'TODAY',
    start: (today: ISODate, zone: string) => startOfDayAtTz(today, zone),
    end: (today: ISODate, zone: string) => endOfDayAtTz(today, zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Yesterday',
    period: 'YESTERDAY',
    start: (today: ISODate, zone: string) => startOfDayAtTz(addDays(today, -1), zone),
    end: (today: ISODate, zone: string) => endOfDayAtTz(addDays(today, -1), zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Last 7 days',
    period: 'LAST_7_DAYS',
    start: (today: ISODate, zone: string) => nowAtTz(addDays(today, -7), zone),
    end: (today: ISODate, zone: string) => nowAtTz(today, zone),
    isDefaultTranslation: true,
  },
  {
    label: 'This week',
    period: 'THIS_WEEK',
    start: (today: ISODate, zone: string, locale?: Locale) => startOfDayAtTz(startOfWeek(today, locale), zone),
    end: (today: ISODate, zone: string, locale?: Locale) => endOfDayAtTz(endOfWeek(today, locale), zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Last week',
    period: 'LAST_WEEK',
    start: (
      today: ISODate,
      zone: string,
      locale?: Locale,
    ) => startOfDayAtTz(addWeeks(startOfWeek(today, locale), -1), zone),
    end: (
      today: ISODate,
      zone: string,
      locale?: Locale,
    ) => endOfDayAtTz(addWeeks(endOfWeek(today, locale), -1), zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Last 30 days',
    period: 'LAST_30_DAYS',
    start: (today: ISODate, zone: string) => nowAtTz(addDays(today, -30), zone),
    end: (today: ISODate, zone: string) => nowAtTz(today, zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Last month',
    period: 'LAST_MONTH',
    start: (
      today: ISODate,
      zone: string,
    ) => startOfDayAtTz(startOfMonth(addMonths(today, -1)), zone),
    end: (
      today: ISODate,
      zone: string,
    ) => endOfDayAtTz(endOfMonth(addMonths(today, -1)), zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Year to date',
    period: 'YEAR_TO_DATE',
    start: (today: ISODate, zone: string) => startOfDayAtTz(`${startOfYear(today)}`, zone),
    end: (today: ISODate, zone: string) => nowAtTz(today, zone),
    isDefaultTranslation: true,
  },
  {
    label: 'Lifetime',
    period: 'LIFETIME',
    start: (today: ISODate, zone: string) => nowAtTz(DATE_AMAZON_FOUNDED, zone),
    end: (today: ISODate, zone: string) => nowAtTz(today, zone),
    isDefaultTranslation: true,
  },
];

export default presets;
