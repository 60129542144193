/* eslint-disable react/no-array-index-key */
import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const BORDER_WIDTH = '1px';

const displayAllMixin = css`
  width: ${({ theme }) => `calc(${theme.tree.arrowButtonDisplayAllSpacerWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
  :first-child {
    width: ${({ theme }) => `calc(${theme.tree.arrowButtonDisplayAllTreeNodeSpacerWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
  }

  ${isMobile(css`
    width: ${({ theme }) => `calc(${theme.tree.mobile.arrowButtonDisplayAllSpacerWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
    :first-child {
      width: ${({ theme }) => `calc(${theme.tree.mobile.arrowButtonDisplayAllTreeNodeSpacerWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
    }
  `)}
`;

interface SpacerProps {
  $displayAll?: boolean;
}

const Spacer = styled('div')<SpacerProps>`
  border-inline-end: ${BORDER_WIDTH} solid ${({ theme }) => theme.palette.gray[100]};
  flex: 0 0 auto;

  width: ${({ theme }) => `calc(${theme.tree.arrowButtonWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
  ${isMobile(css`
    width: ${({ theme }) => `calc(${theme.tree.mobile.arrowButtonWidth} - ${BORDER_WIDTH} + ${theme.tree.nodeHalfWidth})`};
  `)}

  ${({ $displayAll }) => ($displayAll && displayAllMixin)}
`
export interface DepthSpacerProps {
  depth: number;
  displayAll?: boolean;
}

const DepthSpacer: FC<DepthSpacerProps> = ({ depth, displayAll }) => (
  <>
    {
      new Array(depth).fill(0).map<JSX.Element>((_unused, index) => (
        <Spacer key={index} $displayAll={displayAll} />
      ))
    }
  </>
);

DepthSpacer.defaultProps = {
  displayAll: false,
};

export default memo(DepthSpacer) as FC<DepthSpacerProps>;
