import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { times } from '@amzn/storm-ui-icons-v3';
import { noop } from '@amzn/storm-ui-utils-v3';
import Text from '../Text';
import Icon from '../Icon';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const StyledPill = styled.div`
  color: ${({ theme }) => theme.pillLegacy.color};
  font-size: ${({ theme }) => theme.pillLegacy.fontSize};
  line-height: ${({ theme }) => theme.pillLegacy.lineHeight};
  width: fit-content;
  background: ${({ theme }) => theme.pillLegacy.bg};
  padding-inline-start: ${({ theme }) => theme.pillLegacy.paddingInlineStart};
  padding-inline-end: ${({ theme }) => theme.pillLegacy.paddingInlineEnd};
  border-radius: ${({ theme }) => theme.pillLegacy.radius};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  display: flex;
  align-items: center;

  ${({ disabled, theme }) => (disabled && `
    color: ${theme.pillLegacy.disabledText};
    background: ${theme.pillLegacy.disabledBg};
    outline: none;
  `)}

  ${isMobile(css`
    font-size: ${({ theme }) => theme.pillLegacy.mobile.fontSize};
  `)}
`;
StyledPill.displayName = 'StyledPill';

const StyledPillLabel = styled.div`
  max-width: ${({ fullWidth, maxWidth, theme }) => (fullWidth ? 'auto' : (`${maxWidth}px` || theme.pillLegacy.maxWidth))};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

StyledPillLabel.displayName = 'StyledPillLabel';

const IconButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})`
  color: ${({ disabled, theme }) => (disabled ? theme.pillLegacy.closeButton.disabledColor : theme.pillLegacy.closeButton.color)};
  margin-inline-start: ${({ theme }) => theme.spacing.micro};
  opacity: 1;
  background: none;
  border: none;
  border-radius: ${({ theme }) => theme.pillLegacy.radius};
  padding: ${({ theme }) => theme.pillLegacy.iconPadding};

  .svg-inline--fa{
    vertical-align: middle;
  }

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ disabled, theme }) => (disabled ? theme.pillLegacy.closeButton.disabledColor : theme.pillLegacy.closeButton.hoverColor)};
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }

  ${isMobile(css`
    padding: ${({ theme }) => theme.pillLegacy.mobile.iconPadding};
  `)}
`;
IconButton.displayName = 'IconButton';

export default class Pill extends PureComponent {
  static propTypes = {
    /**
     * The text displayed on the Pill.
     */
    label: PT.string.isRequired,
    /**
     * Used to disable the Pill.
     */
    disabled: PT.bool,
    /**
     * Add text to create a text divider.
     */
    fullWidth: PT.bool,
    /**
     * Configure the max-width css rule. When prop fullWidth is set to true, max-width is auto.
     */
    maxWidth: PT.number,
    /**
     * The class name passed to the styled Pill.
     */
    className: PT.string,
    /**
     * Called when the Pill is clicked.
     */
    onClick: PT.func,
    /**
     * Called when `X` is clicked.
     */
    onClose: PT.func,
    /**
     * Text that a screen reader will read out when the close button is in focus.
     */
    closeLabel: PT.string,
  }

  static defaultProps = {
    disabled: false,
    fullWidth: false,
    maxWidth: undefined,
    closeLabel: 'remove',
    className: '',
    onClick: noop,
    onClose: noop,
  }

  // Prevent the onClose event from firing the onClick event of the parent.
  onClose = event => {
    event.stopPropagation();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const {
      label,
      disabled,
      fullWidth,
      maxWidth,
      className,
      onClick,
      onClose,
      closeLabel,
      ...rest
    } = this.props;

    return (
      <StyledPill
        disabled={disabled}
        fullWidth={fullWidth}
        className={className}
        onClick={onClick}
        {...rest}
      >
        <StyledPillLabel
          disabled={disabled}
          title={label}
          maxWidth={maxWidth}
        >
          {label}
        </StyledPillLabel>
        <IconButton
          disabled={disabled}
          onClick={this.onClose}
          type="button"
        >
          <Text type="sr-only">{closeLabel}</Text>
          <Icon type={times} />
        </IconButton>
      </StyledPill>
    );
  }
}
