import { hasOwnProperty } from '@amzn/storm-ui-utils';
import { ClickableListItem } from '../types';

const isClickableListItem = (item?: unknown): item is ClickableListItem => !!(
  hasOwnProperty(item, 'type')
  && hasOwnProperty(item.type, 'displayName')
  && typeof item.type.displayName === 'string'
);

export default isClickableListItem;
