/**
 * Creates a new event triggering function that sends a custom change Event
 * to takt.
 *
 * @param eventName Name of the event you want sent to takt.
 */
export default function createTriggerPopoverChangeEvent(eventName: string, componentName = 'popover') {
  return function triggerPopoverChangeEvent(element: HTMLElement, instanceName?: string): void {
    try {
      // Assign a data-takt-value property to the element for takt's content field.
      element.setAttribute('data-takt-value', `${instanceName ?? componentName}-${eventName}`);
      // create a change event and bubble it to the document root for takt to record.
      let event;
      if (typeof (Event) === 'function') {
        // modern browser Events
        event = new Event('change', { bubbles: true, composed: true });
      } else {
        // Legacy IE Events
        event = document.createEvent('Event');
        event.initEvent('change', true, true);
      }
      element.dispatchEvent(event);
    } catch (error) {
      /* log error and continue */
      // eslint-disable-next-line
      console.error(error);
    }
  };
}
