(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@amzn/storm-ui-v3"), require("styled-components"), require("react-measure"), require("victory"), require("@amzn/storm-ui-filter-dropdown-v3"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@amzn/storm-ui-v3", "styled-components", "react-measure", "victory", "@amzn/storm-ui-filter-dropdown-v3"], factory);
	else if(typeof exports === 'object')
		exports["main"] = factory(require("react"), require("@amzn/storm-ui-v3"), require("styled-components"), require("react-measure"), require("victory"), require("@amzn/storm-ui-filter-dropdown-v3"));
	else
		root["main"] = factory(root["react"], root["@amzn/storm-ui-v3"], root["styled-components"], root["react-measure"], root["victory"], root["@amzn/storm-ui-filter-dropdown-v3"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__4731__, __WEBPACK_EXTERNAL_MODULE__1608__, __WEBPACK_EXTERNAL_MODULE__8850__, __WEBPACK_EXTERNAL_MODULE__7426__, __WEBPACK_EXTERNAL_MODULE__1687__) => {
return 