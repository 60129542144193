import { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const formGroupColumnStyleMixin = css<{ fullWidth?: boolean }>`
  ${({ fullWidth, theme }) => !fullWidth && `max-width: ${theme.form.input.maxWidth};`}
  width: 100%;
  padding: 0;
  font-weight: initial;

  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  > * {
    margin-bottom: ${({ theme }) => theme.form.input.internalElementBlockSpacing};
    margin-block-end: ${({ theme }) => theme.form.input.internalElementBlockSpacing};

    :last-child{
      margin-bottom: 0;
      margin-block-end: 0;
    }
  }

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    max-width: ${({ theme }) => theme.form.input.mobile.maxWidth};
    > * {
      margin-bottom: ${({ theme }) => theme.form.input.mobile.internalElementBlockSpacing};
      margin-block-end: ${({ theme }) => theme.form.input.mobile.internalElementBlockSpacing};
    }
  `)}
`;

export default formGroupColumnStyleMixin;
