import { palette } from '../base/color';
import { typography } from '../base/typography';

const labelStyles = {
  color: typography.color.primary,
  colorDisabled: palette.gray[700],
  weight: '700',
  paddingBottom: '2px',
};

export default labelStyles;
