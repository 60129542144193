import { useReducer, useCallback } from 'react';
import MobileMode from './MobileMode';
import MobileModeChangeSource from './MobileModeChangeSource';
import { MobileModeChangeEvent } from './types';

/*
 The useDefaultStateManager() sets the default logic to picking a mobile or not-mobile user
 experience.

 There are three possible sources of truth for device-specific UX:

 1. Manually set user UX preference (Highest priority)
 2. Client-side media queries
 3. Server-side device detection (Lowest priority)
 */

export enum Actions {
  BROWSER_IS_MOBILE = 'BROWSER_IS_MOBILE',
  USER_IS_MOBILE = 'USER_IS_MOBILE',
}

type Action = {
  type: Actions;
  payload: MobileMode;
}

type State = {
  browserIsMobile: MobileMode;
  userIsMobile: MobileMode;
  isMobile?: boolean;
}

type UseDefaultStateManager = {
  isMobile: boolean;
  onMobileModeChange: (event: MobileModeChangeEvent) => void;
}

function getFirstActiveMode(modeOptions: MobileMode[]): boolean | undefined {
  return modeOptions.reduce((acc: boolean | undefined, value: MobileMode) => {
    if (acc !== undefined) {
      return acc;
    }
    if (value === MobileMode.Enable) {
      return true;
    }
    if (value === MobileMode.Disable) {
      return false;
    }
    return acc;
  }, undefined);
}

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case Actions.USER_IS_MOBILE:
      return {
        ...state,
        userIsMobile: payload,
        isMobile: getFirstActiveMode([payload, state.browserIsMobile]),
      };
    case Actions.BROWSER_IS_MOBILE:
      return {
        ...state,
        browserIsMobile: payload,
        isMobile: getFirstActiveMode([state.userIsMobile, payload]),
      };
  }
  return state;
}

function useDefaultStateManager(initialIsMobile = false): UseDefaultStateManager {
  const initialState = {
    userIsMobile: MobileMode.Auto,
    browserIsMobile: MobileMode.Auto,
    isMobile: undefined,
  };

  const [{ isMobile }, dispatch] = useReducer(reducer, initialState);

  const onMobileModeChange = useCallback((event: MobileModeChangeEvent) => {
    if (event.source === MobileModeChangeSource.Browser) {
      dispatch({
        type: Actions.BROWSER_IS_MOBILE,
        payload: event.mobileMode,
      });
    }
    if (event.source === MobileModeChangeSource.User) {
      dispatch({
        type: Actions.USER_IS_MOBILE,
        payload: event.mobileMode,
      });
    }
  }, [dispatch]);

  return {
    isMobile: isMobile ?? initialIsMobile,
    onMobileModeChange,
  };
}

export default useDefaultStateManager;
