import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export interface SurfaceCssProps {
  slideState: TransitionStatus;
  withCloseButton?: boolean;
}

/**
 * This is the surface of the ContextualDrawer into which all of the ContextualDrawer
 * content is rendered.
 */
const DrawerSurface = styled('div')<SurfaceCssProps>`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.25);
  box-sizing: border-box;
  word-wrap: break-word;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  padding: ${({ theme, withCloseButton }) => (withCloseButton
    ? `${theme.spacing.mini} ${theme.spacing.base} ${theme.spacing.small} ${theme.spacing.base}`
    : `${theme.spacing.small} ${theme.spacing.base}`)};
  position: fixed;
  max-width: 100vw;

  @media (prefers-reduced-motion:no-preference) {
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  }

  opacity: ${({ slideState }) => ({
    entering: 0,
    entered: 1,
    exiting: 0,
    exited: 0,
    unmounted: 0,
  }[slideState] ?? 0)};


  transform: ${({ slideState }) => ({
    entering: 'translateY(100%)',
    entered: 'translateY(0%)',
    exiting: 'translateY(100%)',
    exited: 'translateY(0%)',
    unmounted: 'none',
  }[slideState])};

  z-index: ${({ theme }) => theme.modal.zIndex};
`;
DrawerSurface.displayName = 'Surface';

export default DrawerSurface;
