/**
 * TODO: This is a copy of the one in storm-core. Deduplicate this when we have a separate package
 * for storm utility functions.
 */

/**
 * This utility borrows heavily from https://code.amazon.com/packages/MeridianComponents/blobs/4.x/--/src/utils/is-element-of.js
 * Credit goes to Elliot Dickison<elliotdi@amazon.com>
 */

/**
 * Determines if two components are the same. Uses some tricks to smooth out
 * wrinkles with react-hot-loader in development mode
 *
 * console.log(isSameComponent(Checkbox, Checkbox)) // true
 * console.log(isSameComponent(Checkbox, Radio)) // false
 */
export interface Component {
  name?: string;
  displayName?: string;
  WrappedComponent?: Component;
}

const isObjectOrFunction = (
  objOrFunc: unknown,
): objOrFunc is Record<string, unknown> => typeof objOrFunc === 'object' || typeof objOrFunc === 'function';

function hasMember<T extends string>(
  type: T,
  obj: unknown,
): obj is Record<T, unknown> {
  return isObjectOrFunction(obj) && type in obj;
}

const isSameComponent = (
  alpha: unknown,
  beta: unknown,
): boolean => {
  if (process.env.NODE_ENV !== 'production' && hasMember('name', alpha) && hasMember('name', beta) && (alpha.name || beta.name)) {
    // If a name is set then rely on that in development mode. The component
    // name is more reliable than the type in development mode because of a
    // limitation of react-hot-loader (see:
    // https://github.com/gaearon/react-hot-loader/issues/304). Don't use this
    // in production though because minification will change the name and break
    // the comparison.

    const aName = hasMember('displayName', alpha) ? alpha.displayName : alpha.name;
    const bName = hasMember('displayName', beta) ? beta.displayName : beta.name;
    return aName === bName;
  }
  // Just check if the components reference the same class/function.
  return alpha === beta;
};
export interface Element {
  type?: Component;
}

/**
 * Determines if an element is an instance of a particular component.
 *
 * const isCheckbox = isElementOf(Checkbox)
 * console.log(isCheckbox(<Checkbox />)) // true
 */
const isElementOf = (component: Component | undefined) => (
  element: unknown,
): boolean => {
  if (!component || !element || typeof element === 'string') {
    // Bail if either the component or element wasn't provided
    return false;
  }

  if (hasMember('type', element)) {
    if (hasMember('WrappedComponent', element.type) && element.type.WrappedComponent) {
      // If the element's component has a static WrappedComponent property, assume
      // we're working with a HoC and compare the wrapped component instead of the
      // HoC component. The connect HoC from redux uses this pattern, so this
      // check ensures that isElementOf works correctly on redux-connected
      // components.

      return (
        isSameComponent(element.type.WrappedComponent, component)
          || isSameComponent(element.type.WrappedComponent, component.WrappedComponent)
      );
    }
    // If WrappedComponent isn't set do a normal comparison of element.type
    // (the component that element was instantiated from) and component.
    return isSameComponent((element.type as Component), component);
  }
  return false;
};

export default isElementOf;
