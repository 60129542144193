import React, {
  useContext,
  createContext,
  ChangeEvent,
  ReactNode,
} from 'react'
import type { TileDirection, TileInputValue } from './types';

export type TileGroupContextProps<T> = {
  direction?: TileDirection;
  disabled?: boolean;
  multiple?: boolean;
  name?: string;
  onSelect?: (
    selected: T,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  selectedValues?: T[];
}

export const defaultContextValue = {
  direction: undefined,
  disabled: undefined,
  multiple: undefined,
  name: undefined,
  onSelect: undefined,
  selectedValues: undefined,
};

const TileGroupContext = createContext<TileGroupContextProps<any>>(defaultContextValue);

export interface TileGroupContextProviderProps<T> {
  direction: TileDirection;
  disabled: boolean;
  multiple: boolean;
  name: string;
  onSelect: (
    selected: T,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  selectedValues: T[];
  children: ReactNode;
}

export function TileGroupContextProvider<T extends TileInputValue>({
  direction,
  disabled,
  multiple,
  name,
  onSelect,
  selectedValues,
  children,
}: TileGroupContextProviderProps<T>) {
  return (
    <TileGroupContext.Provider
      value={{
        direction,
        disabled,
        multiple,
        name,
        onSelect,
        selectedValues,
      }}
    >
      {children}
    </TileGroupContext.Provider>
  );
}

export const useTileGroupContext = () => {
  const {
    direction,
    disabled,
    multiple,
    name,
    onSelect,
    selectedValues,
  } = useContext(TileGroupContext);

  return {
    direction,
    disabled,
    multiple,
    name,
    onSelect,
    selectedValues,
  };
}
