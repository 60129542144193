import React, { ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react';
import type { TaktProps } from '../types/TaktProps';
import { BoxProps } from '../Box/Box';
import type { FlexContainerProps, FlexItemProps } from '../types/CssProps';
import { FlexBox } from './Flex.styles';

export interface BaseFlexProps extends FlexContainerProps, FlexItemProps {}

export type FlexProps<C extends ElementType> = PropsWithChildren<BaseFlexProps>
  & Omit<ComponentPropsWithoutRef<C>, keyof BaseFlexProps> & TaktProps & BoxProps<C>;

const Flex = <T extends ElementType = 'div'>({
  children,
  alignContent = 'normal',
  alignItems = 'flex-start',
  basis,
  columnGap = 'none',
  direction = 'row',
  shrink,
  order,
  grow,
  justifyContent = 'flex-start',
  paddingBlockEnd = 'none',
  paddingBlockStart = 'none',
  paddingInlineEnd = 'none',
  paddingInlineStart = 'none',
  rowGap = 'none',
  wrap = 'nowrap',
  ...rest
}: FlexProps<T>) => (
  <FlexBox
    {...rest}
    $basis={basis}
    $direction={direction}
    $alignContent={alignContent}
    $justifyContent={justifyContent}
    $alignItems={alignItems}
    $columnGap={columnGap}
    $grow={grow}
    $order={order}
    $rowGap={rowGap}
    $shrink={shrink}
    $wrap={wrap}
    paddingBlockEnd={paddingBlockEnd}
    paddingBlockStart={paddingBlockStart}
    paddingInlineEnd={paddingInlineEnd}
    paddingInlineStart={paddingInlineStart}
  >
    {children}
  </FlexBox>
  );

export default Flex;
