import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import type { Theme } from '../../theme';
import type { Theme as MobileTheme } from '../../theme/themes/mobile';
import focusPseudoElementsAfter from '../../FocusIndicator/styleMixins/focusPseudoElementsAfter';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { colorMixin } from '../Button';

interface BaseStylesProps {
  theme: Theme;
  $primary?: boolean;
  $small?: boolean;
}

interface MobileBaseStylesProps {
  theme: MobileTheme;
  $primary?: boolean;
  $small?: boolean;
}

const selectButtonStyleTheme = (key: string) => ({ theme, $primary }: BaseStylesProps): string => {
  const buttonType = theme.button[$primary ? 'primary' : 'default'];
  return buttonType[key as keyof typeof buttonType];
};

const baseStyles = css<BaseStylesProps>`
  white-space: nowrap;
  color: ${selectButtonStyleTheme('color')};
  cursor: ${({ theme }) => theme.button.cursor};
  text-align: center;
  vertical-align: middle;
  border-width: ${({ theme }) => theme.button.borderWidth};
  border-style: solid;
  padding: 0 ${({ $small }) => ($small === true ? '8px' : '10px')} ;
  user-select: auto;
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,background-color 100ms ease,border-color 100ms ease, box-shadow 100ms ease;
  }
`;

const determineStyles = css`
  background: ${selectButtonStyleTheme('bg')};
  border-color: ${selectButtonStyleTheme('borderColor')};

  :hover {
    background: ${selectButtonStyleTheme('bgHover')};
  }

  :active {
    border-color: ${selectButtonStyleTheme('borderColorActive')};
    background: ${selectButtonStyleTheme('bgActive')};
  }

  :disabled {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    color: ${selectButtonStyleTheme('colorDisabled')};
    background: ${selectButtonStyleTheme('bgDisabled')};
    border-color: ${selectButtonStyleTheme('borderColorDisabled')};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
  }
`;

const selectButtonSizeTheme = (key: string) => ({ theme, $small }: BaseStylesProps): string => {
  const buttonType = theme.button[$small ? 'small' : 'normal'];
  return buttonType[key as keyof typeof buttonType];
};

const selectButtonSizeMobileTheme = (key: string) => ({ theme, $small }: MobileBaseStylesProps): string => {
  const buttonType = theme.button[$small ? 'small' : 'normal'].mobile;
  return buttonType[key as keyof typeof buttonType];
};

const determineSizeStyles = css`
  padding-top: ${selectButtonSizeTheme('paddingV')};
  padding-bottom: ${selectButtonSizeTheme('paddingV')};
  padding-inline-start: ${selectButtonSizeTheme('paddingH')};
  padding-inline-end: ${selectButtonSizeTheme('paddingH')};
  font-size: ${selectButtonSizeTheme('fontSize')};
  ${isMobile(css`
    padding-top: ${selectButtonSizeMobileTheme('paddingV')};
    padding-bottom: ${selectButtonSizeMobileTheme('paddingV')};
    padding-inline-start: ${selectButtonSizeMobileTheme('paddingH')};
    padding-inline-end: ${selectButtonSizeMobileTheme('paddingH')};
    font-size: ${selectButtonSizeMobileTheme('fontSize')};
  `)}
`;

const defaultButtonStyling = css<BaseStylesProps>`
  position: relative;
  ${({ theme }) => theme.typography.base};
  ${baseStyles}
  ${determineStyles}
  ${determineSizeStyles}
`;

const selectRadius = ({ theme }:{ theme: Theme }): string => theme.button.borderRadius;
const selectInnerRadius = ({ theme }:{ theme: Theme }): string => theme.splitButton.innerBorderRadius;

const removeInlineStartBorder = css`
    /*! @noflip */ border-radius: ${selectRadius} ${selectInnerRadius} ${selectInnerRadius} ${selectRadius};
    ::after{
      /*! @noflip */ border-radius: ${selectRadius} ${selectInnerRadius} ${selectInnerRadius} ${selectRadius};
    }

  /*! @noflip */
  [dir="rtl"] & {
    /*! @noflip */ border-radius: ${selectInnerRadius} ${selectRadius} ${selectRadius} ${selectInnerRadius};
    ::after {
      /*! @noflip */ border-radius: ${selectInnerRadius} ${selectRadius} ${selectRadius} ${selectInnerRadius};
    }
  }
`;

const removeInlineEndBorder = css`
  /*! @noflip */ border-radius: ${selectInnerRadius} ${selectRadius} ${selectRadius} ${selectInnerRadius};
  ::after {
    /*! @noflip */ border-radius: ${selectInnerRadius} ${selectRadius} ${selectRadius} ${selectInnerRadius};
  }

  /*! @noflip */
  [dir="rtl"] & {
    /*! @noflip */ border-radius: ${selectRadius} ${selectInnerRadius} ${selectInnerRadius} ${selectRadius};
    ::after{
      /*! @noflip */ border-radius: ${selectRadius} ${selectInnerRadius} ${selectInnerRadius} ${selectRadius};
    }
  }
`;

const loadingStyles = css`
  &&[aria-disabled="true"] {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    ${colorMixin(selectButtonStyleTheme('colorDisabled'))}
    background: ${selectButtonStyleTheme('bgDisabled')};
    border-color: ${selectButtonStyleTheme('borderColorDisabled')};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
  }
`;

interface StyledMainButtonProps extends MergeElementProps<'button'> {
  theme: Theme;
  $small?: boolean;
  $fullWidth?: boolean;
  $primary?: boolean;
  $loading?: boolean;
}

export const StyledMainButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called. blah
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})<StyledMainButtonProps>`
  /* Override Safari User Agent Styles for <button /> */
  margin: 0;
  margin-inline-end: 2px;

  ${defaultButtonStyling};
  ${removeInlineStartBorder};

  ${({ $loading }) => ($loading && loadingStyles)};

  ${({ $fullWidth }) => $fullWidth && css`
    flex-grow: 1;
  `};

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusPseudoElementsAfter)}
  }
`;
StyledMainButton.displayName = 'StyledMainButton';

interface StyledDropdownButtonProps extends MergeElementProps<'button'> {
  $small?: boolean;
  $fullWidth?: boolean;
  $primary?: boolean;
}

export const StyledDropdownButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})<StyledDropdownButtonProps>`
  ${defaultButtonStyling}
  ${removeInlineEndBorder}

  color: ${({ $primary, theme }) => theme.splitButton[$primary ? 'primary' : 'default'].dropdown.color};

  :hover {
    color: ${({ $primary, theme }) => theme.splitButton[$primary ? 'primary' : 'default'].dropdown.hoverColor};
  }

  :disabled {
    color: ${({ $primary, theme }) => theme.splitButton[$primary ? 'primary' : 'default'].dropdown.disabledColor};
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusPseudoElementsAfter)}
  }

  /* Override Safari User Agent Styles for <button /> */
  margin: 0;
  padding-inline-start: 10px;
  padding-inline-end: 11px;

  ${({ $small }) => $small && css`
    padding-inline-start: 8px;
    padding-inline-end: 9px;
  `}

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
StyledDropdownButton.displayName = 'StyledDropdownButton';

/*
 * It is less complicated to collapse borders between elements with negative margin than
 * controlling borders within the child elements.
 */
const collapseInlineChildBorders = css`
  > *:not(:first-child) {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }
`;

export const ButtonGroupFrame = styled('div')`
  display: inline-flex;
  ${collapseInlineChildBorders}
`;

export const SplitButtonWrapper = styled('div')`
  display: flex;
`;

export const HiddenLabelText = styled('div')`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;
