/* eslint-disable id-length */
import { shadow } from '../base/shadow';
import { palette } from '../base/color';
import { spacingValues, mobileSpacingValues } from '../base/spacing';
import { fontSizeValues, typography, mobileFontSizeValues } from '../base/typography';
import { border } from '../base/border';

export const button = {
  boxShadowFocus: shadow.focus,
  // TODO: Remove this at some point. Color is different per type now.
  color: typography.body.bodyFontColor,
  cursor: 'pointer',
  borderWidth: border.roundThin.width,
  borderRadius: border.roundThin.radius,
  normal: {
    fontSize: `${fontSizeValues.base}px`,
    padding: `${Math.round(spacingValues.small / 2)}px`, // Storm-2.x
    paddingH: `${spacingValues.base}px`,
    paddingV: `${Math.round(spacingValues.small / 2)}px`,
  },
  small: {
    fontSize: `${fontSizeValues.mini}px`,
    padding: `${Math.round(spacingValues.mini / 2)}px`, // Storm-2.x
    paddingH: '11px',
    paddingV: `${Math.round(spacingValues.mini / 2)}px`,
  },
  default: {
    color: palette.squidInk,
    borderColor: 'transparent',
    borderColorActive: 'transparent',
    borderColorDisabled: 'transparent',
    bg: palette.gray[150],
    bgHover: palette.gray[200],
    bgActive: palette.gray[300],
    bgDisabled: palette.gray[100],
    colorDisabled: palette.gray[700],
    boxShadow: '0 1px 1px rgb(35 47 63 / 0.3)',
    boxShadowHover: '0 1px 2px rgb(35 47 63 / 0.5)',
    boxShadowActive: '0 1px 2px rgb(35 47 63 / 0.7)',
    boxShadowDisabled: 'none',
  },
  primary: {
    color: palette.white,
    borderColor: 'transparent',
    borderColorActive: 'transparent',
    borderColorDisabled: 'transparent',
    bg: palette.blue[700],
    bgHover: palette.blue[800],
    bgActive: palette.blue[900],
    bgDisabled: palette.gray[100],
    colorDisabled: palette.gray[700],
    boxShadow: '0 1px 1px rgb(35 47 63 / 0.3)',
    boxShadowHover: '0 1px 2px rgb(35 47 63 / 0.4)',
    boxShadowActive: '0 1px 2px rgb(35 47 63 / 0.8)',
    boxShadowDisabled: 'none',
  },
  toggle: {
    color: typography.color.primary,
    borderColor: palette.gray[300],
    borderColorActive: palette.blue[700],
    bg: palette.white,
    bgHover: palette.gray[50],
    bgActive: palette.blue[50],
    borderRadius: border.round.radius,
  },
  toggleArea: {
    color: typography.color.primary,
    borderColor: palette.gray[300],
    borderColorActive: palette.blue[700],
    bg: palette.white,
    bgHover: palette.gray[50],
    bgActive: palette.blue[50],
    borderRadius: border.roundedAreaThin.radius,
    padding: '14px 18px',
  },
  group: {
    bgActive: palette.gray[700],
    colorActive: palette.white,
    boxShadowActive: 'none',
  },
  close: {
    color: palette.gray[700],
    colorHover: palette.squidInk,
  },
  transparent: {
    bg: 'transparent',
    bgHover: palette.gray[150],
    bgActive: palette.gray[300],
    borderColor: 'transparent',
    borderColorActive: 'transparent',
    borderColorDisabled: 'transparent',
    boxShadow: 'none',
    iconColor: palette.squidInk,
    iconColorDefault: palette.gray[700],
    iconColorDisabled: palette.gray[500],
    color: palette.squidInk,
    colorDisabled: palette.gray[500],
  },
};

export const mobileButton = {
  normal: {
    mobile: {
      fontSize: `${mobileFontSizeValues.base}px`,
      padding: `${mobileSpacingValues.mini}px`, // Storm-2.x
      paddingH: '16px',
      paddingV: `${mobileSpacingValues.mini}px`,
    },
  },
  small: {
    mobile: {
      fontSize: `${mobileFontSizeValues.small}px`,
      padding: `${Math.round(mobileSpacingValues.small / 2)}px`, // Storm-2.x
      paddingH: `${mobileSpacingValues.small}px`,
      paddingV: `${Math.round(mobileSpacingValues.small / 2)}px`,
    },
  },
};
