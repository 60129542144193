/* eslint-disable quote-props */
import { palette } from '../base/color';
import { border } from '../base/border';

const progressIndicatorStyles = {
  bgDefault: palette.gray[200],
  borderRadius: border.round.radius,
  heightMini: '6px',
  heightSmall: '10px',
  color: palette.blue[700],
  colors: {
    default: {
      '100': palette.blue[700],
      '200': palette.blue[400],
      '300': palette.blue[300],
    },
    error: {
      '100': palette.red[700],
      '200': palette.red[400],
      '300': palette.red[300],
    },
    warning: {
      '100': palette.clementine[700],
      '200': palette.clementine[400],
      '300': palette.clementine[300],
    },
  },
  colorError: palette.red[700],
  colorWarning: palette.clementine[700],
  tickBg: palette.gray[700],
  tickHeightMini: '10px',
  tickHeightSmall: '14px',
};

export default progressIndicatorStyles;
