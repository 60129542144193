import React, {
  Component,
  ChangeEvent,
  createRef,
  KeyboardEvent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import Fuse, { FuseOptions } from 'fuse.js';
import styled, { css } from 'styled-components';
import {
  SearchInput, useIsMobile, isMobileStyleMixin, TaktProps,
  createStormTaktId,
  TaktIdConsumer,
} from '@amzn/storm-ui';
import { noop } from '@amzn/storm-ui-utils';

export interface WithHideLabelProps {
  hideSearchInputLabel?: boolean;
  mobileFullScreen?: boolean;
  children: (arg: {
    hideLabel: boolean;
    dropdownIsMobile: boolean;
  }) => ReactNode;
}

const WithHideLabel = ({ hideSearchInputLabel, mobileFullScreen, children }: WithHideLabelProps) => {
  const isMobile = useIsMobile();
  // packages/storm-ui/src/Dropdown/DropdownIsMobileContext.tsx
  const dropdownIsMobile = (mobileFullScreen && isMobile) || false;

  return (
    <>
      {children({
        hideLabel: typeof hideSearchInputLabel === 'boolean' ? hideSearchInputLabel : !dropdownIsMobile,
        dropdownIsMobile,
      })}
    </>
  );
}
WithHideLabel.defaultProps = {
  hideSearchInputLabel: undefined,
  mobileFullScreen: undefined,
}

const FilterWrapper = styled.div<{ noPadding: boolean | undefined; }>`
  ${({ noPadding }) => !noPadding && css`
    padding: ${({ theme }) => theme.spacing.mini};

    ${isMobileStyleMixin(css`
      padding: ${({ theme }) => theme.mobile.spacing.mini};
    `)}
  `}
`;

export interface FilterProps<T = any> {
  /**
   * Use this to provide a screen reader label for the search <input> clear button.
   */
  clearButtonLabel?: string;
  /**
   * Labels are required for accessibility but can be visually hidden using this prop.
   */
  hideSearchInputLabel?: boolean;
  /**
   * Used to render dropdown in SecondaryView on mobile device.
   */
  mobileFullScreen?: boolean;
  /**
   * The list of items to be displayed in the dropdown.
   */
  items: T[];
  /**
   * Callback that returns results of fuzzy search on each input state change.
   */
  onFilterChange?: (results: T[], filterValue: string, event: ChangeEvent) => void;
  /**
   * Callback that is called when the clear button is clicked on the filter input.
   */
  onFilterClear?: (value: string) => void;
  /**
   * Options for customizing [Fuse.js](https://fusejs.io/). Sensible defaults are already declared. Define the object properties
   * you want to search with using the `keys` option.
   */
  options?: FuseOptions<T>;
  /**
   * Placeholder text for the search <input> element.
   */
  placeholder?: string;
  /**
   * Unique id for the search <input> element.
   */
  searchInputId: string;
  /**
   * Custom label for the search <input> element.
   */
  searchInputLabel?: string;
  /**
   * Used to render dropdown button with no background.
   */
  transparentButton?: boolean;
  /**
   * By default, the text input will clear and onFilterClear will be called when opening the dropdown.
   * Enabling this prop will circumvent this behavior.
   */
  persistFilterState?: boolean;
}

export interface FilterState {
  filterValue?: string;
}

export interface InjectorFilterProps extends TaktProps{
  /**
   * Callback that is called when the filter dropdown is opened.
   */
  onOpen?: () => void;
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   */
  preRender?: () => ReactNode;
}

const withFilter = <P extends InjectorFilterProps>(
  // @ts-ignore
  WrapperComponent,
) => (
    class WithFilterComponent extends Component<P & FilterProps, FilterState> {
      static propTypes = {
      /**
       * Unique id for the search <input> element.
       */
        searchInputId: PT.string.isRequired,
        /**
       * Placeholder text for the search <input> element.
       */
        placeholder: PT.string,
        /**
       * Callback that returns results of fuzzy search on each input state change.
       */
        onFilterChange: PT.func,
        /**
       * Callback that is called when the clear button is clicked on the filter input.
       */
        onFilterClear: PT.func,
        /**
       * Callback that is called when the filter dropdown is opened.
       */
        onOpen: PT.func,
        /**
       * The list of items to be displayed in the dropdown.
       */
        items: PT.arrayOf(PT.object).isRequired,
        /**
       * Use this to provide a screen reader label for the search <input> clear button.
       */
        clearButtonLabel: PT.string,
        /**
       * Options for customizing [Fuse.js](https://fusejs.io/). Sensible defaults are already declared. Define the object properties
       * you want to search with using the `keys` option.
       */
        options: PT.shape({
          shouldSort: PT.bool,
          threshold: PT.number,
          location: PT.number,
          distance: PT.number,
          maxPatternLength: PT.number,
          minMatchCharLength: PT.number,
          keys: PT.arrayOf(PT.string),
        }),
        /**
       * Render prop for rendering components into the DOM before the filter dropdown.
       */
        preRender: PT.func,
        /**
       * Labels are required for accessibility but can be visually hidden using this prop.
       */
        hideSearchInputLabel: PT.bool,
        /**
       * Used to render dropdown in SecondaryView on mobile device.
       */
        mobileFullScreen: PT.bool,
        /**
       * Custom label for the search <input> element.
       */
        searchInputLabel: PT.string,
        /**
       * Used to render dropdown button with no background.
       */
        transparentButton: PT.bool,
        /**
         * By default, the text input will clear and onFilterClear will be called when opening the dropdown.
         * Enabling this prop will circumvent this behavior.
         */
        persistFilterState: PT.bool,
      };

      static defaultProps = {
        placeholder: '',
        onFilterChange: noop,
        onFilterClear: noop,
        onOpen: noop,
        preRender: noop,
        clearButtonLabel: 'Clear search terms',
        options: {
          shouldSort: true,
          threshold: 0.6,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [
            'label',
            'value',
          ],
        },
        hideSearchInputLabel: undefined,
        mobileFullScreen: undefined,
        searchInputLabel: undefined,
        transparentButton: false,
        persistFilterState: false,
      };

      filterRef = createRef<HTMLInputElement>();

      constructor(props: P & FilterProps) {
        super(props);

        this.state = {
          filterValue: '',
        };
      }

      handleOpen = () => {
        const { onFilterClear, onOpen, persistFilterState } = this.props;

        if (!persistFilterState) {
          this.setState({ filterValue: '' });

          if (onFilterClear) {
            onFilterClear('');
          }
        }

        if (onOpen) {
          onOpen();
        }

        window.requestAnimationFrame(() => {
          this.filterRef?.current?.focus();
        });
      }

      handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
          items = [],
          options,
          onFilterChange,
        } = this.props;

        const filterValue = event.target.value;
        const mergedOptions = {
          ...WithFilterComponent.defaultProps.options,
          ...options,
        };

        const fuse = new Fuse(items, mergedOptions);
        const results = filterValue ? fuse.search(filterValue) : items;

        this.setState({ filterValue });

        if (onFilterChange) {
          onFilterChange(results, filterValue, event);
        }
      }

      handleFilterClear = () => {
        const { onFilterClear } = this.props;

        this.setState({ filterValue: '' });

        if (onFilterClear) {
          onFilterClear('');
        }

        if (this.filterRef) {
          this.filterRef?.current?.focus();
        }
      }

      handleFilterKeyDown = (event: KeyboardEvent) => {
        const { key } = event;
        if (key === ' ' || key === 'Enter') {
          event.stopPropagation();
        }
      }

      renderFilter = () => {
        const {
          searchInputId,
          clearButtonLabel,
          hideSearchInputLabel,
          placeholder,
          preRender,
          searchInputLabel,
          mobileFullScreen,
        } = this.props;
        return (
          <TaktIdConsumer fallbackId={createStormTaktId('with-filter')}>
            {() => (
              <WithHideLabel hideSearchInputLabel={hideSearchInputLabel} mobileFullScreen={mobileFullScreen}>
                {({ hideLabel, dropdownIsMobile }) => (
                  <>
                    {preRender && preRender()}
                    <FilterWrapper noPadding={dropdownIsMobile}>
                      <SearchInput
                        id={searchInputId}
                        placeholder={placeholder}
                        fullWidth
                        inputRef={this.filterRef}
                        value={this.state.filterValue}
                        onClear={this.handleFilterClear}
                        onChange={this.handleFilterChange}
                        clearButtonLabel={clearButtonLabel}
                        onKeyDown={this.handleFilterKeyDown}
                        hideLabel={hideLabel}
                        label={searchInputLabel}
                      />
                    </FilterWrapper>
                  </>
                )}
              </WithHideLabel>
            )}
          </TaktIdConsumer>

        );
      }

      render() {
        const {
          searchInputId,
          clearButtonLabel,
          onFilterChange,
          onFilterClear,
          items,
          options,
          placeholder,
          hideSearchInputLabel,
          searchInputLabel,
          ...rest
        } = this.props;

        return (
          <WrapperComponent
            {...rest as P}
            preRender={this.renderFilter}
            onOpen={this.handleOpen}
          />
        );
      }
    }
  );

export default withFilter;
