import styled from 'styled-components';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import arrowStyleMixin, { ArrowCssProps } from './mixins/arrow';
import surfaceStyleMixin from './mixins/surface';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import PortalComponent from '../Portal/PortalComponent';

export const PopperArrow = styled('div')<ArrowCssProps>`
  ${arrowStyleMixin}
`;
PopperArrow.displayName = 'PopperArrow';

export const PopperTrigger = styled.span`
  display: inline-block;
  width: fit-content;
`;
PopperTrigger.displayName = 'PopperTrigger';

type styleType = 'blue' | 'dark' | 'light';

export const TRANSITION_CLASSNAME_PREFIX = 'popper-surface-transition';

export const PopperSurface = styled('div')<{
  transitionState: TransitionStatus;
  $transitionDuration: number;
  focusVisible?: boolean;
  styleType: styleType;
} & React.HTMLAttributes<HTMLDivElement>>`
  ${surfaceStyleMixin}

  :focus {
    ${focusOutline}
  }

  :focus-visible {
    ${focusOutline}
  }

  @media (prefers-reduced-motion:no-preference) {
    transition: opacity ${({ $transitionDuration }) => ($transitionDuration)}ms ease-in-out;
  }
  opacity: 0;
  &.${TRANSITION_CLASSNAME_PREFIX}-appear {
    opacity: 0;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-appear-active {
    opacity: 1;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-appear-done {
    opacity: 1;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-enter {
    opacity: 1;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-enter-active {
    opacity: 1;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-enter-done {
    opacity: 1;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-exit {
    opacity: 0;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-exit-active {
    opacity: 0;
  }
  &.${TRANSITION_CLASSNAME_PREFIX}-exit-done {
    opacity: 0;
  }

  ${/* only apply `width: max-content;` to Poppers that are children of Poppers */
  PortalComponent} > && {
    width: max-content;
  }
`;

PopperSurface.displayName = 'PopperSurface';
