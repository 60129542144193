/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Ref, PureComponent } from 'react';
import PT, { Validator } from 'prop-types';
import styled, {
  css,
  InterpolationFunction,
  StyledComponent,
  ThemedStyledProps,
} from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { Theme } from '../theme/index';
import { Theme as MobileThemeType } from '../theme/themes/mobile';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

type TextComponentInterpolationFunction
  = InterpolationFunction<ThemedStyledProps<TextComponentProps, Theme>>;

type MobileTextComponentInterpolationFunction
  = InterpolationFunction<ThemedStyledProps<TextComponentProps, MobileThemeType>>;

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

const fontSizeRuleMixin = css`
  font-size: ${({ theme, fontSize }: any) => theme.typography.size[fontSize]};

  ${isMobile(css`
    font-size: ${({ theme, fontSize }: any) => theme.typography.mobile.size[fontSize]};
  `)}
`;

const colorRule: TextComponentInterpolationFunction = ({ theme, textColor }) => {
  if (!textColor) return 'color: inherit;';
  return `color: ${theme.typography.color[textColor] || 'inherit'};`;
};

const inlineRule: TextComponentInterpolationFunction = ({ inline }) => inline && 'display:inline;';

const withMarginRule = (marginId: keyof Theme['typography']): TextComponentInterpolationFunction => (
  ({ theme: { typography }, withMargin }) => `margin: ${(withMargin ? typography[marginId] : 0)};`
);

const styleAsRule = (defaultStyleId: keyof Theme['typography']): TextComponentInterpolationFunction => (
  ({ styleAs, theme }) => (theme.typography[styleAs || defaultStyleId])
);

const styleAsMobileRule = (defaultStyleId: keyof MobileThemeType['typography']['mobile']): MobileTextComponentInterpolationFunction => (
  ({ styleAs, theme }) => (theme.typography.mobile[styleAs || defaultStyleId])
);

const CodeStyleRule = css`
  ${({ theme }) => theme.typography.code};
  color: ${({ theme }) => theme.typography.color.base};
  font-family: monospace;
  padding: 0 4px;
  border-radius: 3px;
`;

const StyledH1 = styled.h1`
  ${styleAsRule('h1')}
  ${isMobile(css`
    ${styleAsMobileRule('h1')}
  `)}
  ${colorRule}
  ${withMarginRule('h1Margin')}
`;
StyledH1.displayName = 'StyledH1';

const StyledH2 = styled.h2`
  ${styleAsRule('h2')}
  ${isMobile(css`
    ${styleAsMobileRule('h2')}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('h2Margin')}
`;
StyledH2.displayName = 'StyledH2';

const StyledH3 = styled.h3`
  ${styleAsRule('h3')}
  ${isMobile(css`
    ${styleAsMobileRule('h3')}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('h3Margin')}
`;
StyledH3.displayName = 'StyledH3';

const StyledH4 = styled.h4`
  ${styleAsRule('h4')}
  ${isMobile(css`
    ${styleAsMobileRule('h4')}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('h4Margin')}
`;
StyledH4.displayName = 'StyledH4';

const StyledH5 = styled.h5`
  ${styleAsRule('h5')}
  ${isMobile(css`
    ${styleAsMobileRule('h5')}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('h5Margin')}
`;
StyledH5.displayName = 'StyledH5';

const StyledH6 = styled.h6`
  ${styleAsRule('h6')}
  ${isMobile(css`
    ${styleAsMobileRule('h6')}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('h6Margin')}
`;
StyledH6.displayName = 'StyledH6';

const StyledLead = styled.p`
  ${({ theme }) => theme.typography.lead}
  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.lead}
  `)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('leadMargin')}
  :last-child {
    ${withMarginRule('baseMargin')}
  }
`;
StyledLead.displayName = 'StyledLead';

const StyledP = styled.p`
  ${({ theme }) => theme.typography.base}
  ${isMobile(css`${({ theme }) => theme.typography.mobile.base}`)}
  ${inlineRule}
  ${colorRule}
  ${withMarginRule('baseMargin')}
  a {
    text-decoration: underline;
  }
  :last-child {
    ${withMarginRule('baseMargin')}
  }
`;
StyledP.displayName = 'StyledP';

const StyledSpan = styled.span`
  ${({ theme }) => theme.typography.base}
  ${isMobile(css`${({ theme }) => theme.typography.mobile.base}`)}
  display:inline;
  ${colorRule}
`;
StyledSpan.displayName = 'StyledSpan';

const StyledSup = styled.sup`
  ${({ theme }) => theme.typography.super}
  ${withMarginRule('baseMargin')}
  color: ${({ theme }) => theme.palette.rust};
  :last-child {
    ${withMarginRule('baseMargin')}
  }
`;
StyledSup.displayName = 'StyledSup';

const StyledScreenReaderOnly = styled.span`
  ${({ theme }) => theme.typography.screenReaderOnly}
`;

StyledScreenReaderOnly.displayName = 'StyledScreenReaderOnly';

/**
 * The `pre` tag is cast to a `span` to avoid prop collisions
 */
const StyledPre = styled.pre`
  ${styleAsRule('code')}
  ${isMobile(css`
    ${styleAsMobileRule('code')}
  `)}
  ${CodeStyleRule}
` as StyledComponent<'span', Record<string, unknown>>;
StyledPre.displayName = 'StyledPre';

const StyledCode = styled.code`
  ${styleAsRule('code')}
  ${isMobile(css`
    ${styleAsMobileRule('code')}
  `)}
  ${CodeStyleRule}
`;
StyledCode.displayName = 'StyledCode';

export interface TextComponentOverrideProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'lead' | 'sup' | 'span' | 'sr-only' | 'pre' | 'code';
}

export interface TextComponentProps extends MergeElementProps<'p', TextComponentOverrideProps >{
  /**
   * Adds the "User Agent" margins. Useful when doing standard article style text placement.
   * @defaultValue `false`
   */
  withMargin?: boolean;
  /**
   * Is one of h1, h2, h3, h4, h5, h6. This allows you to use the correct semantic tag,
   * but style it as something different (e.g, if something is techically an <h2>,
   * but you want it to look like an <h6>).
   * @defaultValue `undefined`
   */
  styleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * @defaultValue `false`
   */
  inline?: boolean;
  /**
   * Pre-defined text color styles. Is one of primary, secondary, or tertiary.
   * @defaultValue `null`
   */
  textColor?: 'base' | 'secondary' | 'tertiary' | null;
  /**
   * The size of the text element.
   * @defaultValue `undefined`
   */
  fontSize?: 'extraLarge' | 'large' | 'medium' | 'base' | 'small' | 'mini' | 'tiny';
  className?: string;
  /**
   * A React Ref that contains the underlying HTMLParagraphElement this component will render.
   * @defaultValue `undefined`
   */
  elementRef?: Ref<HTMLElement>;
  /**
   * Is one of h1, h2, h3, h4, h5, h6, lead, p, span
   * @defaultValue 'p'
   */
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'lead' | 'sup' | 'span' | 'sr-only' | 'pre' | 'code';
}

export class TextComponent extends PureComponent<TextComponentProps> {
  static propTypes = {
    type: PT.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'lead', 'sup', 'span', 'sr-only', 'pre', 'code']),
    withMargin: PT.bool,
    styleAs: PT.string,
    inline: PT.bool,
    textColor: PT.oneOf(['base', 'secondary', 'tertiary']),
    fontSize: PT.oneOf([
      'extraLarge',
      'large',
      'medium',
      'base',
      'small',
      'mini',
      'tiny',
    ]),
    className: PT.string.isRequired,
    elementRef: PT.shape(
      { current: PT.instanceOf(NodeSafeHTMLElement) },
    ) as Validator<Ref<HTMLElement>>,
  }

  static defaultProps = {
    type: 'p',
    withMargin: false,
    styleAs: undefined,
    inline: false,
    textColor: null,
    fontSize: undefined,
    elementRef: undefined,
  }

  render(): JSX.Element {
    const {
      type,
      fontSize,
      elementRef,
      ...rest
    } = this.props;

    switch (type) {
      case 'h1': return <StyledH1 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'h2': return <StyledH2 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'h3': return <StyledH3 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'h4': return <StyledH4 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'h5': return <StyledH5 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'h6': return <StyledH6 {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'lead': return <StyledLead {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'sup': return <StyledSup {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'span': return <StyledSpan {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      case 'sr-only': return <StyledScreenReaderOnly {...rest} ref={elementRef as Ref<HTMLSpanElement>} />;
      case 'pre': return <StyledPre {...rest} ref={elementRef as Ref<HTMLSpanElement>} />;
      case 'code': return <StyledCode {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
      default: return <StyledP {...rest} ref={elementRef as Ref<HTMLParagraphElement>} />;
    }
  }
}

const Text = styled(TextComponent)`
  ${fontSizeRuleMixin}
`;
Text.displayName = 'Text';
Text.propTypes = {
  /**
   * Is one of h1, h2, h3, h4, h5, h6, lead, p, span
   * @defaultValue 'p'
   */
  type: PT.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'lead', 'sup', 'span', 'sr-only', 'pre', 'code']),
  /**
   * Adds the "User Agent" margins. Useful when doing standard article style text placement.
   * @defaultValue false
   */
  withMargin: PT.bool,
  /**
   * Is one of h1, h2, h3, h4, h5, h6. This allows you to use the correct semantic tag,
   * but style it as something different (e.g, if something is techically an <h2>,
   * but you want it to look like an <h6>).
   * @defaultValue undefined
   */
  styleAs: PT.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /**
   * @defaultValue false
   */
  inline: PT.bool,
  /**
   * Pre-defined text color styles. Is one of primary, secondary, or tertiary.
   * @defaultValue null
   */
  textColor: PT.oneOf(['base', 'secondary', 'tertiary']),
  /**
   * The size of the text element.
   * @defaultValue undefined
   */
  fontSize: PT.oneOf([
    'extraLarge',
    'large',
    'medium',
    'base',
    'small',
    'mini',
    'tiny',
  ]),
  className: PT.string,
  /**
  * A React Ref that contains the underlying HTMLParagraphElement this component will render.
  * @defaultValue undefined
  */
  elementRef: PT.shape(
    { current: PT.instanceOf(NodeSafeHTMLElement) },
  ) as Validator<Ref<HTMLElement>>,
};

export interface TextProps extends TextComponentProps {}

export default Text;
