import {
  FC, useEffect, ReactElement, RefObject,
} from 'react';
import { getScrollParent } from '@react-aria/utils';
import { useHandlerRef } from '@amzn/storm-ui-utils-v3';
import getElement from './getElement';

export interface OnTargetMoveProps {
  /**
   * The instance of the target element that may be a child of.
   */
   targetEl?: RefObject<Element> | Element | null;
  /**
   *The event handler that is called when a `scroll` occurs in the window
   */
  handler?: (event: Event)=>void;
  /**
  * Child React elements. ( This component in a "passthrough" and does not effect children or
  * render ant DOM elements )
  */
  children: ReactElement;
}

/**
 * OnTargetMove attaches a scroll event listener to both the window and the
 * first ancestor if the element that scrolls.
 *
 * The OnTargetMove component ensures that `scroll` event listener are
 * cleaned-up when it ( or its ancestor ) is un-mounted. This us useful to prevent leaving
 * unused listener attached after a un-mounted when the parent's lifecycle control's child
 * mount states
 */
const OnTargetMove:FC<React.PropsWithChildren<OnTargetMoveProps>> = ({
  targetEl,
  children,
  handler: handlerArg,
}) => {
  const handlerArgRef = useHandlerRef(handlerArg);

  useEffect(() => {
    /**
     * get references to the target and first scrolling parent element
     */
    const target = getElement(targetEl);
    const scrollParent: (Element | null | undefined) = target && getScrollParent(target);

    function handler(event:Event) {
      if (typeof handlerArgRef.current === 'function') {
        handlerArgRef.current(event);
      }
    }

    /**
     * listen for window scrolling
     */
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handler);
    }

    /**
     * if the scrollParent is not the top `<html/>`, listen for element scrolling
     */
    if (scrollParent && typeof document !== 'undefined' && scrollParent !== document.documentElement) {
      scrollParent.addEventListener('scroll', handler);
    }

    /**
     * listen for visualViewport resizes or fall back to window if the
     * visualViewport API is not available.
     */
    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      window.visualViewport.addEventListener('resize', handler);
    } else if (typeof window !== 'undefined') {
      window.addEventListener('resize', handler);
    }
    return (() => {
      /**
       * clean up any of the listeners
       */
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handler);
      }
      if (scrollParent) {
        scrollParent.removeEventListener('scroll', handler);
      }
      if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handler);
      } else if (typeof window !== 'undefined') {
        window.addEventListener('resize', handler);
      }
    });
  }, [targetEl, handlerArgRef]);
  return children;
};

export default OnTargetMove;
