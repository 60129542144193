import React, { forwardRef, ComponentProps } from 'react';
import { ThemeProvider } from 'styled-components';
import { Surface as SurfaceComponentType, SurfaceType } from './types';
import Surface from './Surface';
import surfaceModifier from '../theme/colorModifiers/surface';
import { Theme } from '../theme';
import { TaktIdProvider } from '../TaktIdContext';
import { TaktProps } from '../types/TaktProps';

interface SurfaceReturnType extends TaktProps, ComponentProps<SurfaceComponentType> {}

export default function createSurface(
  surfaceType: SurfaceType,
): React.FC<React.PropsWithChildren<SurfaceReturnType>> {
  return forwardRef<HTMLDivElement, React.PropsWithChildren<SurfaceReturnType>>((props, ref) => (
    <ThemeProvider
      theme={(theme: Theme) => surfaceModifier(theme, surfaceType)}
    >
      <TaktIdProvider taktId={props.taktId} taktValue={props.taktValue}>
        <Surface {...props} ref={ref} />
      </TaktIdProvider>
    </ThemeProvider>
  ));
}
