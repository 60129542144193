import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useRef,
} from 'react';
import styled from 'styled-components';
import { getFocusableElements, keyboardKeynames as keys, MergeElementProps } from '@amzn/storm-ui-utils-v3';

export const StyledList = styled('ul')`
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;

  list-style: none;
  margin: 7px 0;
  padding: 0;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MenuListProps extends MergeElementProps<'ul'> {}

const MenuList: FC<React.PropsWithChildren<MenuListProps>> = ({
  children,
  ...rest
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLUListElement | HTMLButtonElement>) => {
    const { key } = event;

    const focusableElements = getFocusableElements(listRef.current);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const currentIndex = focusableElements.indexOf(document.activeElement as HTMLElement);
    let newFocusIndex = currentIndex;

    switch (key) {
      case keys.ARROW_DOWN:
        newFocusIndex = currentIndex + 1;
        if (document.activeElement === lastFocusableElement) {
          newFocusIndex = focusableElements.length - 1;
        }
        event.preventDefault();
        break;
      case keys.ARROW_UP:
        newFocusIndex = currentIndex - 1;
        if (document.activeElement === firstFocusableElement) {
          newFocusIndex = 0;
        }
        event.preventDefault();
        break;
      case keys.HOME:
        newFocusIndex = 0;
        event.preventDefault();
        break;
      case keys.END:
        newFocusIndex = focusableElements.length - 1;
        event.preventDefault();
        break;
    }

    focusableElements[newFocusIndex].focus();
  }, []);

  return (
    <StyledList
      ref={listRef}
      role="menu"
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </StyledList>
  );
};

export default MenuList;
