import React, { FC, MouseEvent, KeyboardEvent } from 'react';
import type { InputFormGroupProps, TaktProps } from '@amzn/storm-ui-v3';
import PT, { Validator } from 'prop-types';
import Root from './Root';
import ContextProvider from '../../contexts/ContextProvider';
import { OrientationType, ClickEventHandler, SelectInputEventHandler } from '../../types';

export interface DatePickerInputProps extends TaktProps, Omit<InputFormGroupProps, 'onChange'|'type'|'id'|'onSubmit'> {
  /**
   * The object used to localize the DatePicker.
   * @defaultValue `undefined`
   */
  locale?: Locale | (() => Promise<{ default: Locale }>);
  /**
   * Today's date.
   * @defaultValue `undefined`
   */
  today?: Date;
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * @defaultValue `undefined`
   */
  orientation?: OrientationType;
  /**
   * The number of months to display.
   * @defaultValue `undefined`
   */
  numberOfMonths?: number;
  /**
   * The first or only month displayed.
   * @defaultValue `undefined`
   */
  monthInView?: Date;
  /**
   * The earliest possible date that can be selected.
   * @defaultValue `undefined`
   */
  minDate?: Date;
  /**
   * The latest possible date that can be selected.
   * @defaultValue `undefined`
   */
  maxDate?: Date;
  /**
   * The marketplace time zone.
   * @defaultValue `undefined`
   */
  zone?: string;
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   * @defaultValue `undefined`
   */
  isOutsideRange?: ClickEventHandler;
  /**
   * The unique identifier.
   */
  id: string;
  /**
   * The aria-label attached to the DatePickerInput prefix.
   * @defaultValue `undefined`
   */
  prefixAriaLabel?: string;
  /**
   * The input value.
   * @defaultValue `undefined`
   */
  value?: string;
  /**
   * The function called when the DatePicker value is changed.
   * @defaultValue `undefined`
   */
  onChange?: SelectInputEventHandler;
  /**
   * The function called when the DatePicker input is clicked.
   * This is often the function that handles opening the DatePicker.
   * @defaultValue `undefined`
   */
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  /**
   * The function called when the DatePicker input recieves a
   * keyboard keydown event. Generally, this function would be
   * used to trap focus inside the DatePicker and listen for
   * keys that would trigger the closing of the DatePicker.
   * @defaultValue `undefined`
   */
  onKeyDown?: (event: KeyboardEvent) => void;
  /**
   * The function called when the DatePicker prefix receives a
   * keyboard keydown event. Generally, this function would be
   * used to trap focus inside the DatePicker and listen for
   * keys that would trigger the closing of the DatePicker.
   * @defaultValue `undefined`
   */
  onKeyDownPrefix?: (event: KeyboardEvent) => void;
  /**
   * The function called when the DatePicker prefix receives a keyboard keyup event.
   * @defaultValue `undefined`
   */
  onKeyUpPrefix?: (event: KeyboardEvent) => void;
}

const DatePickerInput:FC<React.PropsWithChildren<DatePickerInputProps>> = props => (
  <ContextProvider {...props}>
    <Root />
  </ContextProvider>
);

DatePickerInput.propTypes = {
  /**
   * The object used to localize the DatePicker.
   */
  locale: PT.oneOfType(
    [PT.objectOf(PT.any), PT.func],
  ) as Validator<Locale | (() => Promise<{ default: Locale }>)>, // eslint-disable-line react/forbid-prop-types
  /**
   * Today's date.
   */
  today: PT.instanceOf(Date),
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   */
  orientation: PT.oneOf(['horizontal', 'vertical']),
  /**
   * The number of months to display.
   */
  numberOfMonths: PT.number,
  /**
   * The first or only month displayed.
   */
  monthInView: PT.instanceOf(Date),
  /**
   * The earliest possible date that can be selected.
   */
  minDate: PT.instanceOf(Date),
  /**
   * The latest possible date that can be selected.
   */
  maxDate: PT.instanceOf(Date),
  /**
   * The marketplace time zone.
   */
  zone: PT.string,
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   */
  isOutsideRange: PT.func,
  /**
   * The unique identifier.
   */
  id: PT.string.isRequired,
  /**
   * The aria-label attached to the DatePickerInput prefix.
   */
  prefixAriaLabel: PT.string,
  /**
   * The input value.
   */
  value: PT.string,
  /**
   * The function called when the DatePicker value is changed.
   */
  onChange: PT.func,
  /**
   * The function called when the DatePicker input is clicked.
   * This is often the function that handles opening the DatePicker.
   */
  onClick: PT.func,
  /**
   * The function called when the DatePicker input recieves a
   * keyboard keydown event. Generally, this function would be
   * used to trap focus inside the DatePicker and listen for
   * keys that would trigger the closing of the DatePicker.
   */
  onKeyDown: PT.func,
  /**
   * The function called when the DatePicker prefix receives a
   * keyboard keydown event. Generally, this function would be
   * used to trap focus inside the DatePicker and listen for
   * keys that would trigger the closing of the DatePicker.
   */
  onKeyDownPrefix: PT.func,
  /**
   * The function called when the DatePicker prefix receives a keyboard keyup event.
   */
  onKeyUpPrefix: PT.func,
};

DatePickerInput.defaultProps = {
  locale: undefined,
  today: undefined,
  orientation: undefined,
  numberOfMonths: undefined,
  monthInView: undefined,
  minDate: undefined,
  maxDate: undefined,
  zone: undefined,
  isOutsideRange: undefined,
  prefixAriaLabel: undefined,
  value: undefined,
  onChange: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onKeyDownPrefix: undefined,
  onKeyUpPrefix: undefined,
};

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
