/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "取消"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "保存"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "自定义"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "请选择一天"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "请选择月份"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "请选择一周"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "最近 30 天"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "最近 7 天"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "上个月"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "上周"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "生命周期"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "本周"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "今天"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "年初至今"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "昨天"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
