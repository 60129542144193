import React, {
  HTMLAttributes,
  MouseEvent,
  PureComponent,
  ReactNode,
  Ref,
} from 'react';
import PT, { Validator } from 'prop-types';
import styled, { css } from 'styled-components';
import { angleInlineEnd } from '@amzn/storm-ui-icons';
import Text, { Link } from '../Text';
import Icon from '../Icon';
import { TextButton } from '../Button';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

const BreadcrumbArrow = styled(Icon)`
  padding: 0 ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.breadcrumbs.spacer};

  ${isMobile(css`
    padding: 0 ${({ theme }) => theme.mobile.spacing.small};
  `)}
`;
BreadcrumbArrow.displayName = 'BreadcrumbArrow';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.palette.emperor};
`;
StyledText.displayName = 'StyledText';

export interface BreadcrumbProps extends HTMLAttributes<HTMLElement> {
  /**
     * Use to add a link to breadcrumb
     * @defaultValue `undefined`
     */
  href?: string;
  /**
     * Text of breadcrumb item
     * @defaultValue `undefined`
     */
  label?: string;
  /**
     * Use to render a button with callback when no href exists
     * @defaultValue `undefined`
     */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
     * Controlled by BreadcrumbGroup. No need to enter manually.
     * @defaultValue `false`
     */
  isFinal?: boolean;
  /**
     * Use if you need a custom component such as a react router link
     * @defaultValue `undefined`
     */
  children?: ReactNode;
  /**
    * A React Ref that contains the underlying HTMLElement this breadcrumb will be render.
    * This may be a HTMLAnchorElement, HTMLButtonElement, or HTMLParagraphElement
    * @defaultValue `undefined`
    */
  elementRef?: Ref<HTMLElement>;
}

export default class Breadcrumb extends PureComponent<BreadcrumbProps> {
  static propTypes = {
    /**
     * Use to add a link to breadcrumb
     */
    href: PT.string,
    /**
     * Text of breadcrumb item
     */
    label: PT.string,
    /**
     * Use to render a button with callback when no href exists
     */
    onClick: PT.func,
    /**
     * Controlled by BreadcrumbGroup. No need to enter manually.
     */
    isFinal: PT.bool,
    /**
     * Use if you need a custom component such as a react router link
     */
    children: PT.node,
    /**
    * A React Ref that contains the underlying HTMLElement this breadcrumb will be render.
    * This may be a HTMLAnchorElement, HTMLButtonElement, or HTMLParagraphElement
    */
    elementRef: PT.shape(
      { current: (PT.instanceOf(NodeSafeHTMLElement)) },
    ) as Validator<Ref<HTMLElement>>,
  }

  static defaultProps = {
    href: undefined,
    label: undefined,
    onClick: undefined,
    isFinal: false,
    children: undefined,
    elementRef: undefined,
  }

  render(): JSX.Element {
    const {
      href,
      label,
      onClick,
      isFinal,
      children,
      elementRef,
      ...rest
    } = this.props;

    let component;
    if (children) {
      component = children;
    } else if (href) {
      component = (
        <Link
          {...rest}
          href={href}
          anchorRef={elementRef as Ref<HTMLAnchorElement>}
        >
          {label}
        </Link>
      );
    } else if (onClick) {
      component = (
        <TextButton
          {...rest}
          onClick={onClick}
          buttonRef={elementRef as Ref<HTMLButtonElement>}
        >
          {label}
        </TextButton>
      );
    } else {
      component = (
        <StyledText
          {...rest}
          elementRef={elementRef as Ref<HTMLParagraphElement>}
        >
          {label}
        </StyledText>
      );
    }
    return (
      <>
        {component}
        {!isFinal && <BreadcrumbArrow type={angleInlineEnd} />}
      </>
    );
  }
}
