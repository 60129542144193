import React, {
  DragEventHandler,
  DragEvent,
  FunctionComponent,
  useContext,
  useState,
  ReactNode,
  UIEvent,
} from 'react';
import PT from 'prop-types';
import { noop } from '@amzn/storm-ui-utils-v3';
import { upload } from '@amzn/storm-ui-icons-v3';
import styled, { css } from 'styled-components';
import Alert from '../../Alert/Alert'
import Icon from '../../Icon/Icon'
import Text from '../../Text';
import FileUploadContext from '../FileUploadContext';

export interface FileUploadDropZoneProps {
  /** Instructional text displayed within drop zone */
  heading?: ReactNode;
  /** ID for drop zone */
  dropzoneId: string;
  /** Callback function called when files are dropped in drop zone */
  onDrop?: DragEventHandler<HTMLDivElement>;
}

interface ContainerProps {
  activeDrag?: boolean;
  disabled?: boolean;
  $fullWidth?: boolean;
}

const transition = (property: string) => css`
  @media (prefers-reduced-motion: no-preference) {
    transition: ${property} 100ms ease-in-out;
  }
`;

const Container = styled('div') <ContainerProps>`
  background: ${({ theme }) => theme.palette.white};
  display: ${({ $fullWidth }) => ($fullWidth ? 'block' : 'inline-block')};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

const Label = styled('label') <ContainerProps>`
  border: 1px dashed ${({ theme }) => theme.palette.gray[500]};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  outline: none;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.base};
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  ${transition('background')}
  &:focus {
    outline: 2px solid ${({ theme }) => theme.palette.blue[700]};
    outline-offset: 2px;
    ${({ disabled }) => !disabled && css`
      ${transition('color')}
      background: ${({ theme }) => theme.palette.blue[50]};
      border-color: ${({ theme }) => theme.palette.blue[700]};
      && > i {
        ${transition('color')}
        color: ${({ theme }) => theme.palette.gray[700]};
      }
    `}
  }
  &:hover {
    ${transition('background')}
    border-color: ${({ theme }) => theme.palette.blue[700]};
    background: ${({ theme }) => theme.palette.blue[50]};
    && > i {
      ${transition('color')}
      color: ${({ theme }) => theme.palette.gray[700]};
    }
  }
  ${({ disabled }) => disabled && css`
    border-color: ${({ theme }) => theme.palette.gray[300]};
    background: ${({ theme }) => theme.palette.gray[25]};
    /* override pointer-events rule for buttons */
    pointer-events: none;
    && > p, div {
      color: ${({ theme }) => theme.typography.color.tertiary};
    }
    && > i {
      ${transition('color')}
      color: ${({ theme }) => theme.palette.gray[300]};
    }
  `}
  ${({ activeDrag }) => activeDrag && css`
    ${transition('background')}
    border-color: ${({ theme }) => theme.palette.blue[700]};
    background: ${({ theme }) => theme.palette.blue[50]};
    && > i {
      ${transition('color')}
      color: ${({ theme }) => theme.palette.blue[900]};
    }
  `}
`;

const Overlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const UploadIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.gray[500]};
  ${transition('color')}
`;

const DropZoneTextContainer = styled('div')`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.base};
`;

const CriteriaListContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing.micro};
  color: ${({ theme }) => theme.typography.color.secondary};
`;

const UploaderAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing.mini};
`;

const FileUploadDropZone: FunctionComponent<FileUploadDropZoneProps> = ({
  heading,
  dropzoneId,
  onDrop,
}) => {
  const [activeDrag, setActiveDrag] = useState<boolean>(false);
  const {
    description,
    disabled,
    errorText,
    fullWidth,
    inputId,
    inputRef,
    onCloseAlert,
  } = useContext(FileUploadContext);
  const dropZoneTextId = `${dropzoneId}-heading`;
  const criteriaListId = `${dropzoneId}-descriptions`;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!disabled && event.key === 'Enter') {
      event.preventDefault();
      if (inputRef?.current) {
        /** Reset the value of the input so
         * duplicate files are not ignored */
        inputRef.current.value = '';
        inputRef.current.click();
      }
    }
  };

  const handleDrag = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setActiveDrag(true);
    } else if (event.type === 'dragleave') {
      setActiveDrag(false);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setActiveDrag(false);
    onDrop?.(event);
  };

  const handleClick = (event: UIEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (inputRef?.current) {
      /** Reset the value of the input so
       * duplicate files are not ignored */
      inputRef.current.value = '';
      inputRef.current.click();
    }
  }

  return (
    <Container
      $fullWidth={fullWidth}
      disabled={disabled}
      id={dropzoneId}
    >
      <Label
        activeDrag={activeDrag}
        disabled={disabled}
        {...(disabled && { 'aria-disabled': true })}
        htmlFor={inputId}
        onDragEnter={handleDrag}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        tabIndex={0}
        aria-labelledby={dropZoneTextId}
        aria-describedby={criteriaListId}
      >
        <UploadIcon type={upload} size="lg" aria-hidden />
        {heading
          && (
            <DropZoneTextContainer id={dropZoneTextId}>
              {heading}
            </DropZoneTextContainer>
          )}
        {description
          && (
            <CriteriaListContainer id={criteriaListId}>
              {description}
            </CriteriaListContainer>
          )}
        {activeDrag && (
          <Overlay
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </Label>
      {errorText && (
        <UploaderAlert type="error" role="alert" withCloseButton onClick={onCloseAlert}>
          <Text>{errorText}</Text>
        </UploaderAlert>
      )}
    </Container>
  );
};

FileUploadDropZone.propTypes = {
  /**
 * Instructional text displayed within drop zone
 */
  heading: PT.node as PT.Validator<ReactNode>,
  /**
   * ID for drop zone
   */
  dropzoneId: PT.string.isRequired,
  /**
   * Callback function called when files are dropped in drop zone
   */
  onDrop: PT.func,
};

FileUploadDropZone.defaultProps = {
  heading: undefined,
  onDrop: noop,
};

export default FileUploadDropZone;
