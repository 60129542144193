/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "cancel": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "default_abandonment_body": [
    {
      "type": 0,
      "value": "If you leave this page you'll lose your changes."
    }
  ],
  "default_abandonment_header": [
    {
      "type": 0,
      "value": "Leave page?"
    }
  ],
  "leave_page": [
    {
      "type": 0,
      "value": "Leave page"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
