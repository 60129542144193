import styled, { css } from 'styled-components';
import Icon from '../../Icon';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import focusOutlineStyle from '../../FocusIndicator/styleMixins/focusOutline';

const switchSize = key => ({ small, theme }) => theme.switch[
  small ? 'small' : 'normal'
][key];

const switchMobileSize = key => ({ small, theme }) => theme.switch[
  small ? 'small' : 'normal'
].mobile[key];

export const switchStyle = css`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;

  height: ${switchSize('innerHeight')}px;
  width: ${switchSize('innerWidth')}px;

  ${isMobile(css`
    height: ${switchMobileSize('innerHeight')}px;
    width: ${switchMobileSize('innerWidth')}px;
  `)}

  :focus {
    outline: none;
  }
  opacity: ${({ disabled, theme }) => (disabled ? theme.switch.disabledOpacity : 1)};

  /* The checkmark */
  svg {
    color: white;
    display: flex;
    width: ${switchSize('innerCheckSize')}px;
    opacity: ${({ disabled, theme }) => (disabled ? theme.switch.disabledOpacity : 1)};
  }
`;

const switchPaddleSize = key => ({ small, theme }) => theme.switch.paddle[
  small ? 'small' : 'normal'
][key];

const switchMobilePaddleSize = key => ({ small, theme }) => theme.switch.paddle[
  small ? 'small' : 'normal'
].mobile[key];

const switchPaddleOffset = ({ small, theme, toggled }) => (toggled
  ? theme.switch.paddle[
    small ? 'small' : 'normal'
  ].checkedTranslateOffset
  : 0);

const switchMobilePaddleOffset = ({ small, theme, toggled }) => (toggled
  ? theme.switch.paddle[
    small ? 'small' : 'normal'
  ].mobile.checkedTranslateOffset
  : 0);

const switchBgColor = key => ({ toggled, disabled, theme }) => {
  const toggledObj = theme.switch.paddle[toggled ? 'checked' : 'default'];
  return disabled ? (toggledObj.disabled || toggledObj[key]) : toggledObj[key];
};

export const SliderButton = styled('button')`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  top: 0;


  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms;
  }
  border-radius: ${({ theme }) => theme.switch.paddle.borderRadius};
  width: 100%;
  height: 100%;

  background-color: ${switchBgColor('bg')};
  :hover {
    background-color: ${switchBgColor('hover')};
  }
  :active {
    background-color: ${switchBgColor('active')};
  }

  padding: 1px ${switchSize('innerPadding')}px;

  border: none;
  ${isMobile(css`
    border-radius: 12px;
    padding: 1px ${switchMobileSize('innerPadding')}px;
  `)}

  /* The slider */
  :hover {
    ::before {
      border: none;
    }
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutlineStyle)}
  }

  ::before {
    opacity: ${({ disabled, theme }) => (disabled ? theme.switch.disabledOpacity : 1)};
    box-sizing: border-box;
    position: absolute;
    content: "";
    top: ${switchPaddleSize('topOffset')}px;

    /* @noflip */ left: ${switchPaddleSize('leftOffset')}px;
    inset-inline-start: ${switchPaddleSize('leftOffset')}px;
    /* @noflip */ right: auto;
    inset-inline-end: auto;

    transition-property: transform;
    @media (prefers-reduced-motion: no-preference) {
      transition-duration: ${({ theme }) => theme.switch.slideTransition};
    }

    background: ${({ theme }) => theme.switch.paddle.bg};
    border: none;
    border-radius: ${({ theme }) => theme.switch.paddle.borderRadius};
    box-shadow: ${({ theme }) => theme.switch.paddle.boxShadow};

    height: ${switchPaddleSize('paddleHeight')}px;
    width: ${switchPaddleSize('paddleWidth')}px;

    /*! @noflip */ transform: translateX(${switchPaddleOffset}px);


    ${isMobile(css`
      height: ${switchMobilePaddleSize('paddleHeight')}px;
      width: ${switchMobilePaddleSize('paddleWidth')}px;

      /*! @noflip */ transform: translateX(${switchMobilePaddleOffset}px);
    `)}
  }

  /*! @noflip */
  [dir="rtl"] &&::before {
    /* @noflip */ right: ${switchPaddleSize('leftOffset')}px;
    inset-inline-start: ${switchPaddleSize('leftOffset')}px;
    /* @noflip */ left: auto;
    inset-inline-end: auto;

    /*! @noflip */ transform: translateX(-${switchPaddleOffset}px);

    ${isMobile(css`
      /*! @noflip */ transform: translateX(-${switchMobilePaddleOffset}px);
    `)}
  }
`;

const switchIconStyle = key => ({ $small, theme }) => theme.switch[
  $small ? 'small' : 'normal'
][key];

const switchIconMobileStyle = key => ({ $small, theme }) => theme.switch[
  $small ? 'small' : 'normal'
].mobile[key];

export const Checkmark = styled(Icon)`
  visibility: ${({ $toggled, $disabled }) => ($disabled && !$toggled ? 'hidden' : 'visible')};
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms;
  }
  display: flex;
  svg {
    font-size: ${switchIconStyle('innerCheckSize')}px;
    opacity: ${({ $disabled, theme }) => ($disabled ? theme.switch.disabledOpacity : 1)};
    ${isMobile(css`
      font-size: ${switchIconMobileStyle('innerCheckSize')}px;
    `)}
  }
`;
