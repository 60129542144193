/* eslint-disable id-length */
import { palette } from '../base/color';
import { fontSizeValues, mobileFontSizes, typography } from '../base/typography';
import { spacing } from '../base/spacing';

export const datepicker = {
  navigationSize: '8px', // back and forward arrows
  navigationPosition: 'absolute',
  navigationTop: spacing.medium,
  navigationPadding: `${spacing.mini} ${spacing.small}`,
  navigationSpacing: spacing.large,
  navigationColor: palette.gray[700],
  navigationHoverColor: palette.squidInk,

  monthColor: typography.color.primary,
  monthSize: `${fontSizeValues.base}px`,

  weekColor: typography.color.secondary,
  weekSize: `${fontSizeValues.small}px`,

  dayBg: palette.gray[25],
  dayColor: typography.color.primary,
  dayHoverBg: palette.blue[50],
  dayBorderColor: palette.white,
  dayBorderWidth: '2px',

  daySelectedBg: palette.blue[900],
  daySelectedColor: palette.white,

  dayTodayBg: palette.gray[200],

  dayBlockedBg: palette.white,
  dayBlockedColor: typography.color.tertiary,
  dayBlockedHoverBg: palette.white,
  dayBlockedHoverColor: palette.gray[400],

  bg: palette.white,
  rangeSelectedBg: palette.blue[700],
  rangeSelectedColor: palette.white,
  rangeSelectedHoverBg: palette.blue[900],

  timeZoneColor: typography.color.tertiary,

  rangePickerBorderRadius: '8px',
};

export const mobileDatepickerStyles = {
  mobile: {
    weekSize: mobileFontSizes.small,
    daySize: mobileFontSizes.base,
    monthSize: mobileFontSizes.medium,
  },
};
