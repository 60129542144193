import { Transform } from '../types';
import meaninglessTransform from './meaninglessTransform';

/** Parses the given transform string into an object with all transformation options */
const parseTransformString = (transformString?: string): Transform => {
  const transformObj = { ...meaninglessTransform };

  if (!transformString) {
    return transformObj;
  }
  return transformString.toLowerCase().split(' ').reduce((acc, curr) => {
    const parts = curr.toLowerCase().split('-');
    const first = parts[0];
    const rest = parts.slice(1).join('-');

    if (first && rest === 'h') {
      acc.flipX = true;
      return acc;
    }

    if (first && rest === 'v') {
      acc.flipY = true;
      return acc;
    }

    const val = parseFloat(rest);

    if (isNaN(val)) {
      return acc;
    }

    switch (first) {
      case 'grow':
        acc.size += val;
        break;

      case 'shrink':
        acc.size -= val;
        break;

      case 'left':
        acc.x -= val;
        break;

      case 'right':
        acc.x += val;
        break;

      case 'up':
        acc.y -= val;
        break;

      case 'down':
        acc.y += val;
        break;

      case 'rotate':
        acc.rotate += val;
        break;
    }

    return acc;
  }, transformObj);
};

export default parseTransformString;
