import { DayModifiers, ISODate } from '../types';
import {
  isAfter,
  isBefore,
  isSameDay,
} from '../utils/dateUtils';
import isDateRange from '../utils/isDateRange';
import isDateSingle from '../utils/isDateSingle';
import useDatePicker from './useDatePicker';
import useDateRange from './useDateRange';
import useDateSingle from './useDateSingle';

/** Returns any modified properties of the given day  */
const useDayModifiers = (date: ISODate, overrides?: DayModifiers): DayModifiers => {
  const context = useDatePicker();
  const single = useDateSingle();
  const range = useDateRange();

  const modifiers: DayModifiers = {
    today: isSameDay(date, context.today),
    ...overrides,
  };

  const { minDate, maxDate } = context;
  // day is disabled if before the minDate
  if (minDate && isBefore(date, minDate)) {
    modifiers.disabled = true;
    modifiers.before = true;
  }
  // day is disabled if after the maxDate
  if (maxDate && isAfter(date, maxDate)) {
    modifiers.disabled = true;
    modifiers.after = true;
  }

  if (isDateSingle(context.type)) {
    modifiers.selected = single.selected ? isSameDay(date, single.selected) : false;
  } else if (isDateRange(context.type)) {
    const { hovered, isDateInHoveredRange } = range;
    // is day hovered
    if (range.selected?.from
      && hovered
      && isSameDay(date, hovered)
      && isAfter(hovered, range.selected.from)) {
      modifiers.hovered = true;
    // is day within the hovered range
    } else if (isDateInHoveredRange?.(date)) {
      modifiers.potential = true;
    }
    // range start and end dates are selected
    if ((range.selected?.from && isSameDay(range.selected.from, date))
    || (range.selected?.to && isSameDay(range.selected.to, date))) {
      modifiers.selected = true;
    }
    // range days are between the start and end dates
    if (range.selected?.from) {
      if (range.selected?.to) {
        if (isAfter(date, range.selected.from) && isBefore(date, range.selected.to)) {
          modifiers.range = true;
        }
      // potential is a range that hasn't been selected yet
      } else if (hovered && isAfter(date, range.selected.from) && isBefore(date, hovered)) {
        modifiers.potential = true;
      }
    }
    /**
     * If multiple date selection is being used, change the background color of
     * the non hovered dates within the potentially selected range to indicate
     * that they will also be selected.
     */
    if (range.multiDateHovered?.from
      && range.multiDateHovered?.to
      && (isAfter(date, range.multiDateHovered.from) || isSameDay(date, range.multiDateHovered.from))
      && (isBefore(date, range.multiDateHovered.to) || isSameDay(date, range.multiDateHovered.to))
    ) {
      modifiers.potential = true;
    }
  }

  return modifiers;
};

export default useDayModifiers;
