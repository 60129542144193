import React, { MouseEventHandler, PureComponent, Ref } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import InputFormGroup, { InputFormGroupProps } from '../InputFormGroup/InputFormGroup';
import SearchInputComponent from './SearchInputComponent';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';

const NodeSafeInputElement = (typeof HTMLInputElement !== 'undefined' ? HTMLInputElement : Object);

interface WrapperProps {
  $fullWidth?: boolean;
}
const Wrapper = styled('div')<WrapperProps>`
  ${({ $fullWidth, theme }) => !$fullWidth && `max-width: ${theme.form.input.maxWidth};`}
  width: 100%;
  position: relative;

  ${isMobile(css`
    max-width: ${({ theme }) => theme.form.input.mobile.maxWidth};
  `)}
`;
Wrapper.displayName = 'Wrapper';

export interface SearchInputProps extends InputFormGroupProps {
  /**
   * The id attribute that is supplied to the input element.
   */
  id: string;
  /**
   * The value of the input.
   */
  value?: string;
  /**
   * Called when user clicks the Clear button.
   * Use this to reset the input’s value and return focus to the input.
   */
  onClear: MouseEventHandler<HTMLButtonElement>;
  /**
   * Denotes if the SearchInput should be disabled.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * An input label is required for accessibility.
   * @defaultValue `"Search"`
   */
  label?: string;
  /**
   * The label is hidden by default; set this to false to show it.
   * @defaultValue `true`
   */
  hideLabel?: boolean;
  /**
   * Denotes if the SearchInput should extend the full width of it's parent.
   * @defaultValue `false`
   */
  fullWidth?: boolean;
  /**
   * Use this to pass properties to the Clear button.
   * @defaultValue `{}`
   */
  clearBtnProps?: { [key: string]: unknown; };
  /**
   * Use this to provide a localized screen reader label for the Clear button.
   * @defaultValue `"Clear search terms"`
   */
  clearButtonLabel?: string;
  /**
   * Use to access the <input> element ref.
   * @defaultValue `() => null`
   */
  inputRef?: Ref<HTMLInputElement>;
}

export default class SearchInput extends PureComponent<SearchInputProps> {
  static propTypes = {
    id: PT.string.isRequired,
    value: PT.string.isRequired,
    onClear: PT.func.isRequired,
    disabled: PT.bool,
    inputRef: PT.oneOfType([
      PT.func,
      PT.shape(
        { current: (PT.instanceOf(NodeSafeInputElement)) },
      ),
    ]),
    label: PT.string,
    hideLabel: PT.bool,
    fullWidth: PT.bool,
    prefix: PT.node,
    suffix: PT.node,
    clearBtnProps: PT.objectOf(PT.any),
    clearButtonLabel: PT.string,
  }

  static defaultProps = {
    disabled: false,
    label: 'Search',
    hideLabel: true,
    fullWidth: false,
    inputRef: () => null,
    prefix: undefined,
    suffix: undefined,
    clearBtnProps: {},
    clearButtonLabel: 'Clear search terms',
  }

  render(): JSX.Element {
    const {
      taktId,
      taktValue,
    } = this.props;
    const {
      id,
      disabled,
      value,
      inputRef,
      label,
      hideLabel,
      fullWidth,
      ...rest
    } = this.props;

    return (
      <Wrapper $fullWidth={fullWidth}>
        <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('search-input')}>
          {({ getDataTaktAttributes }) => (
            <InputFormGroup
              {...getDataTaktAttributes()}
              {...rest}
              id={id}
              inputRef={inputRef}
              disabled={disabled}
              value={value}
              label={label}
              hideLabel={hideLabel}
              fullWidth={fullWidth}
              inputElement={SearchInputComponent}
              type="search"
            />
          )}
        </TaktIdConsumer>

      </Wrapper>
    );
  }
}
