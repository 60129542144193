import React, {
  useContext,
  createContext,
  ReactNode,
} from 'react'

export type TablistContextType = boolean;

export const defaultContextValue = false;

const TablistContext = createContext<TablistContextType>(defaultContextValue);

export interface TablistContextProviderProps {
  isTablist: boolean;
  children: ReactNode;
}

export function TablistContextProvider({
  isTablist,
  children,
}: TablistContextProviderProps) {
  return (
    <TablistContext.Provider
      value={isTablist}
    >
      {children}
    </TablistContext.Provider>
  );
}

export const useTablistContext = () => {
  const isTablist = useContext(TablistContext);

  return {
    isTablist,
  };
}
