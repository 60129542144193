import {
  Locale,
} from 'date-fns';
import { ISODate } from '../types';
import { addDays, startOfWeek, differenceInCalendarDays } from './dateUtils';

/** Returns an array of dates ourside of the current month but within the same calendar view */
const getOutsideStartDays = (month: ISODate, locale?: Locale): Array<ISODate> => {
  const days: ISODate[] = [];
  const firstDayOfWeek = startOfWeek(month, locale);
  const startDiff = differenceInCalendarDays(month, firstDayOfWeek);

  for (let i = 0; i < startDiff; i++) {
    const newDay = addDays(firstDayOfWeek, i);
    days.push(newDay);
  }
  return days;
};

export default getOutsideStartDays;
