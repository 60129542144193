import React, {
  ChangeEvent,
  FC,
  FormEvent,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';
import styled from 'styled-components';
import PT, { Requireable, Validator } from 'prop-types';
import Button from '../Button';
import Input, { InputFormGroupProps } from '../InputFormGroup/InputFormGroup'
import PillBox from './PillBox';
import { ListBuilderItem } from './types';
import { RenderMessage, StatusType } from '../InputFormGroup/types';
import { PillProps } from '../Pill/Pill';

const ListBuilderWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListBuilderBaseWrapper = styled('div')`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  padding: 10px;
`;

const ListBuilderInputWrapper = styled('form')`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export interface ListBuilderInputProps extends Omit<InputFormGroupProps, 'inputElement'|'type'|'fullWidth'|'label'|'hideLabel'|'inline'|'renderLabelStart'|'renderLabelEnd'> {
  /** The unique identifier */
  id: string;
  /** The text displayed in the Button used to add a new Pill */
  addText: ReactNode;
  /** The aria-label for the Button used to remove all Pills */
  removeAllLabel: string;
  /**
   * The list of items displayed as Pills
   * @defaultValue `[]`
   * */
  items?: Array<ListBuilderItem>;
  /**
   * Should the add Button be disabled
   * @defaultValue `false`
   * */
  disableAdd?: boolean;
  /**
   * Adds a message below the input, which will update based on error/success/warning
   * @defaultValue `undefined`
   * */
  message?: string | RenderMessage;
  /**
   * Specifies the type of message to be displayed (error, warning, success)
   * @defaultValue `undefined`
   * */
  statusType?: StatusType;
  /**
   * The function called when a new Pill is added
   * @defaultValue `undefined`
   * */
  onAdd?: (event: MouseEvent<HTMLButtonElement>, value?: string) => void;
  /**
   * The function called when a Pill is removed
   * @defaultValue `undefined`
   * */
  onRemovePill?: (event: MouseEvent<HTMLButtonElement>, item: ListBuilderItem) => void;
  /**
   * The function called when the Button to remove all Pills is clicked
   * @defaultValue `undefined`
   * */
  onRemoveAll?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Can be used to render a custom Pill
   * @defaultValue `undefined`
   * */
  renderPill?: (pill: ListBuilderItem) => ReactNode;
}

const ListBuilderInput: FC<React.PropsWithChildren<ListBuilderInputProps>> = ({
  id,
  addText,
  items,
  onAdd,
  onRemovePill,
  onRemoveAll,
  removeAllLabel,
  disableAdd,
  onChange,
  renderPill,
  disabled,
  ...rest
}) => {
  const [inputVal, setInputVal] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target?.value);
    onChange?.(event);
  }

  const handleOnAdd = (event: MouseEvent<HTMLButtonElement>) => {
    onAdd?.(event, inputVal);
    setInputVal('');
  };

  return (
    <ListBuilderWrapper id={id}>
      <ListBuilderBaseWrapper>
        <ListBuilderInputWrapper onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}>
          <Input
            {...rest}
            fullWidth
            id={`${id}-input`}
            value={inputVal}
            onChange={handleInputChange}
            disabled={disabled}
          />
          <Button
            type="submit"
            onClick={handleOnAdd}
            disabled={disabled || disableAdd}
            tabIndex={0}
          >
            {addText}
          </Button>
        </ListBuilderInputWrapper>
      </ListBuilderBaseWrapper>
      {items && items.length ? (
        <PillBox
          style={{ borderTop: '0' }}
          pills={items}
          onRemovePill={onRemovePill}
          onRemoveAll={onRemoveAll}
          removeAllLabel={removeAllLabel}
          renderPill={renderPill}
        />
      ) : null}
    </ListBuilderWrapper>
  );
};

ListBuilderInput.propTypes = {
  /** The unique identifier */
  id: PT.string.isRequired,
  /** The text displayed in the Button used to add a new Pill */
  addText: PT.string.isRequired,
  /** The aria-label for the Button used to remove all Pills */
  removeAllLabel: PT.string.isRequired,
  /** The list of items displayed as Pills */
  items: PT.arrayOf(
    PT.arrayOf(
      PT.oneOfType([
        PT.string.isRequired,
        PT.object as Requireable<PillProps>,
      ]),
    ),
  ) as Validator<Array<ListBuilderItem>>,
  /** Should the add Button be disabled */
  disableAdd: PT.bool,
  /** Adds a message below the input, which will update based on error/success/warning */
  message: PT.oneOfType([
    PT.string,
    PT.func,
  ]),
  /** Specifies the type of message to be displayed (error, warning, success) */
  statusType: PT.oneOf(['error', 'warning', 'success']) as Validator<StatusType>,
  /** The function called when a new Pill is added */
  onAdd: PT.func,
  /** The function called when a Pill is removed */
  onRemovePill: PT.func,
  /** The function called when the Button to remove all Pills is clicked */
  onRemoveAll: PT.func,
  /** Can be used to render a custom Pill */
  renderPill: PT.func,
};

ListBuilderInput.defaultProps = {
  onAdd: undefined,
  onRemovePill: undefined,
  onRemoveAll: undefined,
  items: [],
  disableAdd: false,
  message: undefined,
  statusType: undefined,
  renderPill: undefined,
}

export default ListBuilderInput;
