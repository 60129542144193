import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../theme';
import { AlertType } from './types';

function colorStyle({ $type, theme }: { $type?: AlertType, theme: Record<string, Theme> }) {
  const color = $type ? theme.message[$type]?.iconColor : null;
  return color ? css`color:${color};` : '';
}

export interface StyledMessageIconProps {
  $type?: AlertType;
  $header?: ReactNode;
}

const StyledMessageIcon = styled('span')<StyledMessageIconProps>`
  font-size: ${({ $header, theme }) => ($header ? theme.message.iconSizeHeader : theme.message.iconSize)};
  min-width: ${({ theme }) => theme.message.iconSizeHeader};
  ${colorStyle};
  text-align: center;
  margin-inline-end: ${({ theme }) => theme.spacing.base};
  margin-top: ${({ $header }) => ($header ? '4px' : '0')};
`;
StyledMessageIcon.displayName = 'StyledMessageIcon';

export default StyledMessageIcon;
