import React, { ReactNode } from 'react';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import { CardStyled, CardStyledProps } from './Card.styles';

interface CardContainerProps extends TaktProps, CardStyledProps {
  children: ReactNode;
}

const CardContainer = ({ taktId, taktValue, ...restProps }: CardContainerProps) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('card')}>
    <CardStyled {...restProps} />
  </TaktIdProvider>
)

export default CardContainer;
