/** Returns if an icon should flip directions on RTL along with the update icon name */
const isHorizontalFlipOnRTL = (iconName: string): [string, boolean] => {
  if (/-inline-(start|end)$/.test(iconName)) {
    const inlineEndIconMatch = iconName.match(/^([a-zA-Z0-9-]+)-inline-end$/);
    if (inlineEndIconMatch !== null) {
      return [`${inlineEndIconMatch[1]}-right`, true];
    }
    const inlineStartIconMatch = iconName.match(/^([a-zA-Z0-9-]+)-inline-start$/);
    if (inlineStartIconMatch !== null) {
      return [`${inlineStartIconMatch[1]}-left`, true];
    }
  }
  return [iconName, false];
};

export default isHorizontalFlipOnRTL;
