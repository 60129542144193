/* eslint-disable react/forbid-prop-types */
import React, { ComponentPropsWithRef, PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import styled, { css, StyledComponent } from 'styled-components';
import { infoCircle } from '@amzn/storm-ui-icons';
import Icon from '../Icon';
import Tooltip, { TooltipProps } from '../Tooltip';
import focusOutlineStyle from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';

export interface HelpTipTooltipProps extends TaktProps, Omit<TooltipProps, 'message' | 'trigger' | 'id'> {
  // eslint-disable-next-line react/require-default-props
  suppressClassNameWarning?: boolean;
}

export interface HelpTipProps extends HelpTipTooltipProps {
  /**
     * The id attribute that is supplied to the popover element.
     * Useful for identifying the tooltip in front-end telemetry.
     * @defaultValue `undefined`
     */
  id?: string;
  /**
     * The help tip tab index.
     * @defaultValue `0`
     */
  tabIndex?: number;
  /**
     * Label for the trigger icon.
     * @defaultValue `"Open tooltip"`
     */
  triggerLabel?: string;
  /**
     * Additional props that are applied to the help tip trigger button.
     * @defaultValue `{}`
     */
  triggerProps?: ComponentPropsWithRef<'button'>;
  /**
     * Render prop to override the trigger icon.
     * @defaultValue `<TooltipIcon type={infoCircle} blockSize />`
     */
  renderTrigger?: ReactNode;
}

const StyledTooltip = styled(Tooltip)<HelpTipTooltipProps>``;

StyledTooltip.displayName = 'StyledTooltip';

export const TooltipButton: StyledComponent<'button', Record<string, unknown>> = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})<{ focusVisible: boolean }>`
  border: none;
  background: transparent;
  padding: ${({ theme }) => theme.helptip.triggerIcon.padding};
  margin: ${({ theme }) => theme.helptip.triggerIcon.margin};
  outline: none;

  :focus {
    ${({ focusVisible }) => (focusVisible && focusOutlineStyle)}
  }
`;

export const TooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.helptip.triggerIcon.color};
  display: block;
  font-size: ${({ theme }) => theme.helptip.triggerIcon.size};

  :hover {
    color: ${({ theme }) => theme.helptip.triggerIcon.hoverColor};
    cursor: pointer;
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.helptip.triggerIcon.mobile.size};
  `)}
`;
TooltipIcon.displayName = 'TooltipIcon';

export default class HelpTip extends PureComponent<HelpTipProps> {
  static propTypes = {
    /**
     * The id attribute that is supplied to the popover element.
     * Useful for identifying the tooltip in front-end telemetry.
     */
    id: PT.string,
    /**
     * The help tip tab index.
     */
    tabIndex: PT.number,
    /**
     * Add the tooltip contents.
     */
    children: PT.oneOfType([
      PT.arrayOf(PT.node), PT.node]).isRequired,
    /**
     * Label for the trigger icon.
     */
    triggerLabel: PT.string,
    /**
     * Additional props that are applied to the help tip trigger button.
     */
    triggerProps: PT.objectOf(PT.any),
    /**
     * Render prop to override the trigger icon.
     */
    renderTrigger: PT.node,
  }

  static defaultProps = {
    id: undefined,
    tabIndex: 0,
    triggerLabel: 'Open tooltip',
    triggerProps: {},
    renderTrigger: (<TooltipIcon type={infoCircle} blockSize />),
  }

  render(): JSX.Element {
    const {
      id,
      tabIndex,
      children,
      triggerLabel,
      triggerProps,
      renderTrigger,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('help-tip')}>
        {({ getDataTaktAttributes }) => (
          <StyledTooltip
            {...rest}
            id={id}
            suppressClassNameWarning
            trigger={(
              <TooltipButton
                {...getDataTaktAttributes()}
                {...triggerProps}
                tabIndex={tabIndex}
                type="button"
                aria-label={triggerLabel}
              >
                {renderTrigger}
              </TooltipButton>
            )}
            message={children}
          />
        )}
      </TaktIdConsumer>

    );
  }
}
