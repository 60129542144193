import { IconDefinition, IconPathData } from '../types';

export const EMPTY_ICON_WIDTH = 512;
export const EMPTY_ICON_HEIGHT = 512;

/* Define a empty icon that will be used before until the real icon data is fetched */
const emptyIcon: IconDefinition['icon'] = [
  EMPTY_ICON_WIDTH as number, // width
  EMPTY_ICON_HEIGHT as number, // height
  [''] as string[], // ligatures
  '' as string, // unicode
  '' as IconPathData, // svgPathData
];

export default emptyIcon;
