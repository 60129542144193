import { PureComponent } from 'react';
import PT from 'prop-types';
import { isFunction } from '@amzn/storm-ui-utils-v3';

/*
 * FocusVisibleObserver follows the focusin, focusout events to set the focusVisible prop. When
 * a pointerdown or mousedown happens in the same event loop as the focus, we infer that
 * the focus was the result of a mouse or pointer input and we do not need to display the
 * visual focus indicator.
 */

/*
 * A mouse down event must happen within 4ms to stop the element from taking visible focus.
 */
const eventTimeout = 4;

export default class FocusVisibleObserver extends PureComponent {
  static propTypes = {
    refPassthrough: PT.oneOfType([
      PT.func, // for legacy refs
      PT.shape({ current: PT.any }),
    ]),
    children: PT.func.isRequired,
  }

  static defaultProps = {
    refPassthrough: undefined,
  }

  constructor(props) {
    super(props);

    this.state = {
      focusVisible: false,
    };
  }

  handleFocusIn = event => {
    if (!this.recentPointerDownEvent && event.target === this.elementRef) {
      this.focusInTimeout = setTimeout(() => {
        this.setState({ focusVisible: true });
      }, eventTimeout);
    }
  }

  handleFocusOut = () => {
    clearTimeout(this.focusInTimeout);
    this.recentPointerDownEvent = false;
    this.setState({ focusVisible: false });
  }

  handlePointerDown = () => {
    clearTimeout(this.focusInTimeout);

    this.recentPointerDownEvent = true;
    setTimeout(() => {
      this.recentPointerDownEvent = false;
    }, eventTimeout);
  }

  setElementRef = newRef => {
    const prevRef = this.elementRef;

    // We are deliberately not listening for "click" events anymore because browsers emit
    // click events when activating radio buttons with a keyboard and they do it in different
    // ways.  Mousedown and pointerdown are sufficient as they are fired before "click" events.
    if (newRef && isFunction(newRef.addEventListener)) {
      // Setup event listeners
      newRef.addEventListener('focusin', this.handleFocusIn);
      newRef.addEventListener('focusout', this.handleFocusOut);
      newRef.addEventListener('pointerdown', this.handlePointerDown);
      newRef.addEventListener('mousedown', this.handlePointerDown);
    } else if (prevRef !== newRef && prevRef && isFunction(prevRef.addEventListener)) {
      // Cleanup old event listeners
      prevRef.removeEventListener('focusin', this.handleFocusIn);
      prevRef.removeEventListener('focusout', this.handleFocusOut);
      prevRef.removeEventListener('pointerdown', this.handlePointerDown);
      prevRef.removeEventListener('mousedown', this.handlePointerDown);
    }

    this.elementRef = newRef;
    this.updateRefPassthrough();
  }

  updateRefPassthrough = () => {
    const { refPassthrough } = this.props;

    // Trigger ref update
    if (refPassthrough) {
      if (typeof refPassthrough === 'function') {
        refPassthrough(this.elementRef || null);
      } else if (typeof refPassthrough === 'object') {
        refPassthrough.current = this.elementRef || null;
      }
    }
  }

  render() {
    const { children, refPassthrough, ...rest } = this.props;
    const { focusVisible } = this.state;
    return children({
      elementRef: this.setElementRef,
      focusVisible,
      propsPassthrough: rest,
    });
  }
}
