import React, {
  PureComponent, ReactNode, ChangeEventHandler, ComponentType,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  noop,
} from '@amzn/storm-ui-utils-v3';
import {
  isMobileStyleMixin as isMobile,
  Icon,
  InputFormGroup,
  InputFormGroupProps,
  withTaktFallbackId,
  createStormTaktId,
} from '@amzn/storm-ui-v3';
import { clock } from '@amzn/storm-ui-icons-v3';
import timePropType from './timePropType';
import TimeInput from './TimeInput';

const ClockIcon = styled(Icon)`
  ${({ theme }) => theme.typography.base};
  ${isMobile(css`
    font-size: ${({ theme }) => theme.form.input.mobile.fontSize};
  `)}
`;

function getTimeInputSupported(): boolean {
  try {
    const input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', 'time');
    input.setAttribute('value', value);
    return (input.value !== value);
  } catch (error) {
    return false;
  }
}

const DefaultTimeInput = withTaktFallbackId<InputFormGroupProps>(InputFormGroup)

export interface TimePickerProps extends InputFormGroupProps {
  id: string;
  /**
   * Label is needed for accessibility when no other label is associated to the input.
   * @defaultValue `undefined`
   */
  label?: ReactNode;
  /**
   * Time is a string in `HH:mm` 24 hour time format. For example `"14:32"` or `"02:59"`.
   */
  value?: string;
  /**
   * A function that receives an event from the `<input />`  element.
   * @defaultValue `() => undefined`
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * Force browser to use a custom time input  instead of the native `<input type="time">`.
   * @defaultValue `false`
   */
  disableNativeTimeInput?: boolean;
  /**
   * Provide customized/localized label for hour input when using a custom
   * time input with `disableNativeTimeInput` equals `true`.
   * @defaultValue `"Hours Time Picker"`
   */
  hourInputLabel?: string;
  /**
   * Provide customized/localized label for minute input when using a custom
   * time input with `disableNativeTimeInput` equals `true`.
   * @defaultValue `"Minutes Time Picker"`
   */
  minuteInputLabel?: string;
  /**
   * Provide customized/localized label for meridiem input when using a custom
   * time input with `disableNativeTimeInput` equals `true`.
   * @defaultValue `"AM/PM Time Picker"`
   */
  meridiemInputLabel?: string;
}

class TimePicker extends PureComponent<TimePickerProps> {
  static propTypes = {
    id: PT.string.isRequired,
    /**
     * Label is needed for accessibility when no other label is associated to the input.
     */
    label: PT.oneOfType([
      PT.string,
      PT.node,
    ]),
    /**
     * Time is a string in `HH:mm` 24 hour time format. For example `"14:32"` or `"02:59"`.
     */
    value: timePropType,
    /**
     * A function that receives an event from the `<input />`  element.
     */
    onChange: PT.func,
    /**
     * Force browser to use a custom time input  instead of the native `<input type="time">`.
     */
    disableNativeTimeInput: PT.bool,
    /**
     * Provide customized/localized label for hour input when using a custom
     * time input with `disableNativeTimeInput` equals `true`.
     */
    hourInputLabel: PT.string,
    /**
     * Provide customized/localized label for minute input when using a custom
     * time input with `disableNativeTimeInput` equals `true`.
     */
    minuteInputLabel: PT.string,
    /**
     * Provide customized/localized label for meridiem input when using a custom
     * time input with `disableNativeTimeInput` equals `true`.
     */
    meridiemInputLabel: PT.string,
  }

  static defaultProps = {
    label: undefined,
    onChange: noop,
    value: '',
    disableNativeTimeInput: false,
    hourInputLabel: 'Hours Time Picker',
    minuteInputLabel: 'Minutes Time Picker',
    meridiemInputLabel: 'AM/PM Time Picker',
  }

  private isTimeInputUnsupported = false;

  constructor(props: TimePickerProps) {
    super(props);
    this.isTimeInputUnsupported = !getTimeInputSupported();
  }

  render(): JSX.Element {
    const {
      id,
      value,
      onChange,
      disableNativeTimeInput,
      hourInputLabel,
      minuteInputLabel,
      meridiemInputLabel,
      ...rest
    } = this.props;

    const useCustomInput = (disableNativeTimeInput || this.isTimeInputUnsupported);
    const inputElement = useCustomInput
      ? TimeInput as ComponentType<any>
      : 'input';

    return (
      <DefaultTimeInput
        {...rest}
        id={id}
        inputElement={inputElement}
        prefix={<ClockIcon type={clock} />}
        type="time"
        value={value}
        onChange={onChange}
        taktFallbackId={createStormTaktId('time-input')}
        {
          ...(useCustomInput && {
            hourInputLabel,
            minuteInputLabel,
            meridiemInputLabel,
          })
        }
      />
    );
  }
}

export default TimePicker;
