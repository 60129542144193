/* eslint-disable id-length */
import { palette } from '../base/color';
import { shadow } from '../base/shadow';
import { typography, mobileTypography } from '../base/typography';
import { spacing, mobileSpacing } from '../base/spacing';
import { border } from '../base/border';

const inputStyles = {
  minWidth: '237px',
  maxWidth: '285px',
  height: '31px',
  radius: border.rounded.radius,
  padding: `0px ${spacing.small}`,
  color: typography.color.primary,
  bg: palette.white,
  placeholderColor: typography.color.tertiary,
  border: `1px solid ${palette.gray[500]}`,
  borderColor: palette.gray[500],
  /* ↑ there are two border variables here to keep prefix and suffix
    borders consistent even when the input border changes due to state */
  borderColorFocus: palette.mango,
  boxShadow: 'none',
  boxShadowFocus: shadow.focusInput,
  internalElementBlockSpacing: '2px',
  internalElementInlineSpacing: `${spacing.mini}`,
  affix: {
    bg: palette.white,
    color: typography.color.tertiary,
    padding: `0px ${spacing.small}`,
    fontSize: typography.size.base,
  },

  message: {
    color: typography.color.secondary,
  },

  disabled: {
    color: typography.color.tertiary,
    bg: palette.gray[100],
    borderColor: palette.gray[300],
  },
  error: {
    borderColor: palette.red[600],
    boxShadow: '0 0 0 2px #F9E5E5 inset',
    boxShadowFocus: `${shadow.focusInput}, 0 0 0 2px #F9E5E5 inset`,
    messageColor: palette.red[600],
  },
  info: {
    iconColor: palette.blue[700],
  },
  warning: {
    borderColor: palette.clementine[500],
    boxShadow: '0 0 0 2px #FCEDDE inset',
    boxShadowFocus: `${shadow.focusInput}, 0 0 0 2px #FCEDDE inset`,
    iconColor: palette.clementine[700],
    messageColor: typography.color.secondary,
  },
  success: {
    borderColor: palette.green[600],
    boxShadow: '0 0 0 2px #dff7df inset',
    boxShadowFocus: `${shadow.focusInput}, 0 0 0 2px #dff7df inset`,
    messageColor: palette.green[700],
  },
};

export const mobileInputStyles = {
  mobile: {
    maxWidth: 'inherit',
    internalElementBlockSpacing: `${mobileSpacing.micro}`,
    height: '44px',
    fontSize: '16px', // to prevent keyboard zooming effect on iOS Safari
    affix: {
      padding: `0px ${mobileSpacing.mini}`,
      fontSize: mobileTypography.mobile.size.base,
    },
  },
};

export default inputStyles;
