import styled, { css } from 'styled-components';
import Icon from '../Icon';
import { PopperTrigger } from '../Popper/Popper.styles';
import Button from '../Button';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { ButtonProps } from '../Button/Button';

const getMobileMarginTopStyle = () => ({ $small }: { $small: boolean }): string => ($small ? '1px' : '3px');

interface DropdownIconProps {
  $disabled?: boolean;
  $small?: boolean;
}
export const DropdownIcon = styled(Icon)<DropdownIconProps>`
  margin-top: 1px;
  margin-inline-start: ${({ $small }) => ($small ? '6px' : '8px')};
  margin-inline-end: 0;
  flex: 0 0 auto;

  color:  ${({ $disabled, theme }) => (!$disabled ? theme.dropdown.icon.color : theme.dropdown.icon.colorDisabled)};
  pointer-events: none;

  ${isMobile(css`
    margin-top: ${getMobileMarginTopStyle()};
  `)}
`;
DropdownIcon.displayName = 'DropdownIcon';

export const DropdownButtonContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
DropdownButtonContent.displayName = 'DropdownButtonContent';

export interface DropdownButtonProps extends ButtonProps {
  $inline?: boolean;
  $error?: boolean;
}
export const DropdownButton = styled(Button)<DropdownButtonProps>`
  display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  box-sizing: border-box;

  :hover {
    > ${DropdownIcon} {
      color: ${({ disabled, theme }) => (!disabled ? theme.dropdown.icon.colorHover : theme.dropdown.icon.colorDisabled)};
    }
  }

  ${({ $error, theme }) => ($error && `
    border: thin solid ${theme.form.input.error.borderColor};
    :active {
      border: thin solid ${theme.form.input.error.borderColor};
    }
  `)}
`;
DropdownButton.displayName = 'DropdownButton';

interface WrapperProps {
  $fullWidth?: boolean;
}
export const Wrapper = styled('div')<WrapperProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  padding: 0;
  font-weight: initial;
  display: inline-block;
  vertical-align: bottom;

  > ${PopperTrigger} {
    width: 100%;
  }

  /* Buttons are wrapped in <div>s. This is required */
  > ${DropdownButton} {
    ${({ $fullWidth }) => $fullWidth && `
      width: 100%;
      text-align: left;
      position: relative;
    `}
  }

  ${isMobile(css`
    width: 100%;
    /* Buttons are wrapped in <div>s. This is required */
    > ${DropdownButton} {
      width: 100%;
      text-align: left;
      position: relative;
    }
  `)}
`;
Wrapper.displayName = 'Wrapper';

export interface PopperContentContainerWrapperProps {
  $minWidth: number | undefined;
  $fullWidth?: boolean;
  $noMaxHeight?: boolean;
}

export const PopperContentContainerWrapper = styled('div')<PopperContentContainerWrapperProps>`
  padding: ${({ $noMaxHeight }) => ($noMaxHeight ? 0 : 7)}px 0px;
  ${({ $fullWidth, $minWidth }) => {
    /* Only set a min-width if we get a positive number. Add 2px for the border thickness */
    const minWidth = $minWidth && $minWidth >= 0 ? $minWidth + 2 : undefined;
    return minWidth ? `
      width: ${($fullWidth) ? `${minWidth}px` : 'auto'};
      min-width: ${minWidth}px;
    ` : '';
  }};
  box-sizing: border-box;
`;

export interface OverflowWrapperProps {
  $noMaxHeight?: boolean;
}

export const OverflowWrapper = styled('div')<OverflowWrapperProps>`
  ${({ $noMaxHeight, theme }) => !$noMaxHeight && `max-height: calc(${theme.dropdown.maxHeight} - 14px);`}
  overflow-x: hidden; /* hides the horizontal scroll */
  overflow-y: auto;   /* enables the vertical scroll */
`;
