import React from 'react';
import PT from 'prop-types';
import TileBox, { TileBoxProps } from './TileBox';

const getPadding = ({ noPadding }: { noPadding: undefined | boolean }) => {
  switch (true) {
    case noPadding === true: {
      return 'none';
    }
    default: {
      return 'medium';
    }
  }
}

export interface TileSectionProps extends Omit<TileBoxProps, 'hideStartBorder'> {
  /**
   * Specifies whether the container has padding or not
   */
  noPadding?: boolean;
}

const TileSection = ({
  children,
  noPadding,
  paddingBlockStart,
  paddingBlockEnd,
  paddingInlineStart,
  paddingInlineEnd,
  ...restProps
}: TileSectionProps) => {
  const padding = getPadding({ noPadding });

  return (
    <TileBox
      {...restProps}
      hideStartBorder={false}
      paddingBlockStart={paddingBlockStart ?? padding}
      paddingBlockEnd={paddingBlockEnd ?? padding}
      paddingInlineStart={paddingInlineStart ?? padding}
      paddingInlineEnd={paddingInlineEnd ?? padding}
    >
      {children}
    </TileBox>
  );
}

TileSection.defaultProps = {
  noPadding: false,
}

TileSection.propTypes = {
  /**
   * Content of the TileSection.
   */
  children: PT.node.isRequired,
  /**
   * Specifies whether the container has padding or not
   */
  noPadding: PT.bool,
}

export default TileSection;
