import React, { MouseEventHandler, PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { angleDown, angleUp } from '@amzn/storm-ui-icons-v3';
import Icon from '../Icon';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

const IconWrapper = styled.span`
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.small};
  flex: 0 0 auto;
  color: ${({ theme }) => theme.sectionExpander.iconColor};
  :hover {
    color: ${({ theme }) => theme.sectionExpander.iconColorHover};
  }
  :active {
    color: ${({ theme }) => theme.sectionExpander.iconColorActive};
  }
`;

export interface ExtenderButtonProps {
  $isOpen: boolean;
}

const ExtenderButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
    type: 'button',
  });
})<ExtenderButtonProps>`
  ${({ theme }) => theme.typography.base}
  display: flex;
  width: 100%;
  border: 1px solid transparent;
  text-align: initial;
  cursor: pointer;
  margin: 0px;

  padding: ${({ theme }) => theme.spacing.medium};
  padding-left: ${({ theme }) => theme.spacing.micro};
  @media (prefers-reduced-motion: no-preference) {
    transition: ${({ theme }) => theme.sectionExpander.transition};
  }

  color: ${({ theme }) => theme.palette.black};
  background-color: ${({ theme, $isOpen }) => ($isOpen ? theme.sectionExpander.bgOpen : theme.sectionExpander.bgClosed)};

  :hover {
    color: ${({ theme }) => theme.palette.black};
    background-color: ${({ theme }) => theme.sectionExpander.bgHover};
    text-decoration: none;
  }
  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
  :active {
    background-color: ${({ theme }) => theme.sectionExpander.bgActive};
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.base};
  `)}
`;
ExtenderButton.displayName = 'ExtenderButton';

const TextButtonLabel = styled.div`
  flex: 1 1 auto;
  padding-left: ${({ theme }) => theme.spacing.medium};
`;

export interface SectionExpanderContentProps {
  $isOpen: boolean;
}

const SectionExpanderContent = styled.section<SectionExpanderContentProps>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.sectionExpander.bgContent};
  border-top: ${({ theme }) => theme.sectionExpander.borderTop};
`;
SectionExpanderContent.displayName = 'SectionExpanderContent';

const bottomBorder = css`
  border-bottom-left-radius: ${({ theme }) => theme.sectionExpander.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.sectionExpander.borderRadius};
`;

const lastContent = css`
  :last-child ${SectionExpanderContent} {
    ${bottomBorder}
  }
`;

const lastButtton = css`
  :last-child ${ExtenderButton} {
    ${bottomBorder}
  }
`;

export interface SectionExpanderWrapperProps {
  $isOpen: boolean;
}

const SectionExpanderWrapper = styled.div<SectionExpanderWrapperProps>`
  border: ${({ theme }) => theme.sectionExpander.border};
  box-shadow: ${({ theme }) => theme.sectionExpander.boxShadow};

  :not(:first-child) {
    border-top: none;
  }

  :first-child,
  :first-child ${ExtenderButton} {
    border-top-left-radius: ${({ theme }) => theme.sectionExpander.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.sectionExpander.borderRadius};
  }

  :last-child {
    ${bottomBorder}
  }

  ${({ $isOpen }) => (
    $isOpen
      ? css`${lastContent}`
      : css`${lastButtton}`
  )}
`;
SectionExpanderWrapper.displayName = 'SectionExpanderWrapper';

export interface SectionExpanderProps extends TaktProps {
  /**
     * The SectionExpander Button label.
     */
  label: ReactNode | ReactNode[];
  /**
     * Denotes if the InlineExapnder is open or closed.
     */
  isOpen: boolean;
  /**
     * The click event handler. Used to toggle state.
     * @defaultValue `undefined`
     */
  handleClick?: MouseEventHandler;
  /**
     * The content within the SectionExpander.
     */
  children?: ReactNode;
}

export default class SectionExpander extends PureComponent<SectionExpanderProps> {
  static propTypes = {
    /**
     * The content within the SectionExpander.
     */
    children: PT.node.isRequired,
    /**
     * The SectionExpander Button label.
     */
    label: PT.node.isRequired,
    /**
     * Denotes if the InlineExapnder is open or closed.
     */
    isOpen: PT.bool.isRequired,
    /**
     * The click event handler. Used to toggle state.
     */
    handleClick: PT.func,
  }

  static defaultProps = {
    handleClick: undefined,
  }

  renderIcon = (): ReactNode => (this.props.isOpen
    ? <Icon type={angleUp} size="lg" />
    : <Icon type={angleDown} size="lg" />
  );

  render(): JSX.Element {
    const {
      children,
      label,
      isOpen,
      handleClick,
      taktId,
      taktValue,
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('section-expander')}>
        {({ getDataTaktAttributes }) => (
          <SectionExpanderWrapper $isOpen={isOpen}>
            <ExtenderButton
              {...getDataTaktAttributes({ taktIdSuffix: 'extender-button' })}
              tabIndex={0}
              aria-expanded={isOpen}
              $isOpen={isOpen}
              onClick={handleClick}
            >
              <TextButtonLabel>
                {label}
              </TextButtonLabel>
              <IconWrapper> {this.renderIcon()} </IconWrapper>
            </ExtenderButton>
            <SectionExpanderContent $isOpen={isOpen}>
              {children}
            </SectionExpanderContent>
          </SectionExpanderWrapper>
        )}
      </TaktIdConsumer>
    );
  }
}
