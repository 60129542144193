import React, { PropsWithChildren, createContext } from 'react';

export interface ErrorIdProviderProps { id: string }

const ErrorIdContext = createContext<ErrorIdProviderProps | undefined>(undefined);

/**
 * This wrapper component provides a prepended id
 * to all StormUI-based errors thrown within itself
 * via the context API.
\ */
const ErrorIdProvider = ({ id, children }: PropsWithChildren<ErrorIdProviderProps>) => (
  <ErrorIdContext.Provider value={{ id }}>
    {children}
  </ErrorIdContext.Provider>
);

export default ErrorIdProvider;
export { ErrorIdContext };
