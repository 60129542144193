import React from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { check } from '@amzn/storm-ui-icons-v3';
import { noop } from '@amzn/storm-ui-utils-v3';
import { switchStyle, SliderButton, Checkmark } from './InnerSwitch.styles';

const switchPropTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  /**
   * Denotes if the Switch should be toggled.
   */
  toggled: PT.bool,
  /**
   * Denotes if the Switch should be disabled.
   */
  disabled: PT.bool,
  /**
   * Returns callback of (value, event).
   */
  onChange: PT.func,
  /**
   * Denotes if the Switch should be small.
   */
  small: PT.bool,
  /**
   * Used to provide a customized aria-label to the Switch. For accessibility purposes only.
   */
  switchLabel: PT.string,
};

const switchDefaultProps = {
  onChange: noop,
  toggled: false,
  disabled: false,
  small: false,
  switchLabel: undefined,
  className: '',
};

const SwitchComponent = ({
  disabled,
  toggled,
  onChange,
  small,
  switchLabel,
  className,
  id,
  ...rest
}) => {
  const { isFocusVisible, focusProps } = useFocusRing();

  const handleOnChange = event => {
    onChange(!event.currentTarget.checked, event);
  };

  return (
    <div className={className}>
      <SliderButton
        id={id}
        aria-label={switchLabel}
        role="switch"
        onClick={handleOnChange}
        checked={toggled}
        toggled={toggled}
        aria-checked={toggled}
        disabled={disabled}
        focusVisible={isFocusVisible}
        type="button"
        small={small}
        {...mergeProps(rest, focusProps)}
      >
        <Checkmark
          type={check}
          $toggled={toggled}
          $disabled={disabled}
          $small={small}
          aria-hidden
        />
      </SliderButton>
    </div>
  );
};

SwitchComponent.propTypes = switchPropTypes;
SwitchComponent.defaultProps = switchDefaultProps;

const InnerSwitch = styled(SwitchComponent)`${switchStyle}`;
InnerSwitch.displayName = 'InnerSwitch';
InnerSwitch.propTypes = switchPropTypes;
InnerSwitch.defaultProps = switchDefaultProps;

export default InnerSwitch;
