/* eslint-disable id-length */
import { border } from '../base/border';
import { palette } from '../base/color';
import { typography, mobileFontSizes } from '../base/typography';

const alert = {
  iconSizeHeader: '24px',
  iconSize: '18px',
  bg: palette.white,
  borderRadius: border.roundedArea.radius,
  borderWidth: border.roundedArea.width,
  closeButtonIconSizeHeader: '18px',
  closeButtonIconSize: '14px',
  closeButtonColor: palette.gray[700],
  closeButtonColorHover: palette.squidInk,

  info: {
    bg: palette.blue[50],
    solidBg: palette.blue[700],
    iconColor: palette.blue[700],
    color: typography.color.primary,
    borderColor: palette.blue[700],
    focusBorderColor: palette.blue[700],
    focusBoxShadowColor: 'rgba(50, 98, 149, 0.50)',
    solidFocusBorderColor: palette.white,
    solidBoxShadowColor: 'rgba(255, 255, 255, 0.50)',
    solidColor: palette.white,
    solidCloseButtonColor: palette.white,
    solidCloseButtonColorHover: palette.white,
  },
  success: {
    bg: palette.green[50],
    solidBg: palette.green[600],
    iconColor: palette.green[600],
    color: typography.color.primary,
    borderColor: palette.green[600],
    focusBorderColor: palette.green[600],
    focusBoxShadowColor: 'rgba(56, 126, 34, 0.5)',
    solidFocusBorderColor: palette.white,
    solidBoxShadowColor: 'rgba(255, 255, 255, 0.50)',
    solidColor: palette.white,
    solidCloseButtonColor: palette.white,
    solidCloseButtonColorHover: palette.white,
  },
  warning: {
    bg: palette.clementine[50],
    solidBg: palette.clementine[500],
    iconColor: palette.clementine[700],
    color: typography.color.primary,
    borderColor: palette.clementine[500],
    focusBorderColor: palette.clementine[500],
    focusBoxShadowColor: 'rgba(231, 118, 0, 0.50)',
    solidFocusBorderColor: palette.white,
    solidBoxShadowColor: 'rgba(255, 255, 255, 0.50)',
    solidColor: palette.squidInk,
    solidCloseButtonColor: palette.squidInk,
    solidCloseButtonColorHover: palette.squidInk,
  },
  error: {
    bg: palette.red[50],
    solidBg: palette.red[600],
    iconColor: palette.red[600],
    color: typography.color.primary,
    borderColor: palette.red[600],
    focusBorderColor: palette.red[600],
    focusBoxShadowColor: 'rgba(196, 0, 0, 0.50)',
    solidFocusBorderColor: palette.white,
    solidBoxShadowColor: 'rgba(255, 255, 255, 0.50)',
    solidColor: palette.white,
    solidCloseButtonColor: palette.white,
    solidCloseButtonColorHover: palette.white,
  },
};

export const mobileAlertStyles = {
  mobile: {
    closeButtonIconSizeHeader: mobileFontSizes.medium,
    closeButtonIconSize: mobileFontSizes.medium,
  },
};

export default alert;
