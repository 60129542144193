import React, { useContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { times } from '@amzn/storm-ui-icons-v3';
import styled, { css } from 'styled-components';
import { TextButton } from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import { OnCloseContext, TypeContext } from './Context';

export interface SheetHeaderCloseButtonProps {
  reverse: boolean;
}

export const SheetHeaderCloseButton = styled(TextButton)<SheetHeaderCloseButtonProps>`
  padding: ${({ theme }) => theme.spacing.base};
  color: ${({ theme, reverse }) => (reverse ? theme.palette.white : theme.typography.color.tertiary)};

  &:hover {
    color: ${({ theme, reverse }) => (reverse ? theme.palette.gray[150] : theme.typography.color.primary)};
  }
`;

export interface HeaderProps {
  reverse: boolean;
  withBoxShadow: boolean;
}

export const Header = styled.header<HeaderProps>`
  position: sticky;
  top: 0;
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
  padding: ${({ theme }) => theme.spacing.base};
  gap: ${({ theme }) => theme.spacing.base};
  background-color: ${({ theme }) => theme.surface.backgroundColor};

  ${({ withBoxShadow }) => withBoxShadow && css`
    box-shadow: 0px 1px 4px rgba(36, 47, 63, 0.15), 0px 0px 2px rgba(35, 47, 63, 0.2);
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.large};
  max-width: 100%;
`;

const ChildrenWrapper = styled.div`
  flex: 1;
`;

export interface SheetHeaderProps {
  /**
   * @defaultValue `"start"`
   */
  closeButton?: 'start' | 'end' | 'false';
  /**
   * @defaultValue `"Close sheet"`
   */
  closeButtonLabel?: string;
  /**
   * @defaultValue `undefined`
   */
  actions?: ReactNode;
  /**
   * @defaultValue `undefined`
   */
  children?: ReactNode;
}

function SheetHeader({
  closeButton,
  closeButtonLabel,
  actions,
  children,
} : SheetHeaderProps) {
  const onClose = useContext(OnCloseContext);
  const type = useContext(TypeContext);
  const isLightType = type === 'light'

  return (
    <Header
      reverse={closeButton === 'end'}
      withBoxShadow={isLightType}
    >
      {closeButton !== 'false' && (
        <SheetHeaderCloseButton
          onClick={onClose}
          reverse={!isLightType}
        >
          <Icon type={times} size="lg" aria-hidden="true" blockSize={false} />
          <Text type="sr-only">{closeButtonLabel}</Text>
        </SheetHeaderCloseButton>
      )}
      <HeaderContainer>
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
        {actions && <div>{actions}</div>}
      </HeaderContainer>
    </Header>
  );
}

SheetHeader.propTypes = {
  closeButton: PropTypes.oneOf(['start', 'end', 'false']),
  closeButtonLabel: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node,
};

SheetHeader.defaultProps = {
  closeButton: 'start',
  closeButtonLabel: 'Close sheet',
  actions: undefined,
  children: undefined,
}

export default SheetHeader;
