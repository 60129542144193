/* eslint-disable id-length */
import React, {
  FC,
  ReactNode,
  MouseEvent,
  ChangeEvent,
} from 'react';
import {
  isMobileStyleMixin,
  SelectList,
  SelectOption,
  Dropdown,
  DropdownItem,
  Text,
  RadioGroup,
  RadioButton,
  useTaktId,
  createStormTaktId,
} from '@amzn/storm-ui';
import styled, { css } from 'styled-components';
import { useIntl } from '@amzn/storm-ui-intl';
import {
  UnresolvedPresetRange,
  PresetRange,
  MultiDateSelector,
  ISOPresetRange,
} from '../../types';
import useDatePicker from '../../hooks/useDatePicker';

const DateRangePresetsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 150px;
  padding-top: 8px;
  border-inline-end: 1px solid #BFBFBF;
  ${isMobileStyleMixin(css`
    margin-bottom: ${({ theme }) => theme.mobile.spacing.mini};
    border: 0;
  `)}
`;

const Zone = styled('div')`
  margin: 0;
  padding-inline-start: 11px;
  padding-inline-end: 11px;
  padding-block-start: 11px;
  padding-block-end: 0;
  text-align: start;
  display: block;
`;

const ZoneText = styled(Text)`
  ${({ theme }) => theme.typography.base};
  font-size: ${({ theme }) => theme.typography.size.mini};
  color: ${({ theme }) => theme.datepicker.timeZoneColor};
  margin: none;
`;

const MultiDateRadioGroupSelector = styled(RadioGroup)`
  margin-inline-start: 11px;
  ${isMobileStyleMixin(css`
    margin-top: 14px;
    margin-inline-start: 0;
  `)}
`;

export interface DateRangePresetSelectorProps {
  presetRanges: PresetRange[] | UnresolvedPresetRange[] | ISOPresetRange[];
  handlePresetSelect: (
    preset?: PresetRange | UnresolvedPresetRange | ISOPresetRange,
    event?: MouseEvent<HTMLButtonElement>,
  ) => unknown;
  selectedPreset: PresetRange | ISOPresetRange | undefined;
  multiDateSelectors?: MultiDateSelector[],
  multiDateSelectorsLabel?: string,
  handleMultiDateSelectorSelect?: (
    multiDateSelector?: MultiDateSelector,
    event?: ChangeEvent<HTMLInputElement>,
  ) => unknown,
  selectedMultiDateSelector?: MultiDateSelector,
  zone: string;
  dropdownLabelOverride?: ((label?: string) => ReactNode);
  isMobile: boolean | undefined;
  zonePrefix?: string;
  renderZoneOverride?: (zone: string) => ReactNode;
}

const DateRangePresetSelector: FC<React.PropsWithChildren<DateRangePresetSelectorProps>> = ({
  presetRanges,
  handlePresetSelect,
  selectedPreset,
  multiDateSelectors,
  multiDateSelectorsLabel,
  handleMultiDateSelectorSelect,
  selectedMultiDateSelector,
  zone,
  dropdownLabelOverride,
  isMobile,
  zonePrefix,
  renderZoneOverride,
}) => {
  const SelectionComponent = isMobile ? Dropdown : SelectList;
  const SelectionComponentItem = isMobile ? DropdownItem : SelectOption;

  const { taktId, taktValue } = useDatePicker();
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-range-picker-preset-selector') });

  const { formatMessage } = useIntl();

  const handlePresetChange = (
    period?: string | null,
    event?: MouseEvent<HTMLButtonElement>,
  ) => {
    if (typeof period === 'string') {
      let range: PresetRange | UnresolvedPresetRange | ISOPresetRange | undefined;
      for (let i = 0; i < presetRanges.length; i++) {
        if (presetRanges[i].period === period) {
          range = presetRanges[i];
          break;
        }
      }
      if (range?.start && range?.end) {
        handlePresetSelect(range, event);
      }
    }
  };

  const handleMultiSelectorChange = (
    selectorRange?: string | undefined,
    event?: ChangeEvent<HTMLInputElement>,
  ) => {
    if (typeof selectorRange === 'string' && multiDateSelectors !== undefined) {
      const multiDateSelector = multiDateSelectors.find(selector => selector.range === selectorRange);
      if (multiDateSelector) {
        handleMultiDateSelectorSelect?.(multiDateSelector, event);
      }
    }
  };

  return (
    <DateRangePresetsWrapper>
      <SelectionComponent
        onChange={handlePresetChange}
        selectedValue={selectedPreset?.period}
        onOverrideLabel={dropdownLabelOverride}
      >
        {
          presetRanges.map(preset => (
            <SelectionComponentItem
              {...getDataTaktAttributes({ taktValue, contextOnlyTaktValues: { selectedPreset: `preset-${preset.label}` } })}
              value={preset.period}
              key={preset.period}
            >
              {
                preset.isDefaultTranslation
                  ? formatMessage({ id: `preset_${preset.period}`, defaultMessage: preset.label })
                  : preset.label
              }
            </SelectionComponentItem>
          ))
        }
      </SelectionComponent>
      { multiDateSelectors && (
        <MultiDateRadioGroupSelector
          name={multiDateSelectorsLabel as string}
          onChange={handleMultiSelectorChange}
          label={multiDateSelectorsLabel}
        >
          {
            multiDateSelectors.map(selector => (
              <RadioButton
                id={selector.range}
                key={selector.range}
                {...selector}
                label={
                  selector.isDefaultTranslation
                    ? formatMessage({ id: `multi_${selector.range}`, defaultMessage: selector.label })
                    : selector.label
                }
                value={selector.range}
                isChecked={selector.range === selectedMultiDateSelector?.range}
                aria-selected={selector.range === selectedMultiDateSelector?.range}
              />
            ))
          }
        </MultiDateRadioGroupSelector>
      )}
      {renderZoneOverride
        ? renderZoneOverride(zone)
        : zone && !isMobile && (
        <Zone>
          <ZoneText type="p">{zonePrefix}</ZoneText>
          <ZoneText type="p">{zone}</ZoneText>
        </Zone>
        )}
    </DateRangePresetsWrapper>
  );
};

DateRangePresetSelector.defaultProps = {
  dropdownLabelOverride: undefined,
  zonePrefix: undefined,
  multiDateSelectors: undefined,
  multiDateSelectorsLabel: 'Multi_Date_Selector_Label',
  handleMultiDateSelectorSelect: undefined,
  selectedMultiDateSelector: undefined,
  renderZoneOverride: undefined,
};

export default DateRangePresetSelector;
