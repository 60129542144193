import React, { useState } from 'react';
import NavigationContext from '../contexts/Navigation/NavigationContext';
import { ISODate, NavigationContextValue } from '../types';
import useDatePicker from './useDatePicker';
import getInitialMonth from '../utils/getInitialMonth';

const useNavigation = (): NavigationContextValue => {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'Navigation context is not defined.',
    );
  }
  return context;
};

const useNavigationState = (): [
  month: ISODate,
  goToMonth: (month: ISODate) => void
] => {
  const context = useDatePicker();
  const initialMonth = getInitialMonth(context);
  return useState<ISODate>(initialMonth);
};

export default useNavigation;
export { useNavigationState };
