export default function parseIetfLanguageTag(tag?: string): { language?: string, country?:string } {
  if (typeof tag !== 'string') {
    return { language: undefined, country: undefined };
  }
  const result = /([a-z]{2,})-?([a-zA-Z]{2,})?/.exec(tag);
  if (!result) {
    return { language: undefined, country: undefined };
  }

  const [, language, country] = result;
  return { language, country };
}
