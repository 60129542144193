/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Custom"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Select a single day"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Select a month"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Select a week"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Last 30 days"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Last seven days"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Last month"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Last week"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Lifetime"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "This week"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Today"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Year to date"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Yesterday"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
