import progressIndicatorStyles from './progressIndicator';

const circularProgressIndicatorStyles = {
  ...progressIndicatorStyles,
  miniSize: '100px',
  smallSize: '125px',
  buttLineCap: 'butt',
  roundLineCap: 'round',
  default: {
    size: 'mini',
    strokeLineCap: 'round',
    marginTop: '-10px',
    strokeWidth: 9,
  },
};

export default circularProgressIndicatorStyles;
