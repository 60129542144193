import React, {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import BoxStyled from './Box.styles';
import { BoxBgColors, BoxPadding } from './types';
import { FlexItemProps } from '../types/CssProps';

export interface BaseBoxProps<C extends React.ElementType> {
  /**
  * This is the content that will be shown in the Box
  */
  children: ReactNode;
  /**
  * Set the HTML element that the Box component should be render as
  * @defaultValue `"div"`
  */
  renderAs?: C;
  /**
   * Set logical block start padding (top padding) for Box body
   * @defaultValue `"large"`
   */
  paddingBlockStart?: BoxPadding;
  /**
   * Set logical block end padding (bottom padding) for Box body
   * @defaultValue `"large"`
   */
  paddingBlockEnd?: BoxPadding;
  /**
   * Set logical inline start padding (left padding) for Box body
   * @defaultValue `"large"`
   */
  paddingInlineStart?: BoxPadding;
  /**
   * Set logical inline end padding (right padding) for Box body
   * @defaultValue `"large"`
   */
  paddingInlineEnd?: BoxPadding;
  /**
   * Background color
   * @defaultValue `undefined`
   */
  bgColor?: BoxBgColors;
}

export type BoxProps<C extends ElementType> = PropsWithChildren<BaseBoxProps<C>>
  & Omit<ComponentPropsWithoutRef<C>, keyof BaseBoxProps<C>> & TaktProps & FlexItemProps;

const Box = <C extends ElementType = 'div'>({
  children,
  flex,
  basis,
  grow,
  shrink,
  renderAs,
  paddingBlockStart,
  paddingBlockEnd,
  paddingInlineStart,
  paddingInlineEnd,
  bgColor,
  taktId,
  taktValue,
  ...rest
}: BoxProps<C>) => (
  <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('box')}>
    {({ getDataTaktAttributes }) => (
      <BoxStyled
        {...getDataTaktAttributes()}
        {...rest}
        $bgColor={bgColor}
        as={renderAs ?? 'div'}
        $flex={flex}
        $basis={basis}
        $grow={grow}
        $shrink={shrink}
        $paddingBlockStart={paddingBlockStart}
        $paddingBlockEnd={paddingBlockEnd}
        $paddingInlineStart={paddingInlineStart}
        $paddingInlineEnd={paddingInlineEnd}
      >
        {children}
      </BoxStyled>
    )}
  </TaktIdConsumer>
  );

Box.propTypes = {
  /**
  * This is the content that will be shown in the Box
  */
  children: PT.node.isRequired,
  /**
  * Set the HTML element that the Box component should be render as
  */
  renderAs: PT.elementType,
  /**
  * Set logical block start padding (top padding) for Box body
  */
  paddingBlockStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
  * Set logical block end padding (bottom padding) for Box body
  */
  paddingBlockEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
  * Set logical inline start padding (left padding) for Box body
  */
  paddingInlineStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
  * Set logical inline end padding (right padding) for Box body
  */
  paddingInlineEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Background color
   */
  bgColor: PT.oneOf([
    'default',
    'offset',
  ]),
}

Box.defaultProps = {
  renderAs: 'div',
  paddingBlockStart: 'large',
  paddingBlockEnd: 'large',
  paddingInlineStart: 'large',
  paddingInlineEnd: 'large',
  bgColor: undefined,
}

export default Box;
