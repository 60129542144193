import {
  KeyboardEvent,
  MouseEvent,
  RefObject,
} from 'react';
import { getElementFromRef, trapTabFocus, keyboardKeynames as keys } from '@amzn/storm-ui-utils';

export interface UseModalParams {
  initialRef: RefObject<HTMLElement>;
  onClose?: (event?: KeyboardEvent<HTMLElement> | MouseEvent<HTMLElement>) => void;
  triggerRef?: RefObject<HTMLElement | undefined> | HTMLElement;
  firstRef?: RefObject<HTMLElement>;
}
export interface UseModal {
  handleKeyUp: (event: KeyboardEvent<HTMLElement>)=> void;
  handleKeyDown: (event: KeyboardEvent<HTMLElement>)=> void;
  handleFocusOnExit: ()=> void;
  handleFocusOnEnter: ()=> void;
}

function useModal(params: UseModalParams):UseModal {
  const {
    initialRef,
    onClose,
    triggerRef,
    firstRef,
  } = params;

  const handleKeyUp = (event: KeyboardEvent<HTMLElement>): void => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();

      if (onClose) {
        onClose(event);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
    if (initialRef.current) {
      trapTabFocus(event, initialRef.current);
    }
  };

  const handleFocusOnExit = (): void => {
    if (triggerRef) {
      const element = getElementFromRef(triggerRef);
      if (element?.focus) {
        element.focus();
      }
    }
  };

  const handleFocusOnEnter = (): void => {
    if (firstRef) {
      const element = getElementFromRef(firstRef);
      if (element?.focus) {
        element.focus();
      }
    }
  };

  return {
    handleKeyUp,
    handleKeyDown,
    handleFocusOnExit,
    handleFocusOnEnter,
  };
}

export default useModal;
