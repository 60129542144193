import { useContext, useCallback } from 'react';
import MobileModeChangeSource from './MobileModeChangeSource';
import MobileMode from './MobileMode';
import MobileThemeContext, { MobileThemeContextProps } from './MobileThemeContext';

// Fake context to cover the case of the hook being used outside the context.
const stubContext = {
  isMobile: false,
  onMobileModeChange: () => undefined,
  browserIsMobile: false,
};

function useSetMobileMode() {
  const {
    isMobile,
    onMobileModeChange,
    browserIsMobile,
  } = ((useContext(MobileThemeContext) as MobileThemeContextProps) ?? stubContext);

  const setMobileMode = useCallback((mobileMode: MobileMode) => {
    onMobileModeChange({
      source: MobileModeChangeSource.User,
      mobileMode,
    });
  }, [onMobileModeChange]);
  return { isMobile, setMobileMode, browserIsMobile };
}

export default useSetMobileMode;
