import React, { FC, MouseEvent, ReactNode } from 'react';
import PT, { Validator } from 'prop-types';
import { TaktProps } from '@amzn/storm-ui';
import { AdvertisingLocale } from '@amzn/storm-ui-utils';
import Root from './Root';
import ContextProvider from '../../contexts/ContextProvider';
import {
  OrientationType,
  ClickEventHandler,
  SelectSingleEventHandler,
  ISODate,
} from '../../types';

export interface DatePickerProps extends TaktProps {
  /**
   * The object used to localize the DatePicker.
   * @defaultValue `undefined`
   */
  locale?: AdvertisingLocale | Locale | (() => Promise<{ default: Locale }>);
  /**
   * Today's date. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  today?: Date | string;
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * @defaultValue `undefined`
   */
  orientation?: OrientationType;
  /**
   * The number of months to display.
   * @defaultValue `undefined`
   */
  numberOfMonths?: number;
  /**
   * The first or only month displayed.
   * @defaultValue `undefined`
   */
  monthInView?: Date;
  /**
   * The earliest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  minDate?: Date | string;
  /**
   * The latest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  maxDate?: Date | string;
  /**
   * The marketplace time zone.
   * @defaultValue `undefined`
   */
  zone?: string;
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   * @defaultValue `undefined`
   */
  isOutsideRange?: ClickEventHandler;
  /**
   * The unique identifier.
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * The date value selected by default. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  date?: Date | string;
  /**
   * Adds a "No end date" preset.
   * @defaultValue `undefined`
   */
  allowNoDate?: boolean;
  /**
   * The function called when the selected date is changed.
   * @defaultValue `undefined`
   */
  onChange?: SelectSingleEventHandler;
  /**
   * The function called if no date is selected.
   * @defaultValue `undefined`
   */
  onNoDateSelect?: (event?: MouseEvent<HTMLButtonElement>) => unknown;
  /**
   * The function called when the visible months are changed.
   * @defaultValue `undefined`
   */
  onVisibleMonthsChange?: (visibleMonths: ISODate[]) => void;
  /**
   * The text displayed if no date is selected.
   * @defaultValue `undefined`
   */
  noDateText?: string;
  /**
   * Is focus on the DatePicker.
   * @defaultValue `undefined`
   */
  focused?: boolean;
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   * @defaultValue `"go to previous month"`
   */
  previousMonthNavButtonLabel?: string;
  /**
   * The localizable aria-label for the calendar's next month nav button.
   * @defaultValue `"go to next month"`
   */
  nextMonthNavButtonLabel?: string;
  /**
   * The prefix for the time zone.
   * @defaultValue `undefined`
   */
  zonePrefix?: string;
  /**
   * The message displayed as a Tooltip over a day before the minDate.
   * @defaultValue `undefined`
   */
  beforeMinDateMessage?: string;
  /**
   * The message displayed as a Tooltip over a day after the maxDate.
   * @defaultValue `undefined`
   */
  afterMaxDateMessage?: string;
  /**
   * The function used to conditionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   * @defaultValue `undefined`
   */
  isDayDisabled?: (day: Date) => boolean | string;
  /**
   * Override the timezone to display a custom node.
   * @defaultValue `undefined`
   */
  renderZoneOverride?: (zone: string) => ReactNode;
}

const DatePicker: FC<React.PropsWithChildren<DatePickerProps>> = props => (
  <ContextProvider {...props} type="single">
    <Root />
  </ContextProvider>
);

DatePicker.propTypes = {
  /**
   * The object used to localize the DatePicker.
   */
  locale: PT.oneOfType(
    [PT.objectOf(PT.any), PT.func],
  ) as Validator<Locale | (() => Promise<{ default: Locale }>)>, // eslint-disable-line react/forbid-prop-types
  /**
   * Today's date. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  today: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   */
  orientation: PT.oneOf(['horizontal', 'vertical']),
  /**
   * The number of months to display.
   */
  numberOfMonths: PT.number,
  /**
   * The first or only month displayed.
   */
  monthInView: PT.instanceOf(Date),
  /**
   * The earliest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  minDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The latest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  maxDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The marketplace time zone.
   */
  zone: PT.string,
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   */
  isOutsideRange: PT.func,
  /**
   * The unique identifier.
   */
  id: PT.string,
  /**
   * The date value selected by default. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  date: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * Adds a "No end date" preset.
   */
  allowNoDate: PT.bool,
  /**
   * The function called when the selected date is changed.
   */
  onChange: PT.func,
  /**
   * The function called if no date is selected.
   */
  onNoDateSelect: PT.func,
  /**
   * The text displayed if no date is selected.
   */
  noDateText: PT.string,
  /**
   * Is focus on the DatePicker.
   */
  focused: PT.bool,
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   */
  previousMonthNavButtonLabel: PT.string,
  /**
   * The localizable aria-label for the calendar's next month nav button.
   */
  nextMonthNavButtonLabel: PT.string,
  /**
   * The message displayed as a Tooltip over a day before the minDate
   */
  beforeMinDateMessage: PT.string,
  /**
   * The message displayed as a Tooltip over a day after the maxDate
   */
  afterMaxDateMessage: PT.string,
  /**
   * The funcion used to confitionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   */
  isDayDisabled: PT.func,
  /**
   * Override the timezone to display a custom node.
   */
  renderZoneOverride: PT.func,
};

DatePicker.defaultProps = {
  locale: undefined,
  today: undefined,
  orientation: undefined,
  numberOfMonths: undefined,
  monthInView: undefined,
  minDate: undefined,
  maxDate: undefined,
  zone: undefined,
  isOutsideRange: undefined,
  id: undefined,
  date: undefined,
  allowNoDate: undefined,
  onChange: undefined,
  onNoDateSelect: undefined,
  noDateText: undefined,
  focused: undefined,
  previousMonthNavButtonLabel: 'go to previous month',
  nextMonthNavButtonLabel: 'go to next month',
  zonePrefix: undefined,
  beforeMinDateMessage: undefined,
  afterMaxDateMessage: undefined,
  isDayDisabled: undefined,
  renderZoneOverride: undefined,
  onVisibleMonthsChange: undefined,
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
