import { css } from 'styled-components';

import focusOutline from './focusOutline';

const focusPseudoElementsAfter = css`
  ::after{
    content:"";
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    ${focusOutline}
    z-index: 1;
    pointer-events: none;
  }
`;

export default focusPseudoElementsAfter;
