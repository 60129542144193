import { createContext, ReactNode, RefObject } from 'react';
import { noop } from '@amzn/storm-ui-utils';

export interface IFileUploadContext {
  disabled?: boolean;
  description?: ReactNode;
  errorText?: string;
  fullWidth?: boolean;
  inputId?: string;
  inputRef: RefObject<HTMLInputElement> | null,
  onCloseAlert?: () => void | undefined;
}

const FileUploadContext = createContext<IFileUploadContext>({
  description: null,
  disabled: false,
  errorText: '',
  fullWidth: false,
  inputId: '',
  inputRef: null,
  onCloseAlert: noop,
});

export default FileUploadContext;
