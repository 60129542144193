import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { Theme } from '../../theme';

export const selectedMixin = (theme: Theme, selected?: boolean, $focused?: boolean) => (selected ? css`
  outline: none;
  background-color: ${theme.dropdown.item.bgActive};
  box-shadow: inset ${theme.dropdown.item.borderWidthActive} 0 0 ${theme.dropdown.item.borderActive};
  ` : css`
  background-color: ${theme.dropdown.item.bg};

  :hover {
    background-color: ${theme.dropdown.item.bgHover};
  }


  ${$focused && css`
    background-color: ${theme.dropdown.item.bgHover};
  `}
`);

export const disabledMixin = (theme: Theme, disabled?: boolean) => (disabled && css`
  color: ${theme.dropdown.item.colorDisabled};
  :hover {
    background-color: ${theme.dropdown.item.bg};
  }
  cursor: not-allowed;
`);

export const noWrapMixin = (noWrap?: boolean) => (noWrap && css`
  /* This is a work around for the way FF and Safari handle adding a scrollbar to a
  * collapsing element. Chrome adds more horizontal space to the element for the scrollbar.
  * FF and Safari subtract horizontal space for the space scrollbar and cause texts to
  * wrap
  */
  white-space: nowrap;
  margin-right: 6px;
`);

interface ItemProps {
  selected?: boolean;
  disabled?: boolean;
  noWrap?: boolean;
  $focused?: boolean;
}

const ItemMixin = css<ItemProps>`
  color: ${({ theme }) => theme.dropdown.item.color};

  ${({ theme, selected, $focused }) => selectedMixin(theme, selected, $focused)}
  ${({ theme, disabled }) => disabledMixin(theme, disabled)}
  ${({ noWrap }) => noWrapMixin(noWrap)}
`;

export const RenderWrapper = styled('span')<ItemProps>`
  display: flex;
  align-items: center;

  padding: 1px 11px 0px 11px;

  ${ItemMixin}
`;

interface SelectButtonProps {
  selected?: boolean;
}

export const SelectButton = styled('button')<SelectButtonProps>`
  background-color: transparent;
  cursor: pointer;
  border: 0;

  :focus {
    outline: none;
  }

  ${({ theme }) => theme.typography.base};
  text-align: start;
  position: relative;
  width: 100%;
  min-height: ${({ theme }) => theme.dropdown.item.minHeight};
  display: list-item;
  list-style-type: none;

  ${isMobile(css<SelectButtonProps>`
    ${({ theme }) => theme.typography.mobile.base};
    min-height: ${({ theme }) => theme.dropdown.item.mobile.minHeight};
    ${({ theme, selected }) => selected && `box-shadow: inset 3px 0 0 ${theme.dropdown.item.borderActive};`}
  `)}
`;

export const StandaloneSelectButton = styled(SelectButton)<ItemProps>`
  && {
    padding: 1px 11px 0px 11px;
  }

  ${ItemMixin}
`;
