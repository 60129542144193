import React, {
  ChangeEvent,
  ReactNode,
  HTMLProps,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { noop } from '@amzn/storm-ui-utils';
import oneIsDefined from '../prop-types/oneIsDefined';
import { RadioGroupProvider } from './RadioGroupContext';
import RadioButtonGroup from './RadioButtonGroup';
import { TaktIdProviderProps } from '../TaktIdContext';

// extends Record<string, unknown>
export interface RadioGroupComponentProps extends TaktIdProviderProps {
  /**
   * This MUST be unique, and it allows the group to manage all children properly.
   */
  name: string;
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel?: boolean;
  /**
   * Positions the legend for the <RadioGroup> inline with the <RadioButton> controls.
   * @defaultValue `false`
   */
  inlineLabel?: boolean;
  /**
   * Callback that is called when the user chooses a different <RadioButton> option.
   * @defaultValue `undefined`
   */
  onChange?: (
    value: string,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  /**
   * Signifies the selected option in the <RadioGroup>
   * @defaultValue `undefined`
   */
  selectedValue?: string;
  /**
   * Label for the RadioGroup
   * @defaultValue `undefined`
   */
  label?: ReactNode;
  /**
   * The string id of a external label element
   * @defaultValue `undefined`
   */
  'aria-labelledby'?: string;
  /**
   * Positions the <RadioButton> components inline instead of stacked.
   * @defaultValue `false`
   */
  inline?: boolean;
  /**
   * Specifies if the radio group is disabled, which disables the entire group if true. If this
   * is false, radio buttons can still be disabled individually.
   * @defaultValue `false`
   */
  disabled?: boolean;
}

const RadioGroupComponent = ({
  children,
  disabled,
  hideLabel,
  inlineLabel,
  inline,
  name,
  label,
  onChange, // removing this, it's handled by bubbled children events
  selectedValue,
  ...rest
}: RadioGroupComponentProps & Omit<HTMLProps<HTMLFieldSetElement>, keyof RadioGroupComponentProps>) => {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    if (onChange && value !== selectedValue) {
      onChange(value, event);
    }
  }

  return (
    <RadioGroupProvider
      handleRadioChange={handleRadioChange}
      isGroupInline={inline}
      isGroupDisabled={disabled}
      name={name}
      selectedValue={selectedValue}
    >
      <RadioButtonGroup
        {...rest}
        inline={inline}
        inlineLabel={inlineLabel}
        label={label}
        disabled={disabled}
        hideLabel={hideLabel}
      >
        {children}
      </RadioButtonGroup>
    </RadioGroupProvider>
  );
}
RadioGroupComponent.defaultProps = {
  hideLabel: false,
  inlineLabel: false,
  onChange: undefined,
  selectedValue: undefined,
  label: undefined,
  'aria-labelledby': undefined,
  inline: false,
  disabled: false,
}

const RadioGroup = styled(RadioGroupComponent)``;
RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  /**
   * The <RadioButton> components to be rendered by the <RadioGroup>.
   */
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node,
  ]).isRequired as React.Requireable<ReactNode[] | ReactNode>,
  ...oneIsDefined({
    /**
     * Label for the RadioGroup
     */
    label: PT.node,
    /**
     * The string id of a external label element
     */
    'aria-labelledby': PT.string,
  }),
  /**
   * This MUST be unique, and it allows the group to manage all children properly.
   */
  name: PT.string.isRequired,
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   */
  hideLabel: PT.bool,
  /**
   * Positions the legend for the <RadioGroup> inline with the <RadioButton> controls.
   */
  inlineLabel: PT.bool,
  /**
   * Callback that is called when the user chooses a different <RadioButton> option.
   */
  onChange: PT.func,
  /**
   * Signifies the selected option in the <RadioGroup>
   */
  selectedValue: PT.string,
  /**
   * Positions the <RadioButton> components inline instead of stacked.
   */
  inline: PT.bool,
};
RadioGroup.defaultProps = {
  hideLabel: false,
  inlineLabel: false,
  selectedValue: '',
  inline: false,
  onChange: noop,
};

export default RadioGroup;
