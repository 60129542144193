import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { AlertMessageTheme, AlertType } from './types';

export interface ColorStyleProps { $type?: string, theme: AlertMessageTheme }
function colorStyle({ $type, theme }: ColorStyleProps) {
  const color = $type ? theme.message[$type]?.color : null;
  return color ? css`color:${color};` : '';
}

export interface StyledMessageHeaderProps extends MergeElementProps<'div'> {
  $type?: AlertType;
}

const StyledMessageHeader = styled('div')<StyledMessageHeaderProps>`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
    padding-bottom: ${({ theme }) => theme.spacing.micro};
    font-weight: 500;
  }
  ${colorStyle}
`;

StyledMessageHeader.displayName = 'StyledMessageHeader';
export default StyledMessageHeader;
