import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  TaktProps,
  TaktIdConsumer,
  Text,
  TextButton,
  createStormTaktId,
} from '@amzn/storm-ui';
import { OptionValue } from './types';
import { PanelWrapper } from './MultiSelect.styles'

const Container = styled(PanelWrapper)`
  display: flex;
  gap: 5px;
  align-items: baseline;
`;

const SelectedText = styled(Text)`
  flex-grow: 1;
`;

export interface MultiSelectSimpleBulkActionPanelProps extends TaktProps {
  /**
   * Used to generate the ids of actionable components
   * @defaultValue `"simpleBulkActionPanel"`
   */
  name: string,
  /**
   * Values of selected options
   */
  selectedValues: OptionValue[],
  /**
   * Label for Select ALL text button
   * @defaultValue `"Select all"`
   */
  bulkActionAllLabel: string,
  /**
   * Label for Clear ALL text button
   * @defaultValue `"Clear all"`
   */
  bulkActionNoneLabel: string,
  /**
   * Function called when Select All text button got clicked
   * @defaultValue `undefined`
   */
  onSelectAll: (event: React.MouseEvent) => void,
  /**
   * Function called when Clear All text button got clicked
   * @defaultValue `undefined`
   */
  onUnSelectAll: (event: React.MouseEvent) => void,
  /**
   * Determine whether to show/hide the bulk action panel
   * @defaultValue `false`
   */
  disabled: boolean,
  /**
   * Threshold to determine whether show SelectAll or ClearAll button
   * If number of selectedValue larger than threshold, show ClearAll
   * @defaultValue `1`
   */
  buttonToggleThreshold: number,
}

/**
 * Bulk Choice Action Panel at the top of dropdown popover.
 * Supports ALL & NONE by defaults.
 * Maintain the ability to allow users to customize the actions
 *
 * @author Zehao Song <zehasong@amazon.com>
 */
export default class MultiSelectSimpleBulkActionPanel
  extends PureComponent<MultiSelectSimpleBulkActionPanelProps> {
  static propTypes = {
    name: PropTypes.string,
    selectedValues: PropTypes.arrayOf(PropTypes.any).isRequired,
    bulkActionAllLabel: PropTypes.string,
    bulkActionNoneLabel: PropTypes.string,
    onSelectAll: PropTypes.func,
    onUnSelectAll: PropTypes.func,
    disabled: PropTypes.bool,
    buttonToggleThreshold: PropTypes.number,
  };

  static defaultProps = {
    name: 'simpleBulkActionPanel',
    onSelectAll: undefined,
    onUnSelectAll: undefined,
    bulkActionAllLabel: 'Select all',
    bulkActionNoneLabel: 'Clear all',
    disabled: false,
    buttonToggleThreshold: 1,
  };

  render(): JSX.Element | null {
    const {
      name,
      disabled,
      selectedValues,
      buttonToggleThreshold,
      bulkActionAllLabel,
      bulkActionNoneLabel,
      onSelectAll,
      onUnSelectAll,
      taktId,
      taktValue,
    } = this.props;

    if (disabled) return null;

    const shouldShowSelectAll = selectedValues.length <= buttonToggleThreshold;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('bulk-action-panel')}>
        {({ getDataTaktAttributes }) => (
          <Container>
            <SelectedText
              textColor="tertiary"
              fontSize="small"
            >
              {selectedValues.length} selected
            </SelectedText>
            <TextButton
              {...getDataTaktAttributes()}
              id={`${name}-Btn`}
              onClick={shouldShowSelectAll ? onSelectAll : onUnSelectAll}
            >
              {shouldShowSelectAll ? bulkActionAllLabel : bulkActionNoneLabel}
            </TextButton>
          </Container>
        )}

      </TaktIdConsumer>
    );
  }
}
