import { css } from 'styled-components';

import focusShadow from './focusShadow';

const focusBorder = css`
  border: ${({
    theme: {
      focusVisible: {
        borderWidth,
        borderStyle,
        borderColor,
      },
    },
  }) => `${borderWidth} ${borderStyle} ${borderColor}`};
  ${focusShadow}
`;

export default focusBorder;
