import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';

export interface MenuItemProps extends MergeElementProps<'li'> {
  $disabled?: boolean;
}

export const MenuItem = styled('li').attrs({ role: 'menuitem' })<MenuItemProps>`
  list-style-type: none;
  text-align: start;
`;
MenuItem.displayName = 'MenuItem';

export interface BaseMenuItemProps {
  $disabled?: boolean;
}

export const BaseMenuItemMixin = css<BaseMenuItemProps>`
  ${({ theme }) => theme.typography.base};
  background-color: ${({ theme }) => theme.dropdown.item.bg};
  box-sizing: border-box;
  min-height: ${({ theme }) => theme.dropdown.item.minHeight};
  padding: 1px 11px 0px 11px;
  position: relative;
  text-align: start;


  @supports selector(:focus-visible) {
    :focus {
      /* Remove the focus indicator on mouse-focus for browsers
         that do support :focus-visible */
      outline: none;
      background-color: ${({ theme }) => theme.dropdown.item.bg};
    }
  }

  :focus-visible {
    background-color: ${({ theme }) => theme.dropdown.item.bgHover};
    outline: none;
  }

  :hover {
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.dropdown.item.bg : theme.dropdown.item.bgHover)};
  }
`;
