/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import {
  commonIconManifest,
  uncommonIconManifest,
  iconLibraryManifest,
} from '@amzn/storm-ui-icons';
import { IconDefinition, IconPrefix } from '../types';
import emptyIcon from './emptyIcon';

/* constants */
const AMAZON_MEDIA_URL_PREFIX = 'https://m.media-amazon.com/images/I/';
const JSON_EXT = '.json';

// Cache for manifest requests
const activeManifestRequests = new Map<
  string,
  Promise<Record<string, string>> | undefined
>();

// Cache for icon requests
const activeIconRequests = new Map<
  string,
  Promise<IconDefinition['icon']> | undefined
>();

// Cache for icon data
const iconCache = new Map<
  string,
  IconDefinition['icon'] | undefined
>();

// export this for the unit test. Do not expose this publicly.
// eslint-disable-next-line no-underscore-dangle
export const _private = {
  activeManifestRequests,
  activeIconRequests,
  iconCache,
};

function fetchIconManifest(iconManifestPhysicalId: string, indicator: string): Promise<Record<string, string>> {
  /* Create a url for the icon manifest */
  const manifestUrl = `${AMAZON_MEDIA_URL_PREFIX}${iconManifestPhysicalId}${JSON_EXT}?icon-library=${indicator}`;
  let request = activeManifestRequests.get(manifestUrl);
  if (!request) {
    try {
      /* If a manifest fetch for this URL does not exist, create it and save it for reuse by other icons */
      request = fetch(manifestUrl)
        .then(response => response.json())
        // Catch any async fetch errors and return a empty manifest
        .catch(err => {
          // Remove this fetch from the list of active ones
          activeManifestRequests.delete(manifestUrl);
          return new Promise((res, rej) => res({}));
        });
      activeManifestRequests.set(manifestUrl, request);
    } catch {
      // If fetch fails, return an empty manifest object
      request = new Promise((res, rej) => res({}));
    }
  }
  return request;
}

function fetchIcon(iconPhysicalId: string, indicator: string): Promise<IconDefinition['icon']> {
  /* Create a url for the icon */
  const iconUrl = `${AMAZON_MEDIA_URL_PREFIX}${iconPhysicalId}${JSON_EXT}?icon=${indicator}`;
  let request = activeIconRequests.get(iconUrl);
  if (!request) {
    try {
      /* If a icon fetch for this URL does not exist, create it and save it for reuse by other icons */
      request = fetch(iconUrl)
        .then(response => response.json())
        // Catch any async fetch errors and return a empty icon
        .catch(err => {
          // Remove this fetch from the list of active ones
          activeIconRequests.delete(iconUrl);
          return new Promise((res, rej) => res(emptyIcon));
        });
      activeIconRequests.set(iconUrl, request);
    } catch {
      // If fetch fails, return an empty icon definition
      request = new Promise((res, rej) => res(emptyIcon));
    }
  }
  return request;
}

function useGetIcon(prefix: 'fas' | 'far' | 'fab', iconName: string): IconDefinition {
  const [icon, setIcon] = useState<IconDefinition['icon']>(
    iconCache.get(`${prefix}/${iconName}`) ?? emptyIcon,
  );

  useEffect(() => {
    let mounted = true;
    if (commonIconManifest[iconName]) {
      iconCache.set(`${prefix}/${iconName}`, commonIconManifest[iconName]);
      if (mounted) {
        setIcon(commonIconManifest[iconName]);
      }
    } else if (
      // Icon fetching is only available in browser environments
      typeof fetch === 'function'
        // Only do a direct icon fetch for icons in the uncommon icon manifest
        && uncommonIconManifest[`${prefix}/${iconName}`]
    ) {
      fetchIcon(uncommonIconManifest[`${prefix}/${iconName}`], iconName).then(iconDef => {
        // Only cache fetched icon definitions with path data
        if (iconDef[4].length > 0) {
          iconCache.set(`${prefix}/${iconName}`, iconDef);
        }
        if (mounted) {
          setIcon(iconDef);
        }
      });
    } else if (
      /* Only run in environments with the fetch */
      typeof fetch === 'function'
      /* Only run if the prefix in in the manifest */
      && prefix in iconLibraryManifest
      /* Only run when the icon is blank ( the icon has not been loaded ) */
      && icon[4] === ''
    ) {
      fetchIconManifest(iconLibraryManifest[prefix], prefix).then(
        iconManifest => {
          if (iconName in iconManifest) {
            return fetchIcon(iconManifest[iconName], iconName).then(iconDef => {
              // Only cache fetched icon definitions with path data
              if (iconDef[4].length > 0) {
                iconCache.set(`${prefix}/${iconName}`, iconDef);
              }
              if (mounted) {
                setIcon(iconDef);
              }
            });
          }
          return null;
        },
      );
    }

    return () => {
      mounted = false;
    };
  }, [prefix, iconName, icon]);

  return {
    prefix: prefix as IconPrefix,
    iconName,
    icon,
  };
}

export default useGetIcon;
