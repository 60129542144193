import { ISODate } from '../types';
import {
  addMonths,
  differenceInCalendarMonths,
  getMonth,
  getYear,
} from './dateUtils';

/** Returns an array of months to display */
const getDisplayMonths = (month: ISODate, numberOfMonths: number): Array<ISODate> => {
  const start: ISODate = `${getYear(month)}-${getMonth(month)}-01`;
  const end = addMonths(start, numberOfMonths);
  const monthsDiff = differenceInCalendarMonths(end, start);
  const months: ISODate[] = [];

  if (monthsDiff === 0) {
    return [start];
  }

  for (let i = 0; i < monthsDiff; i++) {
    const nextMonth = addMonths(start, i);
    months.push(nextMonth);
  }

  return months;
};

export default getDisplayMonths;
