import React from 'react';
import { SelectListClickEventHandler, SelectValue } from './types';

type ISelectListContext = {
  focusedValue?: SelectValue;
  noWrap?: boolean;
  onClick?: SelectListClickEventHandler;
  selectedValue?: SelectValue;
  afterOnSelect?: () => void;
}

const SelectListContext = React.createContext<ISelectListContext>({
  focusedValue: undefined,
  noWrap: false,
  onClick: undefined,
  selectedValue: undefined,
  afterOnSelect: undefined,
});

export default SelectListContext;
