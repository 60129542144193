import React, {
  PureComponent,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
} from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import PT, { Validator } from 'prop-types';
import styled, { css } from 'styled-components';

import focusOutline from '../../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';

const activeStyles = css`
  border-color: ${({ theme }) => theme.button.toggle.borderColorActive};
  background-color: ${({ theme }) => theme.button.toggle.bgActive};
  font-weight: bold;
  a, p {
    font-weight: normal;
  }
`;

const normalStyles = css`
  border-color: ${({ theme }) => theme.button.toggle.borderColor};
  background-color: ${({ theme }) => theme.button.toggle.bg};
  :hover {
    background: ${({ theme }) => theme.button.toggle.bgHover};
  }
`;

export interface StyledToggleProps {
  $active?: boolean;
}

export interface StyledToggleAttrsProps extends StyledToggleProps {
  $focusVisible?: boolean;
}

const StyledToggleButton = styled('button')
  .attrs<StyledToggleProps>(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps({ onFocus, onBlur }, focusProps),
      $focusVisible: isFocusVisible,
    });
  })<StyledToggleAttrsProps>`
  ${({ theme }) => theme.typography.base};
  padding: ${({ theme }) => theme.button.normal.paddingV} 18px;
  border-radius: ${({ theme }) => theme.button.toggle.borderRadius};
  border: 1px solid ${({ theme }) => theme.button.borderColor};
  color: ${({ theme }) => theme.button.toggle.color};
  cursor: ${({ theme }) => theme.button.cursor};
  text-align: center;
  vertical-align: middle;
  user-select: auto;

  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,background-color 100ms ease,border-color 100ms ease, box-shadow 100ms ease;
  }

  ${isMobile(css`
    padding: ${({ theme }) => theme.button.normal.mobile.paddingV} 18px;
    font-size: ${({ theme }) => theme.button.normal.mobile.fontSize};
  `)}

  ${({ $active }) => ($active ? activeStyles : normalStyles)}

  :focus {
    outline: none;
    ${({ $focusVisible }) => ($focusVisible && focusOutline)}
  }

  :disabled {
    pointer-events: all;
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    color: ${({ theme }) => theme.typography.color.tertiary};

    :hover {
      background: ${({ theme, $active }) => ($active ? theme.button.toggle.bgActive : theme.button.toggle.bg)};
    }
  }
`;
StyledToggleButton.displayName = 'StyledToggleButton';

/* SSR workaround for nodejs, things like HTMLButtonElement dont exists in nodejs. */
const NodeSafeHTMLButtonElement = (typeof HTMLButtonElement !== 'undefined' ? HTMLButtonElement : Object) as typeof HTMLButtonElement;

export type ButtonRef = ComponentPropsWithRef<'button'>['ref'];
export interface ToggleButtonProps extends TaktProps, ComponentPropsWithoutRef<'button'> {
  /**
   * @defaultValue `false`
   */
  active?: boolean;
  /**
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * @defaultValue `"button"`
   */
  type?: 'submit' | 'reset' | 'button';
  /**
   * @defaultValue `undefined`
   */
  buttonRef?: ButtonRef;
}

class ToggleButton extends PureComponent<ToggleButtonProps> {
  static propTypes = {
    active: PT.bool,
    disabled: PT.bool,
    type: PT.oneOf(['submit', 'reset', 'button']),
    buttonRef: PT.oneOfType([
      PT.func,
      PT.shape({ current: (PT.instanceOf(NodeSafeHTMLButtonElement)) }) as Validator<ButtonRef>,
    ]),
  }

  static defaultProps = {
    active: false,
    disabled: false,
    type: 'button',
    buttonRef: undefined,
  }

  render(): JSX.Element {
    const {
      active,
      disabled,
      type,
      buttonRef,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('toggle-button')}>
        {({ getDataTaktAttributes }) => (
          <StyledToggleButton
            {...getDataTaktAttributes()}
            {...rest}
            ref={buttonRef}
            $active={active}
            role="switch"
            aria-checked={active}
            disabled={disabled}
            type={type}
          />
        )}
      </TaktIdConsumer>
    );
  }
}

export default ToggleButton;
