import React, {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  SelectList,
  SelectOption,
  createStormTaktId,
  isMobileStyleMixin,
  useTaktId,
} from '@amzn/storm-ui-v3';
import { Locale } from 'date-fns';
import styled, { css } from 'styled-components';
import { ISODate } from '../types';
import { addMonths, getYear, monthToISO } from '../utils/dateUtils';
import useDatePicker from '../hooks/useDatePicker';

const YEARS_BEFORE = 9;
const YEARS_AFTER = 2;

const getMonthsAndYears = (today: ISODate, locale: Locale, min?: ISODate, max?: ISODate) => {
  const allMonths: string[] = [];
  for (let i = 0; i < 12; i++) {
    allMonths.push(locale.localize?.month(i));
  }
  const earliestYear = min ? Number(getYear(min)) : Number(getYear(today)) - YEARS_BEFORE;
  const latestYear = max ? Number(getYear(max)) : Number(getYear(today)) + YEARS_AFTER;
  const allYears: number[] = [];
  for (let i = latestYear; i >= earliestYear; i--) {
    allYears.push(i);
  }
  return {
    months: allMonths,
    years: allYears,
  };
};

const ListWrapper = styled('div')`
  padding: 8px 0;
  display: flex;
  width: 200px;
  height: 315px;
  ${isMobileStyleMixin(css`
    justify-content: center;
    width: 100%;
  `)}
`;

const StaticContainer = styled('div')`
  width: 50%;
  ${isMobileStyleMixin(css`
    overflow: auto;
  `)}
`;

const ScrollContainer = styled('div')`
  width: 50%;
  overflow: auto;
`;

interface CalendarDateSelectorProps {
  locale: Locale;
  calendarIndex: number;
  onApply: (desiredDate: ISODate) => void;
  minDate?: ISODate;
  maxDate?: ISODate;
  today: ISODate;
}

const CalendarDateSelector: FC<React.PropsWithChildren<CalendarDateSelectorProps>> = ({
  locale,
  calendarIndex,
  onApply,
  minDate,
  maxDate,
  today,
}) => {
  const { taktId, taktValue } = useDatePicker();
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-picker-calendar-date-selector') });

  const [selected, setSelected] = useState<{ month?: string, year?: string }>(
    { month: undefined, year: undefined },
  );

  const { months, years } = useMemo(
    () => getMonthsAndYears(today, locale, minDate, maxDate),
    [locale, maxDate, minDate, today],
  );

  const selectAndClose = useCallback((selectedMonth?: string, selectedYear?: string) => {
    setSelected({ month: selectedMonth, year: selectedYear });
    if (selectedMonth && selectedYear) {
      onApply(addMonths(`${selectedYear}-${monthToISO(Number(selectedMonth) + 1)}-01`, -calendarIndex));
    }
  }, [calendarIndex, onApply]);

  return (
    <>
      <ListWrapper>
        <StaticContainer>
          <SelectList
            selectedValue={selected.month}
            onChange={val => selectAndClose(val, selected.year)}
          >
            {months.map((month, index) => (
              <SelectOption
                {...getDataTaktAttributes({ taktValue, contextOnlyTaktValues: { selectedMonth: `${String(index)}` } })}
                value={String(index)}
                key={month}
              >
                {month}
              </SelectOption>
            ))}
          </SelectList>
        </StaticContainer>
        <ScrollContainer>
          <SelectList
            selectedValue={selected.year}
            onChange={val => selectAndClose(selected.month, val)}
          >
            {years.map(year => (
              <SelectOption
                {...getDataTaktAttributes({ taktValue, contextOnlyTaktValues: { selectedYear: `${String(year)}` } })}
                value={String(year)}
                key={year}
              >
                {year}
              </SelectOption>
            ))}
          </SelectList>
        </ScrollContainer>
      </ListWrapper>
    </>
  );
};

CalendarDateSelector.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
};

export default CalendarDateSelector;
