import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import Text, { TextProps } from '../../Text/Text';

const Title = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export interface DefaultTitleProps extends Omit<TextProps, 'ref'> {
  /**
   * String used for the title text.
   */
  label: string;
}

const DefaultTitle: FC<React.PropsWithChildren<DefaultTitleProps>> = ({
  label,
  ...rest
}: DefaultTitleProps): JSX.Element => (
  <Title {...rest} fontSize="base">
    { label }
  </Title>
);

export default DefaultTitle;
