import React, { SyntheticEvent } from 'react';
import { attach } from '@amzn/storm-ui-icons-v3';
import styled from 'styled-components';
import { useIntl } from '@amzn/storm-ui-intl-v3';
import { FileUploadButton } from '../FileUpload';
import Tooltip from '../Tooltip';
import { useTaktId } from '../TaktIdContext';

const StyledUploadButton = styled(FileUploadButton)`
  && + div > button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    padding: unset;

    :hover {
      background: none;
    }

    > i {
      color: ${({ theme }) => theme.palette.gray[500]};
    }

  }
`;

export interface FileAttachmentButtonProps {
  id: string;
  onChange?: (event: SyntheticEvent, files: File[]) => void;
  disabled?: boolean;
  acceptedAttachmentTypes?: string[];
}

const FileAttachmentButton = ({
  id,
  onChange,
  disabled,
  acceptedAttachmentTypes,
}: FileAttachmentButtonProps) => {
  const { formatMessage } = useIntl();
  const { getDataTaktAttributes } = useTaktId({ fallbackId: 'attachment-button' });
  return (
    <Tooltip
      nonInteractive
      inert={disabled}
      trigger={(
        <span aria-label={formatMessage({ id: 'action-attach' })}>
          <StyledUploadButton
            buttonProps={{ ...getDataTaktAttributes() }}
            transparent
            inputId={id}
            label=""
            hideLabel
            icon={attach}
            onChange={onChange}
            disabled={disabled}
            multiple
            accept={acceptedAttachmentTypes}
          />
        </span>
        )}
      message={formatMessage({ id: 'action-attach' })}
    />
  );
};

export default FileAttachmentButton;
