import React, { useEffect } from 'react';
import styled from 'styled-components';
import useDatePicker from '../../hooks/useDatePicker';
import useNavigation from '../../hooks/useNavigation';
import { ISODate } from '../../types';
import CalendarMonth from '../CalendarMonth';
import CalendarMonthHeader from '../CalendarMonthHeader';

export interface MonthFlexProps {
  $orientation: 'row' | 'column';
  $loading: boolean;
}
const DateRangePickerMonthFlex = styled('div')<MonthFlexProps>`
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  display: flex;
  flex-direction: ${({ $orientation }) => $orientation};
`;

const CalendarWrapper = styled('div')`
  flex-direction: column;
  padding: 0 13px;
`;

const renderMonth = (month: ISODate, index: number) => (
  <CalendarWrapper key={index}>
    <CalendarMonthHeader displayMonth={month} index={index} />
    <CalendarMonth displayMonth={month} />
  </CalendarWrapper>
);

const Root = (): JSX.Element => {
  const {
    orientation = 'horizontal',
    id,
    loading,
    onVisibleMonthsChange,
  } = useDatePicker();
  const { displayMonths } = useNavigation();

  useEffect(() => {
    onVisibleMonthsChange?.(displayMonths);
  }, [displayMonths, onVisibleMonthsChange]);

  return (
    <DateRangePickerMonthFlex
      id={id}
      $orientation={orientation === 'vertical' ? 'column' : 'row'}
      $loading={loading}
    >
      {displayMonths.map(renderMonth)}
    </DateRangePickerMonthFlex>
  );
};

export default Root;
