import React, { FC } from 'react';
import PaginationButton, { PaginationNavProps } from './PaginationButton';
import { createStormTaktId } from '../../TaktIdContext';
import LastPageIcon from './icons/LastPageIcon';

const PaginationLastButton: FC<React.PropsWithChildren<PaginationNavProps>> = (
  props: PaginationNavProps,
): JSX.Element => (
  <PaginationButton taktFallbackId={createStormTaktId('pagination-last-button')} {...props}>
    <LastPageIcon />
  </PaginationButton>
);
PaginationLastButton.displayName = 'PaginationLastButton';

export default PaginationLastButton;
