import React, { FC } from 'react';
import { format } from 'date-fns';
import useDatePicker from '../../hooks/useDatePicker';

/**
 * Returns the abbreviated name of the given weekday
 * 1/1/2000 return Sa
 */
const FormatWeekday: FC<React.PropsWithChildren<{ weekday: Date }>> = ({ weekday }) => {
  const { locale } = useDatePicker();
  return (
    <>{format(weekday, 'cccccc', { locale })}</>
  );
};

export default FormatWeekday;
