import { angleDown } from '@amzn/storm-ui-icons-v3';
import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import Icon from '../../Icon';
import Text from '../../Text';

const PositionedIcon = styled(Icon)`
  margin-inline-start: ${({ theme }) => theme.spacing.mini};
`;

export interface DefaultFooterProps {
  /**
   * String used for the text in the footer button.
   */
  label: string;
}

const DefaultFooter: FC<React.PropsWithChildren<DefaultFooterProps>> = ({
  label,
}: DefaultFooterProps): JSX.Element => (
  <Text>
    { label }
    <PositionedIcon type={angleDown} />
  </Text>
);

export default DefaultFooter;
