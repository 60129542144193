import { Locale } from 'date-fns';

const localeMap: Record<string, () => Promise<{ default: Locale }>> = {
  'ar-AE': () => import('date-fns/locale/ar'),
  'de-DE': () => import('date-fns/locale/de'),
  'en-AU': () => import('date-fns/locale/en-AU'),
  'en-CA': () => import('date-fns/locale/en-CA'),
  'en-GB': () => import('date-fns/locale/en-GB'),
  'en-IN': () => import('date-fns/locale/en-IN'),
  'en-US': () => import('date-fns/locale/en-US'),
  'en-SG': () => import('date-fns/locale/en-US'),
  'es-CO': () => import('date-fns/locale/es'),
  'es-ES': () => import('date-fns/locale/es'),
  'es-MX': () => import('date-fns/locale/es'),
  'es-US': () => import('date-fns/locale/es'),
  'fr-CA': () => import('date-fns/locale/fr-CA'),
  'fr-FR': () => import('date-fns/locale/fr'),
  'hi-IN': () => import('date-fns/locale/hi'),
  'it-IT': () => import('date-fns/locale/it'),
  'ja-JP': () => import('date-fns/locale/ja'),
  'ko-KR': () => import('date-fns/locale/ko'),
  'nl-NL': () => import('date-fns/locale/nl'),
  'pl-PL': () => import('date-fns/locale/pl'),
  'pt-BR': () => import('date-fns/locale/pt'),
  'ta-IN': () => import('date-fns/locale/ta'),
  'th-TH': () => import('date-fns/locale/th'),
  'tr-TR': () => import('date-fns/locale/tr'),
  'sv-SE': () => import('date-fns/locale/sv'),
  'vi-VN': () => import('date-fns/locale/vi'),
  'zh-CN': () => import('date-fns/locale/zh-CN'),
  'zh-TW': () => import('date-fns/locale/zh-TW'),
};

const getLazyLocale = (request: string): () => Promise<{ default: Locale }> => localeMap[request] ?? localeMap['en-US'];

export default getLazyLocale;
