const currencySymbols: { [code: string]: string } = {
  USD: '$', // US Dollar
  CAD: '$', // Canadian Dollar
  EUR: '€', // Euro
  GBP: '£', // British Pound Sterling
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  CNY: '¥', // Chinese Yuan
  MXN: '$', // Mexican Peso
  AUD: '$', // Australian Dollar
  AED: 'AED', // United Arab Emirates Dirham
  SAR: 'SAR', // Saudi Arabian Riyal
  BRL: 'R$', // Brazilian Real
  SGD: 'S$', // Singapore Dollar
  SEK: 'kr', // Swedish krona
  TRY: 'TL', // Turkish lira
  PLN: 'zł', // Polish złoty
  EGP: 'EGP', // Egyptian pound
  CZK: 'Kč', // Czech Koruna
  ILS: '₪', // Israeli shekel
  THB: '฿', // Thai Baht
  VND: '₫', // Vietnamese dong
  ZAR: 'R', // South African Rand
};

export default function getCurrencySymbol(currencyCode: string): string {
  return currencySymbols[currencyCode] || currencyCode;
}
