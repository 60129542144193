import React, { FC } from 'react';
import { Text } from '@amzn/storm-ui-v3';
import { ISODate } from '../../types';
import { getDay } from '../../utils/dateUtils';

/**
 * Returns the number of the given day
 * 1/1/2000 returns 1
 */
const FormatDay: FC<React.PropsWithChildren<{ day: ISODate }>> = ({ day }) => <Text type="span">{Number(getDay(day))}</Text>;

export default FormatDay;
