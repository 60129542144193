import React, {
  createContext, useContext, ReactNode, ChangeEvent, Ref,
} from 'react';
import type { FocusRingAria } from '@react-aria/focus';
import type { TileDirection, TileInputValue } from '../types';
import type { DataTaktAttributes } from '../../TaktIdContext';

export type TileControlContextType = {
  focusProps: undefined | FocusRingAria['focusProps'];
  label: string;
  onChange: undefined | ((event: ChangeEvent<HTMLInputElement>) => void);
  value: undefined | TileInputValue;
  dataTaktAttributes: DataTaktAttributes;
  innerRef: undefined | Ref<HTMLInputElement>;
  restProps: Record<string, unknown>;
}

const TileControlContext = createContext<TileControlContextType>({
  focusProps: undefined,
  label: '',
  onChange: undefined,
  value: undefined,
  dataTaktAttributes: {},
  innerRef: undefined,
  restProps: {},
});

export const useTileControlContext = () => useContext(TileControlContext)

export interface TileControlContextProvider {
  focusProps: FocusRingAria['focusProps'];
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: TileInputValue;
  dataTaktAttributes: DataTaktAttributes;
  innerRef: Ref<HTMLInputElement> | undefined;
  restProps: Record<string, any>;
  children: ReactNode;
}

export const TileControlContextProvider = ({
  focusProps,
  label,
  onChange,
  value,
  dataTaktAttributes,
  innerRef,
  restProps,
  children,
}: TileControlContextProvider) => (
  <TileControlContext.Provider
    value={{
      focusProps,
      label,
      onChange,
      value,
      dataTaktAttributes,
      innerRef,
      restProps,
    }}
  >
    {children}
  </TileControlContext.Provider>
);

export type TileContextType = {
  direction: TileDirection;
  disabled: boolean;
  selected: boolean;
}

export const DEFAULT_TILE_CONTEXT: TileContextType = {
  direction: 'column',
  disabled: false,
  selected: false,
};

const TileContext = createContext<TileContextType>(DEFAULT_TILE_CONTEXT);

export interface TileContextProvider {
  direction: TileDirection;
  disabled: boolean;
  selected: boolean;
  children: ReactNode;
}

export const TileContextProvider = ({
  direction,
  disabled,
  selected,
  children,
}: TileContextProvider) => (
  <TileContext.Provider value={{ direction, disabled, selected }}>
    {children}
  </TileContext.Provider>
);

export const useTileContext = () => {
  const {
    direction,
    disabled,
    selected,
  } = useContext(TileContext);

  return {
    direction,
    disabled,
    selected,
  };
}
