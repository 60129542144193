/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "ரத்துசெய்"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "சேமி"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "தனிப்பயன்"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "நாளைத் தேர்ந்தெடுக்கவும்"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "மாதத்தைத் தேர்ந்தெடுக்கவும்"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "வாரத்தைத் தேர்ந்தெடுக்கவும்"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "கடந்த 30 நாட்கள்"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "கடந்த 7 நாட்கள்"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "கடந்த மாதம்"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "கடந்த வாரம்"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "வாழ்நாள்"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "இந்த வாரம்"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "இன்று"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "ஆண்டு தொடக்கம் முதல் இன்றுவரை"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "நேற்று"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
