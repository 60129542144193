import { palette } from '../base/color';
import { link, typography } from '../base/typography';
import { icon } from './icon';
import { focus } from '../base/focus';
import inputStyles from './input';
import labelStyles from './label';

export const surfaceStyles = {
  backgroundColor: palette.white,
};

/*
 * All Surfaces should use this type in order to prevent any style conflicts of nested Surfaces.
 * If one Surface were to override a property that others don't, it can lead to issues.
 */
export type SurfaceThemeOverrides = {
  link: Pick<
    typeof link,
    'color' | 'hoverColor' | 'decoration' | 'overrideAuiLinkStyle'
  >;
  surface: typeof surfaceStyles;
  typography: Pick<typeof typography, 'a'> & {
    body: Pick<(typeof typography)['body'], 'bodyFontColor'>;
    color: Pick<(typeof typography)['color'], 'base'>;
  };
  focusVisible: Pick<
    typeof focus,
    'borderColor' | 'outlineColor' | 'boxShadowColor'
  >;
  icon: Pick<typeof icon, 'color' | 'hover'>;
  form: {
    input: Pick<(typeof inputStyles), 'bg'>;
    label: Pick<(typeof labelStyles), 'color'>;
  };
};
