import { palette } from '../base/color';
import { spacing, mobileSpacing } from '../base/spacing';
import { fontSizes, mobileFontSizes, link } from '../base/typography';

export const tagStyles = {
  fontSize: fontSizes.tiny,
  fontWeight: 'bold',
  letterSpacing: '1px',
  lineHeight: '12px',
  padding: '0 3px 1px 3px',
  radius: '1px',
  bottomOffset: '2px',
  backgroundDefault: link.color,
  backgroundNew: '#018485',
  backgroundBeta: palette.gray[700],
  backgroundInfo: palette.blue[700],
  backgroundError: palette.red[600],
  backgroundWarning: palette.clementine[700],
  backgroundSuccess: palette.green[700],
  color: palette.white,
  margin: `0 ${spacing.mini}`,
};

export const mobileTagStyles = {
  mobile: {
    fontSize: mobileFontSizes.tiny,
    lineHeight: '14px',
    margin: `0 ${mobileSpacing.mini}`,
  },
};
