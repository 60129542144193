/* eslint-disable id-length */
import { palette } from '../base/color';

const secondaryView = {
  bg: palette.white,
  header: {
    bg: palette.gray[50],
    border: {
      color: palette.gray[200],
    },
  },
  closeButton: {
    color: palette.squidInk,
  },
  closeIcon: {
    color: palette.gray[700],
  },
};

export default secondaryView;
