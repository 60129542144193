import { SVGTransformationMap } from '../types';
import parseTransformString from './parseTransformString';
import transformIsMeaningful from './transformIsMeaningful';

/** Converts the given transform string into an object containing CSS for the svg groups */
const getSVGTransformer = (
  width: number, transformString?: string,
): SVGTransformationMap | null => {
  const transform = parseTransformString(transformString);
  if (transform && transformIsMeaningful(transform)) {
    const outer = {
      transform: `translate(${String(width / 2)} 256)`,
    };
    const innerTranslate = `translate(${String(transform.x * 32)}, ${String(transform.y * 32)})`;
    const innerScale = `scale(${String((transform.size / 16) * (transform.flipX ? -1 : 1))}, ${String((transform.size / 16) * (transform.flipY ? -1 : 1))})`;
    const innerRotate = `rotate(${String(transform.rotate)} 0 0)`;
    const inner = {
      transform: `${innerTranslate} ${innerScale} ${innerRotate}`,
    };
    const path = {
      transform: `translate(${String((width / 2) * -1)} -256)`,
    };
    return {
      outer,
      inner,
      path,
    };
  }
  return null;
};

export default getSVGTransformer;
