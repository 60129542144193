import React from 'react';
import PropTypes from 'prop-types';
import type { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { createStormTaktId, useTaktId } from '../TaktIdContext';
import { TaktProps } from '../types/TaktProps';

export interface InputLabelProps extends TaktProps, ComponentPropsWithRef<'label'> {
  /**
   * The string `id` of the input associated with this label.
   */
  htmlFor: string;
}

const Label = styled('label')<InputLabelProps>`
  display: inline;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.form.label.color};
`;

Label.propTypes = {
  /**
   * The string `id` of the input associated with this label.
   */
  htmlFor: PropTypes.string.isRequired,
};

const InputLabel: React.FC<InputLabelProps> = ({ taktId, taktValue, ...props }): JSX.Element => {
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('input-label') });
  return (
    <Label
      {...getDataTaktAttributes({ taktValue })}
      {...props}
    />
  )
}

export default InputLabel;
