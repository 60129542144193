import { Transform } from '../types';

/** Empty transform object with no transformation properties */
const meaninglessTransform: Transform = {
  size: 16,
  x: 0,
  y: 0,
  flipX: false,
  flipY: false,
  rotate: 0,
};

export default meaninglessTransform;
