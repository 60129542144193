import React from 'react';
import { ChildBox, ChildBoxProps } from './Tile.styles';
import { useTileContext } from './TileContext';

export interface TileBoxProps extends Omit<ChildBoxProps, '$direction' | '$hideStartBorder'> {
  hideStartBorder?: ChildBoxProps['$hideStartBorder'];
}

const TileBox = ({
  hideStartBorder,
  ...restProps
}: TileBoxProps) => {
  const {
    direction,
  } = useTileContext();

  return (
    <ChildBox
      {...restProps}
      $hideStartBorder={hideStartBorder}
      $direction={direction}
    />
  );
}

export default TileBox;
