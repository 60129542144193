/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Abbrechen"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Speichern"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Benutzerdefiniert"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Einen bestimmten Tag auswählen"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Einen Monat auswählen"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Eine Woche auswählen"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Letzte 30 Tage"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Letzte 7 Tage"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Letzter Monat"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Letzte Woche"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Laufzeit"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Diese Woche"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Heute"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Jahr bis zum aktuellen Datum"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Gestern"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
