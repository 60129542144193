/* eslint-disable react/require-default-props */
import React, {
  createContext, FC, ReactNode, useContext,
} from 'react';
import { Locale } from 'date-fns';
import { AdvertisingLocale } from '@amzn/storm-ui-utils-v3';

export type DateLocalizationContextValue = {
  locale?: AdvertisingLocale | Locale | (() => Promise<{ default: Locale }>);
  zone?: string;
};

const DateLocalizationContext = createContext<DateLocalizationContextValue | undefined>(undefined);

export interface DateLocalizationProps extends DateLocalizationContextValue {
  children: ReactNode;
}

/**
 * Can be used by customers to provide the same locale to all child DatePickers
 */
const DateLocalizationProvider: FC<React.PropsWithChildren<DateLocalizationProps>> = ({ children, locale, zone }) => (
  <DateLocalizationContext.Provider value={{ locale, zone }}>
    {children}
  </DateLocalizationContext.Provider>
);

// eslint-disable-next-line max-len
const useDateLocalization = (): DateLocalizationContextValue | undefined => useContext(DateLocalizationContext);

export default DateLocalizationProvider;
export { useDateLocalization };
