/* eslint-disable no-nested-ternary */
import React, { FC, useRef } from 'react';
import { Locale } from 'date-fns';
import styled, { css } from 'styled-components';
import {
  createStormTaktId,
  isMobileStyleMixin,
  Theme,
  Tooltip,
  useTaktId,
} from '@amzn/storm-ui-v3';
import useDay from '../hooks/useDay';
import { DayModifiers, ISODate } from '../types';
import { isSameMonth } from '../utils/dateUtils';
import { FormatDay } from './formatters';
import useDatePicker from '../hooks/useDatePicker';
import { startOfDayAtTz } from '../utils/timezoneUtils';

export interface DayButtonProps {
  $modifiers: DayModifiers;
}

/** Gets the background-color based on modifiers */
function getBackgroundColor(theme: Theme, modifiers: DayModifiers): string {
  if (modifiers.selected) return theme.daySelectedBg;
  if (modifiers.range) return theme.rangeSelectedBg;
  if (modifiers.hovered) return theme.rangeSelectedBg;
  if (modifiers.potential) return theme.dayHoverBg;
  if (modifiers.today) return theme.dayTodayBg;
  return theme.dayBg;
}

function getColor(theme: Theme, modifiers: DayModifiers): string {
  if (modifiers.selected) return theme.daySelectedColor;
  if (modifiers.range) return theme.rangeSelectedColor;
  if (modifiers.hovered) return theme.rangeSelectedColor;
  return theme.dayColor;
}

const DayButton = styled('button')<DayButtonProps>`
  background-color: ${({
    theme, $modifiers,
  }) => getBackgroundColor(theme.datepicker, $modifiers)};
  border: ${({ theme }) => theme.datepicker.dayBorderWidth} solid ${({ theme }) => theme.datepicker.dayBorderColor};
  border-width: 1px;
  color: ${({
    theme, $modifiers,
  }) => getColor(theme.datepicker, $modifiers)};
  width: 30px;
  height: 29px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${isMobileStyleMixin(css`
    width: 40px;
    height: 39px;
  `)}

  :hover {
    background-color: ${(
    {
      theme,
      $modifiers,
    },
  ) => (!$modifiers.selected && !$modifiers.range && !$modifiers.hovered ? theme.datepicker.dayHoverBg : null)};
    border: ${({ theme }) => theme.datepicker.dayBorderWidth} solid ${({ theme }) => theme.datepicker.dayBorderColor};
    border-width: 1px;
  }

  > * {
    pointer-events: none;
  }
`;

const DisabledDay = styled('button')`
  border-width: 1px;
  border: ${({ theme }) => theme.datepicker.dayBorderWidth} solid ${({ theme }) => theme.datepicker.dayBorderColor};
  width: 30px;
  height: 29px;
  box-sizing: border-box;
  cursor: not-allowed;
  background: ${({ theme }) => theme.datepicker.dayBlockedBg};
  color: ${({ theme }) => theme.datepicker.dayBlockedColor};
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${isMobileStyleMixin(css`
    width: 40px;
    height: 39px;
  `)}
`;

const getDisabledMessage = (
  modifiers: DayModifiers,
  dayMessage?: string,
  beforeMessage?: string,
  afterMessage?: string,
): string | null => {
  if (dayMessage) return dayMessage;
  if (modifiers.before && beforeMessage) return beforeMessage;
  if (modifiers.after && afterMessage) return afterMessage;
  return null;
};

export interface CalendarDayProps {
  date: ISODate;
  displayMonth: ISODate;
  locale: Locale;
}

const CalendarDay: FC<React.PropsWithChildren<CalendarDayProps>> = props => {
  const { date, displayMonth } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    beforeMinDateMessage,
    afterMaxDateMessage,
    isDayDisabled,
    zone,
    taktId,
    taktValue,
  } = useDatePicker();

  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-picker-day') });

  const dayDisabled = isDayDisabled?.(startOfDayAtTz(date, zone));

  const { buttonProps, modifiers } = useDay(date, buttonRef, { disabled: !!dayDisabled }, displayMonth);

  if (isSameMonth(date, displayMonth)) {
    if (modifiers.disabled) {
      const dayMessage = typeof dayDisabled === 'string' ? dayDisabled : undefined;
      const disabledMessage = getDisabledMessage(modifiers, dayMessage, beforeMinDateMessage, afterMaxDateMessage);
      return (
        <Tooltip
          trigger={(
            <DisabledDay
              {...buttonProps}
              ref={buttonRef}
              type="button"
              {...getDataTaktAttributes({ taktValue, contextOnlyTaktValues: { date, disabled: true } })}
            >
              <FormatDay day={date} />
            </DisabledDay>
          )}
          message={disabledMessage}
          inert={!disabledMessage}
          type="dark"
          nonInteractive
        />
      )
    }

    return (
      <DayButton
        ref={buttonRef}
        type="button"
        $modifiers={modifiers}
        data-iso-date={date}
        {...buttonProps}
        {...getDataTaktAttributes({ taktValue, contextOnlyTaktValues: { date } })}
      >
        <FormatDay day={date} />
      </DayButton>
    );
  }

  return null;
};

export default CalendarDay;
