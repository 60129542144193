/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "रद्द करें"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "सेव करें"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "कस्टम"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "एक दिन चुनें"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "महीना चुनें"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "सप्ताह चुनें"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "पिछले 30 दिन"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "पिछले 7 दिन"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "पिछला महीना"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "पिछला हफ़्ता"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "लाइफ़टाइम"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "इस हफ़्ते"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "आज"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "इस साल आज की तारीख तक"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "बीता कल"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
