import { palette } from '../base/color';

export const rangeSlider = {
  railBackground: palette.gray[200],
  trackStyleBackground: palette.blue[700],
  dotStyleDisplay: 'none',
  handleBg: palette.blue[700],
  handleBgHover: palette.blue[800],
  handleBgActive: palette.blue[900],
  handleStyleBorder: 'none',
  handleBorderDragging: 'rgb(0 115 199 / 0.4)',
  handleShadowDragging: '0 0 0 3px rgb(0 115 199 / 0.4)',
};

export default rangeSlider;
