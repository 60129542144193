import styled, { css } from 'styled-components';

interface StepsWrapper {
  $isVertical: boolean;
}
export const StepsWrapper = styled('ol')<StepsWrapper>`
  display: flex;
  margin: 0;

  ${({ $isVertical }) => $isVertical && css`
    flex-direction: column;
  `}
`;

export default StepsWrapper;
