import React, { FC } from 'react';
import { createStormTaktId } from '../../TaktIdContext';
import FirstPageIcon from './icons/FirstPageIcon';
import PaginationButton, { PaginationNavProps } from './PaginationButton';

const PaginationFirstButton: FC<React.PropsWithChildren<PaginationNavProps>> = (
  props: PaginationNavProps,
): JSX.Element => (
  <PaginationButton taktFallbackId={createStormTaktId('pagination-first-button')} {...props}>
    <FirstPageIcon />
  </PaginationButton>
);
PaginationFirstButton.displayName = 'PaginationFirstButton';

export default PaginationFirstButton;
