/* eslint-disable id-length */
import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  Card, isMobileStyleMixin, TaktProps, Text, useIsMobile,
} from '@amzn/storm-ui';
import { IntlProvider, useIntl } from '@amzn/storm-ui-intl';
import useDatePicker from '../../hooks/useDatePicker';
import useNavigation from '../../hooks/useNavigation';
import CalendarMonth from '../CalendarMonth';
import CalendarMonthHeader from '../CalendarMonthHeader';
import DateRangePresetSelector from './DateRangePresetSelector';
import DateRangeConfirmationControl, { DateRangeConfirmationControlProps } from './DateRangeConfirmationControl';
import useDateRange from '../../hooks/useDateRange';
import { ISODate } from '../../types';
import { getTranslationComponent } from '../../i18n/DatepickerTranslation/static';
import dateFnsLocaleMap from '../../dateFnsLocaleMap';

interface DateRangePickerOuterWrapperProps {
  $loading: boolean;
}
const DateRangePickerOuterWrapper = styled('div')<DateRangePickerOuterWrapperProps>`
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  display: flex;
  ${isMobileStyleMixin(css`
    align-items: center;
    flex-direction: column;
  `)}
`;

const MobileContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  background: ${({ theme }) => theme.datepicker.bg};
  width: 100%;
`;

const MobileHeader = styled('div')`
  background: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.spacing.mini};
  text-align: start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};

  ${isMobileStyleMixin(css`
    padding: ${({ theme }) => theme.mobile.spacing.mini};
  `)}
`;

const MobileBody = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  padding: 6px;

  ${isMobileStyleMixin(css`
    padding: 10px;
  `)}
`;

const InnerMobileFlex = styled('div')`
  width: 305px;
`;

const Zone = styled('p')`
  ${({ theme }) => theme.typography.base};
  color: ${({ theme }) => theme.datepicker.timeZoneColor};
  margin: 0;
  padding-inline-start: 11px;
  padding-inline-end: 11px;
  padding-block-start: 11px;
  padding-block-end: 0;
  text-align: left;
  font-size: ${({ theme }) => theme.typography.size.mini};

  ${isMobileStyleMixin(css`
    padding-inline-start: 0;
    padding-block-start: 0;
    font-size: ${({ theme }) => theme.typography.mobile.size.mini};
  `)}
`;

const DateRangePickerInnerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export interface MonthFlexProps {
  $orientation: 'row' | 'column';
}
const DateRangePickerMonthFlex = styled('div')<MonthFlexProps>`
  display: flex;
  flex-direction: ${({ $orientation }) => $orientation};
`;

const CalendarWrapper = styled('div')`
  flex-direction: column;
  padding: 0 13px;
`;

const renderMonth = (month: ISODate, index: number) => (
  <CalendarWrapper key={index}>
    <CalendarMonthHeader displayMonth={month} index={index} />
    <CalendarMonth displayMonth={month} />
  </CalendarWrapper>
);

interface ConfirmationControlProps extends DateRangeConfirmationControlProps, TaktProps {}

/**
 * This wrapper component is needed to wrap
 * the DateRangeConfirmationControl in an IntlProvider
 */
const ConfirmationControlWrapper = (props: ConfirmationControlProps) => {
  const {
    saveText,
    cancelText,
    ...rest
  } = props;
  const { formatMessage } = useIntl();
  return (
    <DateRangeConfirmationControl
      {...rest}
      cancelText={cancelText ?? formatMessage({ id: 'button_cancel' })}
      saveText={saveText ?? formatMessage({ id: 'button_save' })}
    />
  )
}

const Root: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    id,
    zone,
    zonePrefix,
    cancelText,
    saveText,
    orientation,
    dropdownLabelOverride,
    loading,
    renderZoneOverride,
    locale,
    taktId,
    taktValue,
    onVisibleMonthsChange,
  } = useDatePicker();
  const {
    selected,
    handleOnSubmit,
    handleOnCancel,
    presetRanges,
    handlePresetSelect,
    selectedPreset,
    multiDateSelectors,
    multiDateSelectorsLabel,
    handleMultiDateSelectorSelect,
    selectedMultiDateSelector,
  } = useDateRange();
  const isMobile = useIsMobile();
  const { displayMonths } = useNavigation();

  useEffect(() => {
    onVisibleMonthsChange?.(displayMonths);
  }, [displayMonths, onVisibleMonthsChange]);

  return isMobile ? (
    <IntlProvider
      resetKey={locale.code}
      translations={getTranslationComponent(dateFnsLocaleMap[locale.code ?? 'en-US'])}
    >
      <MobileContainer id={id}>
        <MobileHeader>
          <Text type="h3">Date range</Text>
          <Zone>{zonePrefix}{zone}</Zone>
        </MobileHeader>
        <MobileBody>
          <InnerMobileFlex>
            <DateRangePresetSelector
              presetRanges={presetRanges}
              handlePresetSelect={handlePresetSelect}
              selectedPreset={selectedPreset}
              multiDateSelectors={multiDateSelectors}
              multiDateSelectorsLabel={multiDateSelectorsLabel}
              handleMultiDateSelectorSelect={handleMultiDateSelectorSelect}
              selectedMultiDateSelector={selectedMultiDateSelector}
              zone={zone}
              dropdownLabelOverride={dropdownLabelOverride}
              isMobile={isMobile}
            />
            <DateRangePickerInnerWrapper>
              <Card padding="none">
                <DateRangePickerMonthFlex
                  $orientation={orientation === 'vertical' ? 'column' : 'row'}
                >
                  {displayMonths.map(renderMonth)}
                </DateRangePickerMonthFlex>
              </Card>
              <ConfirmationControlWrapper
                cancelText={cancelText}
                saveText={saveText}
                onSubmit={handleOnSubmit}
                onCancel={handleOnCancel}
                isStartValid={!!selected?.from}
                isMobile={isMobile}
                taktId={taktId}
                taktValue={taktValue}
              />
            </DateRangePickerInnerWrapper>
          </InnerMobileFlex>
        </MobileBody>
      </MobileContainer>
    </IntlProvider>
  ) : (
    <IntlProvider translations={getTranslationComponent(dateFnsLocaleMap[locale.code ?? 'en-US'])}>
      <DateRangePickerOuterWrapper id={id} $loading={loading}>
        <DateRangePresetSelector
          presetRanges={presetRanges}
          handlePresetSelect={handlePresetSelect}
          selectedPreset={selectedPreset}
          multiDateSelectors={multiDateSelectors}
          multiDateSelectorsLabel={multiDateSelectorsLabel}
          handleMultiDateSelectorSelect={handleMultiDateSelectorSelect}
          selectedMultiDateSelector={selectedMultiDateSelector}
          zone={zone}
          dropdownLabelOverride={dropdownLabelOverride}
          isMobile={isMobile}
          zonePrefix={zonePrefix}
          renderZoneOverride={renderZoneOverride}
        />
        <DateRangePickerInnerWrapper>
          <DateRangePickerMonthFlex
            $orientation={orientation === 'vertical' ? 'column' : 'row'}
          >
            {displayMonths.map(renderMonth)}
          </DateRangePickerMonthFlex>
          <ConfirmationControlWrapper
            cancelText={cancelText}
            saveText={saveText}
            onSubmit={handleOnSubmit}
            onCancel={handleOnCancel}
            isStartValid={!!selected?.from}
            isMobile={isMobile}
            taktId={taktId}
            taktValue={taktValue}
          />
        </DateRangePickerInnerWrapper>
      </DateRangePickerOuterWrapper>
    </IntlProvider>
  );
};

export default Root;
