import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import Icon from '../Icon';
import focusOutlineStyle from '../FocusIndicator/styleMixins/focusOutline';
import type { TaktProps } from '../types/TaktProps';

export const CloseIcon = styled(Icon)`
  font-size: 13px;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.micro};
  color: ${({ theme }) => theme.button.close.color};

  ${isMobile(css`
    font-size: 18px;
  `)}

  :hover {
    color: ${({ theme }) => theme.button.close.colorHover};
    cursor: pointer;
  }
`;
CloseIcon.displayName = 'CloseIcon';

export interface CloseButtonProps extends TaktProps, MergeElementProps<'button'> {
  closeButtonLabel: string;
}

export interface CloseButtonAttrsProps extends CloseButtonProps {
  $focusVisible?: boolean;
}

export const CloseButton = styled('button')
  .attrs<CloseButtonProps>(({ theme, closeButtonLabel, ...props }) => {
    const { isFocusVisible, focusProps } = useFocusRing();

    return ({
      ...mergeProps(props, focusProps),
      tabIndex: '0',
      'aria-label': closeButtonLabel,
      $focusVisible: isFocusVisible,
      type: 'button',
    });
  }) <CloseButtonAttrsProps>`
  margin-inline-start: ${({ theme }) => theme.spacing.base};
  border: none;
  background: transparent;
  padding: ${({ theme }) => theme.spacing.none};

  :focus {
    outline: none;
    ${({ $focusVisible }) => ($focusVisible && focusOutlineStyle)}
  }
`;
CloseButton.displayName = 'CloseButton';
