import { DatePickerContextValue, ISODate } from '../types';
import { dateToISODate } from './dateUtils';

/** Returns the initial month to display */
const getInitialMonth = (context: DatePickerContextValue): ISODate => {
  const {
    date,
    startDate,
    monthInView,
    today,
  } = context;
  return date || startDate || monthInView || today || dateToISODate(new Date());
};

export default getInitialMonth;
