import { Transform } from '../types';
import meaninglessTransform from './meaninglessTransform';

/** Returns if the given transform object contains any non-default values */
const transformIsMeaningful = (
  transform: Transform,
): boolean => transform.size !== meaninglessTransform.size
  || transform.x !== meaninglessTransform.x
  || transform.y !== meaninglessTransform.y
  || transform.rotate !== meaninglessTransform.rotate
  || transform.flipX
  || transform.flipY;

export default transformIsMeaningful;
