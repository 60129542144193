import styled, { css } from 'styled-components';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';
import { Theme } from '../theme';
import { AlertType, AlertMessageTheme } from './types';

interface BackgroundStyleProps { $isSolid: boolean; $type: AlertType; theme: AlertMessageTheme }
function backgroundStyle({ $isSolid, $type, theme }: BackgroundStyleProps) {
  const background = theme.message[$type]?.[$isSolid ? 'solidBg' : 'bg'];
  return background ? css`background:${background};` : '';
}

interface BorderStyleProps { $isSolid: boolean; $type: AlertType; theme: AlertMessageTheme }
function borderStyle({ $isSolid, $type, theme }: BorderStyleProps) {
  const borderColor = $isSolid ? null : theme.message[$type]?.borderColor;
  return borderColor ? css`border: ${theme.message.borderWidth} solid ${borderColor};` : '';
}

interface BorderRadiusStyleProps { $isSolid: boolean; theme: Theme; }
function borderRadiusStyle({ $isSolid, theme }: BorderRadiusStyleProps) {
  const borderRadius = $isSolid ? null : theme.message.borderRadius;
  return borderRadius ? css`border-radius: ${borderRadius};` : '';
}

interface ContentAlignStyleProps { $isSolid: boolean; }
function contentAlignStyle({ $isSolid }: ContentAlignStyleProps) {
  return $isSolid ? css`text-align: center; align-items: center;` : '';
}

interface StyledMessageProps extends MergeStyledComponentElementProps<'div'> {
  $isSolid: boolean;
  $type: AlertType;
  inline: boolean;
}

const StyledMessage = styled('div')<StyledMessageProps>`
  position: relative;
  display: flex;
  margin-right: ${({ inline, theme }) => (inline ? theme.spacing.mini : '0')};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  padding: ${({ $isSolid, theme }) => ($isSolid ? theme.spacing.small : theme.spacing.base)};
  ${borderStyle}
  ${borderRadiusStyle}
  ${backgroundStyle}
  ${contentAlignStyle}
`;
StyledMessage.displayName = 'StyledMessage';

export default StyledMessage;
