import React, { PureComponent, HTMLProps } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '../Text/Text';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const StyledText = styled(Text)`
  line-height: 1;
  background: ${({ theme }) => theme.divider.bg};
  padding: ${({ theme }) => theme.divider.textPadding};
  color: ${({ theme }) => theme.divider.textColor};
  position: relative;
  display: inline-block;
  top: -0.5em;

  ${isMobile(css`
    padding: ${({ theme }) => theme.divider.mobile.textPadding};
  `)}
`;

export interface DividerProps {
  /**
   * Add text to create a text divider
   * @defaultValue `undefined`
   */
  text?: string;
}

const Divider = styled(class DividerComponent extends PureComponent<
  DividerProps & Omit<HTMLProps<HTMLDivElement>, keyof DividerProps>
  > {
  render(): JSX.Element {
    const {
      text,
      ...rest
    } = this.props;

    return (
      <div {...rest} role="separator">
        {text && (
          <StyledText type="span">&nbsp;{text}&nbsp;</StyledText>
        )}
      </div>
    );
  }
})`
  text-align: center;
  border-bottom-width: 1px;
  /* Makes the border remain 1px in size at any zoom */
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.divider.color};
  margin: ${({ theme }) => theme.divider.margin};
  line-height: 0;
  height: 0px;

  ${isMobile(css`
    margin: ${({ theme }) => theme.divider.mobile.margin};
  `)}
`;

Divider.propTypes = {
  /**
   * Add text to create a text divider
   */
  text: PT.string,
};

Divider.defaultProps = {
  text: undefined,
};

Divider.displayName = 'Divider';

export default Divider;
