/* eslint-disable id-length */
import { palette } from '../base/color';
import { border } from '../base/border';

type Modal = {
  overlay: string,
  bg: string,
  radius: string,
  content: string,
  header: {
    bg: string,
    borderColor: string,
  },
  footer: {
    bg: string,
    borderColor: string,
  },
  zIndex?: number,
}

const modal: Modal = {
  overlay: 'rgba(35,47,62,0.8)', // palette.squidInk @ 80% opacity
  bg: `linear-gradient(to bottom, ${palette.haze}, ${palette.concrete})`,
  radius: border.roundedArea.radius,
  content: palette.white,
  header: {
    bg: palette.white,
    borderColor: palette.gray[100],
  },
  footer: {
    bg: palette.white,
    borderColor: palette.gray[100],
  },
  zIndex: 700,
};

export default modal;
