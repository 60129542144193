import React, {
  FC,
} from 'react';
import PT from 'prop-types';
import FilterDropdown from './FilterDropdown';
import type { FilterDropdownProps } from './FilterDropdown';

export interface FilterRadioGroupProps extends FilterDropdownProps {
  /**
   * The label for the selected item in the secondary view.
   * @defaultValue `""`
   */
  currentLabel?: string;
  /**
   * Required for RadioGroup `name` property.
   */
  radioGroupName: string;
  /**
   * Required for the RadioGroup `label` property.
   * @defaultValue `""`
   */
  radioGroupLabel?: string;
}

const FilterRadioGroup: FC<React.PropsWithChildren<FilterRadioGroupProps>> = ({
  radioGroupName,
  radioGroupLabel,
  ...restProps
}) => (
  <FilterDropdown
    hideLabel // copied from ./FilterRadioGroupBody.tsx
    {...restProps}
    mobileFullScreen
    name={radioGroupName}
    label={radioGroupLabel}
  />
);

FilterRadioGroup.propTypes = {
  /**
   * Render prop for rendering components into the DOM before the filter dropdown.
   */
  preRender: PT.func,
  /**
   * Overrides the placeholder text for the <input> element.
   */
  selectedLabelOverride: PT.string,
  /**
   * The 'value' of the dropdown item that has been selected.
   */
  selectedValue: PT.string,
  /**
   * The label for the selected item in the secondary view.
   */
  currentLabel: PT.string,
  /**
   * Hook in to this to display the "selected" label differently than how
   * it appears in the list item.
   */
  onOverrideLabel: PT.func,
  /**
   * Callback that is called when the filter dropdown is opened.
   */
  onOpen: PT.func,
  /**
   * Required for RadioGroup `name` property.
   */
  radioGroupName: PT.string.isRequired,
  /**
   * Required for the RadioGroup `label` property.
   */
  radioGroupLabel: PT.string,
  /**
   * Custom string for the SecondaryView `closeButtonLabel` property.
   */
  secondaryViewCloseButtonLabel: PT.string,
  /**
   * Used to render dropdown button with no background.
   */
  transparentButton: PT.bool,
  /**
   * Used to toggle whether element is disabled
   */
  disabled: PT.bool,
  /**
   * By default, the text input will clear and onFilterClear will be called when opening the dropdown.
   * Enabling this prop will circumvent this behavior.
   */
  persistFilterState: PT.bool,
};

FilterRadioGroup.defaultProps = {
  preRender: () => null,
  currentLabel: '',
  selectedValue: '',
  onOverrideLabel: (label?: string) => label,
  selectedLabelOverride: undefined,
  onOpen: () => undefined,
  radioGroupLabel: '',
  secondaryViewCloseButtonLabel: 'Done',
  transparentButton: false,
  disabled: false,
  persistFilterState: false,
};

export default FilterRadioGroup;
