import { SurfaceThemeOverrides } from './surface';
import surfaceDarkStyles from './surfaceDark';

const surfaceDarkInfoStyles: SurfaceThemeOverrides = {
  ...surfaceDarkStyles,
  surface: {
    backgroundColor: '#018485',
  },
};

export default surfaceDarkInfoStyles;
