export default function getCurrencyPosition(language?: string, country?: string): string | null {
  switch (language) {
    case 'en':
    case 'zh':
    case 'ko':
    case 'ja':
    case 'nl':
    case 'pt':
    case 'tr':
    case 'ta':
    case 'hi':
    case 'th':
      return 'prefix';
    case 'ar':
    case 'es':
      // 'es' is default for Mexico and Spain, which have different currency positions
      return country === 'MX' ? 'prefix' : 'suffix';
    case 'fr':
    case 'de':
    case 'it':
    case 'sv':
    case 'pl':
    case 'eg':
    case 'cs':
    case 'he':
    case 'vi':
      return 'suffix';
    default:
      return null;
  }
}
