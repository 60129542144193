import { DateRange } from '../types';
import isValidDate from './isValidDate';

/** Returns true if all dates within the given range are valid */
const isValidDateRange = (dateRange: DateRange) => {
  if ((dateRange.from && !isValidDate(dateRange.from))
    || (dateRange.to && !isValidDate(dateRange.to))) {
    return false;
  }
  return true;
};

export default isValidDateRange;
