/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Cancelar"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Guardar"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Personalizado"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Selecciona un solo día"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Selecciona un mes"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Selecciona una semana"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Últimos 30 días"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Últimos 7 días"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "El mes pasado"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "La semana pasada"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Duración total"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Esta semana"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Hoy"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Año hasta la fecha"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Ayer"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
