import styled from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { PaddingOptions } from '../types/PaddingOptions';

export interface ShroudProps {
  $transitionState: TransitionStatus;
  $fadeDuration: number;
}

export const Container = styled('div')<ShroudProps>`
  background-color: ${({ theme }) => theme.modal.overlay};
  z-index: ${({ theme }) => theme.modal.zIndex};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (prefers-reduced-motion:no-preference) {
    transition: opacity ${({ $fadeDuration }) => ($fadeDuration)}ms ease-in-out;
  }

  opacity: 0;
  opacity: ${({ $transitionState }) => ({
    entering: 0,
    entered: 1,
    exiting: 0,
    exited: 0,
    unmounted: 0,
  }[$transitionState])};
`;
Container.displayName = 'Container';

export interface OuterWrapperProps {
  $transitionState: TransitionStatus;
}

export const OuterWrapper = styled('div')<OuterWrapperProps>`
  box-sizing: border-box;
  outline: none;
  width: fit-content;
  max-width: 80%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.modal.radius};

  @media (prefers-reduced-motion:no-preference) {
    transition: all 100ms ease-in-out;
  }

  transform: ${({ $transitionState }) => ({
    entering: 'scale(0.95)',
    entered: 'none',
    exiting: 'none',
    exited: 'none',
    unmounted: 'none',
  }[$transitionState])};
`;
OuterWrapper.displayName = 'OuterWrapper';

export const Wrapper = styled('div')`
  box-sizing: border-box;
  word-wrap: break-word;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  background: ${({ theme }) => theme.modal.header.bg};
  border-bottom: thin solid ${({ theme }) => theme.modal.header.borderColor};
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
`;
Header.displayName = 'Header';

export interface ContentProps extends MergeElementProps<'div'> {
  $padding?: PaddingOptions;
}

export const Content = styled('div')<ContentProps>`
  background: ${({ theme }) => theme.modal.content};
  padding: ${({ $padding, theme }) => ($padding ? theme.spacing[$padding] : `${theme.spacing.large} ${theme.spacing.base}`)};
  overflow-y: auto;
  max-height: calc(100vh - 13rem);
`;
Content.displayName = 'Content';

export const Footer = styled('div')`
  background: ${({ theme }) => theme.modal.footer.bg};
  padding: 12px 14px;
  display: flex;
  justify-content: flex-end;

  > * {
    margin-inline-end: ${({ theme }) => theme.spacing.mini};
  }

  > *:last-child {
    margin-inline-end: 0;
  }

  border-top: 1px solid ${({ theme }) => theme.modal.footer.borderColor};
`;
Footer.displayName = 'Footer';
