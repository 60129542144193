// eslint-disable-next-line import/prefer-default-export
export const border = {
  square: {
    width: '2px',
    radius: '0px',
  },
  squareThin: {
    width: '1px',
    radius: '0px',
  },
  rounded: {
    width: '2px',
    radius: '5px',
  },
  roundedThin: {
    width: '1px',
    radius: '5px',
  },
  roundedArea: {
    width: '2px',
    radius: '8px',
  },
  roundedAreaThin: {
    width: '1px',
    radius: '8px',
  },
  round: {
    width: '2px',
    radius: '999px',
  },
  roundThin: {
    width: '1px',
    radius: '999px',
  },
};
