import React, { MouseEvent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import Button from '../../Button/Button';
import { withTaktFallbackId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';
import type { ButtonProps } from '../../Button/Button';

import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: ${({ theme }) => theme.pagination.button.width};
  ${isMobile(css`
    width: ${({ theme }) => theme.pagination.button.mobile.width};
    height: ${({ theme }) => theme.pagination.button.mobile.height};
  `)}
`;

interface InternalPaginationButtonProps extends TaktProps {
  /**
   * The id assigned to the interactive element.
   */
  id: string;
  /**
   * Labels are required for screen reader accessibility.
   */
  ariaLabel: string;
  /**
   * onClick handler.
   */
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Disable button.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * fallback id for taktId
   * @defaultValue `undefined`
   */
  taktFallbackId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaginationButtonProps extends InternalPaginationButtonProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaginationNavProps extends Omit<PaginationButtonProps, 'children'> {}

const PageNavButton = withTaktFallbackId<ButtonProps>(StyledButton)

const PaginationButton = (
  props: PropsWithChildren<InternalPaginationButtonProps>,
): JSX.Element => {
  const {
    id, ariaLabel, onClick, children, disabled, taktId, taktValue, taktFallbackId,
  } = props;

  return (
    <PageNavButton
      id={id}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled}
      taktId={taktId}
      taktValue={taktValue}
      taktFallbackId={taktFallbackId}
    >
      {children}
    </PageNavButton>
  );
};

PaginationButton.defaultProps = {
  disabled: false,
  taktFallbackId: undefined,
};

export default PaginationButton;
