import { createContext } from 'react';
import { MobileModeChangeEvent } from './types';

export type MobileThemeContextProps = {
  isMobile: boolean;
  onMobileModeChange: (event: MobileModeChangeEvent) => void;
  browserIsMobile: boolean;
} | undefined

export type MaybeMobileThemeContextProps = MobileThemeContextProps | undefined

const MobileThemeContext = createContext<Partial<MaybeMobileThemeContextProps>>(undefined);

export default MobileThemeContext;
