import {
  Dispatch,
  useContext,
  useMemo,
  useState,
  RefObject,
  SetStateAction,
} from 'react';
import { Instance, Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { ThemeContext } from 'styled-components';
import textDirectionSensitive from './popperModifiers/textDirectionSensitive';
// import calculateOffset from './popperModifiers/calculateOffset';
import calculateOffsetSkidding from './popperModifiers/calculateOffsetSkidding';
import calculateOffsetDistance from './popperModifiers/calculateOffsetDistance';
import { PositioningStrategy } from './types';

export interface PreventOverflowOptions {
  mainAxis: boolean,
}

export interface InlinePopperOptions {
  offsetDistance?: number;
  offsetSkidding?: number;
  placement: Placement,
  enableFlip?: boolean,
  preventOverflow?: PreventOverflowOptions,
  withArrow?: boolean,
  boundaryRef?: RefObject<HTMLElement>,
  strategy?: PositioningStrategy;
  rootBoundary?: 'document' | 'viewport',
}

export interface InlinePopper {
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  triggerElement: HTMLElement | null;
  popperElement: HTMLElement | null;
  arrowElement: HTMLElement | null;
  setTriggerElement: Dispatch<SetStateAction<HTMLElement | null>>;
  setPopperElement: Dispatch<SetStateAction<HTMLElement | null>>;
  setArrowElement: Dispatch<SetStateAction<HTMLElement | null>>;
  update: Instance['update'] | null;
}

export default function useInlinePopper(options: InlinePopperOptions): InlinePopper {
  const {
    placement,
    offsetDistance,
    offsetSkidding,
    enableFlip,
    preventOverflow,
    withArrow,
    boundaryRef,
    strategy,
    rootBoundary = 'document',
  } = options;
  const [triggerElement, setTriggerElement] = useState<null | HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<null | HTMLElement>(null);
  const arrowHeight = useContext(ThemeContext)?.tooltip?.arrowHeight;
  const tooltipOffset = useContext(ThemeContext)?.tooltip?.height;

  const offsetModifier = useMemo(() => ({
    name: 'offset',
    options: {
      offset: ({ placement: popperPlacement }: { placement: Placement }) => {
        const distance = calculateOffsetDistance(withArrow, arrowHeight, tooltipOffset, offsetDistance);
        const skidding = calculateOffsetSkidding(withArrow, popperPlacement, distance, offsetSkidding ?? 0);

        return [skidding, distance];
      },
    },
  }), [withArrow, arrowHeight, tooltipOffset, offsetDistance, offsetSkidding]);

  const { styles, attributes, update } = usePopper(
    triggerElement,
    popperElement, {
      placement,
      modifiers: [{
        name: 'textDirectionSensitive',
        enabled: true,
        phase: 'beforeRead',
        fn: textDirectionSensitive,
      },
      ...(/left|right/.test(placement)
        ? [{
          name: 'flip',
          enabled: enableFlip,
          options: {
            rootBoundary,
            fallbackPlacements: ['bottom', 'top'],
            ...(boundaryRef?.current && { boundary: boundaryRef.current }),
          },
        }]
        : [{
          name: 'flip',
          enabled: enableFlip,
          options: {
            rootBoundary,
            ...(boundaryRef?.current && { boundary: boundaryRef.current }),
          },
        }]
      ),
      offsetModifier,
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'preventOverflow',
        ...(preventOverflow && {
          options: {
            ...preventOverflow,
            ...(boundaryRef?.current && { boundary: boundaryRef.current }),
          },
        }),
      }],
      strategy,
    },
  );

  return {
    styles,
    attributes,
    triggerElement,
    popperElement,
    arrowElement,
    setTriggerElement,
    setPopperElement,
    setArrowElement,
    update,
  };
}
