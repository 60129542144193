import { palette } from '../base/color';
import { fontSizes, mobileFontSizes } from '../base/typography';

export const touchLink = {
  backgroundColor: palette.white,
  borderColor: palette.gray[200],
  borderRadius: '8px',
  color: palette.squidInk,
  iconColor: palette.gray[700],
  fontSize: fontSizes.base,
  group: {
    borderColor: palette.gray[200],
  },
};

export const mobileTouchLink = {
  mobile: {
    fontSize: mobileFontSizes.base,
  },
};
