import type { IconDefinition } from './types';

export const amznAdsStormLogo: IconDefinition = {
  iconName: 'amzn-ads-storm-logo',
  prefix: 'fab',
  icon: [478, 512, [], '', 'M417.77 57.42c34.57 34.04 51.86 80.18 51.86 138.44 0 57.03-11.17 108.84-33.5 155.45-22.33 46.6-54 83.7-94.99 111.3-41 27.6-88.1 41.39-141.33 41.39-37.1 0-69.4-6.8-96.85-20.38l265.49-236.27-123.87-24.13 149.5-185.2a166.36 166.36 0 0 1 23.7 19.4ZM246.9 8 99.98 265.8l105.1 20.48L83.52 472.33a166.36 166.36 0 0 1-23.67-19.38C25.28 418.92 8 372.77 8 314.52c0-57.03 11.17-108.85 33.5-155.45 22.33-46.6 54-83.7 94.98-111.3C169.35 25.64 206.16 12.38 246.9 8Z'],
};

export const textSeparator: IconDefinition = {
  prefix: 'far',
  iconName: 'text-separator',
  icon: [512, 512, [], '', 'M256 8a31 31 0 0 1 31 31v434a31 31 0 1 1-62 0V39a31 31 0 0 1 31-31Z'],
};

export const times: IconDefinition = {
  prefix: 'fas',
  iconName: 'times',
  icon: [384, 512, [], '', 'M20.44 367.51a42.46 42.46 0 0 0 60.05 60.06L192 316.05l111.51 111.51a42.47 42.47 0 0 0 60.06-60.05L252.05 256l111.51-111.51a42.47 42.47 0 0 0-60.05-60.05L192 195.95 80.49 84.44a42.46 42.46 0 1 0-60.05 60.05L131.95 256 20.44 367.51Z'],
};

export const infoCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'info-circle',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z'],
};

export const angleInlineEnd: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-inline-end',
  icon: [256, 512, [], '', 'M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'],
};

export const angleInlineStart: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-inline-start',
  icon: [256, 512, [], '', 'M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z'],
};

export const caretInlineEnd: IconDefinition = {
  prefix: 'fas',
  iconName: 'caret-inline-end',
  icon: [192, 512, [], '', 'M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'],
};
export const opportunities: IconDefinition = {
  prefix: 'fas',
  iconName: 'opportunities',
  icon: [512, 512, [], '', 'M195.98 111.71a15.03 15.23 0 0 1 28.26-1.83l.6 1.83 6.76 21.02A228.8 231.77 0 0 0 371.56 278.9l5.26 1.98 20.6 6.85c13.37 4.57 13.97 23.15 1.8 28.94l-1.8.6-20.6 6.86A228.65 231.62 0 0 0 233.4 466.98l-1.8 5.33-6.77 21.01a15.03 15.23 0 0 1-28.11 1.83l-.75-1.83-6.77-21.01a228.5 231.46 0 0 0-139.8-146.2L44 324.15l-20.6-6.85c-13.23-4.57-13.83-23.15-1.65-28.94l1.65-.6 20.75-6.86a228.5 231.46 0 0 0 143.4-142.84l1.81-5.32 6.77-21.02Zm197.07-98.37a7.52 7.61 0 0 1 13.83-1.37l.6 1.37 3.31 10.5a114.4 115.88 0 0 0 68.55 72.64l4.21 1.53 10.22 3.35c6.47 2.28 6.92 11.11 1.36 14.16l-1.36.6-10.22 3.5a114.25 115.73 0 0 0-71.25 69.9l-1.5 4.12-3.31 10.66a7.52 7.61 0 0 1-13.83 1.21l-.6-1.37-3.46-10.5A114.25 115.73 0 0 0 321.2 121l-4.06-1.52-10.37-3.35c-6.47-2.14-6.92-11.12-1.36-14.17l1.36-.6L317.14 98a114.25 115.73 0 0 0 71.1-70.05l1.5-4.11 3.31-10.66Z'],
};

export const angleDoubleDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-double-down',
  icon: [389, 512, [], '', 'M241.23 259.56a41.46 41.46 0 0 1 61.47 55.47l-2.84 3.15L160 458.04 20.14 318.18a41.45 41.45 0 0 1 55.48-61.47l3.15 2.85L160 340.79l81.23-81.23Zm0-193.46a41.46 41.46 0 0 1 61.47 55.48l-2.84 3.15L160 264.59 20.14 124.73a41.45 41.45 0 0 1 55.48-61.47l3.15 2.84L160 147.34l81.23-81.24Z'],
};

export const angleDoubleRight: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-double-right',
  icon: [448, 512, [], '', 'M227.8 106.48a44.32 44.32 0 0 1 59.31-3.04l3.37 3.04L440 256 290.48 405.52a44.32 44.32 0 0 1-65.72-59.3l3.04-3.37L314.65 256l-86.85-86.84a44.32 44.32 0 0 1 0-62.68Zm-206.82 0a44.32 44.32 0 0 1 59.31-3.04l3.37 3.04L233.18 256 83.66 405.52a44.32 44.32 0 0 1-65.72-59.3l3.04-3.37L107.83 256l-86.85-86.84a44.32 44.32 0 0 1 0-62.68Z'],
};

export const angleDoubleLeft: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-double-left',
  icon: [448, 512, [], '', 'M157.52 106.48a44.32 44.32 0 0 1 65.72 59.3l-3.04 3.38L133.35 256l86.85 86.85a44.32 44.32 0 0 1-59.31 65.71l-3.37-3.04L8 256l149.52-149.52Zm206.82 0a44.32 44.32 0 0 1 65.72 59.3l-3.04 3.38L340.17 256l86.85 86.85a44.32 44.32 0 0 1-59.31 65.71l-3.37-3.04L214.82 256l149.52-149.52Z'],
};

export const angleDoubleUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-double-up',
  icon: [320, 512, [], '', 'm160 247.41 139.86 139.86a41.46 41.46 0 0 1-55.48 61.48l-3.15-2.85L160 364.66 78.77 445.9a41.45 41.45 0 0 1-61.47-55.48l2.84-3.15L160 247.41Zm0-193.45 139.86 139.86a41.46 41.46 0 0 1-55.48 61.47l-3.15-2.85L160 171.21l-81.23 81.23a41.45 41.45 0 0 1-61.47-55.47l2.84-3.15L160 53.96Z'],
};

export const angleDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-down',
  icon: [389, 512, [], '', 'M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z'],
};

export const angleLeft: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-left',
  icon: [256, 512, [], '', 'M237.04 79.88a37.42 37.42 0 0 1 0 52.91L113.84 256l123.2 123.2a37.42 37.42 0 0 1-52.91 52.93L8 256 184.13 79.87a37.42 37.42 0 0 1 52.91 0Z'],
};

export const angleRight: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-right',
  icon: [256, 512, [], '', 'M18.96 432.13a37.42 37.42 0 0 1 0-52.92L142.16 256 18.97 132.78a37.42 37.42 0 0 1 52.91-52.91L248 256 71.87 432.13a37.42 37.42 0 0 1-52.91 0Z'],
};

export const angleUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'angle-up',
  icon: [389, 512, [], '', 'M370.08 364.7a37.3 37.3 0 0 1-52.76 0L194.5 241.88 71.67 364.7a37.3 37.3 0 1 1-52.75-52.75L194.5 136.38l175.58 175.57a37.3 37.3 0 0 1 0 52.75Z'],
};

export const bars: IconDefinition = {
  prefix: 'fas',
  iconName: 'bars',
  icon: [448, 512, [], '', 'M8 67a27 27 0 0 1 27-27h378a27 27 0 0 1 27 27v81H8V67Zm0 135h432v108H8V202Zm0 162h432v81a27 27 0 0 1-27 27H35a27 27 0 0 1-27-27v-81Z'],
};

export const bell: IconDefinition = {
  prefix: 'fas',
  iconName: 'bell',
  icon: [448, 512, [], '', 'M193 39a31 31 0 1 1 62 0v3.1c70.75 14.36 124 76.91 124 151.9v124l50.78 101.57A15.5 15.5 0 0 1 415.92 442H286a62 62 0 1 1-124 0H32.08a15.5 15.5 0 0 1-13.86-22.43L69 318V194c0-74.99 53.25-137.54 124-151.9V39Z'],
};

export const calendarAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'calendar-alt',
  icon: [448, 512, [], '', 'M332 40a27 27 0 0 1 27 27h27a54 54 0 0 1 54 54v297a54 54 0 0 1-54 54H62a54 54 0 0 1-54-54V121a54 54 0 0 1 54-54h27a27 27 0 1 1 54 0h162a27 27 0 0 1 27-27Zm54 148.5H62V418h324V188.5ZM156.5 310v54h-54v-54h54Zm94.5 0v54h-54v-54h54Zm94.5 0v54h-54v-54h54Zm-189-81v54h-54v-54h54Zm94.5 0v54h-54v-54h54Zm94.5 0v54h-54v-54h54Z'],
};

export const caretDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'caret-down',
  icon: [320, 512, [], '', 'M33.33 167.33a25.33 25.33 0 0 0-17.9 43.25l126.66 126.67a25.33 25.33 0 0 0 35.82 0l126.67-126.67a25.33 25.33 0 0 0-17.91-43.25H33.33Z'],
};

export const caretLeft: IconDefinition = {
  prefix: 'fas',
  iconName: 'caret-left',
  icon: [192, 512, [], '', 'M184 130.29a25.14 25.14 0 0 0-42.92-17.78L15.36 238.22a25.14 25.14 0 0 0 0 35.56l125.72 125.71A25.14 25.14 0 0 0 184 381.71V130.3Z'],
};

export const caretRight: IconDefinition = {
  prefix: 'fas',
  iconName: 'caret-right',
  icon: [192, 512, [], '', 'M8 381.71a25.14 25.14 0 0 0 42.92 17.78l125.72-125.71a25.14 25.14 0 0 0 0-35.56L50.92 112.51A25.14 25.14 0 0 0 8 130.28v251.43Z'],
};

export const caretUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'caret-up',
  icon: [320, 512, [], '', 'M286.67 344.67a25.33 25.33 0 0 0 17.9-43.25L177.92 174.75a25.33 25.33 0 0 0-35.82 0L15.42 301.42a25.33 25.33 0 0 0 17.91 43.25h253.34Z'],
};

export const checkCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'check-circle',
  icon: [512, 512, [], '', 'M256 504c136.97 0 248-111.03 248-248S392.97 8 256 8 8 119.03 8 256s111.03 248 248 248Zm145.92-347.42a31 31 0 0 1 0 43.84L225 377.34 110.08 262.42a31 31 0 1 1 43.84-43.84L225 289.66l133.08-133.08a31 31 0 0 1 43.84 0Z'],
};

export const check: IconDefinition = {
  prefix: 'fas',
  iconName: 'check',
  icon: [512, 512, [], '', 'M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z'],
};

export const clock: IconDefinition = {
  prefix: 'far',
  iconName: 'clock',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm0 62C153.28 70 70 153.28 70 256c0 102.73 83.28 186 186 186 102.73 0 186-83.27 186-186 0-102.72-83.27-186-186-186Zm0 31a31 31 0 0 1 30.8 27.38l.2 3.62v93h93a31 31 0 0 1 3.62 61.8l-3.62.2H256a31 31 0 0 1-30.8-27.38L225 256V132a31 31 0 0 1 31-31Z'],
};

export const cog: IconDefinition = {
  prefix: 'far',
  iconName: 'cog',
  icon: [512, 512, [], '', 'M193.78 34.21A31 31 0 0 1 224.41 8h63.18a31 31 0 0 1 30.63 26.22l7.72 49.38a185.73 185.73 0 0 1 44.35 25.65l46.67-18.03a31 31 0 0 1 38.02 13.42l31.59 54.72a31 31 0 0 1-7.39 39.63l-38.93 31.4a187.86 187.86 0 0 1 0 51.23L479.18 313a31 31 0 0 1 7.39 39.63l-31.6 54.72a31 31 0 0 1-38.01 13.42l-46.67-18.03a185.8 185.8 0 0 1-44.36 25.65l-7.71 49.39A31 31 0 0 1 287.59 504h-63.18a31 31 0 0 1-30.63-26.21l-7.72-49.39a185.78 185.78 0 0 1-44.35-25.65l-46.67 18.02a31 31 0 0 1-38.02-13.41l-31.6-54.72a31 31 0 0 1 7.4-39.63l38.93-31.4a187.52 187.52 0 0 1 0-51.23L32.82 199a31 31 0 0 1-7.39-39.63l31.6-54.72a31 31 0 0 1 38.01-13.42l46.67 18.03a185.76 185.76 0 0 1 44.35-25.65l7.72-49.39ZM256 333.5a77.5 77.5 0 1 0 0-155 77.5 77.5 0 0 0 0 155Z'],
};

export const desktop: IconDefinition = {
  prefix: 'fas',
  iconName: 'desktop',
  icon: [576, 512, [], '', 'M8 81a35 35 0 0 1 35-35h490a35 35 0 0 1 35 35v280a35 35 0 0 1-35 35H358l35 70H183l35-70H43a35 35 0 0 1-35-35V81Zm490 210H78v35h420v-35Z'],
};

export const download: IconDefinition = {
  prefix: 'fas',
  iconName: 'download',
  icon: [512, 512, [], '', 'M256 8a46.5 46.5 0 0 1 46.5 46.5v135.74l44.62-44.62a46.5 46.5 0 0 1 65.76 65.76L256 368.26 99.12 211.38a46.5 46.5 0 1 1 65.76-65.76l44.62 44.62V54.5A46.5 46.5 0 0 1 256 8ZM54.5 411a46.5 46.5 0 0 0 0 93h403a46.5 46.5 0 0 0 0-93h-403Z'],
};

export const ellipsisV: IconDefinition = {
  prefix: 'fas',
  iconName: 'ellipsis-v',
  icon: [192, 512, [], '', 'M96 132A62 62 0 1 0 96 8a62 62 0 0 0 0 124Zm62 124a62 62 0 1 1-124 0 62 62 0 0 1 124 0Zm0 186a62 62 0 1 1-124 0 62 62 0 0 1 124 0Z'],
};

export const exchangeAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'exchange-alt',
  icon: [512, 512, [], '', 'M312.38 273.38a46.5 46.5 0 0 1 0-65.76L326 194H128.26a46.5 46.5 0 0 1 0-93H326l-13.62-13.62a46.5 46.5 0 1 1 65.76-65.76L504.02 147.5 378.14 273.38a46.5 46.5 0 0 1-65.76 0Zm-112.76 31a46.5 46.5 0 1 0-65.76-65.76L7.98 364.5l125.88 125.88a46.5 46.5 0 0 0 65.76-65.76L186 411h197.74a46.5 46.5 0 0 0 0-93H186l13.62-13.62Z'],
};

export const exclamation: IconDefinition = {
  prefix: 'fas',
  iconName: 'exclamation',
  icon: [192, 512, [], '', 'M158 442a62 62 0 1 1-124 0 62 62 0 0 1 124 0ZM96 8a62 62 0 0 1 62 62v217a62 62 0 1 1-124 0V70A62 62 0 0 1 96 8Z'],
};

export const exclamationTriangle: IconDefinition = {
  prefix: 'fas',
  iconName: 'exclamation-triangle',
  icon: [576, 512, [], '', 'M288 8a49.6 49.6 0 0 1 40.24 20.6l2.6 4 231.47 396.8a49.6 49.6 0 0 1-38.04 74.37l-4.8.23H56.53a49.6 49.6 0 0 1-45.26-69.89l2.42-4.7L245.16 32.6A49.6 49.6 0 0 1 288 8Zm0 363.73a33.07 33.07 0 1 0 0 66.14 33.07 33.07 0 0 0 0-66.14Zm0-231.46a33.07 33.07 0 0 0-33.07 33.06V305.6a33.07 33.07 0 0 0 66.14 0V173.33A33.07 33.07 0 0 0 288 140.27Z'],
};

export const externalLinkAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'external-link-alt',
  icon: [576, 512, [], '', 'M226 39a31 31 0 0 1 31-31h279v279a31 31 0 1 1-62 0V113.84L247.92 339.92a31 31 0 0 1-43.84-43.84L430.16 70H257a31 31 0 0 1-31-31ZM71 70a31 31 0 0 1 31 31v341h341a31 31 0 1 1 0 62H71a31 31 0 0 1-31-31V101a31 31 0 0 1 31-31Z'],
};

export const grabber: IconDefinition = {
  prefix: 'fas',
  iconName: 'grabber',
  icon: [320, 512, [], '', 'M51.5 39a31 31 0 0 1 31-31H129v77.5H51.5V39Zm0 248H129v77.5H51.5V287Zm0 139.5H129V504H82.5a31 31 0 0 1-31-31v-46.5Zm0-279H129V225H51.5v-77.5ZM191 8h46.5a31 31 0 0 1 31 31v46.5H191V8Zm0 279h77.5v77.5H191V287Zm0 139.5h77.5V473a31 31 0 0 1-31 31H191v-77.5Zm0-279h77.5V225H191v-77.5Z'],
};

export const lock: IconDefinition = {
  prefix: 'fas',
  iconName: 'lock',
  icon: [448, 512, [], '', 'M224 8c66.4 0 120.62 52.2 123.85 117.81L348 132v93h46.5a15.5 15.5 0 0 1 15.25 12.71l.25 2.79v248a15.5 15.5 0 0 1-12.71 15.25l-2.79.25h-341a15.5 15.5 0 0 1-15.25-12.71L38 488.5v-248a15.5 15.5 0 0 1 12.71-15.25l2.79-.25H100v-93C100 63.52 155.52 8 224 8Zm0 62a62 62 0 0 0-62 62v93h124v-93a62 62 0 0 0-62-62Z'],
};

export const longArrowAltDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'long-arrow-alt-down',
  icon: [320, 512, [], '', 'M118.54 320.06V74.7a41.46 41.46 0 0 1 82.92 0v245.37l39.77-39.77a41.46 41.46 0 0 1 58.63 58.62L160 478.77 20.14 338.9a41.45 41.45 0 1 1 58.63-58.63l39.77 39.78Z'],
};

export const longArrowAltUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'long-arrow-alt-up',
  icon: [320, 512, [], '', 'M241.23 231.72a41.46 41.46 0 0 0 58.63-58.63L160 33.23 20.14 173.1a41.45 41.45 0 1 0 58.63 58.63l39.78-39.78V437.3a41.45 41.45 0 1 0 82.9 0V191.94l39.78 39.78Z'],
};

export const minusSquare: IconDefinition = {
  prefix: 'fas',
  iconName: 'minus-square',
  icon: [448, 512, [], '', 'M386 40a54 54 0 0 1 53.85 49.97L440 94v324a54 54 0 0 1-49.97 53.85L386 472H62a54 54 0 0 1-53.85-49.97L8 418V94a54 54 0 0 1 49.97-53.85L62 40h324Zm-28.36 189H90.37a27 27 0 0 0 0 54h267.29a27 27 0 0 0 0-54Z'],
};

export const mobileAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'mobile-alt',
  icon: [320, 512, [], '', 'M266.29 8a35.43 35.43 0 0 1 35.19 31.3l.23 4.13v425.14a35.43 35.43 0 0 1-31.3 35.2l-4.12.23H53.7a35.43 35.43 0 0 1-35.19-31.3l-.23-4.13V43.43a35.43 35.43 0 0 1 31.3-35.2L53.71 8h212.57ZM160 397.71a35.43 35.43 0 1 0 0 70.86 35.43 35.43 0 0 0 0-70.86Z'],
};

export const pencilAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'pencil-alt',
  icon: [512, 512, [], '', 'm278.06 92.69 141.26 141.25-260.7 260.69a32 32 0 0 1-18.41 9.1L136 504H8V376a32 32 0 0 1 6.6-19.46l2.77-3.17L278.06 92.68Zm120.57-75.32 96 96a32 32 0 0 1 0 45.26l-52.69 52.68L300.7 70.06l52.68-52.69a32 32 0 0 1 45.26 0Z'],
};

export const plusSquare: IconDefinition = {
  prefix: 'fas',
  iconName: 'plus-square',
  icon: [448, 512, [], '', 'M386 40a54 54 0 0 1 53.85 49.97L440 94v324a54 54 0 0 1-49.97 53.85L386 472H62a54 54 0 0 1-53.85-49.97L8 418V94a54 54 0 0 1 49.97-53.85L62 40h324ZM224 95.36a27 27 0 0 0-27 27V229H90.36a27 27 0 1 0 0 54H197v106.64a27 27 0 1 0 54 0V283h106.64a27 27 0 0 0 0-54H251V122.36a27 27 0 0 0-27-27Z'],
};

export const questionCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'question-circle',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm0 341a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Zm0-279c-59.92 0-108.5 48.58-108.5 108.5a31 31 0 0 0 61.8 3.62l.2-3.62a46.5 46.5 0 0 1 93 0c0 11.75-4.23 18.25-16.53 29.95L274 219.4c-9.82 8.83-22.52 20.6-32.34 36.64C231.41 272.8 225 292.94 225 318h62c0-13.69 3.28-22.62 7.55-29.59 3.93-6.42 9.21-12.09 16.4-18.78l15.02-13.5c17.14-15.68 38.53-39.1 38.53-77.63C364.5 118.58 315.92 70 256 70Z'],
};

export const search: IconDefinition = {
  prefix: 'fas',
  iconName: 'search',
  icon: [512, 512, [], '', 'M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z'],
};

export const sitemap: IconDefinition = {
  prefix: 'fas',
  iconName: 'sitemap',
  icon: [640, 512, [], '', 'M249.14 8a17.71 17.71 0 0 0-17.71 17.71v141.72c0 9.78 7.93 17.71 17.71 17.71h53.15v53.15H107.43A17.71 17.71 0 0 0 89.7 256v70.86H36.57a17.71 17.71 0 0 0-17.71 17.71V486.3c0 9.78 7.93 17.71 17.71 17.71H178.3c9.78 0 17.71-7.93 17.71-17.71V344.57c0-9.78-7.93-17.71-17.71-17.71h-53.15V273.7H302.3v53.15h-53.15a17.71 17.71 0 0 0-17.71 17.71V486.3c0 9.78 7.93 17.71 17.71 17.71h141.72c9.78 0 17.71-7.93 17.71-17.71V344.57c0-9.78-7.93-17.71-17.71-17.71H337.7V273.7h177.15v53.15H461.7a17.71 17.71 0 0 0-17.7 17.72V486.3c0 9.78 7.93 17.71 17.71 17.71h141.72c9.78 0 17.71-7.93 17.71-17.71V344.57c0-9.78-7.93-17.71-17.71-17.71h-53.14V256c0-9.78-7.94-17.71-17.72-17.71H337.71v-53.15h53.15c9.78 0 17.71-7.93 17.71-17.71V25.7c0-9.78-7.93-17.71-17.71-17.71H249.14Z'],
};

export const sort: IconDefinition = {
  prefix: 'fas',
  iconName: 'sort',
  icon: [512, 512, [], '', 'M273.38 199.62a46.5 46.5 0 0 1-65.76 0L194 186v197.74a46.5 46.5 0 0 1-93 0V186l-13.62 13.62a46.5 46.5 0 0 1-65.76-65.76L147.5 7.98l125.88 125.88a46.5 46.5 0 0 1 0 65.76Zm31 112.76a46.5 46.5 0 0 0-65.76 65.76L364.5 504.02l125.88-125.88a46.5 46.5 0 0 0-65.76-65.76L411 326V128.26a46.5 46.5 0 0 0-93 0V326l-13.62-13.62Z'],
};

export const tabletAlt: IconDefinition = {
  prefix: 'fas',
  iconName: 'tablet-alt',
  icon: [448, 512, [], '', 'M404 4a36 36 0 0 1 35.76 31.8L440 40v432a36 36 0 0 1-31.8 35.76l-4.2.24H44a36 36 0 0 1-35.76-31.8L8 472V40A36 36 0 0 1 39.8 4.24L44 4h360Zm-72 396H116v36h216v-36Z'],
};

export const upload: IconDefinition = {
  prefix: 'fas',
  iconName: 'upload',
  icon: [512, 512, [], '', 'M412.88 228.97a46.74 46.74 0 0 1-65.76 0L302.5 184.7v134.7c0 25.5-20.82 46.15-46.5 46.15s-46.5-20.65-46.5-46.15V184.7l-44.62 44.27a46.74 46.74 0 0 1-65.76 0 45.9 45.9 0 0 1 0-65.26L256 8l156.88 155.71a45.9 45.9 0 0 1 0 65.26ZM54.5 411.7C28.82 411.7 8 432.37 8 457.85 8 483.35 28.82 504 54.5 504h403c25.68 0 46.5-20.64 46.5-46.15 0-25.48-20.82-46.15-46.5-46.15h-403Z'],
};

export const userCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'user-circle',
  icon: [496, 512, [], '', 'M248 16c132.55 0 240 107.45 240 240S380.55 496 248 496 8 388.55 8 256 115.45 16 248 16Zm45 270h-90a134.86 134.86 0 0 0-111.54 58.92C122.43 399.32 180.93 436 248 436s125.57-36.68 156.54-91.08a134.88 134.88 0 0 0-104.27-58.73L293 286Zm-45-180a75 75 0 1 0 0 150 75 75 0 0 0 0-150Z'],
};

export const user: IconDefinition = {
  prefix: 'fas',
  iconName: 'user',
  icon: [496, 512, [], '', 'M248 16c132.55 0 240 107.45 240 240S380.55 496 248 496 8 388.55 8 256 115.45 16 248 16Zm0 60c-99.41 0-180 80.59-180 180 0 32.34 8.53 62.7 23.46 88.92A134.86 134.86 0 0 1 203 286h90c46.34 0 87.23 23.35 111.54 58.92A179.17 179.17 0 0 0 428 256c0-99.41-80.59-180-180-180Zm0 30a75 75 0 1 1 0 150 75 75 0 0 1 0-150Z'],
};

export const copy: IconDefinition = {
  prefix: 'fas',
  iconName: 'copy',
  icon: [448, 512, [], '', 'M301.5 101a31 31 0 0 1 31 31v341a31 31 0 0 1-31 31h-248a31 31 0 0 1-31-31V132a31 31 0 0 1 31-31h248Zm93-93a31 31 0 0 1 30.92 28.69l.08 2.31v341a31 31 0 0 1-28.69 30.92l-2.3.08h-31V70h-248V39a31 31 0 0 1 28.68-30.91L146.5 8h248Z'],
};

export const education: IconDefinition = {
  prefix: 'fas',
  iconName: 'education',
  icon: [576, 512, [], '', 'M428 11c52.66 0 91.75 12.07 115.05 22.03l9 4.1c9.03 4.37 14.47 13.11 15.69 22.9l.26 4.26v403.22c0 14.14-16.73 23.75-29.85 18.5C514.45 476.5 477.13 466 428 466c-41.63 0-74.79 7.54-98.36 15.63l-10.2 3.74c-1.58.62-3.1 1.24-4.57 1.86l-8.11 3.57-6.7 3.26-5.24 2.8L288 501l-4.75-2.95-4.51-2.52-5.97-3.05-7.4-3.44c-1.36-.6-2.77-1.2-4.24-1.81l-9.5-3.73c-23.8-8.75-58.8-17.5-103.63-17.5-49.13 0-86.45 10.5-110.15 20-12.18 4.89-27.48-3.05-29.6-15.53L8 467.5V64.3c0-11.46 5.63-22.17 15.94-27.16C45.36 26.76 87.82 11 148 11c41.63 0 74.79 7.55 98.36 15.63l10.2 3.74c1.58.62 3.1 1.24 4.57 1.85l8.11 3.58 6.7 3.26 5.24 2.8L288 46l4.75-2.95 4.51-2.52 5.97-3.05 7.4-3.44c2.7-1.2 5.65-2.42 8.82-3.67l10.2-3.74C353.2 18.55 386.36 11 428 11Zm0 70c-41.63 0-74.79 7.54-98.36 15.63l-10.2 3.74a233.7 233.7 0 0 0-4.57 1.85l-8.11 3.58-6.7 3.26-5.24 2.8L288 116v315l4.75-2.95 4.51-2.52 5.97-3.05 7.4-3.44c1.36-.6 2.77-1.2 4.24-1.81l9.5-3.73c23.8-8.75 58.8-17.5 103.63-17.5 27 0 50.44 3.18 70 7.6v-315c-19.56-4.43-43-7.6-70-7.6Z'],
};

export const ellipsisH: IconDefinition = {
  prefix: 'fas',
  iconName: 'ellipsis-h',
  icon: [512, 512, [], '', 'M70 318a62 62 0 1 0 0-124 62 62 0 0 0 0 124Zm248-62a62 62 0 1 1-124 0 62 62 0 0 1 124 0Zm186 0a62 62 0 1 1-124 0 62 62 0 0 1 124 0Z'],
};

export const filter: IconDefinition = {
  prefix: 'fas',
  iconName: 'filter',
  icon: [512, 512, [], '', 'M147.5 333.5a77.5 77.5 0 0 1 68.78 41.76l2.27 4.74H473a31 31 0 0 1 3.62 61.8l-3.62.2H218.55a77.52 77.52 0 0 1-139.83 4.74L76.45 442H39a31 31 0 0 1-3.62-61.8L39 380h37.45a77.52 77.52 0 0 1 71.05-46.5Zm217-155a77.52 77.52 0 0 1 71.05 46.5H473a31 31 0 1 1 0 62h-37.45a77.52 77.52 0 0 1-142.1 0H39a31 31 0 1 1 0-62h254.45a77.52 77.52 0 0 1 71.05-46.5Zm-217-155A77.52 77.52 0 0 1 218.55 70H473a31 31 0 1 1 0 62H218.55a77.52 77.52 0 0 1-142.1 0H39a31 31 0 1 1 0-62h37.45a77.52 77.52 0 0 1 71.05-46.5Z'],
};

export const globe: IconDefinition = {
  prefix: 'fas',
  iconName: 'globe',
  icon: [496, 512, [], '', 'M248 16c132.55 0 240 107.45 240 240S380.55 496 248 496 8 388.55 8 256 115.45 16 248 16Zm47.42 330h-94.84a339 339 0 0 0 11.73 36.34c7.51 19.26 15.8 34.2 23.74 43.78 3.22 3.88 5.83 6.24 7.78 7.67l2.43 1.53 1.74.67 1.75-.67 2.42-1.53c1.95-1.44 4.56-3.8 7.78-7.68 7.95-9.56 16.23-24.51 23.74-43.77 2.96-7.58 5.7-15.57 8.19-23.81l3.54-12.53Zm108.5 0h-46.73a414.23 414.23 0 0 1-17.6 58.15l-3.64 8.94A180.87 180.87 0 0 0 403.92 346Zm-265.11 0H92.08c16.2 28 39.73 51.24 67.97 67.09l-3.64-8.94a402.51 402.51 0 0 1-14.67-46.04L138.8 346Zm286.7-120h-58.85a350.96 350.96 0 0 1 1.34 30 351 351 0 0 1-1.34 30h58.85a181.09 181.09 0 0 0 0-60Zm-119.12 0H189.61a299.53 299.53 0 0 0-1.61 30c0 9.52.55 19.62 1.6 30h116.8a299.25 299.25 0 0 0 1.6-30c0-9.52-.55-19.62-1.6-30Zm-177.04 0H70.48a181.2 181.2 0 0 0 0 60h58.86a351 351 0 0 1-1.35-30c0-9.66.46-19.72 1.34-30Zm206.6-127.09 3.64 8.95a404.5 404.5 0 0 1 14.68 46.03l2.92 12.11h46.73a180.86 180.86 0 0 0-67.97-67.09ZM248.9 76.3l-.9-.3-.9.29c-.24.1-.52.22-.84.38l-2.43 1.53c-1.95 1.43-4.56 3.8-7.78 7.67-7.94 9.57-16.23 24.53-23.74 43.78a321.87 321.87 0 0 0-8.19 23.81L200.58 166h94.84a338.72 338.72 0 0 0-11.73-36.34c-7.51-19.25-15.8-34.2-23.74-43.78-3.22-3.88-5.83-6.24-7.78-7.67l-2.42-1.53c-.33-.16-.6-.29-.84-.38Zm-88.85 22.6a180.89 180.89 0 0 0-63.37 59.57l-4.6 7.52h46.73a414.23 414.23 0 0 1 17.6-58.14l3.64-8.95Z'],
};

export const grid: IconDefinition = {
  prefix: 'fas',
  iconName: 'grid',
  icon: [512, 512, [], '', 'M8 39A31 31 0 0 1 39 8h186v217H8V39ZM287 8h186a31 31 0 0 1 31 31v186H287V8ZM8 287h217v217H39a31 31 0 0 1-31-31V287Zm279 0h217v186a31 31 0 0 1-31 31H287V287Z'],
};

export const pauseCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'pause-circle',
  icon: [512, 512, [], '', 'M256 504c136.97 0 248-111.03 248-248S392.97 8 256 8 8 119.03 8 256s111.03 248 248 248Zm-93-341h62v186h-62V163Zm124 0h62v186h-62V163Z'],
};

export const playCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'play-circle',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm-62 155v186l186-93-186-93Z'],
};

export const refresh: IconDefinition = {
  prefix: 'fas',
  iconName: 'refresh',
  icon: [512, 512, [], '', 'M504 256v170.5a46.5 46.5 0 0 1-93 0l.04-14.7c-40.46 47.36-103.1 76.7-170.54 76.7-115.58 0-217-86.14-217-201.5h93c0 55.85 51.25 108.5 124 108.5 43.8 0 79.82-19.09 101.54-46.47l-8.54-.03a46.5 46.5 0 0 1 0-93H504ZM271.5 23.5c115.58 0 217 86.14 217 201.5h-93c0-55.85-51.25-108.5-124-108.5-43.82 0-79.85 19.1-101.56 46.5h8.56a46.5 46.5 0 0 1 0 93H8V85.5a46.5 46.5 0 0 1 93 0l-.01 14.67C141.45 52.83 204.07 23.5 271.5 23.5Z'],
};

export const zoomIn: IconDefinition = {
  prefix: 'fas',
  iconName: 'zoom-in',
  icon: [512, 512, [], '', 'M242.4 269.6a46.57 46.57 0 0 1 0 65.8l-75.65 75.63h42.75A46.47 46.47 0 0 1 256 457.5a46.52 46.52 0 0 1-46.5 46.5H8V302.5a46.52 46.52 0 0 1 93.03 0v42.75l75.58-75.64a46.57 46.57 0 0 1 65.78 0ZM504 8v201.5a46.52 46.52 0 0 1-46.5 46.5 46.47 46.47 0 0 1-46.47-46.5v-42.75L335.4 242.4a46.57 46.57 0 0 1-65.78 0 46.57 46.57 0 0 1 0-65.78l75.64-75.58H302.5a46.52 46.52 0 0 1 0-93.03H504Z'],
};

export const zoomOut: IconDefinition = {
  prefix: 'fas',
  iconName: 'zoom-out',
  icon: [512, 512, [], '', 'M349 54.5a46.5 46.5 0 0 0-93 0V256H54.5a46.5 46.5 0 0 0 0 93h42.74l-75.62 75.62a46.5 46.5 0 0 0 65.76 65.76L163 414.76v42.74a46.5 46.5 0 0 0 93 0V256h201.5a46.5 46.5 0 0 0 0-93h-42.74l75.62-75.62a46.5 46.5 0 0 0-65.76-65.76L349 97.24V54.5Z'],
};

export const plus: IconDefinition = {
  prefix: 'fas',
  iconName: 'plus',
  icon: [448, 512, [], '', 'M180.8 428.8a43.2 43.2 0 1 0 86.4 0V299.2h129.6a43.2 43.2 0 1 0 0-86.4H267.2V83.2a43.2 43.2 0 1 0-86.4 0v129.6H51.2a43.2 43.2 0 0 0 0 86.4h129.6v129.6Z'],
};

export const transferCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'transfer-circle',
  icon: [512, 512, [], '', 'M256 504c136.97 0 248-111.03 248-248S392.97 8 256 8 8 119.03 8 256s111.03 248 248 248Zm2.97-419.22a31 31 0 0 1 4.3 43.63l-34.73 42.34H380a31 31 0 1 1 0 62H97.47L215.34 89.09a31 31 0 0 1 43.63-4.3Zm-5.94 342.43a31 31 0 0 1-4.3-43.62l34.73-42.34H132a31 31 0 1 1 0-62h282.54L296.66 422.91a31 31 0 0 1-43.63 4.3Z'],
};

export const plusCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'plus-circle',
  icon: [512, 512, [], '', 'M256 504c136.97 0 248-111.03 248-248S392.97 8 256 8 8 119.03 8 256s111.03 248 248 248Zm0-62a31 31 0 0 1-31-31V287H101a31 31 0 0 1 0-62h124V101a31 31 0 1 1 62 0v124h124a31 31 0 1 1 0 62H287v124a31 31 0 0 1-31 31Z'],
};

export const minus: IconDefinition = {
  prefix: 'fas',
  iconName: 'minus',
  icon: [448, 512, [], '', 'M8 256a43.2 43.2 0 0 1 43.2-43.2h345.6a43.2 43.2 0 1 1 0 86.4H51.2A43.2 43.2 0 0 1 8 256Z'],
};

export const minusCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'minus-circle',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm155 217H101a31 31 0 0 0 0 62h310a31 31 0 1 0 0-62Z'],
};

export const link: IconDefinition = {
  prefix: 'fas',
  iconName: 'link',
  icon: [512, 512, [], '', 'M126.13 180.42a34.24 34.24 0 0 1 0 48.43L109 245.97C65.64 289.33 65.64 359.63 109 403c43.37 43.36 113.67 43.36 157.03 0l17.12-17.13a34.24 34.24 0 0 1 48.43 48.43l-17.13 17.12c-70.1 70.1-183.76 70.1-253.87 0-70.1-70.1-70.1-183.77 0-253.87l17.12-17.13a34.24 34.24 0 0 1 48.43 0Zm191.26-34.24a34.24 34.24 0 0 1 50.43 46.25l-2 2.18L194.6 365.8a34.24 34.24 0 0 1-50.43-46.24l2-2.18L317.4 146.2Zm134.03-85.6c70.1 70.1 70.1 183.77 0 253.87l-17.12 17.12a34.24 34.24 0 1 1-48.42-48.42L403 266.03c43.36-43.36 43.36-113.66 0-157.03-43.37-43.36-113.67-43.36-157.03 0l-17.12 17.13a34.24 34.24 0 1 1-48.42-48.43l17.12-17.12c70.1-70.1 183.77-70.1 253.87 0Z'],
};

export const image: IconDefinition = {
  prefix: 'fas',
  iconName: 'image',
  icon: [512, 512, [], '', 'M442 8a62 62 0 0 1 61.92 58.9L504 70v372a62 62 0 0 1-58.9 61.92l-3.1.08H70a62 62 0 0 1-61.92-58.9L8 442V70A62 62 0 0 1 66.9 8.08L70 8h372Zm0 62H70v248l93-93 155 155 93-93 31 31V70Zm-124 62a62 62 0 1 1 0 124 62 62 0 0 1 0-124Z'],
};

export const ban: IconDefinition = {
  prefix: 'fas',
  iconName: 'ban',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8ZM118.54 184.3A154.33 154.33 0 0 0 101 256c0 85.6 69.4 155 155 155 23.88 0 46.5-5.4 66.7-15.04l5-2.5L118.54 184.3ZM256 101c-23.88 0-46.5 5.4-66.7 15.04l-5 2.5L393.46 327.7A154.32 154.32 0 0 0 411 256c0-85.6-69.4-155-155-155Z'],
};

export const signOut: IconDefinition = {
  prefix: 'fas',
  iconName: 'escape',
  icon: [512, 512, [], '', 'M131.99 8.02a46.5 46.5 0 0 1 0 93h-31v309.97h31a46.5 46.5 0 1 1 0 92.99h-77.5A46.5 46.5 0 0 1 8 457.48V54.52a46.5 46.5 0 0 1 46.5-46.5h77.49Zm215.14 91.11L504 256 347.13 412.87a46.5 46.5 0 1 1-65.75-65.76l44.61-44.61H190.26a46.5 46.5 0 1 1 0-93H326l-44.61-44.61a46.5 46.5 0 1 1 65.75-65.76Z'],
};

export const closeCircle: IconDefinition = {
  prefix: 'fas',
  iconName: 'close-circle',
  icon: [512, 512, [], '', 'M256 504c136.97 0 248-111.03 248-248S392.97 8 256 8 8 119.03 8 256s111.03 248 248 248ZM125.58 125.58a31 31 0 0 1 43.84 0L256 212.16l86.58-86.58a31 31 0 1 1 43.84 43.84L299.84 256l86.58 86.58a31 31 0 1 1-43.84 43.84L256 299.84l-86.58 86.58a31 31 0 1 1-43.84-43.84L212.16 256l-86.58-86.58a31 31 0 0 1 0-43.84Z'],
};

export const solidClock: IconDefinition = {
  prefix: 'fas',
  iconName: 'solid-clock',
  icon: [512, 512, [], '', 'M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm0 93a31 31 0 0 0-31 31v124a31 31 0 0 0 31 31h124a31 31 0 1 0 0-62h-93v-93a31 31 0 0 0-31-31Z'],
};

export const thumbsUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'thumbs-up',
  icon: [512, 512, [], '', 'M103.78 26.17A31 31 0 0 1 132 8h93a31 31 0 0 1 30.87 33.8l-12.42 136.7H457.5a31 31 0 0 1 31 31v93c0 1.01-.05 2.03-.15 3.04l-11.28 114.58A93 93 0 0 1 384.5 504h-268a93 93 0 0 1-93-93V209.5c0-4.43.95-8.8 2.78-12.83l77.5-170.5ZM151.96 70 85.5 216.22V411a31 31 0 0 0 31 31h268.01a31 31 0 0 0 30.86-27.96l11.13-113.06V240.5h-217a31 31 0 0 1-30.87-33.8L191.05 70h-39.09Z'],
};

export const solidThumbsUp: IconDefinition = {
  prefix: 'fas',
  iconName: 'thumbs-up',
  icon: [512, 512, [], '', 'M 103.78 26.17 A 31 31 0 0 1 132 8 h 93 a 31 31 0 0 1 30.87 33.8 l -12.42 136.7 H 457.5 a 31 31 0 0 1 31 31 v 93 c 0 1.01 -0.05 2.03 -0.15 3.04 l -11.28 114.58 A 93 93 0 0 1 384.5 504 h -268 a 93 93 0 0 1 -93 -93 V 209.5 c 0 -4.43 0.95 -8.8 2.78 -12.83 l 77.5 -170.5 Z Z'],
};

export const thumbsDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'thumbs-down',
  icon: [512, 512, [], '', 'M103.78 485.83A31 31 0 0 0 132 504h93a31 31 0 0 0 30.87-33.8l-12.42-136.7H457.5a31 31 0 0 0 31-31v-93c0-1.01-.05-2.03-.15-3.04L477.07 91.88A93 93 0 0 0 384.5 8h-268a93 93 0 0 0-93 93v201.5c0 4.43.95 8.8 2.78 12.83l77.5 170.5ZM151.96 442 85.5 295.79V101a31 31 0 0 1 31-31h268.01a31 31 0 0 1 30.86 27.96l11.13 113.06v60.48h-217a31 31 0 0 0-30.87 33.8L191.05 442h-39.09Z'],
};

export const solidThumbsDown: IconDefinition = {
  prefix: 'fas',
  iconName: 'thumbs-down',
  icon: [512, 512, [], '', 'M 103.78 485.83 A 31 31 0 0 0 132 504 h 93 a 31 31 0 0 0 30.87 -33.8 l -12.42 -136.7 H 457.5 a 31 31 0 0 0 31 -31 v -93 c 0 -1.01 -0.05 -2.03 -0.15 -3.04 L 477.07 91.88 A 93 93 0 0 0 384.5 8 h -268 a 93 93 0 0 0 -93 93 v 201.5 c 0 4.43 0.95 8.8 2.78 12.83 l 77.5 170.5 Z Z'],
};

export const expand: IconDefinition = {
  prefix: 'fas',
  iconName: 'expand',
  icon: [512, 512, [], '', 'M90.7 435.1A41.3 41.3 0 0 1 8 437.6V77a41.3 41.3 0 0 1 82.6-2.5v360.7ZM504 256 357.9 402.1a41.3 41.3 0 0 1-60.2-56.6l1.7-1.8 46.3-46.4H171a41.3 41.3 0 0 1 0-82.6h174.8l-46.3-46.4a41.3 41.3 0 0 1-1.7-56.7l1.7-1.7a41.3 41.3 0 0 1 56.7-1.7l1.8 1.7L504 256Z'],
};

export const collapse: IconDefinition = {
  prefix: 'fas',
  iconName: 'collapse',
  icon: [512, 512, [], '', 'M90.7 435.1A41.3 41.3 0 0 1 8 437.5l-.1-2.4V77a41.3 41.3 0 0 1 82.6-2.4V435ZM504 256c0 22-17.2 40-38.9 41.3H287.3l46.4 46.4 1.6 1.7a41.3 41.3 0 0 1-58.1 58.6l-2-1.9L129.1 256l146.1-146.1 1.8-1.7c15.5-13.9 39-14 54.7-.2l2 1.9 1.6 1.7c13.9 15.6 14 39 .2 54.7l-1.8 2-46.4 46.4h175.4A41.3 41.3 0 0 1 504 256Z'],
};

export const house: IconDefinition = {
  prefix: 'far',
  iconName: 'house',
  icon: [585, 512, [], '', 'm61.5 248.48-.88.72c-15.49 13.45-39.74 5.1-43.65-15.04a26.65 26.65 0 0 1 9.89-26.2l226.2-185.85a62.19 62.19 0 0 1 78.88 0l226.2 185.86c16.25 12.53 12.83 37.95-6.15 45.76a26.66 26.66 0 0 1-27.61-4.53l-.9-.72v227.08A28.52 28.52 0 0 1 495.07 504H374.23a28.43 28.43 0 0 1-28.43-28.44V326.3H239.2v149.25A28.52 28.52 0 0 1 210.75 504H89.93a28.43 28.43 0 0 1-28.43-28.44V248.48ZM286.82 63.33l-172 141.44v245.91h71.06V301.44A28.52 28.52 0 0 1 214.32 273h156.35a28.5 28.5 0 0 1 28.43 28.43v149.25h71.07v-245.9l-172-141.44a8.88 8.88 0 0 0-11.35 0Z'],
};

export const solidHouse: IconDefinition = {
  prefix: 'fas',
  iconName: 'solid-house',
  icon: [585, 512, [], '', 'm60.02 249.2 18.66-15.28v241.65A28.51 28.51 0 0 0 107.11 504h103.06a28.51 28.51 0 0 0 28.43-28.43V326.31h106.6v149.26A28.51 28.51 0 0 0 373.64 504H476.7a28.51 28.51 0 0 0 28.43-28.43V233.91l18.66 15.29c15.49 13.45 39.73 5.1 43.64-15.05a26.65 26.65 0 0 0-9.88-26.18L331.35 22.11a62.19 62.19 0 0 0-78.9 0L26.27 207.97c-15.49 13.46-10.6 38.63 8.8 45.32a26.65 26.65 0 0 0 24.96-4.1Z'],
};

export const star: IconDefinition = {
  prefix: 'far',
  iconName: 'star',
  icon: [565, 512, [], '', 'M337.23 218.7 282.5 115.32l-54.73 103.37-101.82 16.13 72.77 57.28-11.99 115.35 95.69-53.82 95.83 53.64-11.98-115.21 72.87-57.26-101.9-16.1Zm206.23 21.14-105.24 82.69 15.31 147.26a34.2 34.2 0 0 1-46.89 31.78l-124.14-69.5-120.62 67.86a34.2 34.2 0 0 1-50.41-30.13l15.3-147.27-107-84.23a34.22 34.22 0 0 1 15.61-58.4l147.92-23.43 68.63-129.62a34.2 34.2 0 0 1 61.14 0l68.62 129.62 146.64 23.16a34.22 34.22 0 0 1 15.13 60.2Z'],
};

export const solidStar: IconDefinition = {
  prefix: 'fas',
  iconName: 'solid-star',
  icon: [565, 512, [], '', 'm313.07 26.85 68.62 129.62 146.64 23.16a34.22 34.22 0 0 1 15.13 60.2l-105.24 82.7 15.31 147.26a34.2 34.2 0 0 1-46.89 31.78l-124.14-69.5-120.62 67.86a34.2 34.2 0 0 1-50.41-30.13l15.3-147.27-107-84.23a34.22 34.22 0 0 1 15.61-58.4l147.93-23.43 68.62-129.62a34.21 34.21 0 0 1 61.14 0Z'],
};

export const solidSparkle: IconDefinition = {
  prefix: 'fas',
  iconName: 'solid-sparkle',
  icon: [512, 512, [], '', 'M237.797 21.578a18.695 18.695 0 0 1 6.308-9.435 19.012 19.012 0 0 1 21.718-1.378 18.774 18.774 0 0 1 7.469 8.563l.754 2.25 8.49 25.839c13.571 41.242 36.462 78.867 66.928 110.006 30.465 31.14 67.701 54.97 108.868 69.676l6.607 2.433 25.874 8.421c16.794 5.618 17.547 28.457 2.262 35.574l-2.262.738-25.874 8.433c-41.542 13.719-79.371 36.673-110.593 67.109-31.221 30.434-55.012 67.545-69.548 108.491l-2.262 6.553-8.503 25.827a18.732 18.732 0 0 1-6.335 9.262 18.977 18.977 0 0 1-10.53 4.022 19.002 19.002 0 0 1-10.964-2.653 18.772 18.772 0 0 1-7.478-8.382l-.943-2.249-8.503-25.827c-13.518-41.241-36.367-78.873-66.801-110.022-30.434-31.147-67.645-54.987-108.794-69.697l-6.782-2.41-25.874-8.419c-16.618-5.618-17.372-28.458-2.073-35.576l2.073-.737 26.063-8.432c41.543-13.709 79.372-36.659 110.592-67.093 31.219-30.434 55.001-67.547 69.524-108.495l2.274-6.541 8.503-25.839-.188-.012Z'],
};

export const attach: IconDefinition = {
  prefix: 'fas',
  iconName: 'attach',
  icon: [512, 512, [], '', 'M297.6 14.63C333.56 3.07 382.9 2.6 426.94 46.64c44.04 44.04 43.58 93.39 32.01 129.33-5.57 17.34-13.65 31.68-20.14 41.55a172.04 172.04 0 0 1-11.3 15.24c-.35.42-.64.77-.88 1.03l-.3.35-.11.12-.05.06-.02.02-.02.02-.55.6L303.94 356.6l-.6.49-.6.49-.01.02-.08.05-.1.1-.27.2-.35.28-.39.3a108.98 108.98 0 0 1-9.67 6.53 110.5 110.5 0 0 1-25.73 11.35c-22.44 6.51-54.66 6.72-82.28-20.9-27.63-27.62-27.42-59.84-20.9-82.28a110.44 110.44 0 0 1 11.34-25.74 108.48 108.48 0 0 1 7.1-10.4l.22-.27.1-.11.03-.05.02-.02.02-.02.97-1.2 93.97-93.97a30.96 30.96 0 0 1 43.78 43.79l-91.3 91.3a48.73 48.73 0 0 0-6.78 13.94c-2.2 7.56-2 14.04 5.2 21.25 7.21 7.2 13.7 7.4 21.25 5.22a48.73 48.73 0 0 0 13.94-6.79L380.4 192.57l1.25-1.54c1.3-1.66 3.23-4.23 5.41-7.55 4.45-6.76 9.56-16 12.93-26.47 6.33-19.69 6.84-42.9-16.85-66.59-23.68-23.68-46.9-23.18-66.58-16.84-10.48 3.37-19.71 8.47-26.47 12.92a110.23 110.23 0 0 0-9.1 6.67L134.76 239.41c-11.44 11.45-29.45 38.8-35.47 71.3-5.69 30.72-.77 65.39 31.6 97.76 32.37 32.37 67.04 37.29 97.76 31.6 32.5-6.02 59.86-24.03 71.3-35.47l123.83-123.83a30.96 30.96 0 0 1 43.79 43.78l-.02.02-123.82 123.81c-19.51 19.52-57.94 44.08-103.8 52.57-47.65 8.83-103.93.2-152.82-48.7-48.9-48.9-57.52-105.16-48.7-152.81 8.5-45.86 33.05-84.3 52.57-103.8L238.03 48.57l.58-.58.6-.54.03-.02.02-.02.05-.05.13-.11.34-.3 1.03-.9a171.48 171.48 0 0 1 15.24-11.29c9.88-6.48 24.22-14.56 41.56-20.14Z'],
};

export const rocket: IconDefinition = {
  prefix: 'far',
  iconName: 'rocket',
  icon: [512, 512, [], '', ['M343.07 63.87c-38.04 17.87-72.4 49.3-103.23 93.75-22.67 32.68-43.23 72.1-61.78 117.67a945.84 945.84 0 0 0-13.54 35.3l35.4 36.14c14.04-5.2 27.37-10.43 40.03-15.68 48.1-19.94 86.45-40.14 116.85-60.35 59.73-39.7 88.36-79.15 100.75-116.76 11.22-34.06 9.86-68.79 3.03-104.65-44.06-7.27-82.88-1.7-117.51 14.58ZM147.66 249.02c12.68-29.88 26.38-57.48 41.22-82.52L116 167.87l-53.5 81.14h85.16Zm66.52-121.16c32.21-44.34 69.49-78.24 112.66-98.53 46-21.61 97.35-27.13 153.83-15.05a19.08 19.08 0 0 1 14.64 14.54c9.51 43.04 14.08 89.69-1.53 137.06-15.03 45.62-47.93 89.47-107.6 130.98l-2.83 109.15a19.08 19.08 0 0 1-8.36 15.3l-117.02 79.4a19.08 19.08 0 0 1-29.79-15.79V376.84c-8.68 3.34-17.61 6.67-26.8 9.98a19.08 19.08 0 0 1-20.1-4.6l-52.35-53.44a19.08 19.08 0 0 1-4.39-19.63 1065 1065 0 0 1 7.94-21.98H27.08a19.07 19.07 0 0 1-15.93-29.58l78.53-119.1a19.08 19.08 0 0 1 15.56-8.58l108.94-2.05Zm52.16 233.47v87.6l79.12-53.7 1.92-73.91c-23.65 13.58-50.54 26.92-81.04 40Z', 'M357.76 205.13a50.86 50.86 0 0 0 50.87-50.87 50.86 50.86 0 0 0-50.87-50.87 50.86 50.86 0 0 0-50.88 50.87 50.86 50.86 0 0 0 50.88 50.87Z']],
};

export const rocketBlastOff: IconDefinition = {
  prefix: 'far',
  iconName: 'rocket-blast-off',
  icon: [512, 512, [], '', ['M343.07 63.87c-38.04 17.87-72.4 49.3-103.23 93.75-22.67 32.68-43.23 72.1-61.78 117.67a948.27 948.27 0 0 0-13.54 35.3l35.4 36.14c14.04-5.2 27.37-10.43 40.03-15.68 48.1-19.94 86.45-40.14 116.85-60.35 59.73-39.7 88.36-79.15 100.75-116.76 11.22-34.06 9.86-68.79 3.03-104.65-44.06-7.27-82.88-1.7-117.51 14.58ZM147.66 249.02c12.68-29.88 26.38-57.48 41.22-82.52L116 167.87l-53.5 81.15h85.16Zm66.52-121.16c32.21-44.34 69.49-78.24 112.66-98.53C372.85 7.72 424.2 2.2 480.67 14.28a19.08 19.08 0 0 1 14.64 14.54c9.51 43.04 14.08 89.69-1.52 137.06-15.04 45.62-47.94 89.47-107.61 130.98l-2.83 109.15a19.08 19.08 0 0 1-8.36 15.3l-117.02 79.4a19.08 19.08 0 0 1-29.79-15.79V376.84c-8.68 3.34-17.61 6.67-26.8 9.98a19.08 19.08 0 0 1-20.1-4.6l-52.35-53.44a19.08 19.08 0 0 1-4.39-19.63c2.6-7.44 5.24-14.77 7.94-21.98H27.08a19.08 19.08 0 0 1-15.93-29.58l78.53-119.1a19.08 19.08 0 0 1 15.56-8.58l108.94-2.05Zm52.16 233.47v87.6l79.12-53.7 1.92-73.91c-23.65 13.58-50.54 26.92-81.04 40Z', 'M306.87 154.26a50.87 50.87 0 1 1 101.74 0 50.87 50.87 0 0 1-101.74 0Z', 'M77.04 443.82c-7.01 2.88-14.02-4.13-11.14-11.14l33.82-82.36a8.53 8.53 0 0 1 13.93-2.8l48.54 48.55a8.53 8.53 0 0 1-2.8 13.93l-82.35 33.82Z']],
};

export const feedback: IconDefinition = {
  prefix: 'far',
  iconName: 'feedback',
  icon: [512, 512, [], '', ['M8 8v496h34.17l113.5-54.15H504V8H8Zm62.44 59.7h371.12v322.45H140.95l-70.5 33.64V67.69Z', 'm371.3 133.23 8.8 58.5H131.9l8.8-58.5h230.6Z', 'm371.3 256.75 8.8 58.5H131.9l8.8-58.5h230.6Z']],
};
