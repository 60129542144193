// the majority of the styles for this component are from the button styles.
// these are the styles that override what the button was setting
const peekabooStickyTab = {
  borderRadius: '16px',
  verticalBoxShadow: '-1px 0 1px rgb(35 47 63 / 30%)',
  hover: {
    verticalBoxShadow: '-1px 0 2px rgb(35 47 63 / 0.5)',
  },
  active: {
    verticalBoxShadow: '-1px 0 2px rgb(35 47 63 / 0.7)',
  },
};

export default peekabooStickyTab;
