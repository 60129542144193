/* eslint-disable id-length */
import { DateRange, ISODateRange } from '../types';
import { endOfDayAtTz, startOfDayAtTz } from './timezoneUtils';

/** Formats the selected range to start at the beginning of
 * the selected day and end at the end of the selected day
 * relative to time zone.
 */
const formatRangeToTimeZone = (range: ISODateRange, zone: string): DateRange => ({
  from: range.from ? startOfDayAtTz(range.from, zone) : undefined,
  to: range.to ? endOfDayAtTz(range.to, zone) : undefined,
});

export default formatRangeToTimeZone;
