/* eslint-disable quotes, quote-props, comma-dangle, max-len */
import { lazy } from 'react';
import DefaultTranslationComp from './en-US'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Translation } from './types';

export type { Translations } from './types';

const translationImports = {
  'ar-AE': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--ar-AE" */ './ar-AE'),
  'de-DE': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--de-DE" */ './de-DE'),
  'en-AU': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--en-AU" */ './en-AU'),
  'en-CA': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--en-CA" */ './en-CA'),
  'en-GB': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--en-GB" */ './en-GB'),
  'en-IN': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--en-IN" */ './en-IN'),
  'en-SG': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--en-SG" */ './en-SG'),
  'es-CO': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--es-CO" */ './es-CO'),
  'es-ES': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--es-ES" */ './es-ES'),
  'es-MX': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--es-MX" */ './es-MX'),
  'es-US': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--es-US" */ './es-US'),
  'fr-CA': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--fr-CA" */ './fr-CA'),
  'fr-FR': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--fr-FR" */ './fr-FR'),
  'hi-IN': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--hi-IN" */ './hi-IN'),
  'it-IT': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--it-IT" */ './it-IT'),
  'ja-JP': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--ja-JP" */ './ja-JP'),
  'ko-KR': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--ko-KR" */ './ko-KR'),
  'nl-NL': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--nl-NL" */ './nl-NL'),
  'pl-PL': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--pl-PL" */ './pl-PL'),
  'pt-BR': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--pt-BR" */ './pt-BR'),
  'sv-SE': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--sv-SE" */ './sv-SE'),
  'ta-IN': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--ta-IN" */ './ta-IN'),
  'th-TH': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--th-TH" */ './th-TH'),
  'tr-TR': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--tr-TR" */ './tr-TR'),
  'vi-VN': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--vi-VN" */ './vi-VN'),
  'zh-CN': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--zh-CN" */ './zh-CN'),
  'zh-TW': () => import(/* webpackChunkName: "storm-ui--AbandonmentDialog--zh-TW" */ './zh-TW')
};

const translationComps: Record<string, ReturnType<typeof lazy<Translation>>> = Object
  .entries(translationImports)
  .reduce((acc, [localCode, importFn]) => (
    { ...acc, [localCode]: lazy(importFn) }
  ), {})

export function getTranslationComponent(locale?: string) {
  return {
    component: (locale && locale in translationComps) ? translationComps[locale] : DefaultTranslationComp,
    locale: (locale && locale in translationComps) ? locale : 'en-US',
    translationImports
  };
}
