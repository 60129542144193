import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { solidSparkle } from '@amzn/storm-ui-icons-v3';
import Icon from '../Icon/Icon';
import { ThemeType } from '../theme/ThemeProvider';

const SparkleGroup = styled('div')`
  position: relative;
  padding: 0.8em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const sparkleColorAnimation = (theme: ThemeType) => keyframes`
  0% {
    color: ${theme.palette.blue[600]};
  }

  50% {
    color: ${theme.palette.cyan[500]};
  }

  100% {
    color: ${theme.palette.blue[600]};
  }
`;

const sparkleSpinAnimation = keyframes`
  0% {
    transform: rotate(-270deg) translateX(0.4em) rotate(270deg) scale(1);
  }

  50% {
    transform: rotate(-450deg) translateX(0.5em) rotate(450deg) scale(0.15);
  }

  100% {
    transform: rotate(-630deg) translateX(0.4em) rotate(630deg) scale(1);
  }
`;

const spinAnimation = css`
  animation-name: ${sparkleSpinAnimation};
`;

const aiAnimation = css`
  animation-name: ${sparkleSpinAnimation}, ${({ theme }) => sparkleColorAnimation(theme)};
`;

const AIGenIcon = styled(Icon)`
  position: absolute;
  ${({ color }) => color === 'ai' ? aiAnimation : spinAnimation}
  animation-duration: 1800ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const Sparkle1 = styled(AIGenIcon)``;

const Sparkle2 = styled(AIGenIcon)`
  animation-delay: -0.6s;
`;

const Sparkle3 = styled(AIGenIcon)`
  animation-delay: -1.2s;
`;

export interface AISparkleIconProps {
  loading?: boolean;
  color?: 'ai' | 'default';
}

const AISparkleIcon = ({ loading, color }: AISparkleIconProps) => loading ? (
  <SparkleGroup data-testid="storm-ui-icon-sparkle-group">
    <Sparkle1 type={solidSparkle} color={color} />
    <Sparkle2 type={solidSparkle} color={color} />
    <Sparkle3 type={solidSparkle} color={color} />
  </SparkleGroup>
) : <div style={{ width: '1.6em' }}><Icon type={solidSparkle} /></div>;

export default AISparkleIcon;
