import React, {
  PureComponent,
  ReactNode,
  MouseEvent as ReactMouseEvent,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import type { Theme as MobileTheme } from '../../theme/themes/mobile';
import { ButtonProps } from '../../Button/Button';
import { createStormTaktId, TaktIdConsumer } from '../../TaktIdContext';
import Tab, { tabMarginsBase, tabMarginsSmall } from './Tab';
import type { TabSize } from './Tab';

const getMobileMargin = () => ({ theme, size }: {theme: MobileTheme, size: string }): string => (
  size === 'small' ? theme.navigation.tabs.mobile.marginEndSmall : theme.navigation.tabs.mobile.marginEndBase
);

export interface NavItemProps {
  size?: TabSize;
  $isActive?: boolean;
  $selectable?: boolean;
}

const NavItem = styled.li<NavItemProps>`
  display: inline-flex;
  align-items: baseline;
  position: relative;

  ${({ theme, size }) => {
    switch (size) {
      case 'small': return tabMarginsSmall(theme);
      default: return tabMarginsBase(theme);
    }
  }};

  ${({ $isActive, theme }) => $isActive && css`
    ::after {
      position: absolute;
      content: '';
      height: 3px;
      margin: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${theme.card.tabs.activeBorder};
      border-radius: 2px 2px 0 0;
    }
  `}

  ${({ $isActive, $selectable, theme }) => !$isActive && $selectable && css`
    :hover::after {
      position: absolute;
      content: '';
      height: 3px;
      margin: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${theme.card.tabs.activeBorder};
      border-radius: 2px 2px 0 0;
    }
  `}

  ${isMobile(css`
    margin-inline-end: ${getMobileMargin()};
    white-space: nowrap;
  `)}
`;
NavItem.displayName = 'NavItem';

export interface TabbedNavigationItemProps extends ButtonProps {
  /**
     * The page unique identifier of the TabbedNavigationItem.
     * @defaultValue `undefined`
     */
  id?: string;
  /**
     * The label of the navigation item.
     */
  label: ReactNode;
  /**
     * Sets the navigation item to active.
     * @defaultValue `false`
     */
  active?: boolean;
  /**
     * If an href is passed, the rendered element will be an <a> tag instead of a <button>.
     * @defaultValue `undefined`
     */
  href?: string;
  /**
     * Handles the click event fired when a navigation item is clicked.
     * @defaultValue `undefined`
     */
  onClick?: (event: ReactMouseEvent, ...args: any[]) => void;
  /**
     * The size of the navigation item.
     * @defaultValue `"base"`
     */
  size?: TabSize;
  /**
     * Render a component before the clickable navigation Item
     * @defaultValue `undefined`
     */
  renderStart?: () => JSX.Element,
  /**
     * Render a component after the clickable navigation Item
     * @defaultValue `undefined`
     */
  renderEnd?: () => JSX.Element,
}

export default class TabbedNavigationItem extends PureComponent<TabbedNavigationItemProps> {
  static propTypes = {
    /**
     * The page unique identifier of the TabbedNavigationItem.
     */
    id: PT.string,
    /**
     * The label of the navigation item.
     */
    label: PT.node.isRequired,
    /**
     * Sets the navigation item to active.
     */
    active: PT.bool,
    /**
     * If an href is passed, the rendered element will be an <a> tag instead of a <button>.
     */
    href: PT.string,
    /**
     * Handles the click event fired when a navigation item is clicked.
     */
    onClick: PT.func,
    /**
     * The size of the navigation item.
     */
    size: PT.oneOf(['base', 'small']),
    /**
     * Render a component before the clickable navigation Item
     */
    renderStart: PT.func,
    /**
     * Render a component after the clickable navigation Item
     */
    renderEnd: PT.func,
  }

  static defaultProps = {
    id: undefined,
    active: false,
    href: undefined,
    onClick: undefined,
    size: 'base',
    renderStart: undefined,
    renderEnd: undefined,
  }

  render(): JSX.Element {
    const {
      id,
      active,
      label,
      href,
      onClick,
      renderStart,
      renderEnd,
      size,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tabbed-navigation-item')}>
        {({ getDataTaktAttributes }) => (
          <NavItem
            id={id}
            role="presentation"
            size={size}
            $isActive={active}
            $selectable={!!href || !!onClick}
          >
            {renderStart && renderStart()}
            <Tab
              {...rest}
              active={active}
              dataTaktAttributes={getDataTaktAttributes()}
              href={href}
              label={label}
              onClick={onClick}
              size={size}
            />
            {renderEnd && renderEnd()}
          </NavItem>
        )}
      </TaktIdConsumer>
    );
  }
}
