import React, {
  FC,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  MouseEvent,
  KeyboardEvent,
  isValidElement,
} from 'react';
import PT, { Validator } from 'prop-types';
import { Placement } from '@popperjs/core';
import {
  focusFirstElement,
  isEventFromWithinElement,
  keyboardKeynames as keys,
  MergeStyledComponentElementProps,
  noop,
  trapTabFocus,
} from '@amzn/storm-ui-utils';
import isShadowRootInstance from '../prop-types/isShadowRoot';
import useInlinePopper from './useInlinePopper';
import { PopperTrigger } from './Popper.styles';
import PopperContainer from './PopperContainer';
import PopperContentContainer from './PopperContentContainer/PopperContentContainer';
import {
  Alignment, PaddingValue, Position, PositioningStrategy,
} from './types';
import { SurfaceType } from '../Surface/types';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);

export interface PopperProps extends TaktProps, MergeStyledComponentElementProps<'div'>{
  /**
   * Specifies whether the `<Popper/>` is visible.
   */
  isOpen: boolean;
  /**
   * Justifies the `<Popper/>` relative to the trigger.
   * @defaultValue `"center"`
   */
  align?: Alignment;
  /**
   * @defaultValue `undefined`
   */
  anchorEl?: string; // TODO: not used anymore
  /**
   * Moves focus to the first focusable element inside the `<Popper/>` element.
   * @defaultValue `false`
   */
  autoFocus?: boolean;
  /**
   * The content of the `<Popper/>` component.
   * @defaultValue `undefined`
   */
  children: ReactNode;
  /**
   * Screen reader label for the `<Popper/>` close button. Required when
   * rendering the close button for the `<Popper/>` to be accessible.
   * @defaultValue `Close Popover`
   */
  closeButtonLabel?: string;
  /**
   * Renders the `<Popper/>` inline with the trigger.
   * @defaultValue `false`
   */
  disablePortal?: boolean;
  /**
   * Sets whether the body of the underlying Popper component is focusable when navigating with a keyboard.
   * @defaultValue `false`
   */
  focusableBody?: boolean;
  /**
   * Callback that is called when the specified trigger element is clicked.
   * @defaultValue `undefined`
   */
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  /** TODO: This should also accept a KeyboardEvent. Fix this in Storm 4.x */
  /**
   * Callback that is called when the close button on the `<Popper/>` element is clicked.
   * Use React.useCallback so the same instance of the function is used on re-render.
   * @defaultValue `() => undefined`
   */
  onCloseButtonClick?: (event: MouseEvent<HTMLButtonElement> | any) => void;
  /**
   * Set the padding of the `<Popper/>` element.
   * @defaultValue `"base"`
   */
  padding?: PaddingValue
  /**
   * Positions the `<Popper/>` element relative to the trigger.
   * @defaultValue `"top"`
   */
  position?: Position;
  /**
   * Fine grain control over the space between the PIP and the anchor.
   * @defaultValue `0`
   */
  spacing?: number;
  /**
   * This element will render the `<Popper/>` when clicked. The `<Popper/>` will be
   * positioned and aligned relative to this element. It can also be a React Ref.
   */
  trigger: ReactElement | RefObject<HTMLElement>;
  /**
   * Specifies the stylistic theme for the `<Popper/>`.
   * @defaultValue `"light"`
   */
  type?: SurfaceType;
  /**
   * Specifies whether to render the `<Popper/>` component with the arrow pip.
   * @defaultValue `true`
   */
  withArrow?: boolean;
  /**
   * Specifies whether the close button should render.
   * @defaultValue `false`
   */
  withCloseButton?: boolean;
  /**
   * A React Ref that contains the HTMLElement this popper will be rendered into.
   * @defaultValue `undefined`
   */
  portalRef?: RefObject<HTMLElement>;
  /**
   * A React Ref to a element that popper will attempt to stay inside by flipping
   * @defaultValue `undefined`
   */
  boundaryRef?: RefObject<HTMLElement>;
  /**
   * A React Ref to the Popper container element
   * @defaultValue `undefined`
   */
  popperRef?: RefObject<HTMLElement>;
  /**
   * *Only required for `closed` shadow roots*
   * By default an mousedown event is added to document to close the
   * popper on click on outside of popper. But while using with shadow
   * DOM, events originating from nodes inside of the shadow DOM are
   * re-targeted so they appear to come from the shadow host. Due to
   * this popper gets closed on click on content inside popper.
   * This prop can be used to add event listener to the shadow DOM.
   * @defaultValue `undefined`
   */
  shadowRoot?: EventTarget;
  /**
   * Describes the positioning strategy to use. By default, it is absolute,
   * which in the simplest cases does not require repositioning of the popper.
   * If your reference element is in a fixed container, use the fixed strategy:
   * @defaultValue `"absolute"`
   */
  strategy?: PositioningStrategy;
}

const Popper: FC<React.PropsWithChildren<PopperProps>> = ({
  align = 'center',
  autoFocus = false,
  children,
  closeButtonLabel = 'Close Popover',
  disablePortal,
  isOpen,
  onClick,
  onCloseButtonClick = noop,
  position = 'top',
  padding = 'base',
  spacing = 0,
  trigger,
  type = 'light',
  withCloseButton = false,
  withArrow = true,
  portalRef,
  boundaryRef,
  popperRef,
  shadowRoot,
  strategy = 'absolute',
  taktId,
  taktValue,
  ...rest
}) => {
  const placement: Placement = align === 'center' ? position : `${position}-${align}`;

  const {
    attributes,
    styles,
    triggerElement,
    popperElement,
    setArrowElement,
    setPopperElement,
    setTriggerElement,
    update,
  } = useInlinePopper({
    offsetDistance: spacing,
    placement,
    enableFlip: true,
    preventOverflow: {
      mainAxis: true,
    },
    withArrow,
    boundaryRef,
    strategy,
  });

  useEffect(() => {
    if (!isValidElement(trigger)) {
      if (trigger?.current) {
        setTriggerElement(trigger.current);
      }
    }
  }, [setTriggerElement, trigger]);

  /**
   * Handles mouse clicks on the popover close button.
   */
  const handleCloseClicked = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    // call the callback function passed via props.
    onCloseButtonClick(event);
  }, [onCloseButtonClick]);

  /**
   * Click on outside popover and trigger should close the popover
   */
  const handleDocumentMousedown = useCallback((event: Event) => {
    if (
      typeof onCloseButtonClick === 'function'
      && !isEventFromWithinElement(event, popperElement)
      && !isEventFromWithinElement(event, triggerElement)
    ) {
      /** TODO: Fix this type in Storm 4.x */
      onCloseButtonClick(event as unknown as MouseEvent<HTMLButtonElement>);
    }
  }, [onCloseButtonClick, popperElement, triggerElement]);

  /**
   * Handles keyboard KeyDown input when the popover surface is in focus.
   */
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (autoFocus && popperElement) {
      // Trap the focus of the browser inside the popover element. Keep the user in this element
      // and allow them to tab through content until the user closes the <Popover />.
      trapTabFocus(event, popperElement, false);
    }

    if (key === keys.SPACE) {
      event.stopPropagation();
    }
  }, [autoFocus, popperElement]);

  /**
   * Handles keyboard input when the popover surface is in focus.
   */
  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    // Close the popover if the Escape key is pressed
    if (key === keys.ESCAPE) {
      event.stopPropagation();
      onCloseButtonClick(event);
    }
  }, [onCloseButtonClick]);

  /**
   * Catches the click event from the popover trigger and only
   * call the 'onClick' callback if it was passed.
   */
  const preventTriggerClickThrough = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  /**
   * Function called once the <Transition /> component is in the entered state.
   *
   * If we are showing focus because we have had some keyboard input, we should set
   * focus to the Popover element.
   */
  const handlePopoverEntered = useCallback(() => {
    if (autoFocus && popperElement) {
      focusFirstElement(popperElement);
    }
  }, [autoFocus, popperElement]);

  const handlePopoverEntering = useCallback((node: unknown, isAppearing: boolean) => {
    if (!isAppearing && update !== null) {
      update();
    }
  }, [update]);

  /**
   * Function called once the <Transition /> component is in the exited state.
   */
  const handlePopoverExited = useCallback(() => {
    if (autoFocus && triggerElement) {
      focusFirstElement(triggerElement);
    }
  }, [autoFocus, triggerElement]);

  return (
    <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('popper')}>
      {isValidElement(trigger) && (
        <PopperTrigger
          onClick={preventTriggerClickThrough}
          ref={setTriggerElement}
        >
          {trigger}
        </PopperTrigger>
      )}
      <PopperContainer
        {...rest}
        attributes={attributes}
        closeButtonLabel={closeButtonLabel}
        disablePortal={disablePortal}
        handleCloseClicked={handleCloseClicked}
        handleKeyDown={handleKeyDown}
        handleKeyUp={handleKeyUp}
        handlePopoverEntered={handlePopoverEntered}
        handlePopoverEntering={handlePopoverEntering}
        handlePopoverExited={handlePopoverExited}
        isOpen={isOpen}
        onDocumentMousedown={handleDocumentMousedown}
        position={position}
        setArrowElement={setArrowElement}
        setPopperElement={setPopperElement}
        shadowRoot={shadowRoot}
        styles={styles}
        type={type}
        withCloseButton={withCloseButton}
        withArrow={withArrow}
        portalRef={portalRef}
        popperRef={popperRef}
      >
        <PopperContentContainer
          closeButtonLabel={closeButtonLabel}
          handleCloseClicked={handleCloseClicked}
          padding={padding}
          type={type}
          withCloseButton={withCloseButton}
        >
          {children}
        </PopperContentContainer>
      </PopperContainer>
    </TaktIdProvider>
  );
};

Popper.propTypes = {
  /**
   * This element will render the `<Popper/>` when clicked. The `<Popper/>` will be
   * positioned and aligned relative to this element. It can also be a React Ref.
   */
  trigger: PT.oneOfType([
    PT.shape(
      { current: (PT.instanceOf(NodeSafeHTMLElement)) },
    ) as Validator<RefObject<HTMLElement>>,
    PT.element,
  ]).isRequired as PT.Validator<RefObject<HTMLElement> | ReactElement>,
  /**
   * Justifies the `<Popper/>` relative to the trigger.
   */
  align: PT.oneOf(['start', 'center', 'end']),
  /**
   * Moves focus to the first focusable element inside the `<Popper/>` element.
   */
  autoFocus: PT.bool,
  /**
   * The content of the `<Popper/>` component.
   */
  children: PT.node as PT.Validator<ReactNode>,
  /**
   * Renders the `<Popper/>` inline with the trigger.
   */
  disablePortal: PT.bool,
  /**
   * The unique id attribute that is supplied to the `<Popper/>` element. Useful
   * for identifying the `<Popper/>` in front-end telemetry.
   */
  id: PT.string,
  /**
   * Specifies whether the `<Popper/>` is visible.
   */
  isOpen: PT.bool.isRequired,
  /**
   * Callback that is called when the specified trigger element is clicked.
   */
  onClick: PT.func,
  /**
   * Callback that is called when the close button on the `<Popper/>` element is clicked.
   * Use React.useCallback so the same instance of the function is used on re-render.
   */
  onCloseButtonClick: PT.func,
  /**
   * Set the padding of the `<Popper/>` element.
   */
  padding: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  /**
   * Positions the `<Popper/>` element relative to the trigger.
   */
  position: PT.oneOf(['top', 'right', 'bottom', 'left']),
  /**
   * Fine grain control over the space between the PIP and the anchor.
   */
  spacing: PT.number,
  /**
   * Specifies the stylistic theme for the `<Popper/>`.
   */
  type: PT.oneOf(['light', 'dark', 'blue']),
  /**
   * Specifies whether the close button should render.
   */
  withCloseButton: PT.bool,
  anchorEl: PT.string,
  /**
   * Screen reader label for the `<Popper/>` close button. Required when
   * rendering the close button for the `<Popper/>` to be accessible.
   */
  closeButtonLabel: PT.string,
  /**
   * Specifies whether to render the `<Popper/>` component with the arrow pip.
   */
  withArrow: PT.bool,
  /**
   * A React Ref that contains the HTMLElement this popper will be rendered into.
   */
  portalRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<RefObject<HTMLElement>>,
  /**
   * A React Ref to a element that popper will attempt to stay inside by flipping
   */
  boundaryRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<RefObject<HTMLElement>>,
  /**
   * A React Ref to the Popper container element
   */
  popperRef: PT.shape(
    { current: (PT.instanceOf(NodeSafeHTMLElement)) },
  ) as Validator<RefObject<HTMLElement>>,
  /**
   * *Only required for `closed` shadow roots*
   * By default an mousedown event is added to document to close the
   * popper on click on outside of popper. But while using with shadow
   * DOM, events originating from nodes inside of the shadow DOM are
   * re-targeted so they appear to come from the shadow host. Due to
   * this popper gets closed on click on content inside popper.
   * This prop can be used to add event listener to the shadow DOM.
   */
  shadowRoot: isShadowRootInstance,
  /**
   * Describes the positioning strategy to use. By default, it is absolute,
   * which in the simplest cases does not require repositioning of the popper.
   * If your reference element is in a fixed container, use the fixed strategy:
   */
  strategy: PT.oneOf(['absolute', 'fixed']),
  /**
   * Sets whether the body of the underlying Popper component is focusable when navigating with a keyboard.
   */
  focusableBody: PT.bool,
};

Popper.defaultProps = {
  align: 'center',
  anchorEl: undefined,
  autoFocus: false,
  children: undefined,
  closeButtonLabel: 'Close Popover',
  disablePortal: false,
  id: undefined,
  onClick: undefined,
  onCloseButtonClick: noop,
  padding: 'base',
  position: 'top',
  spacing: 0,
  type: 'light',
  withCloseButton: false,
  withArrow: true,
  portalRef: undefined,
  boundaryRef: undefined,
  popperRef: undefined,
  shadowRoot: undefined,
  strategy: 'absolute',
  focusableBody: false,
};

export default Popper;
