import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled('div')`
  align-items: center;
  display: flex;
  gap: 0.15em;
`;

const wave = keyframes`
  40% {
      transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.5em);
  }
  65% {
    transform: translateY(-0.5em);
  }
  90% {
      transform: translateY(0.05em);
  }
  100% {
      transform: translateY(0em);
  }
`;

const Dot = styled('div')`
  border-radius: 100%;
  height: 0.4em;
  width: 0.4em;

  animation-name: ${wave};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const Dot1 = styled(Dot)`
  animation-delay: 0.3s;
  background: ${({ theme }) => theme.palette.cyan[500]}
`;

const Dot2 = styled(Dot)`
  animation-delay: 0.45s;
  background: ${({ theme }) => `linear-gradient(90deg, ${theme.palette.cyan[500]} 0%, ${theme.palette.blue[600]} 100%)`};
`;

const Dot3 = styled(Dot)`
  animation-delay: 0.6s;
  background: ${({ theme }) => theme.palette.blue[600]}
`;

const AITypingIcon = () => (
  <Container>
    <Dot1 />
    <Dot2 />
    <Dot3 />
  </Container>
);

export default AITypingIcon;
