/* eslint-disable id-length */
import { palette } from './color';
import { mobileSpacing, spacing } from './spacing';

export const fontFamily = 'Amazon Ember, Helvetica, sans-serif';

/*
* These are the font size raw values in px that are the default desktop constants.
*/
export const fontSizeValues = {
  extraLarge: 28,
  large: 21,
  medium: 17,
  base: 13,
  small: 12,
  mini: 11,
  tiny: 10,
};

// These are the font size raw values in px that are specific to mobile.
export const mobileFontSizeValues = {
  extraLarge: 24,
  large: 22,
  medium: 18,
  base: 15,
  small: 13,
  mini: 12,
  tiny: 11,
};

/*
 * Semantic sizes for fonts using English terms.
 * These are exported to customers for their use.
 */
export const fontSizes = {
  extraLarge: `${fontSizeValues.extraLarge}px`,
  large: `${fontSizeValues.large}px`,
  medium: `${fontSizeValues.medium}px`,
  base: `${fontSizeValues.base}px`,
  small: `${fontSizeValues.small}px`,
  mini: `${fontSizeValues.mini}px`,
  tiny: `${fontSizeValues.tiny}px`,
};

/*
 * Semantic sizes for fonts for mobile using English terms. These are exported to customers
 * for their use.
 */
export const mobileFontSizes = {
  extraLarge: `${mobileFontSizeValues.extraLarge}px`,
  large: `${mobileFontSizeValues.large}px`,
  medium: `${mobileFontSizeValues.medium}px`,
  base: `${mobileFontSizeValues.base}px`,
  small: `${mobileFontSizeValues.small}px`,
  mini: `${mobileFontSizeValues.mini}px`,
  tiny: `${mobileFontSizeValues.tiny}px`,
};

/*
 * Typography styles specific to list,
 * used specifically for OrderedList and UnorderedList components.
 */
export const list = {
  margin: '0 0 0 1.5em',
};

/*
 * Typography styles specific to links, used specifically for <a> tags and Link components.
 */
export const link = {
  color: palette.blue[700],
  hoverColor: palette.blue[800],
  activeColor: palette.blue[900],
  decoration: 'none',
  hoverDecoration: 'underline',
  outlineOffset: 0,
  base: `${fontSizeValues.base}px`,
  small: `${fontSizeValues.small}px`,
  mini: `${fontSizeValues.mini}px`,
  tiny: `${fontSizeValues.tiny}px`,
  /*
   * When using alternative background colors, links must have additional selector specificity
   * to override AUI rules. Existing applications depend on legacy link specificity, so it
   * must only be applied to links within <SurfaceXxxx> containers
   */
  overrideAuiLinkStyle: false,
};

/*
 * Typography styles specific to mobile links, used specifically for <a> tags and Link components.
 */
export const mobileLink = {
  base: `${mobileFontSizeValues.base}px`,
  small: `${mobileFontSizeValues.small}px`,
  mini: `${mobileFontSizeValues.mini}px`,
  tiny: `${mobileFontSizeValues.tiny}px`,
};

/*
 * This is the primary object that the theme imports. Changing this will change the structure of
 * the theme.
 */
export const typography = {
  /* legacy named values */
  fontSizeValues,
  size: fontSizes,
  color: {
    base: palette.squidInk, // Deprecate
    primary: palette.squidInk,
    secondary: palette.gray[800],
    tertiary: palette.gray[700],
    warning: palette.squidInk,
    success: palette.green[700],
    error: palette.red[600],
  },
  body: {
    bodyFontColor: palette.squidInk,
    bodyFontFamily: fontFamily,
    bodyFontSize: `${fontSizeValues.base}px`,
  },
  h1: `
    font-size: ${fontSizeValues.extraLarge}px;
    line-height: 1.2;
    font-weight: normal;
    font-family: ${fontFamily};
    padding: 0;
  `,
  h1Margin: '0.67em 0 0.34em 0',

  h2: `
    font-size: ${fontSizeValues.large}px;
    line-height: 1.3;
    font-weight: 700;
    font-family: ${fontFamily};
    padding: 0;
  `,
  h2Margin: '0.83em 0 0.42em 0',

  h3: `
    font-size: ${fontSizeValues.medium}px;
    line-height: 1.255;
    font-weight: 700;
    font-family: ${fontFamily};
    padding: 0;
  `,
  h3Margin: '1em 0 0.5em 0',

  h4: `
    font-size: ${fontSizeValues.medium}px;
    line-height: 1.255;
    font-weight: normal;
    font-family: ${fontFamily};
    padding: 0;
  `,
  h4Margin: '1.33em 0 0.67em 0',

  h5: `
    font-size: ${fontSizeValues.base}px;
    line-height: 1.465;
    font-weight: 700;
    font-family: ${fontFamily};
    padding: 0;
  `,
  h5Margin: '1.67em 0 0.84em 0',
  h6: `
    font-size: ${fontSizeValues.mini}px;
    line-height: 1.465;
    font-weight: normal;
    font-family: ${fontFamily};
    text-transform: uppercase;
    padding: 0;
  `,
  h6Margin: '2.33em 0 1.17em 0',
  lead: `
    font-size: ${fontSizeValues.medium}px;
    line-height: 1.465;
    font-weight: normal;
    font-family: ${fontFamily};
    padding: 0;
  `,
  leadMargin: '1em 0 0.5em 0',
  base: `
    font-size: ${fontSizeValues.base}px;
    line-height: 1.465;
    font-weight: normal;
    font-family: ${fontFamily};
    padding: 0;
  `,
  baseMargin: '1em 0 0.5em 0',
  a: `
    font-size: inherit;
    color: ${link.color};
    text-decoration: ${link.decoration};

    :hover{
      color: ${link.hoverColor};
      text-decoration: ${link.hoverDecoration};
    }
  `,
  super: `
    display: inline;
    font-weight: normal;
    font-size: 75%;
    padding: 0 0 0 0.3em;
    font-family: ${fontFamily};
  `,
  panelHeader: `
    font-size: ${fontSizeValues.large}px;
    line-height: 1.2;
    font-weight: normal;
    font-family: ${fontFamily};
    padding: 0 ${spacing.large} ${spacing.large} ${spacing.large};
    margin: 0;
  `,
  screenReaderOnly: `
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  `,
  code: `
    background-color: ${palette.gray[50]};
    border: 1px solid ${palette.gray[100]};
  `,
};

export const mobileTypography = {
  mobile: {
    fontSizeValues: mobileFontSizeValues,
    size: mobileFontSizes,
    h1: `
      font-size: ${mobileFontSizeValues.extraLarge}px;
    `,
    h2: `
      font-size: ${mobileFontSizeValues.large}px;
    `,
    h3: `
      font-size: ${mobileFontSizeValues.medium}px;
    `,
    h4: `
      font-size: ${mobileFontSizeValues.medium}px;
    `,
    h5: `
      font-size: ${mobileFontSizeValues.base}px;
    `,
    h6: `
      font-size: ${mobileFontSizeValues.mini}px;
    `,
    lead: `
      font-size: ${mobileFontSizeValues.medium}px;
    `,
    base: `
      font-size: ${mobileFontSizeValues.base}px;
    `,
    a: `
      font-size: ${mobileFontSizeValues.base};
    `,
    panelHeader: `
      font-size: ${mobileFontSizeValues.large}px;
      padding: 0 ${mobileSpacing.large} ${mobileSpacing.large} ${mobileSpacing.large};
    `,
    code: `
      font-size: ${mobileFontSizeValues.base}px;
    `,
  },
};
