// Raw values, in pixels
const spacingValues = {
  none: 0,
  micro: 4,
  mini: 6,
  small: 10,
  base: 14,
  medium: 18,
  large: 22,
  xlarge: 26,
  xxlarge: 44,
};

type Spacings = keyof typeof spacingValues;
const spacing = {} as Record<Spacings, string>;

(Object.keys(spacingValues) as Spacings[]).forEach(key => {
  spacing[key] = `${spacingValues[key]}px`;
});

const mobileSpacingValues = {
  none: 0,
  micro: 6,
  mini: 10,
  small: 14,
  base: 18,
  medium: 22,
  large: 26,
  xlarge: 44,
  xxlarge: 44,
};

type MobileSpacings = keyof typeof mobileSpacingValues;
const mobileSpacing = {} as Record<MobileSpacings, string>;

(Object.keys(mobileSpacingValues) as MobileSpacings[]).forEach(key => {
  mobileSpacing[key] = `${mobileSpacingValues[key]}px`;
});

export {
  spacing,
  spacingValues,
  mobileSpacing,
  mobileSpacingValues,
};
