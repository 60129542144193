/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Annulla"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Salva"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Personalizza"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Seleziona un solo giorno"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Seleziona un mese"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Seleziona una settimana"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Ultimi 30 giorni"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Ultimi 7 giorni"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Il mese scorso"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "La settimana scorsa"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Per tutta la durata della campagna"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Questa settimana"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Oggi"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Anno in corso fino alla data corrente"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Ieri"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
