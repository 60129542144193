import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import {
  ProgressSize,
  ProgressStatus,
  ProgressColorValue,
} from './types';
import {
  Container,
  Progress,
  ProgressContainer,
  Tick,
} from './ProgressIndicator.styles';

const getClampedValue = (val: unknown, max:number) => {
  if (typeof val !== 'number') {
    return 0;
  }
  return Math.min(Math.max(val, 0), max);
};

interface InternalProgressIndicatorValue {
  label: undefined | string;
  value: number;
}

export interface ProgressIndicatorValue extends InternalProgressIndicatorValue {
  label: string;
}

export interface ProgressIndicatorProps {
  /**
   * The maximum progress value.
   * @defaultValue `100`
   */
  max?: number;
  /**
   * This should be between 0 and max value.
   * It can either be a single value or an array of object contains value and label
   * (The maximum size of the array is 3)
   * @defaultValue `0`
   */
  value?: ProgressIndicatorValue[] | number;
  /**
   * Should the label be rendered inline.
   * @defaultValue `false`
   */
  inline?: boolean;
  /**
   * \["mini", "small"\]
   * @defaultValue `"mini"`
   */
  size?: ProgressSize;
  /**
   * Should the label be displayed.
   * @defaultValue `false`
   */
  showLabel?: boolean;
  /**
   * This is a render prop. The current value will be passed to it.
   * Use it to pass a custom React node label.
   * @defaultValue `(val) => <Text type="p">{val}%</Text>`
   */
  label?: (clampedValue: number, max?: number) => ReactNode;
  /**
   * @defaultValue `default`
   */
  status?: ProgressStatus;
  /**
   * Displays a progress target line along the progress indicator. This
   * must be a value between the min and max of the progress indicator.
   * @defaultValue `undefined`
   */
  targets?: number[];
}

const ProgressIndicator: FC<React.PropsWithChildren<ProgressIndicatorProps>> = ({
  max = 100,
  value = 0,
  inline,
  size = 'mini',
  showLabel,
  label,
  status = 'default',
  targets,
}) => {
  const sortedValueObjects = ((
    Array.isArray(value) ? value : [{ value }]
  ) as InternalProgressIndicatorValue[])
    .map(valueObject => ({
      ...valueObject,
      value: getClampedValue(valueObject.value, max),
    }))
    .sort((valueObjectA, valueObjectB) => valueObjectA.value - valueObjectB.value)
    .slice(-3); // only up to 3 values
  const maxValue = sortedValueObjects[sortedValueObjects.length - 1]?.value ?? 0;

  return (
    <ProgressContainer $inline={inline}>
      <Container>
        {sortedValueObjects.reverse().map((valueObject, index) => (
          <Progress
            key={`${valueObject.value}_${valueObject.label ?? 'key'}`}
            max={max}
            value={valueObject.value}
            $size={size}
            $status={status}
            $colorValue={`${(sortedValueObjects.length - index) * 100}` as ProgressColorValue}
            // <progress> min is always 0
            // aria roles are necessary for component accessibility.
            // Without them, the progress element is treated as indeterminate.
            // aria-role progressbar is already set by the browser and does not need to be set here.
            aria-valuemin={0}
            aria-valuemax={max}
            aria-valuenow={valueObject.value}
            aria-label={valueObject.label}
          />
        ))}
        {(targets && targets.length > 0) && targets.map((targetValue, index) => (
          <Tick
            key={`${targetValue}_${index + 1}`}
            $size={size}
            $targetValue={((targetValue / max) * 100)}
            $value={maxValue}
          />
        ))}
      </Container>
      {showLabel && label?.(maxValue, max)}
    </ProgressContainer>
  );
};

ProgressIndicator.propTypes = {
  /**
   * The maximum progress value.
   */
  max: PropTypes.number,
  /**
   * This should be between 0 and max value.
   * It can either be a single value or an array of object contains value and label
   * (The maximum size of the array is 3)
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  ]) as PropTypes.Validator<ProgressIndicatorValue[] | number>,
  /**
   * Should the label be rendered inline.
   */
  inline: PropTypes.bool,
  /**
   * \["mini", "small"\]
   */
  size: PropTypes.oneOf(['mini', 'small']),
  /**
   * Should the label be displayed.
   */
  showLabel: PropTypes.bool,
  /**
   * This is a render prop. The current value will be passed to it.
   * Use it to pass a custom React node label.
   */
  label: PropTypes.func,
  status: PropTypes.oneOf(['default', 'warning', 'error']),
  /**
   * Displays a progress target line along the progress indicator. This
   * must be a value between the min and max of the progress indicator.
   */
  targets: PropTypes.arrayOf(PropTypes.number.isRequired),
};

ProgressIndicator.defaultProps = {
  max: 100,
  value: 0,
  inline: false,
  size: 'mini',
  showLabel: false,
  label: (val: number): ReactNode => <Text type="p">{val}%</Text>,
  status: 'default',
  targets: undefined,
};

export default ProgressIndicator;
