import React, { FC, MouseEventHandler, ReactNode } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  check,
  exclamation,
  exclamationTriangle,
  infoCircle,
  times,
} from '@amzn/storm-ui-icons';
import { noop, MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';
import Text from '../Text';
import { SurfaceDarkInfo } from '../Surface/index';
import { Theme, ThemeProvider } from '../theme';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import Message from './Message';
import MessageHeader from './MessageHeader';
import MessageIcon from './MessageIcon';
import { CloseButton, CloseButtonIcon } from './CloseButton';
import { AlertType, HeadingLevel } from './types';
import Icon from '../Icon';
import { TaktProps } from '../types/TaktProps';
import { createStormTaktId, TaktIdConsumer } from '../TaktIdContext';

const StyledContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;
StyledContent.displayName = 'StyledContent';

export interface StyledMessageContentProps {
  $isSolid?: boolean;
  $type: AlertType;
}

const StyledMessageContent = styled('div')<StyledMessageContentProps>`
  ${({ theme }) => theme.typography.base};
  ul, ol {
    color: ${({ theme }) => theme.typography?.color?.base};
  }

  ${({ $isSolid, theme, $type }) => ($isSolid && css`
    color: ${theme.message[$type].solidColor};
    a, a:hover, a:focus {
      color: ${theme.message[$type].solidColor};
      text-decoration: underline;
    }
  `)}

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
`;
StyledMessageContent.displayName = 'StyledMessageContent';

// All child elements inside an Alert should have their focus rings overwritten based on the
// Alert style.  To accomplish that, we are overwriting the theme focusVisible properties for
// this component and all children by passing a new theme object to the ThemeProvider.
const focusThemeOverrider = (isSolid: boolean, type: AlertType) => (theme: Theme) => {
  const alertTypeTheme = (theme.message as unknown as Record<AlertType, Theme>)[type];

  // Do not modify the theme if there is no associated `type` in the theme data
  if (!alertTypeTheme) {
    return theme;
  }

  const focusBorderColor = isSolid
    ? alertTypeTheme?.solidFocusBorderColor
    : alertTypeTheme?.focusBorderColor;

  const focusShadowColor = isSolid
    ? alertTypeTheme?.solidFocusBorderColor
    : alertTypeTheme?.focusBorderColor;

  const computedTheme = {
    ...theme,
    focusVisible: {
      ...theme.focusVisible,
      ...(focusBorderColor ? {
        outlineColor: focusBorderColor,
        borderColor: focusBorderColor,
      } : {}),
      ...(focusShadowColor ? { boxShadowColor: focusShadowColor } : {}),
    },
  };
  return computedTheme;
};

const chooseIcon = ($type: string) => {
  switch ($type) {
    case 'error': return <Icon type={exclamation} />;
    case 'warning': return <Icon type={exclamationTriangle} />;
    case 'success': return <Icon type={check} />;
    default: return <Icon type={infoCircle} />;
  }
};

export interface AlertProps extends MergeStyledComponentElementProps<'div'>, TaktProps {
  /**
   * The type of Alert
   * @defaultValue `"info"`
   */
  type?: AlertType;
    /**
   * The heading text that is rendered by the Alert. This renders as an h4 by default.
   * @defaultValue `undefined`
   */
  header?: ReactNode;
    /**
   * The header will always render in the style of an 'h4' but this does not always fit
   * semantically with the rest of your page. Use this prop to change the level of heading
   * you want to render.
   * @defaultValue `"h4"`
   */
  headerLevel?: HeadingLevel;
    /**
   * Creates a solid background callout. Does not render header, even if it exists.
   * @defaultValue `false`
   */
  isSolid?: boolean;
    /**
   * Whether the message is visible. Useful when implementing onClose handler.
   * @defaultValue `true`
   */
  isOpen?: boolean;
    /**
   * Adds a close icon to dismiss the message. It is up to you to implement onClose handler.
   * @defaultValue `false`
   */
  withCloseButton?: boolean;
    /**
   * Screen reader-only label for the close button. Provide a translated
   * string via this prop for internationalized UIs.
   * @defaultValue `"Dismiss message"`
   */
  closeButtonLabel?: string;
    /**
   * Function to perform on message close. Never invoked unless withCloseButton is true.
   * @defaultValue `() => undefined`
   */
  onClose?: MouseEventHandler<HTMLButtonElement>;
    /**
   * The React nodes that are rendered by the Alert.
   * @defaultValue `undefined`
   */
  children?: ReactNode;
    /**
   * Hides the Alert specific icon.
   * @defaultValue `false`
   */
  hideIcon?: boolean;
    /**
   * Adds a small amount of margin-right to the Alert.
   * @defaultValue `false`
   */
  inline?: boolean;
}

const Alert: FC<React.PropsWithChildren<AlertProps>> = ({
  type = 'info',
  header,
  headerLevel = 'h4',
  isSolid = false,
  isOpen = true,
  withCloseButton = false,
  closeButtonLabel = 'Dismiss message',
  children,
  onClose,
  hideIcon = false,
  taktId,
  taktValue,
  ...rest
}) => {
  if (!isOpen) return null;

  return (
    <ThemeProvider theme={
      /*
       * This is not optimal. A new theme modifier function is returned on each render.
       * This prevents the ThemeProvider from caching the new theme values.
       */
      focusThemeOverrider(isSolid, type)
    }
    >
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('alert')}>
        {({ getDataTaktAttributes }) => (
          <Message
            {...rest}
            as={
              /* When more surfaces are created, replace this with a surface select function */
              type === 'feature-introduction' ? SurfaceDarkInfo : undefined
            }
            $type={type}
            $isSolid={isSolid}
          >
            {!hideIcon && !isSolid
              && (
                <MessageIcon $header={header} $type={type}>
                  {chooseIcon(type)}
                </MessageIcon>
              )}
            <StyledContent>
              {header && !isSolid
                && (
                  <MessageHeader $type={type}>
                    <Text type={headerLevel} styleAs="h4">{header}</Text>
                  </MessageHeader>
                )}
              {children
                && (
                  <StyledMessageContent $isSolid={isSolid} $type={type}>
                    {children}
                  </StyledMessageContent>
                )}
            </StyledContent>
            {withCloseButton
              && (
                <CloseButton onClick={onClose} {...getDataTaktAttributes({ taktIdSuffix: 'close-button' })}>
                  <CloseButtonIcon $isSolid={isSolid} type={times} $alertType={type} header={header} />
                  <Text type="sr-only">{closeButtonLabel}</Text>
                </CloseButton>
              )}
          </Message>
        )}
      </TaktIdConsumer>
    </ThemeProvider>
  );
};

Alert.propTypes = {
  type: PT.oneOf<AlertType>(['info', 'warning', 'error', 'success', 'feature-introduction']),
  /**
   * The heading text that is rendered by the Alert. This renders as an h4 by default.
   */
  header: PT.node as PT.Validator<ReactNode>,
  /**
   * The header will always render in the style of an 'h4' but this does not always fit
   * semantically with the rest of your page. Use this prop to change the level of heading
   * you want to render.
   */
  headerLevel: PT.oneOf<HeadingLevel>(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /**
   * Creates a solid background callout. Does not render header, even if it exists.
   */
  isSolid: PT.bool,
  /**
   * Whether the message is visible. Useful when implementing onClose handler.
   */
  isOpen: PT.bool,
  /**
   * Adds a close icon to dismiss the message. It is up to you to implement onClose handler.
   */
  withCloseButton: PT.bool,
  /**
   * Screen reader-only label for the close button. Provide a translated
   * string via this prop for internationalized UIs.
   */
  closeButtonLabel: PT.string,
  /**
   * Function to perform on message close. Never invoked unless withCloseButton is true.
   */
  onClose: PT.func,
  /**
   * The React nodes that are rendered by the Alert.
   */
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired as React.Requireable<ReactNode | ReactNode[]>,
  /**
   * Hides the Alert specific icon.
   */
  hideIcon: PT.bool,
  /**
   * Adds a small amount of margin-right to the Alert.
   */
  inline: PT.bool,
};

Alert.defaultProps = {
  type: 'info',
  isSolid: false,
  isOpen: true,
  withCloseButton: false,
  closeButtonLabel: 'Dismiss message',
  onClose: noop,
  header: undefined,
  headerLevel: 'h4',
  hideIcon: false,
  inline: false,
};

export default Alert;
