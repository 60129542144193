/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "İptal"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Kaydet"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Özel"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Tek bir gün seçin"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Ay seçin"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Hafta seçin"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Son 30 gün"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Son 7 gün"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Geçen ay"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Geçen hafta"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Ömür boyu"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Bu hafta"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Bugün"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Yılbaşından bugüne kadar"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Dün"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
