import React, { useRef, RefObject } from 'react';
import SelectList from '../../SelectList';
import DropdownPopper, { DropdownPopperProps } from '../../Dropdown/DropdownPopper';
import usePopperSelectListHelper, { UsePopperSelectListHelperProps } from '../../Dropdown/usePopperSelectListHelper';

export type Offset = {
  x?: number | string,
  y?: number | string,
}

interface SplitButtonPopoverProps extends Pick<DropdownPopperProps, 'trigger' |'$minWidth' | 'offsetDistance' | 'offsetSkidding' | 'children'>, Omit<UsePopperSelectListHelperProps, 'popperRef' | 'anchorEl'> {
  focusedAnchorEl: RefObject<HTMLButtonElement>;
  onClose: UsePopperSelectListHelperProps['onClose'];
  onPopoverClose: DropdownPopperProps['onClose'];
}

const SplitButtonPopper = ({
  isOpen,
  onClose,
  $minWidth,
  trigger,
  offsetDistance,
  offsetSkidding,
  children,
  focusedAnchorEl,
  onPopoverClose,
}: SplitButtonPopoverProps) => {
  const popperRef = useRef<HTMLDivElement>(null);

  const {
    focusedValue,
    handleKeyDown,
    onPopoverElementDidMount,
  } = usePopperSelectListHelper({
    isOpen,
    anchorEl: focusedAnchorEl,
    popperRef,
    onClose,
    focusSelected: true,
  })

  return (
    <DropdownPopper
      isOpen={isOpen}
      onClose={onPopoverClose}
      onKeyDown={handleKeyDown}
      popperRef={popperRef}
      $minWidth={$minWidth}
      trigger={trigger}
      offsetDistance={offsetDistance}
      offsetSkidding={offsetSkidding}
      popperElementDidMount={onPopoverElementDidMount}
      closePopperIfTriggerClicked
    >
      <SelectList
        afterOnSelect={onClose}
        focusedValue={focusedValue}
      >
        {children}
      </SelectList>
    </DropdownPopper>
  );
}

export default SplitButtonPopper;
