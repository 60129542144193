import { Locale } from 'date-fns';
import { AdvertisingLocale } from '@amzn/storm-ui-utils-v3';
import { MaybeLocale } from '../types';

const isLocale = (maybeLocale: MaybeLocale | undefined): maybeLocale is AdvertisingLocale => typeof maybeLocale === 'string';

const isDFNSLocale = (maybeLocale: MaybeLocale | undefined): maybeLocale is Locale => typeof maybeLocale === 'object' && 'localize' in maybeLocale;

const isLocalePromise = (maybeLocale: MaybeLocale | undefined): maybeLocale is () => Promise<{ default: Locale }> => typeof maybeLocale === 'function';

export {
  isLocale,
  isDFNSLocale,
  isLocalePromise,
};
