import { deepMerge } from '@amzn/storm-ui-utils';
import { SurfaceThemeOverrides } from '../component/surface';
import type { Theme } from '../themes/types';
import { SurfaceType } from '../../Surface/types';
import surfaceDarkStyles from '../component/surfaceDark';
import surfaceDarkInfoStyles from '../component/surfaceDarkInfo';
import surfaceLightStyles from '../component/surfaceLight';

const getSurfaceStylesBySurfaceType = (
  theme: Record<string, SurfaceThemeOverrides>,
  surfaceType: SurfaceType,
): SurfaceThemeOverrides => {
  if (surfaceType === 'dark') return theme.surfaceDark || surfaceDarkStyles;
  if (surfaceType === 'blue') return theme.surfaceDarkInfo || surfaceDarkInfoStyles;
  return theme.surfaceLight || surfaceLightStyles;
};

export default function surfaceModifier(
  theme: Theme,
  surfaceType: SurfaceType,
): Theme {
  const surfaceThemeOverrides = getSurfaceStylesBySurfaceType(theme, surfaceType);

  const modifiedTheme = { ...theme };

  // For any theme targets with overrides, merge the current theme values
  // with any of the overridden values. Making multiple deepMerge calls is
  // arguably better here because of the small number of overridden targets for now.
  Object.entries(surfaceThemeOverrides).forEach(([key, value]) => {
    modifiedTheme[key] = deepMerge(
      {},
      theme[key],
      value,
    );
  });

  return modifiedTheme;
}
