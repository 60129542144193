import React, { memo } from 'react';
import styled from 'styled-components';

const LastPageIcon = styled('svg')`
  width: 1em;
  height: 1em;

  /*! @noflip */
  [dir="rtl"] && {
    /*! @noflip */ transform: scaleX(-1);
  }
`;

export default Object.assign(
  memo(() => (
    <LastPageIcon
      aria-hidden="true"
      role="img"
      viewBox="0 0 382 515"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M227 276L91 412c-10 9-25 9-34 0l-23-23c-9-9-9-24 0-33l96-97-96-96c-9-10-9-25 0-34l23-23c9-9 24-9 33 0l136 136c10 9 10 25 1 34zm127 114c0 17-13 30-30 30h-20c-17 0-30-13-30-30V129c0-17 13-30 30-30h20c17 0 30 13 30 30v261z"
        fill="currentColor"
      />
    </LastPageIcon>
  )),
  { displayName: 'FirstPageIcon' },
);
