import React, { ReactNode, forwardRef } from 'react';
import PT from 'prop-types';
import { InputLabel, UIDConsumer } from '@amzn/storm-ui';
import AdaptiveHelpTip from '@amzn/storm-ui-adaptive-helptip';

import {
  FormRowDiv,
  LeftColumn,
  RightColumn,
  ControlComponent,
  LabelContainer,
  Label,
  HelpTipPlaceholder,
  OptionalTag,
  AdaptiveHelpTipContainer,
} from './FormRow.styles';

export interface FormRowProps {
  /**
   * Unique identifier for this FormRow.
   * This will also set the ids for the components contained in this row according to their indices.
   * The value string must be limited to the
   * characters `a-z`, `A-Z`, `0-9`, `%`, `-` and be at least 3 characters long.
   * (https://storm.advertising.amazon.dev/for-developers/uid/#apiuidconsumer)
   */
  id: string;
  /**
   * The content for this row. The function should be of the format:
   * componentId => {... return what you want to render here... }.
   * You must pass the componentId to the appropriate input to ensure accessibility.
   */
  controlComponents: Array<(componentId: string) => JSX.Element>;
  /**
   * Use if you need to add a classname to the form row.
   * @defaultValue `undefined`
   */
  className?: string;
  /**
   * Use in conjunction with `helpTipText` to add rich content (bolds, italics, links)
   * to the helptip. `HelptipNode` will not appear without the `helpTipText` prop
   * being used.
   * The `helpTipNode` content must read the same as the `helpTipText` for
   * accessibility.
   * @defaultValue `undefined`
   */
  helpTipNode?: ReactNode;
  /**
   * Use if you need to add a helptip for this form row.
   * This text will be displayed in that helptip.
   * @defaultValue `undefined`
   */
  helpTipText?: string;
  /**
   * Use if you need to make this row's label a section header.
   * This will align the row label to the top of the row for screen size >576px.
   * @defaultValue `false`
   */
  isSectionHeader?: boolean;
  /**
   * The label for this row.
   */
  label: string;
  /**
   * Use if you need to override the vertical spacing for this row's label.
   * This is useful in the case of misalignment for some special components.
   * @defaultValue `"6px"`
   */
  labelTopSpacing?: string;
  /**
   * Use if you need to add a secondary text below the row label.
   * @defaultValue `undefined`
   */
  optionalTag?: string;
}

const FormRow = forwardRef<HTMLDivElement, FormRowProps>(({
  id,
  controlComponents,
  className,
  helpTipNode,
  helpTipText,
  isSectionHeader,
  label,
  labelTopSpacing,
  optionalTag,
}, ref) => (
  <FormRowDiv id={id} className={className} ref={ref}>
    <LeftColumn
      // Top spacing aligns label with 1st control component
      // in right column for screen width > 576px
      $topSpacing={isSectionHeader ? '0px' : labelTopSpacing}
    >
      <LabelContainer>
        <Label>
          <UIDConsumer value={`${id}-control-component-0`}>
            {(componentId: string) => (
              <InputLabel htmlFor={componentId}>
                {label}
              </InputLabel>
            )}
          </UIDConsumer>
          {helpTipText && (
            <AdaptiveHelpTipContainer>
              <AdaptiveHelpTip triggerLabel={helpTipText}>
                {helpTipNode ?? helpTipText}
              </AdaptiveHelpTip>
            </AdaptiveHelpTipContainer>
          )}
          {!helpTipText && <HelpTipPlaceholder />}
        </Label>
        <UIDConsumer value={`${id}-control-component-0`}>
          {(componentId: string) => (
            <OptionalTag htmlFor={componentId}>{optionalTag}</OptionalTag>
          )}
        </UIDConsumer>
      </LabelContainer>
    </LeftColumn>
    <RightColumn>
      {controlComponents.map((componentFn: (componentId: string) => JSX.Element, index: number) => (
        <UIDConsumer
          value={`${id}-control-component-${index}`}
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}-control-component-${index}`}
        >
          {(componentId: string) => (
            <ControlComponent key={`control-component-${componentId}`}>
              {componentFn(componentId)}
            </ControlComponent>
          )}
        </UIDConsumer>
      ))}
    </RightColumn>
  </FormRowDiv>
));

FormRow.propTypes = {
  /**
   * Use if you need to add a classname to the form row.
   */
  className: PT.string,
  /**
   * Unique identifier for this FormRow.
   * This will also set the ids for the components contained in this row according to their indices.
   * The value string must be limited to the
   * characters `a-z`, `A-Z`, `0-9`, `%`, `-` and be at least 3 characters long.
   * (https://storm.advertising.amazon.dev/for-developers/uid/#apiuidconsumer)
   */
  id: PT.string.isRequired,
  /**
   * Use if you need to add a helptip for this form row.
   * This text will be displayed in that helptip.
   */
  helpTipText: PT.string,
  /**
   * Use if you need to add a secondary text below the row label.
   */
  optionalTag: PT.string,
  /**
   * Use if you need to make this row's label a section header.
   * This will align the row label to the top of the row for screen size >576px.
   */
  isSectionHeader: PT.bool,
  /**
   * The label for this row.
   */
  label: PT.string.isRequired,
  /**
   * Use if you need to override the vertical spacing for this row's label.
   * This is useful in the case of misalignment for some special components.
   */
  labelTopSpacing: PT.string,
  /**
   * The content for this row. The function should be of the format:
   * componentId => {... return what you want to render here... }.
   * You must pass the componentId to the appropriate input to ensure accessibility.
   */
  controlComponents: PT.arrayOf(PT.func.isRequired).isRequired,
  /**
   * Use in conjunction with `helpTipText` to add rich content (bolds, italics, links)
   * to the helptip. `HelptipNode` will not appear without the `helpTipText` prop
   * being used.
   * The `helpTipNode` content must read the same as the `helpTipText` for
   * accessibility.
   */
  helpTipNode: PT.node as PT.Validator<ReactNode>,
};

FormRow.defaultProps = {
  className: undefined,
  helpTipText: undefined,
  optionalTag: undefined,
  isSectionHeader: false,
  labelTopSpacing: '6px',
  helpTipNode: undefined,
};

export default FormRow;
