import { RefObject } from 'react';

export const getSurroundingFocusableElements = (focusableEls: HTMLElement[]) => {
  let previous: HTMLElement | undefined;
  let next: HTMLElement | undefined;
  for (let i = 0; i < focusableEls.length; i++) {
    next = focusableEls[i + 1];
    if (focusableEls[i] === document.activeElement) {
      break;
    } else {
      previous = focusableEls[i];
    }
  }
  return { previous, next };
};

export const getUntabbablePills = (ref: RefObject<HTMLDivElement>): HTMLElement[] => Array.from(ref.current?.querySelectorAll<HTMLElement>('button[tabindex="-1"]') ?? []);
