import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { InputFormGroupTheme } from './types';

function selectRadius({ theme }: { theme: InputFormGroupTheme }) {
  return theme.form.input.radius;
}

export interface PrefixProps extends MergeElementProps<'div'> {
  disabled?: boolean;
  $ignoreTextDirection?: boolean;
}

const Prefix = styled('div') <PrefixProps>`
  flex: 0 0 auto;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-inline-end: ${({ theme }) => theme.spacing.small};
  padding-inline-start: calc(${({ theme }) => theme.spacing.small} - 2px);
  min-height: calc(${({ theme }) => theme.form.input.height} - 6px);
  background: ${({ theme }) => theme.form.input.affix.bg};
  font-size: ${({ theme }) => theme.form.input.affix.fontSize};
  color: ${({ theme }) => theme.form.input.affix.color};
  line-height: 1.465;

  /*! @noflip */ border-radius: ${selectRadius} 0 0 ${selectRadius};

  ${({ $ignoreTextDirection }) => (!$ignoreTextDirection && css`
    /*! @noflip */
    [dir="rtl"] && {
      /*! @noflip */ border-radius: 0 ${selectRadius} ${selectRadius} 0;
    }
  `)}

  ${({ disabled, theme }) => (disabled && css`
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    border-color: ${theme.form.input.disabled.borderColor};
    background: ${theme.form.input.disabled.bg};
    color: ${theme.form.input.disabled.color};
  `)}

  ${isMobile(css`
    padding-inline-start: calc(${({ theme }) => theme.mobile.spacing.mini} - 2px);
    padding-inline-end: ${({ theme }) => theme.mobile.spacing.mini};
    font-size: ${({ theme }) => theme.form.input.mobile.affix.fontSize};
  `)}
`;
Prefix.displayName = 'Prefix';

export default Prefix;
