/* eslint-disable import/prefer-default-export */
import { palette } from '../base/color';
import { spacing } from '../base/spacing';
import { border } from '../base/border';

const snackbar = {
  backgroundColor: palette.white,
  borderColor: 'transparent',
  borderWidth: border.roundedAreaThin.width,
  borderRadius: border.roundedAreaThin.radius,
  boxShadow: '0 4px 8px 0 rgb(35 47 63 / 0.20), 0 0 2px 0 rgb(35 47 63 / 0.30)',
  closeButton: {
    color: palette.gray[700],
    hoverColor: palette.squidInk,
  },
  closeButtonHoverColor: palette.squidInk,
  marginBetween: spacing.base,
  padding: `${spacing.medium} ${spacing.base}`,
};

export default snackbar;
