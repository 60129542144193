/**
 * This prop type check insure that times are strings in the format of `HH:mm`. HH is a 2 digit
 * 24 hour and mm is a 2 digit minute.
 * @param  {any}    props         The prop being tested
 * @param  {string} propName      Name of the prop being tested. Used to make a meaningful warring.
 * @param  {[type]} componentName The name of the component the prop is being used in.
 */
export default function timePropType(props: any, propName: string, componentName: string): Error | undefined {
  if (!/(^([0-1][0-9]|2[0-4]):[0-5][0-9]$|^$)/.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to`
        + ` \`${componentName}\`. Validation failed.`,
    );
  }
  return undefined;
}
