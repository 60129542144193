import React, { memo } from 'react';
import styled from 'styled-components';

const FirstPageIcon = styled('svg')`
width: 1em;
height: 1em;

  /*! @noflip */
  [dir="rtl"] && {
    /*! @noflip */ transform: scaleX(-1);
  }
`;

export default Object.assign(
  memo(() => (
    <FirstPageIcon
      aria-hidden="true"
      role="img"
      viewBox="0 0 382 515"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M154 276c-9-9-9-25 1-34l136-136c9-9 24-9 33 0l23 23c9 9 9 24 0 34l-96 96 96 97c9 9 9 24 0 33l-23 23c-9 9-24 9-34 0L154 276zM27 390V129c0-17 13-30 30-30h20c17 0 30 13 30 30v261c0 17-13 30-30 30H57c-17 0-30-13-30-30z"
        fill="currentColor"
      />
    </FirstPageIcon>
  )),
  { displayName: 'FirstPageIcon' },
);
