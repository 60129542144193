import { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const formGroupRowStyleMixin = css<{ inline?: boolean }>`
  padding: 0;
  font-weight: initial;

  max-width: none;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  > * {
      margin-right: ${({ theme }) => theme.form.input.internalElementInlineSpacing};
      margin-inline-end: ${({ theme }) => theme.form.input.internalElementInlineSpacing};

      :last-child{
        margin-right: ${({ inline, theme }) => (inline ? theme.spacing.micro : '0')};
        margin-inline-end: ${({ inline, theme }) => (inline ? theme.spacing.micro : '0')};
      }
  }

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    margin-right: 0;
    margin-inline-end: 0;
    :last-child{
      margin-right: 0;
      margin-inline-end: 0;
    }
  `)}
`;

export default formGroupRowStyleMixin;
