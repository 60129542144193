/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing } from '../base/spacing';

const tooltip = {
  // legacy styles for 2.x
  // determines z-index of PIP (the triangle)
  // The content bubble uses a portal, not z-index
  zindex: '3',

  // styles
  arrowHeight: 7/* px */,
  paddingTopBottom: spacing.base,
  paddingLeftRight: spacing.medium,
  paddingTopBottomWithTopCornerIcon: spacing.small,
  paddingLeftRightWithTopCornerIcon: spacing.small,
  contentPaddingTopBottomWithTopCornerIcon: spacing.micro,
  contentPaddingLeftRightWithTopCornerIcon: spacing.medium,
  radius: '8px',
  height: 10, // must be a Number (IE: not '10px' etc.)
  mouseLeaveTimeout: 600, // delay in ms
  mouseEnterTimeout: 0, // delay in ms
  maxWidth: '400px',
  maxHeight: '400px',
  boxShadow: '0 4px 8px 0 rgb(35 47 63 / 0.20), 0 0 2px 0 rgb(35 47 63 / 0.30)',
  arrowShadow: {
    bottom: '-1px -1px 4px -2px rgba(35, 47, 63, 0.2), -1px -1px 2px -1px rgba(35, 47, 63, 0.3)',
    right: '-1px 1px 4px -2px rgba(35, 47, 63, 0.2), -1px 1px 2px -1px rgba(35, 47, 63, 0.3)',
    top: '1px 1px 4px -2px rgba(35, 47, 63, 0.2), 1px 1px 2px -1px rgba(35, 47, 63, 0.3)',
    left: '1px -1px 4px -2px rgba(35, 47, 63, 0.2), 1px -1px 2px -1px rgba(35, 47, 63, 0.3)',
  },
  bg: {
    light: palette.white,
    dark: palette.squidInk,
    blue: '#018485',
  },
  color: {
    light: palette.squidInk,
    dark: palette.white,
    blue: palette.white,
  },
  borderColor: {
    light: palette.white,
    dark: palette.squidInk,
    blue: '#018485',
  },
  linkColor: {
    light: palette.blue[700],
    dark: palette.white,
    blue: palette.white,
  },
  linkHoverColor: {
    light: palette.blue[800],
    dark: palette.white,
    blue: palette.white,
  },
  linkDecoration: {
    light: 'none',
    dark: 'underline',
    blue: 'underline',
  },
  closeIcon: {
    light: palette.gray[700],
    dark: palette.white,
    blue: palette.white,
    padding: '0 3px',
    marginLeft: '6px',
    svg: {
      verticalAlign: 'top',
      width: '1em',
    },
  },
  closeIconBg: {
    light: palette.white,
    dark: palette.squidInk,
    blue: '#018485',
  },
  closeIconHover: {
    light: palette.squidInk,
    dark: palette.white,
    blue: palette.white,
  },
  closeIconOpacity: {
    light: 1,
    dark: 1,
    blue: 1,
  },
  content: {
    border: '1px solid transparent',
  },
};

export default tooltip;
