import React, {
  MouseEventHandler,
  FC,
  ReactNode,
  ComponentProps,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { angleDown, angleUp } from '@amzn/storm-ui-icons-v3';
import Icon from '../Icon';
import TextButton from '../Button/TextButton';
import type { TaktProps } from '../types/TaktProps';
import { TaktIdProvider, createStormTaktId, withTaktFallbackId } from '../TaktIdContext';

const IconWrapper = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.icon.color};
  width: 14px;
  flex: 0 0 auto;
  padding-inline-end: ${({ theme }) => theme.spacing.mini};
  padding-inline-start: 0;
`;

const StyledTextButton = styled(TextButton)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  text-align: initial;

  :hover {
    > ${IconWrapper} {
      color: ${({ theme }) => theme.icon.hover.color};
    }
  }
`;
StyledTextButton.displayName = 'ExtenderButton';

const ExtenderButtonWithTaktFallback = withTaktFallbackId(StyledTextButton);
const ExtenderButton = styled(ExtenderButtonWithTaktFallback)``;

const TextButtonLabel = styled('div')`
  flex: 1 1 auto;
`;

const RenderEndWrapper = styled('div')`
  display: inline-block;
`;

const InlineExpanderContent = styled('section')<{isOpen:boolean}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding-inline-start: ${({ theme }) => theme.spacing.base};
  padding-inline-end: 0;
`;
InlineExpanderContent.displayName = 'InlineExpanderContent';

export interface InlineExpanderProps extends TaktProps {
  /**
   * The InlineExpander Button label.
   */
  label: ReactNode;
  /**
   * The content within the InlineExpander.
   */
  children: ReactNode;
  /**
   * Denotes if the InlineExapnder is open or closed.
   */
  isOpen: boolean;
  /**
   * The click event handler. Used to toggle state.
   * @defaultValue `() => undefined`
   */
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Renders the content after the label
   * @defaultValue `() => undefined`
   */
  renderEnd?: () => ReactNode;
}

// eslint-disable-next-line max-len
const InlineExpander: FC<React.PropsWithChildren<InlineExpanderProps & ComponentProps<typeof ExtenderButton>>> = ({
  children,
  label,
  handleClick,
  isOpen,
  renderEnd,
  taktId,
  taktValue,
  ...rest
}) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('inline-expander')}>
    <ExtenderButton
      onClick={handleClick}
      tabindex="0"
      aria-expanded={isOpen}
      taktFallbackId={createStormTaktId(isOpen ? 'close-button' : 'open-button')}
      {...rest}
    >
      <IconWrapper>
        { isOpen
          ? <Icon type={angleUp} />
          : <Icon type={angleDown} />}
      </IconWrapper>
      <TextButtonLabel>{label}</TextButtonLabel>
    </ExtenderButton>
    {renderEnd && (
      <RenderEndWrapper>
        {renderEnd()}
      </RenderEndWrapper>
    )}
    <InlineExpanderContent {...rest} isOpen={isOpen}>
      {children}
    </InlineExpanderContent>
  </TaktIdProvider>
);

InlineExpander.defaultProps = {
  handleClick: () => undefined,
  renderEnd: () => undefined,
};

InlineExpander.propTypes = {
  /**
   * The content within the InlineExpander.
   */
  children: PT.node.isRequired as React.Requireable<ReactNode>,
  /**
   * The InlineExpander Button label.
   */
  label: PT.node.isRequired as React.Requireable<ReactNode>,
  /**
   * Denotes if the InlineExapnder is open or closed.
   */
  isOpen: PT.bool.isRequired,
  /**
   * The click event handler. Used to toggle state.
   */
  handleClick: PT.func,
  /**
   * Renders the content after the label
   */
  renderEnd: PT.func,
};

export default InlineExpander;
