import React, { Component, ReactNode } from 'react';
import PT from 'prop-types';
import UIDContext, { Prefixer } from './UIDContext';
import uidSafeCharsPropType from './uidSafeCharsPropType';

/*
 Memoizing createUid prevents redundant calls up the react tree.
 */
function createMemoizedUid() {
  let lastParentPrefixer: Prefixer;
  let lastValue: string;
  let lastUid: string;
  return function createUid(parentPrefixer: Prefixer, value: string) {
    if (
      lastUid === undefined
      || parentPrefixer !== lastParentPrefixer
      || value !== lastValue
    ) {
      lastParentPrefixer = parentPrefixer;
      lastValue = value;
      lastUid = parentPrefixer(value);
    }
    return lastUid;
  };
}

export interface UIDConsumerProps {
  value: string;
  children: (uid: string) => ReactNode;
}

class UIDConsumer extends Component<UIDConsumerProps> {
  uid = createMemoizedUid();

  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    value: uidSafeCharsPropType,
    children: PT.func.isRequired,
  };

  render(): JSX.Element {
    const { children, value } = this.props;
    return (
      <UIDContext.Consumer>
        { prefixer => children(this.uid(prefixer, value))}
      </UIDContext.Consumer>
    );
  }
}

export default UIDConsumer;
