/* eslint-disable react/require-default-props */
import React, { forwardRef, ChangeEvent, ReactNode } from 'react';
import type { FocusRingAria } from '@react-aria/focus';
import type { TaktProps } from '../../../types/TaktProps';
import type { TileInputValue } from '../../types';
import { Label, Input } from './TileToggleArea.styles';

export interface TileToggleAreaProps extends TaktProps {
  checked?: boolean;
  children: ReactNode;
  disabled?: boolean;
  focusProps?: FocusRingAria['focusProps'];
  label: string;
  multiple?: boolean;
  name?: string;
  onChange: undefined | ((event: ChangeEvent<HTMLInputElement>) => void);
  value: undefined | TileInputValue;
  className?: string;
}

const TileToggleArea = forwardRef<HTMLInputElement, TileToggleAreaProps>(({
  checked,
  children,
  disabled,
  focusProps,
  label,
  multiple,
  name,
  onChange,
  value,
  className,
  ...restProps
}, ref) => (
  <Label
    htmlFor={label}
    disabled={disabled}
    $active={checked}
    className={className}
  >
    <Input
      {...restProps}
      focusProps={focusProps}
      ref={ref}
      id={label}
      type={multiple ? 'checkbox' : 'radio'}
      checked={checked}
      aria-checked={checked}
      disabled={disabled}
      name={name}
      value={value}
      onChange={onChange}
    />
    {children}
  </Label>
))

export default TileToggleArea;
