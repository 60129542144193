/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing } from '../base/spacing';
import { fontSizes, mobileFontSizes } from '../base/typography';

export const tableStyles = {
  border: `1px solid ${palette.gray[100]}`, // TODO: Storm 3.x remove
  header: {
    border: `1px solid ${palette.gray[100]}`,
    bg: palette.gray[50],
    color: palette.squidInk,
    fontSize: fontSizes.base,
    fontWeight: '700',
    paddingRight: spacing.base,
    paddingLeft: spacing.base,
    paddingTop: spacing.mini,
    paddingBottom: spacing.mini,
  },
  data: {
    border: 'none',
    bg: palette.white,
    color: palette.squidInk,
    fontSize: fontSizes.base,
    fontWeight: 'normal',
    paddingRight: spacing.base,
    paddingLeft: spacing.base,
    paddingTop: spacing.mini,
    paddingBottom: spacing.mini,
  },
};

export const mobileTableStyles = {
  mobile: {
    header: {
      fontSize: mobileFontSizes.base,
    },
    data: {
      fontSize: mobileFontSizes.base,
    },
  },
};
