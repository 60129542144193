/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing } from '../base/spacing';

export const sideNavStyles = {
  bg: '#FBFBFC', // gray-10 in the new scheme
  width: '180px',
  borderRight: '1px solid #E6E9ED',
  paddingTop: '12px',
};

export const sideNavItemStyles = {
  color: palette.black,
  // this 4 pixels is the border thickness (see comment below)
  activeBorderLeft: `4px solid ${palette.azure}`,
  inactiveBorderLeft: '0px',
  paddingRight: '5px',
  paddingTop: '3px',
  paddingBottom: '3px',
  margin: `${spacing.micro} 0px ${spacing.micro} 0px`,
  marginSubItem: '5px 0px 5px 0px',
  // the difference between active and inactive must match the border thickness
  subItemActiveLeftPadding: '30px',
  subItemInactiveLeftPadding: '34px',
  activeLeftPadding: '14px',
  inactiveLeftPadding: '18px',
  activeFontWeight: 'bold',
  inactiveFontWeight: 'normal',
  hoverBg: palette.white,
};
