import { ComponentPropsWithRef } from 'react';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import Icon from '../Icon';
import focusOutlineStyle from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { PaddingOptions } from '../types/PaddingOptions';
import { TransitionStatus } from '../types/react-transition-group/Transition';

export const Header = styled('div')`
  background: ${({ theme }) => theme.secondaryView.header.bg};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryView.header.border.color};
  box-sizing: border-box;
  padding: 1px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;
Header.displayName = 'Header';

export interface ContentProps extends MergeElementProps<'div'> {
  padding: PaddingOptions;
}

export const Content = styled('div')<ContentProps>`
  margin-top: 51px;
  overflow-x: auto;
  padding: ${({ padding, theme }) => theme.spacing[padding]};
  height: 100%;

  ${isMobile(css<ContentProps>`
    padding: ${({ padding, theme }) => theme.mobile.spacing[padding]};
  `)}
`;
Content.displayName = 'Content';

export interface CloseButtonAttrsProps extends ComponentPropsWithRef<'button'> {
  $focusVisible?: boolean;
}

export const CloseButton = styled('button').attrs<ComponentPropsWithRef<'button'>>(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    $focusVisible: isFocusVisible,
    type: 'button',
  });
})<CloseButtonAttrsProps>`
  ${({ theme }) => theme.typography.base};
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.secondaryView.closeButton.color};
  font-weight: bold;
  overflow: hidden;
  padding: 14px 14px;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  :focus {
    outline: none;
    ${({ $focusVisible }) => ($focusVisible && focusOutlineStyle)}
    outline-offset: -1px;

    ${isMobile(css`
      outline: none;
      box-shadow: none;
    `)}
  }

  :hover{
    color: ${({ theme }) => theme.secondaryView.closeButton.color};
    cursor: pointer;
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.base};
  `)}
`;
CloseButton.displayName = 'CloseButton';

export const CloseButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.secondaryView.closeIcon.color};
  margin-inline-end: ${({ theme }) => theme.spacing.mini};
`;
CloseButtonIcon.displayName = 'CloseButtonIcon';

export interface ShroudProps {
  $transitionState: TransitionStatus;
}

export const Shroud = styled('div')<ShroudProps>`
  background-color: ${({ theme }) => theme.secondaryView.bg};
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 150ms ease-in-out;
  }
  opacity: ${({ $transitionState }) => {
    if ($transitionState === 'entered') {
      return 1;
    }

    return 0;
  }};
  z-index: ${({ theme }) => theme.modal.zIndex};
`;
Shroud.displayName = 'Shroud';
