import React, {
  HTMLAttributes, MouseEventHandler, PureComponent, ReactNode,
} from 'react';
import PT from 'prop-types';

import { angleUp } from '@amzn/storm-ui-icons';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import {
  DiscloseIcon,
  CardHeaderContentStyled,
  CollapsibleCardHeaderStyled,
  CardHeaderStyled,
} from './CardHeader.styles';
import { CardPadding, CardHeaderPadding } from './types';

export interface CardHeaderProps extends TaktProps, HTMLAttributes<HTMLElement> {
  /**
     * This is the content that will be shown in the CardHeader.
     */
  children: ReactNode;
  /**
     * Specifies whether the card is collapsed. When `true` only the `<CardHeader />`
     * is displayed.
     * @defaultValue `false`
     */
  isCollapsed?: boolean;
  /**
     * Specifies that the card can be collapsed. The `<CardHeader />` component then
     * renders as a click target which collapses the card when clicked.
     * @defaultValue `false`
     */
  collapsable?: boolean;
  /**
     * Event handler that is triggered when the user clicks the header.
     * @defaultValue `undefined`
     */
  onCollapse?: MouseEventHandler;
  /**
     * Sets the padding around the `<CardHeader />`.
     * @defaultValue `"inherit"`
     */
  padding?: CardHeaderPadding;
  /**
     * Passed in by the parent `<Card />` component. This is the padding value for
     * the `<Card />`.
     * @defaultValue `"xlarge"`
     */
  cardPadding?: CardPadding;
  /**
   * @defaultValue `undefined`
   */
  tabIndex?: number;
}

class CardHeader extends PureComponent<CardHeaderProps> {
  static displayName = 'CardHeader'

  static propTypes = {
    /**
     * This is the content that will be shown in the CardHeader.
     */
    children: PT.node.isRequired,
    /**
     * Specifies whether the card is collapsed. When `true` only the `<CardHeader />`
     * is displayed.
     */
    isCollapsed: PT.bool,
    /**
     * Specifies that the card can be collapsed. The `<CardHeader />` component then
     * renders as a click target which collapses the card when clicked.
     */
    collapsable: PT.bool,
    /**
     * Event handler that is triggered when the user clicks the header.
     */
    onCollapse: PT.func,
    /**
     * Sets the padding around the `<CardHeader />`.
     */
    padding: PT.oneOf([
      'inherit',
      'none',
      'micro',
      'mini',
      'small',
      'base',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
    ]),
    /**
     * Passed in by the parent `<Card />` component. This is the padding value for
     * the `<Card />`.
     */
    cardPadding: PT.oneOf([
      'none',
      'micro',
      'mini',
      'small',
      'base',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
    ]),
  }

  static defaultProps = {
    isCollapsed: false,
    collapsable: false,
    onCollapse: undefined,
    padding: 'inherit',
    cardPadding: 'xlarge',
    tabIndex: undefined,
  }

  render(): JSX.Element {
    const {
      children,
      collapsable,
      isCollapsed,
      onCollapse,
      padding,
      cardPadding,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    const headerPadding = padding === 'inherit' ? cardPadding : padding;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('card-header')}>
        {({ getDataTaktAttributes }) => {
          if (collapsable) {
            return (
              <CollapsibleCardHeaderStyled
                {...getDataTaktAttributes()}
                {...rest}
                aria-expanded={!isCollapsed}
                tabIndex={0}
                collapsable={collapsable}
                onClick={onCollapse}
                isCollapsed={isCollapsed}
                padding={headerPadding}
              >
                <DiscloseIcon type={angleUp} />
                <CardHeaderContentStyled>
                  {children}
                </CardHeaderContentStyled>
              </CollapsibleCardHeaderStyled>
            );
          }
          return (
            <CardHeaderStyled
              {...rest}
              padding={headerPadding}
            >
              {children}
            </CardHeaderStyled>
          );
        }}
      </TaktIdConsumer>
    );
  }
}

// Temporary fix until CommunicationsHubSharedAssets updates their
// StyledCardHeader's isCollapsed type in CommunicationCard.tsx
export default CardHeader as any;
