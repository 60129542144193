import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { TextButton } from '../Button';
import Icon from '../Icon';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { AlertType } from './types';

export const CloseButton = styled(TextButton)`
  align-self: start;
  flex: 0 0 auto;
  outline: none;
`;
CloseButton.displayName = 'StyledButton';

export interface CloseButtonIconProps {
  $isSolid: boolean;
  $alertType: AlertType;
  header: ReactNode;
}

export const CloseButtonIcon = styled(Icon)<CloseButtonIconProps>`
  color: ${({ $isSolid, theme, $alertType }) => ($isSolid ? theme.message[$alertType].solidCloseButtonColor : theme.icon.color)};
  opacity: 1;
  font-size: ${({ header, theme }) => (header
    ? theme.message.closeButtonIconSizeHeader
    : theme.message.closeButtonIconSize)};
  padding: 0 5px 0 5px;

  :hover {
    opacity: 1.0;
    color: ${({ $isSolid, theme, $alertType }) => ($isSolid ? theme.message[$alertType].solidCloseButtonColor : theme.icon.hover.color)};
    cursor: pointer;
  }

  ${isMobile(css<{ header: ReactNode; }>`
    font-size: ${({ header, theme }) => (header ? theme.message.mobile.closeButtonIconSizeHeader : theme.message.mobile.closeButtonIconSize)};
  `)}
`;
CloseButtonIcon.displayName = 'StyledIcon';
