/* eslint-disable id-length */
import { ISODate, ISODateRange } from '../types';
import { isBefore } from './dateUtils';

/** Adds the given date to the current date range */
const addToRange = (day: ISODate, range?: ISODateRange): ISODateRange => {
  const { from, to } = range || {};
  if (from && to) {
    return { from: day, to: undefined };
  }
  if (!to && from && isBefore(day, from)) {
    return { from: day, to: undefined };
  }
  if (from && !to) {
    return { from, to: day };
  }
  return { from: day, to: undefined };
};

export default addToRange;
