/* eslint-disable id-length */
export const iconSizeMap: { [size: string]: string } = {
  xs: '0.75',
  sm: '0.875',
  lg: '1.3333333333',
};

/** Gets the icons size */
const getIconSize = (size?: string): string | undefined => {
  if (size) {
    if (iconSizeMap[size]) return `${iconSizeMap[size]}em`;
    return size.replace('x', 'em');
  }
  return undefined;
};

export default getIconSize;
