import { createContext } from 'react';
import { SurfaceType } from '../Surface/types';

export type OnCloseContextType = undefined | (() => void | undefined);

export const OnCloseContext = createContext<OnCloseContextType>(undefined);

export type TypeContextType = undefined | SurfaceType;

export const TypeContext = createContext<TypeContextType>(undefined);
