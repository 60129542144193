import { RefObject, useRef, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PortalContext from './PortalContext';

/**
 * The useGetPortal hook finds the most appropriate element to be the target of a `createPortal()`
 * The priority is as follows:
 *     1. The element stored in the ref passed into the second argument (externalPortalRef)
 *     2. The portal element provided by the context by PortalContext
 *     3. The element corresponding to the string id passed in via the arguments
 *     4. The element corresponding to the string id found in the theme at
 *        key `theme.popover.portalElementId`
 * @param portalElementId The string id of a element that will potentially be used
 * to render the portal into.
 * @return a React ref to the element to be the target of a `createPortal()`
 */
function useGetPortal(
  portalElementIdArg?: string,
  externalPortalRef?: RefObject<HTMLElement>,
): (RefObject<HTMLElement> | undefined) {
  /** All react hooks must be called in the same order every render */
  const portalRef = useRef<HTMLElement | null>(null);
  const inlinePortalRef = useContext(PortalContext);
  const theme = useContext(ThemeContext);

  /** Use the ref from the externalPortalRef when it is available */
  if (externalPortalRef) {
    return externalPortalRef;
  }

  /** Use the ref from the PortalContext when it is available */
  if (inlinePortalRef) {
    return inlinePortalRef;
  }

  /** Use the fallback portalElementId stored in the theme */
  if (typeof document !== undefined) {
    /** Get the portal element id from the function argument or theme */
    const portalElementId = portalElementIdArg || theme?.popover?.portalElementId || null;
    const portalElement = document.getElementById(portalElementId);
    /** If the portal element exists assign it to the ref */
    if (portalElement) {
      portalRef.current = portalElement;
      return portalRef;
    }
  }

  /** When all options have been exhausted, give up and return undefined */
  return undefined;
}

export default useGetPortal;
