import { palette } from '../base/color';
import { spacing, spacingValues } from '../base/spacing';
import { typography } from '../base/typography';

const treeViewStyles = {
  indentValue: spacingValues.medium,
  discloseIconColor: typography.color.tertiary,
  discloseIconSize: spacing.mini,
  discloseButtonSize: spacing.medium,
  discloseButtonWrapperSize: spacing.xlarge,
  nodeContentPadding: spacing.micro,
  nodeSeparator: `1px solid ${palette.mercury}`,
  nodeActiveColor: palette.haze,
};

export default treeViewStyles;
