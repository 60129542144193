import React, {
  FC, MouseEvent, KeyboardEvent, ReactNode,
  useRef,
  FocusEvent,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { times } from '@amzn/storm-ui-icons-v3';
import { MergeElementProps, keyboardKeynames } from '@amzn/storm-ui-utils-v3';
import { getUntabbablePills, getSurroundingFocusableElements } from './utils';
import { ListBuilderItem } from './types';
import Button from '../Button';
import Icon from '../Icon';
import Pill from '../Pill';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const PillBoxWrapper = styled('div')`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  background: ${({ theme }) => theme.palette.gray[25]};
  overflow: auto;
  ${isMobile(css`
    flex-direction: column-reverse;
  `)}
`;

const InnerPillWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  ${isMobile(css`
    padding-top: 0;
  `)}
`;

const RemoveAllButtonWrapper = styled('div')`
  width: max-content;
  ${isMobile(css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `)}
`;

const defaultRenderPill = ([key, pillProps]: ListBuilderItem) => (
  <Pill {...pillProps} key={key} />
);

export interface PillBoxProps extends MergeElementProps<'div'> {
  /**
   * @defaultValue `[]`
   */
  pills?: Array<ListBuilderItem>;
  removeAllLabel: string;
  /**
   * @defaultValue `undefined`
   */
  onRemovePill?: (event: MouseEvent<HTMLButtonElement>, item: ListBuilderItem) => void;
  /**
   * @defaultValue `undefined`
   */
  onRemoveAll?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * @defaultValue `defaultRenderPill`
   */
  renderPill?: (pill: ListBuilderItem) => ReactNode;
}

const PillBox: FC<React.PropsWithChildren<PillBoxProps>> = props => {
  const {
    pills,
    removeAllLabel,
    onRemovePill,
    onRemoveAll,
    renderPill = defaultRenderPill,
    ...rest
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const untabbablePills = getUntabbablePills(wrapperRef);

    const { previous, next } = getSurroundingFocusableElements(untabbablePills);

    if (event.key === keyboardKeynames.ARROW_RIGHT || event.key === keyboardKeynames.ARROW_DOWN) {
      if (next) {
        next.focus();
      } else {
        untabbablePills.at(0)?.focus();
      }
    }

    if (event.key === keyboardKeynames.ARROW_LEFT || event.key === keyboardKeynames.ARROW_UP) {
      if (previous) {
        previous.focus();
      } else {
        untabbablePills.at(-1)?.focus();
      }
    }
  };

  const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    if (event.target === wrapperRef.current) {
      setHasFocus(true);
      getUntabbablePills(wrapperRef)[0].focus();
    }
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    const untabbablePills = getUntabbablePills(wrapperRef);
    if (event.relatedTarget && !untabbablePills.includes(event.relatedTarget as HTMLElement)) {
      setHasFocus(false);
    }
  };

  return (
    <PillBoxWrapper
      {...rest}
      ref={wrapperRef}
      tabIndex={hasFocus ? -1 : 0}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <InnerPillWrapper>
        {pills?.map(
          pill => {
            const [key, pillProps] = pill;
            return renderPill([key, {
              ...pillProps,
              onKeyDown: handleKeyDown,
              key,
              onClose: event => onRemovePill?.(event, pill),
              size: 'small',
              tabIndex: -1,
              closeLabel: `remove ${pillProps.label}`,
            }]);
          },
        )}
      </InnerPillWrapper>
      <RemoveAllButtonWrapper>
        {pills && pills.length > 0 && (
          <Button transparent onClick={onRemoveAll} aria-label={removeAllLabel}>
            <Icon type={times} />
          </Button>
        )}
      </RemoveAllButtonWrapper>
    </PillBoxWrapper>
  )
};

PillBox.defaultProps = {
  pills: [],
  onRemovePill: undefined,
  onRemoveAll: undefined,
  renderPill: undefined,
};

export default PillBox;
