/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useRef,
  ReactNode,
  RefObject,
} from 'react';
import { TaktIdProvider } from '../TaktIdContext';
import DropdownPopper, { DropdownPopperProps } from './DropdownPopper';
import SelectList, { SelectListProps } from '../SelectList/SelectList';
import usePopperSelectListHelper, { UsePopperSelectListHelperProps } from './usePopperSelectListHelper';
import { getPlacement } from './utils';

function getSize(elementRef: RefObject<HTMLElement | undefined> | undefined) {
  let height;
  let width;
  if (elementRef && elementRef.current) {
    const size = elementRef.current.getBoundingClientRect();
    height = size.height;
    width = size.width;
  }
  return {
    height,
    width,
  };
}

export interface DesktopContainerProps extends Omit<SelectListProps, 'focusedValue'>, Omit<DropdownPopperProps, 'popperRef' | 'popperElementDidMount' | 'onKeyDown' | '$minWidth' | 'offset' | 'placement'> {
  preRender?: () => ReactNode;
  postRender?: () => ReactNode;
  children: ReactNode;
  focusSelected?: boolean;
  onClose: () => void;
  selectedValue?: string | undefined;
  anchorEl?: UsePopperSelectListHelperProps['anchorEl'];
  forceOpen?: boolean;
  popperRef?: RefObject<HTMLDivElement>;
  openDirection?: 'bottom' | 'top';
  forceDirection?: boolean;
}

function DesktopContainer({
  isOpen,
  onClose,
  children,
  shadowRoot,
  trigger,
  preRender,
  postRender,
  selectedValue,
  onChange,
  noWrap,
  focusSelected,
  anchorEl,
  forceOpen,
  popperRef,
  openDirection,
  forceDirection,
}: DesktopContainerProps) {
  const internalRef = useRef<HTMLDivElement>(null);
  const { height, width } = getSize(anchorEl);

  const {
    focusedValue,
    handleKeyDown,
    onPopoverElementDidMount,
  } = usePopperSelectListHelper({
    isOpen,
    anchorEl,
    popperRef: popperRef ?? internalRef,
    onClose,
    focusSelected,
    focusAnchorElDisabled: forceOpen,
  })

  return (
    <DropdownPopper
      popperRef={popperRef ?? internalRef}
      isOpen={isOpen}
      onClose={onClose}
      offsetDistance={-Math.abs(height ?? 0)}
      $minWidth={width}
      onKeyDown={handleKeyDown}
      popperElementDidMount={onPopoverElementDidMount}
      shadowRoot={shadowRoot}
      trigger={trigger}
      focusFirstElementOnPopoverExited
      placement={getPlacement(openDirection)}
      enableFlip={!forceDirection}
    >
      <TaktIdProvider fallbackId="dropdown-desktop">
        {preRender?.()}
        <SelectList
          selectedValue={selectedValue}
          onChange={onChange}
          noWrap={noWrap}
          focusedValue={focusedValue}
          afterOnSelect={onClose}
        >
          {children}
        </SelectList>
        {postRender?.()}
      </TaktIdProvider>
    </DropdownPopper>
  );
}
DesktopContainer.defaultProps = {
  selectedValue: undefined,
  preRender: undefined,
  postRender: undefined,
  focusSelected: true,
  anchorEl: undefined,
  forceOpen: false,
}

export default DesktopContainer;
