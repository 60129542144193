import React, { FC, ReactNode, useContext } from 'react';
import PT from 'prop-types';
import { noop } from '@amzn/storm-ui-utils';
import DropdownDivider from '../../Dropdown/DropdownDivider';
import { SelectOptionGroupContainer, StyledSelectOptionGroupHeader } from './SelectOptionGroup.styles';
import SelectList, { SelectListProps } from '../SelectList';
import { SelectOptionGroupDisplayName } from '../utils/displayNames';
import { SelectListClickEventHandler } from '../types';
import SelectListContext from '../SelectListContext';

export interface SelectOptionGroupProps extends SelectListProps {
  /**
   * Specifies label for the group.
   */
  label: ReactNode;
  /**
   * Called when the group is clicked.
   * @defaultValue `() => undefined`
   */
  onClick?: SelectListClickEventHandler;
}

const SelectOptionGroup: FC<React.PropsWithChildren<SelectOptionGroupProps>> = ({
  label,
  onClick,
  noWrap,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  focusedValue,
  /**
   * DEPRECATE IN STORM 4.X: This should only be read from SelectListContext.
   */
  selectedValue,
  ...rest
}) => {
  const {
    selectedValue: selectedValueFromContext,
    focusedValue: focusedValueFromContext,
    noWrap: noWrapFromContext,
    onClick: selectListOnClick,
    afterOnSelect,
  } = useContext(SelectListContext);

  return (
    <SelectOptionGroupContainer>
      <DropdownDivider />
      <StyledSelectOptionGroupHeader>{label}</StyledSelectOptionGroupHeader>
      <SelectList
        onChange={selectListOnClick || onClick}
        {...rest}
        selectedValue={selectedValueFromContext || selectedValue}
        focusedValue={focusedValueFromContext || focusedValue}
        noWrap={noWrapFromContext || noWrap}
        afterOnSelect={afterOnSelect}
      />
    </SelectOptionGroupContainer>
  );
};

SelectOptionGroup.displayName = SelectOptionGroupDisplayName;
SelectOptionGroup.propTypes = {
  label: PT.string.isRequired,
  onClick: PT.func,
};
SelectOptionGroup.defaultProps = {
  onClick: noop,
};

export default SelectOptionGroup;
