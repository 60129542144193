import { useContext } from 'react';
import DatePickerContext from '../contexts/DatePicker/DatePickerContext';
import { DatePickerContextValue } from '../types';

const useDatePicker = (): DatePickerContextValue => {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error(
      'DatePicker context is not defined.',
    );
  }
  return context;
};

export default useDatePicker;
