import React, {
  ChangeEventHandler,
  Component,
  MouseEventHandler,
  Ref,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { search, times } from '@amzn/storm-ui-icons-v3';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import Text from '../Text';
import Icon from '../Icon';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import Button from '../Button';
import { styleInputMixin } from '../InputFormGroup/InputFormGroup.styles';
import { ButtonProps } from '../Button/Button';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';

const NodeSafeInputElement = (typeof HTMLInputElement !== 'undefined' ? HTMLInputElement : Object);

interface SearchInputWrapperProps {
  $disabled?: boolean;
}
const SearchInputWrapper = styled('span')<SearchInputWrapperProps>`
  gap: 2px;

  && {
    background-color: ${({ theme, $disabled }) => (
    $disabled ? theme.form.input.disabled.bg : theme.form.input.bg
  )};
  }
`;

const StyledSearch = styled(
  ({ hideLabel, ...rest }) => <Icon type={search} {...rest} />,
)`
  font-size: ${({ theme }) => theme.form.search.searchIconFontSize};
  opacity: ${({ theme }) => theme.form.search.searchIconOpacity};
  color: ${({ theme }) => theme.form.search.searchIconColor};
  margin-inline-end: ${({ theme }) => theme.form.search.searchIconMarginRight};
  display: flex;

  ${isMobile(css`
    font-size: ${({ theme }) => theme.form.search.mobile.searchIconFontSize};
  `)}
`;
StyledSearch.displayName = 'StyledSearch';

const StyledClose = styled(Button)`
  font-size: ${({ theme }) => theme.form.search.closeButtonFontSize};
  background: none;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.form.search.closeButtonColor};
  margin: ${({ theme }) => theme.form.search.closeButtonMargin};
  padding: ${({ theme }) => theme.form.search.closeButtonPadding};
  border-radius: ${({ theme }) => theme.form.search.closeButtonBorderRadius};

  :hover {
    color: ${({ theme }) => theme.form.search.closeButtonColorHover};
    background: none;
    box-shadow: none;
  }

  & span {
    display: flex;
  }

  ${isMobile(css`
    font-size: ${({ theme }) => theme.form.search.mobile.closeButtonFontSize};
    position: absolute;
    padding: ${({ theme }) => theme.form.search.mobile.closeButtonPadding};

    /* @noflip */ left: auto;
    inset-inline-start auto;
    /* @noflip */ right: 0;
    inset-inline-end: 0;

    [dir="rtl"] && {
      /* @noflip */ right: auto;
      inset-inline-start auto;
      /* @noflip */ left: 0;
      inset-inline-end: 0;
    }
  `)}
`;
StyledClose.displayName = 'StyledClose';

const CloseIcon = withTaktFallbackId<ButtonProps>(StyledClose);

interface SearchInputElementProps extends MergeElementProps<'input'> {
  className: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClear: MouseEventHandler<HTMLButtonElement>;
  clearBtnProps?: Partial<ButtonProps>;
  clearButtonLabel?: string;
  inputRef?: Ref<HTMLInputElement>;
  disabled?: boolean;
}

class SearchInputElement extends Component<SearchInputElementProps> {
  static propTypes = {
    className: PT.string.isRequired,
    value: PT.string.isRequired,
    onChange: PT.func.isRequired,
    onClear: PT.func.isRequired,
    clearBtnProps: PT.objectOf(PT.any),
    clearButtonLabel: PT.string,
    inputRef: PT.oneOfType([
      PT.func,
      PT.shape(
        { current: (PT.instanceOf(NodeSafeInputElement)) },
      ),
    ]),
    disabled: PT.bool,
  }

  static defaultProps = {
    clearBtnProps: {},
    clearButtonLabel: 'Clear search terms',
    inputRef: () => null,
    disabled: false,
  }

  render(): JSX.Element {
    const {
      className,
      value,
      onChange,
      onClear,
      clearBtnProps,
      clearButtonLabel,
      inputRef,
      disabled,
      ...rest
    } = this.props;
    return (
      <SearchInputWrapper $disabled={disabled} className={className}>
        <StyledSearch />
        <input {...rest} disabled={disabled} value={value} onChange={onChange} ref={inputRef} />
        {value && (
          <CloseIcon
            {...clearBtnProps}
            type="button"
            onClick={onClear}
            aria-label={clearButtonLabel}
            disabled={disabled}
            taktFallbackId={createStormTaktId('close-button')}
          >
            <Icon type={times} />
            <Text type="sr-only">{clearButtonLabel}</Text>
          </CloseIcon>
        )}
      </SearchInputWrapper>
    );
  }
}

const SearchInputComponent = styled(SearchInputElement)<SearchInputElementProps>`
  display: flex;
  align-items: center;
  min-height: calc(${({ theme }) => theme.form.input.height} - 6px);

  > input {
    ${styleInputMixin}
    flex : 1 1 auto;
    padding: 0;
    border-style: none;
    -webkit-appearance: none;

    min-width: 1em;
    :focus{
      outline: none;
      box-shadow: none;
      border: none;
    }

    height:100%;
    min-height: auto;
    box-shadow: none;
    border: none;
  }
`;

export default SearchInputComponent;
