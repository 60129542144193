import { palette } from '../base/color';
import { mobileSpacing } from '../base/spacing';
import { fontSizes, mobileFontSizes, typography } from '../base/typography';

const searchInputStyles = {
  searchIconOpacity: 1,
  searchIconMarginRight: '8px',
  searchIconFontSize: fontSizes.base,
  searchIconColor: palette.gray[500],
  closeButtonFontSize: fontSizes.mini,
  closeButtonColor: typography.color.tertiary,
  closeButtonColorHover: palette.squidInk,
  closeButtonPadding: '0 4px',
  closeButtonBorderRadius: '100%',
  closeButtonMargin: '0 0 0 2px',
};

export const mobileSearchInputStyles = {
  mobile: {
    searchIconFontSize: mobileFontSizes.base,
    closeButtonFontSize: mobileFontSizes.base,
    closeButtonPadding: `9px ${mobileSpacing.small}`,
  },
};

export default searchInputStyles;
