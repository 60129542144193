import styled from 'styled-components';
import SpinnerInput from './SpinnerInput';

const TimeInputSubInput = styled(SpinnerInput)``;
TimeInputSubInput.displayName = 'TimeInputSubInput';

interface TimeInputInputGroupProps {
  $isFocus: string | null;
}

const TimeInputInputGroup = styled('span')<TimeInputInputGroupProps>`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  background: ${({ theme }) => theme.form.input.bg};

  border-top-right-radius: ${({ theme }) => theme.form.input.radius};
  border-bottom-right-radius: ${({ theme }) => theme.form.input.radius};

  height: calc(${({ theme }) => theme.form.input.height} - 6px);
  padding-inline-end: ${({ theme }) => theme.form.input.padding};


  ${TimeInputSubInput}{
    width: 1.5em; /* Force all browsers to maintain a input width of *approximately* 2 characters - white space */
    ${({ theme }) => theme.typography.base}; /* override AUI */
    box-shadow: none; /* override AUI */
    height: auto; /* override AUI */
    border: none; /* override AUI */
    margin: 0;/* override AUI */
    background: none;/* override AUI */
    text-align: center; /* override AUI */
    border-radius: 0; /* override AUI */

    :focus {
      outline: none;/* override AUI */
      box-shadow: none; /* override AUI */
      border: none; /* override AUI */
    }
  }
  && {
    width: auto;
  }

`;
TimeInputInputGroup.displayName = 'TimeInputInputGroup';

export {
  TimeInputInputGroup,
  TimeInputSubInput,
};
