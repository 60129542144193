import { css } from 'styled-components';

const focusShadow = css`
  box-shadow: ${({
    theme: {
      focusVisible: {
        boxShadowBlurRadius,
        boxShadowSpreadRadius,
        boxShadowColor,
      },
    },
  }) => `0 0 ${boxShadowBlurRadius} ${boxShadowSpreadRadius} ${boxShadowColor}`};
`;

export default focusShadow;
