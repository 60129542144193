/* eslint-disable id-length */
import { IconDefinition, SVGTransformationMap } from '../types';
import getSanitizedIcon from './getSanitizedIcon';
import getSVGTransformer from './getSVGTransformer';

/** Returns the path */
const getIconPath = (pathData: string | string[], transform?: string) => {
  if (Array.isArray(pathData)) {
    let pathBuilder = '';
    pathData.forEach(path => {
      pathBuilder += `<path ${transform ? `transform="${transform}"` : ''} fill="currentColor" d="${path}" />`;
    });
    return pathBuilder;
  }
  return `<path ${transform ? `transform="${transform}"` : ''} fill="currentColor" d="${pathData}" />`;
};

/** Either returns the Group if a transform exists or just the path */
const getIconGroup = (path: string | string[], transformer?: SVGTransformationMap | null) => {
  if (transformer) {
    const {
      outer: { transform: outerTransform },
      inner: { transform: innerTransform },
      path: { transform: pathTransform },
    } = transformer;
    return `<g transform="${outerTransform}"><g transform="${innerTransform}">${getIconPath(path, pathTransform)}</g></g>`;
  }
  return getIconPath(path);
};

/** Returns the SVG as a string */
const renderIconSVG = (
  iconDef: IconDefinition, transform?: string, className?: string,
): string => {
  const { prefix, iconName, icon } = iconDef;
  const { height, width, path } = getSanitizedIcon(icon);
  const transformer = getSVGTransformer(width, transform);
  return `<svg aria-hidden="true" viewBox="0 0 ${width} ${height}" class="${className} ${prefix} fa-${iconName}" data-fa-i2svg>${getIconGroup(path, transformer)}</svg>`;
};

export default renderIconSVG;
