import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
  ReactElement,
} from 'react';
import {
  OnRootContainerMousedown,
  keyboardKeynames as keys,
  isEventFromWithinElement,
  noop,
  composeRefs,
} from '@amzn/storm-ui-utils-v3';
import { TaktProps } from '../types/TaktProps';
import Popper, { PopperProps } from '../Popper/Popper';
import MenuList from './MenuList/MenuList';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';

export interface MenuProps extends TaktProps, PopperProps {
  /**
   * Identifier for the Menu. Used for aria-attributes to provide accessibility to the Menu.
   */
  id: string;
  /**
   * This disables interaction with the menu trigger and applies special
   * visual styles to indicate that it's not interactive.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * Callback that is called when the Menu is closed. Use React.useCallback so the same
   * instance of the function is used on re-render.
   * @defaultValue `() => undefined`
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (event: any) => void;
  trigger: ReactElement;
  /**
   * Justifies the `<Popper/>` relative to the trigger.
   * @defaultValue `"start"`
   */
  align?: PopperProps['align'];
  /**
   * Positions the `<Popper/>` element relative to the trigger.
   * @defaultValue `"bottom"`
   */
  position?: PopperProps['position'];
  /**
   * Specifies whether to render the `<Popper/>` component with the arrow pip.
   * @defaultValue `false`
   */
  withArrow?: PopperProps['withArrow'];
}

const Menu: FC<React.PropsWithChildren<MenuProps>> = ({
  id,
  align = 'start',
  children,
  disabled = false,
  onClose,
  onClick,
  isOpen,
  position = 'bottom',
  trigger,
  withArrow = false,
  taktId,
  taktValue,
  ...rest
}) => {
  const popperRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLElement>(null);

  const onClickOutside = useCallback((event: MouseEvent) => {
    if (popperRef.current
      && !isEventFromWithinElement(event, popperRef.current)
      && !isEventFromWithinElement(event, triggerRef.current)
    ) {
      onClose?.(event);
    }
  }, [onClose]);

  const onClickTrigger = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    if (!disabled) {
      event.stopPropagation();
      event.preventDefault();
      onClick?.(event);
    }
  }, [disabled, onClick]);

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>): void => {
    const { key } = event;

    switch (key) {
      case keys.ESCAPE:
      case keys.TAB:
      case keys.ENTER:
      case keys.SPACE:
        onClose?.(event);
        event.preventDefault();
        break;
      default:
        break;
    }
  }, [onClose]);

  return (
    <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('menu')}>
      <Popper
        align={align}
        autoFocus
        position={position}
        isOpen={isOpen}
        popperRef={popperRef}
        padding="none"
        spacing={10}
        withArrow={withArrow}
        {...rest}
        onClick={onClickTrigger}
        onKeyDown={onKeyDown}
        trigger={
          React.cloneElement(trigger, {
            'aria-haspopup': 'menu',
            'aria-controls': `${id}`,
            'aria-expanded': isOpen,
            id: `${id}-trigger`,
            buttonRef: trigger.props.buttonRef ? composeRefs(trigger.props.buttonRef, triggerRef) : undefined,
            ref: trigger.props.buttonRef ? undefined : triggerRef,
            disabled,
          })
        }
      >
        <OnRootContainerMousedown
          rootContainer={typeof document !== 'undefined' ? document.documentElement : undefined}
          handler={onClickOutside as unknown as (event: Event) => void}
        >
          <MenuList
            id={`${id}`}
            aria-labelledby={`${id}-trigger`}
          >
            {children}
          </MenuList>
        </OnRootContainerMousedown>
      </Popper>
    </TaktIdProvider>
  );
};

Menu.defaultProps = {
  disabled: false,
  onClose: noop,
};

export default Menu;
