import React, {
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  PureComponent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { keyboardKeynames as keys, MergeElementProps } from '@amzn/storm-ui-utils-v3';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import focusOutlineStyle from '../../FocusIndicator/styleMixins/focusOutline';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';

// eslint-disable-next-line max-len
type SuperClickEventHandler = MouseEventHandler<HTMLLIElement> & KeyboardEventHandler<HTMLLIElement>;

export interface NavItemProps extends MergeElementProps<'li'> {
  onClick?: SuperClickEventHandler;
  $isActive?: boolean;
  $subItem?: boolean;
}

const NavItem = styled('li').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
    type: 'button',
  });
})<NavItemProps>`
  font-weight: ${({ $isActive, theme }) => ($isActive ? theme.sideNavItem.activeFontWeight : theme.sideNavItem.inactiveFontWeight)};
  color: ${({ theme }) => theme.sideNavItem.color};
  border-left: ${({ $isActive, theme }) => ($isActive ? theme.sideNavItem.activeBorderLeft : theme.sideNavItem.inactiveBorderLeft)};
  box-sizing: border-box;
  width:100%;
  padding-right: ${({ theme }) => theme.sideNavItem.paddingRight};
  padding-top: ${({ theme }) => theme.sideNavItem.paddingTop};
  padding-bottom: ${({ theme }) => theme.sideNavItem.paddingBottom};
  padding-left: ${({ $isActive, $subItem, theme }) => {
    if ($subItem) {
      if ($isActive) { return theme.sideNavItem.subItemActiveLeftPadding; }
      return theme.sideNavItem.subItemInactiveLeftPadding;
    }
    if ($isActive) { return theme.sideNavItem.activeLeftPadding; }
    return theme.sideNavItem.inactiveLeftPadding;
  }};
  margin: ${({ theme, $subItem }) => ($subItem ? theme.sideNavItem.marginSubItem : theme.sideNavItem.margin)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${({ $subItem }) => ($subItem ? '12px' : '13px')};
  :active, :visited {
    color: ${({ theme }) => theme.sideNavItem.color};
  }

  :link {
    color: ${({ theme }) => theme.sideNavItem.color};
    text-decoration: none;
  }

  :hover {
    font-weight: bold;
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutlineStyle)}
  }
`;
NavItem.displayName = 'NavItem';

const NavItemLabel = styled.span`
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
NavItemLabel.displayName = 'NavItemLabel';

export interface SideNavItemProps extends TaktProps, MergeElementProps<'li'> {
  /**
     * onClick function passed to SideNavItem.
     * @defaultValue `undefined`
     */
  onClick?: SuperClickEventHandler;
  /**
     * Determines if the item is active or not.
     * @defaultValue `false`
     */
  isActive?: boolean;
  /**
     * Determines if the item is a subItem or not (displays indented).
     * @defaultValue `false`
     */
  subItem?: boolean;
  /**
     * Render a component before the item's label.
     * @defaultValue `undefined`
     */
  renderStart?: () => ReactNode;
  /**
     * Render a component after the item's label.
     * @defaultValue `undefined`
     */
  renderEnd?: () => ReactNode;
}

class SideNavItem extends PureComponent<SideNavItemProps> {
  static propTypes = {
    /**
     * onClick function passed to SideNavItem.
     */
    onClick: PT.func,
    /**
     * Determines if the item is active or not.
     */
    isActive: PT.bool,
    /**
     * Determines if the item is a subItem or not (displays indented).
     */
    subItem: PT.bool,
    /**
     * What to display in the side nav item.
     */
    children: PT.node.isRequired,
    /**
     * Render a component before the item's label.
     */
    renderStart: PT.func,
    /**
     * Render a component after the item's label.
     */
    renderEnd: PT.func,
  }

  static defaultProps = {
    onClick: undefined,
    subItem: false,
    isActive: false,
    renderStart: undefined,
    renderEnd: undefined,
  }

  handleKeyDown = (event: KeyboardEvent<HTMLLIElement>): void => {
    const { key } = event;
    const { onClick } = this.props;
    if (key === keys.ENTER || key === keys.SPACE) {
      onClick?.(event);
      event.preventDefault();
    }
  }

  render(): JSX.Element {
    const {
      onClick,
      isActive,
      subItem,
      children,
      renderStart,
      renderEnd,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('side-nav-item')}>
        {({ getDataTaktAttributes }) => (
          <NavItem
            {...getDataTaktAttributes()}
            {...rest}
            onClick={onClick}
            $isActive={isActive}
            $subItem={subItem}
            tabIndex={0}
            role="button"
            onKeyDown={this.handleKeyDown}
          >
            {renderStart && renderStart()}
            <NavItemLabel>
              {children}
            </NavItemLabel>
            {renderEnd && renderEnd()}
          </NavItem>
        )}

      </TaktIdConsumer>
    );
  }
}

export default SideNavItem;
