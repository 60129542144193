import React, {
  PureComponent,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
} from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import PT, { Validator } from 'prop-types';
import styled, { css } from 'styled-components';

import focusOutline from '../../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';

export interface StyledToggleProps {
  $active?: boolean;
  disabled?: boolean;
}

export interface StyledToggleAttrsProps extends StyledToggleProps {
  $focusVisible?: boolean;
}

export const activeStyles = css`
  border-color: ${({ theme }) => theme.button.toggleArea.borderColorActive};
  background-color: ${({ theme }) => theme.button.toggleArea.bgActive};
  font-weight: bold;
  a, p {
    font-weight: normal;
  }
`;

export const normalStyles = css`
  border-color: ${({ theme }) => theme.button.toggleArea.borderColor};
  background-color: ${({ theme }) => theme.button.toggleArea.bg};
  :hover {
    background: ${({ theme }) => theme.button.toggleArea.bgHover};
  }
`;

export const basicStyles = css`
  ${({ theme }) => theme.typography.base};
  padding: ${({ theme }) => theme.button.toggleArea.padding};
  border-radius: ${({ theme }) => theme.button.toggleArea.borderRadius};
  border: 1px solid ${({ theme }) => theme.button.borderColor};
  box-shadow: 0px 2px 4px rgba(35, 47, 63, 0.15);
  color: ${({ theme }) => theme.button.toggleArea.color};
  cursor: ${({ theme }) => theme.button.cursor};
  text-align: center;
  vertical-align: middle;
  user-select: auto;

  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,background-color 100ms ease,border-color 100ms ease, box-shadow 100ms ease;
  }
`;

export const mobileBasicStyles = css`
  padding: ${({ theme }) => theme.button.toggleArea.padding};
  font-size: ${({ theme }) => theme.button.normal.mobile.fontSize};
`;

export const disabledStyles = css<StyledToggleAttrsProps>`
  pointer-events: all;
  cursor: not-allowed;
  outline: none;
  opacity: 1;
  color: ${({ theme }) => theme.typography.color.tertiary};
  box-shadow: none;
  :hover {
    background: ${({ theme, $active }) => ($active ? theme.button.toggleArea.bgActive : theme.button.toggleArea.bg)};
    box-shadow: none;
  }
`;

const StyledToggleArea = styled('div')
  .attrs<StyledToggleProps>(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps({ onFocus, onBlur }, focusProps),
      $focusVisible: isFocusVisible,
    });
  })<StyledToggleAttrsProps>`
  ${basicStyles}

  ${isMobile(mobileBasicStyles)}

  ${({ $active }) => ($active ? activeStyles : normalStyles)}

  :focus {
    outline: none;
    ${({ $focusVisible }) => ($focusVisible && focusOutline)}
  }

  :hover {
    box-shadow: 0px 2px 5px rgba(35, 47, 63, 0.25);
  }

  ${({ disabled }) => (disabled && css<StyledToggleAttrsProps>`
    ${disabledStyles}
  `)}
`;
StyledToggleArea.displayName = 'StyledToggleArea';

/* SSR workaround for nodejs, things like HTMLDivElement dont exists in nodejs. */
const NodeSafeHTMLDivElement = (typeof HTMLDivElement !== 'undefined' ? HTMLDivElement : Object) as typeof HTMLDivElement;

export type ElementRef = ComponentPropsWithRef<'div'>['ref'];
export interface ToggleAreaProps extends TaktProps, ComponentPropsWithoutRef<'div'> {
  /**
   * @defaultValue `false`
   */
  active?: boolean;
  /**
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * @defaultValue `undefined`
   */
  elementRef?: ElementRef;
}

class ToggleArea extends PureComponent<ToggleAreaProps> {
  static propTypes = {
    active: PT.bool,
    disabled: PT.bool,
    elementRef: PT.oneOfType([
      PT.func,
      PT.shape({ current: (PT.instanceOf(NodeSafeHTMLDivElement)) }) as Validator<ElementRef>,
    ]),
  }

  static defaultProps = {
    active: false,
    disabled: false,
    elementRef: undefined,
  }

  render(): JSX.Element {
    const {
      active,
      disabled,
      elementRef,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('toggle-area')}>
        {({ getDataTaktAttributes }) => (
          <StyledToggleArea
            tabIndex={1} // eslint-disable-line jsx-a11y/tabindex-no-positive
            {...getDataTaktAttributes()}
            {...rest}
            ref={elementRef}
            role="switch"
            $active={active}
            aria-checked={active}
            aria-disabled={disabled}
            disabled={disabled}
          />
        )}
      </TaktIdConsumer>
    );
  }
}

export default ToggleArea;
