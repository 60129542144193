import {
  mobileTypography as typography,
  mobileLink as link,
} from '../base/typography';
import {
  mobileSpacing,
  mobileSpacingValues,
} from '../base/spacing';
import { mobileButton as button } from '../component/button';
import { mobileDividerStyles as divider } from '../component/divider';
import { mobileDropdownStyles as dropdown } from '../component/dropdown';
import { mobileHelptipStyles as helptip } from '../component/helptip';
import { mobileInputStyles as input } from '../component/input';
import { mobileTagStyles as tag } from '../component/tag';
import { mobileTouchLink as touchLink } from '../component/touchLink';
import { mobileTree as tree } from '../component/tree';
import { mobileRadioStyles as radio } from '../component/radio';
import { mobileSearchInputStyles as search } from '../component/searchInput';
import { mobileAlertStyles } from '../component/alert';
import { mobileCheckboxStyles as checkbox } from '../component/checkbox';
import { mobileTabNavigationStyles as tabNav } from '../component/tabNavigation';
import { mobilePagination as pagination } from '../component/pagination';
import { mobilePill as pillLegacy } from '../component/pillLegacy';
import { mobilePill as pill } from '../component/pill';
import { mobileTableStyles as table } from '../component/table';
import { mobileDatepickerStyles as datepicker } from '../component/datepicker';
/* TS does not like { mobileSwitchStyles as switch }. likely because switch is a JS reserved word */
import { mobileSwitchStyles } from '../component/switch';

const mobileMediaQuery = '(orientation: portrait) and (max-width: 575.98px) and (pointer: coarse), (orientation: landscape) and (max-height: 575.98px) and (pointer: coarse)';
const notMobileMediaQuery = 'all and (orientation: portrait) and (min-width: 576px), all and (orientation: landscape) and (min-height: 576px), all and (pointer: fine)';

const theme = {
  mobile: {
    mediaQuery: mobileMediaQuery,
    inverseMediaQuery: notMobileMediaQuery,
    spacing: mobileSpacing,
    spacingValues: mobileSpacingValues,
    link,
  },
  globals: {
    mobile: {
      link, // DEPRECATE
    },
  },
  typography,
  button,
  divider,
  dropdown,
  tag,
  form: {
    input,
    radio,
    search,
    checkbox,
  },
  message: mobileAlertStyles,
  touchLink,
  tree,
  navigation: {
    tabs: tabNav,
  },
  switch: mobileSwitchStyles,
  pagination,
  pillLegacy,
  pillv2Beta: pill, // adding in for TropicalUI support, which references this theme value
  pill,
  table,
  datepicker,
  helptip,
};

export default theme;

export type Theme = typeof theme;
