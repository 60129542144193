import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { angleInlineEnd } from '@amzn/storm-ui-icons-v3';
import {
  TouchLinkContent,
  TouchLinkIcon,
} from '../TouchLink.styles';

const InlineTouchLinkContent = styled(TouchLinkContent)`
  /* inline touch links are transparent */
  background-color: unset;
  border: none;
  border-radius: 0px;
  padding: 0;
`;

const InlineTouchLinkIcon = styled(TouchLinkIcon)`
  /* @noflip */ left: auto;
  inset-inline-start: auto;
  /* @noflip */ right: 0;
  inset-inline-end: 0;

  [dir="rtl"] && {
    /* @noflip */ right: auto;
    inset-inline-start: auto;
    /* @noflip */ left: 0;
    inset-inline-end: 0;
  }
`;

export interface InlineTouchLinkBodyProps {
  children: ReactNode;
}

const InlineTouchLinkBody = ({ children }: InlineTouchLinkBodyProps) => (
  <InlineTouchLinkContent>
    {children}
    <InlineTouchLinkIcon type={angleInlineEnd} size="lg" />
  </InlineTouchLinkContent>
)

export default InlineTouchLinkBody;
