import React, {
  FC, ReactNode,
  MouseEventHandler,
  ComponentProps,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { angleDown, angleUp } from '@amzn/storm-ui-icons';
import Icon from '../Icon';
import TextButton from '../Button/TextButton';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const IconWrapper = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.icon.color};
  width: 14px;
  padding-inline-end: ${({ theme }) => theme.spacing.mini};
  flex: 0 0 auto;
`;

const ExtenderButton = styled(TextButton)`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  text-align: initial;

  :hover {
    > ${IconWrapper} {
      color: ${({ theme }) => theme.icon.hover.color};
    }
  }

  ${isMobile(css`
    margin: 10px auto;
  `)}
`;
ExtenderButton.displayName = 'ExtenderButton';

const TextButtonLabel = styled('div')`
  flex: 1 1 auto;
`;

const ExtenderContent = styled('section')<{isOpen:boolean}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
ExtenderContent.displayName = 'ExtenderContent';

export interface ExtenderProps {
  /**
   * The Extender Button label.
   */
  label: ReactNode;
  /**
   * Denotes if the InlineExapnder is open or closed.
   */
  isOpen: boolean;
  /**
   * The click event handler. Used to toggle state.
   * @defaultValue `() => undefined`
   */
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Additional props to be passed to the triggering text button.
   * @defaultValue `{}`
   */
  triggerProps?: ComponentProps<typeof ExtenderButton>;
}

const Extender: FC<React.PropsWithChildren<ExtenderProps & ComponentProps<typeof ExtenderContent>>> = ({
  children,
  handleClick,
  isOpen,
  label,
  triggerProps = {},
  ...rest
}) => (
  <>
    <ExtenderContent {...rest} isOpen={isOpen}>
      {children}
    </ExtenderContent>
    <ExtenderButton
      {...triggerProps}
      onClick={handleClick}
      tabindex="0"
      aria-expanded={isOpen}
    >
      <IconWrapper>
        {isOpen
          ? <Icon type={angleUp} size="sm" />
          : <Icon type={angleDown} size="sm" /> }
      </IconWrapper>
      <TextButtonLabel>{label}</TextButtonLabel>
    </ExtenderButton>
  </>
);

Extender.propTypes = {
  /**
   * The content within the Extender.
   */
  children: PT.node.isRequired as React.Requireable<ReactNode>,
  /**
   * The Extender Button label.
   */
  label: PT.node.isRequired as React.Requireable<ReactNode>,
  /**
   * Denotes if the InlineExapnder is open or closed.
   */
  isOpen: PT.bool.isRequired,
  /**
   * The click event handler. Used to toggle state.
   */
  handleClick: PT.func,
  /**
   * Additional props to be passed to the triggering text button.
   */
  triggerProps: PT.object, // eslint-disable-line react/forbid-prop-types
};

Extender.defaultProps = {
  handleClick: () => undefined,
  triggerProps: {},
};

export default Extender;
