/* eslint-disable id-length */
/**
 * A mapping of date-fns locale codes to i18n
 */
const dateFnsLocaleMap: Record<string, string> = {
  ar: 'ar-AE',
  cs: 'cs-CZ',
  de: 'de-DE',
  'en-AU': 'en-AU',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  'en-IN': 'en-IN',
  'en-US': 'en-US',
  es: 'es-MX',
  'fr-CA': 'fr-CA',
  fr: 'fr-FR',
  he: 'he-IL',
  hi: 'hi-IN',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  'nl-NL': 'nl',
  pl: 'pl-PL',
  'pt-BR': 'pt-BR',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

export default dateFnsLocaleMap;
