import React, {
  useCallback, useState, useRef, KeyboardEvent,
} from 'react';
import { useTheme } from 'styled-components';
import {
  noop,
  keyboardKeynames as keys,
} from '@amzn/storm-ui-utils';
import useInlinePopper from '../Popper/useInlinePopper';
import { PopperTrigger } from '../Popper/Popper.styles';
import { Theme } from '../theme';
import { getPlacement } from './utils';
import type { TooltipContainerProps } from './types';

function NonInteractiveContainer({
  align,
  children,
  forceOpen,
  inert,
  position,
  spacing,
  trigger,
  triggerAriaDescribedby,
}: TooltipContainerProps) {
  const theme = useTheme();
  const timeout = useRef<number | undefined>(undefined);
  const [isClosedByUser, setIsClosedByUser] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isOpen = forceOpen || (!inert && !isClosedByUser && (isFocused || isHovered));

  const placement = getPlacement(align, position);

  const {
    attributes,
    styles,
    setArrowElement,
    setPopperElement,
    setTriggerElement,
    update,
  } = useInlinePopper({
    offsetDistance: spacing,
    placement,
    enableFlip: true,
    withArrow: true,
    rootBoundary: 'viewport',
  });

  const handleIsFocused = useCallback((value: boolean) => {
    setIsFocused(value);
    setIsClosedByUser(false);
  }, []);

  const handleFocus = useCallback(() => {
    handleIsFocused(true);
  }, [handleIsFocused]);

  const handleBlur = useCallback(() => {
    handleIsFocused(false);
  }, [handleIsFocused]);

  const handleIsHovered = useCallback((value:boolean, delay = 0) => {
    clearTimeout(timeout.current);
    if (delay) {
      timeout.current = window.setTimeout(() => {
        setIsHovered(value);
      }, delay);
    } else {
      setIsHovered(value);
    }
  }, []);

  /**
   * Handles the mouse hovering over the <Tooltip /> trigger.
   */
  const handleMouseEnterTrigger = useCallback(() => {
    if (inert) {
      return;
    }
    handleIsHovered(true, (theme as Theme).tooltip.mouseEnterTimeout);
  }, [handleIsHovered, inert, theme]);

  /**
   * Handles the mouse leaving the <Tooltip /> trigger.
   */
  const handleMouseLeaveTrigger = useCallback(() => {
    handleIsHovered(false, (theme as Theme).tooltip.mouseLeaveTimeout);
  }, [handleIsHovered, theme]);

  /**
   * Handles keyboard input on the <Tooltip /> trigger.
   *
   * Should close the <Tooltip /> if Esc is clicked.
   */
  const handleKeyDownTrigger = useCallback((event: KeyboardEvent<HTMLSpanElement>) => {
    if (inert) {
      return;
    }

    const { key } = event;
    if (key === keys.ESCAPE) {
      setIsClosedByUser(true);
      handleIsHovered(false);
    }
  }, [handleIsHovered, inert]);

  /**
   * Handles the mouse hovering over the <Tooltip /> surface. It should
   * stop the <Tooltip /> from closing.
   *
   * Because it is mouse input, focus should not be visible.
   */
  const handleMouseEnterTooltip = useCallback(() => {
    handleIsHovered(true);
  }, [handleIsHovered]);

  /**
   * Handles the mouse leaving the <Tooltip /> surface. It should
   * close the <Tooltip /> unless it is being forced open.
   *
   * Because it is mouse input, focus should not be visible.
   */
  const handleMouseLeaveTooltip = useCallback(() => {
    handleIsHovered(false);
  }, [handleIsHovered]);

  const handleTooltipEntering = useCallback((node: unknown, isAppearing: boolean) => {
    if (!isAppearing && update !== null) {
      update();
    }
  }, [update]);

  return (
    <>
      <PopperTrigger
        onKeyDown={handleKeyDownTrigger}
        ref={setTriggerElement}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {React.cloneElement(trigger, {
          onMouseEnter: handleMouseEnterTrigger,
          onMouseLeave: handleMouseLeaveTrigger,
          'aria-describedby': triggerAriaDescribedby,
        })}
      </PopperTrigger>
      {children({
        attributes,
        focusableBody: true,
        handleCloseClicked: noop,
        handleMouseEnter: handleMouseEnterTooltip,
        handleMouseLeave: handleMouseLeaveTooltip,
        handlePopoverEntering: handleTooltipEntering,
        isOpen,
        setArrowElement,
        setPopperElement,
        styles,
        withCloseButton: false,
      })}
    </>
  );
}

export default NonInteractiveContainer;
