import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import Text from '../Text';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const focusMixin = css`
  content:"";
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  outline: ${({ theme }) => `${theme.focusVisible.outlineWidth} ${theme.focusVisible.outlineStyle} ${theme.focusVisible.outlineColor}`};
  outline-offset: 2px;
  z-index: 1;
  pointer-events: none;

  ${isMobile(css`
    outline-offset: -2px;
  `)}
`;

const tabUnderline = css`
  position: absolute;
  content: '';
  height: 3px;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.card.tabs.activeBorder};
  border-radius: 2px 2px 0 0;
`;

interface WrapperProps extends MergeElementProps<'div'> {
  isTabOverflow: boolean;
}

export const Wrapper = styled('div') <WrapperProps>`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.card.tabs.border};

  ${({ theme, isTabOverflow }) => isTabOverflow && isMobile(css`
    // visual indicator on scrollable with additional tabs
    ::after {
      position: absolute;
      height: calc(100% - 1px);
      width: ${theme.navigation.tabs.mobile.width};

      /* @noflip */ left: auto;
      inset-inline-start: auto;
      /* @noflip */ right: 0;
      inset-inline-end: 0;

      top: 0;
      content: " ";
      background: ${theme.card.tabs.scrollableIndicatorBackground};
    }

    /*! @noflip */
    [dir="rtl"] &&::after {
      background: ${theme.card.tabs.scrollableIndicatorBackgroundRTL};

      /* @noflip */ right: auto;
      inset-inline-start: auto;
      /* @noflip */ left: 0;
      inset-inline-end: 0;
    }
  `)};
`;

export const TabGroupWrapper = styled.div`
  display: block;
  background: ${({ theme }) => theme.card.tabs.bg};
  border-radius: ${({ theme }) => theme.card.radius} ${({ theme }) => theme.card.radius} 0 0;

  ${isMobile(css`
    overflow-x: scroll;
    ::-webkit-scrollbar { // Chrome & Safari hide scroll bar
      display: none;
    }
    scrollbar-width: none; // FF hide scroll bar
  `)}
`;

// Styled component representing the Tab Group.
export const TabGroup = styled.div`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.card.radius} ${({ theme }) => theme.card.radius} 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 10px;
  list-style: none;
  background: ${({ theme }) => theme.card.tabs.bg};
  padding: 0;
  margin: 0;

  ${isMobile(css`
    white-space: nowrap;
    flex-wrap: nowrap;
  `)}
`;
TabGroup.displayName = 'TabGroup';

interface DefaultTextProps {
  isActive: boolean;
  $label: string;
}

export const DefaultText = styled(Text)<DefaultTextProps>`
  ${({ theme }) => theme.typography.h5}

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.h5}
  `)}

  ${({ isActive }) => !isActive && `
    font-weight: 500;
  `}

  ::after {
    display: block;
    content: "${({ $label }) => $label || ''}";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }
`;
DefaultText.displayName = 'DefaultText';

interface TabProps extends MergeElementProps<'button'> {
  isActive?: boolean
}

// Styled component representing the Tab.
export const Tab = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
}) <TabProps>`
  background: ${({ theme }) => theme.card.tabs.bg};
  display: inline-block;
  list-style-type: none;
  color: ${({ theme }) => theme.card.tabs.fontColor};
  margin:  ${({ theme }) => theme.card.tabs.margin};
  text-align: left; text-align: start;
  outline: none;
  padding: 0px;
  position: relative;
  border: none;

  :first-of-type {
    /*! @noflip */ border-radius: ${({ theme }) => theme.card.radius} 0 0 0;
    margin-inline-start: 16px;

    /*! @noflip */
    [dir="rtl"] & {
      /*! @noflip */ border-radius: 0 ${({ theme }) => theme.card.radius} 0 0;
    }
  }

  :last-of-type {
    margin-inline-end: 16px;
  }

  ${({ theme, isActive }) => (isActive && css`
    color: ${theme.card.tabs.activeFontColor};
    border-inline-start: none;
    border-inline-end: none;
    border-top: none;
  `)}

  ${({ theme, isActive }) => (!isActive && css`
    border: none;

    :hover {
      color: ${theme.card.tabs.hoverFontColor};
      cursor: pointer;
      background-color: ${theme.card.tabs.hover};
    }
  `)}

  ${({ focusVisible }) => (focusVisible && css`
    :first-of-type::after {
      ${focusMixin}
      /*! @noflip */ border-radius: ${({ theme }) => theme.card.radius} 0 0 0;

      /*! @noflip */
      [dir="rtl"] & {
        /*! @noflip */ border-radius: 0 ${({ theme }) => theme.card.radius} 0 0;
      }
    }

    :not(:first-of-type)::after {
      ${focusMixin}
    }
  `)}
`;
Tab.displayName = 'Tab';

// Styled component for the space after the tabs with border bottom
export const TabRemainSpace = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.card.tabs.bg};
  flex: 1;

  /*! @noflip */ border-radius: 0 ${({ theme }) => theme.card.radius} 0 0;

  /*! @noflip */
  [dir="rtl"] & {
    /*! @noflip */ border-radius: ${({ theme }) => theme.card.radius} 0 0 0;
  }

  ${isMobile(css`
    padding-inline-end: ${({ theme }) => theme.navigation.tabs.mobile.width};
  `)}
`;

interface TabWrapperProps extends MergeElementProps<'div'> {
  $isActive?: boolean;
}

export const TabWrapper = styled('div') <TabWrapperProps>`
  padding: 13px 0;
  ${({ $isActive }) => $isActive && css`
    ::after {
      ${tabUnderline}
    }
  `}

  ${({ $isActive }) => !$isActive && css`
    :hover::after {
      ${tabUnderline}
    }
  `}
`;
