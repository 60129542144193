import { IconDefinition } from '../types';
import { EMPTY_ICON_HEIGHT, EMPTY_ICON_WIDTH } from './emptyIcon';

type IconData = {
  width: number;
  height: number;
  path: string | string[];
}

// Icon definiton removed of all properties
const sanitizedIcon: IconData = {
  width: EMPTY_ICON_WIDTH,
  height: EMPTY_ICON_HEIGHT,
  path: '',
};

/**
 * If the given icon does not contain a standard height, width, or path,
 * it will be sanitized and an emmpty icon will be returned.
 */
const getSanitizedIcon = (icon: IconDefinition['icon']): IconData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [iconWidth, iconHeight, iconLigatures, iconUnicode, iconPathData] = icon;
  const sanitizedWidth = parseInt(String(iconWidth), 10);
  if (sanitizedWidth < 64 || sanitizedWidth > 640) {
    return sanitizedIcon;
  }
  const sanitizedHeight = parseInt(String(iconHeight), 10);
  if (sanitizedHeight !== 512) {
    return sanitizedIcon;
  }
  // Regexp of all characters allowed in an SVG path
  const pathRegex = new RegExp(/^[MLHVCSQTAZ0-9,.\t -]{0,5000}$/i);
  if (Array.isArray(iconPathData)) {
    for (let i = 0; i < iconPathData.length; i++) {
      if (typeof iconPathData[i] !== 'string' || !pathRegex.test(iconPathData[i])) {
        return sanitizedIcon;
      }
    }
  } else if (typeof iconPathData !== 'string' || !pathRegex.test(iconPathData)) {
    return sanitizedIcon;
  }
  return {
    width: sanitizedWidth,
    height: sanitizedHeight,
    path: iconPathData,
  };
};

export default getSanitizedIcon;
