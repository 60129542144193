import {
  useEffect,
  useState,
  KeyboardEvent,
  RefObject,
} from 'react';
import { handleKeyDownEvents } from './utils';

export interface UsePopperSelectListHelperProps {
  isOpen: boolean;
  anchorEl: RefObject<HTMLElement | undefined> | undefined;
  popperRef: RefObject<HTMLDivElement>;
  onClose?: () => void;
  focusSelected?: boolean;
  focusAnchorElDisabled?: boolean;
}

function usePopperSelectListHelper({
  isOpen,
  anchorEl,
  popperRef,
  onClose,
  focusSelected,
  focusAnchorElDisabled,
}: UsePopperSelectListHelperProps) {
  const [focusedValue, setFocusedValue] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setFocusedValue(null);
    }
  }, [isOpen])

  const handleFocus = (element?: HTMLElement | null, value?: string | null): void => {
    if (element) {
      element.focus();
    }
    setFocusedValue(value ?? null)
  }

  const handleEscape = (): void => {
    onClose?.();
    if (!focusAnchorElDisabled) {
      handleFocus(anchorEl?.current);
    }
  }

  const handleKeyboardSelect = (event: KeyboardEvent<HTMLButtonElement>): void => {
    if (event.target instanceof HTMLButtonElement) {
      event.target?.click();
    }
    if (!focusAnchorElDisabled) {
      handleFocus(anchorEl?.current);
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement | HTMLButtonElement>): void => {
    if (event.target instanceof HTMLButtonElement) {
      const keyedOverElement = handleKeyDownEvents(
        event as KeyboardEvent<HTMLButtonElement>,
        popperRef.current ?? null,
        handleEscape,
        handleKeyboardSelect,
      );
      if (keyedOverElement && keyedOverElement instanceof HTMLButtonElement) {
        setFocusedValue(keyedOverElement.value);
      }
    }
  }

  const onPopoverElementDidMount = (popoverEl?: HTMLElement | null): void => {
    if (focusSelected) {
      // focus on the selected item or the first option on the list
      const selectedEl: HTMLButtonElement | null | undefined = popoverEl?.querySelector?.('[role="option"][aria-selected="true"]');
      if (selectedEl) {
        handleFocus(selectedEl, selectedEl.getAttribute('value'));
        return;
      }
      const firstEl: HTMLButtonElement | null | undefined = popoverEl?.querySelector?.('[role="option"]:first-child');
      if (firstEl) {
        handleFocus(firstEl, firstEl.getAttribute('value'));
      }
    }
  }

  return {
    focusedValue,
    handleKeyDown,
    onPopoverElementDidMount,
  }
}

export default usePopperSelectListHelper;
