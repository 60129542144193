import React, { PureComponent, MouseEventHandler, ReactNode } from 'react';
import PT from 'prop-types';
import { SelectOption } from '../../../SelectList';
import { SelectOptionProps } from '../../../SelectList/SelectOption/SelectOption';
import { TaktProps } from '../../../types/TaktProps';

export interface SplitButtonItemProps extends TaktProps, SelectOptionProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  value: ReactNode;
}

export default class SplitButtonItem extends PureComponent<SplitButtonItemProps> {
  static propTypes = {
    onClick: PT.func.isRequired,
    value: PT.oneOfType([PT.string, PT.object]).isRequired,
  }

  render(): JSX.Element {
    const { onClick, ...rest } = this.props;

    return (
      <SelectOption
        onClick={onClick}
        {...rest}
      />
    );
  }
}
