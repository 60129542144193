import styled, { css } from 'styled-components';
import Text from '../Text/Text';
import { LABEL_PLACEMENT, STATUS } from './constants';
import { Theme } from '../theme';
import { StepStatus } from './types';

const tailStyles = css`
  content: '';
  height: 2px;
  display: block;
  top: 50%;
  top: calc(50% - 1px);
  opacity: 1;
  border-radius: 1px;
  /* @noflip */ left: 100%;
  inset-inline-start: 100%;
`;

interface StepTailProps {
  $isVertical?: boolean;
  $labelPlacement?: 'horizontal' | 'vertical';
}
export const StepTail = styled('div')<StepTailProps>`
  ${({ $labelPlacement, theme }) => ($labelPlacement === LABEL_PLACEMENT.VERTICAL
    ? css`
      position: absolute;
      padding: ${theme.steps.verticalLabelTailPadding};
      top: ${theme.steps.verticalLabelTailTop};

      /* @noflip */ left: 50%;
      inset-inline-start: 50%;
      /* @noflip */ right: -50%;
      inset-inline-end: -50%;

      [dir="rtl"] && {
        /* @noflip */ right: 50%;
        inset-inline-start: 50%;
        /* @noflip */ left: -50%;
        inset-inline-end: -50%;
      }

      ::after {
        ${tailStyles}
        background: ${theme.steps.tailColor};
      }
    ` : ''
  )}
  ${({ $isVertical, theme }) => $isVertical && css`
    background: ${theme.steps.tailColor};
    width: 2px;
    position: relative;

    /* @noflip */ left: 9px;
    inset-inline-start: 9px;
    /* @noflip */ right: auto;
    inset-inline-end: auto;

    [dir="rtl"] && {
      /* @noflip */ right: 9px;
      inset-inline-start: 9px;
      /* @noflip */ left: auto;
      inset-inline-end: auto;
    }
  `}
`;

interface CircleProps {
  $isVertical?: boolean;
  $labelPlacement?: 'horizontal' | 'vertical';
}
export const Circle = styled('div')<CircleProps>`
  width: ${({ theme }) => theme.steps.circleSize};
  height: ${({ theme }) => theme.steps.circleSize};
  text-align: center;
  display: inline-block;
  position: relative;
  flex: 1;
  ${({ $labelPlacement, theme }) => ($labelPlacement === LABEL_PLACEMENT.VERTICAL
    ? `
    margin-inline-end: 0;
    ` : `
      margin-inline-end: ${theme.steps.circleSpacing};
    `
  )}
  ${({ $isVertical, theme }) => $isVertical && css`
    flex: none;
    z-index: 1;
    background: white;
    margin-inline-start: 0;
    padding-bottom: ${theme.spacingValues.xlarge}px;
  `}
`;

const getCircleBackgroundColor = (status: StepStatus | null, theme: Theme) => {
  switch (status) {
    case STATUS.COMPLETED:
    case STATUS.ACTIVE:
      return theme.steps.circleBackgroundColorCompleted;
    case STATUS.FUTURE:
    case STATUS.DISABLED:
    default:
      return theme.steps.circleBackgroundColorFuture;
  }
};

const getCircleBackgroundOpacity = (status: StepStatus | null, theme: Theme) => {
  switch (status) {
    case STATUS.FUTURE:
      return theme.steps.circleBackgroundOpacityFuture;
    case STATUS.DISABLED:
      return theme.steps.circleBackgroundOpacityDisabled;
    default:
      return theme.steps.circleBackgroundOpacityDefault;
  }
};

interface CircleBackgroundProps {
  $status: StepStatus | null;
}
export const CircleBackground = styled('div')<CircleBackgroundProps>`
  width: ${({ theme }) => theme.steps.circleSize};
  height: ${({ theme }) => theme.steps.circleSize};
  border-radius: ${({ theme }) => theme.steps.circleSize};
  position: absolute;
  top: 0;
  background-color: ${({ $status, theme }) => getCircleBackgroundColor($status, theme)};
  opacity: ${({ $status, theme }) => getCircleBackgroundOpacity($status, theme)};
`;

const getCircleContentColor = (status: StepStatus | null, theme: Theme) => {
  switch (status) {
    case STATUS.COMPLETED:
    case STATUS.ACTIVE:
      return theme.steps.circleContentColorCompleted;
    case STATUS.FUTURE:
    case STATUS.DISABLED:
    default:
      return theme.steps.circleContentColorFuture;
  }
};

interface CircleContentProps {
  $status: StepStatus | null;
  $contentType?: string;
}
export const CircleContent = styled(Text).attrs({ type: 'span' })<CircleContentProps>`
  font-size: ${({ theme }) => theme.steps.circleContentSize};
  position: absolute;
  opacity: 1;
  z-index: 1;
  color: ${({ $status, theme }) => getCircleContentColor($status, theme)};
  display: ${props => (props.$status === 'disabled' ? 'none' : 'inherit')};
  top: ${({ $contentType, theme }) => (
    $contentType === 'icon'
      ? theme.steps.circleIconTop
      : theme.steps.circleContentTop)};
  left: ${({ $contentType, theme }) => (
    $contentType === 'icon'
      ? theme.steps.circleIconLeft
      : theme.steps.circleContentLeft)};
  vertical-align: top;
`;

interface ContentWrapperProps {
  $isVertical?: boolean;
  $labelPlacement: 'horizontal' | 'vertical';
}
export const ContentWrapper = styled('div')<ContentWrapperProps>`
  vertical-align: top;
  position: relative;
  flex: 1;
  ${({ $labelPlacement, theme, $isVertical }) => ($labelPlacement === LABEL_PLACEMENT.VERTICAL
    ? `
      display: block;
      text-align: center;
      top: ${theme.steps.verticalLabelContentTop}
    `
    : !$isVertical && css`
      display: inline-block;
      margin-top: -1px;
      ::after {
        width: 1000px;
        background: ${theme.steps.tailColor};
        position: absolute;
        margin-inline-start: ${theme.steps.stepSpacing};
        ${tailStyles}
      }
    `
  )}
`;

interface TitleWrapperProps {
  $status: StepStatus | null;
}
export const TitleWrapper = styled('div')<TitleWrapperProps>`
  color: ${({ theme }) => theme.steps.titleColor};
  color: ${({ $status, theme }) => ($status === STATUS.DISABLED
    ? theme.steps.titleColorDisabled
    : theme.steps.titleColor)};
  font-weight: ${({ $status, theme }) => ($status === STATUS.ACTIVE
    ? theme.steps.titleFontWeightActive
    : theme.steps.titleFontWeightDefault)};
`;

interface StepWrapperProps {
  $isVertical?: boolean;
  $labelPlacement?: 'horizontal' | 'vertical';
}
export const StepWrapper = styled('li')<StepWrapperProps>`
  list-style-type: none;
  flex: 1;
  position: relative;
  :last-child ${Circle}::after{
    display: none;
  }
  :last-child ${StepTail}::after{
    display: none;
  }
  ${({ $isVertical, theme }) => $isVertical && css`
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    :last-child ${StepTail} {
      background: none;
    }
    padding-top: ${theme.steps.stepSpacing};
  `}
  ${({ $labelPlacement, theme }) => ($labelPlacement === LABEL_PLACEMENT.VERTICAL
    ? `
      overflow: visible;
      text-align: center;
    `
    : `
      overflow: hidden;
      margin-right: ${theme.steps.stepSpacing};
      :last-child {
        flex: none;
      }
    `
  )}
`;
