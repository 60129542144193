import React, { createRef, PureComponent } from 'react';
import PT from 'prop-types';
import { noop } from '@amzn/storm-ui-utils-v3';
import timePropType from '../timePropType';
import TimeInputFocusController from './TimeInputFocusController';
import TimeInputState from './TimeInputState';
import {
  TimeInputInputGroup,
  TimeInputSubInput,
} from './TimeInput.styles';

const hourOptions = Array
  .from({ length: 12 })
  .map((hour, idx) => `0${String(idx + 1)}`.slice(-2));

const minuteOptions = Array
  .from({ length: 60 })
  .map((minute, idx) => `0${String(idx)}`.slice(-2));

const meridiemOptions = ['AM', 'PM'];

export interface TimeInputProps {
  value?: string,
  onChange?: () => void,
  className?: string,
  hourInputLabel?: string,
  minuteInputLabel?: string,
  meridiemInputLabel?: string,
  id: string,
}

class TimeInput extends PureComponent<TimeInputProps> {
  static propTypes = {
    value: timePropType,
    onChange: PT.func,
    className: PT.string,
    hourInputLabel: PT.string,
    minuteInputLabel: PT.string,
    meridiemInputLabel: PT.string,
    id: PT.string.isRequired,
  }

  static defaultProps = {
    onChange: noop,
    value: '',
    className: '',
    hourInputLabel: '',
    minuteInputLabel: '',
    meridiemInputLabel: '',
  }

  private targetInputRef = createRef<HTMLInputElement>();

  onChangeTimeInputState = (value?: string | null): void => {
    if (typeof window !== 'undefined' && this.targetInputRef.current) {
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )?.set;
      nativeInputValueSetter?.call(this.targetInputRef.current, value);
      this.targetInputRef.current?.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  render(): JSX.Element {
    const {
      value,
      onChange,
      className,
      hourInputLabel,
      minuteInputLabel,
      meridiemInputLabel,
      id,
    } = this.props;
    return (
      <TimeInputFocusController>
        {({
          focus,
          focusNext, focusPrevious,
          onFocusHour, onFocusMinute, onFocusMeridiem,
          onBlur,
        }) => (
          <TimeInputInputGroup $isFocus={focus} className={className} id={id}>
            <TimeInputState
              value={value}
              onChange={this.onChangeTimeInputState}
            >
              {({
                hour, onChangeHour,
                minute, onChangeMinute,
                meridiem, onChangeMeridiem,
              }) => (
                <>
                  <TimeInputSubInput
                    value={hour}
                    options={hourOptions}
                    size={2}
                    isFocus={focus === 'hour'}
                    onChange={onChangeHour}
                    onFocus={onFocusHour}
                    onBlur={onBlur}
                    focusNext={focusNext}
                    focusPrevious={focusPrevious}
                    label={hourInputLabel}
                    id={`${id}-hour`}
                  />
                  :
                  <TimeInputSubInput
                    value={minute}
                    options={minuteOptions}
                    size={2}
                    isFocus={focus === 'minute'}
                    onChange={onChangeMinute}
                    onFocus={onFocusMinute}
                    onBlur={onBlur}
                    focusNext={focusNext}
                    focusPrevious={focusPrevious}
                    label={minuteInputLabel}
                    id={`${id}-minute`}
                  />
                  {'\u00A0' /* &nbsp <-- prevent jsx parsing problem with html escaping */}
                  <TimeInputSubInput
                    value={meridiem}
                    options={meridiemOptions}
                    size={2}
                    isFocus={focus === 'meridiem'}
                    onChange={onChangeMeridiem}
                    onFocus={onFocusMeridiem}
                    onBlur={onBlur}
                    focusNext={focusNext}
                    focusPrevious={focusPrevious}
                    label={meridiemInputLabel}
                    id={`${id}-meridiem`}
                  />
                  <input
                    ref={this.targetInputRef}
                    style={{ display: 'none' }}
                    onChange={onChange}
                  />
                </>
              )}
            </TimeInputState>
          </TimeInputInputGroup>
        )}
      </TimeInputFocusController>
    );
  }
}

export default TimeInput;
