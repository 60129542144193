import { palette } from '../base/color';
import { link } from '../base/typography';
import { SurfaceThemeOverrides } from './surface';

/* eslint-disable id-length */
const surfaceLightStyles: SurfaceThemeOverrides = {
  link: {
    color: palette.blue[700],
    hoverColor: palette.blue[800],
    decoration: 'none',
    overrideAuiLinkStyle: true,
  },
  surface: {
    backgroundColor: palette.white,
  },
  typography: {
    color: {
      base: palette.squidInk,
    },
    body: {
      bodyFontColor: palette.squidInk,
    },
    a: `
      color: ${link.color};
      text-decoration: ${link.decoration};

      :hover{
        color: ${link.hoverColor};
        text-decoration: ${link.hoverDecoration};
      }
    `,
  },
  focusVisible: {
    borderColor: palette.auiblue,
    outlineColor: palette.blue[700],
    boxShadowColor: 'rgba(0,102,192,0.50)',
  },
  icon: {
    color: palette.gray[700],
    hover: {
      color: palette.squidInk,
    },
  },
  form: {
    input: {
      bg: palette.gray[0],
    },
    label: {
      color: palette.squidInk,
    },
  },
};

export default surfaceLightStyles;
