/* eslint-disable id-length, import/prefer-default-export */
import { border } from '../base/border';
import { palette } from '../base/color';
import { spacing, spacingValues } from '../base/spacing';
import { typography } from '../base/typography';

export const card = {
  border: 'none',
  bg: palette.white,
  boxShadow: '0 0 2px 0 rgba(35,47,63,0.30), 0 1px 4px 0 rgba(35,47,63,0.15);',
  radius: border.roundedArea.radius,
  padding: spacing.xlarge,

  selected: {
    borderColor: palette.blue[100],
    bg: palette.blue[50],
  },

  header: {
    borderBottom: `1px solid ${palette.gray[100]}`,
    borderRadius: border.roundedArea.radius,
    bg: palette.white,
    margin: `-${spacing.xlarge}`, // Keep this value the same as card.padding
    padding: `${spacing.medium} ${spacing.xlarge}`,
    width: 'auto',
  },

  tabs: {
    paddingValue: spacingValues.medium,
    fontColor: typography.color.secondary,
    activeFontColor: typography.color.primary,
    bg: palette.gray[10],
    hover: palette.gray[10],
    hoverFontColor: typography.color.primary,
    activeBorder: palette.blue[700],
    border: palette.gray[100],
    scrollableIndicatorBackground: 'linear-gradient(90deg,rgba(251, 251, 251,0) 0%,rgba(251, 251, 251,1) 75%)',
    scrollableIndicatorBackgroundRTL: 'linear-gradient(270deg,rgba(251, 251, 251,0) 0%,rgba(251, 251, 251,1) 75%)',
    margin: `0 ${spacingValues.large / 2}px`,
  },
};
