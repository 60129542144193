import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';
import { SizeProp } from '../types';
import isHorizontalFlipOnRTL from '../utils/isHorizontalFlipOnRTL';
import useGetIcon from '../utils/useGetIcon';
import getIconSize from '../utils/getIconSize';
import renderIconSVG from '../utils/renderIconSVG';
import { IconClassWrapper } from '../Icon.styles';
import ErrorBoundary from './ErrorBoundary';

const farIcons = new Set(['lightbulb', 'calendar-alt', 'clock', 'text-separator', 'trash-alt', 'house']);

interface IconWrapperProps extends MergeStyledComponentElementProps<'span'> {
  $blockSize?: boolean;
  $horizontalFlipOnRTL?: boolean;
}

const IconWrapperStyled = styled('i')<IconWrapperProps>`
  ${({ $blockSize }) => ($blockSize ? css`
  display: inline-block;
  > .svg-inline--fa {
    display: block;
  }` : '')}
  ${// Only use the icon RTL flipper when using a logical direction icon type;
  ({ $horizontalFlipOnRTL }) => ($horizontalFlipOnRTL
    ? css`
    /*! @noflip */
    [dir="rtl"] && > svg {
      /*! @noflip */ transform: scaleX(-1);
    }` : '')}
`;

export interface RemoteIconProps extends Omit<MergeStyledComponentElementProps<'span'>, 'size'|'children'> {
  type: string;
  size?: SizeProp | string;
  transform?: string;
  blockSize?: boolean;
  'data-testid'?: string;
}

const RemoteIcon: FC<React.PropsWithChildren<RemoteIconProps>> = ({
  type,
  size,
  transform,
  blockSize = false,
  'data-testid': dataTestId,
  ...rest
}) => {
  const prefix = farIcons.has(type) ? 'far' : 'fas';
  const [iconName, horizontalFlipOnRTL] = isHorizontalFlipOnRTL(type);
  const icon = useGetIcon(prefix, iconName);
  const iconFontSize = getIconSize(size);
  return (
    <ErrorBoundary>
      <IconClassWrapper
        $pulse={iconName === 'spinner'}
        $size={size}
        $fontSize={iconFontSize}
      >
        {(className?: string) => (
          <IconWrapperStyled
            {...rest}
            $fontSize={iconFontSize}
            $blockSize={blockSize}
            $horizontalFlipOnRTL={horizontalFlipOnRTL}
            dangerouslySetInnerHTML={{ __html: renderIconSVG(icon, transform, className) }}
            data-testid={dataTestId || `storm-ui-icon-${iconName}`}
          />
        )}
      </IconClassWrapper>
    </ErrorBoundary>
  );
};

RemoteIcon.propTypes = {
  /**
   * The type of Icon.
   */
  type: PT.string.isRequired,
  /**
   * Font Awesome [sizing classes ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/sizing-icons).
   */
  size: PT.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
  /**
   * Font Awesome [transform element attribute ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/power-transforms).
   */
  transform: PT.string,
  /**
   * Changes the `<svg/>` to be `display: block;`.
   * Use when centering icons in small spaces.
   */
  blockSize: PT.bool,
};

RemoteIcon.defaultProps = {
  size: undefined,
  transform: undefined,
  blockSize: false,
  'data-testid': undefined,
};

export default RemoteIcon;
