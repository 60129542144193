import React, { FC } from 'react';
import { TaktProps, useIsMobile } from '@amzn/storm-ui-v3';
import PT, { Validator } from 'prop-types';
import { AdvertisingLocale } from '@amzn/storm-ui-utils-v3';
import {
  ClickEventHandler,
  SelectRangeEventHandler,
  OrientationType,
  ISODate,
} from '../../types';
import Root from './Root';
import ContextProvider from '../../contexts/ContextProvider';

export interface InlineDateRangePickerProps extends TaktProps {
  /**
   * The object used to localize the DatePicker.
   * @defaultValue `undefined`
   */
  locale?: AdvertisingLocale | Locale | (() => Promise<{ default: Locale }>);
  /**
   * Today's date.
   * @defaultValue `undefined`
   */
  today?: Date | string;
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * Defaults to horizontal for desktop and vertical for mobile.
   * @defaultValue `undefined`
   */
  orientation?: OrientationType;
  /**
   * The number of months to display.
   * Defaults to 2 for desktop and 1 for mobile.
   * @defaultValue `undefined`
   */
  numberOfMonths?: number;
  /**
   * The first or only month displayed.
   * @defaultValue `undefined`
   */
  monthInView?: Date;
  /**
   * The earliest possible date that can be selected.
   * @defaultValue `undefined`
   */
  minDate?: Date | string;
  /**
   * The latest possible date that can be selected.
   * @defaultValue `undefined`
   */
  maxDate?: Date | string;
  /**
   * The marketplace time zone.
   * @defaultValue `undefined`
   */
  zone?: string;
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   * @defaultValue `undefined`
   */
  isOutsideRange?: ClickEventHandler;
  /**
   * The unique identifier.
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * The selected start date.
   * @defaultValue `undefined`
   */
  startDate?: Date | string;
  /**
  * The selected end date.
  * @defaultValue `undefined`
  */
  endDate?: Date | string;
  /**
   * The function called when the selected date is changed.
   * @defaultValue `undefined`
   */
  onChange?: SelectRangeEventHandler;
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   * @defaultValue `"go to previous month"`
   */
   previousMonthNavButtonLabel?: string;
   /**
    * The localizable aria-label for the calendar's next month nav button.
    * @defaultValue `"go to next month"`
    */
   nextMonthNavButtonLabel?: string;
   /**
    * The message displayed as a Tooltip over a day before the minDate
    * @defaultValue `undefined`
    */
   beforeMinDateMessage?: string;
   /**
    * The message displayed as a Tooltip over a day after the maxDate
    * @defaultValue `undefined`
    */
   afterMaxDateMessage?: string;
   /**
   * The funcion used to confitionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   * @defaultValue `undefined`
   */
  isDayDisabled?: (day: Date) => boolean | string;
  /**
   * The function called when the visible months are changed.
   * @defaultValue `undefined`
   */
  onVisibleMonthsChange?: (visibleMonths: ISODate[]) => void;
}

const InlineDateRangePicker: FC<React.PropsWithChildren<InlineDateRangePickerProps>> = props => {
  const { numberOfMonths, orientation } = props;
  const isMobile = useIsMobile();
  // Should default to 1 visibile month on mobile, unless specified otherwise
  let dateRangeMonths = numberOfMonths;
  if (!dateRangeMonths && isMobile) {
    dateRangeMonths = 1;
  }
  // Should default to vertical orientation on mobile, unless specified otherwise
  let dateRangeOrientation = orientation;
  if (!orientation && numberOfMonths && numberOfMonths > 1) {
    dateRangeOrientation = 'vertical';
  }
  return (
    <ContextProvider
      {...props}
      type="range"
      numberOfMonths={dateRangeMonths ?? 2}
      orientation={dateRangeOrientation ?? 'horizontal'}
    >
      <Root />
    </ContextProvider>
  );
};

InlineDateRangePicker.propTypes = {
  /**
   * The object used to localize the DatePicker.
   */
  locale: PT.oneOfType(
    [PT.objectOf(PT.any), PT.func],
  ) as Validator<Locale | (() => Promise<{ default: Locale }>)>, // eslint-disable-line react/forbid-prop-types
  /**
   * Today's date.
   */
  today: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * Defaults to horizontal for desktop and vertical for mobile.
   */
  orientation: PT.oneOf(['horizontal', 'vertical']),
  /**
   * The number of months to display.
   * Defaults to 2 for desktop and 1 for mobile.
   */
  numberOfMonths: PT.number,
  /**
   * The first or only month displayed.
   */
  monthInView: PT.instanceOf(Date),
  /**
   * The earliest possible date that can be selected.
   */
  minDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The latest possible date that can be selected.
   */
  maxDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The marketplace time zone.
   */
  zone: PT.string,
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   */
  isOutsideRange: PT.func,
  /**
   * The unique identifier.
   */
  id: PT.string,
  /**
   * The selected start date.
   */
  startDate: PT.instanceOf(Date),
  /**
  * The selected end date.
  */
  endDate: PT.instanceOf(Date),
  /**
   * The function called when the selected date is changed.
   */
  onChange: PT.func,
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   */
  previousMonthNavButtonLabel: PT.string,
  /**
     * The localizable aria-label for the calendar's next month nav button.
     */
  nextMonthNavButtonLabel: PT.string,
  /**
   * The message displayed as a Tooltip over a day before the minDate
   */
  beforeMinDateMessage: PT.string,
  /**
   * The message displayed as a Tooltip over a day after the maxDate
   */
  afterMaxDateMessage: PT.string,
  /**
   * The function used to conditionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   */
  isDayDisabled: PT.func,
};

InlineDateRangePicker.defaultProps = {
  locale: undefined,
  today: undefined,
  orientation: undefined,
  numberOfMonths: undefined,
  monthInView: undefined,
  minDate: undefined,
  maxDate: undefined,
  zone: undefined,
  isOutsideRange: undefined,
  id: undefined,
  startDate: undefined,
  endDate: undefined,
  onChange: undefined,
  previousMonthNavButtonLabel: 'go to previous month',
  nextMonthNavButtonLabel: 'go to next month',
  beforeMinDateMessage: undefined,
  afterMaxDateMessage: undefined,
  isDayDisabled: undefined,
  onVisibleMonthsChange: undefined,
};

InlineDateRangePicker.displayName = 'InlineDateRangePicker';

export default InlineDateRangePicker;
