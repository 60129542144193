import React, {
  FC, ReactNode, useCallback, useEffect, useState, MouseEvent,
} from 'react';
import styled from 'styled-components';
import { DropdownItem } from '@amzn/storm-ui-v3';
import DateSingleContext from './DateSingleContext';
import { AllPickerProps, DateSingleProps, ISODate } from '../../types';
import isDateSingle from '../../utils/isDateSingle';
import useDatePicker from '../../hooks/useDatePicker';
import { startOfDayAtTz } from '../../utils/timezoneUtils';
import useNavigation from '../../hooks/useNavigation';
import { dateToISODate } from '../../utils/dateUtils';
import isValidDate from '../../utils/isValidDate';

const NoDateButtonContainer = styled('div')`
  display: block;
  text-align: center;
  position: relative;
  padding: 5px 0;
  border-bottom: 1px solid #DDDDDD;
`;

const StyledDropdownItem = styled(DropdownItem)`
  width: 100%;
`;

export interface DateSingleProviderProps {
  baseProps: AllPickerProps;
  children: ReactNode;
}

const DateSingleProvider: FC<React.PropsWithChildren<DateSingleProviderProps>> = ({
  children,
  baseProps,
}) => {
  const { zone, date } = useDatePicker();

  let selected: ISODate | undefined;
  if (isDateSingle(baseProps.type)) {
    if (date) selected = dateToISODate(date, zone);
  }

  const [selectedDate, setSelectedDate] = useState<ISODate | undefined>(selected);

  const handleDateSelect = useCallback((
    handledDate: ISODate,
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    const tzDate = startOfDayAtTz(handledDate, zone);
    if (isValidDate(tzDate)) {
      setSelectedDate(handledDate);
      if (isDateSingle(baseProps.type)) {
        (baseProps as DateSingleProps).onChange?.(tzDate, event);
      }
    }
  }, [baseProps, zone]);

  const { goToMonth } = useNavigation();

  useEffect(() => {
    if (selectedDate) {
      goToMonth(selectedDate);
    }
  }, [goToMonth, selectedDate]);

  const NoDateOption = () => {
    const {
      allowNoDate,
      noDateText = 'No end date',
      onNoDateSelect,
    } = baseProps;
    if (allowNoDate) {
      const handleNoDate = (event: MouseEvent<HTMLButtonElement>) => {
        setSelectedDate(undefined);
        onNoDateSelect?.(event);
      }
      return (
        <NoDateButtonContainer>
          <StyledDropdownItem
            onClick={handleNoDate}
            selected={!selectedDate}
            value="no-end-date"
          >
            {noDateText}
          </StyledDropdownItem>
        </NoDateButtonContainer>
      );
    }
    return null;
  };

  return (
    <DateSingleContext.Provider
      value={{
        selected: selectedDate,
        handleDateSelect,
        NoDateOption,
      }}
    >
      {children}
    </DateSingleContext.Provider>
  );
};

export default DateSingleProvider;
