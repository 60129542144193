import React, {
  Component,
  createRef,
  KeyboardEvent,
  ComponentPropsWithoutRef,
} from 'react';
import PT from 'prop-types';
import { Text } from '@amzn/storm-ui-v3';
import {
  noop,
} from '@amzn/storm-ui-utils-v3';
import {
  incrementOption,
  decrementOption,
  clearOption,
  createJumpToOption,
} from './spinnerNavigation';

export interface SpinnerInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'value' | 'onChange'>{
  className?: string;
  value?: string | null;
  nullDisplayOption?: string;
  options: string[];
  onChange: (arg0?: string | null) => void;
  size: number;
  isFocus: boolean;
  focusNext?: (event?: KeyboardEvent) => void;
  focusPrevious?: (event?: KeyboardEvent) => void;
  label?: string;
  id: string;
}

class SpinnerInput extends Component<SpinnerInputProps> {
  static propTypes = {
    className: PT.string,
    value: PT.string,
    nullDisplayOption: PT.string,
    options: PT.arrayOf(PT.string).isRequired,
    onChange: PT.func.isRequired,
    size: PT.number.isRequired,
    isFocus: PT.bool.isRequired,
    focusNext: PT.func,
    focusPrevious: PT.func,
    label: PT.string,
    id: PT.string.isRequired,
  }

  static defaultProps = {
    className: '',
    value: '',
    nullDisplayOption: '--',
    focusNext: noop,
    focusPrevious: noop,
    label: '',
  }

  private inputRef = createRef<HTMLInputElement>();

  private jumpToOption;

  private selectionTimeout?: NodeJS.Timeout;

  constructor(props: SpinnerInputProps) {
    super(props);
    this.jumpToOption = createJumpToOption();
  }

  componentDidUpdate(prevProps: SpinnerInputProps): void {
    const { isFocus, value } = this.props;

    if (prevProps.isFocus === false && isFocus === true) {
      this.jumpToOption = createJumpToOption();
    }

    if (
      (prevProps.isFocus === false && isFocus === true)
      || (isFocus === true && prevProps.value !== value)
    ) {
      this.setInputSelected();
    }
  }

  componentWillUnmount(): void {
    if (this.selectionTimeout) {
      clearTimeout(this.selectionTimeout);
    }
  }

  onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { key } = event;
    const {
      options,
      value,
      onChange,
      focusNext,
      focusPrevious,
      size,
    } = this.props;

    // We do not override Tab input, keep native functionality.
    if (key === 'Tab') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (key === 'ArrowUp') {
      onChange(incrementOption(options, value));
      return;
    }

    if (key === 'ArrowDown') {
      onChange(decrementOption(options, value));
      return;
    }

    if (key === 'Backspace') {
      onChange(clearOption());
      this.jumpToOption = createJumpToOption();
      return;
    }

    if (key === 'ArrowRight') {
      focusNext?.(event);
      return;
    }

    if (key === 'ArrowLeft') {
      focusPrevious?.(event);
      return;
    }

    const { matchType, foundOption } = this.jumpToOption(options, key, size);

    if (matchType === 'preliminary' || matchType === 'full') {
      onChange(foundOption);
      if (matchType === 'full') {
        focusNext?.(event);
      }
    }
  }

  setInputSelected = (): void => {
    const {
      size,
    } = this.props;
    if (typeof window !== 'undefined' && this.inputRef) {
      if (document.activeElement !== this.inputRef.current) {
        this.inputRef.current?.focus();
      }
      this.inputRef.current?.setSelectionRange(0, size);
    }
  }

  onClick = (): void => {
    // workaround for event timing in Safari
    this.selectionTimeout = setTimeout(() => {
      const { isFocus } = this.props;
      if (isFocus) {
        this.setInputSelected();
      }
    }, 0);
  }

  render(): JSX.Element {
    const {
      className,
      size,
      isFocus,
      value,
      nullDisplayOption,
      options,
      onChange,
      focusNext,
      focusPrevious,
      label,
      id,
      ...rest
    } = this.props;

    return (
      <>
        <label htmlFor={id}>
          <Text type="sr-only">{label}</Text>
        </label>
        <input
          id={id}
          {...rest}
          size={size}
          ref={this.inputRef}
          className={className}
          type="text"
          value={value !== null ? value : nullDisplayOption}
          onKeyDown={this.onKeyDown}
          onClick={this.onClick}
          readOnly
        />
      </>
    );
  }
}
export default SpinnerInput;
