import React, { PropsWithChildren, ReactNode } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { IntlProvider, IntlProviderProps, useIntl } from '@amzn/storm-ui-intl-v3';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import { PaddingOptions } from '../types/PaddingOptions';
import isMobileMixin from '../theme/style-mixins/isMobile/isMobile';
import { getTranslationComponent } from '../i18n/AbandonmentDialogTranslation';
import { Translations } from '../i18n/AbandonmentDialogTranslation/types';
import Modal from '../Modal/Modal';
import SecondaryView from '../SecondaryView/SecondaryView';
import useIsMobile from '../theme/MobileThemeProvider/useIsMobile';
import Text from '../Text/Text';
import { TaktProps } from '../types/TaktProps';
import DefaultAbandonmentFooter from './DefaultAbandonmentFooter';
import CancelButton from './DefaultAbandonmentFooter/CancelButton';
import LeaveButton from './DefaultAbandonmentFooter/LeaveButton';

const MobileHeaderContainer = styled.div`
  padding-inline-start: ${({ theme }) => theme.spacing.base};
  padding-inline-end: ${({ theme }) => theme.spacing.base};
`
const MobileFooterContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.base};
  display: flex;
  justify-content: flex-end;
`

interface ChildrenWrapperProps extends MergeElementProps<'div'> {
  $paddingBlockStart: undefined | PaddingOptions;
  $paddingBlockEnd: undefined | PaddingOptions;
  $paddingInlineStart: undefined | PaddingOptions;
  $paddingInlineEnd: undefined | PaddingOptions;
}
const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  // copied from Modal
  padding-block-start: ${({ $paddingBlockStart, theme }) => ($paddingBlockStart ? theme.spacing[$paddingBlockStart] : theme.spacing.large)};
  padding-block-end: ${({ $paddingBlockEnd, theme }) => ($paddingBlockEnd ? theme.spacing[$paddingBlockEnd] : theme.spacing.large)};
  padding-inline-start: ${({ $paddingInlineStart, theme }) => ($paddingInlineStart ? theme.spacing[$paddingInlineStart] : theme.spacing.base)};
  padding-inline-end: ${({ $paddingInlineEnd, theme }) => ($paddingInlineEnd ? theme.spacing[$paddingInlineEnd] : theme.spacing.base)};

  ${isMobileMixin(css<ChildrenWrapperProps>`
    padding-block-start: ${({ $paddingBlockStart, theme }) => ($paddingBlockStart ? theme.mobile.spacing[$paddingBlockStart] : theme.mobile.spacing.base)};
    padding-block-end: ${({ $paddingBlockEnd, theme }) => ($paddingBlockEnd ? theme.mobile.spacing[$paddingBlockEnd] : theme.mobile.spacing.base)};
    padding-inline-start: ${({ $paddingInlineStart, theme }) => ($paddingInlineStart ? theme.mobile.spacing[$paddingInlineStart] : theme.mobile.spacing.base)};
    padding-inline-end: ${({ $paddingInlineEnd, theme }) => ($paddingInlineEnd ? theme.mobile.spacing[$paddingInlineEnd] : theme.mobile.spacing.base)};
  `)}
`;

export interface renderFooterParams {
  handleCancel: () => void;
  handleLeave: () => void;
}

export interface AbandonmentDialogContentProps extends TaktProps {
  /**
   * Indicates if the dialog should be displayed
   */
  isOpen: boolean;
  /**
   * Function to handle 'Continue Working' action
   */
  onCancel: () => void;
  /**
   * Function to handle 'Leave Page' action
   */
  onLeave: () => void;
  /**
   * Sets the block start padding around the content.
   * @defaultValue `undefined`
   */
  paddingBlockStart?: PaddingOptions;
    /**
   * Sets the block end padding around the content.
   * @defaultValue `undefined`
   */
  paddingBlockEnd?: PaddingOptions;
    /**
   * Sets the inline start padding around the content.
   * @defaultValue `undefined`
   */
  paddingInlineStart?: PaddingOptions;
    /**
   * Sets the inline end padding around the content.
   * @defaultValue `undefined`
   */
  paddingInlineEnd?: PaddingOptions;
  /**
   * Render prop for custom header content
   * @defaultValue `undefined`
   */
  renderHeader?: () => ReactNode;
  /**
   * Render prop for custom footer content, with callbacks for Cancel and Leave actions
   * @defaultValue `undefined`
   */
  renderFooter?: ({ handleCancel, handleLeave }:renderFooterParams) => ReactNode;
}

const AbandonmentDialogContent: React.FC<PropsWithChildren<AbandonmentDialogContentProps>> = ({
  isOpen,
  onCancel,
  onLeave,
  paddingBlockStart,
  paddingBlockEnd,
  paddingInlineStart,
  paddingInlineEnd,
  renderHeader,
  renderFooter,
  taktId,
  children,
  ...rest
}) => {
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();

  const getDefaultHeader = () => (isMobile
    ? (<MobileHeaderContainer><Text type="h1" styleAs="h4" withMargin>{formatMessage({ id: 'default_abandonment_header' })}</Text></MobileHeaderContainer>)
    : formatMessage({ id: 'default_abandonment_header' }))

  const header = renderHeader ? renderHeader() : getDefaultHeader();

  const footer = renderFooter
    ? renderFooter({ handleCancel: onCancel, handleLeave: onLeave })
    : (
      <DefaultAbandonmentFooter
        {...rest}
        onCancel={onCancel}
        onLeave={onLeave}
      />
    )

  return isMobile ? (
    <SecondaryView
      {...rest}
      padding="none"
      isOpen={isOpen}
      onClose={onCancel}
      closeButtonLabel={formatMessage({ id: 'cancel' })}
      taktId={taktId}
    >
      {header}
      <ChildrenWrapper
        $paddingBlockStart={paddingBlockStart}
        $paddingBlockEnd={paddingBlockEnd}
        $paddingInlineStart={paddingInlineStart}
        $paddingInlineEnd={paddingInlineEnd}
      >
        {children ?? <Text>{formatMessage({ id: 'default_abandonment_body' })}</Text>}
      </ChildrenWrapper>
      <MobileFooterContainer>
        {footer}
      </MobileFooterContainer>
    </SecondaryView>
  ) : (
    <Modal
      {...rest}
      padding="none"
      isOpen={isOpen}
      header={header}
      footer={footer}
      onClose={onCancel}
      taktId={taktId}
    >
      <ChildrenWrapper
        $paddingBlockStart={paddingBlockStart}
        $paddingBlockEnd={paddingBlockEnd}
        $paddingInlineStart={paddingInlineStart}
        $paddingInlineEnd={paddingInlineEnd}
      >
        {children ?? <Text>{formatMessage({ id: 'default_abandonment_body' })}</Text>}
      </ChildrenWrapper>
    </Modal>
  )
}

AbandonmentDialogContent.defaultProps = {
  paddingBlockStart: undefined,
  paddingBlockEnd: undefined,
  paddingInlineStart: undefined,
  paddingInlineEnd: undefined,
  renderHeader: undefined,
  renderFooter: undefined,
};

// eslint-disable-next-line max-len
export interface AbandonmentDialogProps extends AbandonmentDialogContentProps, PropsWithChildren, Omit<IntlProviderProps<Translations>, 'translations'> {
  /**
   * the locale used for any internal translate strings, defaults to 'en-US'
   * @defaultValue `"en-US"`
   */
  locale?: string;
}

const AbandonmentDialog = ({
  locale = 'en-US',
  onError,
  fallback,
  onReset,
  ...rest
}: AbandonmentDialogProps): JSX.Element => {
  const translations = getTranslationComponent(locale);
  return (
    <IntlProvider
      onError={onError}
      resetKey={locale}
      fallback={fallback}
      onReset={onReset}
      translations={translations}
    >
      <AbandonmentDialogContent {...rest} />
    </IntlProvider>
  );
}

AbandonmentDialog.propTypes = {
  // Indicates if the dialog should be displayed
  isOpen: PT.bool.isRequired,
  // Function to handle 'Continue Working' action
  onCancel: PT.func.isRequired,
  // Function to handle 'Leave Page' action
  onLeave: PT.func.isRequired,
  // Sets the padding around the modal's content.
  paddingBlockStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  paddingBlockEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  paddingInlineStart: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  paddingInlineEnd: PT.oneOf([
    'none',
    'micro',
    'mini',
    'small',
    'base',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  // Render prop for custom header content
  renderHeader: PT.func,
  // Render prop for custom footer content, with callbacks for Cancel and Leave actions
  renderFooter: PT.func,
  // the locale used for any internal translate strings, defaults to 'en-US'
  locale: PT.string,
};

AbandonmentDialog.defaultProps = {
  paddingBlockStart: undefined,
  paddingBlockEnd: undefined,
  paddingInlineStart: undefined,
  paddingInlineEnd: undefined,
  renderHeader: undefined,
  renderFooter: undefined,
  locale: 'en-US',
};

AbandonmentDialog.Cancel = CancelButton;
AbandonmentDialog.Leave = LeaveButton;
export default AbandonmentDialog;
