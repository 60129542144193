/* eslint-disable id-length */
import { palette } from '../base/color';

const popoverPrimitives = {
  arrowHeight: 7/* px */,
  surfaceBorderColor: palette.silver,
  surfaceColor: palette.white,
  surfaceBorderRadius: 4/* px */,
  surfaceShadow: '0 2px 4px 0 rgba(0,0,0,0.13)',
};

const popover = {
  transition: 'opacity 250ms ease-in-out',
  borderColorDefault: 'transparent',
  borderColor: {
    light: 'transparent',
    dark: 'transparent',
    blue: 'transparent',
  },
  radius: '8px',
  bg: palette.white,
  boxShadow: '0 4px 8px 0 rgb(35 47 63 / 0.20), 0 0 2px 0 rgb(35 47 63 / 0.30)',
  zIndex: 500,
  portalElementId: 'portal',
  ...popoverPrimitives,
};

export default popover;
