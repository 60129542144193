import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { angleDoubleLeft, angleDoubleRight } from '@amzn/storm-ui-icons-v3';
import Icon from '../Icon';
import Text from '../Text';
import focusOutlineStyle from '../FocusIndicator/styleMixins/focusOutline';
import { TaktProps } from '../types/TaktProps';
import { TaktIdConsumer, TaktIdProvider, createStormTaktId } from '../TaktIdContext';

const NavWrapper = styled.nav`
  position: relative;
`;
NavWrapper.displayName = 'NavWrapper';

export interface NavContainerProps extends MergeElementProps<'ul'> {
  $width?: string | null;
}
const NavContainer = styled('ul')<NavContainerProps>`
  padding: 0;
  margin: 0;
  width: ${({ $width, theme }) => ($width || theme.sideNav.width)};
  height: 100%;
  background-color: ${({ theme }) => theme.sideNav.bg};
  border-right: ${({ theme }) => theme.sideNav.borderRight};
  box-sizing: border-box;
  padding-top: ${({ theme }) => theme.sideNav.paddingTop};
  margin-left: 0px;
  display: inline-block;
`;
NavContainer.displayName = 'NavContainer';

interface NavExpandToggleProps extends TaktProps, MergeElementProps<'button'> {}

const NavExpandToggle = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
    type: 'button',
  });
})<NavExpandToggleProps>`
  background-color: transparent;
  display: inline-block;
  position: absolute;
  vertical-align: top;
  top: 120px;
  width: 0;
  height: 34px;
  /*! @noflip */ border-left: 14px solid #E6E9ED;
  /*! @noflip */ border-right: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  padding: 0;
  cursor: pointer;
  :active {
    color: inherit;
  }
  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutlineStyle)}
  }
/*! @noflip */
  [dir="rtl"] && {
  transform: scaleX(-1);
}
`;
NavExpandToggle.displayName = 'NavExpandToggle';

const NavIconContainer = styled.span`
  position: absolute;
  /*! @noflip */ left: -12px;
  /*! @noflip */ right: 0;
  top: 0;
`;
NavIconContainer.displayName = 'NavIconContainer';

export interface SideNavProps extends TaktProps, MergeElementProps<'nav'> {
  /**
     * The desired width of the nav container.
     * @defaultValue `null`
     */
  width?: string | null;
  /**
     * Hide the side nav by default with `true`.
     * @defaultValue `false`
     */
  isInitiallyCollapsed?: boolean;
  /**
     * If provided, an expander button is shown. When clicked it will call the callback.
     * @defaultValue `null`
     */
  onToggleCollapse?: (isCollapsed?: boolean) => void;
  /**
     * Provide a localized screen reader label for the expander button. [See string translations for the default value](https://translator.amazon.com/search/strings?exact_match&tag=stormui_hide_nav_menu)
     * @defaultValue `"Hide navigation menu"`
     */
  collapseScreenReaderLabel?: string;
  /**
     * Provide a localized screen reader label for the expander button. [See string translations for the default value](https://translator.amazon.com/search/strings?exact_match&tag=stormui_show_nav_menu)
     * @defaultValue `"Show navigation menu"`
     */
  expandScreenReaderLabel?: string;
  /**
     * Provide a localized screen reader label for the navigation landmark.
     * @defaultValue `"Sidebar"`
     */
  ariaLabel?: string;
}

class SideNav extends PureComponent<SideNavProps, { isCollapsed?: boolean; }> {
  static propTypes = {
    /**
     * What to display in the side nav.
     */
    children: PT.node.isRequired,
    /**
     * The desired width of the nav container.
     */
    width: PT.string,
    /**
     * Hide the side nav by default with `true`.
     */
    isInitiallyCollapsed: PT.bool,
    /**
     * If provided, an expander button is shown. When clicked it will call the callback.
     */
    onToggleCollapse: PT.func,
    /**
     * Provide a localized screen reader label for the expander button. [See string translations for the default value](https://translator.amazon.com/search/strings?exact_match&tag=stormui_hide_nav_menu)
     */
    collapseScreenReaderLabel: PT.string,
    /**
     * Provide a localized screen reader label for the expander button. [See string translations for the default value](https://translator.amazon.com/search/strings?exact_match&tag=stormui_show_nav_menu)
     */
    expandScreenReaderLabel: PT.string,
    /**
     * Provide a localized screen reader label for the navigation landmark.
     */
    ariaLabel: PT.string,
  }

  static defaultProps = {
    width: null,
    isInitiallyCollapsed: false,
    onToggleCollapse: null,
    collapseScreenReaderLabel: 'Hide navigation menu',
    expandScreenReaderLabel: 'Show navigation menu',
    ariaLabel: 'Sidebar',
  }

  constructor(props: SideNavProps) {
    super(props);
    this.state = {
      isCollapsed: props.isInitiallyCollapsed,
    };
  }

  handleToggleCollapse = (): void => {
    this.setState(({ isCollapsed }) => ({ isCollapsed: !isCollapsed }));

    const { onToggleCollapse } = this.props;
    if (typeof onToggleCollapse === 'function') {
      const { isCollapsed } = this.state;
      onToggleCollapse(!isCollapsed);
    }
  }

  render(): JSX.Element {
    const {
      taktId,
      taktValue,
    } = this.props;

    const {
      children,
      width,
      onToggleCollapse,
      isInitiallyCollapsed,
      expandScreenReaderLabel = 'Show navigation menu',
      collapseScreenReaderLabel = 'Hide navigation menu',
      ariaLabel = 'Sidebar',
      ...rest
    } = this.props;
    const { isCollapsed } = this.state;
    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('side-nav')}>
        <NavWrapper {...rest} aria-label={ariaLabel}>
          {!isCollapsed
            && (
              <NavContainer $width={width}>
                {children}
              </NavContainer>
            )}
          {onToggleCollapse && (
            <TaktIdConsumer fallbackId={createStormTaktId('expander-toggle')}>
              {({ getDataTaktAttributes }) => (
                <NavExpandToggle
                  onClick={this.handleToggleCollapse}
                  aria-expanded={!isCollapsed}
                  {...getDataTaktAttributes()}
                >
                  <NavIconContainer>
                    {isCollapsed && (
                      <>
                        <Icon type={angleDoubleRight} size="xs" />
                        <Text type="sr-only">{expandScreenReaderLabel}</Text>
                      </>
                    )}
                    {!isCollapsed && (
                      <>
                        <Icon type={angleDoubleLeft} size="xs" />
                        <Text type="sr-only">{collapseScreenReaderLabel}</Text>
                      </>
                    )}
                  </NavIconContainer>
                </NavExpandToggle>
              )}
            </TaktIdConsumer>

          )}
        </NavWrapper>
      </TaktIdProvider>
    );
  }
}

export default SideNav;
