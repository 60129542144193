import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { deviceTypes, MergeStyledComponentElementProps, getPadding } from '@amzn/storm-ui-utils';
import Icon from '../Icon';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { CardPadding } from './types';

export const DiscloseIcon = styled(Icon)
  .attrs({
    size: 'lg',
  })`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: ${({ theme }) => theme.spacing.xlarge};
  margin-inline-end: ${({ theme }) => theme.spacing.mini};
  color: ${({ theme }) => theme.palette.gray[800]};
`;
DiscloseIcon.displayName = 'DiscloseIcon';

const cardHeaderTypography = css`
>h1 {
  ${({ theme }) => theme.typography.h1}
}

>h2 {
  ${({ theme }) => theme.typography.h2}
}

>h3 {
  ${({ theme }) => theme.typography.h3}
}

>h4 {
  ${({ theme }) => theme.typography.h4}
}

>h5 {
  ${({ theme }) => theme.typography.h5}
}

>h6 {
  ${({ theme }) => theme.typography.h6}
}
`;

export const CardHeaderContentStyled = styled.div`
  flex: 1 1 auto;
  ${cardHeaderTypography}
`;
CardHeaderContentStyled.displayName = 'CardHeaderContentStyled';

interface CardHeaderStylesProps {
  padding?: CardPadding;
  isCollapsed?: boolean;
}

const cardHeaderStyles = css<CardHeaderStylesProps>`
  background: ${({ theme }) => theme.card.header.bg};
  padding: ${({ theme, padding }) => getPadding(theme, padding)}px;
  text-align: start;
  border: none;
  border-bottom: ${({ theme, isCollapsed }) => (
    isCollapsed
      ? 'none'
      : theme.card.header.borderBottom
  )};
  border-radius: ${({ theme, isCollapsed }) => (
    isCollapsed
      ? theme.card.header.borderRadius
      : `${theme.card.header.borderRadius} ${theme.card.header.borderRadius} 0 0`
  )};

  ${isMobile(css<CardHeaderStylesProps>`
    padding: ${({ theme, padding }) => getPadding(theme, padding, deviceTypes.MOBILE)}px;
  `)}
`;

interface CollapsibleCardHeaderAttrsProps extends CardHeaderStylesProps, MergeStyledComponentElementProps<'button'> {
  focusVisible?: boolean;
  collapsable?: boolean;
}

export const CollapsibleCardHeaderStyled = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
    type: 'button',
  });
})<CollapsibleCardHeaderAttrsProps>`
  ${({ theme }) => theme.typography.base}
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  outline: none;
  color: ${({ theme }) => theme.typography?.color?.base};
  > ${DiscloseIcon} {
    @media (prefers-reduced-motion: no-preference) {
      transition: all 0.15s ease-in-out;
    }

    ${({ isCollapsed }) => (isCollapsed ? '/*! @noflip */ transform: rotate(180deg)' : '')};
  }
  ${cardHeaderStyles}
  :focus {
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
`;
CollapsibleCardHeaderStyled.displayName = 'CollapsibleCardHeaderStyled';

export const CardHeaderStyled = styled.div`
  ${cardHeaderStyles}
  ${cardHeaderTypography}
`;
CardHeaderStyled.displayName = 'CardHeaderStyled';
