import React, {
  RefObject, useCallback, useState, useEffect, useLayoutEffect,
} from 'react';
import {
  SecondaryView,
  SecondaryViewProps,
  DropdownPopper,
  DropdownPopperProps,
} from '@amzn/storm-ui';
import { useMultiSelectIsMobile } from './MultiSelectIsMobileContext'
import { Position, OptionValue } from './types';

function getSize(elementRef: RefObject<HTMLElement | undefined> | undefined) {
  let height;
  let width;
  if (elementRef && elementRef.current) {
    const size = elementRef.current.getBoundingClientRect();
    height = size.height;
    width = size.width;
  }
  return {
    height,
    width,
  };
}

interface MultiSelectWrapperProps extends Omit<DropdownPopperProps, '$minWidth'> {
  anchorEl: RefObject<HTMLElement> | undefined;
  onKeyDown: (event: React.KeyboardEvent<Element>) => void;
  secondaryViewCloseButtonLabel: SecondaryViewProps['closeButtonLabel'];
  position: Position;
  selectedValues: OptionValue[];
}

function MultiSelectWrapper({
  anchorEl,
  isOpen,
  onClose,
  position,
  $fullWidth,
  onKeyDown,
  popperElementDidMount,
  secondaryViewCloseButtonLabel,
  selectedValues,
  trigger,
  children,
}: MultiSelectWrapperProps) {
  const isMobile = useMultiSelectIsMobile();
  const [forceUpdate, setForceUpdate] = useState(0);
  const [{ width, height }, setAnchorElRect] = useState<{width: undefined | number, height: undefined | number}>({
    width: undefined,
    height: undefined,
  });

  const handlePopperElementDidMount = useCallback<DropdownPopperProps['popperElementDidMount']>(popperElement => {
    setForceUpdate(prevState => prevState + 1);
    popperElementDidMount(popperElement);
  }, [popperElementDidMount])

  useLayoutEffect(() => {
    setAnchorElRect(getSize(anchorEl));
  }, [selectedValues, forceUpdate, anchorEl]);

  useEffect(() => {
    function handleResize() {
      setForceUpdate(prevState => prevState + 1);
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  if (isMobile) {
    return (
      <>
        <SecondaryView
          isOpen={isOpen}
          onClose={onClose}
          toggleEl={anchorEl}
          padding="none"
          closeButtonLabel={secondaryViewCloseButtonLabel}
        >
          {children}
        </SecondaryView>
        {trigger()}
      </>
    );
  }
  return (
    <DropdownPopper
      isOpen={isOpen}
      onClose={onClose}
      offsetDistance={position === 'bottom' ? 0 : -Math.abs(height ?? 0)}
      $fullWidth={$fullWidth}
      $minWidth={width ? width - 2 : undefined}
      onKeyDown={onKeyDown}
      popperElementDidMount={handlePopperElementDidMount}
      trigger={trigger}
      focusFirstElementOnPopoverExited
      $noMaxHeight
    >
      {children}
    </DropdownPopper>
  );
}

export default MultiSelectWrapper;
