import React, { useEffect, useRef, ReactNode } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { composeRefs } from '@amzn/storm-ui-utils-v3';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

export interface CheckboxInputProps {
  checkboxSize: 'medium' | 'large';
}

export interface CheckboxInputAttrsProps extends CheckboxInputProps {
  focusVisible?: boolean;
}

export const basicMixin = css<CheckboxInputProps>`
  /* --- User Agent Overrides --- */
  margin: unset;

  -webkit-appearance: none;
  position: initial;
  width: ${({ checkboxSize, theme }) => theme.form.checkbox.size[checkboxSize]};
  height: ${({ checkboxSize, theme }) => theme.form.checkbox.size[checkboxSize]};
  bottom: auto;
  border-radius: ${({ theme }) => theme.form.checkbox.radius};
  border-color: transparent;
  vertical-align: middle;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.borderColor};
  background-color: ${({ theme }) => theme.form.checkbox.bg};
  flex-shrink: 0;
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms ease;
  }
`;

export const checkedMixin = css`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSI+ICAgICAgICA8dGl0bGU+Q29udHJvbHMvQ2hlY2tib3ggLSBTZWxlY3RlZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik00LjA3NTczMjUsOS4yNzI3OTk1OCBMMC4xNzU3Mjg2ODcsNS4zNzI3OTU3NyBDLTAuMDU4NTc2MjI5MSw1LjEzODQ5MDg2IC0wLjA1ODU3NjIyOTEsNC43NTg1OTIwNSAwLjE3NTcyODY4Nyw0LjUyNDI2MzcgTDEuMDI0MjM3MzMsMy42NzU3MzE2MiBDMS4yNTg1NDIyNCwzLjQ0MTQwMzI2IDEuNjM4NDY0NDksMy40NDE0MDMyNiAxLjg3Mjc2OTQxLDMuNjc1NzMxNjIgTDQuNDk5OTk4NTQsNi4zMDI5MzczMSBMMTAuMTI3MjMwNiwwLjY3NTcyODY4NyBDMTAuMzYxNTM1NSwwLjQ0MTQyMzc3MSAxMC43NDE0NTc4LDAuNDQxNDIzNzcxIDEwLjk3NTc2MjcsMC42NzU3Mjg2ODcgTDExLjgyNDI3MTMsMS41MjQyNjA3NyBDMTIuMDU4NTc2MiwxLjc1ODU2NTY4IDEyLjA1ODU3NjIsMi4xMzg0NjQ0OSAxMS44MjQyNzEzLDIuMzcyNzkyODQgTDQuOTI0MjY0NTcsOS4yNzI4MjMwMiBDNC42ODk5MzYyMiw5LjUwNzEyNzk0IDQuMzEwMDM3NDEsOS41MDcxMjc5NCA0LjA3NTczMjUsOS4yNzI3OTk1OCBaIiBpZD0icGF0aC0xIi8+ICAgIDwvZGVmcz4gICAgPGcgaWQ9IkNvbnRyb2xzL0NoZWNrYm94LS0tU2VsZWN0ZWQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlJhZGlvLUJ1dHRvbi0tLUFjdGl2ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDMuMDAwMDAwKSI+ICAgICAgICAgICAgPGcgaWQ9Ikljb24vU3VjY2VzcyI+ICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSIvPiAgICAgICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgICAgIDx1c2UgaWQ9IlNoYXBlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-size: cover;
  background-color: ${({ theme }) => theme.form.checkbox.bgChecked};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgChecked};
`;

export const disabledMixin = css`
  cursor: not-allowed;
  opacity: 0.5;
  outline: none;
  bottom: auto;
`;

const CheckboxInput = styled('input')
  .attrs<CheckboxInputProps>(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      /*
       *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
       * make sure user supplied `onFocus` and `onBlur` are also called.
      */
      ...mergeProps({ onFocus, onBlur }, focusProps),
      focusVisible: isFocusVisible,
      type: 'checkbox',
    });
  })<CheckboxInputAttrsProps>`
&&& {
  ${basicMixin}

  :indeterminate {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cpath d='M2 7.6103433V6.4034526C2 6.07018962 2.24514086 5.8 2.54754146 5.8h8.90491508C11.75484201 5.8 12 6.0702095 12 6.40347247v1.2068907c0 .33326297-.24515405.6034526-.54755464.6034526H2.54753029C2.24512969 8.21381577 2 7.94362296 2 7.6103433z' fill='%23FFFFFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-size: cover;
    background-color: ${({ theme }) => theme.form.checkbox.bgChecked};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgChecked};
  }

  :checked {
    ${checkedMixin}
  }

  :active {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSI+ICAgICAgICA8dGl0bGU+Q29udHJvbHMvQ2hlY2tib3ggLSBTZWxlY3RlZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik00LjA3NTczMjUsOS4yNzI3OTk1OCBMMC4xNzU3Mjg2ODcsNS4zNzI3OTU3NyBDLTAuMDU4NTc2MjI5MSw1LjEzODQ5MDg2IC0wLjA1ODU3NjIyOTEsNC43NTg1OTIwNSAwLjE3NTcyODY4Nyw0LjUyNDI2MzcgTDEuMDI0MjM3MzMsMy42NzU3MzE2MiBDMS4yNTg1NDIyNCwzLjQ0MTQwMzI2IDEuNjM4NDY0NDksMy40NDE0MDMyNiAxLjg3Mjc2OTQxLDMuNjc1NzMxNjIgTDQuNDk5OTk4NTQsNi4zMDI5MzczMSBMMTAuMTI3MjMwNiwwLjY3NTcyODY4NyBDMTAuMzYxNTM1NSwwLjQ0MTQyMzc3MSAxMC43NDE0NTc4LDAuNDQxNDIzNzcxIDEwLjk3NTc2MjcsMC42NzU3Mjg2ODcgTDExLjgyNDI3MTMsMS41MjQyNjA3NyBDMTIuMDU4NTc2MiwxLjc1ODU2NTY4IDEyLjA1ODU3NjIsMi4xMzg0NjQ0OSAxMS44MjQyNzEzLDIuMzcyNzkyODQgTDQuOTI0MjY0NTcsOS4yNzI4MjMwMiBDNC42ODk5MzYyMiw5LjUwNzEyNzk0IDQuMzEwMDM3NDEsOS41MDcxMjc5NCA0LjA3NTczMjUsOS4yNzI3OTk1OCBaIiBpZD0icGF0aC0xIi8+ICAgIDwvZGVmcz4gICAgPGcgaWQ9IkNvbnRyb2xzL0NoZWNrYm94LS0tU2VsZWN0ZWQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlJhZGlvLUJ1dHRvbi0tLUFjdGl2ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDMuMDAwMDAwKSI+ICAgICAgICAgICAgPGcgaWQ9Ikljb24vU3VjY2VzcyI+ICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSIvPiAgICAgICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgICAgIDx1c2UgaWQ9IlNoYXBlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
    background-size: cover;
    background-color: ${({ theme }) => theme.form.checkbox.bgActive};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgActive};
    transform: scale(1.2);
  }

  :hover {
    cursor:  ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.focusVisible.borderColor};
    background-size: cover;
    background-color: ${({ theme }) => theme.form.checkbox.bgHover};

    :indeterminate {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cpath d='M2 7.6103433V6.4034526C2 6.07018962 2.24514086 5.8 2.54754146 5.8h8.90491508C11.75484201 5.8 12 6.0702095 12 6.40347247v1.2068907c0 .33326297-.24515405.6034526-.54755464.6034526H2.54753029C2.24512969 8.21381577 2 7.94362296 2 7.6103433z' fill='%23FFFFFF' fill-rule='nonzero'/%3E%3C/svg%3E");
      background-color: ${({ theme }) => theme.form.checkbox.bgCheckedHover};
    }

    :checked {
      background-color: ${({ theme }) => theme.form.checkbox.bgCheckedHover};
    }
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }

  :disabled {
    ${disabledMixin}
    :active {
      transform: none;
    }
    :hover {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.borderColor};
      background-color: ${({ theme }) => theme.form.checkbox.bg};

      :checked {
        background-color: ${({ theme }) => theme.form.checkbox.bgChecked};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgChecked};
      }
    }
  }
}
`;
CheckboxInput.displayName = 'CheckboxInput';

const centerToLabel = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
`;

export interface InputAreaProps {
  positionWithLabel: 'start'|'center'
}

const InputArea = styled('div')<InputAreaProps>`
  flex: 0 0 auto;
  ${({ positionWithLabel }) => positionWithLabel === 'center' && centerToLabel}
`;
InputArea.displayName = 'InputArea';

export interface LabelCentererProps {
  disabled: boolean;
  hideLabel: boolean;
}

const LabelCenterer = styled('label')<LabelCentererProps>`
  ${({ theme }) => theme.typography.base};
  cursor:  ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled, theme }) => (disabled ? theme.form.label.colorDisabled : theme.form.label.color)};

  ${({ hideLabel }) => (hideLabel && `
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  `)}

  ${isMobile(css`${({ theme }) => theme.typography.mobile.base};`)}
`;
LabelCenterer.displayName = 'LabelCenterer';

const LabelArea = styled('div')`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  margin-inline-start: 6px;

  ${isMobile(css`margin-inline-start: 10px;`)}
`;
LabelArea.displayName = 'LabelArea';

export interface CheckboxCentererProps {
  withMarginTop?: boolean;
}

const CheckboxCenterer = styled('div')<CheckboxCentererProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  ${({ withMarginTop }) => (
    withMarginTop && css`margin-top: 3px;`
  )}
`;
CheckboxCenterer.displayName = 'CheckboxCenterer';

const CheckboxInputGroup = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  padding: 0;

  ${isMobile(css`
    padding: ${({ theme }) => theme.form.checkbox.mobile.padding};
  `)}
`;

export interface CheckboxProps extends TaktProps, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   * @defaultValue `false`
   */
  hideLabel?: boolean;
  /**
   * Use this prop to access the ref of the checkbox's input element.
   * @defaultValue `() => null`
   */
  inputRef?: React.RefObject<HTMLInputElement>;
  /**
   * Specifies that the state of the checkbox is indeterminate.
   * @defaultValue `false`
   */
  indeterminate?: boolean;
  /**
   * The control's label that's displayed next to the checkbox. A state change occurs when a user
   * clicks on it.
   */
  label: ReactNode;
  /**
   * Specifies the size of the checkbox.
   */
  size?: 'medium' | 'large';
  /**
   * Specifies the positioning of the checkbox with relation to the label.
   * @defaultValue `"center"`
   */
  positionWithLabel?: 'start'|'center';
}

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const {
    disabled = false,
    hideLabel = false,
    id,
    inputRef,
    indeterminate = false,
    label,
    positionWithLabel = 'center',
    checked = false,
    className,
    size = 'medium',
    taktId,
    taktValue,
    ...rest
  } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current !== null) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate, checked]);

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('checkbox')}>
      {({ getDataTaktAttributes }) => (
        <CheckboxInputGroup className={className}>
          <InputArea positionWithLabel={positionWithLabel}>
            <CheckboxCenterer
              withMarginTop={((!hideLabel) && positionWithLabel === 'start' && size === 'medium')}
            >
              <CheckboxInput
                id={id}
                checked={checked}
                disabled={disabled}
                ref={composeRefs(checkboxRef, inputRef)}
                checkboxSize={size}
                {...getDataTaktAttributes()}
                {...rest}
              />
            </CheckboxCenterer>
          </InputArea>
          <LabelArea>
            <LabelCenterer
              htmlFor={id}
              disabled={disabled}
              hideLabel={hideLabel}
              {...getDataTaktAttributes()}
            >
              {label}
            </LabelCenterer>
          </LabelArea>
        </CheckboxInputGroup>
      )}
    </TaktIdConsumer>
  );
};

Checkbox.propTypes = {
  /**
   * Adds the specified id to the checkbox input element.
   */
  id: PT.string.isRequired,
  /**
   * The control's label that's displayed next to the checkbox. A state change occurs when a user
   * clicks on it.
   */
  label: PT.node.isRequired,
  /**
   * Specifies if the checkbox is selected.
   */
  checked: PT.bool,
  /**
   * Utilized when wrapping the checkbox in a styled-component. Passes the styles specified by the
   * styled-component to the checkbox wrapper component.
   */
  className: PT.string,
  /**
   * Specifies if the control is disabled, which prevents the user from modifying the value and
   * prevents the value from being included in a form submission. A disabled control can't receive
   * focus.
   */
  disabled: PT.bool,
  /**
   * Labels are required for screen reader accessibility, but can be hidden visually.
   */
  hideLabel: PT.bool,
  /**
   * Specifies that the state of the checkbox is indeterminate.
   */
  indeterminate: PT.bool,
  /**
   * Use this prop to access the ref of the checkbox's input element.
   */
  inputRef: PT.func,
  /**
   * Called when the user changes the component state.
   */
  onChange: PT.func,
  /**
   * Specifies the positioning of the checkbox with relation to the label.
   */
  positionWithLabel: PT.oneOf(['start', 'center']),
  /**
   * Specifies the size of the checkbox.
   * @defaultValue `"medium"`
   */
  size: PT.oneOf(['medium', 'large']),
};

Checkbox.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  hideLabel: false,
  indeterminate: false,
  inputRef: () => null,
  onChange: () => undefined,
  positionWithLabel: 'center',
  size: 'medium',
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
