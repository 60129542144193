import React from 'react';
import styled, { css } from 'styled-components';

interface CheckboxProps {
  checked: boolean | undefined;
  disabled: boolean | undefined;
  checkboxSize: 'medium';
}

type MultiSelectItemCheckboxProps = Omit<CheckboxProps, 'checkboxSize'>

// Copied from packages/storm-ui/src/Checkbox/CheckboxInput
export type CheckboxInputProps = CheckboxProps;

export const defaultStyle = css<CheckboxInputProps>`
  /* --- User Agent Overrides --- */
  margin: unset;

  -webkit-appearance: none;
  position: initial;
  width: ${({ checkboxSize, theme }) => theme.form.checkbox.size[checkboxSize]};
  height: ${({ checkboxSize, theme }) => theme.form.checkbox.size[checkboxSize]};
  bottom: auto;
  border-radius: ${({ theme }) => theme.form.checkbox.radius};
  border-color: transparent;
  vertical-align: middle;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.borderColor};
  background-color: ${({ theme }) => theme.form.checkbox.bg};
  flex-shrink: 0;
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease,
      border-color 100ms ease, box-shadow 100ms ease;
  }
`;

export const activeStyle = css`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSI+ICAgICAgICA8dGl0bGU+Q29udHJvbHMvQ2hlY2tib3ggLSBTZWxlY3RlZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik00LjA3NTczMjUsOS4yNzI3OTk1OCBMMC4xNzU3Mjg2ODcsNS4zNzI3OTU3NyBDLTAuMDU4NTc2MjI5MSw1LjEzODQ5MDg2IC0wLjA1ODU3NjIyOTEsNC43NTg1OTIwNSAwLjE3NTcyODY4Nyw0LjUyNDI2MzcgTDEuMDI0MjM3MzMsMy42NzU3MzE2MiBDMS4yNTg1NDIyNCwzLjQ0MTQwMzI2IDEuNjM4NDY0NDksMy40NDE0MDMyNiAxLjg3Mjc2OTQxLDMuNjc1NzMxNjIgTDQuNDk5OTk4NTQsNi4zMDI5MzczMSBMMTAuMTI3MjMwNiwwLjY3NTcyODY4NyBDMTAuMzYxNTM1NSwwLjQ0MTQyMzc3MSAxMC43NDE0NTc4LDAuNDQxNDIzNzcxIDEwLjk3NTc2MjcsMC42NzU3Mjg2ODcgTDExLjgyNDI3MTMsMS41MjQyNjA3NyBDMTIuMDU4NTc2MiwxLjc1ODU2NTY4IDEyLjA1ODU3NjIsMi4xMzg0NjQ0OSAxMS44MjQyNzEzLDIuMzcyNzkyODQgTDQuOTI0MjY0NTcsOS4yNzI4MjMwMiBDNC42ODk5MzYyMiw5LjUwNzEyNzk0IDQuMzEwMDM3NDEsOS41MDcxMjc5NCA0LjA3NTczMjUsOS4yNzI3OTk1OCBaIiBpZD0icGF0aC0xIi8+ICAgIDwvZGVmcz4gICAgPGcgaWQ9IkNvbnRyb2xzL0NoZWNrYm94LS0tU2VsZWN0ZWQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlJhZGlvLUJ1dHRvbi0tLUFjdGl2ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDMuMDAwMDAwKSI+ICAgICAgICAgICAgPGcgaWQ9Ikljb24vU3VjY2VzcyI+ICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSIvPiAgICAgICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgICAgIDx1c2UgaWQ9IlNoYXBlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-size: cover;
  background-color: ${({ theme }) => theme.form.checkbox.bgActive};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgActive};
  transform: scale(1.2);
`;

export const checkedStyle = css<CheckboxInputProps>`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSI+ICAgICAgICA8dGl0bGU+Q29udHJvbHMvQ2hlY2tib3ggLSBTZWxlY3RlZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik00LjA3NTczMjUsOS4yNzI3OTk1OCBMMC4xNzU3Mjg2ODcsNS4zNzI3OTU3NyBDLTAuMDU4NTc2MjI5MSw1LjEzODQ5MDg2IC0wLjA1ODU3NjIyOTEsNC43NTg1OTIwNSAwLjE3NTcyODY4Nyw0LjUyNDI2MzcgTDEuMDI0MjM3MzMsMy42NzU3MzE2MiBDMS4yNTg1NDIyNCwzLjQ0MTQwMzI2IDEuNjM4NDY0NDksMy40NDE0MDMyNiAxLjg3Mjc2OTQxLDMuNjc1NzMxNjIgTDQuNDk5OTk4NTQsNi4zMDI5MzczMSBMMTAuMTI3MjMwNiwwLjY3NTcyODY4NyBDMTAuMzYxNTM1NSwwLjQ0MTQyMzc3MSAxMC43NDE0NTc4LDAuNDQxNDIzNzcxIDEwLjk3NTc2MjcsMC42NzU3Mjg2ODcgTDExLjgyNDI3MTMsMS41MjQyNjA3NyBDMTIuMDU4NTc2MiwxLjc1ODU2NTY4IDEyLjA1ODU3NjIsMi4xMzg0NjQ0OSAxMS44MjQyNzEzLDIuMzcyNzkyODQgTDQuOTI0MjY0NTcsOS4yNzI4MjMwMiBDNC42ODk5MzYyMiw5LjUwNzEyNzk0IDQuMzEwMDM3NDEsOS41MDcxMjc5NCA0LjA3NTczMjUsOS4yNzI3OTk1OCBaIiBpZD0icGF0aC0xIi8+ICAgIDwvZGVmcz4gICAgPGcgaWQ9IkNvbnRyb2xzL0NoZWNrYm94LS0tU2VsZWN0ZWQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IlJhZGlvLUJ1dHRvbi0tLUFjdGl2ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDMuMDAwMDAwKSI+ICAgICAgICAgICAgPGcgaWQ9Ikljb24vU3VjY2VzcyI+ICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSIvPiAgICAgICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgICAgIDx1c2UgaWQ9IlNoYXBlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-size: cover;
  background-color: ${({ theme }) => theme.form.checkbox.bgChecked};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgChecked};
`;

export const hoverStyle = css<CheckboxInputProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.focusVisible.borderColor};
  background-size: cover;
  background-color: ${({ theme }) => theme.form.checkbox.bgHover};
`;

export const hoverCheckedStyle = css<CheckboxInputProps>`
  background-color: ${({ theme }) => theme.form.checkbox.bgCheckedHover};
`;

export const disabledStyle = css`
  cursor: not-allowed;
  opacity: 0.5;
  outline: none;
  bottom: auto;
`;

export const disabledActiveStyle = css`
  transform: none;
`;

export const disabledHoverStyle = css`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.borderColor};
  background-color: ${({ theme }) => theme.form.checkbox.bg};
`;

export const disabledHoverCheckedStyle = css`
  background-color: ${({ theme }) => theme.form.checkbox.bgChecked};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.form.checkbox.bgChecked};
`;

const Checkbox = styled('div')`
  &&& {
    ${defaultStyle}
    ${({ disabled, checked }) => {
    if (disabled) {
      return css`
          ${checked && checkedStyle}
          :active {
            ${disabledActiveStyle}
          }
          ${disabledStyle}
          :hover {
            ${disabledHoverStyle}
            ${checked && disabledHoverCheckedStyle}
          }
        `;
    }

    return css`
        ${checked && checkedStyle}
        :active {
          ${activeStyle}
        }
        :hover {
          ${hoverStyle}
          ${checked && hoverCheckedStyle}
        }
      `;
  }}

    margin-inline-end: ${({ theme }) => theme.spacing.small};
    display: inline-block;
    vertical-align: middle;
    min-height: 0;
  }
`;

const MultiSelectItemCheckbox = ({
  checked,
  disabled,
  ...rest
}: MultiSelectItemCheckboxProps) => (
  <Checkbox
    {...rest}
    checked={checked}
    disabled={disabled}
    checkboxSize="medium"
    aria-hidden="true"
  />
);

export default MultiSelectItemCheckbox;
