import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import Text from '../../Text/Text';

export interface PageStatusTextProps {
  fontSize: string;
}

const PageStatus = (styled(Text)`
  display: flex;
  align-items: center;
`) as FC<React.PropsWithChildren<PageStatusTextProps>>;
PageStatus.displayName = 'PageStatus';

export interface PaginationPageStatusProps {
  /**
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * The text that displays "x out of 12 pages" in the example.
   */
  currentPageLabel: string;
}

const PaginationPageStatus: FC<React.PropsWithChildren<PaginationPageStatusProps>> = (
  { currentPageLabel, ...rest }: PaginationPageStatusProps,
): JSX.Element => (
  <PageStatus {...rest} fontSize="base">
    {currentPageLabel}
  </PageStatus>
);

PaginationPageStatus.defaultProps = {
  id: undefined,
};

export default PaginationPageStatus;
