/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing, mobileSpacing } from '../base/spacing';
import { typography } from '../base/typography';

export const dropdownStyles = {
  maxHeight: '400px',
  submenuBorderColor: palette.gray[100],

  // SelectOption
  item: {
    bg: palette.white,
    minHeight: spacing.xlarge,
    bgHover: palette.gray[50],
    borderHover: palette.gray[300],
    bgActive: palette.gray[100],
    borderActive: palette.blue[700],
    borderWidthActive: '3px',
    color: typography.color.primary,
    colorDisabled: typography.color.tertiary,
  },

  group: {
    headingColor: typography.color.primary,
  },

  icon: {
    color: palette.gray[700],
    colorHover: palette.squidInk,
    colorDisabled: palette.gray[500],
  },
};

export const mobileDropdownStyles = {
  item: {
    mobile: {
      minHeight: mobileSpacing.xlarge,
      padding: mobileSpacing.base,
    },
  },
};
