import React, { MouseEvent, Ref } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

import { MergeElementProps } from '@amzn/storm-ui-utils';
import focusOutlineStyle from '../../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';

import { LoadingIcon, colorMixin } from '../Button';

const loadingStyles = css`
  &&[aria-disabled="true"] {
    cursor: not-allowed;
    ${colorMixin(({ theme }) => theme.globals.textDisabledColor)}
    pointer-events: none;
  }
`;

interface StyledTextButtonProps extends Omit<MergeElementProps<'button'>, 'type'|'onClick'> {
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  size?: 'base' | 'small' | 'mini' | 'tiny';
  buttonRef?: Ref<HTMLButtonElement>;
  $loading?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: MouseEvent<HTMLButtonElement>, ...args: any[]) => void;
}

const StyledTextButton = styled('button').attrs(({ onFocus, onBlur }) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  return ({
    /*
     *`useFocusRing()` uses `onFocus` and `onBlur` props, so `mergeProps()` must be used to
     * make sure user supplied `onFocus` and `onBlur` are also called.
    */
    ...mergeProps({ onFocus, onBlur }, focusProps),
    focusVisible: isFocusVisible,
  });
})<StyledTextButtonProps>`
  ${({ theme }) => theme.typography.base};
  font-size:   ${({ size, theme }) => (theme.link[size || 'base'])};

  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.link.color};
  user-select: auto;

  ${({ $loading }) => ($loading && loadingStyles)};

  :hover {
    color: ${({ theme }) => theme.link.hoverColor};
    text-decoration: ${({ theme }) => theme.link.hoverDecoration};
    background: none;
    cursor: pointer;
  }

  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutlineStyle)}
  }

  :disabled {
    color: ${({ theme }) => theme.globals.textDisabledColor};
    cursor: not-allowed;

    :focus {
      outline: none;
    }

    :hover {
      text-decoration: none;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease,background-color 100ms ease,border-color 100ms ease, box-shadow 100ms ease;
  }

  ${isMobile(css<StyledTextButtonProps>`
    ${({ theme }) => theme.typography.mobile.base};
    font-size: ${({ size, theme }) => (theme.mobile.link[size || 'base'])};
  `)}
`;

export interface TextButtonProps extends TaktProps, Omit<StyledTextButtonProps, '$loading'> {
  /**
   * @defaultValue `"button"`
   */
  type?: StyledTextButtonProps['type'];
  /**
   * @defaultValue `false`
   */
  disabled?: StyledTextButtonProps['disabled'];
  /**
   * @defaultValue `"base"`
   */
  size?: StyledTextButtonProps['size'];
  /**
   * @defaultValue `undefined`
   */
  buttonRef?: StyledTextButtonProps['buttonRef'];
  /**
   * @defaultValue `undefined`
   */
  onClick?: StyledTextButtonProps['onClick'];
  /**
   * To indicate the button is loading.
   * @defaultValue `false`
   */
  loading?: boolean;
  /**
   * Substitute for Button's body when loading is true.
   * @defaultValue `undefined`
   */
  loadingLabel?: string;
}

const TextButton = ({
  buttonRef,
  taktId,
  taktValue,
  loading,
  loadingLabel,
  children,
  onClick,
  ...rest
}: TextButtonProps) => (
  <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('text-button')}>
    {({ getDataTaktAttributes }) => (
      <StyledTextButton
        ref={buttonRef}
        {...getDataTaktAttributes()}
        {...rest}
        $loading={loading}
        {...(loading) && { 'aria-live': 'assertive' }}
        {...(loading) && { 'aria-disabled': true }}
        onClick={loading ? undefined : onClick}
      >
        {loading && <LoadingIcon />}
        <>{(loading && loadingLabel) ? loadingLabel : children}</>
      </StyledTextButton>
    )}
  </TaktIdConsumer>
)
TextButton.propTypes = {
  type: PT.oneOf(['submit', 'reset', 'button']),
  disabled: PT.bool,
  size: PT.oneOf(['base', 'small', 'mini', 'tiny']),
  buttonRef: PT.oneOfType([
    PT.func,
    /* allow the `any` Prop Type here so prevent breaking in SSR context  */
    // eslint-disable-next-line react/forbid-prop-types
    PT.shape({ current: PT.any }),
  ]),
  onClick: PT.func,
  loading: PT.bool,
  loadingLabel: PT.string,
}

TextButton.defaultProps = {
  type: 'button',
  disabled: false,
  size: 'base',
  buttonRef: undefined,
  onClick: undefined,
  loading: false,
  loadingLabel: undefined,
}

export default TextButton;
