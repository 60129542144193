/* eslint-disable no-nested-ternary */
import {
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
} from 'react';
import { useIsMobile } from '@amzn/storm-ui-v3';
import { format, isFirstDayOfMonth } from 'date-fns';
import useDateSingle from './useDateSingle';
import useDatePicker from './useDatePicker';
import useDateRange from './useDateRange';
import useDayModifiers from './useDayModifiers';
import useDayFocus from './useDayFocus';
import isDateSingle from '../utils/isDateSingle';
import isDateRange from '../utils/isDateRange';
import { isSameDay, isSameMonth, isoDateToDate } from '../utils/dateUtils';
import {
  DatePickerContextValue,
  DateSingleContextValue,
  DateRangeContextValue,
  DayModifiers,
  ISODate,
  ISODateRange,
} from '../types';

export function getTabIndex({
  focusedDay,
  context,
  single,
  range,
  date,
  displayMonth,
}: {
  focusedDay?: ISODate;
  context: DatePickerContextValue;
  single: DateSingleContextValue;
  range: DateRangeContextValue;
  date: ISODate;
  displayMonth: ISODate;
}) {
  if (focusedDay) {
    if (isSameDay(focusedDay, date)) {
      return 0;
    }
    return -1;
  }

  const isFirstDayOfCurrentMonth = isSameMonth(date, displayMonth) && isFirstDayOfMonth(isoDateToDate(date));
  const selectedDate = isDateSingle(context.type)
    ? single.selected
    : isDateRange(context.type)
      ? range.selected?.from
      : undefined;

  if (selectedDate) {
    if (isSameDay(selectedDate, date)) {
      return 0;
    }

    // Pick the firt day of the month if selectedDate is not in the current grid
    if (!isSameMonth(selectedDate, date)) {
      if (isFirstDayOfCurrentMonth) {
        return 0;
      }
    }

    return -1;
  }

  if (isFirstDayOfCurrentMonth) {
    return 0;
  }

  return -1;
}

function getAriaLabel(date: ISODate, modifiers: DayModifiers, locale: Locale) {
  let label = format(
    new Date(`${date}T00:00:00.0000`),
    'EEEE MMMM d yyyy',
    { locale },
  );
  if (modifiers.selected) {
    label += ', selected day';
  } else if (modifiers.range) {
    label += ', selected range';
  }
  return label;
}

type ButtonProps = {
  tabIndex: number;
  'aria-label': string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * Should set this for non-mobile use-cases but mobile use-cases don't require mouse event listeners.
   */
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Should set this for non-mobile use-cases but mobile use-cases don't require mouse event listeners.
   */
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  onFocus: FocusEventHandler<HTMLButtonElement>,
  onBlur: FocusEventHandler<HTMLButtonElement>,
  onKeyDown: KeyboardEventHandler<HTMLButtonElement>,
}

type UseDay = {
  selected: ISODate | ISODate[] | ISODateRange | undefined;
  single: DateSingleContextValue;
  range: DateRangeContextValue;
  buttonProps: ButtonProps;
  modifiers: DayModifiers;
};

const useDay = (
  date: ISODate, buttonRef: RefObject<HTMLButtonElement>, overrides: DayModifiers, displayMonth: ISODate,
): UseDay => {
  const isMobile = useIsMobile();
  const context = useDatePicker();
  const single = useDateSingle();
  const range = useDateRange();

  const {
    focus,
    blur,
    focusOnKeyDown,
    focusedDay,
  } = useDayFocus(date, buttonRef);

  const modifiers = useDayModifiers(date, overrides);

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (modifiers.disabled) {
      context.isOutsideRange?.(date, event);
      return;
    }

    if (isDateSingle(context.type)) {
      single.handleDateSelect(date, event);
    } else if (isDateRange(context.type)) {
      range.handleDateSelect(date, event);
    }
  };

  const handleMouseEnter: MouseEventHandler = event => {
    if (isDateRange(context.type)) {
      range.handleOnMouseEnter(date, event);
    }
  };

  const handleMouseLeave: MouseEventHandler = event => {
    if (isDateRange(context.type)) {
      range.handleOnMouseLeave(date, event);
    }
  };

  const handleFocus: FocusEventHandler = () => {
    focus(date);
  };

  const handleBlur: FocusEventHandler = () => {
    blur();
  };

  const handleKeyDown: KeyboardEventHandler = event => {
    focusOnKeyDown(event);
  };

  const tabIndex = getTabIndex({
    focusedDay,
    context,
    single,
    range,
    date,
    displayMonth,
  });

  const ariaLabel = getAriaLabel(date, modifiers, context.locale);

  const mouseEventProps = !isMobile ? {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  } : {};

  return {
    selected: isDateSingle(context.type)
      ? single.selected
      : isDateRange(context.type)
        ? range.selected
        : undefined,
    single,
    range,
    buttonProps: {
      tabIndex,
      'aria-label': ariaLabel,
      onClick: handleClick,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
      ...mouseEventProps,
    },
    modifiers,
  };
};

export default useDay;
