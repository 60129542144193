import React, {
  FC, ReactNode, MouseEventHandler,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { times } from '@amzn/storm-ui-icons-v3';
import { TextButton } from '../Button';
import Icon from '../Icon';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';
import { TextButtonProps } from '../Button/TextButton';

const AnchoredAlertContent = styled.div`
  padding: ${({ theme }) => theme.tooltip.paddingTopBottom} ${({ theme }) => theme.tooltip.paddingLeftRight};

  overflow: visible;
  max-width: ${({ theme }) => theme.tooltip.maxWidth};
  max-height: ${({ theme }) => theme.tooltip.maxHeight};
`;
AnchoredAlertContent.displayName = 'AnchoredAlertContent';

const AnchoredAlertContentWithTopCornerIcon = styled(AnchoredAlertContent)`
  padding: ${({ theme }) => theme.tooltip.paddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.paddingLeftRightWithTopCornerIcon};
`;
AnchoredAlertContentWithTopCornerIcon.displayName = 'AnchoredAlertContentWithTopCornerIcon';

const AnchoredAlertScrollContainer = styled.div`
  overflow-x: auto;
`;
AnchoredAlertScrollContainer.displayName = 'AnchoredAlertScrollContainer';

const AnchoredAlertMessage = styled.div`
  ${({ theme }) => theme.typography.base};
`;
AnchoredAlertMessage.displayName = 'AnchoredAlertMessage';

type ThemeType = 'light' | 'dark' | 'blue';

interface StyledCloseButtonBaseProps extends TextButtonProps {
  $styleType: ThemeType;
}
const CloseButtonBase = withTaktFallbackId<Omit<TextButtonProps, 'type'|'size'|'buttonRef'>>(TextButton);

const StyledCloseButton = styled(CloseButtonBase)<StyledCloseButtonBaseProps>`
  opacity: ${({ $styleType }) => (($styleType === 'blue' || $styleType === 'dark') ? 1 : 0.5)};
  padding: ${({ theme }) => theme.tooltip.closeIcon.padding};
  text-align: center;
  color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  outline: none;

  :hover{
    opacity: 1;
    background: ${({ $styleType, theme }) => (theme.tooltip.closeIconBg[$styleType])};
    color: ${({ $styleType, theme }) => (theme.tooltip.closeIcon[$styleType])};
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: background .2s ease-in-out;
  }

  :focus {
    opacity: 1;
  }
`;

const CloseButton = styled(StyledCloseButton)`
  margin-inline-start: ${({ theme }) => theme.tooltip.closeIcon.marginLeft};
`;
CloseButton.displayName = 'CloseButton';

const CloseIcon = styled(Icon)`
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;

  > svg {
    vertical-align: ${({ theme }) => theme.tooltip.closeIcon.svg.verticalAlign};
    width: ${({ theme }) => theme.tooltip.closeIcon.svg.width};
  }
`;
CloseIcon.displayName = 'CloseIcon';

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ContentContainerWithTopCornerIcon = styled(FlexContainer)`
  padding: ${({ theme }) => theme.tooltip.contentPaddingTopBottomWithTopCornerIcon} ${({ theme }) => theme.tooltip.contentPaddingLeftRightWithTopCornerIcon};
`;
ContentContainerWithTopCornerIcon.displayName = 'ContentContainerWithTopCornerIcon';

const NoMarginCloseIcon = styled(CloseButton)`
  margin: 0;
  margin-inline-start: auto;
`;

export interface AnchoredAlertContentProps {
  type?: ThemeType;
  message: string | ReactNode;
  withCloseButton?: boolean;
  closeButtonLabel?: string;
  handleCloseClicked: MouseEventHandler<HTMLButtonElement>;
  footer?: ReactNode;
  children?: ReactNode;
  closeIconPosition?: 'default' | 'topCorner';
}

const AnchoredAlertContentContainer:FC<React.PropsWithChildren<AnchoredAlertContentProps>> = ({
  children,
  closeButtonLabel,
  closeIconPosition,
  handleCloseClicked,
  footer,
  message,
  type = 'light',
  withCloseButton,
}) => {
  const DefaultLayout = (
    <AnchoredAlertContent>
      <FlexContainer>
        <div>{message ? <AnchoredAlertMessage>{message}</AnchoredAlertMessage> : children}</div>
        {withCloseButton && (
          <CloseButton
            aria-label={closeButtonLabel}
            onClick={handleCloseClicked}
            $styleType={type}
            type="button"
            taktFallbackId={createStormTaktId('close-button')}
          >
            <CloseIcon type={times} />
          </CloseButton>
        )}
      </FlexContainer>
      { footer && (
        <FlexContainer>
          { footer }
        </FlexContainer>
      )}
    </AnchoredAlertContent>
  );

  const TopCornerCloseIconLayout = (
    <AnchoredAlertContentWithTopCornerIcon>
      <FlexContainer>
        <NoMarginCloseIcon
          aria-label={closeButtonLabel}
          onClick={handleCloseClicked}
          $styleType={type}
          type="button"
          taktFallbackId={createStormTaktId('close-icon')}
        >
          <CloseIcon type={times} />
        </NoMarginCloseIcon>
      </FlexContainer>
      <ContentContainerWithTopCornerIcon>
        <div>{message ? <AnchoredAlertMessage>{message}</AnchoredAlertMessage> : children}</div>
      </ContentContainerWithTopCornerIcon>
      { footer && (
        <FlexContainer>
          { footer }
        </FlexContainer>
      )}
    </AnchoredAlertContentWithTopCornerIcon>
  );

  return (
    <AnchoredAlertScrollContainer>
      {closeIconPosition === 'topCorner' ? TopCornerCloseIconLayout : DefaultLayout}
    </AnchoredAlertScrollContainer>
  );
};

AnchoredAlertContentContainer.propTypes = {
  handleCloseClicked: PT.func.isRequired,
  children: PT.node as PT.Validator<ReactNode>,
  closeButtonLabel: PT.string,
  closeIconPosition: PT.oneOf(['default', 'topCorner']),
  footer: PT.node as PT.Validator<ReactNode>,
  message: PT.oneOfType([
    PT.string,
    PT.node,
  ]) as PT.Validator<string | ReactNode>,
  type: PT.oneOf<ThemeType>(['light', 'dark', 'blue']),
  withCloseButton: PT.bool,
};

AnchoredAlertContentContainer.defaultProps = {
  children: undefined,
  closeButtonLabel: 'Close Tooltip',
  closeIconPosition: 'default',
  footer: undefined,
  message: '',
  type: 'light',
  withCloseButton: true,
};

export default AnchoredAlertContentContainer;
