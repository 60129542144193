import React, { FC, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { times } from '@amzn/storm-ui-icons-v3';
import { useIntl, IntlProvider, IntlComponentProps } from '@amzn/storm-ui-intl-v3';
import Icon from '../../Icon';
import TextButton, { TextButtonProps } from '../TextButton';
import { getTranslationComponent } from '../../i18n/CloseButtonTranslation';

const TextButtonWrapper = styled(TextButton)`
  background: transparent;
  color: ${({ theme }) => theme.button.close.color};
  font-size: ${({ theme }) => theme.typography.size.medium};

  :hover {
    color: ${({ theme }) => theme.button.close.colorHover};
    cursor: pointer;
  }
`;

export interface CloseButtonContentProps extends Omit<TextButtonProps, 'ref'|'size'|'onError'|'onReset'> {
  className?: string;
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'sm' | 'lg';
}

const CloseButtonContent: FC<CloseButtonContentProps> = ({
  className,
  onClick,
  label,
  size = 'sm',
  ...rest
}) => {
  const { formatMessage } = useIntl();
  return (
    <TextButtonWrapper
      {...rest}
      className={className}
      tabIndex={0}
      onClick={onClick}
      aria-label={label || formatMessage({ id: 'close_button_label' })}
      type="button"
    >
      <Icon type={times} size={size} />
    </TextButtonWrapper>
  );
};

export interface CloseButtonProps extends CloseButtonContentProps, IntlComponentProps {
  /**
   * @defaultValue `""`
   */
  className?: string;
  /**
   * @defaultValue `undefined`
   */
  label?: string;
  /**
   * @defaultValue `() => undefined`
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * @defaultValue `"sm"`
   */
  size?: 'sm' | 'lg';
  /**
   * @defaultValue `"en-US"`
   */
  locale?: string;
}

const CloseButton = ({
  locale,
  onError,
  fallback,
  onReset,
  ...rest
}: CloseButtonProps) => {
  const translations = getTranslationComponent(locale);
  return (
    <IntlProvider
      onError={onError}
      resetKey={locale}
      fallback={fallback}
      onReset={onReset}
      translations={translations}
    >
      <CloseButtonContent {...rest} />
    </IntlProvider>
  );
};

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  locale: PropTypes.string,
};

CloseButton.defaultProps = {
  className: '',
  label: undefined,
  onClick: () => undefined,
  size: 'sm',
  locale: 'en-US',
};

export default CloseButton;
