/* eslint-disable id-length */
import { palette } from '../base/color';
import { SurfaceThemeOverrides } from './surface';

const surfaceDarkStyles: SurfaceThemeOverrides = {
  link: {
    color: palette.white,
    hoverColor: palette.white,
    decoration: 'underline',
    overrideAuiLinkStyle: true,
  },
  surface: {
    backgroundColor: palette.squidInk,
  },
  typography: {
    color: {
      base: palette.white,
    },
    body: {
      bodyFontColor: palette.white,
    },
    a: `
      color: ${palette.white};
      text-decoration: underline;

      :hover{
        color: ${palette.white};
        text-decoration: underline;
      }
    `,
  },
  focusVisible: {
    borderColor: palette.white,
    outlineColor: palette.white,
    boxShadowColor: 'rgba(255,255,255,0.50)',
  },
  icon: {
    color: palette.white,
    hover: {
      color: palette.white,
    },
  },
  form: {
    input: {
      bg: palette.gray[0],
    },
    label: {
      color: palette.gray[0],
    },
  },
};

export default surfaceDarkStyles;
