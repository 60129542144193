import React, {
  ReactNode,
  FC,
  RefObject,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { angleInlineStart } from '@amzn/storm-ui-icons';
import { MergeElementProps, PreventScroll, useDetectVirtualKeyboard } from '@amzn/storm-ui-utils';
import {
  Content,
  CloseButton,
  CloseButtonIcon,
  Header,
  Shroud,
} from './SecondaryView.styles';
import View from './View';
import InlinePortalProvider from '../Portal/InlinePortalProvider';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { PaddingOptions } from '../types/PaddingOptions';
import { useTaktId, createStormTaktId } from '../TaktIdContext';

export function createSetSizeToVisualViewport(containerRef: React.RefObject<HTMLDivElement>) {
  return function setSizeToVisualViewport(): void {
    if (containerRef.current instanceof HTMLDivElement && window.visualViewport) {
      // To fix issue caused by virtual keyboard
      const { height, width } = window.visualViewport;
      const { style } = containerRef.current;
      style.height = `${height}px`;
      style.width = `${width}px`;
    }
  };
}

export interface OverlayProps extends MergeElementProps<'div'> {
  fadeDuration: number;
  transitionState: TransitionStatus;
  handleKeyDown: KeyboardEventHandler;
  handleKeyUp: KeyboardEventHandler;
  containerRef: RefObject<HTMLDivElement>;
  onClose?: () => void;
  closeButtonRef: RefObject<HTMLButtonElement>;
  closeButtonLabel?: ReactNode | string;
  closeButtonProps?: Record<string, unknown>;
  padding: PaddingOptions;
  children: ReactNode;
}

const Overlay: FC<React.PropsWithChildren<OverlayProps>> = ({
  fadeDuration,
  transitionState,
  handleKeyDown,
  handleKeyUp,
  containerRef,
  onClose,
  closeButtonRef,
  closeButtonLabel,
  closeButtonProps,
  padding,
  children,
  ...rest
}: OverlayProps) => {
  const { getDataTaktAttributes: getCloseButtonDataTaktAttributes } = useTaktId({ taktId: closeButtonProps?.taktId as (string | undefined), fallbackId: createStormTaktId('close-button') });
  // bool to track keyboard open state locally.
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isVirtualKeyboardOpen] = useDetectVirtualKeyboard();

  useEffect(() => {
    if (typeof window !== 'undefined' && 'visualViewport' in window && window.visualViewport) {
      const { visualViewport } = window;

      const setSizeToVisualViewport = createSetSizeToVisualViewport(containerRef);

      // if the keyboard is opened, set the local state to open
      if (isVirtualKeyboardOpen) {
        setIsKeyboardOpen(true);
      }

      // this code block is executed if the keyboard is either open,
      // or was open and is now closed (tracked by the local isKeyboardOpen state)
      if (isVirtualKeyboardOpen || isKeyboardOpen) {
        setIsKeyboardOpen(isVirtualKeyboardOpen);
        visualViewport.addEventListener('resize', setSizeToVisualViewport);
        setSizeToVisualViewport();
      }

      /* do any cleanup */
      return () => {
        visualViewport.removeEventListener('resize', setSizeToVisualViewport);
      };
    }
    return () => undefined;
  }, [containerRef, isKeyboardOpen, isVirtualKeyboardOpen]);

  return (
    <PreventScroll>
      <Shroud $transitionState={transitionState}>
        <View
          {...rest}
          role="presentation"
          $fadeDuration={fadeDuration}
          $fadeState={transitionState}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          ref={containerRef}
        >
          <InlinePortalProvider>
            <Header>
              <CloseButton
                tabIndex={0}
                onClick={onClose}
                ref={closeButtonRef}
                type="button"
                {...getCloseButtonDataTaktAttributes({
                  taktValue: closeButtonProps?.taktValue as (Record<string, unknown> | undefined),
                })}
                {...closeButtonProps}
              >
                <CloseButtonIcon type={angleInlineStart} />
                {closeButtonLabel}
              </CloseButton>
            </Header>
            <Content padding={padding}>
              {children}
            </Content>
          </InlinePortalProvider>
        </View>
      </Shroud>
    </PreventScroll>
  );
};

Overlay.defaultProps = {
  closeButtonProps: undefined,
  onClose: undefined,
  closeButtonLabel: undefined,
};

export default Overlay;
