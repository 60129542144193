import React, {
  ComponentProps, ComponentPropsWithoutRef, PureComponent,
} from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';

export interface BaseListItemProps extends TaktProps, ComponentPropsWithoutRef<'li'> {}

class ListItem extends PureComponent<BaseListItemProps> {
  static propTypes = {
    children: PT.node.isRequired,
  }

  render(): JSX.Element {
    const {
      children,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('list-item')}>
        {({ getDataTaktAttributes }) => (
          <li {...getDataTaktAttributes()} {...rest}>{children}</li>
        )}
      </TaktIdConsumer>
    );
  }
}

const StyledListItem = styled(ListItem)``;

StyledListItem.displayName = 'ListItem';

export interface ListItemProps extends ComponentProps<typeof StyledListItem>, TaktProps {}

export default StyledListItem;
