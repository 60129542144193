import PT from 'prop-types';
import React, {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import {
  TaktIdConsumerProps,
  TaktIdContextProps,
  TaktIdParams,
  TaktIdProviderProps,
  UseTaktIdProps,
} from './TaktIdContext.types';
import { getDataTaktAttributesFunction } from './TaktIdContext.utils';

const DEFAULT_DELIMITER = ':';

const TaktIdContext = createContext<TaktIdContextProps>({
  taktFeatureChain: undefined,
  delimiter: undefined,
  useFallbacks: true,
  taktPrefix: undefined,
  taktValues: undefined,
});

// ---------------------------------------------

const TaktIdProvider:FC<PropsWithChildren<TaktIdProviderProps>> = ({
  children,
  taktId,
  customDelimiter_DO_NOT_USE, // eslint-disable-line camelcase
  useFallbacks,
  fallbackId,
  taktIdPrefix,
  taktValue,
}) => {
  const context = useContext(TaktIdContext);
  /**
   * if this is the top of the feature chain, and no taktId was provided,
   * disregard this provider
   */
  if (!context.taktFeatureChain && !taktId) {
    return (<>{children}</>);
  }
  /**
   * use the top level delimiter if set,
   * else we're at the top level and use the one passed in,
   * else fallback to default ':'
   */
  const delim = context.delimiter ?? customDelimiter_DO_NOT_USE ?? DEFAULT_DELIMITER; // eslint-disable-line camelcase, max-len
  // set whether fallbacks should be used based on having an existing context & useFallbacks flag
  const canUseFallbacks = context.taktFeatureChain ? context.useFallbacks : useFallbacks;
  // determine which Id to append to the chain
  const appendedId = taktId || (canUseFallbacks ? fallbackId : undefined);
  // if no id was provided that can be used, do not append anything with this provider
  if (!appendedId) {
    return (<>{children}</>);
  }

  // append any new taktValues to the stored taktValue object
  const appendedValue = {
    ...(context.taktValues),
    ...taktValue,
  };

  // replace the prefix if one is passed in
  const updatedPrefix = taktIdPrefix ?? context.taktPrefix ?? undefined;

  // if previous prefix exists, append to it, else use passed in prefix to start context chain
  const appendedChain = context.taktFeatureChain ? `${context.taktFeatureChain}${delim}${appendedId}` : appendedId;

  return (
    <TaktIdContext.Provider value={{
      taktFeatureChain: appendedChain,
      delimiter: delim,
      useFallbacks: canUseFallbacks,
      taktValues: appendedValue,
      taktPrefix: updatedPrefix,
    }}
    >
      {children}
    </TaktIdContext.Provider>
  );
};

TaktIdProvider.propTypes = {
  taktId: PT.string,
  customDelimiter_DO_NOT_USE: PT.string,
  useFallbacks: PT.bool,
  fallbackId: PT.string,
  taktIdPrefix: PT.string,
  // eslint-disable-next-line react/forbid-prop-types
  taktValue: PT.objectOf(PT.any),
}

TaktIdProvider.defaultProps = {
  taktId: undefined,
  customDelimiter_DO_NOT_USE: undefined,
  useFallbacks: true,
  fallbackId: undefined,
  taktIdPrefix: undefined,
  taktValue: undefined,

}

// --------------------------------------------

export const getTaktIdParams = ({
  taktIdContext,
  taktId,
  fallbackId,
  taktIdPrefix,
  taktValue,
}: {
  taktIdContext: TaktIdContextProps;
  taktId?: string;
  fallbackId?: string;
  taktIdPrefix?: string;
  taktValue?: Record<string, unknown>;
}): TaktIdParams => {
  // determine the taktId to append to use
  // if there is no parent chain, do not append anything or return anything from the context
  const hasParentChain = taktIdContext.taktFeatureChain;
  const updatedPrefix = hasParentChain ? taktIdPrefix ?? taktIdContext.taktPrefix : taktIdPrefix;
  const dataTaktId = hasParentChain ? (taktId || (taktIdContext.useFallbacks ? fallbackId : undefined)) : taktId;
  const dataTaktValue = hasParentChain ? { ...taktIdContext.taktValues, ...taktValue } : taktValue;

  return {
    taktIdPrefix: updatedPrefix,
    unprefixedTaktId: dataTaktId,
    dataTaktId: updatedPrefix ? `${updatedPrefix}-${dataTaktId}` : dataTaktId,
    dataTaktValue,
    dataTaktFeature: taktIdContext.taktFeatureChain,
    getDataTaktAttributes: getDataTaktAttributesFunction({
      taktIdContext,
      taktIdPrefix,
      taktId: dataTaktId,
      taktValue,
    }),
  };
}

/**
 * TaktIdContext consumer that vends a function: (contextValue) => (Children)
 */
const TaktIdConsumer: FC<TaktIdConsumerProps> = ({
  children,
  taktId,
  fallbackId,
  taktValue,
  taktIdPrefix,
}): JSX.Element => (
  <TaktIdContext.Consumer>
    {(taktIdContext: TaktIdContextProps) => {
      const {
        dataTaktId,
        unprefixedTaktId,
        dataTaktValue,
        dataTaktFeature,
        getDataTaktAttributes,
      } = getTaktIdParams({
        taktIdContext,
        taktId,
        fallbackId,
        taktValue,
        taktIdPrefix,
      });

      // if there is no parent chain, do not append anything or return anything
      if (!dataTaktFeature) {
        return (
          <>
            {children({
              dataTaktId,
              dataTaktValue,
              getDataTaktAttributes,
            })}
          </>
        );
      }
      return (
        <TaktIdProvider taktId={unprefixedTaktId} taktIdPrefix={taktIdPrefix} taktValue={dataTaktValue}>
          {children({
            dataTaktId,
            dataTaktValue,
            dataTaktFeature,
            getDataTaktAttributes,
          })}
        </TaktIdProvider>
      );
    }}
  </TaktIdContext.Consumer>
);

TaktIdConsumer.propTypes = {
  taktId: PT.string,
  fallbackId: PT.string,
  taktIdPrefix: PT.string,
  // eslint-disable-next-line react/forbid-prop-types
  taktValue: PT.objectOf(PT.any),
}

TaktIdConsumer.defaultProps = {
  taktId: undefined,
  fallbackId: undefined,
  taktIdPrefix: undefined,
  taktValue: undefined,
}

/**
 * React hook to append an taktId to the context
 * without modifying the existing context value.
 *
 * If no context exists, this will return undefined.
 *
 * @param taktId: string - value returned as id if context exists
 * @param fallbackId: string - value returned as id if taktId is undefined and useFallbacks is true
 * @param taktValue: Record<string, unknown> - object appended to the context.taktValues and used as the data-takt-value
 * @param taktIdPrefix: string - a string that prefixes all lower level taktIds
 *
 * @returns params: TaktIdParams - object containing:
 *   - dataTaktId?: string - taktId value to be used. May be `undefined` if no context existed above.
 *   - dataTaktFeature?: string - delimited chain of Ids from all TaktIdProviders & TaktIdConsumers
 *     above this hook call in the hierarchy
 *   - getDataTaktAttributes: GetDataTaktAttributes
 */
const useTaktId = (props:UseTaktIdProps):TaktIdParams => {
  const taktIdContext = useContext(TaktIdContext);
  if (props === undefined || typeof props === 'string') {
    return {
      getDataTaktAttributes: () => ({
        'data-takt-id': undefined,
        'data-takt-feature': undefined,
        'data-takt-value': undefined,
      }),
    };
  }
  const {
    taktId,
    fallbackId,
    taktValue,
    taktIdPrefix,
  } = props;
  return getTaktIdParams({
    taktIdContext,
    taktId,
    fallbackId,
    taktValue,
    taktIdPrefix,
  });
}

/**
 * For non-exported components already have taktId prop but need a new fallback id
 * This should only be used on non-exported components, otherwise we expose the fallbackId prop.
 */
function withTaktFallbackId<ComponentProps extends { taktId?: string }>(
  Component: React.ComponentType<ComponentProps>,
) {
  const ComponentWithTaktFallbackId = (props: ComponentProps & {
    // eslint-disable-next-line react/require-default-props
    taktFallbackId?: string
    taktValue?: Record<string, unknown>,
  }) => {
    const {
      taktId,
      taktFallbackId,
      taktValue,
      ...restProps
    } = props;
    // remove the prefix so we can set it through the `useTaktId` calls inside the components
    const { unprefixedTaktId, dataTaktValue } = useTaktId({ taktId, fallbackId: taktFallbackId, taktValue });

    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return (<Component taktId={unprefixedTaktId} taktValue={dataTaktValue} {...restProps as ComponentProps} />)
  };

  return ComponentWithTaktFallbackId;
}

export {
  TaktIdContext,
  TaktIdProvider,
  TaktIdConsumer,
  useTaktId,
  withTaktFallbackId,
};
