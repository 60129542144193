/* eslint-disable consistent-return */
import { ModifierArguments, State, Placement } from '@popperjs/core';

function setPlacement(state: State, placement: Placement): State {
  return Object.assign(state, { placement });
}

/*
 * textDirectionSensitive is a modifier for popper.js. It reads the text direction of the
 * reference element and mirrors placement direction when detecting rtl mode.
 */
export default function textDirectionSensitiveFn({ state }: ModifierArguments<object>): State | void {
  /* test for the existence of the reference element */
  const reference = state?.elements?.reference;
  if (!(reference instanceof HTMLElement)) {
    return;
  }

  /* test for the existence of the getComputedStyle function and get a reference to it */
  const getComputedStyle = reference?.ownerDocument?.defaultView?.getComputedStyle;
  if (!((typeof getComputedStyle) === 'function') || !getComputedStyle) {
    return;
  }

  /* get text direction of the reference element */
  const direction = getComputedStyle(reference)?.direction;

  /* do nothing when non-RTL direction is detected */
  if (!(direction === 'rtl')) {
    return;
  }

  /* Mirror placements on rtl mode */
  switch (state?.options?.placement) {
    case 'left-start':
      return setPlacement(state, 'right-start');
    case 'left':
      return setPlacement(state, 'right');
    case 'left-end':
      return setPlacement(state, 'right-end');
    case 'right-start':
      return setPlacement(state, 'left-start');
    case 'right':
      return setPlacement(state, 'left');
    case 'right-end':
      return setPlacement(state, 'left-end');
    case 'top-start':
      return setPlacement(state, 'top-end');
    case 'top-end':
      return setPlacement(state, 'top-start');
    case 'bottom-start':
      return setPlacement(state, 'bottom-end');
    case 'bottom-end':
      return setPlacement(state, 'bottom-start');
    default:
  }
}
