import React, { createContext, useContext, ReactNode } from 'react';
import useIsMobile from '../theme/MobileThemeProvider/useIsMobile';

const DropdownIsMobileContext = createContext<boolean>(false);

export interface DropdownIsMobileProvider {
  mobileFullScreen?: boolean;
  children: ReactNode;
}

export const DropdownIsMobileProvider = ({ mobileFullScreen, children }: DropdownIsMobileProvider) => {
  const isMobile = useIsMobile();
  const dropdownIsMobile = (mobileFullScreen && isMobile) || false;

  return (
    <DropdownIsMobileContext.Provider value={dropdownIsMobile}>
      {children}
    </DropdownIsMobileContext.Provider>
  );
}
DropdownIsMobileProvider.defaultProps = {
  mobileFullScreen: false,
}

export const useDropdownIsMobile = () => {
  const isMobile = useContext(DropdownIsMobileContext);

  return isMobile;
}

export interface DropdownIsMobileConsumerProps {
  children: (isMobile: boolean) => ReactNode;
}

export const DropdownIsMobileConsumer = ({ children }: DropdownIsMobileConsumerProps) => {
  const isMobile = useDropdownIsMobile();

  return (
    <>
      {children(isMobile)}
    </>
  );
}
