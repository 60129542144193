import React from 'react';
import { useIntl } from '@amzn/storm-ui-intl-v3';
import { createStormTaktId, useTaktId } from '../../TaktIdContext';
import Button, { ButtonProps } from '../../Button/Button';

export interface AbandonmentDialogLeaveButtonProps extends Omit<ButtonProps, 'onClick'> {
  /**
   * Function to handle 'Leave Page' action
   */
  onLeave: () => void;
}

const LeaveButton: React.FC<AbandonmentDialogLeaveButtonProps> = ({
  onLeave, taktId, children, ...rest
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const { dataTaktId } = useTaktId({ taktId, fallbackId: createStormTaktId('leave-button') });

  return (
    <Button primary {...rest} onClick={onLeave} taktId={dataTaktId}>
      {children ?? formatMessage({ id: 'leave_page' })}
    </Button>
  )
}

export default LeaveButton;
