import styled, { css } from 'styled-components';
import {
  isMobileStyleMixin as isMobile,
} from '@amzn/storm-ui';

const smallScreen = '@media (max-width: 576px)';
const adaptiveHelpTipWidth = '19px';
const labelMargin = '2px';

const FormRowDiv = styled.div`
  margin: 0 0 ${({ theme }) => theme.spacing.base} 0;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;

  // Stacked
  ${smallScreen} {
    flex-direction: column;
  }

  ${isMobile(css`
    flex-direction: column;
  `)}
`;

interface LeftColumnProps {
  $topSpacing?: string;
}

const LeftColumn = styled('div')<LeftColumnProps>`
  position: relative;
  top: ${({ $topSpacing }) => $topSpacing};
  flex: 0 0 33.33%;
  display: flex;
  justify-content: flex-end;
  text-align: end;
  max-width: 167px;

  // Stacked
  ${smallScreen} {
    top: 0px;
    max-width: 100%;
    justify-content: flex-start;
    text-align: start;
  }

  ${isMobile(css`
    top: 0px;
    max-width: 100%;
    justify-content: flex-start;
    text-align: start;
  `)}
`;

const RightColumn = styled.div`
  flex: 1 1 100%;
  margin-inline-start: ${({ theme }) => theme.spacing.small};
  margin-inline-end: ${({ theme }) => theme.spacing.xlarge};

  // Stacked
  ${smallScreen} {
    margin-inline-start: 0;
  }

  ${isMobile(css`
    margin-inline-start: 0;
  `)}
`;

const ControlComponent = styled.div`
  :not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.base};
  }
  label,
  label&,
  legend,
  legend& {
    font-weight: 400;
  }

  ${isMobile(css`
    label,
    label&&,
    legend,
    legend&& {
      margin-bottom: 1px;
      padding-bottom: 0px;
      font-weight: 400;
    }
  `)}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-end: ${labelMargin};
  margin-inline-start: 0;
  ${({ theme }) => theme.typography.base};

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
`;

const HelpTipPlaceholder = styled.div`
  padding: 9.5px;
`;

const Label = styled.div`
  display: flex;
  justify-content: flex-end;

  // Stacked
  ${smallScreen} {
    justify-content: flex-start;

    label,
    label& {
      margin-bottom: ${labelMargin};
    }
  }

  ${isMobile(css`
    justify-content: flex-start;

    label,
    label& {
      margin-bottom: ${labelMargin};
    }
  `)}
`;

const OptionalTag = styled('label')`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.boulder};
  margin-inline-end: ${adaptiveHelpTipWidth};
  margin-inline-start: 0;

  label,
  label&,
  legend,
  legend& {
    font-weight: 400;
    padding-inline-start: 0;
  }

  /* Stacked */
  ${smallScreen} {
    margin-inline-end: 0px;
    margin-inline-start: 0px;
  }

  ${isMobile(css`
    margin-inline-end: 0px;
    margin-inline-start: 0px;
  `)}
`;

/*  Offset the default padding in AdaptiveHelpTip on mobile */
const AdaptiveHelpTipContainer = styled.div`
  ${isMobile(css`
    button {
      padding: 0 7px;
      transform: translateY(-2px);
    }
  `)}
`;

export {
  FormRowDiv,
  LeftColumn,
  RightColumn,
  ControlComponent,
  LabelContainer,
  HelpTipPlaceholder,
  Label,
  OptionalTag,
  AdaptiveHelpTipContainer,
};
