import PT, { checkPropTypes, Validator } from 'prop-types';

/**
 * This prop-type validator can be used to validate shadow root instance
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isShadowRootInstance(props: any, propName: string, componentName: string): void | null {
  // validate only if browser supports shadow DOM
  if (typeof window.ShadowRoot === 'function') {
    return checkPropTypes(
      { [propName]: PT.instanceOf(window.ShadowRoot) },
      props,
      propName,
      componentName,
    );
  }

  return null;
}

export default isShadowRootInstance as Validator<HTMLElement>;
