/**
 * This are a set of utility functions for implementing a spinner input.
 */

/**
 * Step forward to the next option after the supplied value.
 * @param  {string[]} options   all option in the spinner
 * @param  {string} value       supplied value
 * @return {string}             the next option after the supplied value.
 */
export const incrementOption = (options: string[], value: string | null = ''): string => {
  const valueIdx = options.indexOf(value as string);
  if (valueIdx === -1) {
    return options[0];
  }
  if (options.length <= (valueIdx + 1)) {
    return options[0];
  }
  return options[valueIdx + 1];
};

/**
 * Step backward to previous next option after the supplied value.
 * @param  {string[]} options all option in the spinner
 * @param  {string}   value   supplied value
 * @return {string}           the previous option after the supplied value.
 */
export const decrementOption = (options: string[], value: string | null = ''): string => {
  const valueIdx = options.indexOf(value as string);

  if (valueIdx === -1) {
    return options[options.length - 1];
  }
  if (valueIdx <= 0) {
    return options[options.length - 1];
  }
  return options[valueIdx - 1];
};

/**
 * Set the value of of the spinner to null.
 *
 * @return {null}         the null value of the spinner.
 */
export const clearOption = ():null => null;

type createToJumpOptionReturn = (options: string[], key: string, maxLength: number) =>
  { matchType: string, foundOption: string | null };
/**
 * Create a function that can jump to any option in the spinner based on the last keyboard inputs.
 * @return {function} A function that takes a keyboard letter or number input and returns the
 * possible value from options that it matches.
 */
// eslint-disable-next-line max-len
export const createJumpToOption = ():createToJumpOptionReturn => {
  let inputBuffer = '';
  let foundOption: string | null = null;

  /**
   * A function that takes a keyboard letter or number input and returns the possible value from
   * options that it matches.
   *
   * @param  {string[]} options   Array of all options
   * @param  {string}   key       letter or number from the keyboard input
   * @param  {number}   maxLength the maximum length of a string match
   * @return {object}   the closes match with the match type
   */
  return (options: string[], key: string, maxLength: number):{ matchType: string, foundOption: string | null } => {
    let matchType = 'none';

    const lowerCaseKey = key.toLowerCase();

    if (lowerCaseKey.length > 1) {
      return { matchType, foundOption: null };
    }

    const lowerCaseOptions = options.map(option => option?.toLowerCase());

    /*
     * If we have a character key-press do a preliminary search to see if it exists anywhere in
     * the options.
     */
    const preliminaryMatchIdx = lowerCaseOptions
      .findIndex(option => option.includes(lowerCaseKey));

    if (preliminaryMatchIdx === -1) {
      return { matchType, foundOption };
    }
    matchType = 'preliminary';
    foundOption = options[preliminaryMatchIdx];

    let testBuffer = `${inputBuffer}${lowerCaseKey}`.slice(-maxLength);
    inputBuffer = testBuffer;
    let foundOptionIdx = -1;

    const optionFinder = (option: string): boolean => option?.includes(testBuffer);
    while (testBuffer.length > 1 && foundOptionIdx === -1) {
      foundOptionIdx = lowerCaseOptions.findIndex(optionFinder);

      if (foundOptionIdx === -1) {
        testBuffer = testBuffer.slice(1);
      }
    }

    if (testBuffer.length === maxLength && foundOptionIdx !== -1) {
      matchType = 'full';
      foundOption = options[foundOptionIdx];
    }
    return { matchType, foundOption };
  };
};
