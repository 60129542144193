import React, {
  useCallback,
  ComponentProps,
  forwardRef,
  ReactNode,
  useRef,
  RefAttributes,
  MouseEvent,
} from 'react';
import PropTypes from 'prop-types';
import { Transition, TransitionStatus } from 'react-transition-group';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils-v3';
import Portal from '../Portal';
import CloseButton from '../Button/CloseButton/CloseButton';
import { Overlay } from './Overlay';
import { TaktProps } from '../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';

/**
 * Expose all the ContextualDrawer Props and the passthrough props of the `<div>` element
 */
export interface ContextualDrawerProps extends MergeStyledComponentElementProps<'div'>, TaktProps {
  /**
   * The contents of the Drawer.
   */
  children: ReactNode;
  /**
   * Customized text label for close button.
   * @defaultValue `""`
   */
  closeButtonLabel?: string;
  /**
   * Props that are applied to the CloseButton component.
   * @defaultValue `undefined`
   */
  closeButtonProps?: Record<string, unknown>;
  /**
   * The state of the drawer. If true, the drawer will be open, otherwise it will be closed.
   * @defaultValue `false`
   */
  isOpen?: boolean;
  /**
   * Called when a user presses the close button.
   * @defaultValue `() => undefined`
   */
  onClose?: ComponentProps<typeof CloseButton>['onClick'];
  /**
   * Conditionally renders the close button. If true, the close button will be rendered,
   * otherwise it will not be rendered.
   * @defaultValue `true`
   */
  withCloseButton?: boolean;
}

/*
 * ContextualDrawerComponent is a workaround for the types generated by forwardRef() when wrapping a type
 * created by MergeStyledComponentElementProps. By default, the type generated will have all the known
 * attributes of the HTML listed out. This creates a problem for older version of the lib.dom.d.ts that
 * ships with typescript because it will be missing any new attributes. e.g. the `nonce` attribute
 * was added TS 3.2.0, so earlier versions of TS do not recognize it as optional.
 */
export type ContextualDrawerComponent = React.ForwardRefExoticComponent<
Omit<ContextualDrawerProps, 'ref'> & RefAttributes<HTMLDivElement>
>

const ContextualDrawer:ContextualDrawerComponent = forwardRef<
  HTMLDivElement,
  ContextualDrawerProps
  >(({
    children,
    closeButtonLabel,
    closeButtonProps,
    isOpen,
    onClose,
    withCloseButton,
    taktId,
    taktValue,
    ...rest
  }, ref) => {
    const drawerContainerRef = useRef<HTMLDivElement>(null);

    const handleCloseButtonClicked = useCallback((event: unknown) => {
      if (onClose) {
        onClose(event as unknown as MouseEvent<HTMLButtonElement>);
      }
    }, [onClose]);

    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('contextual-drawer')}>
        <Transition
          timeout={150}
          appear
          in={isOpen}
          nodeRef={drawerContainerRef}
        >
          {(slideState: TransitionStatus) => {
            if (slideState === 'exited') return null;

            return (
              <Portal>
                <Overlay
                  {...rest}
                  ref={ref}
                  drawerContainerRef={drawerContainerRef}
                  slideState={slideState}
                  withCloseButton={withCloseButton}
                  closeButtonLabel={closeButtonLabel}
                  handleCloseButtonClicked={handleCloseButtonClicked}
                  closeButtonProps={closeButtonProps}
                >
                  {children}
                </Overlay>
              </Portal>
            );
          }}
        </Transition>
      </TaktIdProvider>
    );
  });

ContextualDrawer.propTypes = {
  /**
   * The contents of the Drawer.
   */
  children: PropTypes.node.isRequired as PropTypes.Requireable<ReactNode>,
  /**
   * Customized text label for close button.
   */
  closeButtonLabel: PropTypes.string,
  /**
   * Props that are applied to the CloseButton component.
   */
  closeButtonProps: PropTypes.objectOf(PropTypes.any),
  /**
   * The state of the drawer. If true, the drawer will be open, otherwise it will be closed.
   */
  isOpen: PropTypes.bool,
  /**
   * Called when a user presses the close button.
   */
  onClose: PropTypes.func,
  /**
   * Conditionally renders the close button. If true, the close button will be rendered,
   * otherwise it will not be rendered.
   */
  withCloseButton: PropTypes.bool,
};

ContextualDrawer.defaultProps = {
  closeButtonLabel: '',
  closeButtonProps: undefined,
  isOpen: false,
  onClose: () => undefined,
  withCloseButton: true,
};

ContextualDrawer.displayName = 'ContextualDrawer';

export default ContextualDrawer;
