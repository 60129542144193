import { Placement } from '@popperjs/core';

const calculateOffsetSkidding = (
  withArrow: boolean | undefined,
  placement: Placement,
  distance: number,
  offsetSkidding: number,
): number => {
  if (placement === 'left-start' || placement === 'right-start') {
    const value = (withArrow ? distance : 0) + offsetSkidding;
    return -1 * value;
  }

  if (placement === 'left-end' || placement === 'right-end') {
    const value = (withArrow ? distance : 0) + offsetSkidding;
    return value;
  }

  if (placement === 'top-start' || placement === 'bottom-start') {
    const value = (withArrow ? distance + 4 : 0) + offsetSkidding;
    return -1 * value;
  }

  if (placement === 'top-end' || placement === 'bottom-end') {
    const value = (withArrow ? distance + 4 : 0) + offsetSkidding;
    return value;
  }

  return 0;
};

export default calculateOffsetSkidding;
