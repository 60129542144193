import React, {
  createRef,
  KeyboardEvent,
  MouseEvent,
  PureComponent,
  ReactNode,
  ReactNodeArray,
  RefObject,
} from 'react';
import { Transition } from 'react-transition-group';
import PT from 'prop-types';
import { times } from '@amzn/storm-ui-icons';
import {
  getFocusableElements,
  trapTabFocus,
  getElementFromRef,
  MergeElementProps,
  noop,
  PreventScroll,
} from '@amzn/storm-ui-utils';
import type { TaktProps } from '../types/TaktProps';
import { TaktIdProvider, createStormTaktId, TaktIdConsumer } from '../TaktIdContext';
import Text from '../Text';
import Portal from '../Portal';
import InlinePortalProvider from '../Portal/InlinePortalProvider';

import {
  Container,
  OuterWrapper,
  Wrapper,
  Header,
  Footer,
  Content,
} from './Modal.styles';
import { CloseButton, CloseButtonProps, CloseIcon } from './CloseButton';
import { TransitionStatus } from '../types/react-transition-group/Transition';
import { PaddingOptions } from '../types/PaddingOptions';

export interface ModalProps extends TaktProps, MergeElementProps<'div'> {
  /**
     * Control open / close of modal. It will animate automatically on change.
     * @defaultValue `false`
     */
  isOpen?: boolean,
  /**
     * No header will be rendered if this is blank, UNLESS hasCloseButton is true.
     * @defaultValue `undefined`
     */
  header?: ReactNode | ReactNodeArray,
  /**
     * Most modals will have a primary and secondary action here, such as "save" and "cancel".
     * @defaultValue `undefined`
     */
  footer?: ReactNode | ReactNodeArray,
  /**
     * Controls display of the close button.
     * @defaultValue `true`
     */
  hasCloseButton?: boolean,
  /**
     * Customized text label for close button.
     * @defaultValue `"Close modal"`
     */
  closeButtonLabel?: string,
  /**
     * Props that are applied to the CloseButton component.
     * if `label` is set, it will override closeButtonLabel prop
     * @defaultValue `undefined`
     */
  closeButtonProps?: Partial<CloseButtonProps>,
  /**
     * Fired when any close action occurs, such as: clicking outside, hitting close button,
     * or hitting escape.
     * @defaultValue `() => undefined`
     */
  onClose?: () => void,
  /**
     * Sets the padding around the modal's content.
     * @defaultValue `undefined`
     */
  padding?: PaddingOptions,
  /**
     * Use if you need to add a class name to the inner content div.
     * @defaultValue `""`
     */
  contentClassName?: string,
  /**
     * This should correspond to the ROOT level dom node id that the modal will render into.
     * When this prop is not explicitly passed a value, the modal will mount into the div with
     * id matching the portalElementId property in the popover object of the theme.
     * @defaultValue `undefined`
     */
  modalElementId?: string,
  /**
     * Pass a ref to an element here to use as the toggle element.
     * @defaultValue `undefined`
     */
  toggleEl?: RefObject<HTMLElement | undefined> | HTMLElement,
}

const fadeDuration = 50;
class Modal extends PureComponent<ModalProps> {
  private closeRef = createRef<HTMLButtonElement>();

  private wrapperRef = createRef<HTMLDivElement>();

  private modalContainerRef = createRef<HTMLDivElement>();

  static propTypes = {
    /**
     * Control open / close of modal. It will animate automatically on change.
     */
    isOpen: PT.bool,
    /**
     * The React nodes/nodes that are rendered as content of the Modal.
     */
    children: PT.oneOfType([
      PT.arrayOf(PT.node),
      PT.node,
    ]).isRequired,
    /**
     * No header will be rendered if this is blank, UNLESS hasCloseButton is true.
     */
    header: PT.oneOfType([
      PT.arrayOf(PT.node),
      PT.node,
    ]),
    /**
     * Most modals will have a primary and secondary action here, such as "save" and "cancel".
     */
    footer: PT.oneOfType([
      PT.arrayOf(PT.node),
      PT.node,
      PT.any,
    ]),
    /**
     * Controls display of the close button.
     */
    hasCloseButton: PT.bool,
    /**
     * Customized text label for close button.
     */
    closeButtonLabel: PT.string,
    /**
     * Props that are applied to the CloseButton component.
     * if `label` is set, it will override closeButtonLabel prop
     */
    closeButtonProps: PT.objectOf(PT.any),
    /**
     * Fired when any close action occurs, such as: clicking outside, hitting close button,
     * or hitting escape.
     */
    onClose: PT.func,
    /**
     * Sets the padding around the modal's content.
     */
    padding: PT.oneOf([
      'none',
      'micro',
      'mini',
      'small',
      'base',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
    ]),
    /**
     * Use if you need to add a class name to the inner content div.
     */
    contentClassName: PT.string,
    /**
     * This should correspond to the ROOT level dom node id that the modal will render into.
     * When this prop is not explicitly passed a value, the modal will mount into the div with
     * id matching the portalElementId property in the popover object of the theme.
     */
    modalElementId: PT.string,
    /**
     * Pass a ref to an element here to use as the toggle element.
     */
    toggleEl: PT.oneOfType([
      PT.instanceOf(typeof Element !== 'undefined' ? Element : Object),
      PT.shape({ current: PT.instanceOf(typeof Element !== 'undefined' ? Element : Object) }),
    ]),
  }

  static defaultProps = {
    closeButtonLabel: 'Close modal',
    closeButtonProps: undefined,
    contentClassName: '',
    footer: undefined,
    hasCloseButton: true,
    header: undefined,
    isOpen: false,
    modalElementId: undefined,
    onClose: noop,
    padding: undefined,
    toggleEl: undefined,
  }

  componentWillUnmount(): void {
    const { isOpen } = this.props;
    // Run `teardownModal()` if the Modal was open at unmount time.
    if (isOpen) {
      this.teardownModal();
    }
  }

  teardownModal = (): void => {
    // Bring focus back to the toggle element
    if (this.props.toggleEl) {
      const element = getElementFromRef(this.props.toggleEl);
      if (element?.focus) {
        element.focus();
      }
    }
  }

  handleKeyUp = (event: KeyboardEvent<HTMLDivElement>): void => {
    const { onClose } = this.props;

    if (event.key === 'Escape') {
      event.preventDefault();
      if (onClose) {
        onClose();
      }
    }
  }

  handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (this.modalContainerRef.current) {
      trapTabFocus(event, this.modalContainerRef.current);
    }
  }

  handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    const { target, currentTarget } = event;
    const { onClose } = this.props;

    if (target === currentTarget) {
      event.preventDefault();
      if (onClose) {
        onClose();
      }
    }
  }

  handleEntered = (): void => {
    const focusableEls = getFocusableElements(this.wrapperRef.current);
    if (focusableEls.length > 0) {
      focusableEls[0].focus();
    }
  }

  renderContents(state: TransitionStatus): JSX.Element {
    const {
      children,
      closeButtonProps,
      header,
      footer,
      hasCloseButton,
      contentClassName,
      onClose,
      padding,
      closeButtonLabel = 'Close modal',
      /* omitting from rest */
      taktId,
      taktValue,
      isOpen,
      modalElementId,
      ...rest
    } = this.props;

    return (
      <Container
        {...rest}
        onMouseDown={this.handleClick}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        role="presentation"
        ref={this.modalContainerRef}
        $fadeDuration={fadeDuration}
        $transitionState={state}
      >
        <PreventScroll>
          <OuterWrapper
            $transitionState={state}
            ref={this.wrapperRef}
          >
            <InlinePortalProvider>
              <Wrapper>
                {(header || hasCloseButton)
                && (
                  <Header>
                    <Text type="h1" styleAs="h4">{header}</Text>
                    {hasCloseButton && (
                      <TaktIdConsumer taktId={closeButtonProps?.taktId} taktValue={taktValue} fallbackId={createStormTaktId('close-button')}>
                        {({ getDataTaktAttributes }) => (
                          <CloseButton
                            closeButtonLabel={closeButtonLabel}
                            onClick={onClose}
                            ref={this.closeRef}
                            {...getDataTaktAttributes({ taktValue: closeButtonProps?.taktValue })}
                            {...closeButtonProps}
                          >
                            <CloseIcon type={times} />
                          </CloseButton>
                        )}
                      </TaktIdConsumer>
                    )}
                  </Header>
                )}
                <Content
                  className={contentClassName}
                  $padding={padding}
                >
                  {children}
                </Content>
                {footer && <Footer>{footer}</Footer>}
              </Wrapper>
            </InlinePortalProvider>
          </OuterWrapper>
        </PreventScroll>
      </Container>
    );
  }

  render(): JSX.Element {
    const {
      isOpen,
      modalElementId,
      taktId,
      taktValue,
    } = this.props;

    return (
      <Transition
        timeout={fadeDuration}
        appear
        in={isOpen}
        onExit={this.teardownModal}
        onEntered={this.handleEntered}
        nodeRef={this.modalContainerRef}
      >
        {state => {
          if (state === 'exited') {
            return null;
          }

          return (
            <Portal containerId={modalElementId}>
              <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('modal')}>
                {this.renderContents(state)}
              </TaktIdProvider>
            </Portal>
          );
        }}
      </Transition>
    );
  }
}

export default Modal;
