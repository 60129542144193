import React from 'react';
import { Locale } from 'date-fns';
import styled from 'styled-components';
import CalendarDay from './CalendarDay';
import { ISODate } from '../types';

export interface CalendarWeekProps {
  dates: Array<ISODate>;
  displayMonth: ISODate;
  locale: Locale;
}

const Row = styled('tr')`
  padding: unset;
`;

const Cell = styled('td')`
  padding: unset;
`;

const CalendarWeek = (props: CalendarWeekProps): JSX.Element => {
  const { dates, displayMonth, locale } = props;
  return (
    <Row role="row">
      {dates.map(date => (
        <Cell
          key={date}
          role="gridcell"
        >
          <CalendarDay date={date} displayMonth={displayMonth} locale={locale} />
        </Cell>
      ))}
    </Row>
  );
};

export default CalendarWeek;
