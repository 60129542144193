import React, { PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import { check } from '@amzn/storm-ui-icons-v3';
import Icon from '../Icon';
import { StepsWrapper } from './Steps.styles';
import { STATUS } from './constants';
import { StepIcon, StepLabelPlacement, StepStatusDescriptions } from './types';

export interface StepsProps {
  /**
     * The icons used in place of Steps.
     * @defaultValue `{ completed: <Icon type={check} /> }`
     */
  icons?: StepIcon;
  /**
     * Denotes if the Steps should be numbered.
     * @defaultValue `true`
     */
  numbered?: boolean;
  /**
     * The initial Step index.
     * @defaultValue `0`
     */
  initial?: number;
  /**
     * Denotes if the Steps should render vertically.
     * @defaultValue `false`
     */
  isVertical?: boolean;
  /**
     * Index of current step.
     * @defaultValue `0`
     */
  current?: number;
  /**
     * Placement of step title.
     * @defaultValue `"horizontal"`
     */
  labelPlacement?: StepLabelPlacement;
  /**
   * @defaultValue `[]`
   */
  children?: ReactNode;
  /**
     * Use this prop to provide customized/localized steps description in internationalized UIs.
     * This will be used for screen reader labels.
     * @defaultValue `"Steps"`
     */
  description?: string;
  /**
     * Use this prop to provide customized/localized step descriptions in internationalized UIs for
     * active, completed, future, and disabled step status.
     * This will be used for screen reader labels in `<Step>` component.
     * @defaultValue `{
     * completed: "Completed step", active: "Active step", future: "Future step", disabled: "Unavailable step"
     * }`
     */
  statusDescriptions?: StepStatusDescriptions;
}

class Steps extends PureComponent<StepsProps> {
  static propTypes = {
    /**
     * The icons used in place of Steps.
     */
    icons: PT.objectOf(PT.node),
    /**
     * Denotes if the Steps should be numbered.
     */
    numbered: PT.bool,
    /**
     * The initial Step index.
     */
    initial: PT.number,
    /**
     * Denotes if the Steps should render vertically.
     */
    isVertical: PT.bool,
    /**
     * Index of current step.
     */
    current: PT.number,
    /**
     * Placement of step title.
     */
    labelPlacement: PT.oneOf(['horizontal', 'vertical']),
    children: PT.arrayOf(PT.node),
    /**
     * Use this prop to provide customized/localized steps description in internationalized UIs.
     * This will be used for screen reader labels.
     */
    description: PT.string,
    /**
     * Use this prop to provide customized/localized step descriptions in internationalized UIs for
     * active, completed, future, and disabled step status.
     * This will be used for screen reader labels in `<Step>` component.
     */
    statusDescriptions: PT.objectOf(PT.string),
  }

  static defaultProps = {
    icons: {
      completed: <Icon type={check} />,
    },
    numbered: true,
    initial: 0,
    isVertical: false,
    current: 0,
    labelPlacement: 'horizontal',
    children: [],
    description: 'Steps',
    statusDescriptions: {
      completed: 'Completed step',
      active: 'Active step',
      future: 'Future step',
      disabled: 'Unavailable step',
    },
  }

  render(): JSX.Element {
    const {
      icons = {
        completed: <Icon type={check} />,
      },
      numbered = true,
      children,
      initial = 0,
      current = 0,
      labelPlacement = 'horizontal',
      description = 'Steps',
      statusDescriptions = {
        completed: 'Completed step',
        active: 'Active step',
        future: 'Future step',
        disabled: 'Unavailable step',
      },
      isVertical = false,
    } = this.props;
    const revisedLabelPlacement = isVertical ? 'horizontal' : labelPlacement;

    return (
      <StepsWrapper role="list" aria-label={description} $isVertical={isVertical}>
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          const stepNumber = initial + index;
          const childProps = {
            ...child.props,
            stepNumber: `${stepNumber + 1}`,
            stepIndex: stepNumber,
            icons,
            isVertical,
            numbered,
            labelPlacement: revisedLabelPlacement,
            statusDescriptions,
          };
          if (!child.props.status) {
            if (stepNumber === current) {
              childProps.status = STATUS.ACTIVE;
            } else if (stepNumber < current) {
              childProps.status = STATUS.COMPLETED;
            } else {
              childProps.status = STATUS.FUTURE;
            }
          }
          childProps.active = stepNumber === current;
          return React.cloneElement(child, childProps);
        })}
      </StepsWrapper>
    );
  }
}

export default Steps;
