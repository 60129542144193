import React, {
  MouseEvent,
  useCallback,
  FC,
  Validator,
} from 'react';
import PT from 'prop-types';
import {
  SelectListItem,
  SelectListClickEventHandler,
  SelectValue,
} from './types';
import SelectListContext from './SelectListContext';

export interface SelectListProps {
  /**
   * @defaultValue `undefined`
   */
  onChange?: SelectListClickEventHandler;
  /**
   * @defaultValue `undefined`
   */
  selectedValue?: SelectListItem;
  /**
   * @defaultValue `false`
   */
  noWrap?: boolean;
  /**
   * @defaultValue `undefined`
   */
  focusedValue?: string | null;
  /**
   * @defaultValue `undefined`
   */
  afterOnSelect?: () => void;
  /**
   * @defaultValue `undefined`
   */
  children?: SelectListItem | SelectListItem[];
}

const SelectList: FC<React.PropsWithChildren<SelectListProps>> = ({
  onChange,
  selectedValue,
  noWrap,
  focusedValue,
  afterOnSelect,
  children,
}): JSX.Element | null => {
  const handleClick = useCallback(
    (value: SelectValue, event: MouseEvent<HTMLButtonElement>) => {
      onChange?.(value, event);
    }, [onChange],
  );

  return children ? (
    <SelectListContext.Provider
      value={{
        focusedValue,
        noWrap,
        onClick: onChange ? handleClick : undefined,
        selectedValue,
        afterOnSelect,
      }}
    >
      {children}
    </SelectListContext.Provider>
  ) : null;
};

SelectList.propTypes = {
  onChange: PT.func,
  selectedValue: PT.any as Validator<SelectListItem>,
  children: PT.node as Validator<SelectListItem | SelectListItem[]>,
  noWrap: PT.bool,
  focusedValue: PT.string,
  afterOnSelect: PT.func,
};

SelectList.defaultProps = {
  onChange: undefined,
  selectedValue: undefined,
  children: undefined,
  noWrap: false,
  focusedValue: undefined,
  afterOnSelect: undefined,
};

export default SelectList;
