/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing, mobileSpacing } from '../base/spacing';
import {
  fontSizes,
  mobileFontSizes,
  typography,
} from '../base/typography';

const pill = {
  bg: palette.gray[200],
  color: typography.color.primary,
  closeButton: {
    color: palette.gray[700],
    disabledColor: palette.gray[300],
    hoverColor: palette.squidInk,
  },
  disabledBg: palette.gray[100],
  disabledText: typography.color.tertiary,
  paddingInlineStart: spacing.small,
  paddingInlineEnd: '3px',
  radius: '30px',
  maxWidth: '500px',
  lineHeight: '22px',
  fontSize: fontSizes.small,
  iconPadding: `0 ${spacing.micro} 2px ${spacing.micro}`,
};

export const mobilePill = {
  mobile: {
    fontSize: mobileFontSizes.small,
    iconPadding: `0 ${mobileSpacing.micro} 2px ${mobileSpacing.micro}`,
  },
};

export default pill;
