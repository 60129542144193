import React, {
  FC, MouseEvent, KeyboardEvent, useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import { MergeElementProps, keyboardKeynames as keys } from '@amzn/storm-ui-utils-v3';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { BaseMenuItemMixin, MenuItem, BaseMenuItemProps } from './MenuItem.styles';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MenuItemButtonProps extends TaktProps, Omit<MergeElementProps<'button'>, 'onClick'> {
  /**
   * @defaultValue `undefined`
   */
  onClick?: (event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void;
}

export interface ItemButtonProps extends BaseMenuItemProps, MergeElementProps<'button'> {
  disabled?: boolean;
}

export interface ItemButtonAttrsProps extends ItemButtonProps {
  $focusVisible?: boolean;
}

const ItemButton = styled('button')<ItemButtonProps>`
  ${BaseMenuItemMixin}
  border: 0;
  margin: 0;
  width: 100%;
  ${({ theme, disabled }) => (disabled ? css`
    color: ${theme.dropdown.item.colorDisabled};
    cursor: not-allowed;
  ` : css`
    color: ${theme.dropdown.item.color};
    cursor: pointer;
  `)}
  /** this is needed for iOS, which uses a 3px focus outline */
  :-internal-direct-focus,
  :-webkit-direct-focus {
    outline: none;
  }

  ${isMobile(css<ItemButtonProps>`
    ${({ theme }) => theme.typography.mobile.base};
    min-height: ${({ theme }) => theme.dropdown.item.mobile.minHeight};
  `)}
`;

const MenuItemButton: FC<React.PropsWithChildren<MenuItemButtonProps>> = ({
  children,
  disabled,
  onClick,
  taktId,
  taktValue,
  ...rest
}) => {
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLButtonElement>) => {
    const { key } = event;

    switch (key) {
      case keys.ENTER:
      case keys.SPACE:
        onClick?.(event);
        break;
      default:
        break;
    }
  }, [onClick]);

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('menu-item-button')}>
      {({ getDataTaktAttributes }) => (
        <MenuItem>
          <ItemButton
            {...getDataTaktAttributes()}
            {...rest}
            type="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            disabled={disabled}
            $disabled={disabled}
          >
            {children}
          </ItemButton>
        </MenuItem>
      )}
    </TaktIdConsumer>
  );
};

MenuItemButton.defaultProps = {
  onClick: undefined,
};

export default MenuItemButton;
