import MobileModeHint from './MobileModeHint';

/* Utility for getting `matchMedia()` compatible function with or without a browser context */

interface StubMediaQueryList {
  matches: boolean;
  addListener: (event: any) => void;
  removeListener: (event: any) => void;
}

function stubMatchMediaMobile(): StubMediaQueryList {
  return {
    matches: true,
    addListener: () => undefined,
    removeListener: () => undefined,
  };
}

function stubMatchMediaNotMobile(): StubMediaQueryList {
  return {
    matches: false,
    addListener: () => undefined,
    removeListener: () => undefined,
  };
}

function getMatchMediaFunction(hint: MobileModeHint) {
  if (typeof window !== 'undefined' && 'matchMedia' in window) {
    return window.matchMedia;
  }
  return (hint === MobileModeHint.Mobile) ? stubMatchMediaMobile : stubMatchMediaNotMobile;
}

export default getMatchMediaFunction;
