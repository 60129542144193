import React, { FC, ReactNode, MouseEvent } from 'react';
import { TaktProps, useIsMobile } from '@amzn/storm-ui';
import PT, { Validator } from 'prop-types';
import { AdvertisingLocale } from '@amzn/storm-ui-utils';
import {
  ClickEventHandler,
  UnresolvedPresetRange,
  PresetRange,
  SelectRangeEventHandler,
  OrientationType,
  MultiDateSelector,
  ISOPresetRange,
  ISODate,
} from '../../types';
import Root from './Root';
import ContextProvider from '../../contexts/ContextProvider';
import presetRanges from './presetRanges';

export interface DateRangePickerProps extends TaktProps {
  /**
   * The object used to localize the DatePicker.
   * @defaultValue `undefined`
   */
  locale?: AdvertisingLocale | Locale | (() => Promise<{ default: Locale }>);
  /**
   * Today's date. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  today?: Date | string;
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * Defaults to horizontal for desktop and vertical for mobile.
   * @defaultValue `"horizontal"`
   */
  orientation?: OrientationType;
  /**
   * The number of months to display.
   * Defaults to 2 for desktop and 1 for mobile.
   * @defaultValue `undefined`
   */
  numberOfMonths?: number;
  /**
   * The first or only month displayed.
   * @defaultValue `undefined`
   */
  monthInView?: Date;
  /**
   * The earliest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  minDate?: Date | string;
  /**
   * The latest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   * @defaultValue `undefined`
   */
  maxDate?: Date | string;
  /**
   * The marketplace time zone.
   * @defaultValue `undefined`
   */
  zone?: string;
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   * @defaultValue `undefined`
   */
  isOutsideRange?: ClickEventHandler;
  /**
   * The unique identifier.
   * @defaultValue `undefined`
   */
  id?: string;
  /**
   * The selected start date.
   * @defaultValue `undefined`
   */
  startDate?: Date | string;
  /**
  * The selected end date.
  * @defaultValue `undefined`
  */
  endDate?: Date | string;
  /**
   * The ID for period for when a custom range is selected.
   * @defaultValue `undefined`
   */
  customPeriodId?: string;
  /**
   * An array of preset ranges that show as the selectable list.
   * @defaultValue `presetRanges`
   */
  presetRanges?: PresetRange[] | UnresolvedPresetRange[] | ISOPresetRange[];
  /**
   * The selected range preset object.
   * @defaultValue `undefined`
   */
  selectedPreset?: PresetRange | ISOPresetRange;
  /**
   * An array of options to select a range of days at a time.
   * @defaultValue `undefined`
   */
  multiDateSelectors?: MultiDateSelector[];
  /**
   * The selected multiDateSelector object.
   * @defaultValue `undefined`
   */
  selectedMultiDateSelector?: MultiDateSelector;
  /**
   * The label above the list of multiDateSelectors
   * @defaultValue `undefined`
   */
  multiDateSelectorsLabel?: string;
  /**
   * Function that is triggered when the selected multi date selector is changed
   * @defaultValue `undefined`
   */
  onMultiDateSelectorChange?: (selected: MultiDateSelector, e?: MouseEvent<HTMLButtonElement>) => void;
  /**
   * The function called when the visible months are changed.
   * @defaultValue `undefined`
   */
  onVisibleMonthsChange?: (visibleMonths: ISODate[]) => void;
  /**
   * Header string for mobile `<DateRangePicker />`.
   * @defaultValue `undefined`
   */
  mobileHeader?: string;
  /**
   * Override the mobile `<DateRangePicker />` dropdown label
   * to display custom date range. It will get passed into the
   * "onOverrideLabel" property for `<Dropdown />`. Read more in /dropdown.
   * @defaultValue `undefined`
   */
  dropdownLabelOverride?: (label?: string) => ReactNode;
  /**
   * The function called when the selected date is changed.
   * @defaultValue `undefined`
   */
  onChange?: SelectRangeEventHandler;
  /**
   * he function called on submit.
   * @defaultValue `undefined`
   */
  onSubmit?: (e?: MouseEvent<HTMLButtonElement>, selected?: PresetRange) => unknown;
  /**
   * The function called on cancel.
   * @defaultValue `undefined`
   */
  onCancel?: (e?: MouseEvent<HTMLButtonElement>) => unknown;
  /**
   * The text label for the cancel button.
   * @defaultValue `undefined`
   */
  cancelText?: string;
  /**
   * The text label for the save button.
   * @defaultValue `undefined`
   */
  saveText?: string;
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   * @defaultValue `"go to previous month"`
   */
  previousMonthNavButtonLabel?: string;
  /**
  * The localizable aria-label for the calendar's next month nav button.
  * @defaultValue `"go to next month"`
  */
  nextMonthNavButtonLabel?: string;
  /**
  * The prefix for the time zone.
  * @defaultValue `undefined`
  */
  zonePrefix?: string;
  /**
   * The message displayed as a Tooltip over a day before the minDate
   * @defaultValue `undefined`
   */
  beforeMinDateMessage?: string;
  /**
   * The message displayed as a Tooltip over a day after the maxDate
   * @defaultValue `undefined`
   */
  afterMaxDateMessage?: string;
  /**
   * The funcion used to confitionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   * @defaultValue `undefined`
   */
  isDayDisabled?: (day: Date) => boolean | string;
  /**
   * Override the timezone to display a custom node.
   * @defaultValue `undefined`
   */
  renderZoneOverride?: (zone: string) => ReactNode;
}

const DateRangePicker: FC<React.PropsWithChildren<DateRangePickerProps>> = props => {
  const { numberOfMonths, orientation } = props;
  const isMobile = useIsMobile();
  // Should default to 1 visibile month on mobile, unless specified otherwise
  let dateRangeMonths = numberOfMonths;
  if (!dateRangeMonths && isMobile) {
    dateRangeMonths = 1;
  }
  // Should default to vertical orientation on mobile, unless specified otherwise
  let dateRangeOrientation = orientation;
  if (!orientation && numberOfMonths && numberOfMonths > 1) {
    dateRangeOrientation = 'vertical';
  }

  return (
    <ContextProvider
      {...props}
      type="range"
      numberOfMonths={dateRangeMonths ?? 2}
      orientation={dateRangeOrientation ?? 'horizontal'}
    >
      <Root />
    </ContextProvider>
  );
};

DateRangePicker.propTypes = {
  /**
   * The object used to localize the DatePicker.
   */
  locale: PT.oneOfType(
    [PT.objectOf(PT.any), PT.func],
  ) as Validator<Locale | (() => Promise<{ default: Locale }>)>, // eslint-disable-line react/forbid-prop-types
  /**
   * Today's date. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  today: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * If more than a single month is displayed,
   * this will render the months either horizontally
   * or vertically.
   * Defaults to horizontal for desktop and vertical for mobile.
   */
  orientation: PT.oneOf(['horizontal', 'vertical']),
  /**
   * The number of months to display.
   * Defaults to 2 for desktop and 1 for mobile.
   */
  numberOfMonths: PT.number,
  /**
   * The first or only month displayed.
   */
  monthInView: PT.instanceOf(Date),
  /**
   * The earliest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  minDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The latest possible date that can be selected. Can be a Date object or a string in 'yyyy-MM-dd' format.
   */
  maxDate: PT.oneOfType([
    PT.string,
    PT.instanceOf(Date),
  ]),
  /**
   * The marketplace time zone.
   */
  zone: PT.string,
  /**
   * The function called if a date is selected earlier than `minDate` or later than `maxDate`.
   */
  isOutsideRange: PT.func,
  /**
   * The unique identifier.
   */
  id: PT.string,
  /**
   * The selected start date.
   */
  startDate: PT.instanceOf(Date),
  /**
  * The selected end date.
  */
  endDate: PT.instanceOf(Date),
  /**
   * The ID for period for when a custom range is selected.
   */
  customPeriodId: PT.string,
  /**
   * An array of preset ranges that show as the selectable list.
   */
  presetRanges: PT.arrayOf(PT.any), // eslint-disable-line react/forbid-prop-types
  /**
   * The selected range preset object.
   */
  selectedPreset: PT.shape({
    label: PT.string,
    period: PT.string,
    start: PT.instanceOf(Date),
    end: PT.instanceOf(Date),
  }) as Validator<PresetRange>,
  /**
   * An array of options to select a range of days at a time.
   */
  multiDateSelectors: PT.arrayOf(PT.any), // eslint-disable-line react/forbid-prop-types
  /**
   * The selected multiDateSelector object.
   */
  selectedMultiDateSelector: PT.shape({
    label: PT.string,
    range: PT.string,
    getFrom: PT.func,
    getTo: PT.func,
  }) as Validator<MultiDateSelector>,
  /**
   * The label above the list of multiDateSelectors
   */
  multiDateSelectorsLabel: PT.string,
  /**
   * Function that is triggered when the selected multi date selector is changed
   */
  onMultiDateSelectorChange: PT.func,
  /**
   * Header string for mobile `<DateRangePicker />`.
   */
  mobileHeader: PT.string,
  /**
   * Override the mobile `<DateRangePicker />` dropdown label
   * to display custom date range. It will get passed into the
   * "onOverrideLabel" property for `<Dropdown />`. Read more in /dropdown.
   */
  dropdownLabelOverride: PT.func,
  /**
   * The function called when the selected date is changed.
   */
  onChange: PT.func,
  /**
   * he function called on submit.
   */
  onSubmit: PT.func,
  /**
   * The function called on cancel.
   */
  onCancel: PT.func,
  /**
   * The text label for the cancel button.
   */
  cancelText: PT.string,
  /**
   * The text label for the save button.
   */
  saveText: PT.string,
  /**
   * The localizable aria-label for the calendar's previous month nav button.
   */
  previousMonthNavButtonLabel: PT.string,
  /**
    * The localizable aria-label for the calendar's next month nav button.
    */
  nextMonthNavButtonLabel: PT.string,
  /**
   * The message displayed as a Tooltip over a day before the minDate
   */
  beforeMinDateMessage: PT.string,
  /**
   * The message displayed as a Tooltip over a day after the maxDate
   */
  afterMaxDateMessage: PT.string,
  /**
   * The function used to conditionally disable days. It can take a boolean or
   * a string, if a Tooltip message is desired.
   */
  isDayDisabled: PT.func,
  /**
   * Override the timezone to display a custom node.
   */
  renderZoneOverride: PT.func,
};

DateRangePicker.defaultProps = {
  locale: undefined,
  today: undefined,
  orientation: 'horizontal',
  numberOfMonths: undefined,
  monthInView: undefined,
  minDate: undefined,
  maxDate: undefined,
  zone: undefined,
  isOutsideRange: undefined,
  id: undefined,
  startDate: undefined,
  endDate: undefined,
  customPeriodId: undefined,
  presetRanges,
  selectedPreset: undefined,
  multiDateSelectors: undefined,
  selectedMultiDateSelector: undefined,
  multiDateSelectorsLabel: undefined,
  onMultiDateSelectorChange: undefined,
  mobileHeader: undefined,
  dropdownLabelOverride: undefined,
  onChange: undefined,
  onSubmit: undefined,
  onCancel: undefined,
  previousMonthNavButtonLabel: 'go to previous month',
  nextMonthNavButtonLabel: 'go to next month',
  zonePrefix: undefined,
  beforeMinDateMessage: undefined,
  afterMaxDateMessage: undefined,
  isDayDisabled: undefined,
  renderZoneOverride: undefined,
  cancelText: undefined,
  saveText: undefined,
  onVisibleMonthsChange: undefined,
};

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;
