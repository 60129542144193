import React, {
  useState, useEffect, ReactNode, FC, useMemo,
} from 'react';
import {
  Locale,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { formatInTimeZone } from 'date-fns-tz';
import { AllPickerProps, DatePickerContextValue, ISODate } from '../../types';
import DatePickerContext from './DatePickerContext';
import { useDateLocalization } from '../external/DateLocalizationProvider';
import getLazyLocale from '../../utils/getLazyLocale';
import { dateToISODate } from '../../utils/dateUtils';
import { isDFNSLocale, isLocale, isLocalePromise } from '../../utils/isLocale';

export interface DatePickerProviderProps {
  baseProps: AllPickerProps;
  children: ReactNode;
}

const DatePickerProvider: FC<React.PropsWithChildren<DatePickerProviderProps>> = ({
  children,
  baseProps,
}) => {
  const {
    locale: localLocale,
    today,
    date,
    startDate,
    endDate,
    minDate,
    maxDate,
    monthInView,
    ...rest
  } = baseProps;

  const externalContext = useDateLocalization();

  let initialLocale = enUS;
  let shouldLoad = !!localLocale;
  if (localLocale && isDFNSLocale(localLocale)) {
    initialLocale = localLocale;
    shouldLoad = false;
  } else if (externalContext && isDFNSLocale(externalContext.locale)) {
    initialLocale = externalContext.locale;
    shouldLoad = false;
  }

  const [locale, setLocale] = useState<Locale>(initialLocale);
  const [loading, setLoading] = useState(shouldLoad);

  useEffect(() => {
    let localeGetter;
    if (localLocale) {
      if (isLocale(localLocale)) {
        localeGetter = getLazyLocale(localLocale);
      } else if (isLocalePromise(localLocale)) {
        localeGetter = localLocale;
      }
    } else if (externalContext) {
      if (isLocale(externalContext.locale)) {
        localeGetter = getLazyLocale(externalContext.locale);
      } else if (isLocalePromise(externalContext.locale)) {
        localeGetter = externalContext.locale;
      }
    }
    if (localeGetter) {
      if (typeof localeGetter === 'object' && 'localize' in localeGetter) {
        setLocale(localeGetter as Locale);
      }
      if (typeof localeGetter === 'function') {
        localeGetter().then((localeRes: { default: Locale }) => {
          setLocale(localeRes.default);
          setLoading(false);
        });
      }
    }
  }, [externalContext, localLocale]);

  let { zone } = baseProps;
  if (!zone) {
    if (externalContext && externalContext.zone) {
      zone = externalContext.zone;
    } else {
      zone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
    }
  }

  /**
    * The IANA tz name does not accept spaces.
    * if a timezone is provided with spaces,
    * they will be replaced with underscores.
    */
  zone = zone.replace(/ /g, '_');

  const todaysDate = useMemo(() => (
    today
      ? dateToISODate(today)
      : formatInTimeZone(new Date(), zone ?? '', 'yyyy-MM-dd')) as ISODate,
  [today, zone]);

  const contextValue: DatePickerContextValue = {
    ...rest,
    locale: isDFNSLocale(localLocale) ? localLocale : locale,
    zone,
    today: todaysDate,
    date: date ? dateToISODate(date, zone) : undefined,
    startDate: startDate ? dateToISODate(startDate, zone) : undefined,
    endDate: endDate ? dateToISODate(endDate, zone) : undefined,
    minDate: minDate ? dateToISODate(minDate, zone) : undefined,
    maxDate: maxDate ? dateToISODate(maxDate, zone) : undefined,
    monthInView: monthInView ? dateToISODate(monthInView, zone) : undefined,
    loading,
  };

  return (
    <DatePickerContext.Provider value={contextValue}>
      {children}
    </DatePickerContext.Provider>
  );
};

export default DatePickerProvider;
