import React from 'react';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';
import { StyledBasePagination } from './Pagination.styles';
import type { StyledBasePaginationProps } from './Pagination.styles';

export interface BasePaginationProps extends TaktProps, StyledBasePaginationProps {}

const BasePagination = ({
  children,
  id,
  taktId,
  taktValue,
  ...restProps
}: BasePaginationProps) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('pagination')}>
    <StyledBasePagination {...restProps} id={id}>
      {children}
    </StyledBasePagination>
  </TaktIdProvider>
)

export default BasePagination
