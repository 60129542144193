import React, { FC } from 'react';
import PT, { Validator } from 'prop-types';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { IconDefinition, SizeProp } from './types';
import renderIconSVG from './utils/renderIconSVG';
import RemoteIcon from './RemoteIcon/RemoteIcon';
import isHorizontalFlipOnRTL from './utils/isHorizontalFlipOnRTL';
import getIconSize from './utils/getIconSize';
import { IconClassWrapper, IconWrapperStyled } from './Icon.styles';

export interface IconProps extends Omit<MergeElementProps<'span'>, 'size'|'children'> {
  /**
   * The type of Icon.
   */
  type: string | IconDefinition;
  /**
   * Font Awesome [sizing classes ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/sizing-icons).
   * @defaultValue `undefined`
   */
  size?: SizeProp | string;
  /**
   * Font Awesome [transform element attribute ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/power-transforms).
   * @defaultValue `undefined`
   */
  transform?: string;
  /**
   * Changes the `<svg/>` to be `display: block;`.
   * Use when centering icons in small spaces.
   * @defaultValue `false`
   */
  blockSize?: boolean;
  /**
   * @defaultValue `undefined`
   */
  'data-testid'?: string;
}

const Icon: FC<IconProps> = ({
  type,
  size,
  transform,
  blockSize = false,
  'data-testid': dataTestId,
  ...rest
}) => {
  if (typeof type === 'string') {
    return (
      <RemoteIcon
        {...rest}
        key={type}
        type={type}
        size={size}
        transform={transform}
        blockSize={blockSize}
        data-testid={dataTestId}
      />
    );
  }

  const [iconName, horizontalFlipOnRTL] = isHorizontalFlipOnRTL(type.iconName);

  const icon = {
    ...type,
    iconName,
  };

  const iconFontSize = getIconSize(size);

  return (
    <IconClassWrapper
      $pulse={iconName === 'spinner'}
      $size={size}
      $fontSize={iconFontSize}
    >
      {(className?: string) => (
        <IconWrapperStyled
          {...rest}
          $blockSize={blockSize}
          $horizontalFlipOnRTL={horizontalFlipOnRTL}
          dangerouslySetInnerHTML={{ __html: renderIconSVG(icon, transform, className) }}
          // Used to help identify which icon is being used for testing purposes
          data-testid={dataTestId || `storm-ui-icon-${iconName}`}
        />
      )}
    </IconClassWrapper>
  );
};

Icon.propTypes = {
  /**
   * The type of Icon.
   */
  type: PT.oneOfType([PT.string, PT.shape({
    iconName: PT.string,
    prefix: PT.oneOf(['fas', 'fab', 'far', 'fal', 'fad']),
    icon: PT.arrayOf(PT.oneOfType([PT.number, PT.string, PT.arrayOf(PT.string)])),
  }) as unknown as Validator<IconDefinition>]).isRequired,
  /**
   * Font Awesome [sizing classes ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/sizing-icons).
   */
  size: PT.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
  /**
   * Font Awesome [transform element attribute ](https://fontawesome.com/v5.0.13/how-to-use/on-the-web/styling/power-transforms).
   */
  transform: PT.string,
  /**
   * Changes the `<svg/>` to be `display: block;`.
   * Use when centering icons in small spaces.
   */
  blockSize: PT.bool,
};

Icon.defaultProps = {
  size: undefined,
  transform: undefined,
  blockSize: false,
  'data-testid': undefined,
};

export default Icon;
