import { addDays, Locale, startOfWeek } from 'date-fns';

/** Returns an array of weekday names by locale */
const getWeekdays = (locale?: Locale): Array<Date> => {
  const start = startOfWeek(new Date(), { locale });
  const days = [];
  for (let i = 0; i < 7; i++) {
    const day = addDays(start, i);
    days.push(day);
  }
  return days;
};

export default getWeekdays;
