import {
  SurfaceDark, SurfaceDarkInfo, SurfaceLight,
} from '../Surface';
import { Surface, SurfaceType } from '../Surface/types';

// eslint-disable-next-line import/prefer-default-export
export const getContainer = (type:SurfaceType): Surface => {
  if (type === 'dark') return SurfaceDark;
  if (type === 'blue') return SurfaceDarkInfo;
  return SurfaceLight;
};
