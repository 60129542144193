/* eslint-disable id-length */
import { palette } from '../base/color';

const switchStyles = {
  slideTransition: '.1s',
  bg: palette.gray[500],
  border: 'none',
  borderColor: palette.dustyGray,
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.07) inset',
  radius: '4px',
  disabledOpacity: 0.8,

  normal: {
    // legacy styles for 2.x
    height: 21,
    width: 32,
    padding: 3,
    checkmark: 8,
    checkSize: 8,

    // styles
    innerHeight: 21,
    innerWidth: 40,
    innerPadding: 6,
    innerCheckSize: 9,
  },

  small: {
    // legacy styles for 2.x
    height: 14,
    width: 25,
    padding: 4,
    checkmark: 6,
    checkSize: 6,

    // styles
    innerHeight: 16,
    innerWidth: 30,
    innerPadding: 4,
    innerCheckSize: 7,
  },

  paddle: {
    // legacy styles for 2.x
    radius: '10px',

    // styles
    borderRadius: '12px',
    bg: palette.white,
    borderHover: 'none',
    boxShadow: 'none',
    default: {
      bg: palette.gray[500],
      hover: palette.gray[600],
      active: palette.gray[700],
      disabled: palette.gray[200],
      border: 'none',
    },
    checked: {
      bg: palette.blue[700],
      hover: palette.blue[800],
      active: palette.blue[900],
      disabled: palette.blue[300],
      border: 'none',
    },
    normal: {
      // legacy styles for 2.x
      top: 3,
      left: 3,
      height: 15,
      width: 15,
      checkedOffset: 11,

      // styles
      topOffset: 3,
      leftOffset: 4,
      paddleHeight: 15,
      paddleWidth: 15,
      checkedTranslateOffset: 17,
    },
    small: {
      // legacy styles for 2.x
      top: 2,
      left: 2,
      height: 10,
      width: 10,
      checkedOffset: 11,

      // styles
      topOffset: 3,
      leftOffset: 4,
      paddleHeight: 10,
      paddleWidth: 10,
      checkedTranslateOffset: 12,
    },
  },
};

export const mobileSwitchStyles = {
  normal: {
    mobile: {
      // legacy styles for 2.x
      height: 24,
      width: 37,

      // styles
      innerHeight: 24,
      innerWidth: 36,
      innerCheckSize: 7,
      innerPadding: 4,
    },
  },

  small: {
    mobile: {
      // legacy styles for 2.x
      height: 16,
      width: 29,

      // styles
      innerHeight: 16,
      innerWidth: 26,
      innerCheckSize: 7,
      innerPadding: 4,
    },
  },
  paddle: {
    normal: {
      mobile: {
        // legacy styles for 2.x
        height: 24,
        width: 24,
        checkedOffset: 13,

        // styles
        paddleHeight: 18,
        paddleWidth: 18,
        checkedTranslateOffset: 11,
      },
    },
    small: {
      mobile: {
        // legacy styles for 2.x
        height: 16,
        width: 16,
        checkedOffset: 13,

        // styles
        paddleHeight: 10,
        paddleWidth: 10,
        checkedTranslateOffset: 9,
      },
    },
  },
};

export default switchStyles;
