import React, { FC } from 'react';
import { angleInlineEnd } from '@amzn/storm-ui-icons-v3';
import { createStormTaktId } from '../../TaktIdContext';
import Icon from '../../Icon/Icon';
import PaginationButton, { PaginationNavProps } from './PaginationButton';

const PaginationNextButton: FC<React.PropsWithChildren<PaginationNavProps>> = (
  props: PaginationNavProps,
): JSX.Element => (
  <PaginationButton taktFallbackId={createStormTaktId('pagination-next-button')} {...props}>
    <Icon type={angleInlineEnd} blockSize />
  </PaginationButton>
);
PaginationNextButton.displayName = 'PaginationNextButton';

export default PaginationNextButton;
