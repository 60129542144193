import React, { PureComponent, ReactNode } from 'react';
import PT from 'prop-types';
import Text from '../Text';
import {
  StepWrapper,
  StepTail,
  Circle,
  CircleBackground,
  ContentWrapper,
  TitleWrapper,
  CircleContent,
} from './Step.styles';
import { STATUS } from './constants';
import {
  StepIcon,
  StepLabelPlacement,
  StepStatus,
  StepStatusDescriptions,
} from './types';

export interface StepProps {
  /**
   * @defaultValue `[]`
   */
  children?: ReactNode;
  /**
     * The Step number.
     * @defaultValue `null`
     */
  stepNumber?: string;
  /**
     * The icons used in place of the Step.
     * @defaultValue `{}`
     */
  icons?: StepIcon;
  /**
     * Denotes if the Step should render vertically.
     * @defaultValue `false`
     */
  isVertical?: boolean;
  /**
     * Denotes if the Step should be numbered.
     * @defaultValue `true`
     */
  numbered?: boolean;
  /**
     * Title of the step.
     * @defaultValue `""`
     */
  title?: ReactNode | string;
  /**
     * Renders the label either horizontally or vertically.
     * @defaultValue `"horizontal"`
     */
  labelPlacement?: StepLabelPlacement;
  /**
     * Set the status of this `<Step>`.If the status is not explicitly set,
     * it will be inferred from the index of the current `<Step>`.
     * @defaultValue `null`
     */
  status?: StepStatus;
  /**
     * The aria-label on the Step wrapper div.
     * @defaultValue `{}`
     */
  statusDescriptions?: StepStatusDescriptions;
}

class Step extends PureComponent<StepProps> {
  static propTypes = {
    children: PT.arrayOf(PT.node),
    /**
     * The Step number.
     */
    stepNumber: PT.string,
    /**
     * The icons used in place of the Step.
     */
    icons: PT.objectOf(PT.object),
    /**
     * Denotes if the Step should render vertically.
     */
    isVertical: PT.bool,
    /**
     * Denotes if the Step should be numbered.
     */
    numbered: PT.bool,
    /**
     * Title of the step.
     */
    title: PT.oneOfType([PT.string, PT.node]),
    /**
     * Renders the label either horizontally or vertically.
     */
    labelPlacement: PT.oneOf(['horizontal', 'vertical']),
    /**
     * Set the status of this `<Step>`.If the status is not explicitly set,
     * it will be inferred from the index of the current `<Step>`.
     */
    status: PT.oneOf(['completed', 'active', 'future', 'disabled']),
    /**
     * The aria-label on the Step wrapper div.
     */
    statusDescriptions: PT.objectOf(PT.string),
  }

  static defaultProps = {
    children: [],
    icons: {},
    isVertical: false,
    numbered: true,
    title: '',
    labelPlacement: 'horizontal',
    status: null,
    stepNumber: null,
    statusDescriptions: {},
  }

  renderIconNode(): JSX.Element | null {
    const {
      stepNumber,
      status = null,
      icons,
      numbered,
    } = this.props;
    let iconNode;
    if (icons && status === STATUS.COMPLETED) {
      iconNode = (
        <CircleContent $status={status} $contentType="icon">{icons.completed}</CircleContent>
      );
    } else if (!numbered) {
      iconNode = null;
    } else {
      iconNode = (
        <CircleContent $status={status}>{stepNumber}</CircleContent>
      );
    }
    return iconNode;
  }

  render(): JSX.Element {
    const {
      children,
      status = null,
      title = '',
      isVertical = false,
      labelPlacement = 'horizontal',
      statusDescriptions,
    } = this.props;
    return (
      <StepWrapper $labelPlacement={labelPlacement} $isVertical={isVertical} role="listitem" aria-label={status && statusDescriptions ? statusDescriptions[status] : undefined}>
        <StepTail $labelPlacement={labelPlacement} $isVertical={isVertical} />
        <Circle $labelPlacement={labelPlacement} $isVertical={isVertical} aria-hidden="true">
          {this.renderIconNode()}
          <CircleBackground $status={status} />
        </Circle>
        <ContentWrapper $labelPlacement={labelPlacement} $isVertical={isVertical}>
          {isVertical && children ? children
            : (
              <Text type="span">
                <TitleWrapper $status={status}>
                  {title}
                </TitleWrapper>
              </Text>
            )}
        </ContentWrapper>
      </StepWrapper>
    );
  }
}

export default Step;
