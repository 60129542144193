import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { SurfaceLight } from '../Surface/index';
import {
  MaxSizeProp,
  SheetSide,
  SheetPaddingOptions,
} from './types';
import { TransitionStatus, EXITED } from '../types/react-transition-group/Transition';

export interface ShroudProps {
  $fadeState: TransitionStatus;
}

export const Shroud = styled('div')<ShroudProps>`
  background-color: ${({ theme }) => theme.modal.overlay};
  position: fixed;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 200ms ease-in-out;
  }
  opacity: 0;
  opacity: ${({ $fadeState }) => ({
    entering: 0,
    entered: 1,
    exiting: 0,
    exited: 0,
    unmounted: 0,
  }[$fadeState])};
  ${({ $fadeState }) => ($fadeState === EXITED) && `
  visibility: hidden;
  `}
  z-index: ${({ theme }) => theme.modal.zIndex};
`;
Shroud.displayName = 'Shroud';

export interface SheetContentContainerProps {
  $slideState: TransitionStatus;
  side: SheetSide;
}

const transformLeftToRight = {
  entering: 'translateX(-100%)',
  entered: 'translateX(0%)',
  exiting: 'translateX(-100%)',
  exited: 'translateX(0%)',
  unmounted: 'translateX(0%)',
};

const transformRightToLeft = {
  entering: 'translateX(100%)',
  entered: 'translateX(0%)',
  exiting: 'translateX(100%)',
  exited: 'translateX(0%)',
  unmounted: 'translateX(0%)',
};

const transformBottomToTop = {
  entering: 'translateY(100%)',
  entered: 'translateY(0%)',
  exiting: 'translateY(100%)',
  exited: 'translateY(0%)',
  unmounted: 'translateY(0%)',
};

const getSheetContentContainerStyle = (side: SheetSide, $slideState: TransitionStatus) => {
  switch (side) {
    case 'start':
      return css`
        max-height: 100%;
        height: 100%;
        bottom: 0;

        /* @noflip */ left: 0;
        inset-inline-start: 0;
        /* @noflip */ right: auto;
        inset-inline-end: auto;

        /*! @noflip */
        transform: ${transformLeftToRight[$slideState]};
        display: flex;
        flex-direction: row-reverse;

        /*! @noflip */
        [dir="rtl"] && {
          /*! @noflip */ transform: ${transformRightToLeft[$slideState]};

          /* @noflip */ right: 0;
          inset-inline-start: 0;
          /* @noflip */ left: auto;
          inset-inline-end: auto;
        }
      `;
    case 'end':
      return css`
        max-height: 100%;
        height: 100%;
        bottom: 0;

        /* @noflip */ left: auto;
        inset-inline-start: auto;
        /* @noflip */ right: 0;
        inset-inline-end: 0;

        /*! @noflip */ transform: ${transformRightToLeft[$slideState]};
        display: flex;
        flex-direction: row;

        /*! @noflip */
        [dir="rtl"] && {
          /*! @noflip */ transform: ${transformLeftToRight[$slideState]};

          /* @noflip */ right: auto;
          inset-inline-start: auto;
          /* @noflip */ left: 0;
          inset-inline-end: 0;
        }
      `;
    case 'bottom':
    default:
      return css`
        max-width: 100vw;
        width: 100vw;
        bottom: 0;
        left: 0;
        /*! @noflip */ transform: ${transformBottomToTop[$slideState]}
      `;
  }
};

export const SheetContentContainer = styled('div')<SheetContentContainerProps>`
  border-radius: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
  outline: none;
  position: absolute;
  ${({ side, $slideState }) => getSheetContentContainerStyle(side, $slideState)};
  @media (prefers-reduced-motion: no-preference) {
    transition: transform 200ms ease-in-out;
  }
`;
SheetContentContainer.displayName = 'SheetContentContainer';

const getSheetContentStyle = (side: SheetSide, $maxSize: MaxSizeProp, padding: string) => {
  switch (side) {
    case 'start':
    case 'end':
      return css`
        max-width: ${`calc(${$maxSize === 'viewport' ? '100vw' : '80vw'} + max(${padding},env(safe-area-inset-left)) + max(${padding},env(safe-area-inset-right)))`};
        height: 100%;
      `;
    case 'bottom':
    default:
      return css`
        max-height: ${`calc(${$maxSize === 'viewport' ? '100vh' : '65vh'} + ${padding} + max(${padding},env(safe-area-inset-bottom)))`};
        ${/* iOS workaround */ $maxSize === 'viewport' ? 'max-height: -webkit-fill-available;' : ''}
      `;
  }
};

export interface SheetContentSurfaceProps {
  side: SheetSide;
  $maxSize: MaxSizeProp;
  $padding: SheetPaddingOptions;
}

export const SheetContentSurface = styled(SurfaceLight)<SheetContentSurfaceProps>`
  ${({
    theme, side, $maxSize, $padding,
  }) => getSheetContentStyle(side, $maxSize, theme.spacing[$padding])};
  overflow-y: auto;
  pointer-events: auto;
`;
SheetContentSurface.displayName = 'SheetContentSurface';

export interface SheetContentProps {
  $padding: SheetPaddingOptions;
}

export const SheetContent = styled.div<SheetContentProps>`
  padding: ${({ $padding, theme }) => theme.spacing[$padding]};
  padding-inline-end: max(${({ $padding, theme }) => theme.spacing[$padding]},env(safe-area-inset-right));
  padding-bottom: max(${({ $padding, theme }) => theme.spacing[$padding]},env(safe-area-inset-bottom));
  padding-inline-start: max(${({ $padding, theme }) => theme.spacing[$padding]},env(safe-area-inset-left));
`;
SheetContent.displayName = 'SheetContent';

export const SheetClose = styled.div`
  position: relative;
  margin-inline-start: auto;
  margin-inline-end: 0;
  display: flex;
`;

export interface CloseButtonProps {
  $focusVisible: boolean;
  side: 'bottom' | 'start' | 'end';
}

const buttonBottom = css`
  margin-block-start: 5px;
  margin-block-end: 5px;
  margin-inline-end: 10px;
  margin-inline-start: auto;
`;

const buttonStart = css`
  margin-block-start: 10px;
  margin-block-end: auto;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
`;

const buttonEnd = css`
  margin-block-start: 10px;
  margin-block-end: auto;
  margin-inline-end: 10px;
  margin-inline-start: 10px;
`;

export const CloseButton = styled('button')
  .attrs(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps({ onFocus, onBlur }, focusProps),
      $focusVisible: isFocusVisible,
      type: 'button',
    });
  })<CloseButtonProps>`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.typography.size.medium};
  padding: ${({ theme }) => theme.spacing.none};
  position: relative;
  ${({ side }) => ({
    bottom: buttonBottom,
    start: buttonStart,
    end: buttonEnd,
  }[side])}


  :focus {
    outline: none;
    ${({ $focusVisible, theme }) => ($focusVisible && css`
      outline: 2px solid ${theme.palette.white};
      outline-offset: 2px;
    `)}
  }

  :hover{
    color: ${({ theme }) => theme.palette.white};
    cursor: pointer;
  }
`;
CloseButton.displayName = 'CloseButton';
