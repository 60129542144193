/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Cancelar"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Salvar"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Personalizado"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Selecione um único dia"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Selecione um mês"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Selecione uma semana"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Últimos 30 dias"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Últimos 7 dias"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Último mês"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Última semana"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Vitalício"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Esta semana"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Hoje"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Ano até a data"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Ontem"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
