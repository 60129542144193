/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEventHandler, FC } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { times } from '@amzn/storm-ui-icons';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import { LoadingIcon } from '../Button/Button'
import Text from '../Text';
import Icon from '../Icon';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../types/TaktProps';
import { createStormTaktId, useTaktId } from '../TaktIdContext';

export interface PillStyleProps {
  size?: 'base' | 'small';
  disabled?: boolean;
  applied?: boolean;
  $displayOnly?: boolean;
  theme?: any;
  $loading?: boolean;
}

export interface PillProps extends TaktProps, Omit<MergeElementProps<'div'>, 'onClick'> {
  /**
   *  the main text displayed in the pill
   * @defaultValue `undefined`
   */
  label?: string;
  /**
   *  the addition text displayed before the main text
   * @defaultValue `undefined`
   */
  prefix?: string;
  /**
   * the addition text displayed after the main text
   * @defaultValue `undefined`
   */
  suffix?: string;
  /**
   * the size styles of the pill padding
   * @defaultValue `"base"`
   */
  size?: 'base' | 'small';
  /**
   * Changes the visual style and `aria-pressed` attribute of the pill
   * to indicate whether it is applied to the current view
   * @defaultValue `true`
   */
  applied?: boolean;
  /**
   * disables the pill when true
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * set the pill to the parent containers width when true
   * @defaultValue `false`
   */
  fullWidth?: boolean;
  /**
   * sets the maximum width the pill is allowed to expand to
   * @defaultValue `500`
   */
  maxWidth?: number;
  /**
   * text used for screen-readers when selecting the close button
   * @defaultValue `"remove"`
   */
  closeLabel?: string;
  /**
   * allows for additional classNames to be added
   * @defaultValue `""`
   */
  className?: string;
  /**
   * function called when the pill is clicked
   * @defaultValue `undefined`
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * function called when the close icon of the pill is clicked
   * @defaultValue `undefined`
   */
  onClose?: MouseEventHandler<HTMLButtonElement>;
  /**
   * To indicate the button is loading.
   * @defaultValue `false`
   */
  loading?: boolean;
  /**
   * Substitute for Button's body when loading is true.
   * @defaultValue `undefined`
   */
  loadingLabel?: string;
}

const isSmallSize = (size?: string): boolean => size === 'small';

const borderRadius = ({ theme }: PillStyleProps) => theme.pill.borderRadius;

const endCapPadding = ({ size, theme }: PillStyleProps) => (
  isSmallSize(size) ? theme.pill.small.paddingHorizontal : theme.pill.paddingHorizontal
);
const bgColor = ({
  $loading,
  disabled,
  applied,
  theme,
}: PillStyleProps) => {
  switch (true) {
    case $loading: return theme.pill.disabled.bgColor;
    case disabled: return theme.pill.disabled.bgColor;
    case applied: return theme.pill.applied.bgColor;
    default: return theme.pill.bgColor;
  }
};
const textColor = ({ $loading, disabled, theme }: PillStyleProps) => {
  switch (true) {
    case $loading: return theme.pill.disabled.textColor;
    case disabled: return theme.pill.disabled.textColor;
    default: return theme.pill.textColor;
  }
};
const desktopHeight = ({ size, theme }: PillStyleProps) => (
  isSmallSize(size) ? theme.pill.small.height : theme.pill.height
);
const mobileHeight = ({ size, theme }: PillStyleProps) => (
  isSmallSize(size) ? theme.pill.mobile.small.height : theme.pill.height
);

interface SelectWidth {
  fullWidth?:boolean;
  maxWidth?:number;
  theme:any;
}

const selectWidth = ({ fullWidth, maxWidth, theme }:SelectWidth) => {
  if (fullWidth) {
    /**
     * there is an issue with flex children shrinking beyond their content.
     * to fix this, when `fullWidth` is set, we set a "min-width: 0" on flexed child elements
     */
    return 'min-width: 0';
  }
  if (typeof maxWidth === 'number') {
    return `max-width: ${maxWidth}px`;
  }
  return '';
};

const PillText = styled(Text)
  .attrs(props => ({
    ...props,
    type: 'span',
  }))`
  display: block;
  color: inherit;
  line-height: 1.2;
`;

const PillFrame = styled('div')<PillStyleProps>`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-height: ${desktopHeight};
  background: ${bgColor};
  isolation: isolate;

  ${({
    theme,
    $loading,
    disabled,
    $displayOnly,
  }) => !$loading && !disabled && !$displayOnly && `
    :hover {
      background: ${theme.pill.hover.bgColor};
      box-shadow: ${theme.pill.hover.boxShadow};
    }
  `}

  ${isMobile(css`
    min-height: ${mobileHeight};
  `)}

  /*! @noflip */ border-top-right-radius: ${borderRadius};
  /*! @noflip */ border-bottom-right-radius: ${borderRadius};
  /*! @noflip */ border-top-left-radius: ${borderRadius};
  /*! @noflip */ border-bottom-left-radius: ${borderRadius};

  > * {
    color: ${textColor};
    border: none;
    margin: 0;
    background: ${bgColor};
    padding: 0 0.25em;
  }
  > *:first-child {
    padding-inline-start: ${endCapPadding};
    /*! @noflip */ border-top-left-radius: ${borderRadius};
    /*! @noflip */ border-bottom-left-radius: ${borderRadius};
  }
  > *:last-child {
    padding-inline-end: ${endCapPadding};
    /*! @noflip */ border-top-right-radius: ${borderRadius};
    /*! @noflip */ border-bottom-right-radius: ${borderRadius};
  }

  /*! @noflip */
  [dir="rtl"] && {
    > *:first-child {
      /*! @noflip */ border-top-left-radius: 0;
      /*! @noflip */ border-bottom-left-radius: 0;
      /*! @noflip */ border-top-right-radius: ${borderRadius};
      /*! @noflip */ border-bottom-right-radius: ${borderRadius};
    }

    > *:last-child {
      /*! @noflip */ border-top-right-radius: 0;
      /*! @noflip */ border-bottom-right-radius: 0;
      /*! @noflip */ border-top-left-radius: ${borderRadius};
      /*! @noflip */ border-bottom-left-radius: ${borderRadius};
    }

    > *:first-child:last-child {
      /*! @noflip */ border-top-right-radius: ${borderRadius};
      /*! @noflip */ border-bottom-right-radius: ${borderRadius};
      /*! @noflip */ border-top-left-radius: ${borderRadius};
      /*! @noflip */ border-bottom-left-radius: ${borderRadius};
    }
  }
`;
PillFrame.displayName = 'PillFrame';

export interface NonClickableContentProps{
  disabled?: boolean;
  maxWidth?: number;
  fullWidth?: boolean;
  title?: string;
  tabIndex?: number;
}

const NonClickableContent = styled('div')<NonClickableContentProps>`
  flex: 1 1 auto;
  ${selectWidth};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export interface ClickableContentProps extends TaktProps, NonClickableContentProps {
  applied?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  $loading?: boolean;
}

export interface ClickableContentAttrsProps extends ClickableContentProps {
  $focusVisible: boolean;
}

const ClickableContent:FC<React.PropsWithChildren<ClickableContentProps>> = styled('button')
  .attrs<ClickableContentProps>(
    props => {
      const { isFocusVisible, focusProps } = useFocusRing();
      return mergeProps(
        props,
        focusProps,
        {
          $focusVisible: isFocusVisible,
          type: 'button',
        },
      );
    },
  )<ClickableContentAttrsProps>`
  flex: 1 1 auto;
  ${selectWidth};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  outline: none;

  cursor: ${({ disabled, $loading, onClick }) => {
    if (disabled) return 'not-allowed';
    if ($loading) return 'not-allowed';
    return onClick ? 'pointer' : 'default';
  }};

  :focus {
    ${({ $focusVisible = false }) => ($focusVisible && css`
      ${focusOutline}
      z-index: 1;
    `)}
  }
`;

interface ContentType extends Omit<ClickableContentProps, '$loading'> {
  loading?: boolean;
}

const Content:FC<React.PropsWithChildren<ContentType>> = ({
  disabled,
  title,
  maxWidth,
  fullWidth,
  onClick,
  applied,
  taktId,
  taktValue,
  loading,
  ...rest
}) => {
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('pill') })
  if (onClick) {
    return (
      <ClickableContent
        {...getDataTaktAttributes({ taktValue })}
        {...rest}
        disabled={disabled}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        title={title}
        onClick={loading ? undefined : onClick}
        aria-pressed={applied}
        $loading={loading}
        {...(loading) && { 'aria-live': 'assertive' }}
        {...(loading) && { 'aria-disabled': true }}
      />
    );
  }
  return (
    <NonClickableContent
      {...rest}
      disabled={disabled}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      title={title}
      {...(loading) && { 'aria-live': 'assertive' }}
    />
  );
};
Content.propTypes = {
  title: PT.string,
  applied: PT.bool,
  disabled: PT.bool,
  fullWidth: PT.bool,
  maxWidth: PT.number,
  onClick: PT.func,
  tabIndex: PT.number,
  loading: PT.bool,
};

Content.defaultProps = {
  title: undefined,
  applied: true,
  disabled: false,
  fullWidth: false,
  maxWidth: undefined,
  onClick: undefined,
  tabIndex: undefined,
  loading: false,
};

const LabelText = styled(PillText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
LabelText.displayName = 'LabelText';

const PrefixText = styled(PillText)`
  white-space: nowrap;
  margin-inline-end: ${({ theme }) => theme.pill.prefixPaddingEnd};
  font-weight: bold;
`;
PrefixText.displayName = 'PrefixText';

const SuffixText = styled(PillText)`
  white-space: nowrap;
  margin-inline-start: ${({ theme }) => theme.pill.suffixPaddingStart};
  font-weight: bold;
`;
SuffixText.displayName = 'SuffixText';

const Pill: React.FC<React.PropsWithChildren<PillProps>> = ({
  label,
  prefix,
  suffix,
  size = 'base',
  applied,
  disabled = false,
  fullWidth = false,
  maxWidth = 500,
  className = '',
  onClick,
  onClose,
  closeLabel = 'remove',
  tabIndex,
  taktId,
  taktValue,
  loading,
  loadingLabel,
  ...rest
}: PillProps) => (
  <PillFrame
    {...rest}
    size={size}
    className={className}
    disabled={disabled}
    applied={applied}
    $displayOnly={(typeof onClick !== 'function' && typeof onClose !== 'function') || loading}
    $loading={loading}
  >
    <Content
      taktId={taktId}
      taktValue={taktValue}
      disabled={disabled}
      title={label}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClick={onClick}
      loading={loading}
    >
      {loading && <LoadingIcon />}
      {(loading && loadingLabel) ? <LabelText>{loadingLabel}</LabelText> : (
        <>
          { prefix && <PrefixText>{prefix}{label ? ':' : ''}</PrefixText> }
          <LabelText>{label}</LabelText>
          { suffix && <SuffixText>{suffix}</SuffixText> }
        </>
      )}
    </Content>
    {
      onClose && (
        <ClickableContent
          disabled={loading || disabled}
          onClick={onClose}
          tabIndex={tabIndex}
        >
          <Text type="sr-only">{closeLabel}</Text>
          <Icon type={times} aria-hidden />
        </ClickableContent>
      )
    }
  </PillFrame>
);

Pill.propTypes = {
  label: PT.string,
  prefix: PT.string,
  suffix: PT.string,
  size: PT.oneOf([
    'base',
    'small',
  ]),
  applied: PT.bool,
  disabled: PT.bool,
  fullWidth: PT.bool,
  maxWidth: PT.number,
  className: PT.string,
  onClick: PT.func,
  onClose: PT.func,
  closeLabel: PT.string,
  /**
   * To indicate the button is loading.
   */
  loading: PT.bool,
  /**
   * Substitute for Button's body when loading is true.
   */
  loadingLabel: PT.string,
};

Pill.defaultProps = {
  label: undefined,
  prefix: undefined,
  suffix: undefined,
  size: 'base',
  applied: true,
  disabled: false,
  fullWidth: false,
  maxWidth: undefined,
  closeLabel: 'remove',
  className: '',
  onClick: undefined,
  onClose: undefined,
  loading: false,
  loadingLabel: undefined,
};

Pill.displayName = 'Pill';

export default Pill;
