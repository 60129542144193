/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Avbryt"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Spara"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Anpassad"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Välj en enda dag"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Välj en månad"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Välj en vecka"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Senaste 30 dagarna"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Senaste sju dagarna"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Senaste månaden"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Förra veckan"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Livstid"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Den här veckan"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Idag"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "År till dags dato"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Igår"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
