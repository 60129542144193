export const BASE_INPUT_HEIGHT = 29;

export const TOOLTIP_DELAY_MS = 1000;

export const MAX_ATTACHMENTS = 6;

export const MAX_SIZE_MB = 5 * 1000 * 1000;

export const MAX_ROWS = 4;

export const INPUT_PADDING = 10;
