import styled, { css } from 'styled-components';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils-v3';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

export type StyledBasePaginationProps = MergeStyledComponentElementProps<'div'>;

export const StyledBasePagination = styled('div')`
  display: flex;
  width: fit-content;
  flex-wrap: wrap;

  && > * {
    margin-inline-end: ${({ theme }) => theme.pagination.paginationItemMargin};
  }

  && > *:last-child {
    margin-inline-end: 0;
  }

  ${isMobile(css`
    flex-direction: column;
    width: auto;
    align-items: center;


    && > * {
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-bottom: ${({ theme }) => theme.pagination.mobile.paginationItemMargin};
    }
    && > *:last-child {
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-bottom: 0;
    }
  `)}
`;

export interface PaginationNavGroupProps extends MergeStyledComponentElementProps<'nav'> {}

export const PaginationNavGroup = styled('nav')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  && > * {
    margin-inline-end: ${({ theme }) => theme.pagination.paginationNavGroupMargin};
  }

  && > *:last-child {
    margin-inline-end: 0;
  }
`;
