import { palette, color } from '../base/color';
import { link, typography } from '../base/typography';
import { spacing, spacingValues } from '../base/spacing';
import { focus } from '../base/focus';
import globals from '../base/globals';
import alertStyles from '../component/alert';
import breadcrumbs from '../component/breadcrumb';
import { button as buttonStyles } from '../component/button';
import { card } from '../component/card';
import checkboxStyles from '../component/checkbox';
import circularProgressIndicatorStyles from '../component/circularProgressIndicator';
import { datepicker } from '../component/datepicker';
import { dividerStyles } from '../component/divider';
import { dropdownStyles } from '../component/dropdown';
import { helptipStyles } from '../component/helptip';
import { icon } from '../component/icon';
import inputStyles from '../component/input';
import labelStyles from '../component/label';
import modal from '../component/modal';
import paginationStyles from '../component/pagination';
import pillLegacyStyles from '../component/pillLegacy';
import pillStyles from '../component/pill';
import popoverStyles from '../component/popover';
import { portalStyles } from '../component/portal';
import progressIndicatorStyles from '../component/progressIndicator';
import { radioStyles } from '../component/radio';
import { rangeSlider } from '../component/rangeSlider';
import sectionExpanderStyles from '../component/sectionExpander';
import { sideNavStyles, sideNavItemStyles } from '../component/sideNavigation';
import searchInputStyles from '../component/searchInput';
import snackbar from '../component/snackbar';
import sortableListStyles from '../component/sortableList';
import starRatingStyles from '../component/starRating';
import stepsStyles from '../component/steps';
import { surfaceStyles } from '../component/surface';
import switchStyles from '../component/switch';
import { tableStyles } from '../component/table';
import tabNavigationStyles from '../component/tabNavigation';
import { tagStyles } from '../component/tag';
import textAreaStyles from '../component/textarea';
import tooltip from '../component/tooltip';
import { touchLink } from '../component/touchLink';
import { tree } from '../component/tree';
import treeViewStyles from '../component/treeView';
import anchoredAlertStyles from '../component/anchoredAlert';
import secondaryView from '../component/secondaryView';
import { splitButton } from '../component/splitButton';
import peekabooStickyTab from '../component/peekabooStickyTab';
import pageLayout from '../component/pageLayout';
import surfaceDarkStyles from '../component/surfaceDark';
import surfaceDarkInfoStyles from '../component/surfaceDarkInfo';
import surfaceLightStyles from '../component/surfaceLight';

const theme = {
  pageLayout,
  palette,
  spacingValues,
  spacing,
  globals,
  color,
  typography,
  breadcrumbs,
  button: buttonStyles,
  card,
  divider: dividerStyles,
  dropdown: dropdownStyles,
  form: {
    label: labelStyles,
    input: inputStyles,
    textarea: textAreaStyles,
    search: searchInputStyles,
    checkbox: checkboxStyles,
    radio: radioStyles,
  },
  icon,
  message: alertStyles,
  modal,
  navigation: {
    tabs: tabNavigationStyles,
  },
  pagination: paginationStyles,
  pillLegacy: pillLegacyStyles,
  pillv2Beta: pillStyles, // adding in for TropicalUI support, which references this theme value
  pill: pillStyles,
  popover: popoverStyles,
  portal: portalStyles,
  switch: switchStyles,
  tooltip,
  helptip: helptipStyles,
  datepicker,
  starRating: starRatingStyles,
  sortableList: sortableListStyles,
  sideNav: sideNavStyles,
  sideNavItem: sideNavItemStyles,
  surface: surfaceStyles,
  surfaceLight: surfaceLightStyles,
  surfaceDark: surfaceDarkStyles,
  surfaceDarkInfo: surfaceDarkInfoStyles,
  rangeSlider,
  table: tableStyles,
  tree,
  treeView: treeViewStyles,
  sectionExpander: sectionExpanderStyles,
  tag: tagStyles,
  touchLink,
  steps: stepsStyles,
  focusVisible: focus,
  progressIndicator: progressIndicatorStyles,
  circularProgressIndicator: circularProgressIndicatorStyles,
  snackbar,
  anchoredAlert: anchoredAlertStyles,
  link,
  secondaryView,
  splitButton,
  peekabooStickyTab,
};

export default theme;

export type Theme = typeof theme
