import React, { createContext, useContext, ReactNode } from 'react';
import { useIsMobile } from '@amzn/storm-ui';

const MultiSelectIsMobileContext = createContext<boolean>(false);

export interface MultiSelectIsMobileProvider {
  mobileFullScreen?: boolean;
  children: ReactNode;
}

export const MultiSelectIsMobileProvider = ({ mobileFullScreen, children }: MultiSelectIsMobileProvider) => {
  const isMobile = useIsMobile();
  const multiSelectIsMobile = (mobileFullScreen && isMobile) || false;

  return (
    <MultiSelectIsMobileContext.Provider value={multiSelectIsMobile}>
      {children}
    </MultiSelectIsMobileContext.Provider>
  );
}
MultiSelectIsMobileProvider.defaultProps = {
  mobileFullScreen: false,
}

export const useMultiSelectIsMobile = () => {
  const isMobile = useContext(MultiSelectIsMobileContext);

  return isMobile;
}

export interface MultiSelectIsMobileConsumerProps {
  children: (isMobile: boolean) => ReactNode;
}

export const MultiSelectIsMobileConsumer = ({ children }: MultiSelectIsMobileConsumerProps) => {
  const isMobile = useMultiSelectIsMobile();

  return (
    <>
      {children(isMobile)}
    </>
  );
}

export function isMultiSelectMobile(style: any) {
  return ({ $isMobile, theme }: any) => {
    const hasMobileTheme = !!theme?.mobile;
    if (hasMobileTheme && $isMobile) {
      return [style];
    }
    return null;
  };
}

export interface MultiSelectMobileProps {
  $isMobile: boolean
}
