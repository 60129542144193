import { Validator, checkPropTypes } from 'prop-types';

type TypeCheckers = {
  [propName: string]: Validator<any>;
}

/**
 * This prop-type validator checks that at least one of the prop is not undefined.
 */
function oneIsDefined(typeCheckers: TypeCheckers): TypeCheckers {
  /*
   * Iterate the type checker object and wrap each one in function that checks if one of the
   * props is not null. Return a object of wrapped type checkers.
   */

  const oneIsDefinedPropNames = Object.keys(typeCheckers);
  return oneIsDefinedPropNames
    .reduce((wrappedTypeCheckers, propName) => Object.assign(wrappedTypeCheckers, {

      /**
       * Define a new prop type checker that will wrap the ones given.
       */
      [propName]: (props: any, localPropName: string, componentName: string) => {
        /**  create a list of props that will be checked */
        const propNamesToCheck = Object.keys(props)
          .filter(propNameToCheck => oneIsDefinedPropNames.includes(propNameToCheck));

        /* test for at least one defined instance of each target prop */
        if (
          !propNamesToCheck
            .reduce(
              (propExists: boolean, propNameKey: string) => (
                propExists || props[propNameKey] !== undefined
              ),
              false,
            )
        ) {
          /**
           * No prop, throw a error to be shown in the console
           */
          return new Error(
            `Prop ${
              oneIsDefinedPropNames
                .map((propNameToCheck: string) => `\`${propNameToCheck}\``).join(' or ')
            } must be supplied to \`${componentName}\`. `,
          );
        }
        /**
         * Run the default checkPropTypes to makes sure that if the prop is defined, the type is
         * correct.
         */
        // eslint-disable-next-line react/destructuring-assignment
        if (props[propName] !== undefined) {
          checkPropTypes(
            { [propName]: typeCheckers[propName] },
            props,
            propName,
            componentName,
          );
        }
        /*
         * If nothing incorrect is found, we return null to the prop-type system.
         */
        return null;
      },
    }), {});
}

export default oneIsDefined;
