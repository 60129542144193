import React, { ReactNode } from 'react';
import { angleInlineEnd } from '@amzn/storm-ui-icons-v3';
import {
  TouchLinkContent,
  TouchLinkIcon,
} from './TouchLink.styles';

export interface TouchLinkBodyProps {
  children: ReactNode;
}

const TouchLinkBody = ({ children }: TouchLinkBodyProps) => (
  <TouchLinkContent>
    {children}
    <TouchLinkIcon type={angleInlineEnd} size="lg" />
  </TouchLinkContent>
)

export default TouchLinkBody;
