import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';

export interface TouchLinkGroupProps extends MergeElementProps<'ul'> {
  /**
   * The React nodes that are rendered by the TouchlinkGroup.
   */
  children: ReactNode;
}

const TouchLinkGroupItem = styled('li')`
  border-radius: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.touchLink.borderColor};
  list-style: none;
  margin: 0;
  word-wrap: break-word;

  > a {
    border-radius: 0px;
  }

  :first-of-type, :first-of-type > a {
    border-radius: ${({ theme }) => theme.touchLink.borderRadius} ${({ theme }) => theme.touchLink.borderRadius} 0 0;
  }

  :last-of-type, :last-of-type > a {
    border-bottom: none;
    border-radius: 0 0 ${({ theme }) => theme.touchLink.borderRadius} ${({ theme }) => theme.touchLink.borderRadius};
  }
`;

const TouchLinkGroupComponent: FC<React.PropsWithChildren<TouchLinkGroupProps>> = ({ children, ...rest }) => (
  <ul {...rest}>
    {children && React.Children.map(children, child => {
      if (!React.isValidElement(child)) return null;
      return (
        <TouchLinkGroupItem>
          {React.cloneElement(child, { ...child.props })}
        </TouchLinkGroupItem>
      );
    })}
  </ul>
);

const TouchLinkGroup = styled(TouchLinkGroupComponent)`
  background-color: ${({ theme }) => theme.touchLink.backgroundColor};
  border: 1px solid ${({ theme }) => theme.touchLink.group.borderColor};
  border-radius: ${({ theme }) => theme.touchLink.borderRadius};
  display: block;
  list-style-type: none;
  margin-left: 0;
  padding: 0;
  position: relative;

  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
`;

TouchLinkGroup.displayName = 'TouchLinkGroup';
TouchLinkGroup.propTypes = {
  /**
   * The React nodes that are rendered by the TouchLinkGroup.
   */
  children: PropTypes.node as PropTypes.Validator<ReactNode>,
};

export default TouchLinkGroup;
