import MobileMode from '../../MobileThemeProvider/MobileMode';

export default function isNotMobile(style: any) {
  return ({ theme }: any) => {
    const mode = theme?.mobile?.mode;
    if (mode === MobileMode.Auto) {
      return ['@media ', theme.mobile.inverseMediaQuery, '{', style, '}'];
    }
    if (mode === undefined || mode === MobileMode.Disable) {
      return [style];
    }
    return null;
  };
}
