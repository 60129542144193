import { HTMLAttributes } from 'react';
import PT from 'prop-types';
import { useFocusRing } from '@react-aria/focus';

/*
 * ProvideFocusRing is just a wrapper for `useFocusRing()` so it can be used in class components
 */

interface FocusRingAria {
  isFocused: boolean;
  isFocusVisible: boolean;
  focusProps: HTMLAttributes<HTMLElement>;
}

export interface ProvideFocusRingProps {
  /**
   * @defaultValue `false`
   */
  within?: boolean;
  /**
   * @defaultValue `undefined`
   */
  isTextInput?: boolean;
  /**
   * @defaultValue `undefined`
   */
  autoFocus?: boolean;
  children: (focusRingAria: FocusRingAria) => JSX.Element;
}

function ProvideFocusRing({ children, ...rest }: ProvideFocusRingProps): JSX.Element {
  return children(useFocusRing(rest));
}

ProvideFocusRing.propTypes = {
  within: PT.bool,
  isTextInput: PT.bool,
  autoFocus: PT.bool,
  children: PT.func.isRequired,
};

ProvideFocusRing.defaultProps = {
  within: false,
  isTextInput: undefined,
  autoFocus: undefined,
};

export default ProvideFocusRing;
