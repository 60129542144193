import React, {
  PureComponent, MouseEvent, KeyboardEvent, Ref, ChangeEvent,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  keyboardKeynames as keys,
  noop,
  MergeStyledComponentElementProps,
} from '@amzn/storm-ui-utils';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import focusOutline from '../FocusIndicator/styleMixins/focusOutline';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import type { TaktProps } from '../types/TaktProps';

export interface StyledButtonProps {
  small: boolean;
  $isChecked: boolean;
  disabled: boolean;
}
export interface StyledButtonAttrsProps extends StyledButtonProps {
  $focusVisible: boolean;
}

const NodeSafeHTMLButtonElement = (typeof HTMLButtonElement !== 'undefined' ? HTMLButtonElement : Object) as typeof HTMLButtonElement;

const normalStyles = css`
  padding-top: ${({ theme }) => theme.button.normal.paddingV};
  padding-bottom: ${({ theme }) => theme.button.normal.paddingV};
  padding-inline-start: ${({ theme }) => theme.button.normal.paddingH};
  padding-inline-end: ${({ theme }) => theme.button.normal.paddingH};
  font-size: ${({ theme }) => theme.button.normal.fontSize};
  ${isMobile(css`
    padding-top: ${({ theme }) => theme.button.normal.mobile.paddingV};
    padding-bottom: ${({ theme }) => theme.button.normal.mobile.paddingV};
    padding-inline-start: ${({ theme }) => theme.button.normal.mobile.paddingH};
    padding-inline-end: ${({ theme }) => theme.button.normal.mobile.paddingH};
    font-size: ${({ theme }) => theme.button.normal.mobile.fontSize};
  `)}
`;

const smallStyles = css`
  padding-top: ${({ theme }) => theme.button.small.paddingV};
  padding-bottom: ${({ theme }) => theme.button.small.paddingV};
  padding-inline-start: ${({ theme }) => theme.button.small.paddingH};
  padding-inline-end: ${({ theme }) => theme.button.small.paddingH};
  font-size: ${({ theme }) => theme.button.small.fontSize};
  ${isMobile(css`
    padding-top: ${({ theme }) => theme.button.small.mobile.paddingV};
    padding-bottom: ${({ theme }) => theme.button.small.mobile.paddingV};
    padding-inline-start: ${({ theme }) => theme.button.small.mobile.paddingH};
    padding-inline-end: ${({ theme }) => theme.button.small.mobile.paddingH};
    font-size: ${({ theme }) => theme.button.small.mobile.fontSize};
  `)}
`;

const StyledButton = styled('button')
  .attrs<StyledButtonProps>(({ onFocus, onBlur }) => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return ({
      ...mergeProps({ onFocus, onBlur }, focusProps),
      $focusVisible: isFocusVisible,
    });
  }) <StyledButtonAttrsProps>`
  ${({ theme }) => theme.typography.base};
  display: inline-block;
  position: relative;
  cursor:  pointer;
  color: ${({ theme }) => theme.button.default.color};
  padding-right: ${({ small }) => (small ? '10px' : '13px')};
  padding-left: ${({ small }) => (small ? '10px' : '13px')};
  background: ${({ theme }) => theme.button.default.bg};
  font-weight: normal; /* specified this to override AUI's default <label> style */
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.button.default.borderColor};
  @media (prefers-reduced-motion: no-preference) {
    transition: color 100ms ease, background-color 100ms ease, border-color 100ms ease, box-shadow 100ms ease;
  }
  z-index: ${({ $focusVisible }) => ($focusVisible ? '1' : '0')} ;

  ${({ small }) => (small ? smallStyles : normalStyles)};

  :hover {
    cursor:  ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    box-shadow: 0 0 2px 1px ${({ theme }) => theme.button.boxShadowFocus};
    background: ${({ theme }) => theme.button.default.bgHover};
  }

  ${({ theme, $isChecked }) => $isChecked && `
    background: ${theme.button.group.bgActive};
    border: 1px solid #6c6e73;
    box-shadow: ${theme.button.group.boxShadowActive};
    color: ${theme.button.group.colorActive};

    :hover {
      box-shadow: ${theme.button.group.boxShadowActive};
      background: ${theme.button.group.bgActive};
    }
  `}

  :focus {
    ${({ $focusVisible }) => ($focusVisible && focusOutline)}
  }

  ${({ theme, disabled }) => disabled && `
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    color: ${theme.button.default.colorDisabled};
    border-color: ${theme.button.default.borderColorDisabled};
    background: ${theme.button.default.bgDisabled};
    pointer-events: all;
    /* ↑ override pointer-events rule for buttons */
    :hover {
      background: ${theme.button.default.bgDisabled};
    }
  `};
  }

  /* Override Safari User Agent Styles for <button /> */
  margin: 0;
`;
StyledButton.displayName = 'Wrapper';

export interface ButtonGroupItemBaseProps {
  /**
     * The id of the button element. This is set automatically by ButtonGroup.
     * @defaultValue `undefined`
     */
  id?: string;
  /**
     * The state indicating the button is active. This is set automatically by ButtonGroup.
     * @defaultValue `undefined`
     */
  isChecked?: boolean;
  /**
     * Called when the user changes the component state. This is set automatically by ButtonGroup.
     * @defaultValue `undefined`
     */
  onChange?: (
    value: string | undefined,
    event: ChangeEvent<HTMLButtonElement>
  ) => void;
  /**
     * The name of the ButtonGroup this item belongs to. This is set automatically by ButtonGroup.
     * @defaultValue `undefined`
     */
  name?: string;
  /**
    * Determines size of button group.
    * @defaultValue `false`
    */
  small?: boolean,
  /**
     * Red to the actual button element.
     * @defaultValue `() => undefined`
     */
  inputRef?: Ref<HTMLButtonElement>;
  /**
     * The value returned when the button is clicked. This is set automatically by ButtonGroup.
     */
  value?: string,
  /**
     * Set this to true to indicate the button is disabled
     * @defaultValue `false`
     */
  disabled?: boolean;
}

export interface ButtonGroupItemMergedProps extends TaktProps, MergeStyledComponentElementProps<'button', ButtonGroupItemBaseProps> {
  /**
     * The state indicating the button is active. This is set automatically by ButtonGroup.
     * @defaultValue `undefined`
     */
  isChecked?: boolean;
}

export default class ButtonGroupItem extends PureComponent<ButtonGroupItemMergedProps> {
  static propTypes = {
    /**
     * The id of the button element. This is set automatically by ButtonGroup.
     */
    id: PT.string,
    /**
     * The state indicating the button is active. This is set automatically by ButtonGroup.
     */
    isChecked: PT.bool,
    /**
     * Called when the user changes the component state. This is set automatically by ButtonGroup.
     */
    onChange: PT.func,
    /**
     * The name of the ButtonGroup this item belongs to. This is set automatically by ButtonGroup.
     */
    name: PT.string,
    /**
    * Determines size of button group.
    */
    small: PT.bool,
    /**
     * Red to the actual button element.
     */
    inputRef: PT.oneOfType([
      PT.func,
      PT.shape({ current: (PT.instanceOf(NodeSafeHTMLButtonElement)) }),
    ]),
    /**
     * Text or icon of the button.
     */
    children: PT.node.isRequired,
    /**
     * The value returned when the button is clicked. This is set automatically by ButtonGroup.
     */
    value: PT.string.isRequired,
    /**
     * Set this to true to indicate the button is disabled
     */
    disabled: PT.bool,
  }

  static defaultProps = {
    inputRef: noop,
    id: undefined,
    isChecked: undefined,
    onChange: undefined,
    name: undefined,
    small: false,
    disabled: false,
  }

  handleKeyUp = (event: KeyboardEvent<HTMLButtonElement>): void => {
    const { key } = event;
    const { onChange = noop, value } = this.props;

    if (key === keys.ENTER || key === keys.SPACE) {
      onChange(value, event as unknown as ChangeEvent<HTMLButtonElement>);
      event.preventDefault();
    }
  };

  handleChange = (event: MouseEvent<HTMLButtonElement>): void => {
    const { onChange, value } = this.props;
    if (onChange) {
      /** TODO: Fix this type in Storm 4.x */
      onChange(value, event as unknown as ChangeEvent<HTMLButtonElement>);
    }
  }

  render(): JSX.Element {
    const {
      id,
      value,
      isChecked,
      name,
      small,
      disabled,
      inputRef,
      children,
      taktId,
      taktValue,
      ...rest
    } = this.props;

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('button-group-item')}>
        {({ getDataTaktAttributes }) => (
          <StyledButton
            type="button"
            id={id}
            role="radio"
            aria-checked={isChecked}
            $isChecked={isChecked}
            ref={inputRef}
            name={name}
            small={small}
            onClick={this.handleChange}
            onKeyUp={this.handleKeyUp}
            value={value}
            disabled={disabled}
            tabIndex={isChecked ? 0 : -1}
            {...getDataTaktAttributes()}
            {...rest}
          >
            {children}
          </StyledButton>
        )}
      </TaktIdConsumer>
    );
  }
}
