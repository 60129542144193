import React, {
  Children,
  cloneElement,
  isValidElement,
  PureComponent,
  ReactNode,
  MouseEvent,
  MouseEventHandler,
} from 'react';
import PT from 'prop-types';
import { isElementOf, MergeElementProps } from '@amzn/storm-ui-utils';
import type { TaktProps } from '../types/TaktProps';
import CardContainer from './CardContainer';
import {
  CardBodyStyled,
} from './Card.styles';
import CardHeader from './CardHeader';
import { CardPadding } from './types';

const isCardHeader = isElementOf(CardHeader);

export interface CardProps extends TaktProps, Omit<MergeElementProps<'div'>, 'ref'>{
  /**
     * This is the content that will be shown in the Card. If you want to have
     * a Card Header, the first child must be a `<CardHeader />`
     */
  children: ReactNode;
  /**
     * Specifies whether the card is collapsed. When `true` only the `<CardHeader />`
     * is displayed.
     * @defaultValue `false`
     */
  isCollapsed?: boolean;
  /**
     * Specifies that the card can be collapsed. The `<CardHeader />` component then
     * renders as a click target which collapses the card when clicked.
     * @defaultValue `false`
     */
  collapsable?: boolean;
  /**
     * Event handler that is triggered when the user clicks the header of a collapsable card.
     * @defaultValue `undefined`
     */
  handleCollapseChanged?: MouseEventHandler;
  /**
     * Sets the padding around the card's content. If the `<Card />` has a `<CardHeader />` then
     * the padding applies to that as well.
     * @defaultValue `"xlarge"`
     */
  padding?: CardPadding;
}

export default class Card extends PureComponent<CardProps> {
  static propTypes = {
    /**
     * This is the content that will be shown in the Card. If you want to have
     * a Card Header, the first child must be a `<CardHeader />`
     */
    children: PT.node.isRequired,
    /**
     * Specifies whether the card is collapsed. When `true` only the `<CardHeader />`
     * is displayed.
     */
    isCollapsed: PT.bool,
    /**
     * Specifies that the card can be collapsed. The `<CardHeader />` component then
     * renders as a click target which collapses the card when clicked.
     */
    collapsable: PT.bool,
    /**
     * Event handler that is triggered when the user clicks the header of a collapsable card.
     */
    handleCollapseChanged: PT.func,
    /**
     * Sets the padding around the card's content. If the `<Card />` has a `<CardHeader />` then
     * the padding applies to that as well.
     */
    padding: PT.oneOfType([
      PT.number,
      PT.oneOf([
        'none',
        'micro',
        'mini',
        'small',
        'base',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
      ]),
    ]),
  }

  static defaultProps = {
    isCollapsed: false,
    collapsable: false,
    handleCollapseChanged: undefined,
    padding: 'xlarge',
  }

  onCollapse = (event: MouseEvent<HTMLButtonElement>): void => {
    const { collapsable, handleCollapseChanged } = this.props;
    if (collapsable && handleCollapseChanged) {
      handleCollapseChanged(event);
    }
  }

  render(): JSX.Element {
    const {
      children,
      collapsable,
      isCollapsed,
      handleCollapseChanged,
      padding,
      ...rest
    } = this.props;

    let cardHeaderComponent: JSX.Element | undefined;
    const childrenComponents: (JSX.Element | string)[] = [];

    Children.forEach(children, child => {
      if (typeof child === 'string') {
        childrenComponents.push(child);
      }
      if (!isValidElement(child)) {
        return null;
      }

      if (!cardHeaderComponent && isCardHeader(child)) {
        cardHeaderComponent = child;
      } else {
        childrenComponents.push(child);
      }

      return null;
    });

    // To be collapsible, there must be two or more children, a header and the rest of the content
    if (cardHeaderComponent && collapsable) {
      return (
        <CardContainer
          {...rest}
          isCollapsed={isCollapsed}
        >
          {cardHeaderComponent && cloneElement(
            cardHeaderComponent,
            {
              collapsable,
              isCollapsed,
              onCollapse: this.onCollapse,
              cardPadding: padding,
            },
          )}
          <CardBodyStyled visible={!isCollapsed} padding={padding}>
            { childrenComponents }
          </CardBodyStyled>
        </CardContainer>
      );
    }
    return (
      <CardContainer {...rest}>
        {cardHeaderComponent && cloneElement(
          cardHeaderComponent,
          {
            cardPadding: padding,
          },
        )}
        <CardBodyStyled padding={padding}>
          {childrenComponents}
        </CardBodyStyled>
      </CardContainer>
    );
  }
}
