/* eslint-disable id-length */
import {
  Icon,
  InputFormGroup,
  TextButton,
  createStormTaktId,
  useTaktId,
} from '@amzn/storm-ui-v3';
import { calendarAlt } from '@amzn/storm-ui-icons-v3';
import React, { FC } from 'react';
import styled from 'styled-components';
import useDateInput from '../../hooks/useDateInput';
import useDatePicker from '../../hooks/useDatePicker';

const PrefixButton = styled(TextButton)`
  color: ${({ theme }) => theme.datepicker.navigationColor};
`;

const Root: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    id,
    prefixAriaLabel,
    label,
    onClick,
    onKeyDown,
    onKeyDownPrefix,
    onKeyUpPrefix,
    inputRef,
    value,
    disabled,
    handleInputChange,
    placeholder,
    onBlur,
    numberOfMonths,
    monthInView,
    minDate,
    maxDate,
    isOutsideRange,
    locale,
    orientation,
    today,
    zone,
    ...rest
  } = useDateInput();
  const { taktId, taktValue } = useDatePicker();
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-picker-input') });

  return (
    <InputFormGroup
      {...rest}
      id={id}
      label={label}
      value={value}
      readOnly
      inputRef={inputRef}
      onClick={onClick}
      disabled={disabled}
      placeholder={placeholder}
      onChange={handleInputChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      prefix={(
        <PrefixButton
          aria-label={prefixAriaLabel}
          disabled={disabled}
          onClick={onClick}
          onKeyDown={onKeyDownPrefix}
          onKeyUp={onKeyUpPrefix}
          {...getDataTaktAttributes({ taktValue })}
        >
          <Icon type={calendarAlt} />
        </PrefixButton>
      )}
    />
  );
};

export default Root;
