import React, { Component, ReactNode } from 'react';
import emptyIcon from '../utils/emptyIcon';
import renderIconSVG from '../utils/renderIconSVG';

const rawIconErrorData = {
  __html: renderIconSVG({
    prefix: 'far',
    iconName: 'error-icon',
    icon: emptyIcon,
  }, undefined, ''),
};

export interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<{ children?: ReactNode }, ErrorBoundaryState> {
  static defaultProps: { children: undefined; };

  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    const { hasError } = this.state;
    if (hasError) {
      // eslint-disable-next-line react/no-danger
      return <span dangerouslySetInnerHTML={rawIconErrorData} data-testid="storm-ui-icon-error-icon" />;
    }

    const { children } = this.props;

    return children;
  }
}
