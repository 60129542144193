import React, {
  ComponentPropsWithoutRef,
  PureComponent,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';

const StyledList = styled.ol`
  ${({ theme }) => theme.typography.base}
  color: ${({ theme }) => theme.typography.color.base};
  margin: 0;
  margin-inline-start: 1.5em;
  display: block;
  li & li {
    list-style: lower-alpha;
    li & {
      list-style: lower-roman;
    }
  }
  ${isMobile(css`${({ theme }) => theme.typography.mobile.base}`)}
`;

export interface OrderedListProps extends TaktProps, ComponentPropsWithoutRef<'ol'> {}
export default class OrderedList extends PureComponent<OrderedListProps> {
  static propTypes = {
    children: PT.node.isRequired,
  };

  render(): JSX.Element {
    const { children, taktId, taktValue, ...rest } = this.props;

    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('ordered-list')}>
        <StyledList {...rest}>
          {children}
        </StyledList>
      </TaktIdProvider>
    );
  }
}
