/* eslint-disable id-length */
import React, {
  FC, ReactNode, useCallback, useState, MouseEvent, ChangeEvent,
} from 'react';
import DateRangeContext from './DateRangeContext';
import {
  AllPickerProps,
  UnresolvedPresetRange,
  DateRangeProps,
  PresetRange,
  ISODateRange,
  ISODate,
  MultiDateSelector,
  DateRange,
  ISOPresetRange,
} from '../../types';
import isDateRange from '../../utils/isDateRange';
import addToRange from '../../utils/addToRange';
import formatRangeToTimeZone from '../../utils/formatRangeToTimeZone';
import resolvePreset from '../../utils/resolvePreset';
import presets from '../../components/DateRangePicker/presetRanges';
import useNavigation from '../../hooks/useNavigation';
import useDatePicker from '../../hooks/useDatePicker';
import { endOfDayAtTz, startOfDayAtTz } from '../../utils/timezoneUtils';
import {
  dateToISODate,
  isAfter,
  isBefore,
} from '../../utils/dateUtils';
import isValidDateRange from '../../utils/isValidDateRange';

export interface DateRangeProviderProps {
  baseProps: AllPickerProps;
  children: ReactNode;
}

const DateRangeProvider: FC<React.PropsWithChildren<DateRangeProviderProps>> = ({
  children,
  baseProps,
}) => {
  const { goToMonth } = useNavigation();
  const initialRange: ISODateRange = {
    from: undefined,
    to: undefined,
  };
  let initialPreset: PresetRange | ISOPresetRange | undefined;
  let initialMultiDateSelector: MultiDateSelector | undefined;
  if (isDateRange(baseProps.type)) {
    const {
      startDate,
      endDate,
      selectedPreset,
      multiDateSelectors,
      selectedMultiDateSelector,
      zone,
    } = baseProps;
    if (startDate || endDate) {
      initialRange.from = startDate ? dateToISODate(startDate, zone) : undefined;
      initialRange.to = endDate ? dateToISODate(endDate, zone) : undefined;
    } else if (selectedPreset) {
      initialPreset = selectedPreset;
      if (selectedPreset.start) initialRange.from = dateToISODate(selectedPreset.start, zone);
      if (selectedPreset.end) initialRange.to = dateToISODate(selectedPreset.end, zone);
    }
    if (multiDateSelectors) {
      initialMultiDateSelector = selectedMultiDateSelector
        ?? multiDateSelectors.find((selector: MultiDateSelector) => selector.range === 'CUSTOM')
        ?? multiDateSelectors[0];
    }
  }
  const [selectedRange, setSelectedRange] = useState<ISODateRange>(initialRange);
  const [selectedPreset, setSelectedPreset] = useState<PresetRange | ISOPresetRange | undefined>(initialPreset);
  const [
    selectedMultiDateSelector,
    setSelectedMultiDateSelector,
  ] = useState<MultiDateSelector | undefined>(initialMultiDateSelector);
  const [multiDateHovered, setMultiDateHovered] = useState<ISODateRange>();
  const [hoveredRange, setHoveredRange] = useState<ISODate | undefined>();

  const { zone, today, locale } = useDatePicker();

  const handleOnMouseEnter = useCallback((mouseEnteredDate: ISODate) => {
    if (selectedMultiDateSelector && selectedMultiDateSelector?.range !== 'CUSTOM') {
      setMultiDateHovered({
        from: dateToISODate(selectedMultiDateSelector.getFrom(mouseEnteredDate, zone, locale), zone),
        to: dateToISODate(selectedMultiDateSelector.getTo(mouseEnteredDate, zone, locale), zone),
      });
    } else if (selectedRange.from && !selectedRange.to) {
      setHoveredRange(mouseEnteredDate);
    }
  }, [selectedRange.from, selectedRange.to, selectedMultiDateSelector, zone, locale]);

  const handleOnMouseLeave = useCallback(() => {
    setHoveredRange(undefined);
    setMultiDateHovered(undefined);
  }, []);

  const isDateInHoveredRange = (date: ISODate): boolean | undefined => selectedRange.from
    && !selectedRange.to
    && isAfter(date, selectedRange.from)
    && hoveredRange
    && isBefore(date, hoveredRange);

  const handleDateSelect = useCallback(
    (date: ISODate, event: MouseEvent<HTMLButtonElement>) => {
      setSelectedPreset(undefined);
      let newISORange: ISODateRange;
      let newDateRange: DateRange = { from: undefined, to: undefined };
      if (selectedMultiDateSelector && selectedMultiDateSelector.range !== 'CUSTOM') {
        newDateRange = {
          from: selectedMultiDateSelector.getFrom(date, zone, locale),
          to: selectedMultiDateSelector.getTo(date, zone, locale),
        }
        newISORange = {
          from: dateToISODate(selectedMultiDateSelector.getFrom(date, zone, locale), zone),
          to: dateToISODate(selectedMultiDateSelector.getTo(date, zone, locale), zone),
        };
      } else {
        newISORange = addToRange(date, selectedRange);
        newDateRange = formatRangeToTimeZone(newISORange, zone);
      }
      if (isValidDateRange(newDateRange)) {
        setSelectedRange(newISORange);
        if (isDateRange(baseProps.type)) {
          (baseProps as DateRangeProps).onChange?.(newDateRange, newDateRange.from, event);
        }
      }
    }, [baseProps, selectedRange, zone, selectedMultiDateSelector, locale],
  );

  const handlePresetSelect = useCallback(
    (preset?: PresetRange | UnresolvedPresetRange | ISOPresetRange, event?: MouseEvent<HTMLButtonElement>) => {
      if (preset) {
        const { multiDateSelectors, onMultiDateSelectorChange } = baseProps;
        const resolvedPreset = resolvePreset(preset, today, zone, locale);
        setSelectedPreset(resolvedPreset);

        const startIso = resolvedPreset.start ? dateToISODate(typeof preset.start === 'string' ? preset.start : resolvedPreset.start, zone) : undefined;
        const endIso = resolvedPreset.end ? dateToISODate(typeof preset.end === 'string' ? preset.end : resolvedPreset.end, zone) : undefined;
        const newISORange = {
          from: startIso,
          to: endIso,
        }
        setSelectedRange(newISORange);

        if (event?.target instanceof HTMLButtonElement && resolvedPreset.start && resolvedPreset.end) {
          event?.target.setAttribute('data-iso-range', `${startIso}/${endIso}`);
        }
        if (multiDateSelectors) {
          const multiDateSelector = multiDateSelectors.find((selector: MultiDateSelector) => selector.range === 'CUSTOM')
            ?? multiDateSelectors[0];
          setSelectedMultiDateSelector(multiDateSelector);
          onMultiDateSelectorChange?.(multiDateSelector, event);
        }
        const newDateRange = {
          from: resolvedPreset.start,
          to: resolvedPreset.end,
        };

        const newPreset = {
          ...resolvedPreset,
          startIso,
          endIso,
        } as PresetRange;

        if (isDateRange(baseProps.type)) {
          (baseProps as DateRangeProps).onChange?.(newDateRange, newDateRange.from, event);
        }
        if (newDateRange.to) goToMonth(dateToISODate(newDateRange.to, zone));
        baseProps.onSubmit?.(event, newPreset);
      }
    }, [baseProps, goToMonth, locale, today, zone],
  );

  const handleMultiDateSelectorSelect = useCallback(
    (multiDateSelector?: MultiDateSelector, event?: ChangeEvent<HTMLInputElement>) => {
      const { multiDateSelectors, onMultiDateSelectorChange } = baseProps;
      if (multiDateSelector
        && multiDateSelectors.some((selector: MultiDateSelector) => selector.range === multiDateSelector.range)
      ) {
        setSelectedPreset(undefined);
        setSelectedMultiDateSelector(multiDateSelector);
        onMultiDateSelectorChange?.(multiDateSelector, event);
      }
    }, [baseProps],
  );

  const handleOnSubmit = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const { from, to } = selectedRange;
    if (from) {
      const start = startOfDayAtTz(from, zone);
      const end = from && to
        ? endOfDayAtTz(to, zone)
        : endOfDayAtTz(from, zone);
      if (!isNaN(Number(start)) && !isNaN(Number(end))) {
        const { customPeriodId } = baseProps;
        const startIso = dateToISODate(start, zone);
        const endIso = dateToISODate(end, zone);
        const customPreset = {
          label: 'Custom',
          period: customPeriodId?.toUpperCase() || 'CUSTOM',
          start,
          end,
          startIso,
          endIso,
        } as PresetRange;
        if (e?.target instanceof HTMLButtonElement) {
          e?.target.setAttribute('data-iso-range', `${startIso}/${endIso}`);
        }
        baseProps.onSubmit?.(e, customPreset);
      }
    }
  }, [baseProps, selectedRange, zone]);

  const handleOnCancel = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    baseProps.onCancel?.(e);
  }, [baseProps]);

  const {
    presetRanges = presets,
    multiDateSelectors,
    multiDateSelectorsLabel,
  } = baseProps;

  return (
    <DateRangeContext.Provider
      value={{
        selected: selectedRange,
        handleDateSelect,
        handleOnMouseEnter,
        handleOnMouseLeave,
        hovered: hoveredRange,
        isDateInHoveredRange,
        presetRanges,
        handlePresetSelect,
        selectedPreset,
        multiDateSelectors,
        multiDateSelectorsLabel,
        handleMultiDateSelectorSelect,
        selectedMultiDateSelector,
        multiDateHovered,
        handleOnSubmit,
        handleOnCancel,
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
};

export default DateRangeProvider;
