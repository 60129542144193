/* eslint-disable react/forbid-prop-types */
/* eslint id-length: ["error", { "exceptions": ["as"] }] */
import React, {
  PureComponent,
  ReactNode,
  RefObject,
} from 'react';
import PropTypes from 'prop-types';
import { createStormTaktId } from '../TaktIdContext';
import Sheet, { SheetProps } from '../Sheet/Sheet';
import { SurfaceType } from '../Surface/types';

/* SSR workaround for nodejs, things like Element don't exists in nodejs. */
const NodeSafeElement = (typeof Element !== 'undefined' ? Element : Object) as typeof Element;

export interface BottomSheetProps extends Omit<SheetProps, 'side'> {
      /**
     * The React nodes/nodes that are rendered as content of the BottomSheet.
     */
  children: ReactNode;
      /**
     * This should correspond to the ROOT level dom node id that the modal will render into.
     * When this prop is not explicitly passed a value, the modal will mount the bottom sheet into
     * the div with id matching the portalElementId property in the popover object of the theme.
     * @defaultValue `undefined`
     */
  bottomSheetElementId?: string;
      /**
     * This configures the close button for the bottom sheet. Setting the type property will display
     * the corresponding type of close button above the bottom sheet. A type of icon will render the
     * "X" icon. A type of message will render the string value of the label property. The label is
     * read by a screen reader when the close button is in focus. Both type and label are required.
     */
  closeButton: {
    type: 'icon' | 'message',
    label: string
  };
      /**
     * Props that are applied to the CloseButton component.
     * @defaultValue `undefined`
     */
  closeButtonProps?: Record<string, unknown>;
      /**
     * Fired when any close action occurs, such as: clicking on the background, hitting the close
     * button, or hitting escape.
     * @defaultValue `undefined`
     */
  onClose?: () => void;
      /**
     * Control open / close of bottom sheet. It will animate automatically on change.
     * @defaultValue `false`
     */
  isOpen?: boolean;
      /**
     * Pass a ref to an element here to use as the toggle element. Focus will move back to this
     * element when the bottom sheet closes.
     * @defaultValue `undefined`
     */
  toggleEl?: RefObject<HTMLElement | undefined> | HTMLElement;
      /**
     * Sets the BottomSheet background color.
     * @defaultValue `"light"`
     */
  type?: SurfaceType;
  /**
   * The duration of the transition animation
   * @defaultValue `200`
   */
  transitionDuration?: number;
}

class BottomSheet extends PureComponent<BottomSheetProps> {
  public static propTypes = {
    /**
     * The React nodes/nodes that are rendered as content of the BottomSheet.
     */
    children: PropTypes.node.isRequired,
    /**
     * This configures the close button for the bottom sheet. Setting the type property will display
     * the corresponding type of close button above the bottom sheet. A type of icon will render the
     * "X" icon. A type of message will render the string value of the label property. The label is
     * read by a screen reader when the close button is in focus. Both type and label are required.
     */
    closeButton: PropTypes.shape({
      type: PropTypes.oneOf(['icon', 'message']).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * Props that are applied to the CloseButton component.
     */
    closeButtonProps: PropTypes.objectOf(PropTypes.any),
    /**
     * A passthrough prop that allows the BottomSheet Shroud to be customized by wrapping the
     * BottomSheet in a styled-component.
     */
    className: PropTypes.string,
    /**
     * This should correspond to the ROOT level dom node id that the modal will render into.
     * When this prop is not explicitly passed a value, the modal will mount the bottom sheet into
     * the div with id matching the portalElementId property in the popover object of the theme.
     */
    bottomSheetElementId: PropTypes.string,
    /**
     * Control open / close of bottom sheet. It will animate automatically on change.
     */
    isOpen: PropTypes.bool,
    /**
     * Fired when any close action occurs, such as: clicking on the background, hitting the close
     * button, or hitting escape.
     */
    onClose: PropTypes.func,
    /**
     * Pass a ref to an element here to use as the toggle element. Focus will move back to this
     * element when the bottom sheet closes.
     */
    toggleEl: PropTypes.oneOfType([
      PropTypes.instanceOf(NodeSafeElement),
      PropTypes.shape({ current: PropTypes.instanceOf(NodeSafeElement) }),
    ]),
    /**
     * Sets the BottomSheet background color.
     */
    type: PropTypes.oneOf(['light', 'dark', 'blue']),
    transitionDuration: PropTypes.number,
  }

  public static defaultProps = {
    bottomSheetElementId: undefined,
    className: '',
    closeButtonProps: undefined,
    isOpen: false,
    onClose: undefined,
    toggleEl: undefined,
    type: 'light',
    transitionDuration: 200,
  }

  render(): JSX.Element {
    const { bottomSheetElementId, taktId, ...rest } = this.props;
    return (
      <Sheet
        {...rest}
        sheetElementId={bottomSheetElementId}
        side="bottom"
        taktId={taktId || createStormTaktId('bottom-sheet')}
      />
    );
  }
}

export default BottomSheet;
