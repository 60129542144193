/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "キャンセル"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "保存"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "カスタム"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "1日を選択"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "月を選択"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "週を選択"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "過去30日間"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "過去7日"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "先月"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "先週"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "ライフタイム"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "今週"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "今日"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "過去1年"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "昨日"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
