const keys = {
  ENTER: 'Enter',
  SPACE: ' ',
  TAB: 'Tab',
  ESCAPE: 'Escape',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  END: 'End',
  HOME: 'Home',
};

export const handleDropdownButtonKeyDownEvents = (event, openAction) => {
  const { key } = event;
  if (
    key === keys.ARROW_DOWN
    || key === keys.SPACE
    || key === keys.ENTER
  ) {
    openAction(event);
    event.preventDefault();
  }
};

export const getFocusableElements = element => {
  if (!element || !(element instanceof HTMLElement)) return [];
  const focusables = [
    'button:not([disabled]):not([tabindex="-1"])',
    '[href]:not([disabled]):not([tabindex="-1"])',
    'input:not([disabled]):not([tabindex="-1"])',
    'select:not([disabled]):not([tabindex="-1"])',
    'option:not([disabled]):not([tabindex="-1"])',
    'textarea:not([disabled]):not([tabindex="-1"])',
    '[tabindex]:not([tabindex="-1"])',
  ];

  return Array.from(element.querySelectorAll(
    focusables.join(','),
  ));
};

// eslint-disable-next-line import/prefer-default-export
export const handlePopoverKeyDownEvents = (event, context, escapeAction, selectAction) => {
  const { key } = event;
  const isShift = event.shiftKey;
  if (context) {
    const focusableEls = getFocusableElements(context);
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    if (
      key === keys.ARROW_DOWN
      || key === keys.ARROW_UP
      || key === keys.HOME
      || key === keys.END
      || key === keys.TAB
    ) {
      const currentIndex = focusableEls.indexOf(document.activeElement);
      let newFocusIndex = currentIndex;
      if (key === keys.ARROW_DOWN || (key === keys.TAB && !isShift)) {
        newFocusIndex = currentIndex + 1;
        if (document.activeElement === lastFocusableEl) {
          newFocusIndex = focusableEls.length - 1;
        }
      }
      if (key === keys.ARROW_UP || (key === keys.TAB && isShift)) {
        newFocusIndex = currentIndex - 1;
        if (document.activeElement === firstFocusableEl) {
          newFocusIndex = 0;
        }
      }
      if (key === keys.HOME) {
        newFocusIndex = 0;
      }
      if (key === keys.END) {
        newFocusIndex = focusableEls.length - 1;
      }
      if (newFocusIndex >= 0 && newFocusIndex < focusableEls.length) {
        focusableEls[newFocusIndex].focus();
      }
      event.preventDefault();
    }
    if (key === keys.ESCAPE) {
      escapeAction(event);
      event.preventDefault();
    }
    if (key === keys.ENTER || key === keys.SPACE) {
      if (selectAction && !!event.target.value) {
        selectAction(event);
        event.preventDefault();
      }
    }
  }
};
