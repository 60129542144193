import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  && {
    max-width: 100%;
    max-height: 100%;
  }
`;

export interface DefaultImageProps {
  /**
   * URL of the image to display.
   */
  url: string;
  /**
   * String used as the alt text to describe the image. Required for the image
   * to be accessible.
   * @defaultValue `""`
   */
  alt?: string;
}

const DefaultImage: FC<React.PropsWithChildren<DefaultImageProps>> = ({
  url,
  alt,
}: DefaultImageProps): JSX.Element => (
  <Image
    src={url}
    alt={alt}
  />
);

DefaultImage.defaultProps = {
  alt: '',
};

export default DefaultImage;
