import React, { Component, ReactNode } from 'react';
import PT from 'prop-types';
import UIDContext, { Prefixer } from './UIDContext';
import uidSafeCharsPropType from './uidSafeCharsPropType';

/*
  Memoizing createPrefixer prevents redundant calls up the react tree.
 */
function createMemoizedPrefixer() {
  let lastParentPrefixer: Prefixer;
  let lastPrefix: string;
  let lastPrefixer: Prefixer;
  return function createPrefixer(parentPrefixer: Prefixer, prefix: string) {
    if (
      lastPrefixer === undefined
       || parentPrefixer !== lastParentPrefixer
       || prefix !== lastPrefix
    ) {
      lastParentPrefixer = parentPrefixer;
      lastPrefix = prefix;
      lastPrefixer = function prefixer(suffix) {
        return parentPrefixer(`${prefix}:${suffix}`);
      };
    }
    return lastPrefixer;
  };
}

export interface UIDPrefixProps {
  value: string;
  children?: ReactNode;
}

/**
 *
 */
class UIDPrefix extends Component<UIDPrefixProps> {
  prefixer = createMemoizedPrefixer();

  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    value: uidSafeCharsPropType,
    children: PT.node.isRequired,
  };

  render(): JSX.Element {
    const { children, value } = this.props;
    return (
      <UIDContext.Consumer>
        { parentPrefixer => (
          <UIDContext.Provider value={this.prefixer(parentPrefixer, value)}>
            {children}
          </UIDContext.Provider>
        )}
      </UIDContext.Consumer>

    );
  }
}

export default UIDPrefix;
