import React, { PureComponent } from 'react';
import PT from 'prop-types';
import InputFormGroup, { InputFormGroupProps } from '../InputFormGroup/InputFormGroup';
import getCurrencySymbol from './getCurrencySymbol';
import getCurrencyPosition from './getCurrencyPosition';
import parseIetfLanguageTag from './parseIetfLanguageTag';

export interface CurrencyInputProps extends InputFormGroupProps {
  /**
     * The id attribute that is supplied to the input element.
     */
  id: string;
  /**
     * Displays the appropriate currency symbol based on currency (USD, EUR, etc..).
     * @defaultValue `undefined`
     */
  currency?: string;
  /**
     * Positions the currency symbol based on locale (en, fr, en-US, fr-US, etc...).
     * @defaultValue `undefined`
     */
  locale?: string;
  /**
   * @defaultValue `number`
   */
  type?: string;
  /**
     * Set custom aria-label attribute on input.
     * @defaultValue `undefined`
     */
  ariaLabel?: string;
}

export default class CurrencyInput extends PureComponent<CurrencyInputProps> {
  static propTypes = {
    /**
     * The id attribute that is supplied to the input element.
     */
    id: PT.string.isRequired,
    /**
     * Displays the appropriate currency symbol based on currency (USD, EUR, etc..).
     */
    currency: PT.string,
    /**
     * Positions the currency symbol based on locale (en, fr, en-US, fr-US, etc...).
     */
    locale: PT.string,
    type: PT.string,
    /**
     * Set custom aria-label attribute on input.
     */
    ariaLabel: PT.string,
  }

  static defaultProps = {
    type: 'number',
    ariaLabel: undefined,
    currency: undefined,
    locale: undefined,
  }

  render(): JSX.Element {
    const {
      id,
      currency,
      locale,
      type,
      ariaLabel,
      ...rest
    } = this.props;

    if (!currency || !locale) {
      return <InputFormGroup {...rest} id={id} type={type} ariaLabel={ariaLabel} />;
    }

    let currencySymbol;
    let currencyPosition;

    if (typeof Intl !== 'undefined') {
      const parts = new Intl.NumberFormat(locale, { style: 'currency', currency })?.formatToParts(0);
      if (parts && parts.length) {
        currencyPosition = 'prefix';
        currencySymbol = parts[0].value;
        if (parts[parts.length - 1]?.type === 'currency') {
          currencySymbol = parts[parts.length - 1]?.value;
          currencyPosition = 'suffix';
        }
      }
    }

    // Since the AdsConsole does not have en-ZA as a locale, we must force 'ZAR' to map to 'R'. The above code
    // block resolves all other 'en-*' locales to ZAR.
    if (!currencySymbol || currencySymbol === 'ZAR') {
      const { language, country } = parseIetfLanguageTag(locale);
      currencySymbol = getCurrencySymbol(currency);
      currencyPosition = getCurrencyPosition(language, country);
    }

    let prefix = null;
    let suffix = null;

    if (currencySymbol && currencyPosition) {
      if (currencyPosition === 'prefix') {
        prefix = currencySymbol;
      } else {
        suffix = currencySymbol;
      }
    }

    return (
      <InputFormGroup
        {...rest}
        id={id}
        type={type}
        prefix={prefix}
        suffix={suffix}
        ariaLabel={ariaLabel || `enter amount in ${currencySymbol}`}
        ignoreTextDirection
      />
    );
  }
}
