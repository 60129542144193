/* eslint-disable id-length */
import { spacing } from '../base/spacing';

const pagination = {
  paginationItemMargin: spacing.medium,
  paginationNavGroupMargin: spacing.mini,
  button: {
    width: '31px',
  },
};

export const mobilePagination = {
  mobile: {
    paginationItemMargin: '8px',
  },
  button: {
    mobile: {
      width: '40px',
      height: '44px',
    },
  },
};

export default pagination;
