import React, {
  createRef,
  CSSProperties,
  MouseEventHandler,
  PureComponent,
  RefObject,
} from 'react';
import { Transition } from 'react-transition-group';
import PT from 'prop-types';
import {
  composeRefs,
  getFocusableElements,
  isEventFromWithinElement,
  isFunction,
  pageDirection,
  noop,
  MergeStyledComponentElementProps,
  OnRootContainerMousedown,
} from '@amzn/storm-ui-utils-v3';
import {
  OuterWrapper,
  Content,
} from './Popover.styles';
import OnTargetMove from './OnTargetMove';
import getElement from './getElement';
import Portal from '../Portal/Portal';
import isShadowRootInstance from '../prop-types/isShadowRoot';
import { PortalContextProvider } from '../Portal/PortalContext';
import PortalComponent from '../Portal/PortalComponent';
import { TransitionStatus } from '../types/react-transition-group/Transition';

type PositionType = {
  position: string,
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

const NodeSafeHTMLElement = (typeof HTMLElement !== 'undefined' ? HTMLElement : Object);
const NodeSafeElement = (typeof Element !== 'undefined' ? Element : Object);

// how close a popover can be to the edge before swapping sides
const SWAP_MARGIN = 2;

const findAbsoluteCenterY = (rect: DOMRect) => (window.innerHeight / 2) - (rect.height / 2);
const findAbsoluteCenterX = (rect: DOMRect) => (window.innerWidth / 2) - (rect.width / 2);
const trimOverflowTop = (y: number) => {
  if (y < SWAP_MARGIN) return SWAP_MARGIN;

  return y;
};
const trimOverflowLeft = (x: number) => {
  if (x < SWAP_MARGIN) return SWAP_MARGIN;

  return x;
};
const trimOverflowRight = (x: number, width: number) => {
  if (x + width > window.innerWidth - SWAP_MARGIN) {
    return window.innerWidth - SWAP_MARGIN - width;
  }

  return x;
};
const trimOverflowBottom = (y: number, height: number) => {
  if (y + height > window.innerHeight - SWAP_MARGIN) {
    return window.innerHeight - SWAP_MARGIN - height;
  }

  return y;
};

// eslint-disable-next-line max-len
const adjustLeftForY = (align: string, anchorRect: DOMRect, wrapperRect: DOMRect, offset: Offset) => {
  if (align === 'end') {
    // eslint-disable-next-line max-len
    return trimOverflowLeft((anchorRect.left + anchorRect.width) - wrapperRect.width - Number(offset.x));
  }

  if (align === 'center') {
    const center = trimOverflowRight(
      (anchorRect.left + (anchorRect.width / 2)) - ((wrapperRect.width / 2) + Number(offset.x)),
      0,
    );

    return trimOverflowLeft(trimOverflowRight(center, 0));
  }

  return trimOverflowRight(anchorRect.left + Number(offset.x), wrapperRect.width);
};
// eslint-disable-next-line max-len
const adjustTopForX = (align: string, anchorRect: DOMRect, wrapperRect: DOMRect, offset: Offset) => {
  if (align === 'end') {
    // eslint-disable-next-line max-len
    return trimOverflowTop((anchorRect.top + anchorRect.height) - wrapperRect.height - Number(offset.y));
  }

  if (align === 'center') {
    const center = trimOverflowTop(
      (anchorRect.top + (anchorRect.height / 2)) - ((wrapperRect.height / 2) + Number(offset.y)),
    );

    return trimOverflowBottom(trimOverflowTop(center), wrapperRect.height);
  }

  return trimOverflowBottom(anchorRect.top + Number(offset.y), wrapperRect.height);
};

export const calculateAbsoluteHorizontalPosition = (position: string): string => {
  if (pageDirection() === 'rtl' && (position === 'right' || position === 'left')) {
    if (position === 'right') {
      return 'left';
    }
    return 'right';
  }

  return position;
};

export const getRelativeOverlayAlign = (align: string): string => {
  if (pageDirection() === 'rtl' && (align === 'start' || align === 'end')) {
    if (align === 'start') {
      return 'end';
    }
    return 'start';
  }

  return align;
};

// eslint-disable-next-line max-len
const getRelativeOverlay = (align: string, anchorRect: DOMRect, wrapperRect: DOMRect, offset: Offset) => {
  const top = trimOverflowBottom(anchorRect.top + Number(offset.y), wrapperRect.height) || 0;
  const alignBasedOnPageDirection = getRelativeOverlayAlign(align);
  let left;

  switch (alignBasedOnPageDirection) {
    case 'end':
      left = (anchorRect.left + anchorRect.width) - wrapperRect.width - Number(offset.x);
      break;
    case 'center':
      // eslint-disable-next-line max-len
      left = (anchorRect.left + (anchorRect.width / 2)) - ((wrapperRect.width / 2) + Number(offset.x));
      break;
    case 'start':
    default:
      left = anchorRect.left + Number(offset.x);
      break;
  }

  return {
    left: trimOverflowRight(left, wrapperRect.width) || 0,
    top,
  };
};

export type Position = 'top' | 'right' | 'bottom'| 'left'| 'overlay' | string;
export type Align = 'start' | 'center' | 'end' | string;
export type Offset = {
  x?: number | string | undefined,
  y?: number | string | undefined,
}

export interface PopoverProps extends Omit<MergeStyledComponentElementProps<'div'>, 'ref'> {
  /**
   * @defaultValue `undefined`
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
     * Control open / close of popover. It will animate automatically on change.
     */
  isOpen: boolean;
  /**
   * @defaultValue `false`
   */
  showFocus?: boolean;
  /**
     * Add the common backdrop styles used for Date Picker etc.
     * @defaultValue `false`
     */
  withBackdrop?: boolean;
  // Type `any` used to get around customers with inconsistent type definitions
  /**
     * Fired when clicking outside of the popover.
     * @defaultValue `() => undefined`
     */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (event: any) => void;
  /**
     * Use if you need to add a className to the inner content div.
     * @defaultValue `""`
     */
  contentClassName?: string;
  /**
   * @defaultValue `250`
   */
  transitionDuration?: number,
  /**
   * @defaultValue `"opacity 250ms ease-in-out"`
   */
  transition?: string;
  /**
     * Pass a ref to an element here to use as position anchor.
     * In SSR, there is no window.Element.
     * @defaultValue `undefined`
     */
  anchorEl?: RefObject<Element> | Element | null;
  /**
     * Use to position relative to the anchorEl.
     * @defaultValue `"overlay"`
     */
  position?: Position;
  /**
   * @defaultValue `"start"`
   */
  align?: Align;
  /**
     * Use to offset the overlay from the calculated position.
     * @defaultValue `{ x: 0, y: 0 }`
     */
  offset?: Offset | null;
  /**
     * Must return a position, used to override or hook into swapping functionality.
     * @defaultValue `(position) => position`
     */
  onUpdatePosition?: (position: Position) => Position;
  /**
   * @defaultValue `{}`
   */
  style?: CSSProperties;
  /**
     * Set to true to disable the default flip behavior.
     * @defaultValue `false`
     */
  disableFlip?: boolean;
  /**
     * Use to attach custom events to the wrapper inside Popover.
     * @defaultValue `undefined`
     */
  attachCustomEvents?: (element: HTMLElement)=>void;
  /**
     * Use to detach custom events when they have side effects.
     * @defaultValue `undefined`
     */
  detachCustomEvents?: ()=>void;
  /**
     * Function is called just after the popover is mounted into the DOM.
     * The popover element is passed as the first argument.
     * This can be used to add custom telemetry code to the apprentice of a popup.
     * @defaultValue `undefined`
     */
  popoverElementDidMount?: (element: (HTMLElement | null| undefined)) => void;
  /**
     * Function is called just before the popover is removed from the DOM.
     * The popover element is passed as the first argument.
     * This can be used to add custom telemetry code to the dismissal of a popup.
     * @defaultValue `undefined`
     */
  popoverElementWillUnmount?: (element: (HTMLElement | null | undefined)) => void;
  /**
    * A React Ref that contains the HTMLElement this popper will be rendered into.
    * @defaultValue `undefined`
    */
  portalRef?: RefObject<HTMLElement>;
  /**
     * *Only required for `closed` shadow roots*
     * By default an mousedown event is added to document to close the
     * popover on click on outside of popover. But while using with shadow
     * DOM, events originating from nodes inside of the shadow DOM are
     * re-targeted so they appear to come from the shadow host. Due to
     * this popover gets closed on click on content inside popover.
     * This prop can be used to add event listener to the shadow DOM.
     * @defaultValue `undefined`
     */
  shadowRoot?: EventTarget;
  /**
     * Set to true to remove the overflow css rule in popover content container.
     * This is to fix `Dropdown` not scroll-able in IOS when being displayed
     * in a `secondaryView` with `usePreventScroll()` from `@react-aria`.
     * More details in this [SIM](https://issues.amazon.com/issues/CFX-1366).
     * @defaultValue `false`
     */
  disableContentScroll?: boolean;
  /**
     * Set to true to enable a portal within the popover. This enables popover-like components
     * ( popper, popover, dropdown, helptip, tool tip, etc) to be nested within this popover.
     * This portal prevents the popover from trigger a `onClose()` callback when the user
     * interacts with the child popover-like component.
     * @defaultValue `undefined`
     */
  providePortal?: boolean;
  /**
   * @defaultValue `undefined`
   */
  rootContainerRef?: RefObject<HTMLDivElement>;
}

class Popover extends PureComponent<PopoverProps> {
  static propTypes = {
    onClick: PT.func,
    /**
     * Control open / close of popover. It will animate automatically on change.
     */
    isOpen: PT.bool.isRequired,
    showFocus: PT.bool,
    /**
     * Add the common backdrop styles used for Date Picker etc.
     */
    withBackdrop: PT.bool,
    /**
     * The content of the popover.
     */
    children: PT.oneOfType([
      PT.arrayOf(PT.node),
      PT.node,
    ]).isRequired,
    /**
     * Fired when clicking outside of the popover.
     */
    onClose: PT.func,
    /**
     * Use if you need to add a className to the inner content div.
     */
    contentClassName: PT.string,
    transitionDuration: PT.number,
    transition: PT.string,
    /**
     * Pass a ref to an element here to use as position anchor.
     * In SSR, there is no window.Element.
     */
    anchorEl: PT.oneOfType([
      PT.shape({ current: (PT.instanceOf(NodeSafeElement)) }),
      PT.instanceOf(NodeSafeElement),
    ]),
    /**
     * Use to position relative to the anchorEl.
     */
    position: PT.oneOf(['top', 'right', 'bottom', 'left', 'overlay']),
    align: PT.oneOf(['start', 'center', 'end']),
    /**
     * Use to offset the overlay from the calculated position.
     */
    offset: PT.shape({ x: PT.number, y: PT.number }),
    /**
     * Must return a position, used to override or hook into swapping functionality.
     */
    onUpdatePosition: PT.func,
    style: PT.objectOf(PT.string),
    /**
     * Set to true to disable the default flip behavior.
     */
    disableFlip: PT.bool,
    /**
     * Use to attach custom events to the wrapper inside Popover.
     */
    attachCustomEvents: PT.func,
    /**
     * Use to detach custom events when they have side effects.
     */
    detachCustomEvents: PT.func,
    /**
     * Function is called just after the popover is mounted into the DOM.
     * The popover element is passed as the first argument.
     * This can be used to add custom telemetry code to the apprentice of a popup.
     */
    popoverElementDidMount: PT.func,
    /**
     * Function is called just before the popover is removed from the DOM.
     * The popover element is passed as the first argument.
     * This can be used to add custom telemetry code to the dismissal of a popup.
     */
    popoverElementWillUnmount: PT.func,
    /**
    * A React Ref that contains the HTMLElement this popper will be rendered into.
    */
    portalRef: PT.shape({ current: (PT.instanceOf(NodeSafeHTMLElement)) }),
    /**
     * *Only required for `closed` shadow roots*
     * By default an mousedown event is added to document to close the
     * popover on click on outside of popover. But while using with shadow
     * DOM, events originating from nodes inside of the shadow DOM are
     * re-targeted so they appear to come from the shadow host. Due to
     * this popover gets closed on click on content inside popover.
     * This prop can be used to add event listener to the shadow DOM.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shadowRoot: isShadowRootInstance,
    /**
     * Set to true to remove the overflow css rule in popover content container.
     * This is to fix `Dropdown` not scroll-able in IOS when being displayed
     * in a `secondaryView` with `usePreventScroll()` from `@react-aria`.
     * More details in this [SIM](https://issues.amazon.com/issues/CFX-1366).
     */
    disableContentScroll: PT.bool,
    /**
     * Set to true to enable a portal within the popover. This enables popover-like components
     * ( popper, popover, dropdown, helptip, tool tip, etc) to be nested within this popover.
     * This portal prevents the popover from trigger a `onClose()` callback when the user
     * interacts with the child popover-like component.
     */
    providePortal: PT.bool,
  }

  static defaultProps = {
    onClick: undefined,
    withBackdrop: false,
    onClose: noop,
    contentClassName: '',
    anchorEl: undefined,
    position: 'overlay',
    align: 'start',
    offset: { x: 0, y: 0 },
    transitionDuration: 250,
    transition: 'opacity 250ms ease-in-out',
    onUpdatePosition: (position: Position): Position => position,
    style: {},
    showFocus: false,
    disableFlip: false,
    attachCustomEvents: undefined,
    detachCustomEvents: undefined,
    popoverElementDidMount: undefined,
    popoverElementWillUnmount: undefined,
    portalRef: undefined,
    shadowRoot: undefined,
    disableContentScroll: false,
    providePortal: undefined,
    rootContainerRef: undefined,
  }

  wrapperRef: RefObject<HTMLDivElement>;

  portalRef: RefObject<HTMLElement>;

  constructor(props: PopoverProps) {
    super(props);
    this.wrapperRef = createRef();
    this.portalRef = createRef();
  }

  componentWillUnmount(): void {
    const { detachCustomEvents } = this.props;
    if (detachCustomEvents) {
      detachCustomEvents();
    }
  }

  getValidOffset(): Offset {
    const { offset } = this.props;

    if (!offset) return { x: 0, y: 0 };

    return {
      x: Number(offset.x) || 0,
      y: Number(offset.y) || 0,
    };
  }

  teardownPopover = (): void => {
    const { popoverElementWillUnmount } = this.props;
    // eslint-disable-next-line max-len
    if (popoverElementWillUnmount && isFunction(popoverElementWillUnmount) && this.wrapperRef.current instanceof HTMLElement) {
      popoverElementWillUnmount(this.wrapperRef.current);
    }
  }

  handleEntered = (): void => {
    const { showFocus, popoverElementDidMount } = this.props;
    const focusableEls = getFocusableElements(this.wrapperRef.current);
    if (
      document.activeElement
      && document.activeElement instanceof HTMLElement
      && showFocus
      && focusableEls.length > 0
      && focusableEls.indexOf(document.activeElement) < 0
    ) {
      focusableEls[0].focus();
    }
    // eslint-disable-next-line max-len
    if (popoverElementDidMount && isFunction(popoverElementDidMount) && this.wrapperRef.current instanceof HTMLElement) {
      popoverElementDidMount(this.wrapperRef.current);
    }
  }

  handleExited = (): void => {
    const shouldShowFocus = (this.props.showFocus && document.activeElement === document.body);

    if (shouldShowFocus && this.props.anchorEl) {
      const element = getElement(this.props.anchorEl);
      if (element && element instanceof HTMLElement) {
        const focusableEls = getFocusableElements(element);
        if (focusableEls.length > 0) { // e.g. tooltip trigger element has a span wrapper
          focusableEls[0].focus();
        } else {
          element.focus(); // simple trigger like button
        }
      }
    }
  }

  handleClick = (event: MouseEvent): void => {
    const { onClose } = this.props;
    if (this.wrapperRef.current && !isEventFromWithinElement(event, this.wrapperRef.current)) {
      onClose?.(event);
    }
  }

  handleScroll = (): void => {
    const { anchorEl } = this.props;
    if (!getElement(anchorEl) || !this.wrapperRef.current) return;

    window.requestAnimationFrame(() => {
      /**
       * Check if wrapperRef still exists. There is a edge case when `requestAnimationFrame`
       * callback happens after wrapperRef is removed from the DOM
       */
      if (this.wrapperRef.current) {
        const newLocation = this.calculateRelativeLocation();
        if (this.wrapperRef.current instanceof HTMLElement) {
          this.wrapperRef.current.style.left = `${newLocation.left}px`;
          this.wrapperRef.current.style.top = `${newLocation.top}px`;
        }
      }
    });
  }

  // eslint-disable-next-line max-len
  calculateAbsoluteHorizontal = (wrapperRect: DOMRect): PositionType => {
    const { position = 'overlay', align = 'start' } = this.props;
    const offset = this.getValidOffset();
    const vertical = align === 'end' ? 'bottom' : 'top';
    const positionBasedOnPageDirection = calculateAbsoluteHorizontalPosition(position);
    return {
      position: 'fixed',
      [positionBasedOnPageDirection]: Number(offset.x),
      [vertical]: align === 'center' ? findAbsoluteCenterY(wrapperRect) : Number(offset.y),
    };
  }

  calculateAbsoluteVertical = (wrapperRect: DOMRect): PositionType => {
    const { position = 'overlay', align = 'start' } = this.props;
    const offset = this.getValidOffset();
    const horizontal = align === 'end' ? 'right' : 'left';

    return {
      position: 'fixed',
      [position]: Number(offset.y),
      [horizontal]: align === 'center' ? findAbsoluteCenterX(wrapperRect) : Number(offset.x),
    };
  }

  // eslint-disable-next-line max-len
  calculateRelativeVertical = (anchorRect: DOMRect, wrapperRect: DOMRect): { top: number, left: number } => {
    const {
      position = 'overlay', align = 'start', onUpdatePosition, disableFlip,
    } = this.props;
    const offset = this.getValidOffset();
    const above = anchorRect.top - wrapperRect.height - Number(offset.y);
    const below = anchorRect.top + anchorRect.height + Number(offset.y);
    let actualPosition = position;

    if (!disableFlip) {
      if (position === 'bottom' && below + wrapperRect.height > window.innerHeight - SWAP_MARGIN) {
        actualPosition = 'top';
      } else if (position === 'top' && above < SWAP_MARGIN) {
        actualPosition = 'bottom';
      }
    }

    actualPosition = onUpdatePosition ? onUpdatePosition(actualPosition) : actualPosition;
    // TODO: Figure out fallback for when position is no longer top or bottom

    return {
      top: (actualPosition === 'top' ? above : below) || 0,
      left: adjustLeftForY(align, anchorRect, wrapperRect, offset) || 0,
    };
  }

  // eslint-disable-next-line max-len
  calculateRelativeHorizontal = (anchorRect: DOMRect, wrapperRect: DOMRect): { top: number, left: number } => {
    const {
      position = 'overlay', align = 'start', onUpdatePosition, disableFlip,
    } = this.props;
    const offset = this.getValidOffset();
    let left;
    let right;
    let actualPosition = position;

    if (pageDirection() !== 'rtl') {
      left = anchorRect.left - wrapperRect.width - Number(offset.x);
      right = anchorRect.left + anchorRect.width + Number(offset.x);
    } else {
      left = anchorRect.left + anchorRect.width + Number(offset.x);
      right = anchorRect.left - wrapperRect.width - Number(offset.x);
    }

    if (!disableFlip) {
      if (position === 'right' && right + wrapperRect.width > window.innerWidth - SWAP_MARGIN) {
        actualPosition = 'left';
      } else if (position === 'left' && left < SWAP_MARGIN) {
        actualPosition = 'right';
      }
    }

    actualPosition = onUpdatePosition ? onUpdatePosition(actualPosition) : actualPosition;
    // TODO: Figure out fallback for when position is no longer left or right

    return {
      left: (actualPosition === 'left' ? left : right) || 0,
      top: adjustTopForX(align, anchorRect, wrapperRect, offset) || 0,
    };
  }

  preventClickThrough = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  onWrapperMount = (element: HTMLDivElement): void => {
    const { attachCustomEvents } = this.props;
    if (element && attachCustomEvents) {
      attachCustomEvents(element);
    }
    /* recalculate the position as soon as it is mounted to the dom */
    this.handleScroll();
  }

  calculateAbsoluteLocation(): PositionType {
    const { position = 'overlay' } = this.props;
    const offset = this.getValidOffset();
    const fallback: PositionType = {
      position: 'fixed',
      left: Number(offset.x),
      top: Number(offset.y),
    };
    if (!this.wrapperRef.current) return fallback;

    const wrapperRect = this.wrapperRef.current.getBoundingClientRect();

    switch (position) {
      case 'right':
      case 'left':
        return this.calculateAbsoluteHorizontal(wrapperRect);

      case 'top':
      case 'bottom':
        return this.calculateAbsoluteVertical(wrapperRect);

      case 'overlay':
      default:
        return fallback;
    }
  }

  calculateRelativeLocation(): { top: number, left: number } {
    const offset = this.getValidOffset();
    if (!this.wrapperRef.current) {
      return {
        left: Number(offset.x),
        top: Number(offset.y),
      };
    }

    const { position = 'overlay', anchorEl, align = 'start' } = this.props;

    const element = getElement(anchorEl);
    if (element instanceof Element) {
      const anchorRect = element.getBoundingClientRect();
      const wrapperRect = this.wrapperRef.current.getBoundingClientRect();

      switch (position) {
        case 'right':
        case 'left':
          return this.calculateRelativeHorizontal(anchorRect, wrapperRect);

        case 'top':
        case 'bottom':
          return this.calculateRelativeVertical(anchorRect, wrapperRect);

        case 'overlay':
        default:
          return getRelativeOverlay(align, anchorRect, wrapperRect, offset);
      }
    }
    return { top: 0, left: 0 };
  }

  wrapInPortalContext(content: JSX.Element): JSX.Element {
    const {
      providePortal,
    } = this.props;
    /* If `providePortal===false` then do not wrap the content in the PortalContextProvider */
    if (!providePortal) {
      return content;
    }
    return (
      <PortalContextProvider portalElementRef={this.portalRef}>
        {content}
        <PortalComponent ref={this.portalRef as RefObject<HTMLDivElement>} />
      </PortalContextProvider>
    );
  }

  renderContents(state: TransitionStatus): JSX.Element {
    const {
      children,
      contentClassName,
      anchorEl,
      position,
      transitionDuration,
      transition,
      style,
      withBackdrop,
      onUpdatePosition,
      onClose,
      offset,
      disableContentScroll,
      shadowRoot,
      providePortal,
      rootContainerRef,
      ...rest
    } = this.props;

    const location = getElement(anchorEl)
      ? this.calculateRelativeLocation()
      : this.calculateAbsoluteLocation();

    const wrapperStyles = {
      ...style, // to avoid user passed styles breaking locations etc
      ...location,
    };

    return (
      <OnTargetMove
        targetEl={anchorEl}
        handler={this.handleScroll}
      >
        <OnRootContainerMousedown
          rootContainer={shadowRoot ?? document.documentElement}
          handler={this.handleClick as (event: Event) => void}
        >
          <OuterWrapper
            transitionState={state}
            transition={transition}
            style={wrapperStyles}
            withBackdrop={withBackdrop}
            ref={composeRefs(this.wrapperRef, this.onWrapperMount, rootContainerRef)}
            {...rest}
            onClick={this.preventClickThrough}
            $hideOverflow={
              /**
               * Hide the overflow When the popover is *not* providing a portal. The
               * overflow rules will be shifted to the Content component
               */
              !providePortal
            }
          >
            {this.wrapInPortalContext(
              <Content
                className={contentClassName}
                $disableContentScroll={disableContentScroll}
                $hideOverflow={providePortal}
              >
                {children}
              </Content>,
            )}
          </OuterWrapper>

        </OnRootContainerMousedown>
      </OnTargetMove>
    );
  }

  render(): JSX.Element {
    const {
      transitionDuration = 250,
      isOpen,
      portalRef,
    } = this.props;

    return (
      <Transition
        timeout={transitionDuration}
        appear
        in={isOpen}
        onExit={this.teardownPopover}
        onEntered={this.handleEntered}
        onExited={this.handleExited}
        nodeRef={this.wrapperRef}
      >
        {state => {
          if (state === 'exited') return null;
          return (
            <Portal externalPortalRef={portalRef} transitionDuration={transitionDuration}>
              {this.renderContents(state)}
            </Portal>
          );
        }}
      </Transition>
    );
  }
}

export default Popover;
