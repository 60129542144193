import {
  addDays as DFNSAddDays,
  addMonths as DFNSAddMonths,
  startOfWeek as DFNSStartOfWeek,
  endOfWeek as DFNSEndOfWeek,
  differenceInCalendarDays as DFNSDifferenceInCalendarDays,
  getWeek as DFNSGetWeek,
  addYears as DFNSAddYears,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ISODate } from '../types';

/**
 * The first millisecond of the day
 * @constant
 */
const START = '00:00:00.000';

/**
  * The last millisecond of the day
  * @constant
  */
const END = '23:59:59.999';

/**
 * Returns whether the given date is in ISODate format
 * @param date the given date
 * @returns is the given date an ISODate
 *
 * @example
 * '2022-09-20' => true
 * Tue Sep 20 2022 => false
 */
const isISODate = (date: Date | string): date is ISODate => {
  if (typeof date === 'string') {
    return /^\d\d\d\d-[01]\d-[0123]\d$/.test(date);
  }
  return false;
}

/**
 * Parse the year from the given ISODate
 * @param date the given ISODate
 * @returns the year
 *
 * @example
 * '2022-09-20' => '2022'
 */
const getYear = (date: ISODate): string => date.slice(0, 4);

/**
 * Parse the month from the given ISODate
 * @param date the given ISODate
 * @returns the month
 *
 * @example
 * '2022-09-20' => '09'
 */
const getMonth = (date: ISODate): string => date.slice(5, 7);

/**
 * Parse the day from the given ISODate
 * @param date the given ISODate
 * @returns the day
 *
 * @example
 * '2022-09-20' => '20'
 */
const getDay = (date: ISODate): string => date.slice(8, 10);

/**
 * Convert the given number to the ISODate year format
 * @param year the given number
 * @returns the number in ISODate year format
 *
 * @example
 * 2022 => '2022'
 * 999 => '0999'
 * 79 => '0079'
 */
const yearToISO = (year: number): string => year.toString().padStart(4, '0');

/**
 * Convert the given number to the ISODate month format
 * @param month the given number
 * @returns the number in ISODate month format
 *
 * @example
 * 5 => '05'
 * 12 => '12'
 */
const monthToISO = (month: number): string => month.toString().padStart(2, '0');

/**
 * Convert the given number to the ISODate day format
 * @param day the given number
 * @returns the number in ISODate day format
 *
 * @example
 * 5 => '05'
 * 30 => '30'
 */
const dayToISO = (day: number): string => day.toString().padStart(2, '0');

/**
 * Convert the given Date to an ISODate
 * @param date the given Date
 * @param zone the given timezone, defaults to 'browsers timezone'
 * @returns the given Date as an ISODate
 *
 * @example
 * Tue Sep 20 2022 => '2022-09-20'
 */
const dateToISODate = (date: Date | string, zone?: string): ISODate => {
  if (isISODate(date)) {
    return date;
  }
  return formatInTimeZone(date, zone || (Intl.DateTimeFormat().resolvedOptions().timeZone), 'yyyy-MM-dd') as ISODate;
}

/**
 * Convert the given ISODate to a Date
 * @param date the given ISODate
 * @returns the given ISODate as a Date
 *
 * @example
 * '2022-09-20' => Tue Sep 20 2022
 */
const isoDateToDate = (date: ISODate): Date => new Date(`${date}T${START}`);

/**
 * Get the local week index of the given ISODate
 * @param date the given ISODate
 * @param locale the locale
 * @returns the week index
 *
 * @example
 * '2022-09-20' => 39 (enUS locale)
 * '2022-09-20' => 38 (zhCN locale)
 */
const getWeek = (date: ISODate, locale: Locale): number => DFNSGetWeek(isoDateToDate(date), { locale });

/**
 * Add the specified number of days to the given ISODate
 * @param date the given ISODate
 * @param amount the amount of days to be added
 * @returns the new ISODate with the days added
 *
 * @example
 * add 12 days to '2022-09-20' => '2022-10-02'
 */
const addDays = (
  date: ISODate,
  amount: number,
): ISODate => dateToISODate(DFNSAddDays(isoDateToDate(date), amount));

/**
 * Add the specified number of week to the given ISODate
 * @param date the given ISODate
 * @param amount the amount of weeks to be added
 * @returns the new ISODate with the weeks added
 *
 * @example
 * add 5 weeks to '2022-09-20' => '2022-10-25'
 */
const addWeeks = (
  date: ISODate,
  amount: number,
): ISODate => dateToISODate(DFNSAddDays(isoDateToDate(date), amount * 7));

/**
 * Add the specified number of months to the given ISODate
 * @param date the given ISODate
 * @param amount the amount of months to be added
 * @returns the new ISODate with the months added
 *
 * @example
 * add 6 months to '2022-09-20' => '2023-03-20'
 */
const addMonths = (
  date: ISODate,
  amount: number,
): ISODate => dateToISODate(DFNSAddMonths(isoDateToDate(date), amount));

/**
 * Add the specified number of years to the given ISODate
 * @param date the given ISODate
 * @param amount the amount of years to be added
 * @returns the new ISODate with the years added
 *
 * @example
 * add 10 years to '2022-09-20' => '2032-09-20'
 */
const addYears = (
  date: ISODate,
  amount: number,
): ISODate => dateToISODate(DFNSAddYears(isoDateToDate(date), amount));

/**
 * Get the difference in calendar months between two given ISODates
 * @param date1 the first ISODate to compare
 * @param date2 the second ISODate to compare
 * @returns the difference in calendar months between both ISODates
 *
 * @example
 * '2023-03-20' and '2022-09-20' => 6
 */
const differenceInCalendarMonths = (date1: ISODate, date2: ISODate): number => {
  const yearDiff = Number(getYear(date1)) - Number(getYear(date2));
  const monthDiff = Number(getMonth(date1)) - Number(getMonth(date2));
  return yearDiff * 12 + monthDiff
}

/**
 * Get the difference in calendar days between two given ISODates
 * @param date1 the first ISODate to compare
 * @param date2 the second ISODate to compare
 * @returns the difference in calendar days between both ISODates
 *
 * @example
 * '2022-10-31' and '2022-09-01' => 60
 */
const differenceInCalendarDays = (
  date1: ISODate,
  date2: ISODate,
): number => DFNSDifferenceInCalendarDays(isoDateToDate(date1), isoDateToDate(date2));

/**
 * Return the start of the week for the given ISODate and locale
 * @param date the given ISODate
 * @param locale the locale
 * @returns the first day of the week as an ISODate
 *
 * Locales can differ on which day they consider the first day of the week.
 * For example, the US considers Sunday the first day, however, most countries
 * in Europe consider Monday the first day.
 *
 * @example
 * '2022-09-20' => '2022-09-18' (enUS locale)
 * '2022-09-20' => '2022-09-19' (zhCN locale)
 */
const startOfWeek = (
  date: ISODate,
  locale?: Locale,
): ISODate => dateToISODate(DFNSStartOfWeek(isoDateToDate(date), { locale }));

/**
  * Return the end of the week for the given ISODate and locale
  * @param date the given ISODate
  * @param locale the locale
  * @returns the last day of the week as an ISODate
  *
  * Locales can differ on which day they consider the last day of the week.
  * For example, the US considers Saturday the last day, however, most countries
  * in Europe consider Sunday the last day.
  *
  * @example
  * '2022-09-20' => '2022-09-24' (enUS locale)
  * '2022-09-20' => '2022-09-25' (zhCN locale)
  */
const endOfWeek = (
  date: ISODate,
  locale?: Locale,
): ISODate => dateToISODate(DFNSEndOfWeek(isoDateToDate(date), { locale }));

/**
 * Return the start of the month for the given ISODate
 * @param date the given ISODate
 * @returns the first day of the month as an ISOdate
 *
 * @example
 * '2022-09-20' => '2022-09-01'
 */
const startOfMonth = (date: ISODate): ISODate => `${getYear(date)}-${getMonth(date)}-01`;

/**
 * Return the end of the month for the given ISODate
 * @param date the given ISOate
 * @returns the last day of the month as an ISODate
 *
 * @example
 * '2022-09-20' => '2022-09-30'
 */
const endOfMonth = (date: ISODate): ISODate => {
  const EOMDate = new Date(Number(getYear(date)), Number(getMonth(date)), 0);
  return `${getYear(date)}-${getMonth(date)}-${EOMDate.getDate()}`;
};

/**
 * Return the start of the year for the given ISODate
 * @param date the given ISODate
 * @returns the first day of the year as an ISODate
 *
 * @example
 * '2022-09-20' => '2022-01-01'
 */
const startOfYear = (date: ISODate) => `${getYear(date)}-01-01`;

/**
 * Are the given ISODates in the same year?
 * @param date1 the first ISDODate to compare
 * @param date2 the second ISODate to compare
 * @returns if the ISODates are in the same year
 *
 * @example
 * '2022-09-20' and '2022-03-30' => true
 * '2022-09-20' and '2021-09-20' => false
 */
const isSameYear = (date1: ISODate, date2: ISODate): boolean => getYear(date1) === getYear(date2);

/**
 * Are the given ISODates in the same month (and year)?
 * @param date1 the first ISDODate to compare
 * @param date2 the second ISODate to compare
 * @returns if the ISODates are in the same month (and year)
 *
 * @example
 * '2022-09-20' and '2022-09-30' => true
 * '2022-09-20' and '2022-08-20' => false
 */
const isSameMonth = (
  date1: ISODate,
  date2: ISODate,
): boolean => isSameYear(date1, date2) && getMonth(date1) === getMonth(date2);

/**
 * Are the given ISODates in the same day (and year and month)?
 * @param date1 the first ISDODate to compare
 * @param date2 the second ISODate to compare
 * @returns if the ISODates are in the same day (and year and month)
 *
 * @example
 * '2022-09-20' and '2022-09-20' => true
 * '2022-09-20' and '2022-09-19' => false
 */
const isSameDay = (date1: ISODate, date2: ISODate): boolean => date1 === date2;

/**
 * Is the first date before the second one?
 * @param date1 the first ISDODate to compare
 * @param date2 the second ISODate to compare
 * @returns the first date is before the second date
 *
 * @example
 * '2022-09-20' and '2022-10-01' => true
 */
const isBefore = (date1: ISODate, date2: ISODate) => {
  const year1 = Number(getYear(date1));
  const year2 = Number(getYear(date2));
  if (year1 > year2) return false;
  if (year1 < year2) return true;
  const month1 = Number(getMonth(date1));
  const month2 = Number(getMonth(date2));
  if (month1 > month2) return false;
  if (month1 < month2) return true;
  const day1 = Number(getDay(date1));
  const day2 = Number(getDay(date2));
  if (day1 > day2) return false;
  if (day1 < day2) return true;
  return false;
}

/**
 * Is the first date after the second one?
 * @param date1 the first ISDODate to compare
 * @param date2 the second ISODate to compare
 * @returns the first date is after the second date
 *
 * @example
 * '2022-10-01' and '2022-09-20' => true
 */
const isAfter = (date1: ISODate, date2: ISODate) => {
  const year1 = Number(getYear(date1));
  const year2 = Number(getYear(date2));
  if (year1 < year2) return false;
  if (year1 > year2) return true;
  const month1 = Number(getMonth(date1));
  const month2 = Number(getMonth(date2));
  if (month1 < month2) return false;
  if (month1 > month2) return true;
  const day1 = Number(getDay(date1));
  const day2 = Number(getDay(date2));
  if (day1 < day2) return false;
  if (day1 > day2) return true;
  return false;
}

export {
  START,
  END,
  isISODate,
  getYear,
  getMonth,
  getDay,
  yearToISO,
  monthToISO,
  dayToISO,
  dateToISODate,
  isoDateToDate,
  getWeek,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  differenceInCalendarMonths,
  differenceInCalendarDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  isSameYear,
  isSameMonth,
  isSameDay,
  isBefore,
  isAfter,
};
