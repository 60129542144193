// All the changes in https://code.amazon.com/reviews/CR-154369886 don't need to be added to v4
const defaultValueSerializer = (value: any) => {
  try {
    if (value === null) {
      return value;
    }
    if (typeof value === 'undefined') {
      return value;
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      return value;
    }

    return JSON.stringify(value);
  } catch (error) {
    return value;
  }
}

export default defaultValueSerializer;
