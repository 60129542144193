import { createContext } from 'react';
import { MultiSelectToggleEventHandler, OptionValue } from './types';

interface IMultiSelectContext {
  selectedValuesSet?: Set<OptionValue>;
  onToggle?: MultiSelectToggleEventHandler;
}

const MultiSelectContext = createContext<IMultiSelectContext>({
  selectedValuesSet: undefined,
  onToggle: undefined,
});

export default MultiSelectContext;
