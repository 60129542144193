import { css, ThemedStyledProps } from 'styled-components';
import { Theme as DefaultTheme } from '../../theme/themes/default';

type ArrowThemedProps<P> = ThemedStyledProps<P, DefaultTheme>;

export interface ArrowCssProps {
  $position: 'top' | 'bottom' | 'right' | 'left';
  styleType: 'blue' | 'dark' | 'light';
}

const arrow = css`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;

  ::after {
    content: " ";
    position: absolute;
    /*! @noflip */ transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: ${({ theme }: ArrowThemedProps<ArrowCssProps>) => (theme.surface.backgroundColor)};
  }

  /*! @noflip */
  *[data-popper-placement^="right"] > & {
    /*! @noflip */ left: 0px;
  }

  /*! @noflip */
  *[data-popper-placement^="right"] > &::before {
    /*! @noflip */ left: -6px;
  }

  /*! @noflip */
  *[data-popper-placement^="right"] > &::after {
    /*! @noflip */ left: -4px;
    /*! @noflip */ box-shadow: ${({ theme }: ArrowThemedProps<ArrowCssProps>) => (theme.tooltip.arrowShadow.right)};
  }

  /*! @noflip */
  *[data-popper-placement^="left"] > & {
    /*! @noflip */ right: 0px;
  }

  /*! @noflip */
  *[data-popper-placement^="left"] > &::before {
    /*! @noflip */ right: -6px;
  }

  /*! @noflip */
  *[data-popper-placement^="left"] > &::after {
    /*! @noflip */ right: -4px;
    /*! @noflip */ box-shadow: ${({ theme }: ArrowThemedProps<ArrowCssProps>) => (theme.tooltip.arrowShadow.left)};
  }

  *[data-popper-placement^="top"] > & {
    bottom: 0px;
  }

  *[data-popper-placement^="top"] > &::before {
    bottom: -6px;
  }

  *[data-popper-placement^="top"] > &::after {
    bottom: -4px;
    /*! @noflip */ box-shadow: ${({ theme }: ArrowThemedProps<ArrowCssProps>) => (theme.tooltip.arrowShadow.top)};
  }

  *[data-popper-placement^="bottom"] > & {
    top: 0px;
  }

  *[data-popper-placement^="bottom"] > &::before {
    top: -6px;
  }
  *[data-popper-placement^="bottom"] > &::after {
    top: -4px;
    /*! @noflip */ box-shadow: ${({ theme }: ArrowThemedProps<ArrowCssProps>) => (theme.tooltip.arrowShadow.bottom)};
  }
`;

export default arrow;
