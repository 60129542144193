import styled, { css } from 'styled-components';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

export const StyledTree = styled('ul')`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;

  &&[role="tree"] > [role="treeitem"]:first-of-type > div:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.card.radius};
    border-top-right-radius: ${({ theme }) => theme.card.radius};
  }
`;

function getBottomBorder(root: boolean, displayed: boolean) {
  if (displayed) return 'none';
  return root ? '1px solid #e7e7e7' : 'none';
}

interface TreeListItemProps {
  $root: boolean;
  $displayed: boolean;
}
export const StyledTreeList = styled('li')<TreeListItemProps>`
  :not(:last-child) {
    border-bottom: ${({ $root, $displayed }) => getBottomBorder($root, $displayed)}
  }
`;

interface NodeWrapperProps {
  $lastNode: boolean;
}
export const NodeWrapper = styled('div')<NodeWrapperProps>`
  display: flex;
  align-items: stretch;
  ${isMobile(css`
    margin: 0;
  `)}
  :hover {
    background: ${({ theme }) => theme.palette.gray[50]};
    ${({ $lastNode }) => $lastNode && css`
      border-bottom-left-radius: ${({ theme }) => theme.card.radius};
      border-bottom-right-radius: ${({ theme }) => theme.card.radius};
    `}
  }
`;

export const RenderNodeWrapper = styled('div')`
  flex-grow: 1;
  padding: 4px 0;
  ${isMobile(css`
    padding: 3px 0;
  `)}
`;
