import styled, { css } from 'styled-components';
import { mergeProps } from '@react-aria/utils';
import type { FocusRingAria } from '@react-aria/focus';
import {
  normalStyles as toggleAreaNormalStyles,
  basicStyles as toggleAreaBasicStyles,
  mobileBasicStyles as toggleAreaMobileBasicStyles,
  disabledStyles as toggleAreaDisabledStyles,
} from '../../../Button/ToggleArea/ToggleArea';
import isMobile from '../../../theme/style-mixins/isMobile/isMobile';

export interface StyledLabelProps {
  disabled?: boolean;
  $active?: boolean;
}

const activeStyles = css`
  border-color: ${({ theme }) => theme.button.toggleArea.borderColorActive};
  background-color: ${({ theme }) => theme.button.toggleArea.bgActive};
  border-color: unset;
`;

const normalStyles = css`
  ${toggleAreaNormalStyles}
  border-color: unset;
`;

export const Label = styled.label<StyledLabelProps>`
  ${toggleAreaBasicStyles}

  /* aui style */
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 0;
  /* aui style */

  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;

  ${isMobile(css`
    ${toggleAreaMobileBasicStyles}
    padding: 0;
  `)}

  ${({ $active }) => ($active ? activeStyles : normalStyles)}

  outline: none;

  ${({ disabled }) => (disabled && css`
    ${toggleAreaDisabledStyles}
  `)}
`;

interface InputProps {
  focusProps?: FocusRingAria['focusProps']
}
export const Input = styled.input.attrs<InputProps>(({ focusProps, onBlur, onFocus }) => (mergeProps({ onBlur, onFocus }, focusProps)))<InputProps>`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  display: inline;
  outline: 0;
  opacity: 0;
  width: 0;
  height: 0;
`;
