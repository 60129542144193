import React, { FC, ReactNode } from 'react';
import {
  TaktIdProvider,
  Theme,
  ThemeProvider,
  createStormTaktId,
} from '@amzn/storm-ui-v3';
import DatePickerProvider from './DatePicker/DatePickerProvider';
import NavigationProvider from './Navigation/NavigationProvider';
import DateSingleProvider from './DateSingle/DateSingleProvider';
import DateRangeProvider from './DateRange/DateRangeProvider';
import DateInputProvider from './DateInput/DateInputProvider';
import FocusProvider from './Focus/FocusProvider';
import { AllPickerProps } from '../types';

const themeOverride = (theme: Theme) => ({
  ...theme,
  tooltip: {
    ...theme.tooltip,
    mouseLeaveTimeout: 0,
  },
});

type ContextProviderProps = AllPickerProps & {
  children: ReactNode;
};

const ContextProvider: FC<React.PropsWithChildren<ContextProviderProps>> = ({
  children,
  ...baseProps
}) => (
  <DatePickerProvider baseProps={baseProps}>
    <NavigationProvider>
      <DateSingleProvider baseProps={baseProps}>
        <DateRangeProvider baseProps={baseProps}>
          <DateInputProvider baseProps={baseProps}>
            <FocusProvider>
              <ThemeProvider theme={themeOverride}>
                <TaktIdProvider
                  taktId={baseProps.taktId}
                  taktValue={baseProps.taktValue}
                  fallbackId={
                    createStormTaktId(baseProps.type && baseProps.type === 'range' ? 'date-range-picker' : 'date-picker')
                  }
                >
                  {children}
                </TaktIdProvider>
              </ThemeProvider>
            </FocusProvider>
          </DateInputProvider>
        </DateRangeProvider>
      </DateSingleProvider>
    </NavigationProvider>
  </DatePickerProvider>
);

export default ContextProvider;
