/* eslint-disable id-length */
import { palette } from '../base/color';
import { spacing, mobileSpacing } from '../base/spacing';
import {
  fontSizes,
  mobileFontSizes,
  typography,
} from '../base/typography';

export const PILL_COLOR = '#68768C';
const PILL_COLOR_HOVER = '#4A5465';
const PILL_COLOR_BOX_SHADOW = '#232F3F80'

const pill = {
  // legacy styles for 2.x
  bg: palette.gray[200],
  color: typography.color.primary,
  closeButton: {
    color: palette.gray[700],
    disabledColor: palette.gray[300],
    hoverColor: palette.squidInk,
  },
  disabledBg: palette.gray[100],
  disabledText: typography.color.tertiary,
  paddingInlineStart: spacing.small,
  paddingInlineEnd: '3px',
  radius: '30px',

  lineHeight: '22px',
  fontSize: fontSizes.small,
  iconPadding: `0 ${spacing.micro} 2px ${spacing.micro}`,

  // styles
  paddingHorizontal: spacing.small,
  bgColor: PILL_COLOR,
  textColor: palette.white,
  maxWidth: '500px',
  height: '32px',
  borderRadius: '32px', // align with line height
  prefixPaddingEnd: '3px',
  suffixPaddingStart: '3px',
  hover: {
    bgColor: PILL_COLOR_HOVER,
    boxShadow: `0px 1px 3px 0px ${PILL_COLOR_BOX_SHADOW}`,
  },
  disabled: {
    bgColor: palette.gray[100],
    textColor: palette.gray[700],
  },
  applied: {
    bgColor: palette.gray[900],
  },
  small: {
    height: '22px',
    radius: '22px',
    paddingHorizontal: spacing.base,
  },
};

export const mobilePill = {
  mobile: {
    // legacy styles for 2.x
    fontSize: mobileFontSizes.small,
    iconPadding: `0 ${mobileSpacing.micro} 2px ${mobileSpacing.micro}`,

    // styles
    height: '35px',
    radius: '35px',
    small: {
      height: '30px',
      radius: '30px',
    },
  },
};

export default pill;
