import MobileMode from '../../MobileThemeProvider/MobileMode';

export default function isMobile(style: any) {
  return ({ theme }: any) => {
    const mode = theme?.mobile?.mode;
    if (mode === MobileMode.Auto) {
      return ['@media ', theme.mobile.mediaQuery, '{', style, '}'];
    }
    if (mode === MobileMode.Enable) {
      return [style];
    }
    return null;
  };
}
