import { palette } from '../base/color';
import { spacing, spacingValues } from '../base/spacing';
import { fontSizeValues } from '../base/typography';

const stepsStyles = {
  stepSpacing: spacing.small,
  circleSize: '17px',
  circleSpacing: spacing.mini,
  circleBackgroundColorCompleted: palette.green[700],
  circleBackgroundColorFuture: palette.squidInk,
  circleBackgroundOpacityFuture: 0.15,
  circleBackgroundOpacityDisabled: 0.08,
  circleBackgroundOpacityDefault: 1,
  circleContentColorCompleted: palette.white,
  circleContentColorFuture: palette.squidInk,
  circleContentSize: `${fontSizeValues.mini}px`,
  circleContentTop: '0px',
  circleIconTop: '1px',
  circleIconLeft: '3px',
  circleContentLeft: '5.5px',
  tailColor: palette.gray[100],
  titleColor: palette.squidInk,
  titleColorDisabled: palette.gray[700],
  titleFontWeightActive: 'bold',
  titleFontWeightDefault: 'normal',
  /* values specific to vertical label */
  // half circle size
  verticalLabelTailTop: '8px',
  // half circle size plus small space
  verticalLabelTailPadding: `0 ${8 + spacingValues.small}px`,
  // for matching the space between circle and text
  verticalLabelContentTop: '-1px',
};

export default stepsStyles;
