import React, { ReactNode, KeyboardEvent, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { angleInlineEnd } from '@amzn/storm-ui-icons-v3';
import { keyboardKeynames as Keys } from '@amzn/storm-ui-utils-v3';
import Checkbox from '../../Checkbox';
import Icon from '../../Icon';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import focusOutline from '../../FocusIndicator/styleMixins/focusOutline';

const Container = styled.div<{ $isSelected: boolean | undefined }>`
  display: flex;
  padding-inline-start: ${({ theme }) => theme.spacing.small};
  padding-inline-end: ${({ theme }) => theme.spacing.small};
  padding-block-start: ${({ theme }) => theme.spacing.small};
  padding-block-end: ${({ theme }) => theme.spacing.small};
  :not(:last-child) {
    border-bottom-color: ${({ $isSelected, theme }) => ($isSelected ? theme.card.selected.borderColor : theme.divider.color)};
    padding-block-end: ${({ theme }) => theme.spacing.micro};
    border-bottom-style: solid;
    border-width: 1px;
  }
`;

const ClickableContainer = styled.div<{ $isClickable: boolean }>`
  margin: 0;
  padding: 0;
  border: 0;
  background: inherit;
  font: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;
  text-align: inherit;
  vertical-align: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  ${({ $isClickable }) => $isClickable && { cursor: 'pointer' }}
`;

const StartColumn = styled.div`
  margin-inline-end: ${({ theme }) => theme.spacing.small};
`;

const CenterColumn = styled.div`
  flex-grow: 1;
`;

const EndColumn = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.typography.size.base};
  ${isMobile(css`
    font-size: ${({ theme }) => theme.typography.mobile.size.base};
  `)}
`;

const CheckboxWrapper = styled.div`
  height: 100%;
  margin: -10px;
  padding: 10px;
  cursor: pointer;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';

const PositionedCheckbox = styled(Checkbox)`
  > div {
    margin-inline-start: ${({ theme }) => theme.spacing.none};
  }
`;

const StyledIcon = styled(Icon)
  .attrs(props => {
    const { isFocusVisible, focusProps } = useFocusRing();
    return {
      ...mergeProps(props, focusProps),
      focusVisible: isFocusVisible,
    };
  })`
  color: ${({ theme }) => theme.icon.color};
  :focus {
    outline: none;
    ${({ focusVisible }) => (focusVisible && focusOutline)}
  }
  :hover {
    color: ${({ theme }) => theme.icon.hover.color};
  }
`;

const handleStopPropagation = (event: MouseEvent | KeyboardEvent) => event.stopPropagation();

const getClickableContainerAs = ({ href, onClick }: { href: unknown, onClick: unknown }) => {
  if (typeof href === 'string') {
    return 'a';
  }
  if (typeof onClick === 'function') {
    return 'button';
  }
  return 'div'
}

export interface ContentProps {
  href?: string;
  id: string;
  isSelected?: boolean;
  onSelect?: (event: MouseEvent | KeyboardEvent) => void;
  onClick?: (event: MouseEvent | KeyboardEvent) => void;
  ariaLabel: string;
  children: ReactNode;
}

const Content = ({
  href,
  id,
  isSelected,
  onSelect,
  onClick,
  ariaLabel,
  children,
  ...restProps
}: ContentProps): JSX.Element => {
  const isClickable = (typeof href === 'string') || (typeof onClick === 'function');

  return (
    <Container
      $isSelected={isSelected}
    >
      { onSelect && (
        <StartColumn
          onClick={handleStopPropagation}
          onKeyUp={handleStopPropagation}
          role="none"
        >
          <CheckboxWrapper
            onClick={event => onSelect && onSelect(event)}
            onKeyUp={event => event.key === Keys.ENTER && onSelect && onSelect(event)}
            role="none"
          >
            <PositionedCheckbox
              id={`${id}_checkbox`}
              label={ariaLabel}
              hideLabel
              checked={isSelected}
              size="large"
              positionWithLabel="start"
            />
          </CheckboxWrapper>
        </StartColumn>
      )}
      <ClickableContainer
        {...restProps}
        {...(isClickable && {
          'aria-label': ariaLabel,
        })}
        as={getClickableContainerAs({ href, onClick })}
        href={href}
        onClick={(event: MouseEvent) => {
          if (onClick) {
            onClick(event);
          }
        }}
        $isClickable={isClickable}
      >
        <CenterColumn id={`${id}_center_column`}>
          { children }
        </CenterColumn>
        {onClick && (
          <EndColumn>
            <StyledIcon
              type={angleInlineEnd}
              size="lg"
            />
          </EndColumn>
        )}
      </ClickableContainer>
    </Container>
  );
}

Content.defaultProps = {
  href: undefined,
  isSelected: false,
  onSelect: undefined,
  onClick: undefined,
};

export default Content;
