/* eslint-disable quotes, quote-props, comma-dangle  */
import type { TranslationProps } from './types';

export const translations = {
  "button_cancel": [
    {
      "type": 0,
      "value": "Annuleren"
    }
  ],
  "button_save": [
    {
      "type": 0,
      "value": "Opslaan"
    }
  ],
  "multi_CUSTOM": [
    {
      "type": 0,
      "value": "Aangepast"
    }
  ],
  "multi_DAY": [
    {
      "type": 0,
      "value": "Selecteer een enkele dag"
    }
  ],
  "multi_MONTH": [
    {
      "type": 0,
      "value": "Selecteer een maand"
    }
  ],
  "multi_WEEK": [
    {
      "type": 0,
      "value": "Selecteer een week"
    }
  ],
  "preset_LAST_30_DAYS": [
    {
      "type": 0,
      "value": "Afgelopen 30 dagen"
    }
  ],
  "preset_LAST_7_DAYS": [
    {
      "type": 0,
      "value": "Afgelopen 7 dagen"
    }
  ],
  "preset_LAST_MONTH": [
    {
      "type": 0,
      "value": "Afgelopen maand"
    }
  ],
  "preset_LAST_WEEK": [
    {
      "type": 0,
      "value": "Afgelopen week"
    }
  ],
  "preset_LIFETIME": [
    {
      "type": 0,
      "value": "Levensduur"
    }
  ],
  "preset_THIS_WEEK": [
    {
      "type": 0,
      "value": "Deze week"
    }
  ],
  "preset_TODAY": [
    {
      "type": 0,
      "value": "Vandaag"
    }
  ],
  "preset_YEAR_TO_DATE": [
    {
      "type": 0,
      "value": "Jaar tot nu toe"
    }
  ],
  "preset_YESTERDAY": [
    {
      "type": 0,
      "value": "Gisteren"
    }
  ]
};

function TranslationProviderComponent({ children }: TranslationProps) {
  return children(translations);
}

export default Object.assign(TranslationProviderComponent, { translations });
