import React, {
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../../TaktIdContext';

export const StyledFieldset = styled('fieldset')`
  border: none;
  margin: 0;
  padding: 0;
`

export const RadioButtonContainer = styled('div') <{
  inline?: boolean
}>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};

  ${isMobile(css`
    background-color: ${({ theme }) => theme.form.radio.mobile.groupBackgroundColor};
    border: 1px solid ${({ theme }) => theme.form.radio.mobile.groupBorderColor};
    border-radius: ${({ theme }) => theme.form.radio.mobile.groupBorderRadius};
    /* Need to override flex-direction because inline isn't supported for mobile */
    flex-direction: column;
  `)}
`;
RadioButtonContainer.displayName = 'RadioButtonContainer';

const StyledLegend = styled('legend') <{
  disabled?: boolean;
  inlineLabel?: boolean;
  inline?: boolean;
}>`
  ${({ theme }) => theme.typography.base};
  margin-bottom: ${({ inlineLabel, theme }) => (!inlineLabel ? theme.spacing.micro : 0)};
  font-weight: ${({ theme }) => theme.form.label.weight};
  padding: 0;

  /* Need to adjust this when we work to support RTL */
  margin-inline-end: ${({ inline, inlineLabel, theme }) => {
    if (inline) {
      return theme.spacing.base;
    }
    if (inlineLabel) {
      return theme.spacing.mini;
    }
    return 0;
  }};

  ${({ hidden }) => (hidden && `
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  `)}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    outline: none;
    color: ${({ theme }) => theme.form.label.colorDisabled};
  `}

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
    margin-bottom: ${({ theme }) => theme.spacing.micro};
  `)}

  /* AUI Overrides */
  display: block;
  box-sizing: border-box;
`;
StyledLegend.displayName = 'StyledLegend';

const RadioGroupContainer = styled('div') <{
  inlineLabel?: boolean;
  inline?: boolean;
}>`
  ${({ inline, inlineLabel }) => (inlineLabel && `
    align-items: ${inline ? 'center' : 'flex-start'};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `)}

  /* The "inline" and "inlineLabel" props are not supported for mobile experience, overrides ensure no side effects */
  ${isMobile(css<{
  inlineLabel?: boolean;
}>`
    ${({ inlineLabel }) => (inlineLabel && `
      align-items: stretch;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `)}
  `)}
`;
RadioGroupContainer.displayName = 'RadioGroupContainer';

export interface RadioButtonGroupProps extends TaktProps {
  hideLabel?: boolean;
  inlineLabel?: boolean;
  label?: ReactNode;
  inline?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

const RadioButtonGroup = ({
  children,
  inline,
  inlineLabel,
  label,
  disabled,
  hideLabel,
  taktId,
  taktValue,
  ...rest
}: RadioButtonGroupProps) => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('radio-button-group')}>
    <StyledFieldset
      {...rest}
    >
      <RadioGroupContainer
        inline={inline}
        inlineLabel={inlineLabel}
      >
        {label !== undefined ? (
          <StyledLegend
            disabled={disabled}
            hidden={hideLabel}
            inline={inline}
            inlineLabel={inlineLabel}
          >
            {label}
          </StyledLegend>
        ) : null}
        <RadioButtonContainer inline={inline}>
          {children}
        </RadioButtonContainer>
      </RadioGroupContainer>
    </StyledFieldset>
  </TaktIdProvider>
)

RadioButtonGroup.defaultProps = {
  hideLabel: false,
  inlineLabel: false,
  label: undefined,
  inline: false,
  disabled: false,
}

export default RadioButtonGroup;
