import React, {
  FC,
  ReactNode,
  useState,
  Validator,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  BottomSheet,
  BottomSheetProps,
  isMobileStyleMixin,
  Icon,
  TextButton,
  Tooltip,
  TooltipProps,
  useIsMobile,
  TextButtonProps,
  TaktProps,
  TaktIdConsumer,
  createStormTaktId,
} from '@amzn/storm-ui';
import { infoCircle } from '@amzn/storm-ui-icons';

export interface AdaptiveHelpTipBottomSheetProps extends Omit<BottomSheetProps, 'children' | 'closeButton'> {
  closeButton?: {
    type: 'icon' | 'message',
    label: string
  };
}

export interface AdaptiveHelpTipProps extends TaktProps {
    /**
   * Supplied to the popover element. Useful for identifying the tooltip in front-end telemetry.
   * @defaultValue `""`
   */
  id?: string;
    /**
   * Render prop used to override the trigger icon of the `<AdaptiveHelpTip>`.
   * @defaultValue `<TooltipIcon type={infoCircle} blockSize />`
   */
  renderTrigger?: ReactNode;
    /**
   * Label for the trigger icon.
   * @defaultValue `"Open tooltip"`
   */
  triggerLabel?: string;
    /**
   * Additional props that are applied to the help tip trigger `<TextButton />`.
   * @defaultValue `{}`
   */
  triggerProps?: Omit<TextButtonProps, 'ref'>;
    /**
   * [Additional props](/components/tooltip/?tab=code#tooltip-props) that are applied to the `<Tooltip />`.
   * @defaultValue `{}`
   */
  tooltipProps?: Omit<TooltipProps, 'trigger'>;
    /**
   * [Additional props](/components/sheet/?tab=code#bottomsheet-props) that are applied to the `<BottomSheet />`.
   * @defaultValue `{}`
   */
  bottomSheetProps?: AdaptiveHelpTipBottomSheetProps;
}

export const TooltipButton = styled(TextButton)`
  vertical-align: middle;

  border: none;
  background: transparent;
  padding: ${({ theme }) => theme.helptip.triggerIcon.padding};
  margin: ${({ theme }) => theme.helptip.triggerIcon.margin};
  outline: none;

  position: relative;
  top: -1px;

  ${isMobileStyleMixin(css`
    padding: ${({ theme }) => theme.helptip.triggerIcon.mobile.padding};
  `)}
`;
export const TooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.helptip.triggerIcon.color};
  display: block;
  font-size: ${({ theme }) => theme.helptip.triggerIcon.size};

  :hover {
    color: ${({ theme }) => theme.helptip.triggerIcon.hoverColor};
    cursor: pointer;
  }

  ${isMobileStyleMixin(css`
    font-size: ${({ theme }) => theme.helptip.triggerIcon.mobile.size};
  `)}
`;
TooltipIcon.displayName = 'TooltipIcon';

const AdaptiveHelpTip: FC<React.PropsWithChildren<AdaptiveHelpTipProps>> = props => {
  const isMobile = useIsMobile();
  const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState(false);

  const {
    children,
    id,
    renderTrigger,
    triggerLabel,
    triggerProps,
    tooltipProps,
    bottomSheetProps,
    taktId,
    taktValue,
  } = props;

  return (
    isMobile ? (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('adaptive-help-tip')}>
        {({ getDataTaktAttributes }) => (
          <>
            <BottomSheet
              closeButton={{
                type: 'icon',
                label: 'close',
              }}
              {...bottomSheetProps}
              isOpen={bottomSheetIsOpen}
              onClose={() => setBottomSheetIsOpen(false)}
            >
              {children}
            </BottomSheet>
            <TooltipButton
              {...getDataTaktAttributes({ taktIdSuffix: 'trigger-button' })}
              {...triggerProps}
              type="button"
              aria-label={triggerLabel}
              onClick={() => setBottomSheetIsOpen(true)}
            >
              {renderTrigger}
            </TooltipButton>
          </>
        )}
      </TaktIdConsumer>
    ) : (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('adaptive-help-tip')}>
        {({ getDataTaktAttributes }) => (
          <Tooltip
            {...tooltipProps}
            id={id}
            trigger={(
              <TooltipButton
                {...getDataTaktAttributes({ taktIdSuffix: 'trigger-button' })}
                {...triggerProps}
                type="button"
                aria-label={triggerLabel}
              >
                {renderTrigger}
              </TooltipButton>
            )}
            message={children}
          />
        )}
      </TaktIdConsumer>
    )
  );
};

AdaptiveHelpTip.propTypes = {
  /**
   * Supplied to the popover element. Useful for identifying the tooltip in front-end telemetry.
   */
  id: PropTypes.string,
  /**
   * Render prop used to override the trigger icon of the `<AdaptiveHelpTip>`.
   */
  renderTrigger: PropTypes.node as PropTypes.Validator<ReactNode>,
  /**
   * Label for the trigger icon.
   */
  triggerLabel: PropTypes.string,
  /**
   * Additional props that are applied to the help tip trigger `<TextButton />`.
   */
  triggerProps: PropTypes.objectOf(PropTypes.any),
  /**
   * [Additional props](/components/tooltip/?tab=code#tooltip-props) that are applied to the `<Tooltip />`.
   */
  tooltipProps: PropTypes.objectOf(PropTypes.any) as Validator<TooltipProps>,
  /**
   * [Additional props](/components/sheet/?tab=code#bottomsheet-props) that are applied to the `<BottomSheet />`.
   */
  bottomSheetProps: PropTypes.objectOf(PropTypes.any) as Validator<BottomSheetProps>,
};

AdaptiveHelpTip.defaultProps = {
  id: '',
  triggerLabel: 'Open tooltip',
  triggerProps: {},
  tooltipProps: {},
  bottomSheetProps: {},
  renderTrigger: (<TooltipIcon type={infoCircle} blockSize />),
};

export default AdaptiveHelpTip;
