import React, {
  Children, cloneElement, FC, isValidElement, PureComponent, ReactNode,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb';
import isMobile from '../theme/style-mixins/isMobile/isMobile';

const Nav = styled.nav`
  ${({ theme }) => theme.typography.base}
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.breadcrumbs.color};

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
`;
Nav.displayName = 'Nav';

export interface BreadcrumbGroupProps {
  /**
   * The React nodes that are rendered by the BreadcrumbGroup.
   */
  children: ReactNode,
}

export default class BreadcrumbGroup extends PureComponent<BreadcrumbGroupProps> {
  static propTypes = {
    /**
     * The React nodes that are rendered by the BreadcrumbGroup.
     */
    children: PT.node.isRequired,
  }

  render(): JSX.Element {
    const { children } = this.props;
    return (
      (
        <Nav>
          {Children.map(children, (child, index) => {
            if (!isValidElement(child)) {
              return null;
            }

            if ((child.type as FC<React.PropsWithChildren<Breadcrumb>>).name !== Breadcrumb.name) {
              return cloneElement(child, {
                // eslint-disable-next-line react/no-array-index-key
                key: index,
              });
            }

            return cloneElement(child, {
              key: child.props.label,
              // Set isFinal to false as default so the angle-right icon renders for single breadcrumb
              isFinal: Array.isArray(children) ? index === children.length - 1 : false,
            } as BreadcrumbProps);
          })}
        </Nav>
      )
    );
  }
}
