/* eslint-disable quotes, quote-props, comma-dangle, max-len */
import arAE from './ar-AE';
import deDE from './de-DE';
import enAU from './en-AU';
import enCA from './en-CA';
import enGB from './en-GB';
import enIN from './en-IN';
import enSG from './en-SG';
import enUS from './en-US';
import esCO from './es-CO';
import esES from './es-ES';
import esMX from './es-MX';
import esUS from './es-US';
import frCA from './fr-CA';
import frFR from './fr-FR';
import hiIN from './hi-IN';
import itIT from './it-IT';
import jaJP from './ja-JP';
import koKR from './ko-KR';
import nlNL from './nl-NL';
import plPL from './pl-PL';
import ptBR from './pt-BR';
import svSE from './sv-SE';
import taIN from './ta-IN';
import thTH from './th-TH';
import trTR from './tr-TR';
import viVN from './vi-VN';
import zhCN from './zh-CN';
import zhTW from './zh-TW';

import type { Translation } from './types';

export type { Translations } from './types';

/**
 * Imports for translations
 */
const translationComps: Record<string, Translation> = {
  'ar-AE': arAE,
  'de-DE': deDE,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-IN': enIN,
  'en-SG': enSG,
  'en-US': enUS,
  'es-CO': esCO,
  'es-ES': esES,
  'es-MX': esMX,
  'es-US': esUS,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'hi-IN': hiIN,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'sv-SE': svSE,
  'ta-IN': taIN,
  'th-TH': thTH,
  'tr-TR': trTR,
  'vi-VN': viVN,
  'zh-CN': zhCN,
  'zh-TW': zhTW
};

export function getTranslationComponent(locale?: string) {
  return {
    component: (locale && locale in translationComps) ? translationComps[locale] : enUS,
    locale: (locale && locale in translationComps) ? locale : 'en-US'
  };
}
