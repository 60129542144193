import { typography, fontSizeValues } from '../base/typography';

const tabNavigationStyles = {
  // legacy styles for 2.x
  marginRightBase: '22px',
  marginRightSmall: '10px',
  marginRightMobile: '10px',
  paddingBase: '9px 6px',
  paddingSmall: '14px 5px',

  // styles
  marginEndBase: '36px',
  marginEndSmall: '22px',
  paddingAllBase: '9px 0px',
  paddingAllSmall: '14px 0px',
  color: typography.color.secondary,
  fontWeight: 'normal',
  base: '15px',
  small: `${fontSizeValues.base}px`,
  lineHeightBase: '22px',
  lineHeightSmall: '1',
  bottomBorder: '3px solid transparent',
  hoverBottomBorder: '3px solid transparent',
  activeColor: typography.color.primary,
  activeFontWeight: 'bold',
  activeBottomBorder: '3px solid transparent',
};

export const mobileTabNavigationStyles = {
  mobile: {
    marginEndBase: '26px',
    marginEndSmall: '22px',
    scrollableIndicatorBackground: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%)',
    scrollableIndicatorBackgroundRTL: 'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%)',
    width: '60px',
  },
};

export default tabNavigationStyles;
