import { useContext } from 'react';
import { ErrorIdContext } from './ErrorIdProvider';

/**
 * DO NOT USE OUTSIDE OF STORM UI
 *
 * Returns a function to throw an error
 * decorated with a searchable StormUI keyword
 *
 * If the ErrorIdProvider context is
 * available, the error message will
 * be prepended with the given error ID
 */
const useStormError = () => {
  const id = useContext(ErrorIdContext)?.id;
  const throwStormError = (feature: string, message: string): void => {
    let errorMsg = `storm-ui:${feature} ${message}`;
    if (id) {
      errorMsg = `${id}:${errorMsg}`;
    }
    throw new Error(errorMsg);
  }
  return { throwStormError };
};

export default useStormError;
