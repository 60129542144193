import React, { ReactNode } from 'react';
import { angleDown, angleInlineEnd } from '@amzn/storm-ui-icons';
import { createStormTaktId, withTaktFallbackId } from '../TaktIdContext';
import {
  DropdownButton as StyledDropdownButton,
  DropdownButtonProps as StyledButtonProps,
  DropdownButtonContent,
  DropdownIcon,
} from './Dropdown.styles';
import { useDropdownIsMobile } from './DropdownIsMobileContext';

const StyledButton = withTaktFallbackId<StyledButtonProps>(StyledDropdownButton)

export interface DropdownButtonProps extends StyledButtonProps {
  buttonText: ReactNode;
  disabled?: boolean;
  small?: boolean;
}

const DropdownButton = ({
  buttonText,
  disabled,
  small,
  ...restProps
}: DropdownButtonProps) => {
  const isMobile = useDropdownIsMobile();

  return (
    <StyledButton
      {...restProps}
      taktFallbackId={createStormTaktId('trigger-button')}
      disabled={disabled}
      small={small}
    >
      <DropdownButtonContent>
        {buttonText}
      </DropdownButtonContent>
      <DropdownIcon type={isMobile ? angleInlineEnd : angleDown} $disabled={disabled} $small={small} />
    </StyledButton>
  );
}
DropdownButton.defaultProps = {
  disabled: false,
  small: false,
}

export default DropdownButton;
