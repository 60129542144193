import React from 'react';
import { DropdownDivider } from '@amzn/storm-ui';
import { useMultiSelectIsMobile } from './MultiSelectIsMobileContext'

function MultiSelectDivider() {
  const isMobile = useMultiSelectIsMobile();

  if (isMobile) {
    return null;
  }
  return <DropdownDivider />
}

export default MultiSelectDivider;
