import React, { PureComponent, ReactNode, createRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Transition, TransitionStatus } from 'react-transition-group';
import { caretDown, caretInlineEnd } from '@amzn/storm-ui-icons-v3';
import { MergeElementProps } from '@amzn/storm-ui-utils-v3';
import Icon from '../Icon';
import Text from '../Text';
import { TaktProps } from '../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../TaktIdContext';

const ExpanderWrapper = styled.div`
  width: 100%;
`;
ExpanderWrapper.displayName = 'ExpanderWrapper';

const ExpanderTab = styled.div`
  cursor: pointer;
  border: none;
  background: none;
  padding: ${({ theme }) => theme.spacing.mini};
  padding-left: 0;
  width: 100%;

  > h3 {
    margin: 0;
    margin-inline-start: ${({ theme }) => theme.spacing.small};
    display: inline-block;
  }
`;
ExpanderTab.displayName = 'ExpanderTab';

export interface ExpanderContentProps {
  $transition?: string;
  $transitionState: TransitionStatus;
}

const ExpanderContent = styled.section<ExpanderContentProps>`
  border-top: 1px solid ${({ theme }) => theme.palette.alto};
  padding-top: ${({ theme }) => theme.spacing.base};
  padding-inline-start: ${({ theme }) => theme.spacing.base};
  overflow-y: hidden;
  max-height: 0;

  @media (prefers-reduced-motion:no-preference) {
    transition: ${({ $transition }) => ($transition)};
  }

  ${({ $transitionState }) => (({
    entering: 'height: 0',
    entered: 'max-height: 100vh',
  } as Record<TransitionStatus, string>)[$transitionState])}
`;
ExpanderContent.displayName = 'ExpanderContent';

export interface ExpanderProps extends MergeElementProps<'div'>, TaktProps {
  label: string;
  /**
   * @defaultValue `false`
   */
  defaultOpen?: boolean;
  /**
   * @defaultValue `undefined`
   */
  transition?: string;
  /**
   * @defaultValue `0`
   */
  transitionDuration?: number;
}

export default class Expander extends PureComponent<ExpanderProps, { isOpen?: boolean; }> {
  static propTypes = {
    children: PT.node.isRequired,
    label: PT.node.isRequired,
    defaultOpen: PT.bool,
    transition: PT.string,
    transitionDuration: PT.number,
  }

  static defaultProps = {
    defaultOpen: false,
    transition: undefined,
    transitionDuration: 0,
  }

  private containerRef = createRef<HTMLElement>();

  constructor(props: ExpanderProps) {
    super(props);

    this.state = {
      isOpen: props.defaultOpen,
    };
  }

  handleClick = (): void => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  renderIcon = (): ReactNode => (this.state.isOpen
    ? <span><Icon type={caretDown} /></span>
    : <Icon type={caretInlineEnd} />
  );

  render(): JSX.Element {
    const {
      children,
      label,
      transition,
      transitionDuration = 0,
      taktId,
      taktValue,
      ...rest
    } = this.props;
    const { isOpen } = this.state;

    return (
      <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('expander')}>
        <ExpanderWrapper {...rest}>
          <ExpanderTab onClick={this.handleClick}>
            {this.renderIcon()}
            <Text type="h3">{label}</Text>
          </ExpanderTab>

          <Transition
            timeout={transitionDuration}
            appear
            in={isOpen}
            nodeRef={this.containerRef}
          >
            {(state: TransitionStatus) => {
              if (state === 'exited') return null;

              return (
                <ExpanderContent
                  $transitionState={state}
                  $transition={transition}
                  ref={this.containerRef}
                >
                  {children}
                </ExpanderContent>
              );
            }}
          </Transition>
        </ExpanderWrapper>
      </TaktIdProvider>
    );
  }
}
