import React, { FC } from 'react';
import { format } from 'date-fns';
import useDatePicker from '../../hooks/useDatePicker';
import { ISODate } from '../../types';
import { getYear, getMonth } from '../../utils/dateUtils';

/**
 * Returns the full month name and year of the given month
 * 1/1/2000 returns January 2000
 */
const FormatMonth: FC<React.PropsWithChildren<{ month: ISODate }>> = ({ month }) => {
  const { locale } = useDatePicker();
  return (
    <>
      {format(
        new Date(Number(getYear(month)), Number(getMonth(month)) - 1, 1, 0, 0, 0, 0),
        'LLLL y',
        { locale },
      )}
    </>
  );
};

export default FormatMonth;
