import React, {
  forwardRef,
  useCallback,
  ChangeEvent,
  ReactNode,
} from 'react';
import PT from 'prop-types';
import { useFocusRing } from '@react-aria/focus';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';
import type { TaktProps } from '../../types/TaktProps';
import { useTileGroupContext } from '../TileGroupContext';
import { TileContextProvider, TileControlContextProvider, DEFAULT_TILE_CONTEXT } from './TileContext';
import {
  Container,
} from './Tile.styles';
import type { TileDirection, TileInputValue } from '../types';

export interface TileProps<T> extends TaktProps {
  /**
   * How header, body and footer are placed.
   * @defaultValue `undefined`
   */
  direction?: TileDirection;
  /**
   * Set this to true to indicate the Tile is disabled.
   * @defaultValue `false`
   */
  disabled?: boolean;
  /**
   * Hidden label of the Tile.
   */
  label: string;
  /**
   * The function called on input change.
   * @defaultValue `undefined`
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Set this to true to indicate the value of the Tile is selected.
   * @defaultValue `false`
   */
  selected?: boolean;
  /**
   * Value of the Tile. Can be number or string.
   */
  value: T;
  children: ReactNode;
 }

const Tile = forwardRef<HTMLInputElement, TileProps<TileInputValue>>(({
  direction: tileDirection,
  disabled: tileDisabled,
  label,
  onChange,
  selected: tileSelected,
  taktId,
  taktValue,
  value,
  children,
  ...restProps
}, ref) => {
  const {
    onSelect,
    selectedValues,
    direction: tileGroupDirection,
    disabled: tileGroupDisabled,
  } = useTileGroupContext();
  const selected = (selectedValues ?? []).findIndex(selectedValue => selectedValue === value) > -1
    || tileSelected
    || DEFAULT_TILE_CONTEXT.selected;
  const direction = tileGroupDirection || tileDirection || DEFAULT_TILE_CONTEXT.direction;
  const disabled = tileGroupDisabled || tileDisabled || DEFAULT_TILE_CONTEXT.disabled;
  const { isFocusVisible, focusProps } = useFocusRing();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(value, event);
    }
    if (onChange) {
      onChange(event);
    }
  }, [onChange, onSelect, value]);

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('tile')}>
      {({ getDataTaktAttributes }) => (
        <Container
          $checked={selected}
          $disabled={disabled}
          $direction={direction}
          $focusVisible={isFocusVisible}
        >
          <TileContextProvider
            disabled={disabled}
            selected={selected}
            direction={direction}
          >
            <TileControlContextProvider
              focusProps={focusProps}
              label={label}
              onChange={handleChange}
              value={value}
              dataTaktAttributes={getDataTaktAttributes({ taktIdSuffix: 'input' })}
              innerRef={ref}
              restProps={restProps}
            >
              {children}
            </TileControlContextProvider>
          </TileContextProvider>
        </Container>
      )}
    </TaktIdConsumer>
  )
})

Tile.defaultProps = {
  direction: undefined,
  disabled: false,
  onChange: undefined,
  selected: false,
}

Tile.propTypes = {
  /**
   * How header, body and footer are placed.
   */
  direction: PT.oneOf(['column', 'row']),
  /**
   * Set this to true to indicate the Tile is disabled.
   */
  disabled: PT.bool,
  /**
   * Hidden label of the Tile.
   */
  label: PT.string.isRequired,
  /**
   * The function called on input change.
   */
  onChange: PT.func,
  /**
   * Set this to true to indicate the value of the Tile is selected.
   */
  selected: PT.bool,
  /**
   * Value of the Tile.
   */
  value: PT.oneOfType([PT.string, PT.number]).isRequired,
}

export default Tile;
