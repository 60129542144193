import React, {
  createRef, PureComponent, ReactNode, KeyboardEvent, ChangeEvent, ReactElement, FormEvent,
} from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import {
  getFocusableElements,
  noop,
  keyboardKeynames as keys,
} from '@amzn/storm-ui-utils';
import { ButtonGroupItemMergedProps } from './ButtonGroupItem';

const StyledButtonGroup = styled('fieldset')`
  border: none;
  display: block;
  width: fit-content;
  margin: 0;
  position: relative;

  :focus-within, :focus {
    outline: none;
  }
  > button {
    margin-inline-start: 2px;
  }
  > button:first-of-type {
    /* set border radii on outside corners */
    /*! @noflip */ border-radius: ${({ theme }) => theme.button.borderRadius} 0 0 ${({ theme }) => theme.button.borderRadius};
    /* collapse shared borders to 1px */
    border-left-width: 1px;
    ::after{
      /*! @noflip */ border-radius: ${({ theme }) => theme.button.borderRadius} 0 0 ${({ theme }) => theme.button.borderRadius};
    }

    /*! @noflip */
    [dir="rtl"] & {
      /*! @noflip */ border-radius: 0 ${({ theme }) => theme.button.borderRadius} ${({ theme }) => theme.button.borderRadius} 0;
      ::after{
        /*! @noflip */ border-radius: 0 ${({ theme }) => theme.button.borderRadius} ${({ theme }) => theme.button.borderRadius} 0;
      }
      border-left-width: 0px;
      border-right-width: 1px;
    }

    margin-inline-start: 0px;
  }

  > button:last-of-type {
    /* set border radii on outside corners */
    /*! @noflip */ border-radius: 0 ${({ theme }) => theme.button.borderRadius} ${({ theme }) => theme.button.borderRadius} 0;
    ::after{
      /*! @noflip */ border-radius: 0 ${({ theme }) => theme.button.borderRadius} ${({ theme }) => theme.button.borderRadius} 0;
    }

    /*! @noflip */
    [dir="rtl"] & {
      /*! @noflip */ border-radius: ${({ theme }) => theme.button.borderRadius} 0 0 ${({ theme }) => theme.button.borderRadius};
      ::after{
        /*! @noflip */ border-radius: ${({ theme }) => theme.button.borderRadius} 0 0 ${({ theme }) => theme.button.borderRadius};
      }
    }
  }

  /* Unset user agent styles */
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: 0;
`;
StyledButtonGroup.displayName = 'StyledButtonGroup';

const StyledLegendSrOnly = styled.legend`
  ${({ theme }) => theme.typography.screenReaderOnly}
`;
StyledLegendSrOnly.displayName = 'StyledLegendSrOnly';

export interface ButtonGroupProps {
      /**
     * Children of ButtonGroup must be ButtonGroupItems.
     */
  children: ReactElement<ButtonGroupItemMergedProps> | ReactElement<ButtonGroupItemMergedProps>[],
    /**
     * The text displayed above the ButtonGroup.
     * @defaultValue `""`
     */
  label?: string,
    /**
     * This MUST be unique, and it allows the group to manage all children properly.
     */
  name: string,
    /**
     * Determines size of button group.
     * @defaultValue `false`
     */
  small?: boolean,
  /**
   * Called when the user changes the component state.
   * @defaultValue `() => undefined`
   */
  onChange?: (
    value: string | undefined,
    event: FormEvent<HTMLElement>
  ) => void;
  /**
   * The value returned when the button is clicked.
   * @defaultValue `""`
   */
  selectedValue?: string,
}

export default class ButtonGroup extends PureComponent<ButtonGroupProps> {
  static propTypes = {
    /**
     * Children of ButtonGroup must be ButtonGroupItems.
     */
    children: PT.node.isRequired,
    /**
     * The text displayed above the ButtonGroup.
     */
    label: PT.string,
    /**
     * This MUST be unique, and it allows the group to manage all children properly.
     */
    name: PT.string.isRequired,
    /**
     * Determines size of button group.
     */
    small: PT.bool,
    /**
     * Called when the user changes the component state.
     */
    onChange: PT.func,
    /**
     * The value returned when the button is clicked.
     */
    selectedValue: PT.string,
  }

  static defaultProps = {
    label: '',
    small: false,
    selectedValue: '',
    onChange: noop,
  }

  buttonGroupRef = createRef<HTMLFieldSetElement>();

  // KeyDown events to handle for keyboard accessibility. Best practices for Tabs with manual
  // activation here: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html
  handleKeyDown = (event: KeyboardEvent<HTMLFieldSetElement>): void => {
    const { key } = event;

    // By default, buttons handle the Enter key on KeyDown. We prevent this because we want
    // to listen to KeyUp to prevent repeat triggers.
    if (key === keys.ENTER) {
      event.preventDefault();
    }

    if (typeof document !== 'undefined' && this.buttonGroupRef.current) {
      const focusableEls = getFocusableElements(this.buttonGroupRef.current);
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      // When "Left Arrow" or "Right Arrow" is pressed, move focus to the left or right
      if (key === keys.ARROW_DOWN
        || key === keys.ARROW_UP
        || key === keys.ARROW_LEFT
        || key === keys.ARROW_RIGHT
      ) {
        const currentIndex = focusableEls.indexOf(document.activeElement as HTMLElement);
        let newFocusIndex = 0;

        // When "Down Arrow" is pressed, focus to the next
        if (key === keys.ARROW_DOWN || key === keys.ARROW_RIGHT) {
          newFocusIndex = currentIndex + 1;
          if (document.activeElement === lastFocusableEl) {
            newFocusIndex = 0;
          }
        }

        // When "Up Arrow" is pressed, focus to the previous
        if (key === keys.ARROW_UP || key === keys.ARROW_LEFT) {
          newFocusIndex = currentIndex - 1;
          if (document.activeElement === firstFocusableEl) {
            newFocusIndex = focusableEls.length - 1;
          }
        }

        focusableEls[newFocusIndex].focus();
        event.preventDefault();
      }
    }
  }

  handleChangeActive = (
    value: string | undefined,
    event: ChangeEvent<HTMLButtonElement>,
  ): void => {
    const { onChange, selectedValue } = this.props;
    if (value !== selectedValue && onChange) {
      onChange(value, event);
    }
  }

  renderChildren(): ReactNode {
    const {
      name, small, selectedValue, children,
    } = this.props;

    return React.Children.map<ReactNode, ReactElement<ButtonGroupItemMergedProps>>(children, child => {
      if (!React.isValidElement(child)) return null;

      return React.cloneElement(child, {
        key: child.props.value,
        name,
        small,
        isChecked: selectedValue === child.props.value,
        onChange: this.handleChangeActive,
        id: `${name}-${child.props.value}`,
      });
    });
  }

  render(): JSX.Element {
    const { label } = this.props;
    return (
      <StyledButtonGroup
        onKeyDown={event => this.handleKeyDown(event)}
        ref={this.buttonGroupRef}
        role="radiogroup"
      >
        {label && <StyledLegendSrOnly>{label}</StyledLegendSrOnly>}
        {this.renderChildren()}
      </StyledButtonGroup>
    );
  }
}
