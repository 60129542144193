/* eslint-disable id-length */
import { border } from '../base/border';
import { palette } from '../base/color';
import { spacing } from '../base/spacing';

const checkboxStyles = {
  bg: palette.white,
  bgChecked: palette.blue[700],
  bgCheckedHover: palette.blue[800],
  bgDisabled: palette.gray[100],
  bgHover: palette.blue[200],
  bgActive: palette.blue[900],
  borderWidth: '1px',
  borderColor: palette.gray[500],
  borderColorDisabled: palette.gray[300],
  check: 'fas fa - check', // checked icon
  colorDisabled: palette.gray[700],
  radius: '3px',
  size: {
    medium: spacing.base,
    large: '20px',
  },
};

export const mobileCheckboxStyles = {
  mobile: {
    padding: '10px 0',

    groupBackgroundColor: palette.white,
    groupBorderColor: palette.mercury,
    groupBorderRadius: border.roundedThin.radius,
    groupBorderWidth: border.roundedThin.width,
  },
};

export default checkboxStyles;
