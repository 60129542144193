/* eslint max-len: 0 */
import styled, { css } from 'styled-components';
import {
  Button,
  ButtonProps,
  Icon,
  isMobileStyleMixin as isMobile,
} from '@amzn/storm-ui';
import { isMultiSelectMobile, useMultiSelectIsMobile, MultiSelectMobileProps } from './MultiSelectIsMobileContext';

interface WrapperProps {
  $fullWidth?: boolean;
  $inline?:boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  padding: 0;
  font-weight: initial;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: ${({ $inline }) => ($inline ? 'nowrap' : 'wrap')};
  max-width: 100%;
  min-width: 0;

  & button {
    min-width: 0;
    flex: 0 1 auto;

    ${({ $fullWidth }) => $fullWidth && css`
      width: 100%;
      text-align: start;
    `}
    display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
  }
`;
Wrapper.displayName = 'Wrapper';

export const LabelText = styled.label<WrapperProps>`
  ${({ theme }) => theme.typography.base}
  color: ${({ theme }) => theme.typography?.color?.base};
  font-weight:   ${({ theme }) => theme.form.label.weight};

  align-self: center;

  ${({ $inline, theme }) => ($inline ? css`
    display: inline-block;
    margin-inline-end: ${(theme.spacing.mini)};
  ` : css`
    display: block;
    flex: 0 0 100%;
  `)}

  ${isMobile(css`${({ theme }) => theme.typography.mobile.base};`)}
`;
LabelText.displayName = 'LabelText';

export const DropdownLabel = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
DropdownLabel.displayName = 'DropdownLabel';

export const DropdownIcon = styled(Icon)`
  margin-inline-start: ${({ theme }) => theme.spacing.small};;
  color:  ${({ theme }) => theme.palette.gray[700]};
`;
DropdownIcon.displayName = 'DropdownIcon';

export const MultiSelectFixedContainer = styled.div<MultiSelectMobileProps>`
  margin: ${({ theme }) => theme.spacing.small} 0;

  ${isMultiSelectMobile(css`
    margin: 0;
  `)}
`;
MultiSelectFixedContainer.displayName = 'MultiSelectFixedContainer';

// Copied the mobile style from packages/storm-ui/src/Checkbox/CheckboxGroup/CheckboxGroup.tsx:CheckboxGroupItem
export const MultiSelectItemButton = styled.button<MultiSelectMobileProps>`
  ${({ theme }) => theme.typography.base};
  color: ${({ disabled, theme }) => (disabled ? theme.form.label.colorDisabled : theme.form.label.color)};
  margin: 0;
  appearance: none;

  ${isMobile(css`
    ${({ theme }) => theme.typography.mobile.base};
  `)}
  text-align: start;
  position: relative;
  width: 100%;
  display: list-item;
  list-style-type: none;
  cursor: pointer;
  word-wrap: break-word;
  padding: ${({ theme }) => `${theme.spacing.mini} ${theme.spacing.base}`};

  ${({ theme, disabled }) => disabled && css`
    color: ${theme.dropdown.item.colorDisabled};
    cursor: not-allowed;
  `};

  ${({ theme }) => (`
    outline: none;
    border: 0;
    background-color: ${theme.dropdown.item.bg};
    :active, :hover, :focus {
      background-color: ${theme.palette.gray[50]};
      box-shadow: inset 0 0 0 1px ${theme.palette.gray[50]};
    }
  `
  )}

  ${isMultiSelectMobile(css`
    padding: ${({ theme }) => `${theme.mobile.spacing.mini} ${theme.mobile.spacing.small}`};

    background-color: ${({ theme }) => theme.form.checkbox.mobile.groupBackgroundColor};
    border: 1px solid ${({ theme }) => theme.form.checkbox.mobile.groupBorderColor};
    border-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
  `)}

  > div {
    display: flex;
    align-items: center;
  }
`;
MultiSelectItemButton.displayName = 'MultiSelectItemButton';

// Copied the mobile style from packages/storm-ui/src/Checkbox/CheckboxGroup/CheckboxGroup.tsx:CheckboxGroupContainer
export const MultiSelectItemsContainer = styled.div<MultiSelectMobileProps>`
  max-height: ${({ theme }) => theme.dropdown.maxHeight};
  margin: ${({ theme }) => theme.spacing.small} 0;
  overflow-y: auto;

  ${isMultiSelectMobile(css`
    max-height: unset;
    display: block;
    margin: 0;
    padding: ${({ theme }) => theme.mobile.spacing.mini};

    ${MultiSelectItemButton} {
      border-radius: 0;
      border-block-start: none;
    }

    ${MultiSelectItemButton}:first-of-type {
      border-start-start-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
      border-start-end-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
      border-block-start: 1px solid ${({ theme }) => theme.form.checkbox.mobile.groupBorderColor};
    }

    ${MultiSelectItemButton}:last-of-type {
      border-end-start-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
      border-end-end-radius: ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius} ${({ theme }) => theme.form.checkbox.mobile.groupBorderRadius};
    }
  `)}
`;
MultiSelectItemsContainer.displayName = 'MultiSelectItemsContainer';

export const MultiSelectContainer = styled.div<MultiSelectMobileProps>`
  ${isMultiSelectMobile(css`
    height: 100%;
    display: flex;
    flex-direction: column;
    & ${MultiSelectItemsContainer}{
      flex: 1;
    }
  `)}
`;
MultiSelectContainer.displayName = 'MultiSelectContainer';

export const LoadingIcon = styled(Icon)`
  display: block;
  text-align: center;
  margin: ${({ theme }) => theme.spacing.mini} 0
`;
LoadingIcon.displayName = 'LoadingIcon';

export const MultiSelectItemLabel = styled.span<{ $disabled?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;
MultiSelectItemLabel.displayName = 'MultiSelectItemLabel';

// Copied from packages/storm-ui-filter-dropdown/src/FilterDropdown/FilterRadioGroupBody.tsx
export const PanelWrapper = styled.div.attrs(() => {
  const isMultiSelectIsMobile = useMultiSelectIsMobile();
  return ({
    $isMobile: isMultiSelectIsMobile,
  });
})<MultiSelectMobileProps>`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.base}`};

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }

  ${isMultiSelectMobile(css`
    padding: ${({ theme }) => theme.mobile.spacing.mini};
    background-color: ${({ theme }) => theme.palette.white};
    text-align: start;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.mercury};

    :not(:last-child) {
      margin-bottom: 0px;
    }
  `)}
`;

export interface MultiSelectTriggrButtonProps extends ButtonProps {
  $error?: boolean;
}
// https://code.amazon.com/packages/StormUI-v3/blobs/16ba8bd9c82de2acdc417cd002a0901f9c28fdb1/--/packages/storm-ui/src/Dropdown/Dropdown.styles.tsx#L54
export const MultiSelectTriggrButton = styled(Button)<MultiSelectTriggrButtonProps>`
  ${({ $error, theme }) => ($error && `
    border: thin solid ${theme.form.input.error.borderColor};
    :active {
      border: thin solid ${theme.form.input.error.borderColor};
    }
  `)}
`;
