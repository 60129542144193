import React, { FC, ReactNode, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import { TaktProps } from '../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../TaktIdContext';
import {
  TouchLinkAnchor,
  TouchLinkButton,
} from './TouchLink.styles';
import TouchLinkBody from './TouchLinkBody';

export interface TouchLinkProps extends TaktProps {
  /**
   * The React nodes that are rendered by the Touchlink.
   */
  children: ReactNode;
  /**
   * Use to add a link to TouchLink.
   * @defaultValue `undefined`
   */
  href?: string;
  /**
   * Use to add a button to TouchLink.
   * @defaultValue `undefined`
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const TouchLink: FC<React.PropsWithChildren<TouchLinkProps>> = ({
  children,
  href,
  onClick,
  taktId,
  taktValue,
}) => {
  if (onClick) {
    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('touch-link')}>
        {({ getDataTaktAttributes }) => (
          <TouchLinkButton onClick={onClick} {...getDataTaktAttributes()}>
            <TouchLinkBody>
              {children}
            </TouchLinkBody>
          </TouchLinkButton>
        )}
      </TaktIdConsumer>
    );
  }
  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('touch-link')}>
      {({ getDataTaktAttributes }) => (
        <TouchLinkAnchor href={href} {...getDataTaktAttributes()}>
          <TouchLinkBody>
            {children}
          </TouchLinkBody>
        </TouchLinkAnchor>
      )}
    </TaktIdConsumer>
  );
}

TouchLink.propTypes = {
  /**
   * Use to add a link to TouchLink.
   */
  href: PropTypes.string,
  /**
   * Use to add a button to TouchLink.
   */
  onClick: PropTypes.func,
};

TouchLink.defaultProps = {
  href: undefined,
  onClick: undefined,
}

TouchLink.displayName = 'TouchLink';

export default TouchLink;
