import { useContext } from 'react';
import DateSingleContext from '../contexts/DateSingle/DateSingleContext';
import { DateSingleContextValue } from '../types';

const useDateSingle = (): DateSingleContextValue => {
  const context = useContext(DateSingleContext);
  if (!context) {
    throw new Error(
      'DateSingle context is not defined.',
    );
  }
  return context;
};

export default useDateSingle;
