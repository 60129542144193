import React, {
  createRef,
  Component,
  ReactNode,
  ChangeEvent,
  RefObject,
} from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { composeRefs, isString, noop } from '@amzn/storm-ui-utils';
import DropdownItem from './DropdownItem';
import DropdownItemGroup from './DropdownItemGroup';
import isShadowRootInstance from '../prop-types/isShadowRoot';
import LabelLine, { LabelLineComponentProps } from '../FormGroup/LabelLine';
import { Wrapper } from './Dropdown.styles';
import DropdownContainer from './DropdownContainer';
import { ButtonProps } from '../Button/Button';
import { SelectListClickEventHandler, SelectListItem, SelectValue } from '../SelectList/types';
import isClickableListItem from '../SelectList/utils/isClickableListItem';
import defaultValueSerializer from '../SelectList/utils/defaultValueSerializer';
import { TaktIdConsumer, createStormTaktId, withTaktFallbackId } from '../TaktIdContext';
import DropdownButton from './DropdownButton';
import { DropdownIsMobileProvider } from './DropdownIsMobileContext';
import { TaktProps } from '../types/TaktProps';

export interface StyledLabelLineProps extends LabelLineComponentProps {
  $inline?: boolean,
}

const StyledLabelLine = styled(LabelLine)<StyledLabelLineProps>`
  label&& {
    align-self: center;
    margin-bottom: 2px;

    ${({ $inline, theme }) => ($inline ? css`
      display: inline-block;
      margin-bottom: 0;
      margin-inline-end: ${(theme.spacing.mini)};
      margin-inline-start: 0;
    ` : css`
      display: block;
    `)}
  }
`;

const DropdownLabelLine = withTaktFallbackId<StyledLabelLineProps>(StyledLabelLine)

export interface DropdownProps extends TaktProps, Omit<ButtonProps, 'ref'> {
      /**
     * `<DropdownItem />` usually, but can also be `<DropdownDivider />` or `<DropdownItemGroup />`
     * other react elements. Only DropdownItems will be used for selection purposes.
     */
  children?: SelectListItem | SelectListItem[];
      /*
     * We DO want `any` allowed to be passed in because we can not assume the type that a consumer
     * will need to use.
     * @defaultValue `""`
     */
  selectedValue?: SelectValue;
      /**
     * Function called when the user selects a new item. Passes the value and event.
     * @defaultValue `() => undefined`
     */
  onChange?: SelectListClickEventHandler;
      /**
     * Specifies that the dropdown should be a smaller size than default.
     * @defaultValue `false`
     */
  small?: boolean;
      /**
     * Overrides the placeholder text for the dropdown.
     * @defaultValue `undefined`
     */
  selectedLabelOverride?: string;
      /**
     * Hook in to this to display the "selected" label differently than how
     * it appears in the list item.
     * @defaultValue `(label) => label`
     */
  onOverrideLabel?: (label: string, value?: SelectValue) => ReactNode;
      /**
     * Allows you to force dropdown to be open. Leave `undefined` to keep normal behavior.
     * @defaultValue `false`
     */
  forceOpen?: boolean;
      /**
     * Moves the label inline.
     * @defaultValue `false`
     */
  inline?: boolean;
      /**
     * Can be used to render elements prior to the `<SelectList />`. Useful to have a sticky
     * section which does not scroll with long dropdown lists.
     * @defaultValue `undefined`
     */
  preRender?: () => ReactNode;
      /**
     * Can be used to render elements after the `<SelectList />`. Useful to have a sticky
     * section which does not scroll with long dropdown lists.
     * @defaultValue `undefined`
     */
  postRender?: () => ReactNode;
      /**
     * Function called when the dropdown is opened.
     * @defaultValue `() => undefined`
     */
  onOpen?: () => void;
    /**
     * Function called when the dropdown is closed.
     * @defaultValue `() => undefined`
     */
  onClose?: () => void;
      /**
     * The text label for the dropdown.
     * @defaultValue `""`
     */
  label?: string;
      /**
     * Labels are required for screen reader accessibility, but can be hidden visually.
     * @defaultValue `false`
     */
  hideLabel?: boolean;
      /**
     * Force dropdown to fill container (instead of matching content). Can be used to
     * size custom by giving container a width.
     * @defaultValue `false`
     */
  fullWidth?: boolean;
      /**
     * Specifies if the control is disabled, which prevents the user from modifying the value and
     * prevents the value from being included in a form submission. A disabled control can't receive
     * focus.
     * @defaultValue `false`
     */
  disabled?: boolean;
      /**
     * Prevents dropdown items from wrapping text on whitespace. This is useful for browser such as
     * Firefox and Safari that consume some of the dropdown space to place a scrollbar.
     * @defaultValue `false`
     */
  noWrapDropdownItems?: boolean;
      /**
     * When the dropdown opened the currently selected item takes input focus.
     * @defaultValue `false`
     */
  focusSelected?: boolean;
      /**
     * Displays a red border and sets aria-invalid="true" when true. If a dropdown is in an invalid
     * state, pair and describe it with an `<InlineMessage error />` to explain the problem.
     * @defaultValue `false`
     */
  error?: boolean;
      /**
     * Ref to the dropdown toggle button element.
     * @defaultValue `undefined`
     */
  shadowRoot?: EventTarget;
      /**
     * The page unique identifier of the input.
     * @defaultValue `undefined`
     */
  id?: string;
      /**
     * Used to render content *before* the label, but outside the click-able area.
     * @defaultValue `undefined`
     */
  renderLabelStart?: ReactNode | (() => ReactNode);
      /**
     * Used to render content *after* the label, but outside the click-able area.
     * @defaultValue `undefined`
     */
  renderLabelEnd?: ReactNode | (() => ReactNode);
      /**
     * Used to render dropdown button with no background.
     * @defaultValue `false`
     */
  transparentButton?: boolean;
      /**
     * Used to render dropdown in SecondaryView on mobile device.
     * @defaultValue `false`
     */
  mobileFullScreen?: boolean;
      /**
     * Accepts elements to be rendered inside the close button in the SecondaryView's header.
     * @defaultValue `"Done"`
     */
  secondaryViewCloseButtonLabel?: ReactNode;
  /**
   * Ref to the internal Popper component
   * @defaultValue `undefined`
   */
  popperRef?: RefObject<HTMLDivElement>;
  /**
   * Position the popover element relative to the trigger.
   * @defaultValue `"bottom"`
   */
  openDirection?: 'bottom' | 'top';
  /**
   * Set to true if need to force the placement of the popover element.
   * @defaultValue `"false"`
   */
  forceDirection?: boolean;
}

interface DropdownState {
  isPopoverVisible: boolean;
}

class Dropdown extends Component<DropdownProps, DropdownState> {
  static propTypes = {
    /*
     * We DO want `any` allowed to be passed in because we can not assume the type that a consumer
     * will need to use.
     */
    // eslint-disable-next-line
    selectedValue: PT.any,
    /**
     * `<DropdownItem />` usually, but can also be `<DropdownDivider />` or `<DropdownItemGroup />`
     * other react elements. Only DropdownItems will be used for selection purposes.
     */
    children: PT.node.isRequired as PT.Requireable<ReactNode>,
    /**
     * Function called when the user selects a new item. Passes the value and event.
     */
    onChange: PT.func,
    /**
     * Specifies that the dropdown should be a smaller size than default.
     */
    small: PT.bool,
    /**
     * Overrides the placeholder text for the dropdown.
     */
    selectedLabelOverride: PT.string,
    /**
     * Hook in to this to display the "selected" label differently than how
     * it appears in the list item.
     */
    onOverrideLabel: PT.func,
    /**
     * Allows you to force dropdown to be open. Leave `undefined` to keep normal behavior.
     */
    forceOpen: PT.bool,
    /**
     * Moves the label inline.
     */
    inline: PT.bool,
    /**
     * Can be used to render elements prior to the `<SelectList />`. Useful to have a sticky
     * section which does not scroll with long dropdown lists.
     */
    preRender: PT.func,
    /**
     * Can be used to render elements after the `<SelectList />`. Useful to have a sticky
     * section which does not scroll with long dropdown lists.
     */
    postRender: PT.func,
    /**
     * Function called when the dropdown is opened.
     */
    onOpen: PT.func,
    /**
     * Function called when the dropdown is closed.
     */
    onClose: PT.func,
    /**
     * The text label for the dropdown.
     */
    label: PT.string,
    /**
     * Labels are required for screen reader accessibility, but can be hidden visually.
     */
    hideLabel: PT.bool,
    /**
     * Force dropdown to fill container (instead of matching content). Can be used to
     * size custom by giving container a width.
     */
    fullWidth: PT.bool,
    /**
     * Specifies if the control is disabled, which prevents the user from modifying the value and
     * prevents the value from being included in a form submission. A disabled control can't receive
     * focus.
     */
    disabled: PT.bool,
    /**
     * Prevents dropdown items from wrapping text on whitespace. This is useful for browser such as
     * Firefox and Safari that consume some of the dropdown space to place a scrollbar.
     */
    noWrapDropdownItems: PT.bool,
    /**
     * When the dropdown opened the currently selected item takes input focus.
     */
    focusSelected: PT.bool,
    /**
     * Displays a red border and sets aria-invalid="true" when true. If a dropdown is in an invalid
     * state, pair and describe it with an `<InlineMessage error />` to explain the problem.
     */
    error: PT.bool,
    /**
     * Ref to the dropdown toggle button element.
     */
    shadowRoot: isShadowRootInstance,
    /**
     * The page unique identifier of the input.
     */
    id: PT.string,
    /**
     * Used to render content *before* the label, but outside the click-able area.
     */
    renderLabelStart: PT.oneOfType([PT.func, PT.node]) as PT.Validator<ReactNode | (() => ReactNode)>,
    /**
     * Used to render content *after* the label, but outside the click-able area.
     */
    renderLabelEnd: PT.oneOfType([PT.func, PT.node]) as PT.Validator<ReactNode | (() => ReactNode)>,
    /**
     * Used to render dropdown button with no background.
     */
    transparentButton: PT.bool,
    /**
     * Used to render dropdown in SecondaryView on mobile device.
     */
    mobileFullScreen: PT.bool,
    /**
     * Accepts elements to be rendered inside the close button in the SecondaryView's header.
     */
    secondaryViewCloseButtonLabel: PT.node as PT.Validator<ReactNode>,
    /**
     * Position the popover element relative to the trigger.
     */
    openDirection: PT.string,
    /**
     * Set to true if need to force the placement of the popover element.
     */
    forceDirection: PT.bool,
  };

  static defaultProps = {
    selectedValue: '',
    small: false,
    onChange: noop,
    onOpen: noop,
    onClose: noop,
    preRender: undefined,
    postRender: undefined,
    onOverrideLabel: (label: string): string => label,
    forceOpen: false,
    inline: false,
    selectedLabelOverride: undefined,
    label: '',
    hideLabel: false,
    fullWidth: false,
    disabled: false,
    noWrapDropdownItems: false,
    focusSelected: true,
    error: false,
    shadowRoot: undefined,
    id: undefined,
    renderLabelStart: undefined,
    renderLabelEnd: undefined,
    transparentButton: false,
    mobileFullScreen: false,
    secondaryViewCloseButtonLabel: 'Done',
    openDirection: 'bottom',
    forceDirection: false,
  };

  private toggleRef = createRef<HTMLButtonElement>();

  private labelRef = createRef<HTMLLabelElement>();

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      isPopoverVisible: !!props.forceOpen,
    };
  }

  componentDidUpdate(
    { forceOpen: prevForceOpen }: DropdownProps,
    { isPopoverVisible: prevIsPopoverVisible }: DropdownState,
  ): void {
    const { onOpen, onClose, forceOpen } = this.props;
    const { isPopoverVisible } = this.state;
    if (isPopoverVisible !== prevIsPopoverVisible) {
      if (isPopoverVisible) {
        onOpen?.();
      } else {
        onClose?.();
      }
    }
    if (!prevForceOpen && forceOpen) {
      this.setState({ isPopoverVisible: true });
    }
  }

  handleChange = (
    value?: SelectValue,
    event?: ChangeEvent,
  ): void => {
    const { onChange } = this.props;
    onChange?.(value, event);
  }

  togglePopoverView = (): void => {
    const { disabled, forceOpen } = this.props;
    if (!disabled && !forceOpen) {
      this.setState(prevState => ({
        isPopoverVisible: !prevState.isPopoverVisible,
      }));
    }
  }

  togglePopoverViewFromPopper = (event?: ChangeEvent): void => {
    if (!event) {
      this.togglePopoverView();
    } else if (event
      && event.target instanceof Node
      && this.labelRef
      && !this.labelRef.current?.contains(event.target)) {
      this.togglePopoverView();
    }
  }

  getCurrentLabel = (): string => {
    const { selectedValue, children } = this.props;

    let currentLabel = '';

    React.Children.forEach<SelectListItem>(children, item => {
      /*
       * We use the `name` static member to determine if the child is a DropdownItem.
       * We can not directly check if it is the same instance of the component
       * because the hot loader will create new instances in dev mode. Then the comparison
       * will fail incorrectly.
       */
      if (isClickableListItem(item)) {
        if (item?.type.displayName === DropdownItem.displayName) {
          if (JSON.stringify(selectedValue) === JSON.stringify(item.props.value)) {
            currentLabel = item.props.children;
          }
        }

        if (item?.type.displayName === DropdownItemGroup.displayName) {
          React.Children.forEach(item.props.children, option => {
            if (JSON.stringify(selectedValue) === JSON.stringify(option.props.value)) {
              currentLabel = option.props.children;
            }
          });
        }
      }
    });

    return currentLabel;
  }

  getButtonText = () => {
    const { selectedLabelOverride, onOverrideLabel, selectedValue } = this.props;
    const currentLabel = this.getCurrentLabel();
    return selectedLabelOverride || onOverrideLabel?.(currentLabel, selectedValue);
  }

  render(): JSX.Element {
    const {
      children,
      selectedValue,
      selectedLabelOverride,
      onOverrideLabel,
      forceOpen,
      onChange,
      small,
      preRender,
      postRender,
      onOpen,
      onClose,
      inline,
      hideLabel,
      label,
      fullWidth,
      disabled,
      noWrapDropdownItems,
      focusSelected,
      error,
      shadowRoot,
      buttonRef,
      id,
      renderLabelStart,
      renderLabelEnd,
      transparentButton,
      secondaryViewCloseButtonLabel,
      mobileFullScreen,
      taktId,
      taktValue,
      popperRef,
      openDirection,
      forceDirection,
      ...rest
    } = this.props;

    const { name } = this.props

    const { isPopoverVisible } = this.state;

    const buttonText = this.getButtonText();

    return (
      <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('dropdown')}>
        {({ getDataTaktAttributes }) => (
          <DropdownIsMobileProvider mobileFullScreen={mobileFullScreen}>
            <Wrapper $fullWidth={fullWidth}>
              {label && (
                <DropdownLabelLine
                  {...getDataTaktAttributes({ taktIdSuffix: 'label' })}
                  innerRef={this.labelRef}
                  $inline={inline}
                  hidden={hideLabel}
                  renderLabelStart={renderLabelStart}
                  renderLabelEnd={renderLabelEnd}
                  onLabelClick={this.togglePopoverView}
                  {...(isString(id) ? { labelFor: id } : undefined)}
                >
                  {label}
                </DropdownLabelLine>
              )}
              <DropdownContainer
                secondaryViewCloseButtonLabel={secondaryViewCloseButtonLabel}
                anchorEl={this.toggleRef}
                isOpen={isPopoverVisible}
                onClose={this.togglePopoverViewFromPopper}
                shadowRoot={shadowRoot}
                trigger={triggerProps => (
                  <DropdownButton
                    {...rest}
                    {...getDataTaktAttributes({ taktIdSuffix: 'trigger-button' })}
                    $inline={inline}
                    disabled={disabled}
                    small={small}
                    onClick={this.togglePopoverView}
                    buttonRef={composeRefs(buttonRef, this.toggleRef, triggerProps?.setTriggerElement)}
                    aria-haspopup="listbox"
                    aria-label={`${label}${buttonText ? ` ${buttonText}` : ''}`}
                    aria-invalid={error}
                    $error={error}
                    transparent={transparentButton}
                    {
                      ...(isString(id) ? { id } : undefined)
                    }
                    buttonText={buttonText}
                  />
                )}
                preRender={preRender}
                postRender={postRender}
                selectedValue={selectedValue}
                onChange={this.handleChange}
                noWrap={noWrapDropdownItems}
                name=""
                focusSelected={focusSelected}
                forceOpen={forceOpen}
                popperRef={popperRef}
                openDirection={openDirection}
                forceDirection={forceDirection}
              >
                {children}
              </DropdownContainer>
              {/* hidden input used to pass value when forms submit */}
              <input type="hidden" name={name} value={defaultValueSerializer(selectedValue)} />
            </Wrapper>
          </DropdownIsMobileProvider>
        )}
      </TaktIdConsumer>
    );
  }
}

export default Dropdown;
