import React, {
  FC,
  useCallback,
  useRef,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { MergeElementProps, keyboardKeynames as keys } from '@amzn/storm-ui-utils-v3';
import styled, { css } from 'styled-components';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { BaseMenuItemMixin, MenuItem, BaseMenuItemProps } from './MenuItem.styles';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdConsumer, createStormTaktId } from '../../TaktIdContext';

export interface MenuItemLinkProps extends TaktProps, MergeElementProps<'a'> {
  /**
   * This disables interaction with the component and applies special
   * visual styles to indicate that it's not interactive.
   * @defaultValue `false`
   */
  disabled?: boolean;
}

export interface ItemLinkProps extends BaseMenuItemProps, MergeElementProps<'a'> {
}

export interface ItemLinkAttrsProps extends ItemLinkProps {
  $focusVisible?: boolean;
}

const ItemLink = styled('a')<ItemLinkProps>`
  ${BaseMenuItemMixin}
  a&, a&:visited, a&:active, a&:link {
    ${({ theme, $disabled }) => ($disabled ? css`
      color: ${theme.dropdown.item.colorDisabled};
      cursor: not-allowed;
    ` : css`
      color: ${theme.dropdown.item.color};
      cursor: pointer;
    `)}
  }
  display: block;
  line-height: 1.865;
  text-decoration: none;

  ${isMobile(css<ItemLinkProps>`
    ${({ theme }) => theme.typography.mobile.base};
    min-height: ${({ theme }) => theme.dropdown.item.mobile.minHeight};
    line-height: 2.865;
  `)}
`;

const MenuItemLink: FC<React.PropsWithChildren<MenuItemLinkProps>> = ({
  children,
  disabled,
  href,
  rel = 'noopener noreferrer',
  target = '_blank',
  onClick,
  taktId,
  taktValue,
  ...rest
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const onClickHandler = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      event.preventDefault();
    } else {
      onClick?.(event);
    }

    event.stopPropagation();
  }, [disabled, onClick]);

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLAnchorElement>) => {
    const { key } = event;

    if (!disabled && (key === keys.ENTER || key === keys.SPACE)) {
      linkRef.current?.click();
    }
  }, [disabled]);

  return (
    <TaktIdConsumer taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('menu-item-button')}>
      {({ getDataTaktAttributes }) => (
        <MenuItem>
          <ItemLink
            {...getDataTaktAttributes()}
            {...rest}
            href={disabled ? undefined : href}
            ref={linkRef}
            rel={rel}
            target={target}
            tabIndex={disabled ? -1 : 0}
            $disabled={disabled}
            aria-disabled={disabled}
            onClick={onClickHandler}
            onKeyDown={onKeyDown}
          >
            {children}
          </ItemLink>
        </MenuItem>
      )}
    </TaktIdConsumer>
  );
};

MenuItemLink.defaultProps = {
  disabled: false,
};

export default MenuItemLink;
