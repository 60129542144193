import {
  Icon,
  Button,
  TextButton,
  Popper,
  useIsMobile,
  Sheet,
  isMobileStyleMixin,
  useTaktId,
  createStormTaktId,
} from '@amzn/storm-ui-v3';
import { angleDown, angleInlineEnd, angleInlineStart } from '@amzn/storm-ui-icons-v3';
import enUS from 'date-fns/locale/en-US';
import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import useDatePicker from '../hooks/useDatePicker';
import useNavigation from '../hooks/useNavigation';
import { FormatMonth } from './formatters';
import CalendarDateSelector from './CalendarDateSelector';
import { ISODate } from '../types';

const CalendarHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const CaptionLabel = styled('div')`
  font-size: ${({ theme }) => theme.datepicker.monthSize};
  color: ${({ theme }) => theme.datepicker.monthColor};
  font-weight: bold;
  caption-side: initial;

  ${isMobileStyleMixin(css`
    font-size: ${({ theme }) => theme.datepicker.mobile.monthSize};
  `)}
`;

const NavButton = styled(TextButton)`
  color: ${({ theme }) => theme.datepicker.navigationColor};
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  width: 20px;
`;

const None = styled('div')`
  width: 20px;
`;

type NavButtonProps = {
  onClick: () => void;
  label: string;
}
const NextButton = ({ onClick, label, ...rest }: NavButtonProps): JSX.Element => (
  <NavButton {...rest} type="button" onClick={onClick} aria-label={label}>
    <Icon type={angleInlineEnd} />
  </NavButton>
);
const PreviousButton = ({ onClick, label, ...rest }: NavButtonProps): JSX.Element => (
  <NavButton {...rest} type="button" onClick={onClick} aria-label={label}>
    <Icon type={angleInlineStart} />
  </NavButton>
);

const NoneButton = (): JSX.Element => <None />;

export interface CalendarHeaderProps {
  displayMonth: ISODate;
  index?: number;
}

const CalendarMonthHeader:FC<React.PropsWithChildren<CalendarHeaderProps>> = ({ displayMonth, index = 0 }) => {
  const {
    numberOfMonths = 1,
    locale = enUS,
    previousMonthNavButtonLabel = 'go to previous month',
    nextMonthNavButtonLabel = 'go to next month',
    minDate,
    maxDate,
    today,
    taktId,
    taktValue,
  } = useDatePicker();
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const { getDataTaktAttributes } = useTaktId({ taktId, fallbackId: createStormTaktId('date-picker-calendar-month-header') });

  const isMobile = useIsMobile();

  const [active, setActive] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const showPrevious = index === 0;
  const showNext = index === numberOfMonths - 1;

  const handleClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  const handleApply = useCallback((desiredDate: ISODate) => {
    goToMonth(desiredDate);
    setActive(false);
  }, [goToMonth]);

  const renderDateSelector = useCallback(() => (
    <CalendarDateSelector
      locale={locale}
      onApply={handleApply}
      calendarIndex={index}
      minDate={minDate}
      maxDate={maxDate}
      today={today}
    />
  ), [handleApply, index, locale, maxDate, minDate, today]);

  const renderDateSelectorTriggerButton = useCallback(() => (
    <Button
      onClick={handleClick}
      transparent
      buttonRef={anchorRef}
      aria-haspopup="menu"
      {...getDataTaktAttributes({ taktValue, taktIdSuffix: 'calendar-month-header' })}
    >
      <CaptionLabel>
        <FormatMonth month={displayMonth} />
        <Icon type={angleDown} style={{ marginInlineStart: '8px' }} />
      </CaptionLabel>
    </Button>
  ), [displayMonth, getDataTaktAttributes, handleClick, taktValue]);

  return (
    <CalendarHeaderWrapper>
      {showPrevious ? (
        <PreviousButton
          {...getDataTaktAttributes({ taktValue, taktIdSuffix: 'calendar-month-previous' })}
          onClick={() => goToMonth(previousMonth)}
          label={previousMonthNavButtonLabel}
        />
      ) : <NoneButton />}
      {isMobile ? (
        <>
          {renderDateSelectorTriggerButton()}
          <Sheet
            isOpen={active}
            side="bottom"
            closeButton={{
              type: 'message',
              label: 'Done',
            }}
            withCloseButton={false}
          >
            {renderDateSelector()}
          </Sheet>
        </>
      ) : (
        <Popper
          id="example-popper"
          isOpen={active}
          trigger={renderDateSelectorTriggerButton()}
          onCloseButtonClick={() => setActive(false)}
          padding="none"
          withArrow={false}
          position="bottom"
          align="start"
          autoFocus
        >
          {renderDateSelector()}
        </Popper>
      )}
      {showNext ? (
        <NextButton
          {...getDataTaktAttributes({ taktValue, taktIdSuffix: 'calendar-month-next' })}
          onClick={() => goToMonth(nextMonth)}
          label={nextMonthNavButtonLabel}
        />
      ) : <NoneButton />}
    </CalendarHeaderWrapper>
  );
};

CalendarMonthHeader.defaultProps = {
  index: 0,
};

export default CalendarMonthHeader;
