import React, { FC } from 'react';
import NavigationContext from './NavigationContext';
import getDisplayMonths from '../../utils/getDisplayMonths';
import { useNavigationState } from '../../hooks/useNavigation';
import useDatePicker from '../../hooks/useDatePicker';
import { addMonths } from '../../utils/dateUtils';

export type NavigationProviderProps = {
  children: React.ReactNode;
};

const NavigationProvider: FC<React.PropsWithChildren<NavigationProviderProps>> = ({
  children,
}) => {
  const [month, goToMonth] = useNavigationState();

  const { numberOfMonths } = useDatePicker();

  const displayMonths = getDisplayMonths(month, numberOfMonths ?? 1);
  const nextMonth = addMonths(month, 1);
  const previousMonth = addMonths(month, -1);

  return (
    <NavigationContext.Provider
      value={{
        month,
        goToMonth,
        displayMonths,
        previousMonth,
        nextMonth,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
